import { Component, EventEmitter, Output } from "@angular/core";

import { TranslocoService, translate } from "@jsverse/transloco";

import { MessageService } from "primeng/api";
import { IresultInfo } from "../../shared/write-tables.service";

@Component({
  selector: "app-excel-base-component",
  template: "",
  standalone: false,
})
export class ExcelBaseComponent {
  @Output() readonly isBusy = new EventEmitter<boolean>();

  readonly language = this.transloco.getActiveLang();

  constructor(
    protected readonly transloco: TranslocoService,
    protected readonly messageService: MessageService
  ) {}

  myTranslation(key: string): string {
    return translate(key);
  }

  sendTableUpdateMessage(result: IresultInfo): void {
    this.messageService.add({
      severity: result.finishState,
      detail: result.issues
        ? translate("officeAddin.tableWriteIssue") + result.issues
        : translate("officeAddin.tableWriteSuccess") + ` (${result.timeTaken}s.)`,
      life: result.issues ? 5000 : 2000,
    });
  }

  sendFailureMessage(message: string): void {
    this.messageService.add({
      severity: "error",
      detail: message,
    });
  }

  setBusy(busyState: boolean): void {
    this.isBusy.emit(busyState);
  }
}
