import { Component } from "@angular/core";
import { SettingsService } from "../../shared/settings.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrl: "./login.component.scss",
  standalone: false,
})
export class LoginComponent {
  login(): void {
    this.settings.loginWithPopup();
  }

  constructor(private readonly settings: SettingsService) {}
}
