import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: { input: Date; output: Date; }
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { input: any; output: any; }
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: { input: any; output: any; }
  /** Represents NULL values */
  Void: { input: void; output: void; }
};

export class Activity {
  __typename?: 'Activity';
  _etag?: Maybe<Scalars['String']['output']>;
  candidate?: Maybe<CandidateActivityParameter>;
  collection?: Maybe<ActivityParameter>;
  date: Scalars['DateTime']['output'];
  educationExam?: Maybe<EducationExamActivityParameter>;
  id?: Maybe<Scalars['ID']['output']>;
  isInternal?: Maybe<Scalars['Boolean']['output']>;
  localizedSourceTaskDisplayName?: Maybe<Scalars['String']['output']>;
  organizationId: Scalars['ID']['output'];
  parameters?: Maybe<Scalars['JSONObject']['output']>;
  previewableFiles?: Maybe<Array<PreviewableFile>>;
  process?: Maybe<ActivityParameter>;
  processModel?: Maybe<ProcessModelActivityParameter>;
  sharing?: Maybe<SharingActivityParameter>;
  source: ActivitySource;
  sourceProcessDisplayName?: Maybe<Scalars['String']['output']>;
  sourceTaskDisplayNames?: Maybe<Array<ProcessOptionalString>>;
  task?: Maybe<TaskActivityParameter>;
  taskState?: Maybe<TaskActivityParameter>;
  type: ActivityType;
  user: ActivityParameter;
};

export class ActivityCandidateFilterInput {
  organizationId: Scalars['ID']['input'];
  selectedOrganizationIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export class ActivityCollectionFilterInput {
  organizationId: Scalars['ID']['input'];
  selectedOrganizationIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export class ActivityDiff {
  __typename?: 'ActivityDiff';
  children?: Maybe<Array<ActivityDiff>>;
  data: ActivityDiffData;
};

export class ActivityDiffData {
  __typename?: 'ActivityDiffData';
  captionKey: Scalars['String']['output'];
  captionParameter?: Maybe<Scalars['JSONObject']['output']>;
  changedAt?: Maybe<Scalars['DateTime']['output']>;
  changedBy?: Maybe<Scalars['String']['output']>;
  newValue?: Maybe<Scalars['String']['output']>;
  oldValue?: Maybe<Scalars['String']['output']>;
  property: Scalars['String']['output'];
};

export class ActivityDiffInput {
  activityId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};

export class ActivityFilterOption {
  __typename?: 'ActivityFilterOption';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  organizationId: Scalars['ID']['output'];
  organizationName?: Maybe<Scalars['String']['output']>;
};

export class ActivityOrganizationFilterInput {
  organizationId: Scalars['ID']['input'];
};

export class ActivityPageInput {
  filters?: InputMaybe<Scalars['JSONObject']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  organizationId: Scalars['ID']['input'];
  rows?: InputMaybe<Scalars['Int']['input']>;
  sortField?: InputMaybe<Scalars['String']['input']>;
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
};

export class ActivityParameter {
  __typename?: 'ActivityParameter';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  organizationId: Scalars['ID']['output'];
  organizationName?: Maybe<Scalars['String']['output']>;
};

export class ActivitySource {
  __typename?: 'ActivitySource';
  id: Scalars['ID']['output'];
  organizationId: Scalars['ID']['output'];
  organizationName?: Maybe<Scalars['String']['output']>;
  typeName: Scalars['String']['output'];
};

export enum ActivityType {
  AutoProcessOperationTriggered = 'AutoProcessOperationTriggered',
  CandidateAdded = 'CandidateAdded',
  CandidateAddedToCollection = 'CandidateAddedToCollection',
  CandidateAddedToExam = 'CandidateAddedToExam',
  CandidateBirthday = 'CandidateBirthday',
  CandidateBirthdaySoon = 'CandidateBirthdaySoon',
  CandidateDeleted = 'CandidateDeleted',
  CandidateDeletionReminder = 'CandidateDeletionReminder',
  CandidateDroppedOut = 'CandidateDroppedOut',
  CandidateLoggedIn = 'CandidateLoggedIn',
  CandidateNotFound = 'CandidateNotFound',
  CandidateOptedIn = 'CandidateOptedIn',
  CandidateOptedOut = 'CandidateOptedOut',
  CandidateRemovedFromCollection = 'CandidateRemovedFromCollection',
  CandidateRemovedFromExam = 'CandidateRemovedFromExam',
  CandidateRequestDeletion = 'CandidateRequestDeletion',
  CandidateUpdated = 'CandidateUpdated',
  CollectionAdded = 'CollectionAdded',
  CollectionDeleted = 'CollectionDeleted',
  CollectionEmbeddedIntoCollection = 'CollectionEmbeddedIntoCollection',
  CollectionNotFound = 'CollectionNotFound',
  CollectionRemovedFromCollection = 'CollectionRemovedFromCollection',
  CollectionUpdated = 'CollectionUpdated',
  DocumentNotFound = 'DocumentNotFound',
  DocumentSetMetaDataModified = 'DocumentSetMetaDataModified',
  EducationExamCreated = 'EducationExamCreated',
  EducationExamDeleted = 'EducationExamDeleted',
  EducationExamResultCreated = 'EducationExamResultCreated',
  EducationExamResultDeleted = 'EducationExamResultDeleted',
  EducationExamUpdated = 'EducationExamUpdated',
  EmailNotDelivered = 'EmailNotDelivered',
  FeedbackProvided = 'FeedbackProvided',
  FileDeleted = 'FileDeleted',
  FileExpiresSoon = 'FileExpiresSoon',
  FileModified = 'FileModified',
  FileUploaded = 'FileUploaded',
  FreeFormatFileDeleted = 'FreeFormatFileDeleted',
  FreeFormatFileUploaded = 'FreeFormatFileUploaded',
  InvalidSharingTemplate = 'InvalidSharingTemplate',
  InvalidUserGroupRoleMapping = 'InvalidUserGroupRoleMapping',
  OrganizationNotFound = 'OrganizationNotFound',
  OrganizationNotLinked = 'OrganizationNotLinked',
  ProcessCreated = 'ProcessCreated',
  ProcessDeleted = 'ProcessDeleted',
  ProcessModelDeleted = 'ProcessModelDeleted',
  ProcessModelPublished = 'ProcessModelPublished',
  ProcessModelReleaseCreated = 'ProcessModelReleaseCreated',
  ProcessModelUnpublished = 'ProcessModelUnpublished',
  ProcessNotFound = 'ProcessNotFound',
  ProfileMaskUpdated = 'ProfileMaskUpdated',
  SharingAdded = 'SharingAdded',
  SharingDeleted = 'SharingDeleted',
  SharingDisabled = 'SharingDisabled',
  SharingEnabled = 'SharingEnabled',
  SharingTypeModified = 'SharingTypeModified',
  SharingUpdated = 'SharingUpdated',
  TaskCancelled = 'TaskCancelled',
  TaskChanged = 'TaskChanged',
  TaskDeleted = 'TaskDeleted',
  TaskFailed = 'TaskFailed',
  TaskFinished = 'TaskFinished',
  TaskManuallyChanged = 'TaskManuallyChanged',
  TaskManuallyCreated = 'TaskManuallyCreated',
  TaskNotFound = 'TaskNotFound',
  TaskNoteAdded = 'TaskNoteAdded',
  TaskReminderSent = 'TaskReminderSent',
  TaskStarted = 'TaskStarted',
  TaskStateChanged = 'TaskStateChanged',
  TaskStateManuallyChanged = 'TaskStateManuallyChanged',
  UserLoggedIn = 'UserLoggedIn',
  UserNotFound = 'UserNotFound'
}

export class ActivityUserFilterInput {
  organizationId: Scalars['ID']['input'];
  selectedOrganizationIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export class AdHocEducationExam {
  __typename?: 'AdHocEducationExam';
  _etag?: Maybe<Scalars['String']['output']>;
  changedAt: Scalars['DateTime']['output'];
  changedBy: Scalars['String']['output'];
  examDateAndTime?: Maybe<DateWithTime>;
  examInstitution?: Maybe<Scalars['String']['output']>;
  examLocation?: Maybe<Scalars['String']['output']>;
  examModules?: Maybe<Array<ExamModuleType>>;
  examType: ExamType;
  id?: Maybe<Scalars['ID']['output']>;
  languageLevel?: Maybe<Scalars['String']['output']>;
  moduleType: EducationModuleType;
  name: Scalars['String']['output'];
  organizationId: Scalars['ID']['output'];
  plannedExamDateAndTime?: Maybe<DateWithTime>;
  plannedExamDateAndTimeConfirmed: Scalars['Boolean']['output'];
  plannedExamResultDate?: Maybe<Scalars['DateTime']['output']>;
  responsibleRolePayment?: Maybe<Scalars['String']['output']>;
};

export class AdHocEducationExamCreateInput {
  examDateAndTime?: InputMaybe<DateWithTimeInput>;
  examInstitution?: InputMaybe<Scalars['String']['input']>;
  examLocation?: InputMaybe<Scalars['String']['input']>;
  examModules?: InputMaybe<Array<ExamModuleType>>;
  examType: ExamType;
  languageLevel?: InputMaybe<Scalars['String']['input']>;
  moduleType: EducationModuleType;
  name: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  plannedExamDateAndTime?: InputMaybe<DateWithTimeInput>;
  plannedExamDateAndTimeConfirmed: Scalars['Boolean']['input'];
  plannedExamResultDate?: InputMaybe<Scalars['DateTime']['input']>;
  responsibleRolePayment?: InputMaybe<Scalars['String']['input']>;
};

export class AdHocEducationExamForCandidate {
  __typename?: 'AdHocEducationExamForCandidate';
  exam?: Maybe<AdHocEducationExam>;
  level: Scalars['String']['output'];
  participant?: Maybe<EducationExamParticipantEntity>;
  result?: Maybe<EducationExamResultFullData>;
};

export class AdHocEducationExamGetByCandidateIdInput {
  candidateId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};

export class AdHocEducationExamUpdateInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  examDateAndTime?: InputMaybe<DateWithTimeInput>;
  examInstitution?: InputMaybe<Scalars['String']['input']>;
  examLocation?: InputMaybe<Scalars['String']['input']>;
  examModules?: InputMaybe<Array<ExamModuleType>>;
  examType: ExamType;
  id?: InputMaybe<Scalars['ID']['input']>;
  languageLevel?: InputMaybe<Scalars['String']['input']>;
  moduleType: EducationModuleType;
  name: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  plannedExamDateAndTime?: InputMaybe<DateWithTimeInput>;
  plannedExamDateAndTimeConfirmed: Scalars['Boolean']['input'];
  plannedExamResultDate?: InputMaybe<Scalars['DateTime']['input']>;
  responsibleRolePayment?: InputMaybe<Scalars['String']['input']>;
};

export class AddCandidatesToCollectionsInput {
  candidateId: Scalars['ID']['input'];
  collections: Array<CollectionInput>;
  organizationId: Scalars['ID']['input'];
  sourceCollectionId?: InputMaybe<Scalars['ID']['input']>;
  sourceCollectionOrgId?: InputMaybe<Scalars['ID']['input']>;
};

export class AddCandidatesToCollectionsResult {
  __typename?: 'AddCandidatesToCollectionsResult';
  error?: Maybe<Scalars['Boolean']['output']>;
  errorMessage?: Maybe<Scalars['String']['output']>;
  notAddedCollectionIds?: Maybe<Array<Scalars['String']['output']>>;
};

export class AddForeignGroupsToGroupInput {
  _etag: Scalars['String']['input'];
  groupId: Scalars['ID']['input'];
  groupsIds: Array<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
};

export class AddUsersToGroupInput {
  _etag: Scalars['String']['input'];
  groupId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
  userIds: Array<Scalars['ID']['input']>;
};

export class AdditionalModules {
  __typename?: 'AdditionalModules';
  gueteSiegel?: Maybe<Scalars['Boolean']['output']>;
  organizationProfile?: Maybe<Scalars['Boolean']['output']>;
  professions?: Maybe<Scalars['Boolean']['output']>;
  talentPool?: Maybe<Scalars['Boolean']['output']>;
};

export class AfterRecognition {
  __typename?: 'AfterRecognition';
  confirmationSignedDate?: Maybe<Scalars['DateTime']['output']>;
  contractExtension?: Maybe<Scalars['Boolean']['output']>;
  contractSignDate?: Maybe<Scalars['DateTime']['output']>;
  contractTemplate?: Maybe<RecognitionPathContractTemplate>;
  contractTemplateId?: Maybe<Scalars['ID']['output']>;
  contractTemplateName?: Maybe<Scalars['String']['output']>;
  employerHistory?: Maybe<Array<EmployerHistoryEntry>>;
  employerId?: Maybe<Scalars['ID']['output']>;
  employerName?: Maybe<Scalars['String']['output']>;
  employerRegion?: Maybe<Scalars['String']['output']>;
  employerZipcode?: Maybe<Scalars['String']['output']>;
  employmentRelocationRequirements?: Maybe<Array<Scalars['String']['output']>>;
  endDate?: Maybe<Scalars['DateTime']['output']>;
  endOfProbationDate?: Maybe<Scalars['DateTime']['output']>;
  firstDeputyFirstName?: Maybe<Scalars['String']['output']>;
  firstDeputyId?: Maybe<Scalars['ID']['output']>;
  firstDeputyLastName?: Maybe<Scalars['String']['output']>;
  isTemporaryEmployee?: Maybe<Scalars['Boolean']['output']>;
  representativeFirstName?: Maybe<Scalars['String']['output']>;
  representativeId?: Maybe<Scalars['ID']['output']>;
  representativeLastName?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['DateTime']['output']>;
  typeOfWorkLocation?: Maybe<Scalars['String']['output']>;
  workLocation?: Maybe<Scalars['String']['output']>;
};

export class AfterRecognitionInput {
  confirmationSignedDate?: InputMaybe<Scalars['DateTime']['input']>;
  contractExtension?: InputMaybe<Scalars['Boolean']['input']>;
  contractSignDate?: InputMaybe<Scalars['DateTime']['input']>;
  contractTemplate?: InputMaybe<RecognitionPathContractTemplateInput>;
  contractTemplateId?: InputMaybe<Scalars['ID']['input']>;
  contractTemplateName?: InputMaybe<Scalars['String']['input']>;
  employerHistory?: InputMaybe<Array<EmployerHistoryEntryInput>>;
  employerId?: InputMaybe<Scalars['ID']['input']>;
  employerName?: InputMaybe<Scalars['String']['input']>;
  employerRegion?: InputMaybe<Scalars['String']['input']>;
  employerZipcode?: InputMaybe<Scalars['String']['input']>;
  employmentRelocationRequirements?: InputMaybe<Array<Scalars['String']['input']>>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  endOfProbationDate?: InputMaybe<Scalars['DateTime']['input']>;
  firstDeputyFirstName?: InputMaybe<Scalars['String']['input']>;
  firstDeputyId?: InputMaybe<Scalars['ID']['input']>;
  firstDeputyLastName?: InputMaybe<Scalars['String']['input']>;
  isTemporaryEmployee?: InputMaybe<Scalars['Boolean']['input']>;
  representativeFirstName?: InputMaybe<Scalars['String']['input']>;
  representativeId?: InputMaybe<Scalars['ID']['input']>;
  representativeLastName?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  typeOfWorkLocation?: InputMaybe<Scalars['String']['input']>;
  workLocation?: InputMaybe<Scalars['String']['input']>;
};

export class AllCollectionsAutoFilterTemplateSyncInput {
  id: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};

export class AlternativeInstiutionNames {
  __typename?: 'AlternativeInstiutionNames';
  language: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export enum AnabinAssetType {
  AnabinAbschluesse = 'AnabinAbschluesse',
  AnabinDegreeTypes = 'AnabinDegreeTypes',
  AnabinInstitutions = 'AnabinInstitutions'
}

export class AnabinByIdInput {
  id: Scalars['String']['input'];
  type: AnabinAssetType;
};

export class AnabinDegreeInstitutionInfoViewDetails {
  __typename?: 'AnabinDegreeInstitutionInfoViewDetails';
  class?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export class AnabinDegreesForInfoView {
  __typename?: 'AnabinDegreesForInfoView';
  description: Array<Scalars['JSONObject']['output']>;
  institutions?: Maybe<Array<AnabinInstiutionDegreeInfoViewDetails>>;
  ratings?: Maybe<Array<EquivalentDegrees>>;
};

export class AnabinInstitutionInfoViewDetails {
  __typename?: 'AnabinInstitutionInfoViewDetails';
  alternativeNames?: Maybe<Array<AlternativeInstiutionNames>>;
  degrees?: Maybe<Array<AnabinDegreeInstitutionInfoViewDetails>>;
  description: Array<Scalars['JSONObject']['output']>;
};

export class AnabinInstiutionDegreeInfoViewDetails {
  __typename?: 'AnabinInstiutionDegreeInfoViewDetails';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export class AnabinMinimalInput {
  country?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  type: AnabinAssetType;
};

export class AnkaadiaRelease {
  __typename?: 'AnkaadiaRelease';
  changes: Scalars['String']['output'];
  date: Scalars['DateTime']['output'];
  isSeen: Scalars['Boolean']['output'];
  version: Scalars['String']['output'];
};

export enum AnonymizationTypeEnum {
  Address = 'Address',
  BirthDate = 'BirthDate',
  Citizenship = 'Citizenship',
  CountryOfOrigin = 'CountryOfOrigin',
  EmailAddress = 'EmailAddress',
  FamilyAndChildren = 'FamilyAndChildren',
  Gender = 'Gender',
  Name = 'Name',
  PhoneNumber = 'PhoneNumber',
  Picture = 'Picture'
}

export class ApplyAutoFilterTemplateOnAllCandidatesInput {
  id: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};

export class Archive {
  __typename?: 'Archive';
  name: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export enum Asset {
  AnabinAbschluesse = 'AnabinAbschluesse',
  AnabinDegreeTypes = 'AnabinDegreeTypes',
  AnabinInstitutions = 'AnabinInstitutions',
  CertificateRecognitionAuthoritiesDe = 'CertificateRecognitionAuthoritiesDE',
  ChamberOfCrafts = 'ChamberOfCrafts',
  CityCoordinatesDe = 'CityCoordinatesDE',
  CountryInformation = 'CountryInformation',
  CountrySpokenLanguages = 'CountrySpokenLanguages',
  DiplomaticMissionsAbroadDe = 'DiplomaticMissionsAbroadDE',
  DiplomaticMissionsAtHomeDe = 'DiplomaticMissionsAtHomeDE',
  EmploymentApprovalAuthoritiesDe = 'EmploymentApprovalAuthoritiesDE',
  EscoOccupationSkills = 'EscoOccupationSkills',
  EscoSkills = 'EscoSkills',
  ImmigrationAuthoritiesDe = 'ImmigrationAuthoritiesDE',
  ProfessionMapping = 'ProfessionMapping',
  RecognitionAuthoritiesAt = 'RecognitionAuthoritiesAT',
  RecognitionAuthoritiesDe = 'RecognitionAuthoritiesDE',
  ZipCodesAt = 'ZipCodesAT',
  ZipCodesDe = 'ZipCodesDE'
}

export class AssetDownload {
  __typename?: 'AssetDownload';
  fileName: Scalars['String']['output'];
  fileUrl: Scalars['String']['output'];
};

export class AssignCollectionInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  assignedId: Scalars['ID']['input'];
  assignedOrganizationId: Scalars['ID']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
};

export class AssignableMember {
  __typename?: 'AssignableMember';
  displayName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  organizationId: Scalars['ID']['output'];
  type: Scalars['String']['output'];
};

export class AttachProcessToActionInput {
  actionId: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
  processAndOperations: Array<ProcessAndOperationInput>;
};

export class AttachableAutoCollection {
  __typename?: 'AttachableAutoCollection';
  _etag?: Maybe<Scalars['String']['output']>;
  assignedCandidateIds?: Maybe<Array<CandidateId>>;
  assignedCandidates?: Maybe<Array<Candidate>>;
  assignedCollectionIds?: Maybe<Array<CollectionId>>;
  assignedCollections?: Maybe<Array<Collection>>;
  assignmentExceptions?: Maybe<Array<CandidateAssignmentException>>;
  autoCollectionSettings?: Maybe<AutoCollectionSettingsForUi>;
  creationDate: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isAttachable: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  organizationId: Scalars['ID']['output'];
  processes?: Maybe<Array<Process>>;
  sharings?: Maybe<Array<Sharing>>;
  targetSize?: Maybe<Scalars['Float']['output']>;
  totalCandidatesFromAssignedCollections?: Maybe<Scalars['Float']['output']>;
  type?: Maybe<CollectionType>;
};

export class AttachableProcess {
  __typename?: 'AttachableProcess';
  collectionId: Scalars['String']['output'];
  collectionName: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isDelegation: Scalars['Boolean']['output'];
  operationId: Scalars['String']['output'];
  organizationId: Scalars['String']['output'];
};

export class AttachableProcessesGetInput {
  actionId: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
};

export class AttachmentTemplateFileType {
  conversionType: TemplateToDocumentTypeConversion;
  templateName: Scalars['String']['input'];
};

export class AttachmentTemplateFileTypeMapping {
  __typename?: 'AttachmentTemplateFileTypeMapping';
  conversionType: TemplateToDocumentTypeConversion;
  templateName: Scalars['String']['output'];
};

export class Audit {
  __typename?: 'Audit';
  creationDate: Scalars['DateTime']['output'];
  userFirstName?: Maybe<Scalars['String']['output']>;
  userLastName?: Maybe<Scalars['String']['output']>;
  userName: Scalars['String']['output'];
  userOrganizationName?: Maybe<Scalars['String']['output']>;
};

export class AuditDefinition {
  __typename?: 'AuditDefinition';
  _etag?: Maybe<Scalars['String']['output']>;
  definitionDescription?: Maybe<Scalars['String']['output']>;
  definitionName: Scalars['String']['output'];
  id?: Maybe<Scalars['ID']['output']>;
  organizationId: Scalars['ID']['output'];
  tree?: Maybe<Array<AuditDefinitionTreeNode>>;
};

export class AuditDefinitionCreateInput {
  definitionDescription?: InputMaybe<Scalars['String']['input']>;
  definitionName: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  tree?: InputMaybe<Array<TreeNodeInput>>;
};

export class AuditDefinitionDeleteInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
};

export class AuditDefinitionGetInput {
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
};

export class AuditDefinitionListInput {
  organizationId: Scalars['ID']['input'];
};

export class AuditDefinitionTreeNode {
  __typename?: 'AuditDefinitionTreeNode';
  children?: Maybe<Array<AuditDefinitionTreeNode>>;
  description?: Maybe<Scalars['String']['output']>;
  descriptionOwnRecruitment?: Maybe<Scalars['String']['output']>;
  filesAreMandatory?: Maybe<Scalars['Boolean']['output']>;
  forEmployer?: Maybe<Scalars['Boolean']['output']>;
  forPSA?: Maybe<Scalars['Boolean']['output']>;
  helptext?: Maybe<Scalars['String']['output']>;
  helptextProof?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  level: Scalars['Float']['output'];
  name?: Maybe<Scalars['String']['output']>;
  reCertificationOnly?: Maybe<Scalars['Boolean']['output']>;
};

export class AuditDefinitionUpdateInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  definitionDescription?: InputMaybe<Scalars['String']['input']>;
  definitionName: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
  tree?: InputMaybe<Array<TreeNodeInput>>;
};

export class AuditDefinitionUpdateMetaInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  definitionDescription?: InputMaybe<Scalars['String']['input']>;
  definitionName: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
};

export class AuditFile {
  __typename?: 'AuditFile';
  containerName: Scalars['String']['output'];
  fileBlob: Scalars['String']['output'];
  fileName: Scalars['String']['output'];
  fileSize: Scalars['Float']['output'];
  fileType: Scalars['String']['output'];
};

export class AuditFileInput {
  containerName: Scalars['String']['input'];
  fileBlob: Scalars['String']['input'];
  fileName: Scalars['String']['input'];
  fileSize: Scalars['Float']['input'];
  fileType: Scalars['String']['input'];
};

export class AuditGetInput {
  entityId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};

export enum AuditTaskModeEnum {
  AdditionalInformationAfterFinalVotum = 'AdditionalInformationAfterFinalVotum',
  AdditionalInformationAuditAfterFinalVotum = 'AdditionalInformationAuditAfterFinalVotum',
  AuditAdditionalInformation = 'AuditAdditionalInformation',
  EnterAdditionalInformation = 'EnterAdditionalInformation',
  GatherAuditInformation = 'GatherAuditInformation',
  GatherFinalVotum = 'GatherFinalVotum',
  None = 'None',
  PerformAudit = 'PerformAudit',
  ViewConfidante = 'ViewCONFIDANTE',
  ViewExaminee = 'ViewExaminee',
  ViewQualityComittee = 'ViewQualityComittee'
}

export class AugmentedAfterRecognition {
  __typename?: 'AugmentedAfterRecognition';
  confirmationSignedDate?: Maybe<Scalars['DateTime']['output']>;
  contractExtension?: Maybe<Scalars['Boolean']['output']>;
  contractSignDate?: Maybe<Scalars['DateTime']['output']>;
  contractTemplate?: Maybe<RecognitionPathContractTemplate>;
  contractTemplateId?: Maybe<Scalars['ID']['output']>;
  contractTemplateName?: Maybe<Scalars['String']['output']>;
  employerHistory?: Maybe<Array<EmployerHistoryEntry>>;
  employerId?: Maybe<Scalars['ID']['output']>;
  employerName?: Maybe<Scalars['String']['output']>;
  employerRegion?: Maybe<Scalars['String']['output']>;
  employerZipcode?: Maybe<Scalars['String']['output']>;
  employmentRelocationRequirements?: Maybe<Array<Scalars['String']['output']>>;
  endDate?: Maybe<Scalars['DateTime']['output']>;
  endOfProbationDate?: Maybe<Scalars['DateTime']['output']>;
  firstContractExtension?: Maybe<SetInfo>;
  firstDeputyFirstName?: Maybe<Scalars['String']['output']>;
  firstDeputyId?: Maybe<Scalars['ID']['output']>;
  firstDeputyLastName?: Maybe<Scalars['String']['output']>;
  isTemporaryEmployee?: Maybe<Scalars['Boolean']['output']>;
  representativeFirstName?: Maybe<Scalars['String']['output']>;
  representativeId?: Maybe<Scalars['ID']['output']>;
  representativeLastName?: Maybe<Scalars['String']['output']>;
  secondContractExtension?: Maybe<SetInfo>;
  startDate?: Maybe<Scalars['DateTime']['output']>;
  typeOfWorkLocation?: Maybe<Scalars['String']['output']>;
  workLocation?: Maybe<Scalars['String']['output']>;
};

export class AugmentedAfterRecognitionInput {
  confirmationSignedDate?: InputMaybe<Scalars['DateTime']['input']>;
  contractExtension?: InputMaybe<Scalars['Boolean']['input']>;
  contractSignDate?: InputMaybe<Scalars['DateTime']['input']>;
  contractTemplate?: InputMaybe<RecognitionPathContractTemplateInput>;
  contractTemplateId?: InputMaybe<Scalars['ID']['input']>;
  contractTemplateName?: InputMaybe<Scalars['String']['input']>;
  employerHistory?: InputMaybe<Array<EmployerHistoryEntryInput>>;
  employerId?: InputMaybe<Scalars['ID']['input']>;
  employerName?: InputMaybe<Scalars['String']['input']>;
  employerRegion?: InputMaybe<Scalars['String']['input']>;
  employerZipcode?: InputMaybe<Scalars['String']['input']>;
  employmentRelocationRequirements?: InputMaybe<Array<Scalars['String']['input']>>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  endOfProbationDate?: InputMaybe<Scalars['DateTime']['input']>;
  firstContractExtension?: InputMaybe<SetInfoInput>;
  firstDeputyFirstName?: InputMaybe<Scalars['String']['input']>;
  firstDeputyId?: InputMaybe<Scalars['ID']['input']>;
  firstDeputyLastName?: InputMaybe<Scalars['String']['input']>;
  isTemporaryEmployee?: InputMaybe<Scalars['Boolean']['input']>;
  representativeFirstName?: InputMaybe<Scalars['String']['input']>;
  representativeId?: InputMaybe<Scalars['ID']['input']>;
  representativeLastName?: InputMaybe<Scalars['String']['input']>;
  secondContractExtension?: InputMaybe<SetInfoInput>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  typeOfWorkLocation?: InputMaybe<Scalars['String']['input']>;
  workLocation?: InputMaybe<Scalars['String']['input']>;
};

export class AugmentedBeforeRecognition {
  __typename?: 'AugmentedBeforeRecognition';
  contractExtension?: Maybe<Scalars['Boolean']['output']>;
  contractSignedByAllParties?: Maybe<SetInfo>;
  contractSignedByOneParty?: Maybe<SetInfo>;
  contractTemplate?: Maybe<RecognitionPathContractTemplate>;
  contractTemplateId?: Maybe<Scalars['ID']['output']>;
  contractTemplateName?: Maybe<Scalars['String']['output']>;
  employerHistory?: Maybe<Array<EmployerHistoryEntry>>;
  employerId?: Maybe<Scalars['ID']['output']>;
  employerName?: Maybe<Scalars['String']['output']>;
  employerRegion?: Maybe<Scalars['String']['output']>;
  employerZipcode?: Maybe<Scalars['String']['output']>;
  employmentOffer?: Maybe<SetInfo>;
  employmentRelocationRequirements?: Maybe<Array<Scalars['String']['output']>>;
  endOfProbationDate?: Maybe<Scalars['DateTime']['output']>;
  firstContractExtension?: Maybe<SetInfo>;
  firstDeputyFirstName?: Maybe<Scalars['String']['output']>;
  firstDeputyId?: Maybe<Scalars['ID']['output']>;
  firstDeputyLastName?: Maybe<Scalars['String']['output']>;
  isTemporaryEmployee?: Maybe<Scalars['Boolean']['output']>;
  representativeFirstName?: Maybe<Scalars['String']['output']>;
  representativeId?: Maybe<Scalars['ID']['output']>;
  representativeLastName?: Maybe<Scalars['String']['output']>;
  secondContractExtension?: Maybe<SetInfo>;
  typeOfWorkLocation?: Maybe<Scalars['String']['output']>;
  workLocation?: Maybe<Scalars['String']['output']>;
};

export class AugmentedBeforeRecognitionInput {
  contractExtension?: InputMaybe<Scalars['Boolean']['input']>;
  contractSignedByAllParties?: InputMaybe<SetInfoInput>;
  contractSignedByOneParty?: InputMaybe<SetInfoInput>;
  contractTemplate?: InputMaybe<RecognitionPathContractTemplateInput>;
  contractTemplateId?: InputMaybe<Scalars['ID']['input']>;
  contractTemplateName?: InputMaybe<Scalars['String']['input']>;
  employerHistory?: InputMaybe<Array<EmployerHistoryEntryInput>>;
  employerId?: InputMaybe<Scalars['ID']['input']>;
  employerName?: InputMaybe<Scalars['String']['input']>;
  employerRegion?: InputMaybe<Scalars['String']['input']>;
  employerZipcode?: InputMaybe<Scalars['String']['input']>;
  employmentOffer?: InputMaybe<SetInfoInput>;
  employmentRelocationRequirements?: InputMaybe<Array<Scalars['String']['input']>>;
  endOfProbationDate?: InputMaybe<Scalars['DateTime']['input']>;
  firstContractExtension?: InputMaybe<SetInfoInput>;
  firstDeputyFirstName?: InputMaybe<Scalars['String']['input']>;
  firstDeputyId?: InputMaybe<Scalars['ID']['input']>;
  firstDeputyLastName?: InputMaybe<Scalars['String']['input']>;
  isTemporaryEmployee?: InputMaybe<Scalars['Boolean']['input']>;
  representativeFirstName?: InputMaybe<Scalars['String']['input']>;
  representativeId?: InputMaybe<Scalars['ID']['input']>;
  representativeLastName?: InputMaybe<Scalars['String']['input']>;
  secondContractExtension?: InputMaybe<SetInfoInput>;
  typeOfWorkLocation?: InputMaybe<Scalars['String']['input']>;
  workLocation?: InputMaybe<Scalars['String']['input']>;
};

export class AugmentedEmploymentRelationship {
  __typename?: 'AugmentedEmploymentRelationship';
  afterRecognition?: Maybe<AugmentedAfterRecognition>;
  beforeRecognition?: Maybe<AugmentedBeforeRecognition>;
  hasDifferentRelationships?: Maybe<Scalars['Boolean']['output']>;
};

export class AugmentedEmploymentRelationshipInput {
  afterRecognition?: InputMaybe<AugmentedAfterRecognitionInput>;
  beforeRecognition?: InputMaybe<AugmentedBeforeRecognitionInput>;
  hasDifferentRelationships?: InputMaybe<Scalars['Boolean']['input']>;
};

export class AugmentedHousing {
  __typename?: 'AugmentedHousing';
  additionalCosts?: Maybe<Scalars['Float']['output']>;
  address?: Maybe<Scalars['String']['output']>;
  cancellationOfContractSignDate?: Maybe<Scalars['DateTime']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  coldRent?: Maybe<Scalars['Float']['output']>;
  comments?: Maybe<Scalars['String']['output']>;
  contract?: Maybe<SetInfo>;
  country?: Maybe<Scalars['String']['output']>;
  federalState?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  keyHandoverDateTime?: Maybe<DateWithTime>;
  movingInDate?: Maybe<Scalars['DateTime']['output']>;
  movingOutDate?: Maybe<Scalars['DateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nameOfContactPerson?: Maybe<Scalars['String']['output']>;
  operatingCosts?: Maybe<Scalars['Float']['output']>;
  phoneOfContactPerson?: Maybe<Scalars['String']['output']>;
  readinessForOccupancyDate?: Maybe<Scalars['DateTime']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export class AugmentedHousingAcquisition {
  __typename?: 'AugmentedHousingAcquisition';
  firstDeputyFirstName?: Maybe<Scalars['String']['output']>;
  firstDeputyId?: Maybe<Scalars['ID']['output']>;
  firstDeputyLastName?: Maybe<Scalars['String']['output']>;
  housings?: Maybe<Array<AugmentedHousing>>;
  representativeFirstName?: Maybe<Scalars['String']['output']>;
  representativeId?: Maybe<Scalars['ID']['output']>;
  representativeLastName?: Maybe<Scalars['String']['output']>;
  responsibleOrganizationId?: Maybe<Scalars['ID']['output']>;
  responsibleRoleHousingAcquisition?: Maybe<Scalars['String']['output']>;
};

export class AugmentedHousingAcquisitionInput {
  firstDeputyFirstName?: InputMaybe<Scalars['String']['input']>;
  firstDeputyId?: InputMaybe<Scalars['ID']['input']>;
  firstDeputyLastName?: InputMaybe<Scalars['String']['input']>;
  housings?: InputMaybe<Array<AugmentedHousingInput>>;
  representativeFirstName?: InputMaybe<Scalars['String']['input']>;
  representativeId?: InputMaybe<Scalars['ID']['input']>;
  representativeLastName?: InputMaybe<Scalars['String']['input']>;
  responsibleOrganizationId?: InputMaybe<Scalars['ID']['input']>;
  responsibleRoleHousingAcquisition?: InputMaybe<Scalars['String']['input']>;
};

export class AugmentedHousingInput {
  additionalCosts?: InputMaybe<Scalars['Float']['input']>;
  address?: InputMaybe<Scalars['String']['input']>;
  cancellationOfContractSignDate?: InputMaybe<Scalars['DateTime']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  coldRent?: InputMaybe<Scalars['Float']['input']>;
  comments?: InputMaybe<Scalars['String']['input']>;
  contract?: InputMaybe<SetInfoInput>;
  country?: InputMaybe<Scalars['String']['input']>;
  federalState?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  keyHandoverDateTime?: InputMaybe<DateWithTimeInput>;
  movingInDate?: InputMaybe<Scalars['DateTime']['input']>;
  movingOutDate?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nameOfContactPerson?: InputMaybe<Scalars['String']['input']>;
  operatingCosts?: InputMaybe<Scalars['Float']['input']>;
  phoneOfContactPerson?: InputMaybe<Scalars['String']['input']>;
  readinessForOccupancyDate?: InputMaybe<Scalars['DateTime']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export class AugmentedLaborMarketAdmissionCandidateDataDe {
  __typename?: 'AugmentedLaborMarketAdmissionCandidateDataDE';
  employmentApproval?: Maybe<SetInfo>;
  employmentApprovalAuthority?: Maybe<Scalars['String']['output']>;
  employmentApprovalRequestDate?: Maybe<Scalars['DateTime']['output']>;
  employmentApprovalRequested?: Maybe<Scalars['Boolean']['output']>;
  responsibleRoleLaborMarket?: Maybe<Scalars['String']['output']>;
};

export class AugmentedLaborMarketAdmissionCandidateDataDeInput {
  employmentApproval?: InputMaybe<SetInfoInput>;
  employmentApprovalAuthority?: InputMaybe<Scalars['String']['input']>;
  employmentApprovalRequestDate?: InputMaybe<Scalars['DateTime']['input']>;
  employmentApprovalRequested?: InputMaybe<Scalars['Boolean']['input']>;
  responsibleRoleLaborMarket?: InputMaybe<Scalars['String']['input']>;
};

export class AugmentedMigrationCandidateDataAt {
  __typename?: 'AugmentedMigrationCandidateDataAT';
  employmentRelationship?: Maybe<AugmentedEmploymentRelationship>;
  healthProfessionsRegister?: Maybe<HealthProfessionsRegisterCandidateDataAt>;
  qualificationMeasure?: Maybe<QualificationMeasureCandidateDataAt>;
  recognitionPath?: Maybe<AugmentedRecognitionPathEntryAt>;
  residence?: Maybe<ResidenceCandidateDataAt>;
  visa?: Maybe<VisaCandidateDataAt>;
  vocationalSchool?: Maybe<AugmentedVocationalSchoolCandidateDataAt>;
};

export class AugmentedMigrationCandidateDataAtInput {
  employmentRelationship?: InputMaybe<AugmentedEmploymentRelationshipInput>;
  healthProfessionsRegister?: InputMaybe<HealthProfessionsRegisterCandidateDataAtInput>;
  qualificationMeasure?: InputMaybe<QualificationMeasureCandidateDataAtInput>;
  recognitionPath?: InputMaybe<AugmentedRecognitionPathEntryAtInput>;
  residence?: InputMaybe<ResidenceCandidateDataAtInput>;
  visa?: InputMaybe<VisaCandidateDataAtInput>;
  vocationalSchool?: InputMaybe<AugmentedVocationalSchoolCandidateDataAtInput>;
};

export class AugmentedMigrationCandidateDataContainer {
  __typename?: 'AugmentedMigrationCandidateDataContainer';
  at?: Maybe<AugmentedMigrationCandidateDataAt>;
  de?: Maybe<AugmentedMigrationCandidateDataDe>;
  housingAcquisition?: Maybe<AugmentedHousingAcquisition>;
};

export class AugmentedMigrationCandidateDataContainerInput {
  at?: InputMaybe<AugmentedMigrationCandidateDataAtInput>;
  de?: InputMaybe<AugmentedMigrationCandidateDataDeInput>;
  housingAcquisition?: InputMaybe<AugmentedHousingAcquisitionInput>;
};

export class AugmentedMigrationCandidateDataDe {
  __typename?: 'AugmentedMigrationCandidateDataDE';
  employmentRelationship?: Maybe<AugmentedEmploymentRelationship>;
  laborMarketAdmission?: Maybe<AugmentedLaborMarketAdmissionCandidateDataDe>;
  qualificationEvaluation?: Maybe<AugmentedQualificationEvaluationDataDe>;
  qualificationMeasure?: Maybe<AugmentedQualificationMeasureCandidateDataDe>;
  recognitionPath?: Maybe<AugmentedRecognitionPathCandidateDataDe>;
  residence?: Maybe<AugmentedResidenceCandidateDataDe>;
  visa?: Maybe<AugmentedVisaCandidateDataDe>;
  vocationalSchool?: Maybe<AugmentedVocationalSchoolCandidateDataDe>;
};

export class AugmentedMigrationCandidateDataDeInput {
  employmentRelationship?: InputMaybe<AugmentedEmploymentRelationshipInput>;
  laborMarketAdmission?: InputMaybe<AugmentedLaborMarketAdmissionCandidateDataDeInput>;
  qualificationEvaluation?: InputMaybe<AugmentedQualificationEvaluationDataDeInput>;
  qualificationMeasure?: InputMaybe<AugmentedQualificationMeasureCandidateDataDeInput>;
  recognitionPath?: InputMaybe<AugmentedRecognitionPathCandidateDataDeInput>;
  residence?: InputMaybe<AugmentedResidenceCandidateDataDeInput>;
  visa?: InputMaybe<AugmentedVisaCandidateDataDeInput>;
  vocationalSchool?: InputMaybe<AugmentedVocationalSchoolCandidateDataDeInput>;
};

export class AugmentedQualificationEvaluationDataDe {
  __typename?: 'AugmentedQualificationEvaluationDataDE';
  noQualificationEvaluation?: Maybe<Scalars['Boolean']['output']>;
  qualificationEvaluationFirstDeputyFirstName?: Maybe<Scalars['String']['output']>;
  qualificationEvaluationFirstDeputyId?: Maybe<Scalars['ID']['output']>;
  qualificationEvaluationFirstDeputyLastName?: Maybe<Scalars['String']['output']>;
  qualificationEvaluationRepresentativeFirstName?: Maybe<Scalars['String']['output']>;
  qualificationEvaluationRepresentativeId?: Maybe<Scalars['ID']['output']>;
  qualificationEvaluationRepresentativeLastName?: Maybe<Scalars['String']['output']>;
  qualificationEvaluations?: Maybe<Array<AugmentedQualificationEvaluationsDe>>;
  responsibleQualificationEvaluationOrganizationId?: Maybe<Scalars['ID']['output']>;
  responsibleRoleQualificationEvaluation?: Maybe<Scalars['String']['output']>;
  responsibleRoleQualificationEvaluationPayment?: Maybe<Scalars['String']['output']>;
};

export class AugmentedQualificationEvaluationDataDeInput {
  noQualificationEvaluation?: InputMaybe<Scalars['Boolean']['input']>;
  qualificationEvaluationFirstDeputyFirstName?: InputMaybe<Scalars['String']['input']>;
  qualificationEvaluationFirstDeputyId?: InputMaybe<Scalars['ID']['input']>;
  qualificationEvaluationFirstDeputyLastName?: InputMaybe<Scalars['String']['input']>;
  qualificationEvaluationRepresentativeFirstName?: InputMaybe<Scalars['String']['input']>;
  qualificationEvaluationRepresentativeId?: InputMaybe<Scalars['ID']['input']>;
  qualificationEvaluationRepresentativeLastName?: InputMaybe<Scalars['String']['input']>;
  qualificationEvaluations?: InputMaybe<Array<AugmentedQualificationEvaluationsDeInput>>;
  responsibleQualificationEvaluationOrganizationId?: InputMaybe<Scalars['ID']['input']>;
  responsibleRoleQualificationEvaluation?: InputMaybe<Scalars['String']['input']>;
  responsibleRoleQualificationEvaluationPayment?: InputMaybe<Scalars['String']['input']>;
};

export class AugmentedQualificationEvaluationsDe {
  __typename?: 'AugmentedQualificationEvaluationsDE';
  evaluatedQualification?: Maybe<Scalars['String']['output']>;
  evaluationByCentralDepartment?: Maybe<SetInfo>;
  evaluationByFederalStateAuthority?: Maybe<SetInfo>;
  evaluationNoticeKind?: Maybe<Scalars['String']['output']>;
  evaluationRejection?: Maybe<SetInfo>;
  evaluationRequest?: Maybe<SetInfo>;
  federalState?: Maybe<Scalars['String']['output']>;
  fileNumber?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  responsibleAuthorityForQualification?: Maybe<Scalars['String']['output']>;
  responsibleAuthorityForQualificationInOrigin?: Maybe<Scalars['String']['output']>;
};

export class AugmentedQualificationEvaluationsDeInput {
  evaluatedQualification?: InputMaybe<Scalars['String']['input']>;
  evaluationByCentralDepartment?: InputMaybe<SetInfoInput>;
  evaluationByFederalStateAuthority?: InputMaybe<SetInfoInput>;
  evaluationNoticeKind?: InputMaybe<Scalars['String']['input']>;
  evaluationRejection?: InputMaybe<SetInfoInput>;
  evaluationRequest?: InputMaybe<SetInfoInput>;
  federalState?: InputMaybe<Scalars['String']['input']>;
  fileNumber?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  responsibleAuthorityForQualification?: InputMaybe<Scalars['String']['input']>;
  responsibleAuthorityForQualificationInOrigin?: InputMaybe<Scalars['String']['input']>;
};

export class AugmentedQualificationMeasureCandidateDataDe {
  __typename?: 'AugmentedQualificationMeasureCandidateDataDE';
  careSituations?: Maybe<Scalars['Float']['output']>;
  courseNumber?: Maybe<Scalars['String']['output']>;
  educationVoucher?: Maybe<SetInfo>;
  educationVoucherFileNumber?: Maybe<Scalars['String']['output']>;
  educationVoucherFirstDeputyFirstName?: Maybe<Scalars['String']['output']>;
  educationVoucherFirstDeputyId?: Maybe<Scalars['ID']['output']>;
  educationVoucherFirstDeputyLastName?: Maybe<Scalars['String']['output']>;
  educationVoucherRepresentativeFirstName?: Maybe<Scalars['String']['output']>;
  educationVoucherRepresentativeId?: Maybe<Scalars['ID']['output']>;
  educationVoucherRepresentativeLastName?: Maybe<Scalars['String']['output']>;
  educationVoucherRequestDate?: Maybe<Scalars['DateTime']['output']>;
  educationVoucherRequested?: Maybe<Scalars['Boolean']['output']>;
  endOfFurtherEducation?: Maybe<Scalars['DateTime']['output']>;
  noDetailedEquivalence?: Maybe<Scalars['Boolean']['output']>;
  practiceExamDate?: Maybe<Scalars['DateTime']['output']>;
  practiceHours?: Maybe<Scalars['Float']['output']>;
  practiceInstitution?: Maybe<Scalars['String']['output']>;
  qualificationExams?: Maybe<Array<QualificationExam>>;
  qualificationIsSend?: Maybe<Scalars['Boolean']['output']>;
  qualificationIsSendDate?: Maybe<Scalars['DateTime']['output']>;
  qualificationMeasure?: Maybe<Scalars['String']['output']>;
  qualificationMeasureFileNumber?: Maybe<Scalars['String']['output']>;
  qualificationModules?: Maybe<Array<QualificationModule>>;
  qualificationParticipation?: Maybe<SetInfo>;
  qualificationType?: Maybe<Scalars['String']['output']>;
  responsibleEducationVoucherOrganizationId?: Maybe<Scalars['ID']['output']>;
  responsibleRoleEducationVoucher?: Maybe<Scalars['String']['output']>;
  responsibleRoleQualification?: Maybe<Scalars['String']['output']>;
  startOfFurtherEducation?: Maybe<Scalars['DateTime']['output']>;
  theoryExamDate?: Maybe<Scalars['DateTime']['output']>;
  theoryHours?: Maybe<Scalars['Float']['output']>;
  theoryInstitution?: Maybe<Scalars['String']['output']>;
  totalHours?: Maybe<Scalars['Float']['output']>;
};

export class AugmentedQualificationMeasureCandidateDataDeInput {
  careSituations?: InputMaybe<Scalars['Float']['input']>;
  courseNumber?: InputMaybe<Scalars['String']['input']>;
  educationVoucher?: InputMaybe<SetInfoInput>;
  educationVoucherFileNumber?: InputMaybe<Scalars['String']['input']>;
  educationVoucherFirstDeputyFirstName?: InputMaybe<Scalars['String']['input']>;
  educationVoucherFirstDeputyId?: InputMaybe<Scalars['ID']['input']>;
  educationVoucherFirstDeputyLastName?: InputMaybe<Scalars['String']['input']>;
  educationVoucherRepresentativeFirstName?: InputMaybe<Scalars['String']['input']>;
  educationVoucherRepresentativeId?: InputMaybe<Scalars['ID']['input']>;
  educationVoucherRepresentativeLastName?: InputMaybe<Scalars['String']['input']>;
  educationVoucherRequestDate?: InputMaybe<Scalars['DateTime']['input']>;
  educationVoucherRequested?: InputMaybe<Scalars['Boolean']['input']>;
  endOfFurtherEducation?: InputMaybe<Scalars['DateTime']['input']>;
  noDetailedEquivalence?: InputMaybe<Scalars['Boolean']['input']>;
  practiceExamDate?: InputMaybe<Scalars['DateTime']['input']>;
  practiceHours?: InputMaybe<Scalars['Float']['input']>;
  practiceInstitution?: InputMaybe<Scalars['String']['input']>;
  qualificationExams?: InputMaybe<Array<QualificationExamInput>>;
  qualificationIsSend?: InputMaybe<Scalars['Boolean']['input']>;
  qualificationIsSendDate?: InputMaybe<Scalars['DateTime']['input']>;
  qualificationMeasure?: InputMaybe<Scalars['String']['input']>;
  qualificationMeasureFileNumber?: InputMaybe<Scalars['String']['input']>;
  qualificationModules?: InputMaybe<Array<QualificationModuleInput>>;
  qualificationParticipation?: InputMaybe<SetInfoInput>;
  qualificationType?: InputMaybe<Scalars['String']['input']>;
  responsibleEducationVoucherOrganizationId?: InputMaybe<Scalars['ID']['input']>;
  responsibleRoleEducationVoucher?: InputMaybe<Scalars['String']['input']>;
  responsibleRoleQualification?: InputMaybe<Scalars['String']['input']>;
  startOfFurtherEducation?: InputMaybe<Scalars['DateTime']['input']>;
  theoryExamDate?: InputMaybe<Scalars['DateTime']['input']>;
  theoryHours?: InputMaybe<Scalars['Float']['input']>;
  theoryInstitution?: InputMaybe<Scalars['String']['input']>;
  totalHours?: InputMaybe<Scalars['Float']['input']>;
};

export class AugmentedRecognitionPathCandidateDataDe {
  __typename?: 'AugmentedRecognitionPathCandidateDataDE';
  alternative?: Maybe<AugmentedRecognitionPathEntryDe>;
  current?: Maybe<AugmentedRecognitionPathEntryDe>;
};

export class AugmentedRecognitionPathCandidateDataDeInput {
  alternative?: InputMaybe<AugmentedRecognitionPathEntryDeInput>;
  current?: InputMaybe<AugmentedRecognitionPathEntryDeInput>;
};

export class AugmentedRecognitionPathEntryAt {
  __typename?: 'AugmentedRecognitionPathEntryAT';
  federalState?: Maybe<Scalars['String']['output']>;
  fileNumber?: Maybe<Scalars['String']['output']>;
  legalBasis?: Maybe<Scalars['String']['output']>;
  pathOfRecognition?: Maybe<Scalars['String']['output']>;
  psaFirstDeputyFirstName?: Maybe<Scalars['String']['output']>;
  psaFirstDeputyId?: Maybe<Scalars['ID']['output']>;
  psaFirstDeputyLastName?: Maybe<Scalars['String']['output']>;
  psaRepresentativeFirstName?: Maybe<Scalars['String']['output']>;
  psaRepresentativeId?: Maybe<Scalars['ID']['output']>;
  psaRepresentativeLastName?: Maybe<Scalars['String']['output']>;
  recognitionApplication?: Maybe<SetInfo>;
  recognitionAuthority?: Maybe<Scalars['String']['output']>;
  recognitionReceiveDate?: Maybe<Scalars['DateTime']['output']>;
  recognitionReceived?: Maybe<Scalars['Boolean']['output']>;
  recognitionStartDate?: Maybe<Scalars['DateTime']['output']>;
  recognitionStarted?: Maybe<Scalars['Boolean']['output']>;
  responsibleRecognitionOrganizationId?: Maybe<Scalars['ID']['output']>;
  responsibleRoleRecognition?: Maybe<Scalars['String']['output']>;
  targetRecognition?: Maybe<Scalars['String']['output']>;
};

export class AugmentedRecognitionPathEntryAtInput {
  federalState?: InputMaybe<Scalars['String']['input']>;
  fileNumber?: InputMaybe<Scalars['String']['input']>;
  legalBasis?: InputMaybe<Scalars['String']['input']>;
  pathOfRecognition?: InputMaybe<Scalars['String']['input']>;
  psaFirstDeputyFirstName?: InputMaybe<Scalars['String']['input']>;
  psaFirstDeputyId?: InputMaybe<Scalars['ID']['input']>;
  psaFirstDeputyLastName?: InputMaybe<Scalars['String']['input']>;
  psaRepresentativeFirstName?: InputMaybe<Scalars['String']['input']>;
  psaRepresentativeId?: InputMaybe<Scalars['ID']['input']>;
  psaRepresentativeLastName?: InputMaybe<Scalars['String']['input']>;
  recognitionApplication?: InputMaybe<SetInfoInput>;
  recognitionAuthority?: InputMaybe<Scalars['String']['input']>;
  recognitionReceiveDate?: InputMaybe<Scalars['DateTime']['input']>;
  recognitionReceived?: InputMaybe<Scalars['Boolean']['input']>;
  recognitionStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  recognitionStarted?: InputMaybe<Scalars['Boolean']['input']>;
  responsibleRecognitionOrganizationId?: InputMaybe<Scalars['ID']['input']>;
  responsibleRoleRecognition?: InputMaybe<Scalars['String']['input']>;
  targetRecognition?: InputMaybe<Scalars['String']['input']>;
};

export class AugmentedRecognitionPathEntryDe {
  __typename?: 'AugmentedRecognitionPathEntryDE';
  certificate?: Maybe<SetInfo>;
  certificateRequestDate?: Maybe<Scalars['DateTime']['output']>;
  certificateRequested?: Maybe<Scalars['Boolean']['output']>;
  chamberOfCrafts?: Maybe<Scalars['String']['output']>;
  deficitNote?: Maybe<SetInfo>;
  equivalenceTest?: Maybe<Scalars['String']['output']>;
  federalState?: Maybe<Scalars['String']['output']>;
  fileNumber?: Maybe<Scalars['String']['output']>;
  fileNumberAcceleratedProcedure?: Maybe<Scalars['String']['output']>;
  immigrationApplication?: Maybe<SetInfo>;
  immigrationAuthority?: Maybe<Scalars['String']['output']>;
  isCurrent?: Maybe<Scalars['Boolean']['output']>;
  pathOfRecognition?: Maybe<Scalars['String']['output']>;
  psaFirstDeputyFirstName?: Maybe<Scalars['String']['output']>;
  psaFirstDeputyId?: Maybe<Scalars['ID']['output']>;
  psaFirstDeputyLastName?: Maybe<Scalars['String']['output']>;
  psaRepresentativeFirstName?: Maybe<Scalars['String']['output']>;
  psaRepresentativeId?: Maybe<Scalars['ID']['output']>;
  psaRepresentativeLastName?: Maybe<Scalars['String']['output']>;
  recognition?: Maybe<SetInfo>;
  recognitionApplication?: Maybe<SetInfo>;
  recognitionAuthority?: Maybe<Scalars['String']['output']>;
  recognitionCenter?: Maybe<Scalars['String']['output']>;
  recognitionNoticeKind?: Maybe<Scalars['String']['output']>;
  recognitionPartnership?: Maybe<Scalars['Boolean']['output']>;
  recognitionPaymentRole?: Maybe<Scalars['String']['output']>;
  recognitionPaymentRoleAccelerated?: Maybe<Scalars['String']['output']>;
  recognitionRejection?: Maybe<SetInfo>;
  recognitionStartDate?: Maybe<Scalars['DateTime']['output']>;
  recognitionStarted?: Maybe<Scalars['Boolean']['output']>;
  recognitionType?: Maybe<Scalars['String']['output']>;
  responsibleRecognitionOrganizationId?: Maybe<Scalars['ID']['output']>;
  responsibleRoleRecognition?: Maybe<Scalars['String']['output']>;
};

export class AugmentedRecognitionPathEntryDeInput {
  certificate?: InputMaybe<SetInfoInput>;
  certificateRequestDate?: InputMaybe<Scalars['DateTime']['input']>;
  certificateRequested?: InputMaybe<Scalars['Boolean']['input']>;
  chamberOfCrafts?: InputMaybe<Scalars['String']['input']>;
  deficitNote?: InputMaybe<SetInfoInput>;
  equivalenceTest?: InputMaybe<Scalars['String']['input']>;
  federalState?: InputMaybe<Scalars['String']['input']>;
  fileNumber?: InputMaybe<Scalars['String']['input']>;
  fileNumberAcceleratedProcedure?: InputMaybe<Scalars['String']['input']>;
  immigrationApplication?: InputMaybe<SetInfoInput>;
  immigrationAuthority?: InputMaybe<Scalars['String']['input']>;
  isCurrent?: InputMaybe<Scalars['Boolean']['input']>;
  pathOfRecognition?: InputMaybe<Scalars['String']['input']>;
  psaFirstDeputyFirstName?: InputMaybe<Scalars['String']['input']>;
  psaFirstDeputyId?: InputMaybe<Scalars['ID']['input']>;
  psaFirstDeputyLastName?: InputMaybe<Scalars['String']['input']>;
  psaRepresentativeFirstName?: InputMaybe<Scalars['String']['input']>;
  psaRepresentativeId?: InputMaybe<Scalars['ID']['input']>;
  psaRepresentativeLastName?: InputMaybe<Scalars['String']['input']>;
  recognition?: InputMaybe<SetInfoInput>;
  recognitionApplication?: InputMaybe<SetInfoInput>;
  recognitionAuthority?: InputMaybe<Scalars['String']['input']>;
  recognitionCenter?: InputMaybe<Scalars['String']['input']>;
  recognitionNoticeKind?: InputMaybe<Scalars['String']['input']>;
  recognitionPartnership?: InputMaybe<Scalars['Boolean']['input']>;
  recognitionPaymentRole?: InputMaybe<Scalars['String']['input']>;
  recognitionPaymentRoleAccelerated?: InputMaybe<Scalars['String']['input']>;
  recognitionRejection?: InputMaybe<SetInfoInput>;
  recognitionStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  recognitionStarted?: InputMaybe<Scalars['Boolean']['input']>;
  recognitionType?: InputMaybe<Scalars['String']['input']>;
  responsibleRecognitionOrganizationId?: InputMaybe<Scalars['ID']['input']>;
  responsibleRoleRecognition?: InputMaybe<Scalars['String']['input']>;
};

export class AugmentedResidenceCandidateDataDe {
  __typename?: 'AugmentedResidenceCandidateDataDE';
  appointmentHealthDepartmentDate?: Maybe<DateWithTime>;
  appointmentHealthDepartmentKept?: Maybe<Scalars['Boolean']['output']>;
  bankAppointmentDate?: Maybe<DateWithTime>;
  certificateOfConduct?: Maybe<Array<Scalars['String']['output']>>;
  certificateOfConductRequested?: Maybe<Scalars['Boolean']['output']>;
  companyDoctorAppointmentDate?: Maybe<DateWithTime>;
  medicalExaminationAppointmentDate?: Maybe<DateWithTime>;
  personalOnboardingDateTime?: Maybe<DateWithTime>;
  policeRecordAppointmentDate?: Maybe<DateWithTime>;
  recognitionMedicalCertificate?: Maybe<Scalars['Boolean']['output']>;
  registrationAuthority?: Maybe<Scalars['String']['output']>;
  registrationAuthorityAppointmentNumber?: Maybe<Scalars['String']['output']>;
  registrationOfficeDate?: Maybe<DateWithTime>;
  residenceBank?: Maybe<Scalars['String']['output']>;
  residenceBankContactPartner?: Maybe<Scalars['String']['output']>;
  residenceFirstDeputyFirstName?: Maybe<Scalars['String']['output']>;
  residenceFirstDeputyId?: Maybe<Scalars['ID']['output']>;
  residenceFirstDeputyLastName?: Maybe<Scalars['String']['output']>;
  residencePermits?: Maybe<Array<AugmentedResidencePermit>>;
  residenceRepresentativeFirstName?: Maybe<Scalars['String']['output']>;
  residenceRepresentativeId?: Maybe<Scalars['ID']['output']>;
  residenceRepresentativeLastName?: Maybe<Scalars['String']['output']>;
  responsibleResidenceOrganizationId?: Maybe<Scalars['ID']['output']>;
  responsibleRoleResidence?: Maybe<Scalars['String']['output']>;
  suitabilityForWork?: Maybe<Scalars['String']['output']>;
  workStartDate?: Maybe<Scalars['DateTime']['output']>;
};

export class AugmentedResidenceCandidateDataDeInput {
  appointmentHealthDepartmentDate?: InputMaybe<DateWithTimeInput>;
  appointmentHealthDepartmentKept?: InputMaybe<Scalars['Boolean']['input']>;
  bankAppointmentDate?: InputMaybe<DateWithTimeInput>;
  certificateOfConduct?: InputMaybe<Array<Scalars['String']['input']>>;
  certificateOfConductRequested?: InputMaybe<Scalars['Boolean']['input']>;
  companyDoctorAppointmentDate?: InputMaybe<DateWithTimeInput>;
  medicalExaminationAppointmentDate?: InputMaybe<DateWithTimeInput>;
  personalOnboardingDateTime?: InputMaybe<DateWithTimeInput>;
  policeRecordAppointmentDate?: InputMaybe<DateWithTimeInput>;
  recognitionMedicalCertificate?: InputMaybe<Scalars['Boolean']['input']>;
  registrationAuthority?: InputMaybe<Scalars['String']['input']>;
  registrationAuthorityAppointmentNumber?: InputMaybe<Scalars['String']['input']>;
  registrationOfficeDate?: InputMaybe<DateWithTimeInput>;
  residenceBank?: InputMaybe<Scalars['String']['input']>;
  residenceBankContactPartner?: InputMaybe<Scalars['String']['input']>;
  residenceFirstDeputyFirstName?: InputMaybe<Scalars['String']['input']>;
  residenceFirstDeputyId?: InputMaybe<Scalars['ID']['input']>;
  residenceFirstDeputyLastName?: InputMaybe<Scalars['String']['input']>;
  residencePermits?: InputMaybe<Array<AugmentedResidencePermitInput>>;
  residenceRepresentativeFirstName?: InputMaybe<Scalars['String']['input']>;
  residenceRepresentativeId?: InputMaybe<Scalars['ID']['input']>;
  residenceRepresentativeLastName?: InputMaybe<Scalars['String']['input']>;
  responsibleResidenceOrganizationId?: InputMaybe<Scalars['ID']['input']>;
  responsibleRoleResidence?: InputMaybe<Scalars['String']['input']>;
  suitabilityForWork?: InputMaybe<Scalars['String']['input']>;
  workStartDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export class AugmentedResidencePermit {
  __typename?: 'AugmentedResidencePermit';
  fictionalCertificate?: Maybe<SetInfo>;
  fictionalCertificateAvailable?: Maybe<Scalars['Boolean']['output']>;
  fictionalCertificateDoesNotAuthorisesWorking?: Maybe<Scalars['Boolean']['output']>;
  fictionalCertificateNumber?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  immigrationAuthority?: Maybe<Scalars['String']['output']>;
  residencePermit?: Maybe<Scalars['String']['output']>;
  residencePermitDocument?: Maybe<SetInfo>;
  residenceRequestedByPostDate?: Maybe<Scalars['DateTime']['output']>;
  residenceRequestedElectronicallyDate?: Maybe<Scalars['DateTime']['output']>;
};

export class AugmentedResidencePermitInput {
  fictionalCertificate?: InputMaybe<SetInfoInput>;
  fictionalCertificateAvailable?: InputMaybe<Scalars['Boolean']['input']>;
  fictionalCertificateDoesNotAuthorisesWorking?: InputMaybe<Scalars['Boolean']['input']>;
  fictionalCertificateNumber?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  immigrationAuthority?: InputMaybe<Scalars['String']['input']>;
  residencePermit?: InputMaybe<Scalars['String']['input']>;
  residencePermitDocument?: InputMaybe<SetInfoInput>;
  residenceRequestedByPostDate?: InputMaybe<Scalars['DateTime']['input']>;
  residenceRequestedElectronicallyDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export class AugmentedVisaCandidateDataDe {
  __typename?: 'AugmentedVisaCandidateDataDE';
  arrivalAirport?: Maybe<Scalars['String']['output']>;
  arrivalDate?: Maybe<DateWithTime>;
  arrivalTrainStation?: Maybe<Scalars['String']['output']>;
  contactAtArrivalLocation?: Maybe<Scalars['String']['output']>;
  departureAirport?: Maybe<Scalars['String']['output']>;
  departureTrainStation?: Maybe<Scalars['String']['output']>;
  diplomaticMissionOfGermanyInCountry?: Maybe<Scalars['String']['output']>;
  fileNumber?: Maybe<Scalars['String']['output']>;
  flightArrivalDate?: Maybe<DateWithTime>;
  flightDate?: Maybe<DateWithTime>;
  flightNumber?: Maybe<Scalars['String']['output']>;
  pickUpVehicle?: Maybe<Scalars['String']['output']>;
  residencePermit?: Maybe<Scalars['String']['output']>;
  responsibleForTransfer?: Maybe<Scalars['String']['output']>;
  responsibleRoleVisa?: Maybe<Scalars['String']['output']>;
  trainArrivalDate?: Maybe<DateWithTime>;
  trainDepartureDate?: Maybe<DateWithTime>;
  trainNumber?: Maybe<Scalars['String']['output']>;
  visaDocument?: Maybe<SetInfo>;
  visaEnquired?: Maybe<Scalars['Boolean']['output']>;
  visaEnquiredDate?: Maybe<Scalars['DateTime']['output']>;
  visaRelocationRequirements?: Maybe<Array<Scalars['String']['output']>>;
  visaRequestDate?: Maybe<Scalars['DateTime']['output']>;
  visaRequested?: Maybe<Scalars['Boolean']['output']>;
};

export class AugmentedVisaCandidateDataDeInput {
  arrivalAirport?: InputMaybe<Scalars['String']['input']>;
  arrivalDate?: InputMaybe<DateWithTimeInput>;
  arrivalTrainStation?: InputMaybe<Scalars['String']['input']>;
  contactAtArrivalLocation?: InputMaybe<Scalars['String']['input']>;
  departureAirport?: InputMaybe<Scalars['String']['input']>;
  departureTrainStation?: InputMaybe<Scalars['String']['input']>;
  diplomaticMissionOfGermanyInCountry?: InputMaybe<Scalars['String']['input']>;
  fileNumber?: InputMaybe<Scalars['String']['input']>;
  flightArrivalDate?: InputMaybe<DateWithTimeInput>;
  flightDate?: InputMaybe<DateWithTimeInput>;
  flightNumber?: InputMaybe<Scalars['String']['input']>;
  pickUpVehicle?: InputMaybe<Scalars['String']['input']>;
  residencePermit?: InputMaybe<Scalars['String']['input']>;
  responsibleForTransfer?: InputMaybe<Scalars['String']['input']>;
  responsibleRoleVisa?: InputMaybe<Scalars['String']['input']>;
  trainArrivalDate?: InputMaybe<DateWithTimeInput>;
  trainDepartureDate?: InputMaybe<DateWithTimeInput>;
  trainNumber?: InputMaybe<Scalars['String']['input']>;
  visaDocument?: InputMaybe<SetInfoInput>;
  visaEnquired?: InputMaybe<Scalars['Boolean']['input']>;
  visaEnquiredDate?: InputMaybe<Scalars['DateTime']['input']>;
  visaRelocationRequirements?: InputMaybe<Array<Scalars['String']['input']>>;
  visaRequestDate?: InputMaybe<Scalars['DateTime']['input']>;
  visaRequested?: InputMaybe<Scalars['Boolean']['input']>;
};

export class AugmentedVocationalSchoolCandidateDataAt {
  __typename?: 'AugmentedVocationalSchoolCandidateDataAT';
  address?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  currentTrainingYear?: Maybe<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['DateTime']['output']>;
  expectedEndDate?: Maybe<Scalars['DateTime']['output']>;
  federalState?: Maybe<Scalars['String']['output']>;
  medicalCheckAttended?: Maybe<Scalars['Boolean']['output']>;
  medicalCheckAttendedDate?: Maybe<Scalars['DateTime']['output']>;
  medicalCheckPassed?: Maybe<Scalars['Boolean']['output']>;
  medicalCheckPassedDate?: Maybe<Scalars['DateTime']['output']>;
  schoolCourse?: Maybe<Scalars['String']['output']>;
  schoolName?: Maybe<Scalars['String']['output']>;
  trainingContract?: Maybe<SetInfo>;
  trainingTimeInPartTime?: Maybe<Scalars['Boolean']['output']>;
  trainingTimeInYearsTotal?: Maybe<Scalars['String']['output']>;
  trainingTimeShortened?: Maybe<Scalars['Boolean']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export class AugmentedVocationalSchoolCandidateDataAtInput {
  address?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  currentTrainingYear?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  expectedEndDate?: InputMaybe<Scalars['DateTime']['input']>;
  federalState?: InputMaybe<Scalars['String']['input']>;
  medicalCheckAttended?: InputMaybe<Scalars['Boolean']['input']>;
  medicalCheckAttendedDate?: InputMaybe<Scalars['DateTime']['input']>;
  medicalCheckPassed?: InputMaybe<Scalars['Boolean']['input']>;
  medicalCheckPassedDate?: InputMaybe<Scalars['DateTime']['input']>;
  schoolCourse?: InputMaybe<Scalars['String']['input']>;
  schoolName?: InputMaybe<Scalars['String']['input']>;
  trainingContract?: InputMaybe<SetInfoInput>;
  trainingTimeInPartTime?: InputMaybe<Scalars['Boolean']['input']>;
  trainingTimeInYearsTotal?: InputMaybe<Scalars['String']['input']>;
  trainingTimeShortened?: InputMaybe<Scalars['Boolean']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export class AugmentedVocationalSchoolCandidateDataDe {
  __typename?: 'AugmentedVocationalSchoolCandidateDataDE';
  address?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  currentTrainingYear?: Maybe<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['DateTime']['output']>;
  expectedEndDate?: Maybe<Scalars['DateTime']['output']>;
  federalState?: Maybe<Scalars['String']['output']>;
  medicalCheckAttended?: Maybe<Scalars['Boolean']['output']>;
  medicalCheckAttendedDate?: Maybe<Scalars['DateTime']['output']>;
  medicalCheckPassed?: Maybe<Scalars['Boolean']['output']>;
  medicalCheckPassedDate?: Maybe<Scalars['DateTime']['output']>;
  schoolCourse?: Maybe<Scalars['String']['output']>;
  schoolName?: Maybe<Scalars['String']['output']>;
  trainingContract?: Maybe<SetInfo>;
  trainingTimeInPartTime?: Maybe<Scalars['Boolean']['output']>;
  trainingTimeInYearsTotal?: Maybe<Scalars['String']['output']>;
  trainingTimeShortened?: Maybe<Scalars['Boolean']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export class AugmentedVocationalSchoolCandidateDataDeInput {
  address?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  currentTrainingYear?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  expectedEndDate?: InputMaybe<Scalars['DateTime']['input']>;
  federalState?: InputMaybe<Scalars['String']['input']>;
  medicalCheckAttended?: InputMaybe<Scalars['Boolean']['input']>;
  medicalCheckAttendedDate?: InputMaybe<Scalars['DateTime']['input']>;
  medicalCheckPassed?: InputMaybe<Scalars['Boolean']['input']>;
  medicalCheckPassedDate?: InputMaybe<Scalars['DateTime']['input']>;
  schoolCourse?: InputMaybe<Scalars['String']['input']>;
  schoolName?: InputMaybe<Scalars['String']['input']>;
  trainingContract?: InputMaybe<SetInfoInput>;
  trainingTimeInPartTime?: InputMaybe<Scalars['Boolean']['input']>;
  trainingTimeInYearsTotal?: InputMaybe<Scalars['String']['input']>;
  trainingTimeShortened?: InputMaybe<Scalars['Boolean']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export class AutoCvResponse {
  __typename?: 'AutoCVResponse';
  hideCandidateName?: Maybe<Scalars['Boolean']['output']>;
  state: AutoCvResponseStatus;
  url?: Maybe<Scalars['String']['output']>;
};

export enum AutoCvResponseStatus {
  Available = 'Available',
  Processing = 'Processing',
  Unavailable = 'Unavailable'
}

export class AutoCollectionSettings {
  __typename?: 'AutoCollectionSettings';
  filterId: Scalars['String']['output'];
  templateMapping?: Maybe<AutoCollectionTemplateMapping>;
};

export class AutoCollectionSettingsForUi {
  __typename?: 'AutoCollectionSettingsForUI';
  filterId: Scalars['String']['output'];
  templateMapping?: Maybe<AutoCollectionTemplateMappingForUi>;
};

export class AutoCollectionSettingsInput {
  filterId: Scalars['String']['input'];
  templateMapping?: InputMaybe<AutoCollectionTemplateMappingInput>;
};

export class AutoCollectionTemplateMapping {
  __typename?: 'AutoCollectionTemplateMapping';
  templateId: Scalars['ID']['output'];
  templateLatestChangedAtWithChildren?: Maybe<Scalars['DateTime']['output']>;
  variableMappings?: Maybe<Array<VariableMapping>>;
};

export class AutoCollectionTemplateMappingForUi {
  __typename?: 'AutoCollectionTemplateMappingForUI';
  templateId: Scalars['ID']['output'];
  templateLatestChangedAtWithChildren?: Maybe<Scalars['DateTime']['output']>;
  variableMappings?: Maybe<Array<VariableMappingForUi>>;
};

export class AutoCollectionTemplateMappingInput {
  templateId: Scalars['ID']['input'];
  templateLatestChangedAtWithChildren?: InputMaybe<Scalars['DateTime']['input']>;
  variableMappings?: InputMaybe<Array<VariableMappingInput>>;
};

export class AutoGeneratedCvUrlInput {
  candidateId: Scalars['ID']['input'];
  candidateOrganizationId: Scalars['ID']['input'];
  collectionId?: InputMaybe<Scalars['ID']['input']>;
  collectionOrganizationId?: InputMaybe<Scalars['ID']['input']>;
  language: Scalars['String']['input'];
  selectedSharingId?: InputMaybe<Scalars['ID']['input']>;
  sharingOrganizationId?: InputMaybe<Scalars['ID']['input']>;
  templateOrganizationId?: InputMaybe<Scalars['ID']['input']>;
};

export class AutoStateChange {
  __typename?: 'AutoStateChange';
  condition: AutoStateChangeCondition;
  mutations: Array<AutoStateChangeMutation>;
};

export class AutoStateChangeCondition {
  __typename?: 'AutoStateChangeCondition';
  daysInstanceStateUnchanged: Scalars['Float']['output'];
  instanceState: InstanceStateEnum;
};

export class AutoStateChangeConditionInput {
  daysInstanceStateUnchanged: Scalars['Float']['input'];
  instanceState: InstanceStateEnum;
};

export class AutoStateChangeConditionModel {
  __typename?: 'AutoStateChangeConditionModel';
  daysInstanceStateUnchanged: Scalars['Float']['output'];
  instanceState: InstanceStateEnum;
};

export class AutoStateChangeConditionModelInput {
  daysInstanceStateUnchanged: Scalars['Float']['input'];
  instanceState: InstanceStateEnum;
};

export class AutoStateChangeInput {
  condition: AutoStateChangeConditionInput;
  mutations: Array<AutoStateChangeMutationInput>;
};

export class AutoStateChangeModel {
  __typename?: 'AutoStateChangeModel';
  condition: AutoStateChangeConditionModel;
  mutations: Array<AutoStateChangeMutationModel>;
};

export class AutoStateChangeModelInput {
  condition: AutoStateChangeConditionModelInput;
  mutations: Array<AutoStateChangeMutationModelInput>;
};

export class AutoStateChangeMutation {
  __typename?: 'AutoStateChangeMutation';
  instanceState?: Maybe<InstanceStateEnum>;
  responsibleRoles?: Maybe<Array<Scalars['String']['output']>>;
  targetRoles?: Maybe<Array<Scalars['String']['output']>>;
  visibleForRoles?: Maybe<Array<Scalars['String']['output']>>;
};

export class AutoStateChangeMutationInput {
  instanceState?: InputMaybe<InstanceStateEnum>;
  responsibleRoles?: InputMaybe<Array<Scalars['String']['input']>>;
  targetRoles?: InputMaybe<Array<Scalars['String']['input']>>;
  visibleForRoles?: InputMaybe<Array<Scalars['String']['input']>>;
};

export class AutoStateChangeMutationModel {
  __typename?: 'AutoStateChangeMutationModel';
  instanceState?: Maybe<InstanceStateEnum>;
  responsibleRoles?: Maybe<Array<Scalars['String']['output']>>;
  targetRoles?: Maybe<Array<Scalars['String']['output']>>;
  visibleForRoles?: Maybe<Array<Scalars['String']['output']>>;
};

export class AutoStateChangeMutationModelInput {
  instanceState?: InputMaybe<InstanceStateEnum>;
  responsibleRoles?: InputMaybe<Array<Scalars['String']['input']>>;
  targetRoles?: InputMaybe<Array<Scalars['String']['input']>>;
  visibleForRoles?: InputMaybe<Array<Scalars['String']['input']>>;
};

export class AvailableAutogenTemplateListInput {
  organizationId: Scalars['ID']['input'];
};

export class AvailableDocumentTemplate {
  __typename?: 'AvailableDocumentTemplate';
  name: Scalars['String']['output'];
  type: DocumentTemplateType;
};

export class AvailableDocumentTemplateGetInput {
  candidateId?: InputMaybe<Scalars['ID']['input']>;
  candidateOrganizationId?: InputMaybe<Scalars['ID']['input']>;
  collectionId?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
};

export class AvailableDocumentTemplateGroup {
  __typename?: 'AvailableDocumentTemplateGroup';
  immigrationCountry?: Maybe<Scalars['String']['output']>;
  templates: Array<AvailableDocumentTemplate>;
};

export class AvailableDocumentTemplateInput {
  name: Scalars['String']['input'];
  type: DocumentTemplateType;
};

export class AvailableProcessModelGetListInput {
  organizationId: Scalars['String']['input'];
  selectableModelsOnly: Scalars['Boolean']['input'];
};

export class AvailableProcessModelVersionsGetInput {
  language: Scalars['String']['input'];
  modelId: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  ownerOrganizationId: Scalars['ID']['input'];
};

export class AvailableReportsGetInput {
  organizationId: Scalars['ID']['input'];
};

export class BaseProcessDefinition {
  __typename?: 'BaseProcessDefinition';
  baseProcessType: BaseProcessTypeEnum;
  id: Scalars['ID']['output'];
  organizationId: Scalars['String']['output'];
};

export class BaseProcessDefinitionInput {
  baseProcessType: BaseProcessTypeEnum;
  id: Scalars['ID']['input'];
  organizationId: Scalars['String']['input'];
};

export enum BaseProcessTypeEnum {
  CreateProcessOperation = 'CreateProcessOperation',
  ExistingProcess = 'ExistingProcess'
}

export class BeforeRecognition {
  __typename?: 'BeforeRecognition';
  contractExtension?: Maybe<Scalars['Boolean']['output']>;
  contractTemplate?: Maybe<RecognitionPathContractTemplate>;
  contractTemplateId?: Maybe<Scalars['ID']['output']>;
  contractTemplateName?: Maybe<Scalars['String']['output']>;
  employerHistory?: Maybe<Array<EmployerHistoryEntry>>;
  employerId?: Maybe<Scalars['ID']['output']>;
  employerName?: Maybe<Scalars['String']['output']>;
  employerRegion?: Maybe<Scalars['String']['output']>;
  employerZipcode?: Maybe<Scalars['String']['output']>;
  employmentRelocationRequirements?: Maybe<Array<Scalars['String']['output']>>;
  endOfProbationDate?: Maybe<Scalars['DateTime']['output']>;
  firstDeputyFirstName?: Maybe<Scalars['String']['output']>;
  firstDeputyId?: Maybe<Scalars['ID']['output']>;
  firstDeputyLastName?: Maybe<Scalars['String']['output']>;
  isTemporaryEmployee?: Maybe<Scalars['Boolean']['output']>;
  representativeFirstName?: Maybe<Scalars['String']['output']>;
  representativeId?: Maybe<Scalars['ID']['output']>;
  representativeLastName?: Maybe<Scalars['String']['output']>;
  typeOfWorkLocation?: Maybe<Scalars['String']['output']>;
  workLocation?: Maybe<Scalars['String']['output']>;
};

export class BeforeRecognitionInput {
  contractExtension?: InputMaybe<Scalars['Boolean']['input']>;
  contractTemplate?: InputMaybe<RecognitionPathContractTemplateInput>;
  contractTemplateId?: InputMaybe<Scalars['ID']['input']>;
  contractTemplateName?: InputMaybe<Scalars['String']['input']>;
  employerHistory?: InputMaybe<Array<EmployerHistoryEntryInput>>;
  employerId?: InputMaybe<Scalars['ID']['input']>;
  employerName?: InputMaybe<Scalars['String']['input']>;
  employerRegion?: InputMaybe<Scalars['String']['input']>;
  employerZipcode?: InputMaybe<Scalars['String']['input']>;
  employmentRelocationRequirements?: InputMaybe<Array<Scalars['String']['input']>>;
  endOfProbationDate?: InputMaybe<Scalars['DateTime']['input']>;
  firstDeputyFirstName?: InputMaybe<Scalars['String']['input']>;
  firstDeputyId?: InputMaybe<Scalars['ID']['input']>;
  firstDeputyLastName?: InputMaybe<Scalars['String']['input']>;
  isTemporaryEmployee?: InputMaybe<Scalars['Boolean']['input']>;
  representativeFirstName?: InputMaybe<Scalars['String']['input']>;
  representativeId?: InputMaybe<Scalars['ID']['input']>;
  representativeLastName?: InputMaybe<Scalars['String']['input']>;
  typeOfWorkLocation?: InputMaybe<Scalars['String']['input']>;
  workLocation?: InputMaybe<Scalars['String']['input']>;
};

export enum BenefitTimeInterval {
  Monthly = 'Monthly',
  OneTimeOnly = 'OneTimeOnly',
  Quarterly = 'Quarterly',
  Yearly = 'Yearly'
}

export class BillingAddress {
  __typename?: 'BillingAddress';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  line1?: Maybe<Scalars['String']['output']>;
  line2?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
};

export enum BillingEventType {
  CandidateDeclarationOfEmploymentUploaded = 'CandidateDeclarationOfEmploymentUploaded',
  CandidatePreContractUploaded = 'CandidatePreContractUploaded',
  CandidateStatusSetToMatched = 'CandidateStatusSetToMatched',
  CandidateTrainingContractUploaded = 'CandidateTrainingContractUploaded',
  CandidateTransferred = 'CandidateTransferred',
  CandidateWorkingContractUploaded = 'CandidateWorkingContractUploaded'
}

export class BillingSettings {
  __typename?: 'BillingSettings';
  _etag?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  organizationId: Scalars['ID']['output'];
  stripeCustomerId?: Maybe<Scalars['String']['output']>;
};

export class BillingSettingsGetCustomerPortalUrlInput {
  organizationId: Scalars['String']['input'];
  returnUrl: Scalars['String']['input'];
};

export class BirthDayCandidate {
  __typename?: 'BirthDayCandidate';
  _etag?: Maybe<Scalars['String']['output']>;
  age: Scalars['Float']['output'];
  country?: Maybe<Scalars['String']['output']>;
  creationDate: Scalars['DateTime']['output'];
  deletionDate?: Maybe<Scalars['DateTime']['output']>;
  displayId: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
  email?: Maybe<Scalars['String']['output']>;
  family?: Maybe<CandidateFamily>;
  function: Scalars['String']['output'];
  governance?: Maybe<GovernanceCandidateData>;
  id?: Maybe<Scalars['ID']['output']>;
  immigrationCountry: SupportedImmigrationCountry;
  internal?: Maybe<InternalCandidateData>;
  lastLoginDate?: Maybe<Scalars['DateTime']['output']>;
  licensedOrganizationData?: Maybe<CandidateForLicensedOrganizationData>;
  messagesReception?: Maybe<MessagesReception>;
  migration?: Maybe<MigrationCandidateDataContainer>;
  organizationId: Scalars['ID']['output'];
  os?: Maybe<Scalars['JSONObject']['output']>;
  presentation?: Maybe<PresentationCandidateData>;
  processStatus?: Maybe<ProcessStatusCandidateData>;
  profession: Scalars['String']['output'];
  status: CandidateStatus;
  systemOnboarding?: Maybe<CandidateSystemOnboarding>;
  technicalSource?: Maybe<TechnicalSourceEnum>;
  transferData?: Maybe<TransferData>;
};

export class BlobDeleteInput {
  blobName: Scalars['String']['input'];
  candidateId: Scalars['ID']['input'];
  fromImageContainer?: InputMaybe<Scalars['Boolean']['input']>;
  onlyIfExists?: InputMaybe<Scalars['Boolean']['input']>;
  organizationId: Scalars['ID']['input'];
};

export class BlockUserInput {
  organizationId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export class CalculateCandidateProperties {
  __typename?: 'CalculateCandidateProperties';
  languageSkills?: Maybe<Array<CalculatedLanguageSkillForView>>;
};

export class CalculatedLanguageSkillForView {
  __typename?: 'CalculatedLanguageSkillForView';
  certified?: Maybe<Scalars['Boolean']['output']>;
  examCertificateDate?: Maybe<Scalars['DateTime']['output']>;
  examInstitution?: Maybe<Scalars['String']['output']>;
  language?: Maybe<Scalars['String']['output']>;
  skillLevel?: Maybe<Scalars['String']['output']>;
  skillLevelName?: Maybe<Scalars['String']['output']>;
};

export enum CalculationBasis {
  AbsoluteAmount = 'AbsoluteAmount',
  Percentage = 'Percentage'
}

export class Candidate {
  __typename?: 'Candidate';
  _etag?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  creationDate: Scalars['DateTime']['output'];
  deletionDate?: Maybe<Scalars['DateTime']['output']>;
  displayId: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
  email?: Maybe<Scalars['String']['output']>;
  family?: Maybe<CandidateFamily>;
  function: Scalars['String']['output'];
  governance?: Maybe<GovernanceCandidateData>;
  id?: Maybe<Scalars['ID']['output']>;
  immigrationCountry: SupportedImmigrationCountry;
  internal?: Maybe<InternalCandidateData>;
  lastLoginDate?: Maybe<Scalars['DateTime']['output']>;
  licensedOrganizationData?: Maybe<CandidateForLicensedOrganizationData>;
  messagesReception?: Maybe<MessagesReception>;
  migration?: Maybe<MigrationCandidateDataContainer>;
  organizationId: Scalars['ID']['output'];
  os?: Maybe<Scalars['JSONObject']['output']>;
  presentation?: Maybe<PresentationCandidateData>;
  processStatus?: Maybe<ProcessStatusCandidateData>;
  profession: Scalars['String']['output'];
  status: CandidateStatus;
  systemOnboarding?: Maybe<CandidateSystemOnboarding>;
  technicalSource?: Maybe<TechnicalSourceEnum>;
  transferData?: Maybe<TransferData>;
};

export class CandidateAccess {
  __typename?: 'CandidateAccess';
  canAccess: Scalars['Boolean']['output'];
  redirectToOrganization?: Maybe<Scalars['ID']['output']>;
};

export class CandidateAccessInput {
  candidateId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};

export class CandidateActivityInput {
  candidateId: Scalars['ID']['input'];
  filters?: InputMaybe<Scalars['JSONObject']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  organizationId: Scalars['ID']['input'];
  rows?: InputMaybe<Scalars['Int']['input']>;
  sortField?: InputMaybe<Scalars['String']['input']>;
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
};

export class CandidateActivityParameter {
  __typename?: 'CandidateActivityParameter';
  displayId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  organizationId: Scalars['ID']['output'];
  organizationName?: Maybe<Scalars['String']['output']>;
};

export class CandidateAndCollectionIdInput {
  candidate: CandidateIdInput;
  collection: CollectionIdInput;
};

export class CandidateArchiveInput {
  candidateId?: InputMaybe<Scalars['ID']['input']>;
  familyMemberId?: InputMaybe<Scalars['ID']['input']>;
  language: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  requiredDocumentOrganizationId?: InputMaybe<Scalars['ID']['input']>;
  requiredDocumentSetIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export class CandidateAssignmentException {
  __typename?: 'CandidateAssignmentException';
  assignmentExceptionType: CandidateAssignmentExceptionType;
  country?: Maybe<Scalars['String']['output']>;
  creationDate: Scalars['DateTime']['output'];
  displayId: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
  function: Scalars['String']['output'];
  id?: Maybe<Scalars['ID']['output']>;
  immigrationCountry: SupportedImmigrationCountry;
  implicitType: CandidateAssignmentExceptionType;
  organizationId: Scalars['ID']['output'];
  profession: Scalars['String']['output'];
  status: CandidateStatus;
};

export class CandidateAssignmentExceptionIdInput {
  assignmentExceptionType: CandidateAssignmentExceptionType;
  id: Scalars['ID']['input'];
  orgId: Scalars['ID']['input'];
  originalColId?: InputMaybe<Scalars['ID']['input']>;
  originalOrgId?: InputMaybe<Scalars['ID']['input']>;
};

export enum CandidateAssignmentExceptionOperation {
  AddException = 'AddException',
  RemoveException = 'RemoveException'
}

export enum CandidateAssignmentExceptionType {
  Exclude = 'Exclude',
  Include = 'Include'
}

export class CandidateCommentDeleteInput {
  commentId: Scalars['ID']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  iterationId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};

export class CandidateCommentUpsertInput {
  candidateId: Scalars['ID']['input'];
  comment: CommentDataInput;
  feedbackIterationId: Scalars['ID']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
};

export class CandidateCreateCurrentSelection {
  collectionId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};

export class CandidateCreateInput {
  family?: InputMaybe<CandidateFamilyInput>;
  function: Scalars['String']['input'];
  governance?: InputMaybe<GovernanceCandidateDataInput>;
  immigrationCountry: SupportedImmigrationCountry;
  internal?: InputMaybe<InternalCandidateDataInput>;
  licensedOrganizationData?: InputMaybe<CandidateForLicenseOrganizationDataInput>;
  migration?: InputMaybe<MigrationCandidateDataContainerInput>;
  organizationId: Scalars['ID']['input'];
  os?: InputMaybe<Scalars['JSONObject']['input']>;
  presentation?: InputMaybe<PresentationCandidateDataInput>;
  processStatus?: InputMaybe<ProcessStatusCandidateDataInput>;
  profession: Scalars['String']['input'];
  status: CandidateStatus;
};

export class CandidateData {
  __typename?: 'CandidateData';
  candidateId: Scalars['ID']['output'];
  displayId: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
  organizationId: Scalars['ID']['output'];
};

export class CandidateDataEvent {
  __typename?: 'CandidateDataEvent';
  candidateId: Scalars['ID']['output'];
  country?: Maybe<Scalars['String']['output']>;
  date: Scalars['DateTime']['output'];
  displayId: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
  eventType: Scalars['String']['output'];
  organizationId: Scalars['ID']['output'];
};

export class CandidateDeleteInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
  sharingEtag?: InputMaybe<Scalars['String']['input']>;
  sharingId?: InputMaybe<Scalars['ID']['input']>;
  sharingOrganizationId?: InputMaybe<Scalars['ID']['input']>;
};

export class CandidateDeletionRequest {
  __typename?: 'CandidateDeletionRequest';
  _etag?: Maybe<Scalars['String']['output']>;
  candidateId: Scalars['ID']['output'];
  changedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id?: Maybe<Scalars['ID']['output']>;
  organizationId: Scalars['ID']['output'];
};

export class CandidateDeletionRequestInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  candidateId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};

export class CandidateDeletionSetting {
  __typename?: 'CandidateDeletionSetting';
  candidateStatusForDeletion: CandidateStatusForDeletion;
  daysAfterDeletion?: Maybe<Scalars['Float']['output']>;
  isEnabled: Scalars['Boolean']['output'];
};

export class CandidateDeletionSettingInput {
  candidateStatusForDeletion: CandidateStatusForDeletion;
  daysAfterDeletion?: InputMaybe<Scalars['Float']['input']>;
  isEnabled: Scalars['Boolean']['input'];
};

export class CandidateDocTypeAttachment {
  __typename?: 'CandidateDocTypeAttachment';
  docOrganizationRole?: Maybe<Scalars['String']['output']>;
  documentSetType?: Maybe<Scalars['String']['output']>;
  documentTag?: Maybe<Scalars['String']['output']>;
  documentType: Scalars['String']['output'];
};

export class CandidateDocTypeAttachmentInput {
  docOrganizationRole?: InputMaybe<Scalars['String']['input']>;
  documentSetType?: InputMaybe<Scalars['String']['input']>;
  documentTag?: InputMaybe<Scalars['String']['input']>;
  documentType: Scalars['String']['input'];
};

export class CandidateDocumentListInput {
  candidateId?: InputMaybe<Scalars['ID']['input']>;
  familyMemberId?: InputMaybe<Scalars['ID']['input']>;
  language: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
};

export class CandidateDocumentModel {
  __typename?: 'CandidateDocumentModel';
  documentSetType?: Maybe<Scalars['String']['output']>;
  documentTag: Scalars['String']['output'];
  documentType: Scalars['String']['output'];
};

export class CandidateDocumentModelInput {
  documentSetType?: InputMaybe<Scalars['String']['input']>;
  documentTag: Scalars['String']['input'];
  documentType: Scalars['String']['input'];
};

export class CandidateEmployerUpsertInput {
  candidateId: Scalars['ID']['input'];
  employer: EmployerDataInput;
  feedbackIterationId: Scalars['ID']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
};

export type CandidateEvent = CandidateDataEvent | CourseExamDataEvent | DocumentDataEvent;

export class CandidateEventsInput {
  candidateId: Scalars['ID']['input'];
  documentTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  eventCategories: Array<Scalars['String']['input']>;
  eventTimeframe: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
};

export class CandidateFamily {
  __typename?: 'CandidateFamily';
  members?: Maybe<Array<CandidateFamilyMember>>;
};

export class CandidateFamilyInput {
  members?: InputMaybe<Array<CandidateFamilyMemberInput>>;
};

export class CandidateFamilyMember {
  __typename?: 'CandidateFamilyMember';
  address?: Maybe<Scalars['String']['output']>;
  birthDate?: Maybe<Scalars['DateTime']['output']>;
  birthName?: Maybe<Scalars['String']['output']>;
  citizenship?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  cityOfBirth?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  countryOfBirth?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  germanLanguageSkill?: Maybe<Scalars['String']['output']>;
  hasSharedCustody?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  isEmergencyContact?: Maybe<Scalars['Boolean']['output']>;
  lastName: Scalars['String']['output'];
  otherCustodian?: Maybe<Scalars['ID']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  relation: CandidateRelation;
  reunification: Scalars['Boolean']['output'];
  salutation: Scalars['String']['output'];
  sameAddressAsCandidate?: Maybe<Scalars['Boolean']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export class CandidateFamilyMemberInput {
  address?: InputMaybe<Scalars['String']['input']>;
  birthDate?: InputMaybe<Scalars['DateTime']['input']>;
  birthName?: InputMaybe<Scalars['String']['input']>;
  citizenship?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  cityOfBirth?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  countryOfBirth?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  germanLanguageSkill?: InputMaybe<Scalars['String']['input']>;
  hasSharedCustody?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  isEmergencyContact?: InputMaybe<Scalars['Boolean']['input']>;
  lastName: Scalars['String']['input'];
  otherCustodian?: InputMaybe<Scalars['ID']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  relation: CandidateRelation;
  reunification: Scalars['Boolean']['input'];
  salutation: Scalars['String']['input'];
  sameAddressAsCandidate?: InputMaybe<Scalars['Boolean']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export class CandidateFeedbackDeleteInput {
  feedbackId: Scalars['ID']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  iterationId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};

export class CandidateFeedbackUpsertInput {
  candidateId: Scalars['ID']['input'];
  feedback: FeedbackDataInput;
  feedbackIterationId: Scalars['ID']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
};

export class CandidateForEdit {
  __typename?: 'CandidateForEdit';
  candidate: Candidate;
  deletionRequest?: Maybe<CandidateDeletionRequest>;
  favorite?: Maybe<Favorite>;
  messagesReception?: Maybe<MessagesReception>;
  tags?: Maybe<Array<CandidateTag>>;
};

export class CandidateForLicenseOrganizationDataInput {
  internal?: InputMaybe<InternalCandidateDataInput>;
};

export class CandidateForLicensedOrganizationData {
  __typename?: 'CandidateForLicensedOrganizationData';
  internal?: Maybe<InternalCandidateData>;
};

export class CandidateForView {
  __typename?: 'CandidateForView';
  _etag?: Maybe<Scalars['String']['output']>;
  calculatedProperties: CalculateCandidateProperties;
  country?: Maybe<Scalars['String']['output']>;
  creationDate: Scalars['DateTime']['output'];
  deletionDate?: Maybe<Scalars['DateTime']['output']>;
  displayId: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
  email?: Maybe<Scalars['String']['output']>;
  family?: Maybe<CandidateFamily>;
  function: Scalars['String']['output'];
  governance?: Maybe<GovernanceCandidateData>;
  id?: Maybe<Scalars['ID']['output']>;
  immigrationCountry: SupportedImmigrationCountry;
  internal?: Maybe<InternalCandidateData>;
  licensedOrganizationData?: Maybe<CandidateForLicensedOrganizationData>;
  migration?: Maybe<MigrationCandidateDataContainerForView>;
  organizationId: Scalars['ID']['output'];
  os?: Maybe<Scalars['JSONObject']['output']>;
  presentation?: Maybe<PresentationCandidateData>;
  processStatus?: Maybe<ProcessStatusCandidateData>;
  profession: Scalars['String']['output'];
  status: CandidateStatus;
  technicalSource?: Maybe<TechnicalSourceEnum>;
  transferData?: Maybe<TransferData>;
};

export class CandidateId {
  __typename?: 'CandidateId';
  id: Scalars['ID']['output'];
  orgId: Scalars['ID']['output'];
  originalColId?: Maybe<Scalars['ID']['output']>;
  originalOrgId?: Maybe<Scalars['ID']['output']>;
};

export class CandidateIdInput {
  id: Scalars['ID']['input'];
  orgId: Scalars['ID']['input'];
  originalColId?: InputMaybe<Scalars['ID']['input']>;
  originalOrgId?: InputMaybe<Scalars['ID']['input']>;
};

export class CandidateImportIssue {
  __typename?: 'CandidateImportIssue';
  candidateName?: Maybe<Scalars['String']['output']>;
  lineNumber?: Maybe<Scalars['Float']['output']>;
  message: Scalars['String']['output'];
};

export class CandidateImportIssueInput {
  candidateName?: InputMaybe<Scalars['String']['input']>;
  lineNumber?: InputMaybe<Scalars['Float']['input']>;
  message: Scalars['String']['input'];
};

export class CandidateInterviewIterationDeleteInput {
  candidateId: Scalars['ID']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  interviewFeedbackId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};

export class CandidateInterviewsFeedback {
  __typename?: 'CandidateInterviewsFeedback';
  _etag?: Maybe<Scalars['String']['output']>;
  candidateId: Scalars['ID']['output'];
  id?: Maybe<Scalars['ID']['output']>;
  interviewsFeedback?: Maybe<Array<FeedbackIterationData>>;
  organizationId: Scalars['ID']['output'];
};

export class CandidateMultiEditConfiguration {
  __typename?: 'CandidateMultiEditConfiguration';
  fields: Array<Scalars['String']['output']>;
  tabs: Array<Scalars['String']['output']>;
};

export class CandidateMultiEditConfigurationInput {
  organizationId: Scalars['ID']['input'];
};

export class CandidateMultiEditExecuteInput {
  candidate: CandidateUpdateInput;
  candidateIds: Array<CandidateIdInput>;
  sharing?: InputMaybe<CandidateUpdateInputSharing>;
};

export class CandidateMultiEditPreview {
  __typename?: 'CandidateMultiEditPreview';
  candidates: CandidateMultiEditPreviewDetails;
  caption: Scalars['String']['output'];
  field: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export class CandidateMultiEditPreviewDetails {
  __typename?: 'CandidateMultiEditPreviewDetails';
  withDifferentValue?: Maybe<Array<Candidate>>;
  withNoValue?: Maybe<Array<Candidate>>;
  withTheValue?: Maybe<Array<Candidate>>;
};

export class CandidateMultiEditPreviewInput {
  candidate: CandidateUpdateInput;
  candidateIds: Array<CandidateIdInput>;
  sharing?: InputMaybe<CandidateUpdateInputSharing>;
};

export class CandidateMultiInviteExecuteInput {
  candidateIds: Array<CandidateIdInput>;
};

export class CandidateMultiInvitePreviewInput {
  candidateIds: Array<CandidateIdInput>;
};

export class CandidateNote {
  __typename?: 'CandidateNote';
  _etag?: Maybe<Scalars['String']['output']>;
  candidateId: Scalars['ID']['output'];
  changedAt: Scalars['DateTime']['output'];
  changedBy: Scalars['String']['output'];
  contents: Scalars['String']['output'];
  delta: Scalars['JSONObject']['output'];
  id?: Maybe<Scalars['ID']['output']>;
  organizationId: Scalars['ID']['output'];
};

export class CandidateNoteCreateInput {
  candidateId: Scalars['ID']['input'];
  contents: Scalars['String']['input'];
  delta: Scalars['JSONObject']['input'];
  organizationId: Scalars['ID']['input'];
};

export class CandidateNoteDeleteInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  candidateId: Scalars['ID']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
};

export class CandidateNoteGetInput {
  candidateId: Scalars['ID']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
};

export class CandidateNoteListInput {
  candidateId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};

export class CandidateNoteUpdateInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  candidateId: Scalars['ID']['input'];
  contents: Scalars['String']['input'];
  delta: Scalars['JSONObject']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
};

export class CandidatePage {
  __typename?: 'CandidatePage';
  nodes?: Maybe<Array<Candidate>>;
  totalCount: Scalars['Int']['output'];
};

export class CandidatePageInput {
  filters?: InputMaybe<Scalars['JSONObject']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  language: Scalars['String']['input'];
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  rows?: InputMaybe<Scalars['Int']['input']>;
  sortField?: InputMaybe<Scalars['String']['input']>;
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
};

export class CandidatePrice {
  __typename?: 'CandidatePrice';
  cap?: Maybe<Scalars['Float']['output']>;
  floor?: Maybe<Scalars['Float']['output']>;
  priceRows: Array<PriceRow>;
};

export class CandidateProfileConfig {
  __typename?: 'CandidateProfileConfig';
  _etag?: Maybe<Scalars['String']['output']>;
  candidateFunction: Array<Scalars['String']['output']>;
  candidateStatus: Array<CandidateStatus>;
  changedAt: Scalars['DateTime']['output'];
  changedBy: Scalars['String']['output'];
  id?: Maybe<Scalars['ID']['output']>;
  organizationId: Scalars['ID']['output'];
  setId: Scalars['String']['output'];
  tabs: Array<CandidateProfileConfigTab>;
};

export class CandidateProfileConfigCreateInput {
  candidateFunction: Array<Scalars['String']['input']>;
  candidateStatus: Array<CandidateStatus>;
  organizationId: Scalars['ID']['input'];
  setId: Scalars['String']['input'];
  tabs: Array<CandidateProfileConfigTabInput>;
};

export class CandidateProfileConfigDeleteInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
};

export class CandidateProfileConfigGetByFunctionAndStatusInput {
  candidateFunction: Scalars['String']['input'];
  candidateStatus: CandidateStatus;
  organizationId: Scalars['ID']['input'];
  setId: Scalars['String']['input'];
};

export class CandidateProfileConfigGetInput {
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
};

export class CandidateProfileConfigGetListAllInput {
  organizationId: Scalars['ID']['input'];
  setId: Scalars['String']['input'];
};

export class CandidateProfileConfigTab {
  __typename?: 'CandidateProfileConfigTab';
  key: Scalars['String']['output'];
  tabFields?: Maybe<Array<TabField>>;
};

export class CandidateProfileConfigTabInput {
  key: Scalars['String']['input'];
  tabFields?: InputMaybe<Array<TabFieldInput>>;
};

export class CandidateProfileConfigUpdateInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  candidateFunction: Array<Scalars['String']['input']>;
  candidateStatus: Array<CandidateStatus>;
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
  setId: Scalars['String']['input'];
  tabs: Array<CandidateProfileConfigTabInput>;
};

export class CandidateProfileProperty {
  __typename?: 'CandidateProfileProperty';
  expression?: Maybe<Scalars['String']['output']>;
  path: Scalars['String']['output'];
  value?: Maybe<Scalars['JSON']['output']>;
};

export class CandidateProfilePropertyInput {
  expression?: InputMaybe<Scalars['String']['input']>;
  path: Scalars['String']['input'];
  value?: InputMaybe<Scalars['JSON']['input']>;
};

export class CandidateProfileUpdateInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  profile: Scalars['JSONObject']['input'];
};

export enum CandidateRelation {
  Child = 'Child',
  Father = 'Father',
  Mother = 'Mother',
  Other = 'Other',
  Partner = 'Partner'
}

export class CandidateReplacedByInput {
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
};

export class CandidateRequestDeleteInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  candidateId: Scalars['ID']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
};

export class CandidateScholarship {
  __typename?: 'CandidateScholarship';
  amountOfMoney?: Maybe<Scalars['Float']['output']>;
  awardFrequency?: Maybe<Scalars['String']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  responsibleRoleScholarship?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['DateTime']['output']>;
};

export class CandidateScholarshipInput {
  amountOfMoney?: InputMaybe<Scalars['Float']['input']>;
  awardFrequency?: InputMaybe<Scalars['String']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  id: Scalars['ID']['input'];
  responsibleRoleScholarship?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export enum CandidateSheetModeEnum {
  Full = 'Full',
  Simple = 'Simple'
}

export enum CandidateSource {
  Direct = 'Direct',
  Indirect = 'Indirect'
}

export class CandidateStaticDataContextInformation {
  __typename?: 'CandidateStaticDataContextInformation';
  id?: Maybe<Scalars['ID']['output']>;
  immigrationCountry: SupportedImmigrationCountry;
  organizationId: Scalars['ID']['output'];
};

export enum CandidateStatus {
  Approved = 'Approved',
  Archived = 'Archived',
  Associated = 'Associated',
  DroppedOut = 'DroppedOut',
  ForSelection = 'ForSelection',
  InRecognition = 'InRecognition',
  LabourMigrationFinished = 'LabourMigrationFinished',
  Matched = 'Matched',
  New = 'New',
  Paused = 'Paused',
  Rejected = 'Rejected',
  Transferred = 'Transferred',
  Unknown = 'Unknown'
}

export enum CandidateStatusForDeletion {
  Archived = 'Archived',
  DroppedOut = 'DroppedOut',
  LabourMigrationFinished = 'LabourMigrationFinished',
  Rejected = 'Rejected'
}

export class CandidateSystemOnboarding {
  __typename?: 'CandidateSystemOnboarding';
  authId?: Maybe<Scalars['String']['output']>;
  facebookId?: Maybe<Scalars['String']['output']>;
  facebookPageId?: Maybe<Scalars['String']['output']>;
  invitedToSystem?: Maybe<Scalars['Boolean']['output']>;
  invitedToSystemDate?: Maybe<Scalars['DateTime']['output']>;
  isBlocked?: Maybe<Scalars['Boolean']['output']>;
  messagingOpt_In?: Maybe<Scalars['Boolean']['output']>;
  termsAndConditionsAccepted?: Maybe<Scalars['Boolean']['output']>;
  termsAndConditionsAcceptedDate?: Maybe<Scalars['DateTime']['output']>;
};

export class CandidateTag {
  __typename?: 'CandidateTag';
  color: CandidateTagColor;
  key: Scalars['String']['output'];
  label: Scalars['String']['output'];
  parentKey?: Maybe<Scalars['String']['output']>;
  value: Scalars['String']['output'];
  variable: Scalars['String']['output'];
};

export enum CandidateTagColor {
  Danger = 'Danger',
  Info = 'Info',
  Primary = 'Primary',
  Secondary = 'Secondary',
  Success = 'Success',
  Warning = 'Warning'
}

export class CandidateTagConfiguration {
  __typename?: 'CandidateTagConfiguration';
  color: CandidateTagColor;
  variable: Scalars['String']['output'];
};

export class CandidateTagConfigurationInput {
  color: CandidateTagColor;
  variable: Scalars['String']['input'];
};

export class CandidateTagDescription {
  __typename?: 'CandidateTagDescription';
  key: Scalars['String']['output'];
  label: Scalars['String']['output'];
  parentKey?: Maybe<Scalars['String']['output']>;
  variable: Scalars['String']['output'];
};

export class CandidateTagGetInput {
  candidateId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
  sharingId?: InputMaybe<Scalars['ID']['input']>;
  sharingOrganizationId?: InputMaybe<Scalars['ID']['input']>;
};

export class CandidateUpdateInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  family?: InputMaybe<CandidateFamilyInput>;
  function?: InputMaybe<Scalars['String']['input']>;
  governance?: InputMaybe<GovernanceCandidateDataInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  immigrationCountry?: InputMaybe<SupportedImmigrationCountry>;
  internal?: InputMaybe<InternalCandidateDataInput>;
  licensedOrganizationData?: InputMaybe<CandidateForLicenseOrganizationDataInput>;
  migration?: InputMaybe<MigrationCandidateDataContainerInput>;
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  os?: InputMaybe<Scalars['JSONObject']['input']>;
  presentation?: InputMaybe<PresentationCandidateDataInput>;
  processStatus?: InputMaybe<ProcessStatusCandidateDataInput>;
  profession?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<CandidateStatus>;
};

export class CandidateUpdateInputSharing {
  collectionId?: InputMaybe<Scalars['ID']['input']>;
  collectionOrganizationId?: InputMaybe<Scalars['ID']['input']>;
  sharedTabHash?: InputMaybe<Scalars['String']['input']>;
};

export class CandidateVaccinationStatus {
  __typename?: 'CandidateVaccinationStatus';
  candidateId: Scalars['ID']['output'];
  candidateOrgId: Scalars['ID']['output'];
  displayId: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
  vaccinations: Array<VaccinationStatus>;
};

export class CandidatesOfCollectionForViewInput {
  colId: Scalars['ID']['input'];
  orgId: Scalars['ID']['input'];
  selectedSharingId?: InputMaybe<Scalars['ID']['input']>;
};

export class CertificateValuationAuthority {
  __typename?: 'CertificateValuationAuthority';
  city: Scalars['String']['output'];
  email?: Maybe<Scalars['String']['output']>;
  fax?: Maybe<Scalars['String']['output']>;
  federalState: Scalars['String']['output'];
  homepage?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  street: Scalars['String']['output'];
  title: Scalars['String']['output'];
  zipCode: Scalars['String']['output'];
};

export class CertificateValuationAuthorityInput {
  federalStates?: InputMaybe<Array<Scalars['String']['input']>>;
  immigrationCountry: SupportedImmigrationCountry;
};

export class CertifiedEducationExam {
  __typename?: 'CertifiedEducationExam';
  examDateAndTime?: Maybe<DateWithTime>;
  examInstitution?: Maybe<Scalars['String']['output']>;
  examResult?: Maybe<Scalars['String']['output']>;
  examResultId: Scalars['ID']['output'];
  languageLevel?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  passedModules?: Maybe<Array<ExamModuleType>>;
};

export class CertifiedEducationExamGroup {
  __typename?: 'CertifiedEducationExamGroup';
  examResultId?: Maybe<Scalars['String']['output']>;
  exams?: Maybe<Array<CertifiedEducationExam>>;
  institution?: Maybe<Scalars['String']['output']>;
  isFullyCertified: Scalars['Boolean']['output'];
  level: Scalars['String']['output'];
};

export class ChamberOfCrafts {
  __typename?: 'ChamberOfCrafts';
  hwkCity?: Maybe<Scalars['String']['output']>;
  hwkDepartmentEmail?: Maybe<Scalars['String']['output']>;
  hwkDepartmentPhone?: Maybe<Scalars['String']['output']>;
  hwkFederalState?: Maybe<Scalars['String']['output']>;
  hwkFederalStateCode?: Maybe<Scalars['String']['output']>;
  hwkHouseNumber: Scalars['Float']['output'];
  hwkId: Scalars['String']['output'];
  hwkInstitutionName?: Maybe<Scalars['String']['output']>;
  hwkStreet?: Maybe<Scalars['String']['output']>;
  hwkZipCode?: Maybe<Scalars['String']['output']>;
};

export class ChamberOfCraftsInput {
  federalStates?: InputMaybe<Array<Scalars['String']['input']>>;
};

export class Collection {
  __typename?: 'Collection';
  _etag?: Maybe<Scalars['String']['output']>;
  assignedCandidateIds?: Maybe<Array<CandidateId>>;
  assignedCandidates: Array<Candidate>;
  assignedCollectionIds?: Maybe<Array<CollectionId>>;
  assignedCollections: Array<Collection>;
  assignmentExceptions: Array<CandidateAssignmentException>;
  autoCollectionSettings?: Maybe<AutoCollectionSettingsForUi>;
  creationDate: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name: Scalars['String']['output'];
  organizationId: Scalars['ID']['output'];
  processes: Array<Process>;
  sharings: Array<Sharing>;
  targetSize?: Maybe<Scalars['Float']['output']>;
  totalCandidatesFromAssignedCollections: Scalars['Float']['output'];
  type?: Maybe<CollectionType>;
};

export class CollectionAccess {
  __typename?: 'CollectionAccess';
  canAccess: Scalars['Boolean']['output'];
  redirectToOrganization?: Maybe<Scalars['ID']['output']>;
};

export class CollectionAccessInput {
  collectionId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};

export class CollectionAndSharing {
  __typename?: 'CollectionAndSharing';
  collection: Collection;
  sharing?: Maybe<Sharing>;
};

export class CollectionAndSharingInput {
  collection: CollectionCreateInput;
  existing?: InputMaybe<CandidatesOfCollectionForViewInput>;
  filters?: InputMaybe<Scalars['JSONObject']['input']>;
  sharing?: InputMaybe<SharingForCollectionCreateInput>;
};

export class CollectionAutoFilter {
  __typename?: 'CollectionAutoFilter';
  _etag?: Maybe<Scalars['String']['output']>;
  changedAt: Scalars['DateTime']['output'];
  changedBy: Scalars['String']['output'];
  filters: Scalars['JSONObject']['output'];
  id: Scalars['ID']['output'];
  includeSharedCandidates?: Maybe<Scalars['Boolean']['output']>;
  organizationId: Scalars['ID']['output'];
};

export class CollectionAutoFilterGetInput {
  id: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};

export class CollectionAutoFilterSetInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  filters: Scalars['JSONObject']['input'];
  id: Scalars['ID']['input'];
  includeSharedCandidates?: InputMaybe<Scalars['Boolean']['input']>;
  organizationId: Scalars['ID']['input'];
};

export class CollectionAutoFilterTemplate {
  __typename?: 'CollectionAutoFilterTemplate';
  _etag?: Maybe<Scalars['String']['output']>;
  changedAt: Scalars['DateTime']['output'];
  changedBy: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  filters: Scalars['JSONObject']['output'];
  hasCollections: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  includeSharedCandidates?: Maybe<Scalars['Boolean']['output']>;
  latestChangedAtWithChildren: Scalars['DateTime']['output'];
  name: Scalars['String']['output'];
  organizationId: Scalars['ID']['output'];
  sharingTemplates: Array<SharingTemplate>;
  specialPurpose?: Maybe<CollectionAutoFilterTemplateSpecialPurpose>;
  templateName: Scalars['String']['output'];
};

export class CollectionAutoFilterTemplateAttachInput {
  collectionIds: Array<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
  templateId: Scalars['ID']['input'];
};

export class CollectionAutoFilterTemplateDeleteInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  deletionBehavior: CollectionAutoFilterTemplateDeletionBehavior;
  id: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};

export enum CollectionAutoFilterTemplateDeletionBehavior {
  Delete = 'Delete',
  Detach = 'Detach'
}

export class CollectionAutoFilterTemplateDetachAllInput {
  id: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};

export class CollectionAutoFilterTemplateDetachInput {
  collectionId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
  templateId: Scalars['ID']['input'];
};

export class CollectionAutoFilterTemplateGetAllInput {
  organizationId: Scalars['ID']['input'];
};

export class CollectionAutoFilterTemplateGetInput {
  id: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};

export class CollectionAutoFilterTemplateSetInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  filters: Scalars['JSONObject']['input'];
  id: Scalars['ID']['input'];
  includeSharedCandidates?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  specialPurpose?: InputMaybe<CollectionAutoFilterTemplateSpecialPurpose>;
  templateName: Scalars['String']['input'];
};

export enum CollectionAutoFilterTemplateSpecialPurpose {
  ForApplication = 'ForApplication',
  ForInterview = 'ForInterview'
}

export class CollectionAutoFilterTemplateSyncInput {
  collectionId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
  templateId: Scalars['ID']['input'];
};

export class CollectionCandidatePageInput {
  collectionId: Scalars['ID']['input'];
  excludeAssignmentExceptions?: InputMaybe<Scalars['Boolean']['input']>;
  filters?: InputMaybe<Scalars['JSONObject']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  language: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  rows?: InputMaybe<Scalars['Int']['input']>;
  selectedSharingId?: InputMaybe<Scalars['ID']['input']>;
  sortField?: InputMaybe<Scalars['String']['input']>;
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
};

export class CollectionCreateInput {
  autoCollectionSettings?: InputMaybe<AutoCollectionSettingsInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  targetSize?: InputMaybe<Scalars['Float']['input']>;
  type?: InputMaybe<CollectionType>;
};

export class CollectionDefinition {
  __typename?: 'CollectionDefinition';
  collectionType: CollectionKindEnum;
  id: Scalars['ID']['output'];
  organizationId: Scalars['String']['output'];
};

export class CollectionDefinitionInput {
  collectionType: CollectionKindEnum;
  id: Scalars['ID']['input'];
  organizationId: Scalars['String']['input'];
};

export class CollectionDeleteInput {
  _etag: Scalars['String']['input'];
  collectionId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};

export class CollectionEnumerationInput {
  organizationId: Scalars['ID']['input'];
};

export class CollectionEventsInput {
  collectionId: Scalars['ID']['input'];
  documentTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  eventCategories: Array<Scalars['String']['input']>;
  eventTimeframe: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
};

export class CollectionFilterPreset {
  __typename?: 'CollectionFilterPreset';
  _etag?: Maybe<Scalars['String']['output']>;
  changedAt: Scalars['DateTime']['output'];
  changedBy: Scalars['String']['output'];
  filters: Scalars['JSONObject']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  organizationId: Scalars['ID']['output'];
  userId?: Maybe<Scalars['ID']['output']>;
};

export class CollectionFilterPresetChangeStateInput {
  id: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};

export class CollectionFilterPresetCreateInput {
  filters: Scalars['JSONObject']['input'];
  name: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
};

export class CollectionFilterPresetDeleteInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export class CollectionFilterPresetGetInput {
  id: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export class CollectionFilterPresetListInput {
  organizationId: Scalars['ID']['input'];
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export class CollectionFilterPresetUpdateInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  filters: Scalars['JSONObject']['input'];
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
};

export class CollectionForAssignmentInput {
  collectionId: Scalars['ID']['input'];
  fromOrganizationId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};

export class CollectionForDelegationInput {
  collectionId: Scalars['ID']['input'];
  collectionOrganizationId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};

export class CollectionId {
  __typename?: 'CollectionId';
  id: Scalars['ID']['output'];
  orgId: Scalars['ID']['output'];
};

export class CollectionIdInput {
  id: Scalars['ID']['input'];
  orgId: Scalars['ID']['input'];
};

export class CollectionInput {
  collectionId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};

export enum CollectionKindEnum {
  AutoOrGenerated = 'AutoOrGenerated',
  AutoTemplate = 'AutoTemplate',
  Standard = 'Standard'
}

export class CollectionListDto {
  __typename?: 'CollectionListDTO';
  collection?: Maybe<Array<CollectionListEntry>>;
  orgId: Scalars['String']['output'];
};

export class CollectionListEntry {
  __typename?: 'CollectionListEntry';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  organizationId: Scalars['String']['output'];
};

export class CollectionListInput {
  candidateId: Scalars['ID']['input'];
  candidateOrganizationId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};

export class CollectionQueryInput {
  collectionId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};

export class CollectionSharingByOrgAndTypeInput {
  organizationId: Scalars['ID']['input'];
  sharedTabs?: InputMaybe<Array<Scalars['String']['input']>>;
  sharedToOrganizationId: Scalars['ID']['input'];
  sharingTypes: Array<SharingTypeEnum>;
};

export class CollectionTransferInput {
  collectionId: Scalars['ID']['input'];
  keepCandidateCopy: Scalars['Boolean']['input'];
  organizationId: Scalars['ID']['input'];
  targetOrganizationId: Scalars['ID']['input'];
  transferInternalDocuments: Scalars['Boolean']['input'];
  transferInternalNotes: Scalars['Boolean']['input'];
  transferInterviewData: Scalars['Boolean']['input'];
};

export class CollectionTransferResult {
  __typename?: 'CollectionTransferResult';
  errorMessage?: Maybe<Scalars['String']['output']>;
  newCollectionName?: Maybe<Scalars['String']['output']>;
  notTransferredCandidatesDisplayIds?: Maybe<Array<Scalars['String']['output']>>;
  success: Scalars['Boolean']['output'];
  transferredCandidatesDisplayIds?: Maybe<Array<Scalars['String']['output']>>;
  validationMessages?: Maybe<Array<Scalars['String']['output']>>;
};

export enum CollectionType {
  Auto = 'Auto',
  FromTemplate = 'FromTemplate',
  Standard = 'Standard'
}

export class CollectionUpdateInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  autoCollectionSettings?: InputMaybe<AutoCollectionSettingsInput>;
  creationDate: Scalars['DateTime']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  targetSize?: InputMaybe<Scalars['Float']['input']>;
  type?: InputMaybe<CollectionType>;
};

export class CollectionsForCandidateResult {
  __typename?: 'CollectionsForCandidateResult';
  _etag?: Maybe<Scalars['String']['output']>;
  collectionId?: Maybe<Scalars['ID']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  embedded?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  organizationId: Scalars['ID']['output'];
  type?: Maybe<CollectionType>;
};

export class Comment {
  __typename?: 'Comment';
  _etag?: Maybe<Scalars['String']['output']>;
  author?: Maybe<Scalars['String']['output']>;
  comment: Scalars['String']['output'];
  creationDate: Scalars['DateTime']['output'];
  feedbackId: Scalars['ID']['output'];
  id?: Maybe<Scalars['ID']['output']>;
  organizationId: Scalars['ID']['output'];
};

export class CommentCreateInput {
  author?: InputMaybe<Scalars['String']['input']>;
  comment: Scalars['String']['input'];
  feedbackId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};

export class CommentData {
  __typename?: 'CommentData';
  changedAt?: Maybe<Scalars['DateTime']['output']>;
  changedBy?: Maybe<Scalars['String']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
};

export class CommentDataInput {
  changedAt?: InputMaybe<Scalars['DateTime']['input']>;
  changedBy?: InputMaybe<Scalars['String']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

export class CommentDeleteInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
};

export class CommentUpdateInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  author?: InputMaybe<Scalars['String']['input']>;
  comment: Scalars['String']['input'];
  creationDate: Scalars['DateTime']['input'];
  feedbackId: Scalars['ID']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
};

export enum CompensationType {
  PerHour = 'PerHour',
  PerMonth = 'PerMonth'
}

export enum CompletionState {
  Complete = 'Complete',
  Incomplete = 'Incomplete'
}

export class Consent {
  __typename?: 'Consent';
  _etag?: Maybe<Scalars['String']['output']>;
  changedAt: Scalars['DateTime']['output'];
  changedBy: Scalars['String']['output'];
  contents: Scalars['String']['output'];
  delta: Scalars['JSONObject']['output'];
  id?: Maybe<Scalars['ID']['output']>;
  language: Scalars['String']['output'];
  organizationId: Scalars['ID']['output'];
  target: UserRole;
};

export class ConsentCreateInput {
  contents: Scalars['String']['input'];
  delta: Scalars['JSONObject']['input'];
  language: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  target: UserRole;
};

export class ConsentDeleteInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
};

export class ConsentForUser {
  browserLanguage?: InputMaybe<Scalars['String']['input']>;
  isCandidate: Scalars['Boolean']['input'];
  organizationId: Scalars['ID']['input'];
  userOrCandidateId: Scalars['ID']['input'];
};

export class ConsentListInput {
  organizationId: Scalars['ID']['input'];
};

export class ConsentUpdateInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  contents: Scalars['String']['input'];
  delta: Scalars['JSONObject']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  language: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  target: UserRole;
};

export enum ContactSharingMode {
  ShareAll = 'ShareAll',
  ShareDefault = 'ShareDefault',
  ShareSelected = 'ShareSelected'
}

export class Contract {
  __typename?: 'Contract';
  _etag?: Maybe<Scalars['String']['output']>;
  cancellationDate?: Maybe<Scalars['DateTime']['output']>;
  candidatePaymentSettings: Array<PaymentSettings>;
  currency: SupportedBillingCurrency;
  id?: Maybe<Scalars['ID']['output']>;
  licensePaymentSettings: Array<PaymentSettings>;
  organizationId: Scalars['ID']['output'];
  phases: Array<Phase>;
  startDate?: Maybe<Scalars['DateTime']['output']>;
};

export class ContractCreateInput {
  candidatePaymentSettings: Array<PaymentSettingsInput>;
  currency: SupportedBillingCurrency;
  licensePaymentSettings: Array<PaymentSettingsInput>;
  phases: Array<PhaseInput>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export class ContractTemplate {
  __typename?: 'ContractTemplate';
  _etag?: Maybe<Scalars['String']['output']>;
  allowances?: Maybe<Array<ContractTemplateAllowance>>;
  benefits?: Maybe<Array<ContractTemplateBenefit>>;
  canBeDeleted: Scalars['Boolean']['output'];
  changedAt: Scalars['DateTime']['output'];
  changedBy: Scalars['String']['output'];
  compensationRate?: Maybe<Scalars['Float']['output']>;
  compensationType?: Maybe<CompensationType>;
  contractType?: Maybe<Scalars['String']['output']>;
  country?: Maybe<SupportedImmigrationCountry>;
  customLaborAgreement?: Maybe<CustomLaborAgreement>;
  employmentRelationshipType?: Maybe<EmploymentRelationshipType>;
  ezbAdditionalInformation?: Maybe<Scalars['String']['output']>;
  holidayEntitlement?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  laborAgreementSelection?: Maybe<LaborAgreementSelection>;
  name: Scalars['String']['output'];
  noticePeriod?: Maybe<Scalars['Float']['output']>;
  noticePeriodUnit?: Maybe<ContractTermTimePeriod>;
  numberOfSalaries?: Maybe<Scalars['Float']['output']>;
  organizationId: Scalars['ID']['output'];
  otherPaymentRegulation?: Maybe<Scalars['String']['output']>;
  overtimeCompensation?: Maybe<Scalars['String']['output']>;
  overtimeHours?: Maybe<Scalars['Float']['output']>;
  overtimeWithinLimitsPermittedByLaw?: Maybe<Scalars['Boolean']['output']>;
  probationPeriod?: Maybe<Scalars['Float']['output']>;
  probationPeriodUnit?: Maybe<ContractTermTimePeriod>;
  professionDescription?: Maybe<Scalars['String']['output']>;
  professionTitle?: Maybe<Scalars['String']['output']>;
  validFrom?: Maybe<Scalars['DateTime']['output']>;
  validUntil?: Maybe<Scalars['DateTime']['output']>;
  workSchedule?: Maybe<Scalars['String']['output']>;
  workingHoursPerWeek?: Maybe<Scalars['Float']['output']>;
  workingTimeType?: Maybe<WorkingTimeTypeEnum>;
};

export class ContractTemplateAllowance {
  __typename?: 'ContractTemplateAllowance';
  amount?: Maybe<Scalars['Float']['output']>;
  calculationBasis?: Maybe<CalculationBasis>;
  comment?: Maybe<Scalars['String']['output']>;
  customType?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  type?: Maybe<Scalars['String']['output']>;
};

export class ContractTemplateAllowanceInput {
  amount?: InputMaybe<Scalars['Float']['input']>;
  calculationBasis?: InputMaybe<CalculationBasis>;
  comment?: InputMaybe<Scalars['String']['input']>;
  customType?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  type?: InputMaybe<Scalars['String']['input']>;
};

export class ContractTemplateBenefit {
  __typename?: 'ContractTemplateBenefit';
  amount?: Maybe<Scalars['Float']['output']>;
  calculationBasis?: Maybe<CalculationBasis>;
  id: Scalars['ID']['output'];
  timeInterval?: Maybe<BenefitTimeInterval>;
  type: Scalars['String']['output'];
};

export class ContractTemplateBenefitInput {
  amount?: InputMaybe<Scalars['Float']['input']>;
  calculationBasis?: InputMaybe<CalculationBasis>;
  id: Scalars['ID']['input'];
  timeInterval?: InputMaybe<BenefitTimeInterval>;
  type: Scalars['String']['input'];
};

export class ContractTemplateDeleteInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
};

export class ContractTemplateGetInput {
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
};

export class ContractTemplateListInput {
  country?: InputMaybe<SupportedImmigrationCountry>;
  organizationId: Scalars['ID']['input'];
};

export class ContractTemplateSetInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  allowances?: InputMaybe<Array<ContractTemplateAllowanceInput>>;
  benefits?: InputMaybe<Array<ContractTemplateBenefitInput>>;
  compensationRate?: InputMaybe<Scalars['Float']['input']>;
  compensationType?: InputMaybe<CompensationType>;
  contractType?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<SupportedImmigrationCountry>;
  customLaborAgreement?: InputMaybe<CustomLaborAgreementInput>;
  employmentRelationshipType?: InputMaybe<EmploymentRelationshipType>;
  ezbAdditionalInformation?: InputMaybe<Scalars['String']['input']>;
  holidayEntitlement?: InputMaybe<Scalars['Float']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  laborAgreementSelection?: InputMaybe<LaborAgreementSelectionInput>;
  name: Scalars['String']['input'];
  noticePeriod?: InputMaybe<Scalars['Float']['input']>;
  noticePeriodUnit?: InputMaybe<ContractTermTimePeriod>;
  numberOfSalaries?: InputMaybe<Scalars['Float']['input']>;
  organizationId: Scalars['ID']['input'];
  otherPaymentRegulation?: InputMaybe<Scalars['String']['input']>;
  overtimeCompensation?: InputMaybe<Scalars['String']['input']>;
  overtimeHours?: InputMaybe<Scalars['Float']['input']>;
  overtimeWithinLimitsPermittedByLaw?: InputMaybe<Scalars['Boolean']['input']>;
  probationPeriod?: InputMaybe<Scalars['Float']['input']>;
  probationPeriodUnit?: InputMaybe<ContractTermTimePeriod>;
  professionDescription?: InputMaybe<Scalars['String']['input']>;
  professionTitle?: InputMaybe<Scalars['String']['input']>;
  validFrom?: InputMaybe<Scalars['DateTime']['input']>;
  validUntil?: InputMaybe<Scalars['DateTime']['input']>;
  workSchedule?: InputMaybe<Scalars['String']['input']>;
  workingHoursPerWeek?: InputMaybe<Scalars['Float']['input']>;
  workingTimeType?: InputMaybe<WorkingTimeTypeEnum>;
};

export enum ContractTemplateSharingMode {
  ShareAll = 'ShareAll',
  ShareSelected = 'ShareSelected'
}

export enum ContractTermTimePeriod {
  Months = 'Months',
  Weeks = 'Weeks'
}

export class ContractValidationError {
  __typename?: 'ContractValidationError';
  path: Scalars['String']['output'];
  reason: ContractValidationErrorReason;
};

export enum ContractValidationErrorReason {
  CandidateDiscountsMustNotBeProvided = 'CandidateDiscountsMustNotBeProvided',
  CandidatePaymentMustBeInArrears = 'CandidatePaymentMustBeInArrears',
  CandidatePaymentMustBeMonthly = 'CandidatePaymentMustBeMonthly',
  LicenseDiscountsMustNotBeProvided = 'LicenseDiscountsMustNotBeProvided',
  MissingCandidatePaymentSettings = 'MissingCandidatePaymentSettings',
  MissingCurrency = 'MissingCurrency',
  MissingLicensePaymentSettings = 'MissingLicensePaymentSettings',
  MissingPhases = 'MissingPhases',
  MissingPlanId = 'MissingPlanId',
  MissingStartDate = 'MissingStartDate',
  MultiplePhasesStartingAtSameDate = 'MultiplePhasesStartingAtSameDate',
  NoPhaseStartingAtStartDate = 'NoPhaseStartingAtStartDate',
  OverlappingDiscountPeriods = 'OverlappingDiscountPeriods',
  PaymentStartDateBeforeContractStartDate = 'PaymentStartDateBeforeContractStartDate',
  PaymentTranchesOnlyForInAdvance = 'PaymentTranchesOnlyForInAdvance',
  PhaseStartDateBeforeContractStartDate = 'PhaseStartDateBeforeContractStartDate',
  PlanIdMustNotBeProvided = 'PlanIdMustNotBeProvided'
}

export class ConvertManyDocumentsInput {
  candidateId: Scalars['ID']['input'];
  familyMemberId?: InputMaybe<Scalars['ID']['input']>;
  language: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  requiredDocumentOrganizationId?: InputMaybe<Scalars['ID']['input']>;
  requiredDocumentSetIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export enum CopyDataTypeEnum {
  All = 'All',
  AutoListTemplates = 'AutoListTemplates',
  Documents = 'Documents',
  Emails = 'Emails',
  RequiredDocuments = 'RequiredDocuments',
  SendOutRules = 'SendOutRules',
  Templates = 'Templates'
}

export class CopyResult {
  __typename?: 'CopyResult';
  failedCopies: Array<Scalars['String']['output']>;
  successfulCopies: Array<Scalars['String']['output']>;
};

export class CourseEventsInput {
  courseId: Scalars['ID']['input'];
  documentTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  eventCategories: Array<Scalars['String']['input']>;
  eventTimeframe: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
};

export class CourseExamDataEvent {
  __typename?: 'CourseExamDataEvent';
  candidateId: Scalars['ID']['output'];
  country?: Maybe<Scalars['String']['output']>;
  date: Scalars['DateTime']['output'];
  displayId: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
  eventType: Scalars['String']['output'];
  examId: Scalars['ID']['output'];
  organizationId: Scalars['ID']['output'];
};

export class CreatedCollectionsOfAutoFilterTemplateGetInput {
  id: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};

export class CustomLaborAgreement {
  __typename?: 'CustomLaborAgreement';
  collectiveAgreement?: Maybe<Scalars['String']['output']>;
  salaryGroup?: Maybe<Scalars['String']['output']>;
};

export class CustomLaborAgreementInput {
  collectiveAgreement?: InputMaybe<Scalars['String']['input']>;
  salaryGroup?: InputMaybe<Scalars['String']['input']>;
};

export class CustomSkillSet {
  __typename?: 'CustomSkillSet';
  additionalInformationLabel?: Maybe<Scalars['String']['output']>;
  additionalInformationTypes?: Maybe<Array<ProfessionalFieldAdditionalInformationType>>;
  description: Scalars['String']['output'];
  preferredLabel: Scalars['String']['output'];
  requireAdditionalInformation?: Maybe<Scalars['Boolean']['output']>;
  skillType: OccupationSkillType;
  skills?: Maybe<Array<OccupationSkill>>;
};

export class CustomSkillSetInput {
  language: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  professionId: Scalars['String']['input'];
};

export class Customer {
  __typename?: 'Customer';
  balance?: Maybe<Scalars['Float']['output']>;
  billingAddress: BillingAddress;
  currency?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  organizationId: Scalars['String']['output'];
};

export class DashboardAnkaadiaUpdates {
  __typename?: 'DashboardAnkaadiaUpdates';
  metricType: MetricType;
  releases: Array<AnkaadiaRelease>;
};

export class DashboardBirthdays {
  __typename?: 'DashboardBirthdays';
  candidates: Array<BirthDayCandidate>;
  metricType: MetricType;
};

export class DashboardCount {
  __typename?: 'DashboardCount';
  days: Scalars['Float']['output'];
  metricType: MetricType;
  value: Scalars['Float']['output'];
};

export class DashboardCountPerDay {
  __typename?: 'DashboardCountPerDay';
  days: Scalars['Float']['output'];
  metricType: MetricType;
  values: Array<Scalars['Float']['output']>;
};

export type DashboardMetric = DashboardAnkaadiaUpdates | DashboardBirthdays | DashboardCount | DashboardCountPerDay;

export class DashboardMetricInput {
  organizationId: Scalars['ID']['input'];
};

export class DataStrategyConfigurationDescription {
  __typename?: 'DataStrategyConfigurationDescription';
  configuration?: Maybe<Scalars['JSONObject']['output']>;
  strategyId: Scalars['String']['output'];
};

export class DataStrategyConfigurationDescriptionInput {
  configuration?: InputMaybe<Scalars['JSONObject']['input']>;
  strategyId: Scalars['String']['input'];
};

export class DataStrategyGenerationDescription {
  configuration?: InputMaybe<Scalars['JSONObject']['input']>;
  strategyId: Scalars['String']['input'];
};

export class DateWithTime {
  __typename?: 'DateWithTime';
  date?: Maybe<Scalars['DateTime']['output']>;
  hasTime?: Maybe<Scalars['Boolean']['output']>;
};

export class DateWithTimeInput {
  date?: InputMaybe<Scalars['DateTime']['input']>;
  hasTime?: InputMaybe<Scalars['Boolean']['input']>;
};

export class DayOfYear {
  __typename?: 'DayOfYear';
  day: Scalars['Float']['output'];
  month: Scalars['Float']['output'];
};

export class DayOfYearInput {
  day: Scalars['Float']['input'];
  month: Scalars['Float']['input'];
};

export class DegressionRow {
  __typename?: 'DegressionRow';
  amount: Scalars['Float']['output'];
  from: Scalars['Float']['output'];
};

export class DeleteUserInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  auth0Id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
};

export class DerivableProcessModelDeclarationGetInput {
  language: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
};

export class DetachProcessFromActionInput {
  actionId: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
  processId: Scalars['String']['input'];
};

export class DiplomaticMission {
  __typename?: 'DiplomaticMission';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  departments?: Maybe<Scalars['String']['output']>;
  fax?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  leader?: Maybe<Scalars['String']['output']>;
  mail?: Maybe<Scalars['String']['output']>;
  misc?: Maybe<Scalars['String']['output']>;
  number?: Maybe<Scalars['String']['output']>;
  open?: Maybe<Scalars['String']['output']>;
  postal?: Maybe<Scalars['String']['output']>;
  remark?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  telefone?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  url?: Maybe<Scalars['String']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};

export class DiplomaticMissionInput {
  country?: InputMaybe<Scalars['String']['input']>;
  immigrationCountry: SupportedImmigrationCountry;
};

export class Discount {
  __typename?: 'Discount';
  endDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  maxUses?: Maybe<Scalars['Float']['output']>;
  startDate: Scalars['DateTime']['output'];
  type: DiscountType;
  value: Scalars['Float']['output'];
};

export class DiscountInput {
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  id: Scalars['ID']['input'];
  maxUses?: InputMaybe<Scalars['Float']['input']>;
  startDate: Scalars['DateTime']['input'];
  type: DiscountType;
  value: Scalars['Float']['input'];
};

export enum DiscountType {
  Absolute = 'Absolute',
  Percentage = 'Percentage'
}

export class DocTypeAttachment {
  __typename?: 'DocTypeAttachment';
  docOrganizationRole?: Maybe<Scalars['String']['output']>;
  documentTag?: Maybe<Scalars['String']['output']>;
  documentType: Scalars['String']['output'];
};

export class DocTypeAttachmentInput {
  docOrganizationRole?: InputMaybe<Scalars['String']['input']>;
  documentTag?: InputMaybe<Scalars['String']['input']>;
  documentType: Scalars['String']['input'];
};

export class Document {
  __typename?: 'Document';
  _etag?: Maybe<Scalars['String']['output']>;
  alwaysGenerate?: Maybe<Scalars['Boolean']['output']>;
  availableForCandidates?: Maybe<Scalars['Boolean']['output']>;
  candidateId?: Maybe<Scalars['ID']['output']>;
  changedAt: Scalars['DateTime']['output'];
  changedBy: Scalars['String']['output'];
  comment?: Maybe<Scalars['String']['output']>;
  completionState?: Maybe<CompletionState>;
  displayName?: Maybe<Scalars['String']['output']>;
  documentGenerationLanguageHandling?: Maybe<DocumentGenerationLanguageHandling>;
  documentSets?: Maybe<Array<DocumentSet>>;
  familyMemberId?: Maybe<Scalars['ID']['output']>;
  freeFormatFiles?: Maybe<Array<DocumentFreeFormatFile>>;
  generationMode?: Maybe<DocumentGenerationMode>;
  generationStrategy?: Maybe<DataStrategyConfigurationDescription>;
  hideCandidateNameInFileName?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isInternalByRequirement: Scalars['Boolean']['output'];
  isInternalDocument?: Maybe<Scalars['Boolean']['output']>;
  lockState?: Maybe<DocumentLockMode>;
  lockTimeStamp?: Maybe<Scalars['ID']['output']>;
  lockingOrganizationId?: Maybe<Scalars['ID']['output']>;
  mode: DocumentMode;
  organizationId: Scalars['ID']['output'];
  selectionCriteria?: Maybe<Array<DocumentSelectionCriterion>>;
  templateMode?: Maybe<DocumentTemplateMode>;
  throwErrorOnNoSelection?: Maybe<Scalars['Boolean']['output']>;
  touchedOnlyByCandidate: Scalars['Boolean']['output'];
  type?: Maybe<Scalars['String']['output']>;
};

export enum DocumentAccess {
  None = 'None',
  ReadDocument = 'ReadDocument',
  ReadDocumentAndReadWriteMetadata = 'ReadDocumentAndReadWriteMetadata',
  ReadDocumentWhenNotInternal = 'ReadDocumentWhenNotInternal',
  ReadDocumentWhenNotInternalAndReadWriteMetadata = 'ReadDocumentWhenNotInternalAndReadWriteMetadata',
  ReadWriteDocument = 'ReadWriteDocument',
  ReadWriteDocumentWhenNotInternal = 'ReadWriteDocumentWhenNotInternal',
  ReadWriteMetadata = 'ReadWriteMetadata'
}

export class DocumentAccessInfo {
  __typename?: 'DocumentAccessInfo';
  access: DocumentAccess;
};

export class DocumentAiExtractionInput {
  candidateId?: InputMaybe<CandidateIdInput>;
  collectionId?: InputMaybe<CollectionIdInput>;
  documentAiUrl: DocumentAiUrlInput;
  fileName: Scalars['String']['input'];
  filters?: InputMaybe<Scalars['JSONObject']['input']>;
  isFavorite?: InputMaybe<Scalars['Boolean']['input']>;
  organizationId: Scalars['ID']['input'];
  requestId: Scalars['ID']['input'];
  selectedSharingId?: InputMaybe<Scalars['ID']['input']>;
};

export class DocumentAiExtractionResult {
  __typename?: 'DocumentAiExtractionResult';
  data?: Maybe<DocumentAiExtractionResultData>;
  done: Scalars['Boolean']['output'];
  error?: Maybe<Scalars['String']['output']>;
  progress: Scalars['Float']['output'];
  requestId: Scalars['ID']['output'];
  success?: Maybe<Scalars['Boolean']['output']>;
};

export class DocumentAiExtractionResultData {
  __typename?: 'DocumentAiExtractionResultData';
  candidateId?: Maybe<CandidateId>;
  candidateOrFamilyMemberFullName?: Maybe<Scalars['String']['output']>;
  documentType?: Maybe<Scalars['String']['output']>;
  familyMemberId?: Maybe<Scalars['ID']['output']>;
  localizedDocumentType?: Maybe<Scalars['String']['output']>;
  organizationId: Scalars['ID']['output'];
  requestId: Scalars['ID']['output'];
};

export class DocumentAiPerformanceCreateInput {
  organizationId: Scalars['ID']['input'];
  performanceEntries: Array<DocumentAiPerformanceInput>;
};

export class DocumentAiPerformanceInput {
  candidateId: CandidateIdInput;
  documentSetId?: InputMaybe<Scalars['String']['input']>;
  documentType: Scalars['String']['input'];
  familyMemberId?: InputMaybe<Scalars['ID']['input']>;
  fileId: Scalars['ID']['input'];
  fileName: Scalars['String']['input'];
  fileType: Scalars['String']['input'];
  fileUrl: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  predictedCandidateId?: InputMaybe<CandidateIdInput>;
  predictedDocumentType?: InputMaybe<Scalars['String']['input']>;
  predictedFamilyMemberId?: InputMaybe<Scalars['ID']['input']>;
  predictionFinished: Scalars['Boolean']['input'];
  predictionSuccess: Scalars['Boolean']['input'];
};

export class DocumentAiUploadInput {
  blob: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  timeSpanInHours?: InputMaybe<Scalars['Float']['input']>;
};

export class DocumentAiUrl {
  __typename?: 'DocumentAiUrl';
  containerName: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export class DocumentAiUrlInput {
  containerName: Scalars['String']['input'];
  url: Scalars['String']['input'];
};

export class DocumentCandidateStatus {
  __typename?: 'DocumentCandidateStatus';
  additionalFormatsInDocumentSets?: Maybe<Array<DocumentRelatedFormats>>;
  candidateId: Scalars['ID']['output'];
  candidateOrgId: Scalars['ID']['output'];
  completionState?: Maybe<CompletionState>;
  missingFormats?: Maybe<Array<Scalars['String']['output']>>;
  missingPhysicalFormats?: Maybe<Array<Scalars['String']['output']>>;
  status: DocumentStatusEnum;
};

export class DocumentConfigFormat {
  __typename?: 'DocumentConfigFormat';
  count?: Maybe<Scalars['Float']['output']>;
  requiredDeliveryFormats?: Maybe<Array<DocumentDeliveryFormat>>;
  value?: Maybe<Scalars['String']['output']>;
};

export class DocumentConfigFormatInput {
  count?: InputMaybe<Scalars['Float']['input']>;
  requiredDeliveryFormats?: InputMaybe<Array<DocumentDeliveryFormat>>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export interface DocumentConfigurationData {
  countryOfOrigin: Array<Scalars['String']['output']>;
  documentFormat?: Maybe<Array<DocumentConfigFormat>>;
  documentFormatOther?: Maybe<Array<DocumentConfigFormat>>;
  documentSetType?: Maybe<Scalars['String']['output']>;
  documentType: Scalars['String']['output'];
  employerAfterRecognitionIds?: Maybe<Array<Scalars['String']['output']>>;
  employerBeforeRecognitionIds?: Maybe<Array<Scalars['String']['output']>>;
  federalStateDestination: Array<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isOptional?: Maybe<Scalars['Boolean']['output']>;
  notNecessary: Scalars['Boolean']['output'];
  profession: Array<Scalars['String']['output']>;
}

export class DocumentConfigurationDataAt implements DocumentConfigurationData {
  __typename?: 'DocumentConfigurationDataAT';
  countryOfOrigin: Array<Scalars['String']['output']>;
  documentFormat?: Maybe<Array<DocumentConfigFormat>>;
  documentFormatOther?: Maybe<Array<DocumentConfigFormat>>;
  documentSetType?: Maybe<Scalars['String']['output']>;
  documentType: Scalars['String']['output'];
  employerAfterRecognitionIds?: Maybe<Array<Scalars['String']['output']>>;
  employerBeforeRecognitionIds?: Maybe<Array<Scalars['String']['output']>>;
  federalStateDestination: Array<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isOptional?: Maybe<Scalars['Boolean']['output']>;
  legalBasis?: Maybe<Array<Scalars['String']['output']>>;
  notNecessary: Scalars['Boolean']['output'];
  pathOfRecognition?: Maybe<Array<Scalars['String']['output']>>;
  profession: Array<Scalars['String']['output']>;
  targetRecognition?: Maybe<Array<Scalars['String']['output']>>;
};

export class DocumentConfigurationDataDe implements DocumentConfigurationData {
  __typename?: 'DocumentConfigurationDataDE';
  countryOfOrigin: Array<Scalars['String']['output']>;
  documentFormat?: Maybe<Array<DocumentConfigFormat>>;
  documentFormatOther?: Maybe<Array<DocumentConfigFormat>>;
  documentSetType?: Maybe<Scalars['String']['output']>;
  documentType: Scalars['String']['output'];
  employerAfterRecognitionIds?: Maybe<Array<Scalars['String']['output']>>;
  employerBeforeRecognitionIds?: Maybe<Array<Scalars['String']['output']>>;
  equivalenceTest?: Maybe<Array<Scalars['String']['output']>>;
  federalStateDestination: Array<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isOptional?: Maybe<Scalars['Boolean']['output']>;
  notNecessary: Scalars['Boolean']['output'];
  profession: Array<Scalars['String']['output']>;
  recognitionType?: Maybe<Array<Scalars['String']['output']>>;
  residencePermit?: Maybe<Array<Scalars['String']['output']>>;
};

export class DocumentConfigurationInputType {
  countryOfOrigin: Array<Scalars['String']['input']>;
  documentFormat?: InputMaybe<Array<DocumentConfigFormatInput>>;
  documentFormatOther?: InputMaybe<Array<DocumentConfigFormatInput>>;
  documentSetType?: InputMaybe<Scalars['String']['input']>;
  documentType: Scalars['String']['input'];
  employerAfterRecognitionIds?: InputMaybe<Array<Scalars['String']['input']>>;
  employerBeforeRecognitionIds?: InputMaybe<Array<Scalars['String']['input']>>;
  equivalenceTest?: InputMaybe<Array<Scalars['String']['input']>>;
  federalStateDestination: Array<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  isOptional?: InputMaybe<Scalars['Boolean']['input']>;
  legalBasis?: InputMaybe<Array<Scalars['String']['input']>>;
  notNecessary: Scalars['Boolean']['input'];
  pathOfRecognition?: InputMaybe<Array<Scalars['String']['input']>>;
  profession: Array<Scalars['String']['input']>;
  recognitionType?: InputMaybe<Array<Scalars['String']['input']>>;
  residencePermit?: InputMaybe<Array<Scalars['String']['input']>>;
  targetRecognition?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type DocumentConfigurationResultType = DocumentConfigurationDataAt | DocumentConfigurationDataDe;

export class DocumentCreateInput {
  alwaysGenerate?: InputMaybe<Scalars['Boolean']['input']>;
  availableForCandidates?: InputMaybe<Scalars['Boolean']['input']>;
  candidateId?: InputMaybe<Scalars['ID']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  completionState?: InputMaybe<CompletionState>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  documentGenerationLanguageHandling?: InputMaybe<DocumentGenerationLanguageHandling>;
  documentSets?: InputMaybe<Array<DocumentSetInput>>;
  familyMemberId?: InputMaybe<Scalars['ID']['input']>;
  freeFormatFiles?: InputMaybe<Array<DocumentFreeFormatFileInput>>;
  generationMode?: InputMaybe<DocumentGenerationMode>;
  generationStrategy?: InputMaybe<DataStrategyConfigurationDescriptionInput>;
  hideCandidateNameInFileName?: InputMaybe<Scalars['Boolean']['input']>;
  ignoreConcurrencyError?: InputMaybe<Scalars['Boolean']['input']>;
  isInternalDocument?: InputMaybe<Scalars['Boolean']['input']>;
  lockState?: InputMaybe<DocumentLockMode>;
  lockTimeStamp?: InputMaybe<Scalars['ID']['input']>;
  lockingOrganizationId?: InputMaybe<Scalars['ID']['input']>;
  mode: DocumentMode;
  organizationId: Scalars['ID']['input'];
  selectionCriteria?: InputMaybe<Array<DocumentSelectionCriterion>>;
  templateMode?: InputMaybe<DocumentTemplateMode>;
  throwErrorOnNoSelection?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  writeMode: DocumentWriteMode;
};

export class DocumentDataEvent {
  __typename?: 'DocumentDataEvent';
  candidateId: Scalars['ID']['output'];
  country?: Maybe<Scalars['String']['output']>;
  date: Scalars['DateTime']['output'];
  displayId: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
  documentField: Scalars['String']['output'];
  documentId: Scalars['ID']['output'];
  documentSetName?: Maybe<Scalars['String']['output']>;
  documentSetType?: Maybe<Scalars['String']['output']>;
  documentType: Scalars['String']['output'];
  eventType: Scalars['String']['output'];
  isDefaultSet: Scalars['Boolean']['output'];
  isInternalDocument?: Maybe<Scalars['Boolean']['output']>;
  isShared: Scalars['Boolean']['output'];
  organizationId: Scalars['ID']['output'];
};

export class DocumentDeleteInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  candidateId?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
};

export enum DocumentDeliveryFormat {
  Digital = 'Digital',
  Paper = 'Paper'
}

export class DocumentFile {
  __typename?: 'DocumentFile';
  blob: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  name: Scalars['String']['output'];
  selectionValues?: Maybe<Array<Array<Scalars['String']['output']>>>;
  singleSetConfig?: Maybe<Array<SingleSetConfig>>;
  size: Scalars['Float']['output'];
  tags?: Maybe<Array<Scalars['String']['output']>>;
  type: Scalars['String']['output'];
};

export class DocumentFileInput {
  blob: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  name: Scalars['String']['input'];
  selectionValues?: InputMaybe<Array<Array<Scalars['String']['input']>>>;
  singleSetConfig?: InputMaybe<Array<SingleSetConfigInput>>;
  size: Scalars['Float']['input'];
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
  type: Scalars['String']['input'];
};

export enum DocumentFinishingMode {
  Detail = 'Detail',
  Global = 'Global'
}

export class DocumentFormatEqualityMapping {
  __typename?: 'DocumentFormatEqualityMapping';
  equalDocumentFormats: Array<Scalars['String']['output']>;
  sourceDocumentFormat: Scalars['String']['output'];
};

export class DocumentFormatEqualityMappingInput {
  equalDocumentFormats: Array<Scalars['String']['input']>;
  sourceDocumentFormat: Scalars['String']['input'];
};

export class DocumentFormats {
  __typename?: 'DocumentFormats';
  availableFormats: Array<Scalars['String']['output']>;
  formatsUploadedAsDocumentSets: Array<Scalars['String']['output']>;
};

export class DocumentFormatsInput {
  candidateId?: InputMaybe<Scalars['ID']['input']>;
  familyMemberId?: InputMaybe<Scalars['ID']['input']>;
  immigrationCountry?: InputMaybe<SupportedImmigrationCountry>;
  organizationId: Scalars['ID']['input'];
  type?: InputMaybe<Scalars['String']['input']>;
};

export class DocumentFreeFormatFile {
  __typename?: 'DocumentFreeFormatFile';
  blob: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  name: Scalars['String']['output'];
  size: Scalars['Float']['output'];
  type: Scalars['String']['output'];
};

export class DocumentFreeFormatFileInput {
  blob: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  name: Scalars['String']['input'];
  size: Scalars['Float']['input'];
  type: Scalars['String']['input'];
};

export enum DocumentGenerationLanguageHandling {
  BestFittingOnly = 'BestFittingOnly',
  ImmigrationCountryLanguagePlusAll = 'ImmigrationCountryLanguagePlusAll',
  ImmigrationCountryLanguagePlusNextBest = 'ImmigrationCountryLanguagePlusNextBest'
}

export enum DocumentGenerationMode {
  AllCandidates = 'AllCandidates',
  OneCandidate = 'OneCandidate'
}

export class DocumentGetByTypeInput {
  candidateId?: InputMaybe<Scalars['ID']['input']>;
  familyMemberId?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
  type?: InputMaybe<Scalars['String']['input']>;
};

export class DocumentGetByTypesInput {
  candidateId?: InputMaybe<Scalars['ID']['input']>;
  familyMemberId?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
  types: Array<Scalars['String']['input']>;
};

export class DocumentGetInput {
  candidateId?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
};

export class DocumentListInput {
  candidateId?: InputMaybe<Scalars['ID']['input']>;
  collectionId?: InputMaybe<Scalars['ID']['input']>;
  collectionOrganizationId?: InputMaybe<Scalars['ID']['input']>;
  familyMemberId?: InputMaybe<Scalars['ID']['input']>;
  mode: DocumentMode;
  organizationId: Scalars['ID']['input'];
  requiredDocumentSetIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  requiredDocumentsOrgId?: InputMaybe<Scalars['ID']['input']>;
};

export class DocumentLoadInput {
  blob: Scalars['String']['input'];
  candidateId?: InputMaybe<Scalars['ID']['input']>;
  fromImageContainer?: InputMaybe<Scalars['Boolean']['input']>;
  organizationId: Scalars['ID']['input'];
  timeSpanInHours?: InputMaybe<Scalars['Float']['input']>;
};

export class DocumentLoadWithContainerInput {
  blob: Scalars['String']['input'];
  candidateId?: InputMaybe<Scalars['ID']['input']>;
  container: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  timeSpanInHours?: InputMaybe<Scalars['Float']['input']>;
};

export class DocumentLoadWithFileNameInput {
  blob: Scalars['String']['input'];
  candidateId?: InputMaybe<Scalars['ID']['input']>;
  language: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
};

export class DocumentLockAllInput {
  candidateId?: InputMaybe<Scalars['ID']['input']>;
  familyMemberId?: InputMaybe<Scalars['ID']['input']>;
  lockMode: DocumentLockMode;
  lockingOrganizationId: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
};

export class DocumentLockInput {
  candidateId?: InputMaybe<Scalars['ID']['input']>;
  documents: Array<DocumentLockItemInput>;
  lockMode: DocumentLockMode;
  lockingOrganizationId: Scalars['String']['input'];
};

export class DocumentLockItemInput {
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
};

export enum DocumentLockMode {
  LockedByAProcessOwner = 'LockedByAProcessOwner',
  LockedByCandidateOwner = 'LockedByCandidateOwner',
  Unlocked = 'Unlocked'
}

export enum DocumentMode {
  Candidate = 'Candidate',
  Organization = 'Organization',
  Template = 'Template'
}

export class DocumentOverviewInputCandidate {
  candidateId: Scalars['ID']['input'];
  consideredDeliveryFormats: Array<DocumentDeliveryFormat>;
  documentTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  language: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
  requiredDocumentSetIds: Array<Scalars['ID']['input']>;
  requiredDocumentSetOrganizationId: Scalars['ID']['input'];
};

export class DocumentOverviewInputCollection {
  candidateIds?: InputMaybe<Array<Scalars['String']['input']>>;
  collectionId?: InputMaybe<Scalars['ID']['input']>;
  consideredDeliveryFormats: Array<DocumentDeliveryFormat>;
  documentTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  language: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
  requiredDocumentSetIds: Array<Scalars['ID']['input']>;
  requiredDocumentSetOrganizationId: Scalars['ID']['input'];
};

export class DocumentOverviewInputProcess {
  candidateIds?: InputMaybe<Array<Scalars['String']['input']>>;
  consideredDeliveryFormats: Array<DocumentDeliveryFormat>;
  documentTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  language: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
  processId: Scalars['ID']['input'];
};

export class DocumentProcessUploadType {
  __typename?: 'DocumentProcessUploadType';
  displayDescription?: Maybe<Array<ProcessModelString>>;
  displayName: Array<ProcessModelString>;
  documentId: Scalars['String']['output'];
  documentType?: Maybe<Scalars['String']['output']>;
  fileformats: Array<Scalars['String']['output']>;
};

export class DocumentProcessUploadTypeInput {
  displayDescription?: InputMaybe<Array<ProcessModelStringInput>>;
  displayName: Array<ProcessModelStringInput>;
  documentId: Scalars['String']['input'];
  documentType?: InputMaybe<Scalars['String']['input']>;
  fileformats: Array<Scalars['String']['input']>;
};

export enum DocumentPurpose {
  Recognition = 'Recognition',
  Visum = 'Visum'
}

export class DocumentRelatedFormats {
  __typename?: 'DocumentRelatedFormats';
  documentType: Scalars['String']['output'];
  formats?: Maybe<Array<Scalars['String']['output']>>;
};

export class DocumentRequirement {
  __typename?: 'DocumentRequirement';
  _etag?: Maybe<Scalars['String']['output']>;
  changedAt: Scalars['DateTime']['output'];
  changedBy: Scalars['String']['output'];
  checkSpecificSets: Scalars['Boolean']['output'];
  comment: Scalars['Boolean']['output'];
  completionState: Scalars['Boolean']['output'];
  dateOfReceipt: Scalars['Boolean']['output'];
  documentSetTypes?: Maybe<Array<Scalars['String']['output']>>;
  files: Scalars['Boolean']['output'];
  id?: Maybe<Scalars['ID']['output']>;
  isInternal: Scalars['Boolean']['output'];
  issueDate: Scalars['Boolean']['output'];
  organizationId: Scalars['ID']['output'];
  physicalTypes: Scalars['Boolean']['output'];
  resubmissionDate: Scalars['Boolean']['output'];
  resubmissionReason: Scalars['Boolean']['output'];
  type: Scalars['String']['output'];
  validFrom: Scalars['Boolean']['output'];
  validUntil: Scalars['Boolean']['output'];
};

export class DocumentRequirementGetByTypeInput {
  organizationId: Scalars['ID']['input'];
  type: Scalars['String']['input'];
};

export class DocumentRequirementGetInput {
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
};

export class DocumentRequirementInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  changedAt: Scalars['DateTime']['input'];
  changedBy: Scalars['String']['input'];
  checkSpecificSets?: Scalars['Boolean']['input'];
  comment?: Scalars['Boolean']['input'];
  completionState?: Scalars['Boolean']['input'];
  dateOfReceipt?: Scalars['Boolean']['input'];
  documentSetTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  files?: Scalars['Boolean']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  isInternal?: Scalars['Boolean']['input'];
  issueDate?: Scalars['Boolean']['input'];
  organizationId: Scalars['ID']['input'];
  physicalTypes?: Scalars['Boolean']['input'];
  resubmissionDate?: Scalars['Boolean']['input'];
  resubmissionReason?: Scalars['Boolean']['input'];
  type: Scalars['String']['input'];
  validFrom?: Scalars['Boolean']['input'];
  validUntil?: Scalars['Boolean']['input'];
};

export class DocumentRequirementListInput {
  organizationId: Scalars['ID']['input'];
};

export class DocumentRequirementType {
  __typename?: 'DocumentRequirementType';
  deliveryFormat?: Maybe<DocumentDeliveryFormat>;
  description?: Maybe<Array<ProcessOptionalString>>;
  documentSetType?: Maybe<Scalars['String']['output']>;
  documentType: Scalars['String']['output'];
  fileformats: Array<Scalars['String']['output']>;
  minfileFormatsRequired?: Maybe<Scalars['Float']['output']>;
  requireNewUpload?: Maybe<Scalars['Boolean']['output']>;
};

export class DocumentRequirementTypeInput {
  deliveryFormat?: InputMaybe<DocumentDeliveryFormat>;
  description?: InputMaybe<Array<ProcessOptionalStringInput>>;
  documentSetType?: InputMaybe<Scalars['String']['input']>;
  documentType: Scalars['String']['input'];
  fileformats: Array<Scalars['String']['input']>;
  minfileFormatsRequired?: InputMaybe<Scalars['Float']['input']>;
  requireNewUpload?: InputMaybe<Scalars['Boolean']['input']>;
};

export class DocumentRequirementUpsertInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  checkSpecificSets?: Scalars['Boolean']['input'];
  comment?: Scalars['Boolean']['input'];
  completionState?: Scalars['Boolean']['input'];
  dateOfReceipt?: Scalars['Boolean']['input'];
  documentSetTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  files?: Scalars['Boolean']['input'];
  isInternal?: Scalars['Boolean']['input'];
  issueDate?: Scalars['Boolean']['input'];
  physicalTypes?: Scalars['Boolean']['input'];
  resubmissionDate?: Scalars['Boolean']['input'];
  resubmissionReason?: Scalars['Boolean']['input'];
  type: Scalars['String']['input'];
  validFrom?: Scalars['Boolean']['input'];
  validUntil?: Scalars['Boolean']['input'];
};

export class DocumentRequirementsUpsertInput {
  organizationId: Scalars['ID']['input'];
  requirements: Array<DocumentRequirementUpsertInput>;
};

export enum DocumentSelectionCriterion {
  CountryOfOrigin = 'CountryOfOrigin',
  DiplomaticMission = 'DiplomaticMission',
  Employer = 'Employer',
  EmployerAfterRecognition = 'EmployerAfterRecognition',
  FederalState = 'FederalState',
  Function = 'Function',
  ImmigrationAuthority = 'ImmigrationAuthority',
  ImmigrationCountry = 'ImmigrationCountry',
  Language = 'Language',
  Profession = 'Profession',
  QualificationMeasure = 'QualificationMeasure',
  RecognitionAuthority = 'RecognitionAuthority',
  Source = 'Source'
}

export class DocumentSet {
  __typename?: 'DocumentSet';
  dateOfReceipt?: Maybe<Scalars['DateTime']['output']>;
  files?: Maybe<Array<DocumentFile>>;
  foreignKey?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  isDefaultSet: Scalars['Boolean']['output'];
  issueDate?: Maybe<Scalars['DateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  physicalTypes?: Maybe<Array<Scalars['String']['output']>>;
  resubmissionDate?: Maybe<Scalars['DateTime']['output']>;
  resubmissionReason?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  validFrom?: Maybe<Scalars['DateTime']['output']>;
  validUntil?: Maybe<Scalars['DateTime']['output']>;
};

export class DocumentSetInput {
  dateOfReceipt?: InputMaybe<Scalars['DateTime']['input']>;
  files?: InputMaybe<Array<DocumentFileInput>>;
  foreignKey?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
  isDefaultSet: Scalars['Boolean']['input'];
  issueDate?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  physicalTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  resubmissionDate?: InputMaybe<Scalars['DateTime']['input']>;
  resubmissionReason?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  validFrom?: InputMaybe<Scalars['DateTime']['input']>;
  validUntil?: InputMaybe<Scalars['DateTime']['input']>;
};

export class DocumentSharingInput {
  organizationId: Scalars['ID']['input'];
  sharingId: Scalars['ID']['input'];
};

export class DocumentStatus {
  __typename?: 'DocumentStatus';
  candidateStatus: Array<DocumentCandidateStatus>;
  documentName: Scalars['String']['output'];
  documentType: Scalars['String']['output'];
  isOptional: Scalars['Boolean']['output'];
  requiredFormats: Array<Scalars['String']['output']>;
};

export enum DocumentStatusEnum {
  Available = 'Available',
  AvailableButExpired = 'AvailableButExpired',
  Missing = 'Missing',
  MissingButStatusTakenOver = 'MissingButStatusTakenOver',
  MissingNotRequired = 'MissingNotRequired',
  NotShared = 'NotShared',
  Partial = 'Partial'
}

export class DocumentTemplate {
  __typename?: 'DocumentTemplate';
  _etag?: Maybe<Scalars['String']['output']>;
  blob?: Maybe<Scalars['String']['output']>;
  candidateDisplayId?: Maybe<Scalars['String']['output']>;
  candidateDisplayName?: Maybe<Scalars['String']['output']>;
  candidateId: Scalars['ID']['output'];
  candidateOrganizationId: Scalars['ID']['output'];
  candidateOriginCountry?: Maybe<Scalars['String']['output']>;
  changedAt?: Maybe<Scalars['DateTime']['output']>;
  changedBy?: Maybe<Scalars['String']['output']>;
  container?: Maybe<Scalars['String']['output']>;
  fileName?: Maybe<Scalars['String']['output']>;
  fileSize?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name: Scalars['String']['output'];
  organizationId: Scalars['ID']['output'];
  status: DocumentTemplateStatus;
  throwErrorOnNoSelection?: Maybe<Scalars['Boolean']['output']>;
  type: DocumentTemplateType;
  warnings?: Maybe<Array<DocumentTemplateWarning>>;
};

export class DocumentTemplateCandidateInput {
  candidateId: Scalars['ID']['input'];
  candidateOrganizationId: Scalars['ID']['input'];
  collectionId?: InputMaybe<Scalars['String']['input']>;
  collectionOrganizationId?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['ID']['input'];
};

export class DocumentTemplateCollectionInput {
  collectionId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
  templates: Array<AvailableDocumentTemplateInput>;
};

export class DocumentTemplateEditedInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
  type: DocumentTemplateType;
};

export class DocumentTemplateGenerateInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  candidateId: Scalars['ID']['input'];
  candidateOrganizationId: Scalars['ID']['input'];
  collectionId?: InputMaybe<Scalars['String']['input']>;
  collectionOrganizationId?: InputMaybe<Scalars['String']['input']>;
  conversionType?: InputMaybe<TemplateToDocumentTypeConversion>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  type: DocumentTemplateType;
};

export class DocumentTemplateGetInput {
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
  parameters?: InputMaybe<Scalars['JSONObject']['input']>;
  type: DocumentTemplateType;
};

export enum DocumentTemplateMode {
  Docx = 'Docx',
  Json = 'Json',
  Xlsx = 'Xlsx'
}

export enum DocumentTemplateStatus {
  Deprecated = 'Deprecated',
  Edited = 'Edited',
  Error = 'Error',
  InProgress = 'InProgress',
  NotAvailable = 'NotAvailable',
  NotGenerated = 'NotGenerated',
  Success = 'Success',
  Warning = 'Warning'
}

export enum DocumentTemplateType {
  Docx = 'Docx',
  Json = 'Json',
  Pdf = 'Pdf',
  Xlsx = 'Xlsx'
}

export class DocumentTemplateWarning {
  __typename?: 'DocumentTemplateWarning';
  parameters?: Maybe<Scalars['JSONObject']['output']>;
  translationKey: Scalars['String']['output'];
};

export class DocumentTemplateWarningInput {
  parameters?: InputMaybe<Scalars['JSONObject']['input']>;
  translationKey: Scalars['String']['input'];
};

export class DocumentThumbnailInput {
  candidateId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};

export class DocumentTypeOverwrite {
  __typename?: 'DocumentTypeOverwrite';
  _etag?: Maybe<Scalars['String']['output']>;
  changedAt?: Maybe<Scalars['DateTime']['output']>;
  changedBy?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  fileName: Scalars['String']['output'];
  id?: Maybe<Scalars['ID']['output']>;
  name: Scalars['String']['output'];
  organizationId: Scalars['ID']['output'];
  type: Scalars['String']['output'];
};

export class DocumentTypeOverwriteDeleteInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
};

export class DocumentTypeOverwriteGetInput {
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
};

export class DocumentTypeOverwriteUpsertInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  changedAt?: InputMaybe<Scalars['DateTime']['input']>;
  changedBy?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  fileName: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  language: Scalars['String']['input'];
  name: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  type: Scalars['String']['input'];
};

export class DocumentUpdateInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  alwaysGenerate?: InputMaybe<Scalars['Boolean']['input']>;
  availableForCandidates?: InputMaybe<Scalars['Boolean']['input']>;
  candidateId?: InputMaybe<Scalars['ID']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  completionState?: InputMaybe<CompletionState>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  documentGenerationLanguageHandling?: InputMaybe<DocumentGenerationLanguageHandling>;
  documentSets?: InputMaybe<Array<DocumentSetInput>>;
  familyMemberId?: InputMaybe<Scalars['ID']['input']>;
  freeFormatFiles?: InputMaybe<Array<DocumentFreeFormatFileInput>>;
  generationMode?: InputMaybe<DocumentGenerationMode>;
  generationStrategy?: InputMaybe<DataStrategyConfigurationDescriptionInput>;
  hideCandidateNameInFileName?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ignoreConcurrencyError?: InputMaybe<Scalars['Boolean']['input']>;
  isInternalDocument?: InputMaybe<Scalars['Boolean']['input']>;
  lockState?: InputMaybe<DocumentLockMode>;
  lockTimeStamp?: InputMaybe<Scalars['ID']['input']>;
  lockingOrganizationId?: InputMaybe<Scalars['ID']['input']>;
  mode: DocumentMode;
  organizationId: Scalars['ID']['input'];
  selectionCriteria?: InputMaybe<Array<DocumentSelectionCriterion>>;
  templateMode?: InputMaybe<DocumentTemplateMode>;
  throwErrorOnNoSelection?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  writeMode: DocumentWriteMode;
};

export class DocumentUpsertInput {
  alwaysGenerate?: InputMaybe<Scalars['Boolean']['input']>;
  availableForCandidates?: InputMaybe<Scalars['Boolean']['input']>;
  candidateId?: InputMaybe<Scalars['ID']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  completionState?: InputMaybe<CompletionState>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  documentGenerationLanguageHandling?: InputMaybe<DocumentGenerationLanguageHandling>;
  documentSets?: InputMaybe<Array<DocumentSetInput>>;
  familyMemberId?: InputMaybe<Scalars['ID']['input']>;
  freeFormatFiles?: InputMaybe<Array<DocumentFreeFormatFileInput>>;
  generationMode?: InputMaybe<DocumentGenerationMode>;
  generationStrategy?: InputMaybe<DataStrategyConfigurationDescriptionInput>;
  hideCandidateNameInFileName?: InputMaybe<Scalars['Boolean']['input']>;
  isInternalDocument?: InputMaybe<Scalars['Boolean']['input']>;
  lockState?: InputMaybe<DocumentLockMode>;
  lockTimeStamp?: InputMaybe<Scalars['ID']['input']>;
  lockingOrganizationId?: InputMaybe<Scalars['ID']['input']>;
  mode: DocumentMode;
  organizationId: Scalars['ID']['input'];
  selectionCriteria?: InputMaybe<Array<DocumentSelectionCriterion>>;
  templateMode?: InputMaybe<DocumentTemplateMode>;
  throwErrorOnNoSelection?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  writeMode: DocumentWriteMode;
};

export class DocumentUrl {
  __typename?: 'DocumentUrl';
  containerName: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export class DocumentUrlAndFileName {
  __typename?: 'DocumentUrlAndFileName';
  fileName: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export class DocumentValidationInput {
  candidateId?: InputMaybe<Scalars['ID']['input']>;
  files: Array<DocumentValidationInputFile>;
  generationMode: DocumentGenerationMode;
  organizationId: Scalars['ID']['input'];
  templateMode?: InputMaybe<DocumentTemplateMode>;
};

export class DocumentValidationInputFile {
  blob: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export enum DocumentWriteMode {
  Full = 'Full',
  Metadata = 'Metadata'
}

export class DownloadDocumentTemplateCandidateInput {
  candidateId: Scalars['ID']['input'];
  candidateOrganizationId: Scalars['ID']['input'];
  collectionId?: InputMaybe<Scalars['String']['input']>;
  collectionOrganizationId?: InputMaybe<Scalars['String']['input']>;
  inAllFormats?: InputMaybe<Scalars['Boolean']['input']>;
  organizationId: Scalars['ID']['input'];
};

export class DownloadDocumentTemplateCollectionInput {
  collectionId: Scalars['ID']['input'];
  inAllFormats?: InputMaybe<Scalars['Boolean']['input']>;
  organizationId: Scalars['ID']['input'];
  templates: Array<AvailableDocumentTemplateInput>;
};

export class DownloadableFile {
  __typename?: 'DownloadableFile';
  candidateId?: Maybe<Scalars['ID']['output']>;
  containerName: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deleted?: Maybe<Scalars['Boolean']['output']>;
  fileId: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  organizationId: Scalars['ID']['output'];
  size: Scalars['Float']['output'];
  templateName?: Maybe<Scalars['String']['output']>;
  templateType?: Maybe<DocumentTemplateType>;
};

export class DownloadableFilestInput {
  candidateId?: InputMaybe<Scalars['ID']['input']>;
  containerName: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  fileId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  size: Scalars['Float']['input'];
  templateName?: InputMaybe<Scalars['String']['input']>;
  templateType?: InputMaybe<DocumentTemplateType>;
};

export enum EzbDataSharingMode {
  Share = 'Share'
}

export class EzbInfo {
  __typename?: 'EZBInfo';
  detailsOfSocialSecurityIssues?: Maybe<Scalars['String']['output']>;
  hasInsolvencyProceedingInLast5Years?: Maybe<Scalars['Boolean']['output']>;
  hasInsolvencyProceedingLackedAssets?: Maybe<Scalars['Boolean']['output']>;
  hasPendingSecurityPayments?: Maybe<Scalars['Boolean']['output']>;
  hasSocialSecurityIssuesInLast5Years?: Maybe<Scalars['Boolean']['output']>;
};

export class EzbInfoInput {
  detailsOfSocialSecurityIssues?: InputMaybe<Scalars['String']['input']>;
  hasInsolvencyProceedingInLast5Years?: InputMaybe<Scalars['Boolean']['input']>;
  hasInsolvencyProceedingLackedAssets?: InputMaybe<Scalars['Boolean']['input']>;
  hasPendingSecurityPayments?: InputMaybe<Scalars['Boolean']['input']>;
  hasSocialSecurityIssuesInLast5Years?: InputMaybe<Scalars['Boolean']['input']>;
};

export class EducationCourse {
  __typename?: 'EducationCourse';
  _etag?: Maybe<Scalars['String']['output']>;
  changedAt: Scalars['DateTime']['output'];
  changedBy: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  endDate: Scalars['DateTime']['output'];
  id?: Maybe<Scalars['ID']['output']>;
  languageLevel?: Maybe<Scalars['String']['output']>;
  maxParticipants: Scalars['Float']['output'];
  moduleId: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  organizationId: Scalars['ID']['output'];
  participantCollectionId: Scalars['ID']['output'];
  participantCount: Scalars['Float']['output'];
  startDate: Scalars['DateTime']['output'];
  teacherUserIds: Array<Scalars['ID']['output']>;
};

export enum EducationCourseAttendance {
  Absent = 'Absent',
  PartiallyPresent = 'PartiallyPresent',
  Present = 'Present'
}

export class EducationCourseCandidatePerformance {
  __typename?: 'EducationCourseCandidatePerformance';
  candidateDisplayId: Scalars['String']['output'];
  candidateDisplayName: Scalars['String']['output'];
  candidateId: Scalars['ID']['output'];
  candidateOrganizationId: Scalars['ID']['output'];
  candidateState?: Maybe<EducationCourseCandidateStatus>;
  weeks: Array<EducationCourseCandidateWeeklyPerformance>;
};

export enum EducationCourseCandidateStatus {
  InterventionRequired = 'InterventionRequired',
  OnTrack = 'OnTrack',
  Warning = 'Warning'
}

export class EducationCourseCandidateWeeklyPerformance {
  __typename?: 'EducationCourseCandidateWeeklyPerformance';
  attendance?: Maybe<EducationCourseAttendance>;
  comment?: Maybe<Scalars['String']['output']>;
  performance?: Maybe<EducationCoursePerformanceStatus>;
  week: EducationCourseWeek;
};

export class EducationCourseCandidateWeeklyPerformanceView {
  __typename?: 'EducationCourseCandidateWeeklyPerformanceView';
  attendance?: Maybe<EducationCourseAttendance>;
  comment?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  performance?: Maybe<EducationCoursePerformanceStatus>;
  week: EducationCourseWeek;
};

export class EducationCourseCreateInput {
  description?: InputMaybe<Scalars['String']['input']>;
  endDate: Scalars['DateTime']['input'];
  maxParticipants: Scalars['Float']['input'];
  moduleId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  participantCollectionId: Scalars['ID']['input'];
  startDate: Scalars['DateTime']['input'];
  teacherUserIds: Array<Scalars['ID']['input']>;
};

export class EducationCourseDeleteInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
};

export class EducationCourseGetInput {
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
};

export class EducationCourseListForCandidateInput {
  candidateId: Scalars['ID']['input'];
  candidateOrganizationId: Scalars['ID']['input'];
};

export class EducationCourseListInput {
  organizationId: Scalars['ID']['input'];
};

export class EducationCoursePerformance {
  __typename?: 'EducationCoursePerformance';
  candidates: Array<EducationCourseCandidatePerformance>;
  courseId: Scalars['ID']['output'];
  currentWeek: EducationCourseWeek;
  organizationId: Scalars['ID']['output'];
  weeks: Array<EducationCourseWeek>;
};

export class EducationCoursePerformanceByCandidate {
  __typename?: 'EducationCoursePerformanceByCandidate';
  candidateState?: Maybe<EducationCourseCandidateStatus>;
  courseId: Scalars['ID']['output'];
  weeks: Array<EducationCourseCandidateWeeklyPerformanceView>;
};

export class EducationCoursePerformanceByCandidateGetInput {
  candidateId: Scalars['ID']['input'];
  candidateOrganizationId: Scalars['ID']['input'];
  courseId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};

export class EducationCoursePerformanceListInput {
  courseId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};

export enum EducationCoursePerformanceStatus {
  Average = 'Average',
  Bad = 'Bad',
  Good = 'Good'
}

export class EducationCourseTotalPerformance {
  __typename?: 'EducationCourseTotalPerformance';
  _etag?: Maybe<Scalars['String']['output']>;
  candidateId: Scalars['ID']['output'];
  candidateOrganizationId: Scalars['ID']['output'];
  candidateState?: Maybe<EducationCourseCandidateStatus>;
  changedAt: Scalars['DateTime']['output'];
  changedBy: Scalars['String']['output'];
  courseId: Scalars['ID']['output'];
  id?: Maybe<Scalars['ID']['output']>;
  organizationId: Scalars['ID']['output'];
};

export class EducationCourseTotalPerformanceGetInput {
  candidateId: Scalars['ID']['input'];
  candidateOrganizationId: Scalars['ID']['input'];
  courseId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};

export class EducationCourseTotalPerformanceStateUpdateInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  candidateId: Scalars['ID']['input'];
  candidateOrganizationId: Scalars['ID']['input'];
  candidateState?: InputMaybe<EducationCourseCandidateStatus>;
  courseId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};

export class EducationCourseUpdateInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  endDate: Scalars['DateTime']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  maxParticipants: Scalars['Float']['input'];
  moduleId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  participantCollectionId: Scalars['ID']['input'];
  startDate: Scalars['DateTime']['input'];
  teacherUserIds: Array<Scalars['ID']['input']>;
};

export class EducationCourseWeek {
  __typename?: 'EducationCourseWeek';
  number: Scalars['Float']['output'];
  year: Scalars['Float']['output'];
};

export class EducationCourseWeekInput {
  number: Scalars['Float']['input'];
  year: Scalars['Float']['input'];
};

export class EducationCourseWeekPrefillInput {
  courseId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
  week: EducationCourseWeekInput;
};

export class EducationCourseWeeklyAttendanceUpdateInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  attendance?: InputMaybe<EducationCourseAttendance>;
  candidateId: Scalars['ID']['input'];
  candidateOrganizationId: Scalars['ID']['input'];
  courseId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
  week: EducationCourseWeekInput;
};

export class EducationCourseWeeklyCommentUpdateInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  candidateId: Scalars['ID']['input'];
  candidateOrganizationId: Scalars['ID']['input'];
  comment?: InputMaybe<Scalars['String']['input']>;
  courseId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
  week: EducationCourseWeekInput;
};

export class EducationCourseWeeklyPerformance {
  __typename?: 'EducationCourseWeeklyPerformance';
  _etag?: Maybe<Scalars['String']['output']>;
  attendance?: Maybe<EducationCourseAttendance>;
  candidateId: Scalars['ID']['output'];
  candidateOrganizationId: Scalars['ID']['output'];
  changedAt: Scalars['DateTime']['output'];
  changedBy: Scalars['String']['output'];
  comment?: Maybe<Scalars['String']['output']>;
  courseId: Scalars['ID']['output'];
  id?: Maybe<Scalars['ID']['output']>;
  organizationId: Scalars['ID']['output'];
  performance?: Maybe<EducationCoursePerformanceStatus>;
  week: EducationCourseWeek;
};

export class EducationCourseWeeklyPerformanceGetInput {
  candidateId: Scalars['ID']['input'];
  candidateOrganizationId: Scalars['ID']['input'];
  courseId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
  week: EducationCourseWeekInput;
};

export class EducationCourseWeeklyPerformanceUpdateInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  candidateId: Scalars['ID']['input'];
  candidateOrganizationId: Scalars['ID']['input'];
  courseId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
  performance?: InputMaybe<EducationCoursePerformanceStatus>;
  week: EducationCourseWeekInput;
};

export class EducationExam {
  __typename?: 'EducationExam';
  _etag?: Maybe<Scalars['String']['output']>;
  changedAt: Scalars['DateTime']['output'];
  changedBy: Scalars['String']['output'];
  educationCourseIds?: Maybe<Array<Scalars['ID']['output']>>;
  educationModuleId?: Maybe<Scalars['ID']['output']>;
  examDateAndTime?: Maybe<DateWithTime>;
  examInstitution?: Maybe<Scalars['String']['output']>;
  examLocation?: Maybe<Scalars['String']['output']>;
  examModules?: Maybe<Array<ExamModuleType>>;
  examType: ExamType;
  id?: Maybe<Scalars['ID']['output']>;
  name: Scalars['String']['output'];
  organizationId: Scalars['ID']['output'];
  plannedExamDateAndTime?: Maybe<DateWithTime>;
  plannedExamDateAndTimeConfirmed: Scalars['Boolean']['output'];
  plannedExamResultDate?: Maybe<Scalars['DateTime']['output']>;
  responsibleRolePayment?: Maybe<Scalars['String']['output']>;
};

export class EducationExamActivityParameter {
  __typename?: 'EducationExamActivityParameter';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  organizationId: Scalars['ID']['output'];
  organizationName?: Maybe<Scalars['String']['output']>;
};

export class EducationExamBaseData {
  __typename?: 'EducationExamBaseData';
  _etag?: Maybe<Scalars['String']['output']>;
  changedAt: Scalars['DateTime']['output'];
  changedBy: Scalars['String']['output'];
  examDateAndTime?: Maybe<DateWithTime>;
  examInstitution?: Maybe<Scalars['String']['output']>;
  examLocation?: Maybe<Scalars['String']['output']>;
  examModules?: Maybe<Array<ExamModuleType>>;
  examType: ExamType;
  id?: Maybe<Scalars['ID']['output']>;
  name: Scalars['String']['output'];
  organizationId: Scalars['ID']['output'];
  plannedExamDateAndTime?: Maybe<DateWithTime>;
  plannedExamDateAndTimeConfirmed: Scalars['Boolean']['output'];
  plannedExamResultDate?: Maybe<Scalars['DateTime']['output']>;
  responsibleRolePayment?: Maybe<Scalars['String']['output']>;
};

export class EducationExamCandidateIdInput {
  id: Scalars['ID']['input'];
  orgId: Scalars['ID']['input'];
};

export class EducationExamCreateInput {
  educationCourseIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  educationModuleId?: InputMaybe<Scalars['ID']['input']>;
  examDateAndTime?: InputMaybe<DateWithTimeInput>;
  examInstitution?: InputMaybe<Scalars['String']['input']>;
  examLocation?: InputMaybe<Scalars['String']['input']>;
  examModules?: InputMaybe<Array<ExamModuleType>>;
  examType: ExamType;
  name: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  plannedExamDateAndTime?: InputMaybe<DateWithTimeInput>;
  plannedExamDateAndTimeConfirmed: Scalars['Boolean']['input'];
  plannedExamResultDate?: InputMaybe<Scalars['DateTime']['input']>;
  responsibleRolePayment?: InputMaybe<Scalars['String']['input']>;
};

export class EducationExamDeleteInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
};

export class EducationExamForCandidate {
  __typename?: 'EducationExamForCandidate';
  exam?: Maybe<EducationExam>;
  level: Scalars['String']['output'];
  participant?: Maybe<EducationExamParticipantEntity>;
  result?: Maybe<EducationExamResultFullData>;
};

export class EducationExamGetByCandidateIdInput {
  candidateId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};

export class EducationExamGetByCourseIdInput {
  courseId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};

export class EducationExamGetByIdInput {
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
};

export class EducationExamGetByOrgIdInput {
  organizationId: Scalars['ID']['input'];
  status?: InputMaybe<ExamStatus>;
};

export class EducationExamModuleResultInformation {
  __typename?: 'EducationExamModuleResultInformation';
  examModule: ExamModuleType;
  id: Scalars['ID']['output'];
  resultDetails?: Maybe<Scalars['String']['output']>;
};

export class EducationExamModuleResultInformationInput {
  examModule: ExamModuleType;
  id: Scalars['ID']['input'];
  resultDetails?: InputMaybe<Scalars['String']['input']>;
};

export class EducationExamParticipant {
  __typename?: 'EducationExamParticipant';
  _etag?: Maybe<Scalars['String']['output']>;
  candidateDisplayId?: Maybe<Scalars['String']['output']>;
  candidateDisplayName?: Maybe<Scalars['String']['output']>;
  candidateId: Scalars['ID']['output'];
  candidateOrganizationId: Scalars['ID']['output'];
  changedAt: Scalars['DateTime']['output'];
  changedBy: Scalars['String']['output'];
  country?: Maybe<Scalars['String']['output']>;
  examId: Scalars['ID']['output'];
  id?: Maybe<Scalars['ID']['output']>;
  organizationId: Scalars['ID']['output'];
};

export class EducationExamParticipantCreateInput {
  candidateId: Scalars['ID']['input'];
  candidateOrganizationId: Scalars['ID']['input'];
  examId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};

export class EducationExamParticipantCreateManyInput {
  examId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
  participants: Array<EducationExamCandidateIdInput>;
};

export class EducationExamParticipantDeleteInput {
  candidateId: Scalars['ID']['input'];
  candidateOrganizationId: Scalars['ID']['input'];
  examId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};

export class EducationExamParticipantEntity {
  __typename?: 'EducationExamParticipantEntity';
  _etag?: Maybe<Scalars['String']['output']>;
  candidateId: Scalars['ID']['output'];
  candidateOrganizationId: Scalars['ID']['output'];
  changedAt: Scalars['DateTime']['output'];
  changedBy: Scalars['String']['output'];
  examId: Scalars['ID']['output'];
  id?: Maybe<Scalars['ID']['output']>;
  organizationId: Scalars['ID']['output'];
};

export class EducationExamParticipantGetInput {
  examId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};

export class EducationExamResult {
  __typename?: 'EducationExamResult';
  _etag?: Maybe<Scalars['String']['output']>;
  candidateId: Scalars['ID']['output'];
  candidateOrganizationId: Scalars['ID']['output'];
  changedAt?: Maybe<Scalars['String']['output']>;
  changedBy?: Maybe<Scalars['String']['output']>;
  examId: Scalars['ID']['output'];
  examModuleInformation?: Maybe<Array<EducationExamModuleResultInformation>>;
  examResult?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  organizationId: Scalars['ID']['output'];
  passedExamModules?: Maybe<Array<ExamModuleType>>;
};

export class EducationExamResultDeleteInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  candidateId: Scalars['ID']['input'];
  candidateOrganizationId: Scalars['ID']['input'];
  examId: Scalars['ID']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
};

export class EducationExamResultFullData {
  __typename?: 'EducationExamResultFullData';
  _etag?: Maybe<Scalars['String']['output']>;
  candidateDisplayId?: Maybe<Scalars['String']['output']>;
  candidateId: Scalars['ID']['output'];
  candidateName?: Maybe<Scalars['String']['output']>;
  candidateOrganizationId: Scalars['ID']['output'];
  changedAt?: Maybe<Scalars['String']['output']>;
  changedBy?: Maybe<Scalars['String']['output']>;
  educationProviderOrganizationId?: Maybe<Scalars['String']['output']>;
  examId: Scalars['ID']['output'];
  examModuleInformation?: Maybe<Array<EducationExamModuleResultInformation>>;
  examResult?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  languageLevel?: Maybe<Scalars['String']['output']>;
  organizationId: Scalars['ID']['output'];
  participantId?: Maybe<Scalars['String']['output']>;
  passedExamModules?: Maybe<Array<ExamModuleType>>;
};

export class EducationExamResultListInput {
  examId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};

export class EducationExamResultUpsertInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  candidateId: Scalars['ID']['input'];
  candidateOrganizationId: Scalars['ID']['input'];
  examId: Scalars['ID']['input'];
  examModuleInformation?: InputMaybe<Array<EducationExamModuleResultInformationInput>>;
  examResult?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
  passedExamModules?: InputMaybe<Array<ExamModuleType>>;
};

export class EducationExamResultsByCandidateIdInput {
  candidateId: Scalars['ID']['input'];
  candidateOrganizationId: Scalars['ID']['input'];
};

export class EducationExamUpdateInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  educationCourseIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  educationModuleId?: InputMaybe<Scalars['ID']['input']>;
  examDateAndTime?: InputMaybe<DateWithTimeInput>;
  examInstitution?: InputMaybe<Scalars['String']['input']>;
  examLocation?: InputMaybe<Scalars['String']['input']>;
  examModules?: InputMaybe<Array<ExamModuleType>>;
  examType: ExamType;
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  plannedExamDateAndTime?: InputMaybe<DateWithTimeInput>;
  plannedExamDateAndTimeConfirmed: Scalars['Boolean']['input'];
  plannedExamResultDate?: InputMaybe<Scalars['DateTime']['input']>;
  responsibleRolePayment?: InputMaybe<Scalars['String']['input']>;
};

export enum EducationMode {
  Blended = 'Blended',
  FaceToFace = 'FaceToFace',
  Online = 'Online'
}

export class EducationModule {
  __typename?: 'EducationModule';
  _etag?: Maybe<Scalars['String']['output']>;
  changedAt: Scalars['DateTime']['output'];
  changedBy: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  languageLevel: Scalars['String']['output'];
  mode: EducationMode;
  name: Scalars['String']['output'];
  organizationId: Scalars['ID']['output'];
  providerOrganizationId: Scalars['ID']['output'];
  status: EducationModuleStatus;
  type: EducationModuleType;
  units: Scalars['Float']['output'];
};

export class EducationModuleCreateInput {
  description?: InputMaybe<Scalars['String']['input']>;
  languageLevel: Scalars['String']['input'];
  mode: EducationMode;
  name: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  providerOrganizationId: Scalars['ID']['input'];
  status: EducationModuleStatus;
  type: EducationModuleType;
  units: Scalars['Float']['input'];
};

export class EducationModuleDeleteInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
};

export class EducationModuleGetInput {
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
};

export class EducationModuleListInput {
  organizationId: Scalars['ID']['input'];
};

export enum EducationModuleStatus {
  Active = 'Active',
  Inactive = 'Inactive'
}

export enum EducationModuleType {
  LanguageLearning = 'LanguageLearning'
}

export class EducationModuleUpdateInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  languageLevel: Scalars['String']['input'];
  mode: EducationMode;
  name: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  providerOrganizationId: Scalars['ID']['input'];
  status: EducationModuleStatus;
  type: EducationModuleType;
  units: Scalars['Float']['input'];
};

export class EffectiveSharingInput {
  candidateId: Scalars['ID']['input'];
  candidateOrganizationId: Scalars['ID']['input'];
  options?: InputMaybe<EffectiveSharingOptionsInput>;
  organizationId: Scalars['ID']['input'];
};

export class EffectiveSharingOptionsInput {
  candidateFunction: Scalars['String']['input'];
  candidateStatus: CandidateStatus;
  collectionId: Scalars['ID']['input'];
  collectionOrganizationId: Scalars['ID']['input'];
};

export class EffectiveSharingOutput {
  __typename?: 'EffectiveSharingOutput';
  canSeeInternalDocuments: Scalars['Boolean']['output'];
  sharedFileTypes?: Maybe<Array<Scalars['String']['output']>>;
  sharedTabs?: Maybe<Array<SharedTab>>;
  sharingType?: Maybe<SharingTypeEnum>;
  showCompletionStateAtDocuments: Scalars['Boolean']['output'];
  tabHash?: Maybe<Scalars['String']['output']>;
};

export class Email {
  __typename?: 'Email';
  _etag?: Maybe<Scalars['String']['output']>;
  actualProcessId?: Maybe<Scalars['ID']['output']>;
  attachDownloadableTaskFiles?: Maybe<Scalars['Boolean']['output']>;
  attachments?: Maybe<Array<FileAttachment>>;
  body?: Maybe<Scalars['String']['output']>;
  candidateIds?: Maybe<Array<CandidateId>>;
  events?: Maybe<Array<EmailEvent>>;
  from: EmailParty;
  id?: Maybe<Scalars['ID']['output']>;
  includeListUnsubscribe?: Maybe<Scalars['Boolean']['output']>;
  language?: Maybe<Scalars['String']['output']>;
  messengerMessageIds?: Maybe<Array<Scalars['String']['output']>>;
  organizationId: Scalars['ID']['output'];
  processId?: Maybe<Scalars['ID']['output']>;
  processName?: Maybe<Scalars['String']['output']>;
  processOrganizationId?: Maybe<Scalars['ID']['output']>;
  recipientRole?: Maybe<Scalars['String']['output']>;
  replyTo?: Maybe<EmailParty>;
  responsibleRoles?: Maybe<Array<Scalars['String']['output']>>;
  ruleId?: Maybe<Scalars['String']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
  targetRoles?: Maybe<Array<Scalars['String']['output']>>;
  taskIds?: Maybe<Array<Scalars['ID']['output']>>;
  taskStateIds?: Maybe<Array<Scalars['ID']['output']>>;
  templateId?: Maybe<Scalars['ID']['output']>;
  to: Array<EmailParty>;
  userIds?: Maybe<Array<UserId>>;
  visibleForRoles?: Maybe<Array<Scalars['String']['output']>>;
};

export class EmailEvent {
  __typename?: 'EmailEvent';
  date: Scalars['DateTime']['output'];
  email?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  type: EmailEventType;
};

export enum EmailEventType {
  Blocked = 'Blocked',
  Bounced = 'Bounced',
  Delivered = 'Delivered',
  Dropped = 'Dropped',
  Failed = 'Failed',
  NotSent = 'NotSent',
  Opened = 'Opened',
  OptedOut = 'OptedOut',
  Resent = 'Resent',
  Sent = 'Sent'
}

export class EmailForList {
  __typename?: 'EmailForList';
  _etag?: Maybe<Scalars['String']['output']>;
  actualProcessId?: Maybe<Scalars['ID']['output']>;
  attachDownloadableTaskFiles?: Maybe<Scalars['Boolean']['output']>;
  attachments?: Maybe<Array<FileAttachment>>;
  candidateIds?: Maybe<Array<CandidateId>>;
  events?: Maybe<Array<EmailEvent>>;
  from: EmailParty;
  id?: Maybe<Scalars['ID']['output']>;
  includeListUnsubscribe?: Maybe<Scalars['Boolean']['output']>;
  language?: Maybe<Scalars['String']['output']>;
  messengerMessageIds?: Maybe<Array<Scalars['String']['output']>>;
  organizationId: Scalars['ID']['output'];
  processId?: Maybe<Scalars['ID']['output']>;
  processName?: Maybe<Scalars['String']['output']>;
  processOrganizationId?: Maybe<Scalars['ID']['output']>;
  recipientRole?: Maybe<Scalars['String']['output']>;
  replyTo?: Maybe<EmailParty>;
  responsibleRoles?: Maybe<Array<Scalars['String']['output']>>;
  ruleId?: Maybe<Scalars['String']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
  targetRoles?: Maybe<Array<Scalars['String']['output']>>;
  taskIds?: Maybe<Array<Scalars['ID']['output']>>;
  taskStateIds?: Maybe<Array<Scalars['ID']['output']>>;
  templateId?: Maybe<Scalars['ID']['output']>;
  to: Array<EmailParty>;
  userIds?: Maybe<Array<UserId>>;
  visibleForRoles?: Maybe<Array<Scalars['String']['output']>>;
};

export class EmailGetInput {
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
};

export class EmailListForCandidateInput {
  candidateId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};

export class EmailListForProcessInput {
  candidateId?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
  processId: Scalars['ID']['input'];
};

export class EmailListForUserInput {
  organizationId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export class EmailListSendInput {
  candidateId?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
  processId: Scalars['ID']['input'];
};

export class EmailParty {
  __typename?: 'EmailParty';
  displayId?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  invited?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  optedIn?: Maybe<Scalars['Boolean']['output']>;
};

export class EmailSendInput {
  body: Scalars['String']['input'];
  emailId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
  processId: Scalars['ID']['input'];
  subject: Scalars['String']['input'];
};

export class EmailSendOutRule {
  __typename?: 'EmailSendOutRule';
  _etag?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isActive?: Maybe<Scalars['Boolean']['output']>;
  matchedRolesOnly?: Maybe<Scalars['Boolean']['output']>;
  organizationId: Scalars['ID']['output'];
  recipientRoles: Scalars['JSON']['output'];
  responsibleRoles?: Maybe<Array<Scalars['String']['output']>>;
  state: Array<InstanceStateEnum>;
  targetRoles?: Maybe<Array<Scalars['String']['output']>>;
  template: Scalars['String']['output'];
};

export class EmailSendOutRuleCreateInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  matchedRolesOnly?: InputMaybe<Scalars['Boolean']['input']>;
  organizationId: Scalars['ID']['input'];
  recipientRoles: Scalars['JSON']['input'];
  responsibleRoles?: InputMaybe<Array<Scalars['String']['input']>>;
  state: Array<InstanceStateEnum>;
  targetRoles?: InputMaybe<Array<Scalars['String']['input']>>;
  template: Scalars['String']['input'];
};

export class EmailSendOutRuleInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  matchedRolesOnly?: InputMaybe<Scalars['Boolean']['input']>;
  organizationId: Scalars['ID']['input'];
  recipientRoles: Scalars['JSON']['input'];
  responsibleRoles?: InputMaybe<Array<Scalars['String']['input']>>;
  state: Array<InstanceStateEnum>;
  targetRoles?: InputMaybe<Array<Scalars['String']['input']>>;
  template: Scalars['String']['input'];
};

export class EmailSendOutRuleUpdateInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  matchedRolesOnly?: InputMaybe<Scalars['Boolean']['input']>;
  organizationId: Scalars['ID']['input'];
  recipientRoles: Scalars['JSON']['input'];
  responsibleRoles?: InputMaybe<Array<Scalars['String']['input']>>;
  state: Array<InstanceStateEnum>;
  targetRoles?: InputMaybe<Array<Scalars['String']['input']>>;
  template: Scalars['String']['input'];
};

export class EmailTemplate {
  __typename?: 'EmailTemplate';
  _etag?: Maybe<Scalars['String']['output']>;
  changedAt: Scalars['DateTime']['output'];
  changedBy: Scalars['String']['output'];
  comment?: Maybe<Scalars['String']['output']>;
  displayName: Scalars['String']['output'];
  id?: Maybe<Scalars['ID']['output']>;
  isContentTemplate?: Maybe<Scalars['Boolean']['output']>;
  isDefaultTemplate?: Maybe<Scalars['Boolean']['output']>;
  isUsedInProcess?: Maybe<Scalars['Boolean']['output']>;
  isUsedInSendOutRules?: Maybe<Scalars['Boolean']['output']>;
  items: Array<EmailTemplateItem>;
  organizationId: Scalars['ID']['output'];
  sendSeparateUserEmailsForEachCandidate?: Maybe<Scalars['Boolean']['output']>;
  target?: Maybe<EmailTemplateTarget>;
  type: EmailTemplateType;
};

export class EmailTemplateCreateInput {
  comment?: InputMaybe<Scalars['String']['input']>;
  displayName: Scalars['String']['input'];
  isContentTemplate?: InputMaybe<Scalars['Boolean']['input']>;
  items: Array<EmailTemplateItemInput>;
  organizationId: Scalars['ID']['input'];
  sendSeparateUserEmailsForEachCandidate?: InputMaybe<Scalars['Boolean']['input']>;
  target?: InputMaybe<EmailTemplateTarget>;
  type: EmailTemplateType;
};

export class EmailTemplateDeleteInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
};

export class EmailTemplateDescription {
  __typename?: 'EmailTemplateDescription';
  condition?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  matchedRolesOnly?: Maybe<Scalars['Boolean']['output']>;
  recipientRoles: Scalars['JSON']['output'];
  responsibleRoles?: Maybe<Array<Scalars['String']['output']>>;
  state: Array<InstanceStateEnum>;
  targetRoles?: Maybe<Array<Scalars['String']['output']>>;
  template: Scalars['String']['output'];
};

export class EmailTemplateDescriptionInput {
  condition?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  matchedRolesOnly?: InputMaybe<Scalars['Boolean']['input']>;
  recipientRoles: Scalars['JSON']['input'];
  responsibleRoles?: InputMaybe<Array<Scalars['String']['input']>>;
  state: Array<InstanceStateEnum>;
  targetRoles?: InputMaybe<Array<Scalars['String']['input']>>;
  template: Scalars['String']['input'];
};

export class EmailTemplateDuplicateInput {
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
};

export class EmailTemplateGetInput {
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
};

export class EmailTemplateItem {
  __typename?: 'EmailTemplateItem';
  contents?: Maybe<Scalars['String']['output']>;
  html?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  language: Scalars['String']['output'];
  subject?: Maybe<Scalars['String']['output']>;
};

export class EmailTemplateItemInput {
  contents?: InputMaybe<Scalars['String']['input']>;
  html?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  language: Scalars['String']['input'];
  subject?: InputMaybe<Scalars['String']['input']>;
};

export class EmailTemplateListInput {
  organizationId: Scalars['ID']['input'];
};

export class EmailTemplateMutationResult {
  __typename?: 'EmailTemplateMutationResult';
  template?: Maybe<EmailTemplate>;
  validationErrorMessage?: Maybe<Scalars['String']['output']>;
  validationWarningMessage?: Maybe<Scalars['String']['output']>;
};

export class EmailTemplateRender {
  __typename?: 'EmailTemplateRender';
  body: Scalars['String']['output'];
  subject: Scalars['String']['output'];
};

export class EmailTemplateRenderInput {
  candidateId?: InputMaybe<Scalars['ID']['input']>;
  candidateOrganizationId?: InputMaybe<Scalars['ID']['input']>;
  customHtml?: InputMaybe<Scalars['String']['input']>;
  customSubject?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  language: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  processId: Scalars['ID']['input'];
  roles: Array<Scalars['String']['input']>;
  taskId: Scalars['ID']['input'];
};

export enum EmailTemplateTarget {
  Candidate = 'Candidate',
  NewUser = 'NewUser',
  Participant = 'Participant'
}

export enum EmailTemplateType {
  Automatic = 'Automatic',
  Draft = 'Draft',
  Layout = 'Layout'
}

export class EmailTemplateTypeInput {
  organizationId: Scalars['ID']['input'];
  type: EmailTemplateType;
};

export class EmailTemplateUpdateInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  displayName: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  isContentTemplate?: InputMaybe<Scalars['Boolean']['input']>;
  items: Array<EmailTemplateItemInput>;
  organizationId: Scalars['ID']['input'];
  sendSeparateUserEmailsForEachCandidate?: InputMaybe<Scalars['Boolean']['input']>;
  target?: InputMaybe<EmailTemplateTarget>;
  type: EmailTemplateType;
};

export class Employer {
  __typename?: 'Employer';
  description: Array<OrganizationProfileDescription>;
  images?: Maybe<Array<Scalars['String']['output']>>;
  locations: Array<EmployerLocation>;
  organizationId: Scalars['ID']['output'];
  organizationName: Scalars['String']['output'];
};

export class EmployerApplication {
  __typename?: 'EmployerApplication';
  _etag?: Maybe<Scalars['String']['output']>;
  candidateId: Scalars['ID']['output'];
  coverLetter?: Maybe<Scalars['String']['output']>;
  date: Scalars['DateTime']['output'];
  employerOrganizationId: Scalars['ID']['output'];
  employerOrganizationName: Scalars['String']['output'];
  id?: Maybe<Scalars['ID']['output']>;
  organizationId: Scalars['ID']['output'];
  selectedLocations: Array<Scalars['String']['output']>;
  selectedPosition?: Maybe<Scalars['String']['output']>;
};

export class EmployerApplicationGetInput {
  candidateId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};

export class EmployerApplicationInput {
  candidateId: Scalars['ID']['input'];
  coverLetter?: InputMaybe<Scalars['String']['input']>;
  employerOrganizationId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
  selectedLocations: Array<Scalars['String']['input']>;
  selectedPosition: Scalars['String']['input'];
};

export class EmployerData {
  __typename?: 'EmployerData';
  country?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  zipcode?: Maybe<Scalars['String']['output']>;
};

export class EmployerDataInput {
  country?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  zipcode?: InputMaybe<Scalars['String']['input']>;
};

export class EmployerFilterDefaults {
  __typename?: 'EmployerFilterDefaults';
  candidateId?: Maybe<Scalars['ID']['output']>;
  careFacility?: Maybe<Array<Scalars['String']['output']>>;
  city?: Maybe<Array<Scalars['String']['output']>>;
  employmentType?: Maybe<Array<EmploymentRelationshipType>>;
  experienceField?: Maybe<Array<Scalars['String']['output']>>;
  interviewLanguageLevel?: Maybe<Array<Scalars['String']['output']>>;
  organizationId?: Maybe<Scalars['ID']['output']>;
  pathOfRecognition?: Maybe<Array<Scalars['String']['output']>>;
  state?: Maybe<Array<Scalars['String']['output']>>;
  temporalScope?: Maybe<Array<WorkingTimeTypeEnum>>;
  transferLanguageLevel?: Maybe<Array<Scalars['String']['output']>>;
};

export class EmployerFilterFields {
  __typename?: 'EmployerFilterFields';
  careFacility: Scalars['Boolean']['output'];
  city: Scalars['Boolean']['output'];
  employmentType: Scalars['Boolean']['output'];
  experienceField: Scalars['Boolean']['output'];
  interviewLanguageLevel: Scalars['Boolean']['output'];
  pathOfRecognition: Scalars['Boolean']['output'];
  state: Scalars['Boolean']['output'];
  temporalScope: Scalars['Boolean']['output'];
  transferLanguageLevel: Scalars['Boolean']['output'];
};

export class EmployerGetInput {
  candidateId?: InputMaybe<Scalars['ID']['input']>;
  careFacility?: InputMaybe<Array<Scalars['String']['input']>>;
  city?: InputMaybe<Array<Scalars['String']['input']>>;
  employerOrganizationId: Scalars['ID']['input'];
  employmentType?: InputMaybe<Array<EmploymentRelationshipType>>;
  experienceField?: InputMaybe<Array<Scalars['String']['input']>>;
  interviewLanguageLevel?: InputMaybe<Array<Scalars['String']['input']>>;
  organizationId: Scalars['ID']['input'];
  pathOfRecognition?: InputMaybe<Array<Scalars['String']['input']>>;
  state?: InputMaybe<Array<Scalars['String']['input']>>;
  temporalScope?: InputMaybe<Array<WorkingTimeTypeEnum>>;
  transferLanguageLevel?: InputMaybe<Array<Scalars['String']['input']>>;
};

export class EmployerHistoryEntry {
  __typename?: 'EmployerHistoryEntry';
  createdAt: Scalars['DateTime']['output'];
  employerId: Scalars['ID']['output'];
  employerName: Scalars['String']['output'];
  endDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  startDate?: Maybe<Scalars['DateTime']['output']>;
};

export class EmployerHistoryEntryInput {
  createdAt: Scalars['DateTime']['input'];
  employerId: Scalars['ID']['input'];
  employerName: Scalars['String']['input'];
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  id: Scalars['ID']['input'];
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export class EmployerListInput {
  candidateId: Scalars['ID']['input'];
  careFacility?: InputMaybe<Array<Scalars['String']['input']>>;
  city?: InputMaybe<Array<Scalars['String']['input']>>;
  employmentType?: InputMaybe<Array<EmploymentRelationshipType>>;
  experienceField?: InputMaybe<Array<Scalars['String']['input']>>;
  interviewLanguageLevel?: InputMaybe<Array<Scalars['String']['input']>>;
  organizationId: Scalars['ID']['input'];
  pathOfRecognition?: InputMaybe<Array<Scalars['String']['input']>>;
  state?: InputMaybe<Array<Scalars['String']['input']>>;
  temporalScope?: InputMaybe<Array<WorkingTimeTypeEnum>>;
  transferLanguageLevel?: InputMaybe<Array<Scalars['String']['input']>>;
};

export class EmployerLocation {
  __typename?: 'EmployerLocation';
  address: Scalars['String']['output'];
  beds?: Maybe<Scalars['Float']['output']>;
  careFacility?: Maybe<Array<Scalars['String']['output']>>;
  city?: Maybe<Scalars['String']['output']>;
  experienceField?: Maybe<Array<Scalars['String']['output']>>;
  hasInternationalExperience: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  state?: Maybe<Scalars['String']['output']>;
  vacancies: Array<EmployerLocationVacancy>;
  website?: Maybe<Scalars['String']['output']>;
  zipcode: Scalars['String']['output'];
};

export class EmployerLocationVacancy {
  __typename?: 'EmployerLocationVacancy';
  benefits?: Maybe<Array<Scalars['String']['output']>>;
  countryOfOrigin?: Maybe<Array<Scalars['String']['output']>>;
  employmentType?: Maybe<Array<EmploymentRelationshipType>>;
  function?: Maybe<Array<Scalars['String']['output']>>;
  interviewLanguageLevel?: Maybe<Scalars['String']['output']>;
  pathOfRecognition?: Maybe<Scalars['String']['output']>;
  requiredStaff?: Maybe<Array<Scalars['String']['output']>>;
  temporalScope?: Maybe<Array<WorkingTimeTypeEnum>>;
  transferLanguageLevel?: Maybe<Scalars['String']['output']>;
};

export class EmploymentApprovalAuthority {
  __typename?: 'EmploymentApprovalAuthority';
  id: Scalars['String']['output'];
  location: Scalars['String']['output'];
  responsibility: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export class EmploymentApprovalAuthorityByIdInput {
  id: Scalars['String']['input'];
  immigrationCountry: SupportedImmigrationCountry;
  organizationId: Scalars['String']['input'];
};

export class EmploymentApprovalAuthorityInput {
  immigrationCountry: SupportedImmigrationCountry;
  organizationId: Scalars['String']['input'];
};

export class EmploymentRelationship {
  __typename?: 'EmploymentRelationship';
  afterRecognition?: Maybe<AfterRecognition>;
  beforeRecognition?: Maybe<BeforeRecognition>;
  hasDifferentRelationships?: Maybe<Scalars['Boolean']['output']>;
};

export class EmploymentRelationshipInput {
  afterRecognition?: InputMaybe<AfterRecognitionInput>;
  beforeRecognition?: InputMaybe<BeforeRecognitionInput>;
  hasDifferentRelationships?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum EmploymentRelationshipType {
  Internship = 'Internship',
  JobShadowing = 'JobShadowing',
  Permanent = 'Permanent',
  SecondJob = 'SecondJob',
  Temporary = 'Temporary'
}

export class EnableMatchingInput {
  destinationOrganizationId: Scalars['ID']['input'];
  enabled: Scalars['Boolean']['input'];
  organizationId: Scalars['ID']['input'];
};

export enum EnterMissingInformation {
  ArrivalDate = 'ArrivalDate',
  BankAppointmentDate = 'BankAppointmentDate',
  CompanyDoctorAppointmentDate = 'CompanyDoctorAppointmentDate',
  EndOfComplementaryMeasure = 'EndOfComplementaryMeasure',
  EndOfFurtherEducation = 'EndOfFurtherEducation',
  EndOfProbationDate = 'EndOfProbationDate',
  EquivalenceTest = 'EquivalenceTest',
  EstimatedArrivalDate = 'EstimatedArrivalDate',
  EstimatedWorkStartDate = 'EstimatedWorkStartDate',
  FederalState = 'FederalState',
  FederalStateAt = 'FederalStateAt',
  FingerPrintDate = 'FingerPrintDate',
  FirstExamDate = 'FirstExamDate',
  FirstExamPassed = 'FirstExamPassed',
  FirstFinalInterviewDate = 'FirstFinalInterviewDate',
  FirstFinalInterviewPassed = 'FirstFinalInterviewPassed',
  FlightDate = 'FlightDate',
  HealthProfessionRegisterAppointmentDate = 'HealthProfessionRegisterAppointmentDate',
  HealthProfessionRegisterSubmission = 'HealthProfessionRegisterSubmission',
  HealthProfessionRegistrationDate = 'HealthProfessionRegistrationDate',
  ImmigrationAuthority = 'ImmigrationAuthority',
  LegalBasis = 'LegalBasis',
  LocationOfComplementaryMeasure = 'LocationOfComplementaryMeasure',
  MedicalExaminationAppointmentDate = 'MedicalExaminationAppointmentDate',
  NoQualificationEvaluation = 'NoQualificationEvaluation',
  PathOfRecognition = 'PathOfRecognition',
  PathOfRecognitionAt = 'PathOfRecognitionAt',
  PickUpDetails = 'PickUpDetails',
  PlannedEntryDate = 'PlannedEntryDate',
  PoliceRecordAppointmentDate = 'PoliceRecordAppointmentDate',
  PsaFirstDeputy = 'PsaFirstDeputy',
  PsaRepresentative = 'PsaRepresentative',
  QualificationMeasure = 'QualificationMeasure',
  QualificationType = 'QualificationType',
  RecognitionAuthority = 'RecognitionAuthority',
  RecognitionAuthorityAt = 'RecognitionAuthorityAt',
  RecognitionFileNumber = 'RecognitionFileNumber',
  RecognitionFileNumberAcceleratedProcedure = 'RecognitionFileNumberAcceleratedProcedure',
  RecognitionPaymentRole = 'RecognitionPaymentRole',
  RecognitionPaymentRoleAccelerated = 'RecognitionPaymentRoleAccelerated',
  RecognitionType = 'RecognitionType',
  RegistrationOfficeDate = 'RegistrationOfficeDate',
  ResidencePermit = 'ResidencePermit',
  ResponsibleRecognitionOrganization = 'ResponsibleRecognitionOrganization',
  ResponsibleRoleLaborMarket = 'ResponsibleRoleLaborMarket',
  ResponsibleRoleQualification = 'ResponsibleRoleQualification',
  ResponsibleRoleQualificationEvaluation = 'ResponsibleRoleQualificationEvaluation',
  ResponsibleRoleQualificationEvaluationPayment = 'ResponsibleRoleQualificationEvaluationPayment',
  ResponsibleRoleRecognition = 'ResponsibleRoleRecognition',
  ResponsibleRoleResidence = 'ResponsibleRoleResidence',
  ResponsibleRoleVisa = 'ResponsibleRoleVisa',
  RwrCardFileNumber = 'RwrCardFileNumber',
  StartOfComplementaryMeasure = 'StartOfComplementaryMeasure',
  StartOfFurtherEducation = 'StartOfFurtherEducation',
  TargetRecognition = 'TargetRecognition',
  WorkConfirmationSignedDate = 'WorkConfirmationSignedDate',
  WorkContractSignDate = 'WorkContractSignDate',
  WorkContractStartDate = 'WorkContractStartDate',
  WorkContractTypeOfWorkLocation = 'WorkContractTypeOfWorkLocation',
  WorkContractWorkLocation = 'WorkContractWorkLocation',
  WorkEmployer = 'WorkEmployer',
  WorkEmployerAt = 'WorkEmployerAt',
  WorkStartDate = 'WorkStartDate'
}

export class EnterMissingInformationPreview {
  __typename?: 'EnterMissingInformationPreview';
  previews: Array<CandidateMultiEditPreview>;
  totalCandidates: Scalars['Float']['output'];
};

export class EnterMissingInformationPreviewInput {
  organizationId: Scalars['ID']['input'];
  requiredInformation: Scalars['JSONObject']['input'];
  selectedChildTaskIds?: InputMaybe<Array<Scalars['String']['input']>>;
  taskId: Scalars['ID']['input'];
};

export class EnterMissingInformationRequestedInformation {
  __typename?: 'EnterMissingInformationRequestedInformation';
  canAdd?: Maybe<Scalars['Boolean']['output']>;
  canRemove?: Maybe<Scalars['Boolean']['output']>;
  children?: Maybe<Array<EnterMissingInformationRequestedInformation>>;
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  isOptional?: Maybe<Scalars['Boolean']['output']>;
  isReadonly?: Maybe<Scalars['Boolean']['output']>;
  propertyPath: Scalars['String']['output'];
};

export class EnterMissingInformationRequiredInformationInput {
  canAdd?: InputMaybe<Scalars['Boolean']['input']>;
  canRemove?: InputMaybe<Scalars['Boolean']['input']>;
  children?: InputMaybe<Array<EnterMissingInformationRequiredInformationInput>>;
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  isOptional?: InputMaybe<Scalars['Boolean']['input']>;
  isReadonly?: InputMaybe<Scalars['Boolean']['input']>;
  propertyPath: Scalars['String']['input'];
};

export class EnterMissingInformationV2Preview {
  __typename?: 'EnterMissingInformationV2Preview';
  previews: Array<CandidateMultiEditPreview>;
  totalCandidates: Scalars['Float']['output'];
};

export class EnterMissingInformationV2PreviewInput {
  data: Scalars['JSONObject']['input'];
  organizationId: Scalars['ID']['input'];
  selectedChildTaskIds?: InputMaybe<Array<Scalars['String']['input']>>;
  taskId: Scalars['ID']['input'];
};

export class EquivalentDegrees {
  __typename?: 'EquivalentDegrees';
  comment?: Maybe<Scalars['String']['output']>;
  degree?: Maybe<Scalars['String']['output']>;
  equivalence?: Maybe<Scalars['String']['output']>;
};

export class EscoSkillByProfessionAndOrganizationInput {
  language: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  professionId: Scalars['String']['input'];
};

export class EscoSkillByProfessionInput {
  language: Scalars['String']['input'];
  professionId: Scalars['String']['input'];
};

export enum ExamModuleType {
  Listening = 'Listening',
  Oral = 'Oral',
  Reading = 'Reading',
  Speaking = 'Speaking',
  Writing = 'Writing',
  Written = 'Written'
}

export enum ExamStatus {
  Active = 'Active',
  Finished = 'Finished'
}

export enum ExamType {
  ExternalCertifiedExam = 'ExternalCertifiedExam',
  InternalExam = 'InternalExam',
  PlacementTest = 'PlacementTest'
}

export class ExcelAddInDto {
  __typename?: 'ExcelAddInDTO';
  dataTables: Array<ExcelTable>;
};

export class ExcelAddInGetCandidateDataInput {
  collectionId?: InputMaybe<Scalars['String']['input']>;
  collectionOwnerId?: InputMaybe<Scalars['String']['input']>;
  language: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
  tableMode: TableMode;
};

export class ExcelAddInSalesDataInput {
  fromDate: Scalars['DateTime']['input'];
  language: Scalars['String']['input'];
  organizationIds: Array<Scalars['String']['input']>;
  tables: Array<SalesTableType>;
  toDate: Scalars['DateTime']['input'];
};

export class ExcelAddinGetChangeStatisticsInput {
  fromDate: Scalars['DateTime']['input'];
  language: Scalars['String']['input'];
  toDate: Scalars['DateTime']['input'];
};

export class ExcelColumn {
  __typename?: 'ExcelColumn';
  id: Scalars['String']['output'];
  label: Scalars['String']['output'];
};

export class ExcelTable {
  __typename?: 'ExcelTable';
  columns?: Maybe<Array<ExcelColumn>>;
  dataCells: Array<Scalars['JSON']['output']>;
  id: Scalars['String']['output'];
  label: Scalars['String']['output'];
};

export class ExportAssetInput {
  assetType: Asset;
};

export class ExportDataColumn {
  __typename?: 'ExportDataColumn';
  id: Scalars['String']['output'];
  label: Scalars['String']['output'];
};

export class ExportDataTable {
  __typename?: 'ExportDataTable';
  columns?: Maybe<Array<ExportDataColumn>>;
  dataCells: Array<Scalars['JSON']['output']>;
  id: Scalars['String']['output'];
  label: Scalars['String']['output'];
};

export class ExportStaticDataInput {
  lang: Scalars['String']['input'];
};

export class Favorite {
  __typename?: 'Favorite';
  candidateId?: Maybe<Scalars['ID']['output']>;
  candidateOrganizationId?: Maybe<Scalars['ID']['output']>;
  collectionId?: Maybe<Scalars['ID']['output']>;
  collectionOrganizationId?: Maybe<Scalars['ID']['output']>;
  isFavorite: Scalars['Boolean']['output'];
  isReadonly: Scalars['Boolean']['output'];
};

export class FavoriteCandidateInput {
  candidateId?: InputMaybe<Scalars['ID']['input']>;
  candidateOrganizationId?: InputMaybe<Scalars['ID']['input']>;
};

export class FavoriteCollectionInput {
  collectionId?: InputMaybe<Scalars['ID']['input']>;
  collectionOrganizationId?: InputMaybe<Scalars['ID']['input']>;
};

export class FavoriteCollectionsInput {
  collections: Array<CollectionIdInput>;
};

export class FavoriteCollectionsWithSharingByOrgInput {
  organizationId: Scalars['ID']['input'];
  sharedToOrganizationId?: InputMaybe<Scalars['ID']['input']>;
};

export class Feedback {
  __typename?: 'Feedback';
  _etag?: Maybe<Scalars['String']['output']>;
  candidateId: Scalars['ID']['output'];
  candidateOrganizationId: Scalars['ID']['output'];
  comments: Array<Comment>;
  destinationOrganizationId: Scalars['ID']['output'];
  id?: Maybe<Scalars['ID']['output']>;
  interviewConducted?: Maybe<Scalars['Boolean']['output']>;
  organizationId: Scalars['ID']['output'];
  sharingId: Scalars['ID']['output'];
  statusPhaseOne?: Maybe<FeedbackStatusPhaseOne>;
  statusPhaseOneAuthor?: Maybe<Scalars['String']['output']>;
  statusPhaseOneDate?: Maybe<Scalars['DateTime']['output']>;
  statusPhaseTwo?: Maybe<FeedbackStatusPhaseTwo>;
  statusPhaseTwoAuthor?: Maybe<Scalars['String']['output']>;
  statusPhaseTwoDate?: Maybe<Scalars['DateTime']['output']>;
};

export class FeedbackCreateInput {
  candidateId: Scalars['ID']['input'];
  candidateOrganizationId: Scalars['ID']['input'];
  destinationOrganizationId: Scalars['ID']['input'];
  interviewConducted?: InputMaybe<Scalars['Boolean']['input']>;
  organizationId: Scalars['ID']['input'];
  sharingId: Scalars['ID']['input'];
  statusPhaseOne?: InputMaybe<FeedbackStatusPhaseOne>;
  statusPhaseOneAuthor?: InputMaybe<Scalars['String']['input']>;
  statusPhaseOneDate?: InputMaybe<Scalars['DateTime']['input']>;
  statusPhaseTwo?: InputMaybe<FeedbackStatusPhaseTwo>;
  statusPhaseTwoAuthor?: InputMaybe<Scalars['String']['input']>;
  statusPhaseTwoDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export class FeedbackData {
  __typename?: 'FeedbackData';
  candidateFeedback?: Maybe<FeedbackDetailsData>;
  id?: Maybe<Scalars['ID']['output']>;
  interviewDate?: Maybe<Scalars['DateTime']['output']>;
  interviewerFeedback?: Maybe<FeedbackDetailsData>;
  participants?: Maybe<Array<Scalars['String']['output']>>;
};

export class FeedbackDataInput {
  candidateFeedback?: InputMaybe<FeedbackDetailsDataInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  interviewDate?: InputMaybe<Scalars['DateTime']['input']>;
  interviewerFeedback?: InputMaybe<FeedbackDetailsDataInput>;
  participants?: InputMaybe<Array<Scalars['String']['input']>>;
};

export class FeedbackDetailsData {
  __typename?: 'FeedbackDetailsData';
  comment?: Maybe<Scalars['String']['output']>;
  vote?: Maybe<VoteType>;
};

export class FeedbackDetailsDataInput {
  comment?: InputMaybe<Scalars['String']['input']>;
  vote?: InputMaybe<VoteType>;
};

export class FeedbackIterationData {
  __typename?: 'FeedbackIterationData';
  comments?: Maybe<Array<CommentData>>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  employer?: Maybe<EmployerData>;
  feedbacks?: Maybe<Array<FeedbackData>>;
  id?: Maybe<Scalars['ID']['output']>;
};

export class FeedbackIterationDataInput {
  comments?: InputMaybe<Array<CommentDataInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  employer?: InputMaybe<EmployerDataInput>;
  feedbacks?: InputMaybe<Array<FeedbackDataInput>>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

export class FeedbackReportInput {
  language: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  sharingId: Scalars['ID']['input'];
};

export enum FeedbackStatusPhaseOne {
  Backlogged = 'Backlogged',
  InterviewRequested = 'InterviewRequested',
  Rejected = 'Rejected'
}

export enum FeedbackStatusPhaseTwo {
  Rejected = 'Rejected',
  Selected = 'Selected'
}

export class FeedbackUpdateInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  candidateId: Scalars['ID']['input'];
  candidateOrganizationId: Scalars['ID']['input'];
  destinationOrganizationId: Scalars['ID']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  interviewConducted?: InputMaybe<Scalars['Boolean']['input']>;
  organizationId: Scalars['ID']['input'];
  sharingId: Scalars['ID']['input'];
  statusPhaseOne?: InputMaybe<FeedbackStatusPhaseOne>;
  statusPhaseOneAuthor?: InputMaybe<Scalars['String']['input']>;
  statusPhaseOneDate?: InputMaybe<Scalars['DateTime']['input']>;
  statusPhaseTwo?: InputMaybe<FeedbackStatusPhaseTwo>;
  statusPhaseTwoAuthor?: InputMaybe<Scalars['String']['input']>;
  statusPhaseTwoDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export class FieldActivityDiffInput {
  entityId: Scalars['ID']['input'];
  entityTypeName: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  parentEntryId?: InputMaybe<Scalars['ID']['input']>;
  parentPath?: InputMaybe<Scalars['String']['input']>;
  path: Scalars['String']['input'];
};

export enum FileAccess {
  Full = 'Full',
  None = 'None'
}

export class FileAccessInfo {
  __typename?: 'FileAccessInfo';
  access: FileAccess;
};

export class FileAttachment {
  __typename?: 'FileAttachment';
  containerName: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deleted?: Maybe<Scalars['Boolean']['output']>;
  fileId: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  size: Scalars['Float']['output'];
  templateName?: Maybe<Scalars['String']['output']>;
  templateType?: Maybe<DocumentTemplateType>;
};

export class FileAttachmentInput {
  containerName: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  fileId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  size: Scalars['Float']['input'];
  templateName?: InputMaybe<Scalars['String']['input']>;
  templateType?: InputMaybe<DocumentTemplateType>;
};

export class GeneralDocumentRequirement {
  __typename?: 'GeneralDocumentRequirement';
  _etag?: Maybe<Scalars['String']['output']>;
  documentFormatEqualityMapping?: Maybe<Array<DocumentFormatEqualityMapping>>;
  id?: Maybe<Scalars['ID']['output']>;
  organizationId: Scalars['ID']['output'];
};

export class GeneralDocumentRequirementInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  documentFormatEqualityMapping?: InputMaybe<Array<DocumentFormatEqualityMappingInput>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
};

export class GenerateCvAsDocxResponse {
  __typename?: 'GenerateCVAsDocxResponse';
  fileName: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export class GenerateTemplateReportInput {
  CandidatePageInput?: InputMaybe<CandidatePageInput>;
  collectionId?: InputMaybe<Scalars['ID']['input']>;
  collectionOrganizationId?: InputMaybe<Scalars['ID']['input']>;
  conversionType?: InputMaybe<TemplateToDocumentTypeConversion>;
  generationStrategy?: InputMaybe<DataStrategyGenerationDescription>;
  sharingId?: InputMaybe<Scalars['ID']['input']>;
  sharingOrganizationId?: InputMaybe<Scalars['ID']['input']>;
  templateId: Scalars['ID']['input'];
  templateOrganizationId: Scalars['ID']['input'];
};

export class GenerateTemplateReportResult {
  __typename?: 'GenerateTemplateReportResult';
  downloadUrl?: Maybe<Scalars['String']['output']>;
  filename?: Maybe<Scalars['String']['output']>;
  status: DocumentTemplateStatus;
  warnings?: Maybe<Array<DocumentTemplateWarning>>;
};

export class GeoZipCode {
  __typename?: 'GeoZipCode';
  city: Scalars['String']['output'];
  latitude: Scalars['Float']['output'];
  longitude: Scalars['Float']['output'];
  state: Scalars['String']['output'];
};

export class GeoZipCodeInput {
  country: Scalars['String']['input'];
  zipcode: Scalars['String']['input'];
};

export class GetAttachableAutoCollectionsInput {
  organizationId: Scalars['ID']['input'];
  templateId: Scalars['ID']['input'];
};

export class GetCandidateForEdit {
  candidateId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
  sharingId?: InputMaybe<Scalars['ID']['input']>;
  sharingOrganizationId?: InputMaybe<Scalars['ID']['input']>;
};

export class GetCandidateInput {
  candidateId: Scalars['ID']['input'];
  options?: InputMaybe<GetCandidateOptionsInput>;
  organizationId: Scalars['ID']['input'];
  sharingId?: InputMaybe<Scalars['ID']['input']>;
  sharingOrganizationId?: InputMaybe<Scalars['ID']['input']>;
};

export class GetCandidateOptionsInput {
  candidateFunction: Scalars['String']['input'];
  candidateStatus: CandidateStatus;
  collectionId: Scalars['String']['input'];
  collectionOrganizationId: Scalars['String']['input'];
};

export class GetCommentsInput {
  onlyLatest?: InputMaybe<Scalars['Boolean']['input']>;
  organizationId: Scalars['ID']['input'];
  processId: Scalars['ID']['input'];
};

export class GetDataStrategiesInput {
  language: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
};

export class GetDataStrategyConfigurationInput {
  dataStrategyId: Scalars['String']['input'];
  language: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
};

export class GetDataStrategyGenerationInput {
  dataStrategyId: Scalars['String']['input'];
  documentId: Scalars['ID']['input'];
  language: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
};

export class GetLogoUrlInput {
  creatorOrganizationId?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
};

export class GetTaskReferenceByInput {
  language: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  taskId: Scalars['ID']['input'];
};

export class GovernanceCandidateData {
  __typename?: 'GovernanceCandidateData';
  communicationLanguage?: Maybe<Scalars['String']['output']>;
  matching?: Maybe<MatchingStatus>;
  profileDataEditStatus?: Maybe<ProfileDataEditStatus>;
};

export class GovernanceCandidateDataInput {
  communicationLanguage?: InputMaybe<Scalars['String']['input']>;
  matching?: InputMaybe<MatchingStatus>;
  profileDataEditStatus?: InputMaybe<ProfileDataEditStatus>;
};

export class GroupWithOrgId {
  groupId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};

export class GuetegemeinschaftOrganizationLinkInput {
  organizationId: Scalars['ID']['input'];
};

export class GuetegemeinschaftOrganizationLinkOutput {
  __typename?: 'GuetegemeinschaftOrganizationLinkOutput';
  organizationId: Scalars['ID']['output'];
  processId: Scalars['ID']['output'];
};

export class GuetegemeinschaftOrganizationReCertificationInput {
  emailAddress: Scalars['String']['input'];
  emailAddress2: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  organizationName: Scalars['String']['input'];
  organizationType: Scalars['String']['input'];
  salutation: Scalars['String']['input'];
};

export class HealthProfessionsRegisterCandidateDataAt {
  __typename?: 'HealthProfessionsRegisterCandidateDataAT';
  appointmentDate?: Maybe<Scalars['DateTime']['output']>;
  registrationDate?: Maybe<Scalars['DateTime']['output']>;
  submission?: Maybe<Scalars['String']['output']>;
};

export class HealthProfessionsRegisterCandidateDataAtInput {
  appointmentDate?: InputMaybe<Scalars['DateTime']['input']>;
  registrationDate?: InputMaybe<Scalars['DateTime']['input']>;
  submission?: InputMaybe<Scalars['String']['input']>;
};

export class HighestPlacementEducationExam {
  __typename?: 'HighestPlacementEducationExam';
  examDateAndTime?: Maybe<DateWithTime>;
  languageLevel?: Maybe<Scalars['String']['output']>;
};

export class Housing {
  __typename?: 'Housing';
  additionalCosts?: Maybe<Scalars['Float']['output']>;
  address?: Maybe<Scalars['String']['output']>;
  cancellationOfContractSignDate?: Maybe<Scalars['DateTime']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  coldRent?: Maybe<Scalars['Float']['output']>;
  comments?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  federalState?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  keyHandoverDateTime?: Maybe<DateWithTime>;
  movingInDate?: Maybe<Scalars['DateTime']['output']>;
  movingOutDate?: Maybe<Scalars['DateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nameOfContactPerson?: Maybe<Scalars['String']['output']>;
  operatingCosts?: Maybe<Scalars['Float']['output']>;
  phoneOfContactPerson?: Maybe<Scalars['String']['output']>;
  readinessForOccupancyDate?: Maybe<Scalars['DateTime']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export class HousingAcquisition {
  __typename?: 'HousingAcquisition';
  firstDeputyFirstName?: Maybe<Scalars['String']['output']>;
  firstDeputyId?: Maybe<Scalars['ID']['output']>;
  firstDeputyLastName?: Maybe<Scalars['String']['output']>;
  housings?: Maybe<Array<Housing>>;
  representativeFirstName?: Maybe<Scalars['String']['output']>;
  representativeId?: Maybe<Scalars['ID']['output']>;
  representativeLastName?: Maybe<Scalars['String']['output']>;
  responsibleOrganizationId?: Maybe<Scalars['ID']['output']>;
  responsibleRoleHousingAcquisition?: Maybe<Scalars['String']['output']>;
};

export class HousingAcquisitionInput {
  firstDeputyFirstName?: InputMaybe<Scalars['String']['input']>;
  firstDeputyId?: InputMaybe<Scalars['ID']['input']>;
  firstDeputyLastName?: InputMaybe<Scalars['String']['input']>;
  housings?: InputMaybe<Array<HousingInput>>;
  representativeFirstName?: InputMaybe<Scalars['String']['input']>;
  representativeId?: InputMaybe<Scalars['ID']['input']>;
  representativeLastName?: InputMaybe<Scalars['String']['input']>;
  responsibleOrganizationId?: InputMaybe<Scalars['ID']['input']>;
  responsibleRoleHousingAcquisition?: InputMaybe<Scalars['String']['input']>;
};

export class HousingInput {
  additionalCosts?: InputMaybe<Scalars['Float']['input']>;
  address?: InputMaybe<Scalars['String']['input']>;
  cancellationOfContractSignDate?: InputMaybe<Scalars['DateTime']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  coldRent?: InputMaybe<Scalars['Float']['input']>;
  comments?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  federalState?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  keyHandoverDateTime?: InputMaybe<DateWithTimeInput>;
  movingInDate?: InputMaybe<Scalars['DateTime']['input']>;
  movingOutDate?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nameOfContactPerson?: InputMaybe<Scalars['String']['input']>;
  operatingCosts?: InputMaybe<Scalars['Float']['input']>;
  phoneOfContactPerson?: InputMaybe<Scalars['String']['input']>;
  readinessForOccupancyDate?: InputMaybe<Scalars['DateTime']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export class HumanResourcesData {
  __typename?: 'HumanResourcesData';
  contactPartnerFirstName?: Maybe<Scalars['String']['output']>;
  contactPartnerId?: Maybe<Scalars['ID']['output']>;
  contactPartnerLastName?: Maybe<Scalars['String']['output']>;
  personnelArea?: Maybe<Scalars['String']['output']>;
  personnelDepartment?: Maybe<Scalars['String']['output']>;
  personnelNumber?: Maybe<Scalars['String']['output']>;
  supervisorFirstName?: Maybe<Scalars['String']['output']>;
  supervisorId?: Maybe<Scalars['ID']['output']>;
  supervisorLastName?: Maybe<Scalars['String']['output']>;
  terminationEmploymentActor?: Maybe<TerminationEmploymentActor>;
  terminationEmploymentDate?: Maybe<Scalars['DateTime']['output']>;
};

export class HumanResourcesDataInput {
  contactPartnerFirstName?: InputMaybe<Scalars['String']['input']>;
  contactPartnerId?: InputMaybe<Scalars['ID']['input']>;
  contactPartnerLastName?: InputMaybe<Scalars['String']['input']>;
  personnelArea?: InputMaybe<Scalars['String']['input']>;
  personnelDepartment?: InputMaybe<Scalars['String']['input']>;
  personnelNumber?: InputMaybe<Scalars['String']['input']>;
  supervisorFirstName?: InputMaybe<Scalars['String']['input']>;
  supervisorId?: InputMaybe<Scalars['ID']['input']>;
  supervisorLastName?: InputMaybe<Scalars['String']['input']>;
  terminationEmploymentActor?: InputMaybe<TerminationEmploymentActor>;
  terminationEmploymentDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export class ImageAccessInput {
  organizationId: Scalars['ID']['input'];
};

export class ImageAccessTokenResult {
  __typename?: 'ImageAccessTokenResult';
  expiresOn: Scalars['DateTime']['output'];
  organizationId: Scalars['ID']['output'];
  origin: Scalars['String']['output'];
  sasToken: Scalars['String']['output'];
};

export class ImmigrationAuthority {
  __typename?: 'ImmigrationAuthority';
  id: Scalars['String']['output'];
  state: Scalars['String']['output'];
  title: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export class ImmigrationAuthorityInput {
  federalStates?: InputMaybe<Array<Scalars['String']['input']>>;
  immigrationCountry: SupportedImmigrationCountry;
  organizationId: Scalars['String']['input'];
};

export class ImportUrlInput {
  blob: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
};

export enum InstanceStateEnum {
  Active = 'Active',
  Cancelled = 'Cancelled',
  Error = 'Error',
  Finished = 'Finished',
  Inactive = 'Inactive',
  Started = 'Started'
}

export class InternalCandidateData {
  __typename?: 'InternalCandidateData';
  category?: Maybe<Scalars['String']['output']>;
  costCenter?: Maybe<Scalars['String']['output']>;
  headerComment?: Maybe<Scalars['String']['output']>;
  hr?: Maybe<HumanResourcesData>;
  isReplacementForCandidate?: Maybe<Scalars['Boolean']['output']>;
  languageSchooldId?: Maybe<Scalars['ID']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  replacementForCandidateId?: Maybe<Scalars['ID']['output']>;
  replacementForDisplayId?: Maybe<Scalars['String']['output']>;
  replacementForDisplayName?: Maybe<Scalars['String']['output']>;
  replacementForOrganizationId?: Maybe<Scalars['String']['output']>;
  responsibleEmployees?: Maybe<Array<Scalars['ID']['output']>>;
  scholarships?: Maybe<Array<CandidateScholarship>>;
  sourceOrganizationId?: Maybe<Scalars['ID']['output']>;
};

export class InternalCandidateDataInput {
  category?: InputMaybe<Scalars['String']['input']>;
  costCenter?: InputMaybe<Scalars['String']['input']>;
  headerComment?: InputMaybe<Scalars['String']['input']>;
  hr?: InputMaybe<HumanResourcesDataInput>;
  isReplacementForCandidate?: InputMaybe<Scalars['Boolean']['input']>;
  languageSchooldId?: InputMaybe<Scalars['ID']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  replacementForCandidateId?: InputMaybe<Scalars['ID']['input']>;
  replacementForDisplayId?: InputMaybe<Scalars['String']['input']>;
  replacementForDisplayName?: InputMaybe<Scalars['String']['input']>;
  replacementForOrganizationId?: InputMaybe<Scalars['String']['input']>;
  responsibleEmployees?: InputMaybe<Array<Scalars['ID']['input']>>;
  scholarships?: InputMaybe<Array<CandidateScholarshipInput>>;
  sourceOrganizationId?: InputMaybe<Scalars['ID']['input']>;
};

export class IsMatchingEnabled {
  __typename?: 'IsMatchingEnabled';
  destinationOrganizationId: Scalars['ID']['output'];
  enabled: Scalars['Boolean']['output'];
};

export class IsMatchingEnabledInput {
  destinationOrganizationIds: Array<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
};

export enum KnownFormToken {
  CandidateAllowence = 'CandidateAllowence',
  InterviewAppointmentConfirmationForm = 'InterviewAppointmentConfirmationForm',
  InterviewAppointmentProposalForm = 'InterviewAppointmentProposalForm',
  InterviewAppointmentScheduleForm = 'InterviewAppointmentScheduleForm',
  InterviewAppointmentScheduleSingleDateForm = 'InterviewAppointmentScheduleSingleDateForm',
  MatchingAgreementForm = 'MatchingAgreementForm',
  QualificationEvaluationConfiguration = 'QualificationEvaluationConfiguration',
  QualificationEvaluationReceived = 'QualificationEvaluationReceived',
  QualificationEvaluationRequest = 'QualificationEvaluationRequest',
  RecruitingConcept = 'RecruitingConcept',
  ShippingInformationForm = 'ShippingInformationForm',
  SubmissionDate = 'SubmissionDate',
  TravelBookingForm = 'TravelBookingForm'
}

export enum KnownPdfFormTokens {
  AgreementAcceleratedRecognition = 'AgreementAcceleratedRecognition',
  AgreementGuetezeichen = 'AgreementGuetezeichen',
  AgreementRecognitionPartnership = 'AgreementRecognitionPartnership',
  AnnexSupportQualificationEmployee = 'AnnexSupportQualificationEmployee',
  ApplicationAcceleratedRecognition = 'ApplicationAcceleratedRecognition',
  ApplicationExtendResidencePermit = 'ApplicationExtendResidencePermit',
  ApplicationRegistrationOffice = 'ApplicationRegistrationOffice',
  ApplicationResidencePermit = 'ApplicationResidencePermit',
  ApplicationTechnikerInsurance = 'ApplicationTechnikerInsurance',
  ApplicationValuationDegrees = 'ApplicationValuationDegrees',
  ApplicationWageSubsidyEmployeeAbsenceTraining = 'ApplicationWageSubsidyEmployeeAbsenceTraining',
  CandidateProfileTalentCountry = 'CandidateProfileTalentCountry',
  CertificateEmployerSupport = 'CertificateEmployerSupport',
  CertificateOfConduct = 'CertificateOfConduct',
  CompensationConfirmation = 'CompensationConfirmation',
  CompensatoryMeasure = 'CompensatoryMeasure',
  ConfirmationNoMatching = 'ConfirmationNoMatching',
  ConstitutionInstruction = 'ConstitutionInstruction',
  CostAbsorption = 'CostAbsorption',
  CostAbsorptionValuation = 'CostAbsorptionValuation',
  DecisionAdaptionCourse = 'DecisionAdaptionCourse',
  DecisionQualificationMeasure = 'DecisionQualificationMeasure',
  DeclarationCancellationJobTitle = 'DeclarationCancellationJobTitle',
  DeclarationEmployeeWageSubsidyTraining = 'DeclarationEmployeeWageSubsidyTraining',
  DeclarationOfCorrespondence = 'DeclarationOfCorrespondence',
  DeclarationOfEmployerAt = 'DeclarationOfEmployerAT',
  DeclarationOfEmployment = 'DeclarationOfEmployment',
  DeclarationOfEmploymentAfterRecognition = 'DeclarationOfEmploymentAfterRecognition',
  DeclarationOfEmploymentBeforeRecognition = 'DeclarationOfEmploymentBeforeRecognition',
  DeclarationOfImpunity = 'DeclarationOfImpunity',
  DeclarationRecognitionProcedure = 'DeclarationRecognitionProcedure',
  DeclarationVisaProcess = 'DeclarationVisaProcess',
  DoctorCertificateStudyPurpose = 'DoctorCertificateStudyPurpose',
  EducationVoucher = 'EducationVoucher',
  EducationVoucherAg = 'EducationVoucherAG',
  EducationVoucherOsnabrueck = 'EducationVoucherOsnabrueck',
  FamilyReunionChildren = 'FamilyReunionChildren',
  FamilyReunionPartner = 'FamilyReunionPartner',
  HealthProfessionsRegister = 'HealthProfessionsRegister',
  KnowledgeExamApplication = 'KnowledgeExamApplication',
  LanguageExamApplication = 'LanguageExamApplication',
  MedicalCertificate = 'MedicalCertificate',
  MedicalCertificateDoctor = 'MedicalCertificateDoctor',
  NostrificationGuKg = 'NostrificationGuKG',
  NostrificationGuKgTraining = 'NostrificationGuKGTraining',
  NostrificationMabg = 'NostrificationMABG',
  NostrificationMtdg = 'NostrificationMTDG',
  NostrifizierungFh = 'NostrifizierungFH',
  NostrifizierungFhPowerOfAttorney = 'NostrifizierungFHPowerOfAttorney',
  PowerOfAttorney = 'PowerOfAttorney',
  PowerOfAttorneyDelivery = 'PowerOfAttorneyDelivery',
  PowerOfAttorneyRegular = 'PowerOfAttorneyRegular',
  PowerOfAttorneyTechnikerInsurance = 'PowerOfAttorneyTechnikerInsurance',
  PowerOfAttorneyValuation = 'PowerOfAttorneyValuation',
  PowerOfRepresentation = 'PowerOfRepresentation',
  QuestionnaireScientology = 'QuestionnaireScientology',
  Rwr = 'RWR',
  RecognitionApplication = 'RecognitionApplication',
  RecognitionCv = 'RecognitionCV',
  RegistrationCertificateTrainingVoucher = 'RegistrationCertificateTrainingVoucher',
  SubPowerOfAttorney = 'SubPowerOfAttorney',
  SupplementarySheetA = 'SupplementarySheetA',
  SupplementarySheetC = 'SupplementarySheetC',
  VisaApplication = 'VisaApplication',
  VisaExtension = 'VisaExtension',
  VocationalTrainingQuestionnaire = 'VocationalTrainingQuestionnaire',
  WorkGuarantee = 'WorkGuarantee'
}

export class KnownVarsNode {
  __typename?: 'KnownVarsNode';
  data: TreeNodeData;
  key: Scalars['String']['output'];
  label: Scalars['String']['output'];
  parentKey?: Maybe<Scalars['String']['output']>;
};

export class KnownVarsTreeDto {
  __typename?: 'KnownVarsTreeDTO';
  knownVars: Array<KnownVarsNode>;
  metaData?: Maybe<Scalars['String']['output']>;
};

export class LaborAgreement {
  __typename?: 'LaborAgreement';
  _etag?: Maybe<Scalars['String']['output']>;
  allowances?: Maybe<Array<LaborAgreementAllowance>>;
  benefits?: Maybe<Array<LaborAgreementBenefit>>;
  canBeDeleted: Scalars['Boolean']['output'];
  changedAt: Scalars['DateTime']['output'];
  changedBy: Scalars['String']['output'];
  country: SupportedImmigrationCountry;
  federalStates: Array<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  institutionA?: Maybe<Scalars['String']['output']>;
  institutionB?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  noticePeriod: Scalars['Float']['output'];
  noticePeriodUnit: ContractTermTimePeriod;
  organizationId: Scalars['ID']['output'];
  payGrades: Array<LaborAgreementPayGrade>;
  probationPeriod: Scalars['Float']['output'];
  probationPeriodUnit: ContractTermTimePeriod;
  type: Scalars['String']['output'];
  validFrom?: Maybe<Scalars['DateTime']['output']>;
  validUntil?: Maybe<Scalars['DateTime']['output']>;
};

export class LaborAgreementAllowance {
  __typename?: 'LaborAgreementAllowance';
  amount: Scalars['Float']['output'];
  calculationBasis: CalculationBasis;
  comment?: Maybe<Scalars['String']['output']>;
  customType?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  type?: Maybe<Scalars['String']['output']>;
};

export class LaborAgreementAllowanceInput {
  amount: Scalars['Float']['input'];
  calculationBasis: CalculationBasis;
  comment?: InputMaybe<Scalars['String']['input']>;
  customType?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  type?: InputMaybe<Scalars['String']['input']>;
};

export class LaborAgreementBenefit {
  __typename?: 'LaborAgreementBenefit';
  amount: Scalars['Float']['output'];
  calculationBasis: CalculationBasis;
  id: Scalars['ID']['output'];
  timeInterval: BenefitTimeInterval;
  type: Scalars['String']['output'];
};

export class LaborAgreementBenefitInput {
  amount: Scalars['Float']['input'];
  calculationBasis: CalculationBasis;
  id: Scalars['ID']['input'];
  timeInterval: BenefitTimeInterval;
  type: Scalars['String']['input'];
};

export class LaborAgreementCreateInput {
  allowances?: InputMaybe<Array<LaborAgreementAllowanceInput>>;
  benefits?: InputMaybe<Array<LaborAgreementBenefitInput>>;
  country: SupportedImmigrationCountry;
  federalStates: Array<Scalars['String']['input']>;
  institutionA?: InputMaybe<Scalars['String']['input']>;
  institutionB?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  noticePeriod: Scalars['Float']['input'];
  noticePeriodUnit: ContractTermTimePeriod;
  organizationId: Scalars['ID']['input'];
  payGrades: Array<LaborAgreementPayGradeInput>;
  probationPeriod: Scalars['Float']['input'];
  probationPeriodUnit: ContractTermTimePeriod;
  type: Scalars['String']['input'];
  validFrom?: InputMaybe<Scalars['DateTime']['input']>;
  validUntil?: InputMaybe<Scalars['DateTime']['input']>;
};

export class LaborAgreementDeleteInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
};

export class LaborAgreementGetInput {
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
};

export class LaborAgreementListInput {
  country?: InputMaybe<SupportedImmigrationCountry>;
  organizationId: Scalars['ID']['input'];
};

export class LaborAgreementPayGrade {
  __typename?: 'LaborAgreementPayGrade';
  compensationRate: Scalars['Float']['output'];
  compensationType: CompensationType;
  id: Scalars['ID']['output'];
  salaryGroup: Scalars['String']['output'];
  salaryStep: Scalars['String']['output'];
  workingHoursPerWeek: Scalars['Float']['output'];
};

export class LaborAgreementPayGradeInput {
  compensationRate: Scalars['Float']['input'];
  compensationType: CompensationType;
  id: Scalars['ID']['input'];
  salaryGroup: Scalars['String']['input'];
  salaryStep: Scalars['String']['input'];
  workingHoursPerWeek: Scalars['Float']['input'];
};

export class LaborAgreementSelection {
  __typename?: 'LaborAgreementSelection';
  laborAgreementId?: Maybe<Scalars['ID']['output']>;
  organizationId?: Maybe<Scalars['ID']['output']>;
  payGradeId?: Maybe<Scalars['ID']['output']>;
};

export class LaborAgreementSelectionInput {
  laborAgreementId?: InputMaybe<Scalars['ID']['input']>;
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  payGradeId?: InputMaybe<Scalars['ID']['input']>;
};

export class LaborAgreementUpdateInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  allowances?: InputMaybe<Array<LaborAgreementAllowanceInput>>;
  benefits?: InputMaybe<Array<LaborAgreementBenefitInput>>;
  country: SupportedImmigrationCountry;
  federalStates: Array<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  institutionA?: InputMaybe<Scalars['String']['input']>;
  institutionB?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  noticePeriod: Scalars['Float']['input'];
  noticePeriodUnit: ContractTermTimePeriod;
  organizationId: Scalars['ID']['input'];
  payGrades: Array<LaborAgreementPayGradeInput>;
  probationPeriod: Scalars['Float']['input'];
  probationPeriodUnit: ContractTermTimePeriod;
  type: Scalars['String']['input'];
  validFrom?: InputMaybe<Scalars['DateTime']['input']>;
  validUntil?: InputMaybe<Scalars['DateTime']['input']>;
};

export class LaborMarketAdmissionCandidateDataDe {
  __typename?: 'LaborMarketAdmissionCandidateDataDE';
  employmentApprovalAuthority?: Maybe<Scalars['String']['output']>;
  employmentApprovalRequestDate?: Maybe<Scalars['DateTime']['output']>;
  employmentApprovalRequested?: Maybe<Scalars['Boolean']['output']>;
  responsibleRoleLaborMarket?: Maybe<Scalars['String']['output']>;
};

export class LaborMarketAdmissionCandidateDataDeInput {
  employmentApprovalAuthority?: InputMaybe<Scalars['String']['input']>;
  employmentApprovalRequestDate?: InputMaybe<Scalars['DateTime']['input']>;
  employmentApprovalRequested?: InputMaybe<Scalars['Boolean']['input']>;
  responsibleRoleLaborMarket?: InputMaybe<Scalars['String']['input']>;
};

export class LanguageSkill {
  __typename?: 'LanguageSkill';
  certified?: Maybe<Scalars['Boolean']['output']>;
  examInstitution?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  language?: Maybe<Scalars['String']['output']>;
  skillLevel?: Maybe<Scalars['String']['output']>;
};

export class LastUserLogin {
  __typename?: 'LastUserLogin';
  lastLoginDate?: Maybe<Scalars['DateTime']['output']>;
};

export class LastUserLoginInput {
  auth0Id?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['ID']['input'];
};

export class LicensePrice {
  __typename?: 'LicensePrice';
  amount: Scalars['Float']['output'];
};

export class LinkAccountInputAfterLogin {
  connection: Scalars['String']['input'];
  ssoId: Scalars['String']['input'];
};

export class LinkAccountInputBeforeLogin {
  attempts: Scalars['Float']['input'];
  connection: Scalars['String']['input'];
  expiryDate: Scalars['String']['input'];
  hashValue: Scalars['String']['input'];
  isCandidate: Scalars['Boolean']['input'];
  organizationId: Scalars['ID']['input'];
  ssoId: Scalars['String']['input'];
  userOrCandidateId: Scalars['ID']['input'];
};

export class LinkableOrganizationsInput {
  linkedOrganizationId?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
};

export class LocalPartnerProfileConfigSet {
  __typename?: 'LocalPartnerProfileConfigSet';
  _etag?: Maybe<Scalars['String']['output']>;
  changedAt: Scalars['DateTime']['output'];
  changedBy: Scalars['String']['output'];
  id?: Maybe<Scalars['ID']['output']>;
  organizationId: Scalars['ID']['output'];
  setName: Scalars['String']['output'];
};

export class LocalPartnerProfileConfigSetCreateInput {
  organizationId: Scalars['ID']['input'];
  setName: Scalars['String']['input'];
};

export class LocalPartnerProfileConfigSetDeleteInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
};

export class LocalPartnerProfileConfigSetGetInput {
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
};

export class LocalPartnerProfileConfigSetGetListAllInput {
  organizationId: Scalars['ID']['input'];
};

export class LocalPartnerProfileConfigSetUpdateInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
  setName: Scalars['String']['input'];
};

export class LocalizedString {
  __typename?: 'LocalizedString';
  language: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export class LocalizedStringInput {
  language: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export enum MatchingStatus {
  Disabled = 'Disabled',
  Enabled = 'Enabled',
  Viewable = 'Viewable'
}

export class Menu {
  __typename?: 'Menu';
  employerSearch?: Maybe<MenuEmployerSearch>;
  profile?: Maybe<MenuProfile>;
  tasks?: Maybe<MenuTasks>;
};

export class MenuEmployerSearch {
  __typename?: 'MenuEmployerSearch';
  canApply?: Maybe<Scalars['Boolean']['output']>;
  enabled: Scalars['Boolean']['output'];
};

export class MenuProfile {
  __typename?: 'MenuProfile';
  enabled: Scalars['Boolean']['output'];
  tabs?: Maybe<Array<CandidateProfileConfigTab>>;
};

export class MenuTasks {
  __typename?: 'MenuTasks';
  enabled: Scalars['Boolean']['output'];
};

export class MessagesReception {
  __typename?: 'MessagesReception';
  _etag?: Maybe<Scalars['String']['output']>;
  candidateOrUserId: Scalars['String']['output'];
  enabled?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  modifier?: Maybe<Modifier>;
  organizationId: Scalars['ID']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export enum MessengerEventType {
  Failed = 'Failed',
  Sent = 'Sent'
}

export class MessengerMessage {
  __typename?: 'MessengerMessage';
  _etag?: Maybe<Scalars['String']['output']>;
  events?: Maybe<Array<MessengerMessageEvent>>;
  id?: Maybe<Scalars['ID']['output']>;
  language: Scalars['String']['output'];
  lastSentDate: Scalars['DateTime']['output'];
  message?: Maybe<Scalars['String']['output']>;
  messenger: Scalars['String']['output'];
  messengerMessageId?: Maybe<Scalars['String']['output']>;
  organizationId: Scalars['ID']['output'];
  reason: MessengerMessageReason;
  relatedProcessId?: Maybe<Scalars['String']['output']>;
  relatedProcessOrganizationId?: Maybe<Scalars['String']['output']>;
  relatesToEmailId?: Maybe<Scalars['ID']['output']>;
  userOrCandidateId: Scalars['ID']['output'];
};

export class MessengerMessageEvent {
  __typename?: 'MessengerMessageEvent';
  date: Scalars['DateTime']['output'];
  message?: Maybe<Scalars['String']['output']>;
  type: MessengerEventType;
};

export enum MessengerMessageReason {
  AdHocMessage = 'AdHocMessage',
  Email = 'Email',
  StandardReply = 'StandardReply',
  WelcomeAfterOptIn = 'WelcomeAfterOptIn'
}

export class MessengerUserOptIn {
  __typename?: 'MessengerUserOptIn';
  _etag?: Maybe<Scalars['String']['output']>;
  allowMessaging: Scalars['Boolean']['output'];
  candidateOrUserId: Scalars['ID']['output'];
  id?: Maybe<Scalars['ID']['output']>;
  organizationId: Scalars['ID']['output'];
  userAskedDate?: Maybe<Scalars['DateTime']['output']>;
};

export class MeteredTranslationInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
  propertyPath: Array<Scalars['String']['input']>;
  sourceText?: InputMaybe<Scalars['String']['input']>;
  sourceTextAsDelta?: InputMaybe<Scalars['JSONObject']['input']>;
  targetTextAsDelta?: InputMaybe<Scalars['JSONObject']['input']>;
  translationItemId: Scalars['String']['input'];
};

export enum MetricType {
  AnkaadiaUpdates = 'AnkaadiaUpdates',
  CandidateBirthdays = 'CandidateBirthdays',
  CandidatesCreated = 'CandidatesCreated',
  CandidatesLoggedIn = 'CandidatesLoggedIn',
  DocumentsExpiring = 'DocumentsExpiring',
  DocumentsUploaded = 'DocumentsUploaded',
  TasksFinished = 'TasksFinished'
}

export class MigrationCandidateDataAt {
  __typename?: 'MigrationCandidateDataAT';
  employmentRelationship?: Maybe<EmploymentRelationship>;
  healthProfessionsRegister?: Maybe<HealthProfessionsRegisterCandidateDataAt>;
  qualificationMeasure?: Maybe<QualificationMeasureCandidateDataAt>;
  recognitionPath?: Maybe<RecognitionPathEntryAt>;
  residence?: Maybe<ResidenceCandidateDataAt>;
  visa?: Maybe<VisaCandidateDataAt>;
  vocationalSchool?: Maybe<VocationalSchoolCandidateDataAt>;
};

export class MigrationCandidateDataAtInput {
  employmentRelationship?: InputMaybe<EmploymentRelationshipInput>;
  healthProfessionsRegister?: InputMaybe<HealthProfessionsRegisterCandidateDataAtInput>;
  qualificationMeasure?: InputMaybe<QualificationMeasureCandidateDataAtInput>;
  recognitionPath?: InputMaybe<RecognitionPathEntryAtInput>;
  residence?: InputMaybe<ResidenceCandidateDataAtInput>;
  visa?: InputMaybe<VisaCandidateDataAtInput>;
  vocationalSchool?: InputMaybe<VocationalSchoolCandidateDataAtInput>;
};

export class MigrationCandidateDataContainer {
  __typename?: 'MigrationCandidateDataContainer';
  at?: Maybe<MigrationCandidateDataAt>;
  de?: Maybe<MigrationCandidateDataDe>;
  housingAcquisition?: Maybe<HousingAcquisition>;
};

export class MigrationCandidateDataContainerForView {
  __typename?: 'MigrationCandidateDataContainerForView';
  at?: Maybe<MigrationCandidateDataAt>;
  de?: Maybe<MigrationCandidateDataDeForView>;
  housingAcquisition?: Maybe<HousingAcquisition>;
};

export class MigrationCandidateDataContainerInput {
  at?: InputMaybe<MigrationCandidateDataAtInput>;
  de?: InputMaybe<MigrationCandidateDataDeInput>;
  housingAcquisition?: InputMaybe<HousingAcquisitionInput>;
};

export class MigrationCandidateDataDe {
  __typename?: 'MigrationCandidateDataDE';
  employmentRelationship?: Maybe<EmploymentRelationship>;
  laborMarketAdmission?: Maybe<LaborMarketAdmissionCandidateDataDe>;
  qualificationEvaluation?: Maybe<QualificationEvaluationDataDe>;
  qualificationMeasure?: Maybe<QualificationMeasureCandidateDataDe>;
  recognitionPath?: Maybe<RecognitionPathCandidateDataDe>;
  residence?: Maybe<ResidenceCandidateDataDe>;
  visa?: Maybe<VisaCandidateDataDe>;
  vocationalSchool?: Maybe<VocationalSchoolCandidateDataDe>;
};

export class MigrationCandidateDataDeForView {
  __typename?: 'MigrationCandidateDataDEForView';
  employmentRelationship?: Maybe<EmploymentRelationship>;
  laborMarketAdmission?: Maybe<LaborMarketAdmissionCandidateDataDe>;
  qualificationEvaluation?: Maybe<QualificationEvaluationDataDe>;
  qualificationMeasure?: Maybe<QualificationMeasureCandidateDataDe>;
  recognitionPath?: Maybe<RecognitionPathCandidateDataDeForView>;
  residence?: Maybe<ResidenceCandidateDataDe>;
  visa?: Maybe<VisaCandidateDataDe>;
  vocationalSchool?: Maybe<VocationalSchoolCandidateDataDe>;
};

export class MigrationCandidateDataDeInput {
  employmentRelationship?: InputMaybe<EmploymentRelationshipInput>;
  laborMarketAdmission?: InputMaybe<LaborMarketAdmissionCandidateDataDeInput>;
  qualificationEvaluation?: InputMaybe<QualificationEvaluationDataDeInput>;
  qualificationMeasure?: InputMaybe<QualificationMeasureCandidateDataDeInput>;
  recognitionPath?: InputMaybe<RecognitionPathCandidateDataDeInput>;
  residence?: InputMaybe<ResidenceCandidateDataDeInput>;
  visa?: InputMaybe<VisaCandidateDataDeInput>;
  vocationalSchool?: InputMaybe<VocationalSchoolCandidateDataDeInput>;
};

export class Modifier {
  __typename?: 'Modifier';
  id: Scalars['ID']['output'];
  isCandidate?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  orgId: Scalars['ID']['output'];
};

export class ModifierInput {
  id: Scalars['ID']['input'];
  isCandidate?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  orgId: Scalars['ID']['input'];
};

export class Mutation {
  __typename?: 'Mutation';
  addAssignedCollection: Collection;
  addCandidateDeletionRequest?: Maybe<CandidateDeletionRequest>;
  addCandidateToCollections: AddCandidatesToCollectionsResult;
  addForeignGroupsToUserGroup: UserGroup;
  addUsersToGroup: UserGroup;
  applyAutoFilterTemplateOnAllCandidates: CollectionAutoFilterTemplate;
  applyCommand: ProcessModelCommandResult;
  applyEmployer: MutationResult;
  attachAutoCollectionsToTemplate: Array<Collection>;
  attachProcessToAction: ProcessAction;
  blockUnblockCandidate?: Maybe<CandidateSystemOnboarding>;
  blockUser: User;
  changeLockModeForDocuments: Array<Document>;
  changeLockModeForDocumentsByCandidate: Array<Document>;
  clearAllNotifications: Scalars['Boolean']['output'];
  clearNotification: Scalars['Boolean']['output'];
  clearPdfFormTemplate: PdfFormTemplate;
  confirmOptInState: MessengerUserOptIn;
  copyDataToOrg: CopyResult;
  copySharingPreset: SharingPreset;
  createAdHocEducationExam: AdHocEducationExam;
  createAuditDefinition: AuditDefinition;
  createCandidate: Candidate;
  createCandidateNote: CandidateNote;
  createCandidateProfileConfig: CandidateProfileConfig;
  createCollection: Collection;
  createCollectionFilterPreset: CollectionFilterPreset;
  createCollectionWithSharing: Collection;
  createComment: Comment;
  createConsent: Consent;
  createContract: Contract;
  createDerivedProcessModel: ProcessModelExtensionEntity;
  createDocument: Document;
  createDocumentAiPerformances: MutationResult;
  createEducationCourse: EducationCourse;
  createEducationExam: EducationExam;
  createEducationExamParticipant: EducationExamParticipant;
  createEducationModule: EducationModule;
  createEmailSendOutRule: EmailSendOutRule;
  createEmailTemplate: EmailTemplateMutationResult;
  createEmptyProcessModel: ProcessModelEntity;
  createFeedback: Feedback;
  createLaborAgreement: LaborAgreement;
  createLocalPartnerProfileConfigSet: LocalPartnerProfileConfigSet;
  createManyEducationExamParticipants: Array<EducationExamParticipant>;
  createNewPublicAPIAccessToken: Scalars['String']['output'];
  createOrganization: Organization;
  createOrganizationAndCertificationProcess: ReCertificationProcessCreationResult;
  createOrganizationContact: OrganizationContact;
  createOrganizationLink: OrganizationLink;
  createProcess: Process;
  createProcessAction: ProcessAction;
  createProcessModelRelease: ReleasedProcessModelInfo;
  createProfessionalExperience: ProfessionalExperience;
  createProfessionalField: ProfessionalField;
  createReCertificationProcess: ReCertificationProcessCreationResult;
  createRequiredDocument: RequiredDocument;
  createSharingPreset: SharingPreset;
  createSharingTemplate: Array<SharingTemplate>;
  createSharings: Array<Sharing>;
  createShortLink: Scalars['String']['output'];
  createStripeCustomer?: Maybe<Scalars['Void']['output']>;
  createTalentPool: TalentPool;
  createTalentPoolParticipants: Array<TalentPoolParticipant>;
  createTalentPoolSharing: TalentPoolSharing;
  createTask: ProcessTaskResult;
  createTranslationProposal?: Maybe<MutationResult>;
  createUser: User;
  createUserFeedback: MutationResult;
  createUserGroup: UserGroup;
  deleteAuditDefinition?: Maybe<Scalars['Void']['output']>;
  deleteBlob: MutationResult;
  deleteCandidate: MutationResult;
  deleteCandidateNote?: Maybe<Scalars['Void']['output']>;
  deleteCollection: MutationResult;
  deleteCollectionAutoFilterTemplate: MutationResult;
  deleteCollectionFilterPreset: MutationResult;
  deleteComment: MutationResult;
  deleteCondidateProfileConfig: MutationResult;
  deleteConsent?: Maybe<Scalars['Void']['output']>;
  deleteContractTemplate: MutationResult;
  deleteDocument: MutationResult;
  deleteEducationCourse?: Maybe<Scalars['Void']['output']>;
  deleteEducationExam: MutationResult;
  deleteEducationExamParticipant: MutationResult;
  deleteEducationExamResult: MutationResult;
  deleteEducationModule?: Maybe<Scalars['Void']['output']>;
  deleteEmailSendOutRule: Scalars['Boolean']['output'];
  deleteEmailTemplate: MutationResult;
  deleteInterviewComment?: Maybe<CandidateInterviewsFeedback>;
  deleteInterviewFeedback?: Maybe<CandidateInterviewsFeedback>;
  deleteInterviewIteration?: Maybe<CandidateInterviewsFeedback>;
  deleteLaborAgreement: MutationResult;
  deleteLocalPartnerProfileConfigSet: MutationResult;
  deleteOrganizationContact: MutationResult;
  deleteOrganizationLink: MutationResult;
  deleteProcess: MutationResult;
  deleteProcessAction: MutationResult;
  deleteProcessModel: MutationResult;
  deleteProcessModelRelease: MutationResult;
  deleteProfessionalExperience?: Maybe<Scalars['Void']['output']>;
  deleteProfessionalField?: Maybe<Scalars['Void']['output']>;
  deleteRequiredDocument: MutationResult;
  deleteSharing: MutationResult;
  deleteSharingPreset?: Maybe<Scalars['Void']['output']>;
  deleteSharingTemplate: MutationResult;
  deleteTalentPool?: Maybe<Scalars['Void']['output']>;
  deleteTalentPoolParticipant?: Maybe<Scalars['Void']['output']>;
  deleteTalentPoolSharing?: Maybe<Scalars['Void']['output']>;
  deleteTask: MutationResult;
  detachAllCollectionsFromTemplate: Array<Collection>;
  detachCollectionFromTemplate: Collection;
  detachProcessFromAction: ProcessAction;
  duplicateEmailTemplate: EmailTemplateMutationResult;
  duplicateProcessModel: ProcessModelResult;
  duplicateRequiredDocument: RequiredDocument;
  executeCandidateMultiEdit: Array<Candidate>;
  executeCandidateMultiInvite?: Maybe<Scalars['Void']['output']>;
  executeCandidateMultiUninvite?: Maybe<Scalars['Void']['output']>;
  generateAllDocumentTemplatesForCandidate: Array<DocumentTemplate>;
  generateAllDocumentTemplatesForCollection: Array<DocumentTemplate>;
  generateDocumentTemplate: DocumentTemplate;
  generateReportTemplate: GenerateTemplateReportResult;
  getAndGenerateNonEditedTemplate: DocumentTemplate;
  giveConsent: MutationResult;
  importFilledXLSXTemplateForCandidates: XlsxImportResult;
  importFilledXLSXTemplateForContractTemplates: XlsxImportResult;
  importFilledXLSXTemplateForOrganizationCRMData: XlsxImportResult;
  importFilledXLSXTemplateForOrganizationContactSettings: XlsxImportResult;
  importFilledXLSXTemplateForOrganizationContacts: XlsxImportResult;
  importFilledXLSXTemplateForOrganizationUserGroups: XlsxImportResult;
  importFilledXLSXTemplateForOrganizationUserMemberships: XlsxImportResult;
  importFilledXLSXTemplateForOrganizationUsers: XlsxImportResult;
  importFilledXLSXTemplateForOrganizations: XlsxImportResult;
  importXLSXProcessTranslations: XlsxTranslationImportResult;
  inviteCandidate?: Maybe<CandidateSystemOnboarding>;
  linkAccountAfterLogin: MutationResult;
  linkAccountBeforeLogin: MutationResult;
  linkGuetegemeinschaftOrganization: GuetegemeinschaftOrganizationLinkOutput;
  makeCollectionFilterPresetPrivate: CollectionFilterPreset;
  makeCollectionFilterPresetPublic: CollectionFilterPreset;
  makeTenantToDemoTenant: CopyResult;
  markDocumentTemplateAsEdited: DocumentTemplate;
  markTalent?: Maybe<Scalars['Void']['output']>;
  performTask: ProcessPerformResult;
  prefillEducationCourseWeek?: Maybe<Scalars['Void']['output']>;
  profilePictureUpdated: MutationResult;
  publishProcessModel: PublishedProcessModelEntity;
  reInviteUser: MutationResult;
  remindTask?: Maybe<TaskReminderState>;
  removeAssignedCollection: Collection;
  removeCandidateDeletionRequest?: Maybe<Scalars['Void']['output']>;
  removeCandidateFromCollection: MutationResult;
  removeDocumentTypeOverwrite: MutationResult;
  removeForeignGroupsFromUserGroup: UserGroup;
  removeLicense?: Maybe<Scalars['Void']['output']>;
  removeOrganization: MutationResult;
  removeUser: MutationResult;
  removeUserGroup: MutationResult;
  removeUsersFromGroup: UserGroup;
  requestTalents?: Maybe<Scalars['Void']['output']>;
  requestTalentsRestricted?: Maybe<Scalars['Void']['output']>;
  requireConsentOrganization: Organization;
  resetCandidatePassword?: Maybe<CandidateSystemOnboarding>;
  resetPassword: MutationResult;
  sendMessage: MutationResult;
  sendProcessEmail: MutationResult;
  sendProcessEmails: MutationResult;
  setCollectionAutoFilter: CollectionAutoFilter;
  setCollectionAutoFilterTemplate: CollectionAutoFilterTemplate;
  setCommunicationLanguage: MutationResult;
  setContractTemplate: ContractTemplate;
  setFavoriteCandidate: MutationResult;
  setFavoriteCollection: MutationResult;
  setMatchingEnabled: MutationResult;
  setNotificationSettings: NotificationSettings;
  setOrganizationContactSettings: OrganizationContactSettings;
  setOrganizationCrm: OrganizationCrm;
  setOrganizationProfile: OrganizationProfile;
  setPdfFormTemplate: PdfFormTemplate;
  setPdfFormTemplateSettings: PdfFormTemplateSettings;
  setProfessionConfiguration: ProfessionConfiguration;
  submitSharing: Sharing;
  switchUserOrganization: Scalars['Boolean']['output'];
  syncAllCollectionsWithTemplate: Array<Collection>;
  syncAllProcesses: Array<Process>;
  syncCollectionWithTemplate: Collection;
  syncProcess: Process;
  toggleMessagesReception: MessagesReception;
  toggleProcessState: Process;
  transferCollection: CollectionTransferResult;
  triggerRebuildSharingMatrix: Scalars['Boolean']['output'];
  unBlockUser: User;
  unInviteCandidate?: Maybe<CandidateSystemOnboarding>;
  unLinkFacebookAccount: MutationResult;
  undoLastCommand: ProcessModelCommandResult;
  unmarkTalent?: Maybe<Scalars['Void']['output']>;
  unpublishProcessModel: PublishedProcessModelEntity;
  unsetFavoriteCandidate: MutationResult;
  unsetFavoriteCollection: MutationResult;
  unsubmitSharing: Sharing;
  updateAdHocEducationExam: AdHocEducationExam;
  updateAssignedCandidates: Collection;
  updateAssignmentExceptions: Collection;
  updateAuditDefinition: AuditDefinition;
  updateAuditDefinitionMeta: AuditDefinition;
  updateCandidate: Candidate;
  updateCandidateNote: CandidateNote;
  updateCandidateProfile: Candidate;
  updateCandidateProfileConfig: CandidateProfileConfig;
  updateCollection: Collection;
  updateCollectionFilterPreset: CollectionFilterPreset;
  updateComment: Comment;
  updateConsent: Consent;
  updateDocument: Document;
  updateEducationCourse: EducationCourse;
  updateEducationCourseTotalPerformanceState?: Maybe<Scalars['Void']['output']>;
  updateEducationCourseWeeklyAttendance?: Maybe<Scalars['Void']['output']>;
  updateEducationCourseWeeklyComment?: Maybe<Scalars['Void']['output']>;
  updateEducationCourseWeeklyPerformance?: Maybe<Scalars['Void']['output']>;
  updateEducationExam: EducationExam;
  updateEducationModule: EducationModule;
  updateEmailSendOutRule: EmailSendOutRule;
  updateEmailTemplate: EmailTemplateMutationResult;
  updateFeedback: Feedback;
  updateLaborAgreement: LaborAgreement;
  updateLocalPartnerProfileConfigSet: LocalPartnerProfileConfigSet;
  updateOrganization: Organization;
  updateOrganizationContact: OrganizationContact;
  updateOrganizationContractSettings: OrganizationContractSettings;
  updateOrganizationLink: OrganizationLink;
  updateProcess: Process;
  updateProcessAction: ProcessAction;
  updateProcessModel: ProcessModelResult;
  updateProcessModelRelease: ReleasedProcessModelInfo;
  updateProfessionalExperience: ProfessionalExperience;
  updateProfessionalField: ProfessionalField;
  updateRequiredDocument: RequiredDocument;
  updateRequiredDocumentMeta: RequiredDocument;
  updateRequiredVaccinations: RequiredVaccinations;
  updateSharing: Sharing;
  updateSharingPreset: SharingPreset;
  updateSharingTemplate: SharingTemplate;
  updateSystemSetting: SystemSetting;
  updateTalentPool: TalentPool;
  updateTalentPoolParticipant: TalentPoolParticipant;
  updateTalentPoolSharing: TalentPoolSharing;
  updateTask: ProcessTaskResult;
  updateUser: User;
  updateUserGroup: UserGroup;
  upsertCandidateFieldTranslations: Array<PropertyTranslation>;
  upsertDocumentTypeOverwrite?: Maybe<DocumentTypeOverwrite>;
  upsertDocumentWithoutProcessStateChange?: Maybe<Document>;
  upsertEducationExamResult: EducationExamResult;
  upsertGeneralDocumentRequirements: GeneralDocumentRequirement;
  upsertInterviewComment: CandidateInterviewsFeedback;
  upsertInterviewEmployer: CandidateInterviewsFeedback;
  upsertInterviewFeedback: CandidateInterviewsFeedback;
  upsertManyDocumentRequirements: Array<DocumentRequirement>;
  upsertProfessionalFieldSettings: ProfessionalFieldSettings;
};


export type MutationAddAssignedCollectionArgs = {
  input: AssignCollectionInput;
};


export type MutationAddCandidateDeletionRequestArgs = {
  candidateId: Scalars['String']['input'];
  orgId: Scalars['String']['input'];
};


export type MutationAddCandidateToCollectionsArgs = {
  input: AddCandidatesToCollectionsInput;
};


export type MutationAddForeignGroupsToUserGroupArgs = {
  input: AddForeignGroupsToGroupInput;
};


export type MutationAddUsersToGroupArgs = {
  input: AddUsersToGroupInput;
};


export type MutationApplyAutoFilterTemplateOnAllCandidatesArgs = {
  input: ApplyAutoFilterTemplateOnAllCandidatesInput;
};


export type MutationApplyCommandArgs = {
  input: ProcessTaskModelCommandInputType;
  language: Scalars['String']['input'];
  validate: Scalars['Boolean']['input'];
};


export type MutationApplyEmployerArgs = {
  input: EmployerApplicationInput;
};


export type MutationAttachAutoCollectionsToTemplateArgs = {
  input: CollectionAutoFilterTemplateAttachInput;
};


export type MutationAttachProcessToActionArgs = {
  input: AttachProcessToActionInput;
};


export type MutationBlockUnblockCandidateArgs = {
  blocked: Scalars['Boolean']['input'];
  candidateId: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
};


export type MutationBlockUserArgs = {
  input: BlockUserInput;
};


export type MutationChangeLockModeForDocumentsArgs = {
  input: DocumentLockInput;
};


export type MutationChangeLockModeForDocumentsByCandidateArgs = {
  input: DocumentLockAllInput;
};


export type MutationClearNotificationArgs = {
  id: Scalars['ID']['input'];
};


export type MutationClearPdfFormTemplateArgs = {
  input: PdfFormTemplateClearInput;
};


export type MutationCopyDataToOrgArgs = {
  dataType: CopyDataTypeEnum;
  organizationId: Scalars['ID']['input'];
  templateDisplayNames?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type MutationCopySharingPresetArgs = {
  input: SharingPresetCopyInput;
};


export type MutationCreateAdHocEducationExamArgs = {
  input: AdHocEducationExamCreateInput;
};


export type MutationCreateAuditDefinitionArgs = {
  input: AuditDefinitionCreateInput;
};


export type MutationCreateCandidateArgs = {
  currentCollection?: InputMaybe<CandidateCreateCurrentSelection>;
  input: CandidateCreateInput;
};


export type MutationCreateCandidateNoteArgs = {
  input: CandidateNoteCreateInput;
};


export type MutationCreateCandidateProfileConfigArgs = {
  input: CandidateProfileConfigCreateInput;
};


export type MutationCreateCollectionArgs = {
  input: CollectionCreateInput;
};


export type MutationCreateCollectionFilterPresetArgs = {
  input: CollectionFilterPresetCreateInput;
};


export type MutationCreateCollectionWithSharingArgs = {
  input: CollectionAndSharingInput;
};


export type MutationCreateCommentArgs = {
  input: CommentCreateInput;
};


export type MutationCreateConsentArgs = {
  input: ConsentCreateInput;
};


export type MutationCreateContractArgs = {
  input: ContractCreateInput;
  organizationId: Scalars['ID']['input'];
};


export type MutationCreateDerivedProcessModelArgs = {
  input: ProcessModelCreateDerivedInput;
};


export type MutationCreateDocumentArgs = {
  input: DocumentCreateInput;
};


export type MutationCreateDocumentAiPerformancesArgs = {
  input: DocumentAiPerformanceCreateInput;
};


export type MutationCreateEducationCourseArgs = {
  input: EducationCourseCreateInput;
};


export type MutationCreateEducationExamArgs = {
  input: EducationExamCreateInput;
};


export type MutationCreateEducationExamParticipantArgs = {
  input: EducationExamParticipantCreateInput;
};


export type MutationCreateEducationModuleArgs = {
  input: EducationModuleCreateInput;
};


export type MutationCreateEmailSendOutRuleArgs = {
  input: EmailSendOutRuleCreateInput;
};


export type MutationCreateEmailTemplateArgs = {
  input: EmailTemplateCreateInput;
  language: Scalars['String']['input'];
};


export type MutationCreateEmptyProcessModelArgs = {
  input: ProcessModelCreateEmptyInput;
};


export type MutationCreateFeedbackArgs = {
  input: FeedbackCreateInput;
};


export type MutationCreateLaborAgreementArgs = {
  input: LaborAgreementCreateInput;
};


export type MutationCreateLocalPartnerProfileConfigSetArgs = {
  input: LocalPartnerProfileConfigSetCreateInput;
};


export type MutationCreateManyEducationExamParticipantsArgs = {
  input: EducationExamParticipantCreateManyInput;
};


export type MutationCreateNewPublicApiAccessTokenArgs = {
  organizationId: Scalars['String']['input'];
};


export type MutationCreateOrganizationArgs = {
  input: OrganizationInput;
};


export type MutationCreateOrganizationAndCertificationProcessArgs = {
  input: GuetegemeinschaftOrganizationReCertificationInput;
};


export type MutationCreateOrganizationContactArgs = {
  input: OrganizationContactCreateInput;
};


export type MutationCreateOrganizationLinkArgs = {
  input: OrganizationLinkCreateInput;
};


export type MutationCreateProcessArgs = {
  input: ProcessCreateInput;
};


export type MutationCreateProcessActionArgs = {
  input: ProcessActionCreateInput;
};


export type MutationCreateProcessModelReleaseArgs = {
  input: ProcessModelCreateReleaseInput;
};


export type MutationCreateProfessionalExperienceArgs = {
  input: ProfessionalExperienceCreateInput;
};


export type MutationCreateProfessionalFieldArgs = {
  input: ProfessionalFieldCreateInput;
};


export type MutationCreateReCertificationProcessArgs = {
  organizationId: Scalars['String']['input'];
};


export type MutationCreateRequiredDocumentArgs = {
  input: RequiredDocumentCreateInput;
};


export type MutationCreateSharingPresetArgs = {
  input: SharingPresetCreateInput;
};


export type MutationCreateSharingTemplateArgs = {
  input: SharingTemplateCreateInput;
};


export type MutationCreateSharingsArgs = {
  input: SharingCreateInput;
};


export type MutationCreateShortLinkArgs = {
  longLink: Scalars['String']['input'];
};


export type MutationCreateStripeCustomerArgs = {
  organizationId: Scalars['ID']['input'];
};


export type MutationCreateTalentPoolArgs = {
  input: TalentPoolCreateInput;
};


export type MutationCreateTalentPoolParticipantsArgs = {
  input: TalentPoolParticipantsCreateInput;
};


export type MutationCreateTalentPoolSharingArgs = {
  input: TalentPoolSharingCreateInput;
};


export type MutationCreateTaskArgs = {
  input: ProcessTaskCreateInput;
  language?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateTranslationProposalArgs = {
  input: TranslationProposalInput;
};


export type MutationCreateUserArgs = {
  creatorOrgId: Scalars['String']['input'];
  input: UserInput;
};


export type MutationCreateUserFeedbackArgs = {
  input: UserFeedbackCreateInput;
};


export type MutationCreateUserGroupArgs = {
  input: UserGroupInput;
};


export type MutationDeleteAuditDefinitionArgs = {
  input: AuditDefinitionDeleteInput;
};


export type MutationDeleteBlobArgs = {
  input: BlobDeleteInput;
};


export type MutationDeleteCandidateArgs = {
  input: CandidateDeleteInput;
};


export type MutationDeleteCandidateNoteArgs = {
  input: CandidateNoteDeleteInput;
};


export type MutationDeleteCollectionArgs = {
  input: CollectionDeleteInput;
};


export type MutationDeleteCollectionAutoFilterTemplateArgs = {
  input: CollectionAutoFilterTemplateDeleteInput;
};


export type MutationDeleteCollectionFilterPresetArgs = {
  input: CollectionFilterPresetDeleteInput;
};


export type MutationDeleteCommentArgs = {
  input: CommentDeleteInput;
};


export type MutationDeleteCondidateProfileConfigArgs = {
  input: CandidateProfileConfigDeleteInput;
};


export type MutationDeleteConsentArgs = {
  input: ConsentDeleteInput;
};


export type MutationDeleteContractTemplateArgs = {
  input: ContractTemplateDeleteInput;
};


export type MutationDeleteDocumentArgs = {
  input: DocumentDeleteInput;
};


export type MutationDeleteEducationCourseArgs = {
  input: EducationCourseDeleteInput;
};


export type MutationDeleteEducationExamArgs = {
  input: EducationExamDeleteInput;
};


export type MutationDeleteEducationExamParticipantArgs = {
  input: EducationExamParticipantDeleteInput;
};


export type MutationDeleteEducationExamResultArgs = {
  input: EducationExamResultDeleteInput;
};


export type MutationDeleteEducationModuleArgs = {
  input: EducationModuleDeleteInput;
};


export type MutationDeleteEmailSendOutRuleArgs = {
  input: EmailSendOutRuleInput;
};


export type MutationDeleteEmailTemplateArgs = {
  input: EmailTemplateDeleteInput;
};


export type MutationDeleteInterviewCommentArgs = {
  input: CandidateCommentDeleteInput;
};


export type MutationDeleteInterviewFeedbackArgs = {
  input: CandidateFeedbackDeleteInput;
};


export type MutationDeleteInterviewIterationArgs = {
  input: CandidateInterviewIterationDeleteInput;
};


export type MutationDeleteLaborAgreementArgs = {
  input: LaborAgreementDeleteInput;
};


export type MutationDeleteLocalPartnerProfileConfigSetArgs = {
  input: LocalPartnerProfileConfigSetDeleteInput;
};


export type MutationDeleteOrganizationContactArgs = {
  input: OrganizationContactDeleteInput;
};


export type MutationDeleteOrganizationLinkArgs = {
  input: OrganizationLinkDeleteInput;
};


export type MutationDeleteProcessArgs = {
  input: ProcessDeleteInput;
};


export type MutationDeleteProcessActionArgs = {
  input: ProcessActionDeleteInput;
};


export type MutationDeleteProcessModelArgs = {
  input: ProcessModelDeleteInput;
};


export type MutationDeleteProcessModelReleaseArgs = {
  input: ProcessModelDeleteReleaseInput;
};


export type MutationDeleteProfessionalExperienceArgs = {
  input: ProfessionalExperienceDeleteInput;
};


export type MutationDeleteProfessionalFieldArgs = {
  input: ProfessionalFieldDeleteInput;
};


export type MutationDeleteRequiredDocumentArgs = {
  input: RequiredDocumentDeleteInput;
};


export type MutationDeleteSharingArgs = {
  input: SharingDeleteInput;
};


export type MutationDeleteSharingPresetArgs = {
  input: SharingPresetDeleteInput;
};


export type MutationDeleteSharingTemplateArgs = {
  input: SharingTemplateDeleteInput;
};


export type MutationDeleteTalentPoolArgs = {
  input: TalentPoolDeleteInput;
};


export type MutationDeleteTalentPoolParticipantArgs = {
  input: TalentPoolParticipantDeleteInput;
};


export type MutationDeleteTalentPoolSharingArgs = {
  input: TalentPoolSharingDeleteInput;
};


export type MutationDeleteTaskArgs = {
  input: ProcessTaskDeleteInput;
};


export type MutationDetachAllCollectionsFromTemplateArgs = {
  input: CollectionAutoFilterTemplateDetachAllInput;
};


export type MutationDetachCollectionFromTemplateArgs = {
  input: CollectionAutoFilterTemplateDetachInput;
};


export type MutationDetachProcessFromActionArgs = {
  input: DetachProcessFromActionInput;
};


export type MutationDuplicateEmailTemplateArgs = {
  input: EmailTemplateDuplicateInput;
};


export type MutationDuplicateProcessModelArgs = {
  input: ProcessModelDuplicateInput;
};


export type MutationDuplicateRequiredDocumentArgs = {
  input: RequiredDocumentDuplicateInput;
};


export type MutationExecuteCandidateMultiEditArgs = {
  input: CandidateMultiEditExecuteInput;
};


export type MutationExecuteCandidateMultiInviteArgs = {
  input: CandidateMultiInviteExecuteInput;
};


export type MutationExecuteCandidateMultiUninviteArgs = {
  input: CandidateMultiInviteExecuteInput;
};


export type MutationGenerateAllDocumentTemplatesForCandidateArgs = {
  input: DocumentTemplateCandidateInput;
};


export type MutationGenerateAllDocumentTemplatesForCollectionArgs = {
  input: DocumentTemplateCollectionInput;
};


export type MutationGenerateDocumentTemplateArgs = {
  input: DocumentTemplateGenerateInput;
};


export type MutationGenerateReportTemplateArgs = {
  input: GenerateTemplateReportInput;
};


export type MutationGetAndGenerateNonEditedTemplateArgs = {
  input: DocumentTemplateGenerateInput;
};


export type MutationGiveConsentArgs = {
  auth0UserId: Scalars['String']['input'];
  browserLanguage?: InputMaybe<Scalars['String']['input']>;
  isCandidate: Scalars['Boolean']['input'];
  organizationId: Scalars['String']['input'];
  userAgent: Scalars['String']['input'];
  userOrCandidateId: Scalars['String']['input'];
  version: Scalars['String']['input'];
};


export type MutationImportFilledXlsxTemplateForCandidatesArgs = {
  input: XlsxCandidateImportInput;
};


export type MutationImportFilledXlsxTemplateForContractTemplatesArgs = {
  input: XlsxContractTemplateImportInput;
};


export type MutationImportFilledXlsxTemplateForOrganizationCrmDataArgs = {
  input: XlsxOrganizationCrmDataImportInput;
};


export type MutationImportFilledXlsxTemplateForOrganizationContactSettingsArgs = {
  input: XlsxOrganizationContactSettingsImportInput;
};


export type MutationImportFilledXlsxTemplateForOrganizationContactsArgs = {
  input: XlsxOrganizationContactImportInput;
};


export type MutationImportFilledXlsxTemplateForOrganizationUserGroupsArgs = {
  input: XlsxOrganizationUserGroupsImportInput;
};


export type MutationImportFilledXlsxTemplateForOrganizationUserMembershipsArgs = {
  input: XlsxOrganizationUserMembershipsImportInput;
};


export type MutationImportFilledXlsxTemplateForOrganizationUsersArgs = {
  input: XlsxOrganizationUsersImportInput;
};


export type MutationImportFilledXlsxTemplateForOrganizationsArgs = {
  input: XlsxOrganizationImportInput;
};


export type MutationImportXlsxProcessTranslationsArgs = {
  input: XlsxProcessTranslationImportInput;
  language: Scalars['String']['input'];
};


export type MutationInviteCandidateArgs = {
  candidateId: Scalars['String']['input'];
  invitedOrganizationid: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
};


export type MutationLinkAccountAfterLoginArgs = {
  input: LinkAccountInputAfterLogin;
};


export type MutationLinkAccountBeforeLoginArgs = {
  input: LinkAccountInputBeforeLogin;
};


export type MutationLinkGuetegemeinschaftOrganizationArgs = {
  input: GuetegemeinschaftOrganizationLinkInput;
};


export type MutationMakeCollectionFilterPresetPrivateArgs = {
  input: CollectionFilterPresetChangeStateInput;
};


export type MutationMakeCollectionFilterPresetPublicArgs = {
  input: CollectionFilterPresetChangeStateInput;
};


export type MutationMakeTenantToDemoTenantArgs = {
  organizationId: Scalars['ID']['input'];
};


export type MutationMarkDocumentTemplateAsEditedArgs = {
  input: DocumentTemplateEditedInput;
};


export type MutationMarkTalentArgs = {
  input: TalentInstanceInput;
};


export type MutationPerformTaskArgs = {
  input: ProcessStateUpdateInput;
};


export type MutationPrefillEducationCourseWeekArgs = {
  input: EducationCourseWeekPrefillInput;
};


export type MutationProfilePictureUpdatedArgs = {
  input: DocumentThumbnailInput;
};


export type MutationPublishProcessModelArgs = {
  input: ProcessModelPublishInput;
};


export type MutationReInviteUserArgs = {
  orgId: Scalars['String']['input'];
  orgIdofInviter: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type MutationRemindTaskArgs = {
  orgId: Scalars['String']['input'];
  targetRolesToUse?: InputMaybe<Array<Scalars['String']['input']>>;
  taskId: Scalars['String']['input'];
};


export type MutationRemoveAssignedCollectionArgs = {
  input: AssignCollectionInput;
};


export type MutationRemoveCandidateDeletionRequestArgs = {
  input: CandidateRequestDeleteInput;
};


export type MutationRemoveCandidateFromCollectionArgs = {
  input: CandidateAndCollectionIdInput;
};


export type MutationRemoveDocumentTypeOverwriteArgs = {
  input: DocumentTypeOverwriteDeleteInput;
};


export type MutationRemoveForeignGroupsFromUserGroupArgs = {
  input: RemoveForeignGroupsFromGroupInput;
};


export type MutationRemoveLicenseArgs = {
  organizationId: Scalars['ID']['input'];
};


export type MutationRemoveOrganizationArgs = {
  input: OrganizationDeleteInput;
};


export type MutationRemoveUserArgs = {
  input: DeleteUserInput;
};


export type MutationRemoveUserGroupArgs = {
  etag: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};


export type MutationRemoveUsersFromGroupArgs = {
  input: RemoveUsersFromGroupInput;
};


export type MutationRequestTalentsArgs = {
  input: PublicTalentPoolTalentRequestInput;
};


export type MutationRequestTalentsRestrictedArgs = {
  input: RestrictedTalentPoolTalentRequestInput;
};


export type MutationRequireConsentOrganizationArgs = {
  input: OrganizationRequireConsentInput;
};


export type MutationResetCandidatePasswordArgs = {
  candidateId: Scalars['String']['input'];
  invitedOrganizationid: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
};


export type MutationSendMessageArgs = {
  message: Scalars['String']['input'];
};


export type MutationSendProcessEmailArgs = {
  input: EmailSendInput;
};


export type MutationSendProcessEmailsArgs = {
  input: EmailListSendInput;
};


export type MutationSetCollectionAutoFilterArgs = {
  input: CollectionAutoFilterSetInput;
};


export type MutationSetCollectionAutoFilterTemplateArgs = {
  input: CollectionAutoFilterTemplateSetInput;
};


export type MutationSetCommunicationLanguageArgs = {
  communicationLanguage: Scalars['String']['input'];
};


export type MutationSetContractTemplateArgs = {
  input: ContractTemplateSetInput;
};


export type MutationSetFavoriteCandidateArgs = {
  input: FavoriteCandidateInput;
};


export type MutationSetFavoriteCollectionArgs = {
  input: FavoriteCollectionInput;
};


export type MutationSetMatchingEnabledArgs = {
  input: EnableMatchingInput;
};


export type MutationSetNotificationSettingsArgs = {
  input: NotificationSettingsInput;
};


export type MutationSetOrganizationContactSettingsArgs = {
  input: OrganizationContactSettingsSetInput;
};


export type MutationSetOrganizationCrmArgs = {
  input: OrganizationCrmSetInput;
};


export type MutationSetOrganizationProfileArgs = {
  input: OrganizationProfileSetInput;
};


export type MutationSetPdfFormTemplateArgs = {
  input: PdfFormTemplateSetInput;
};


export type MutationSetPdfFormTemplateSettingsArgs = {
  input: PdfFormTemplateSettingsSetInput;
};


export type MutationSetProfessionConfigurationArgs = {
  input: ProfessionConfigurationSetInput;
};


export type MutationSubmitSharingArgs = {
  input: SharingSubmitInput;
};


export type MutationSwitchUserOrganizationArgs = {
  organizationId: Scalars['ID']['input'];
};


export type MutationSyncAllCollectionsWithTemplateArgs = {
  input: AllCollectionsAutoFilterTemplateSyncInput;
};


export type MutationSyncAllProcessesArgs = {
  input: ProcessSyncAllInput;
  syncOptions?: InputMaybe<ProcessSyncInputOptions>;
};


export type MutationSyncCollectionWithTemplateArgs = {
  input: CollectionAutoFilterTemplateSyncInput;
};


export type MutationSyncProcessArgs = {
  input: ProcessSyncInput;
  syncOptions?: InputMaybe<ProcessSyncInputOptions>;
};


export type MutationToggleMessagesReceptionArgs = {
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  isCandidate: Scalars['Boolean']['input'];
  orgId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationToggleProcessStateArgs = {
  input: ProcessActivateInput;
};


export type MutationTransferCollectionArgs = {
  input: CollectionTransferInput;
};


export type MutationUnBlockUserArgs = {
  input: BlockUserInput;
};


export type MutationUnInviteCandidateArgs = {
  candidateId: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
};


export type MutationUnLinkFacebookAccountArgs = {
  ssoId: Scalars['String']['input'];
};


export type MutationUndoLastCommandArgs = {
  input: ProcessModelUndoCommand;
};


export type MutationUnmarkTalentArgs = {
  input: TalentInstanceInput;
};


export type MutationUnpublishProcessModelArgs = {
  input: ProcessModelUnpublishInput;
};


export type MutationUnsetFavoriteCandidateArgs = {
  input: FavoriteCandidateInput;
};


export type MutationUnsetFavoriteCollectionArgs = {
  input: FavoriteCollectionInput;
};


export type MutationUnsubmitSharingArgs = {
  input: SharingSubmitInput;
};


export type MutationUpdateAdHocEducationExamArgs = {
  input: AdHocEducationExamUpdateInput;
};


export type MutationUpdateAssignedCandidatesArgs = {
  input: UpdateAssignedCandidatesInput;
};


export type MutationUpdateAssignmentExceptionsArgs = {
  input: UpdateAssignmentExceptionsInput;
};


export type MutationUpdateAuditDefinitionArgs = {
  input: AuditDefinitionUpdateInput;
};


export type MutationUpdateAuditDefinitionMetaArgs = {
  input: AuditDefinitionUpdateMetaInput;
};


export type MutationUpdateCandidateArgs = {
  input: CandidateUpdateInput;
  sharing: CandidateUpdateInputSharing;
};


export type MutationUpdateCandidateNoteArgs = {
  input: CandidateNoteUpdateInput;
};


export type MutationUpdateCandidateProfileArgs = {
  input: CandidateProfileUpdateInput;
};


export type MutationUpdateCandidateProfileConfigArgs = {
  input: CandidateProfileConfigUpdateInput;
};


export type MutationUpdateCollectionArgs = {
  input: CollectionUpdateInput;
};


export type MutationUpdateCollectionFilterPresetArgs = {
  input: CollectionFilterPresetUpdateInput;
};


export type MutationUpdateCommentArgs = {
  input: CommentUpdateInput;
};


export type MutationUpdateConsentArgs = {
  input: ConsentUpdateInput;
};


export type MutationUpdateDocumentArgs = {
  input: DocumentUpdateInput;
};


export type MutationUpdateEducationCourseArgs = {
  input: EducationCourseUpdateInput;
};


export type MutationUpdateEducationCourseTotalPerformanceStateArgs = {
  input: EducationCourseTotalPerformanceStateUpdateInput;
};


export type MutationUpdateEducationCourseWeeklyAttendanceArgs = {
  input: EducationCourseWeeklyAttendanceUpdateInput;
};


export type MutationUpdateEducationCourseWeeklyCommentArgs = {
  input: EducationCourseWeeklyCommentUpdateInput;
};


export type MutationUpdateEducationCourseWeeklyPerformanceArgs = {
  input: EducationCourseWeeklyPerformanceUpdateInput;
};


export type MutationUpdateEducationExamArgs = {
  input: EducationExamUpdateInput;
};


export type MutationUpdateEducationModuleArgs = {
  input: EducationModuleUpdateInput;
};


export type MutationUpdateEmailSendOutRuleArgs = {
  input: EmailSendOutRuleUpdateInput;
};


export type MutationUpdateEmailTemplateArgs = {
  input: EmailTemplateUpdateInput;
  language: Scalars['String']['input'];
};


export type MutationUpdateFeedbackArgs = {
  input: FeedbackUpdateInput;
};


export type MutationUpdateLaborAgreementArgs = {
  input: LaborAgreementUpdateInput;
};


export type MutationUpdateLocalPartnerProfileConfigSetArgs = {
  input: LocalPartnerProfileConfigSetUpdateInput;
};


export type MutationUpdateOrganizationArgs = {
  input: OrganizationInput;
};


export type MutationUpdateOrganizationContactArgs = {
  input: OrganizationContactUpdateInput;
};


export type MutationUpdateOrganizationContractSettingsArgs = {
  input: OrganizationContractSettingsInput;
};


export type MutationUpdateOrganizationLinkArgs = {
  input: OrganizationLinkUpdateInput;
};


export type MutationUpdateProcessArgs = {
  input: ProcessUpdateInput;
};


export type MutationUpdateProcessActionArgs = {
  input: ProcessActionUpdateInput;
};


export type MutationUpdateProcessModelArgs = {
  input: ProcessModelUpdateInput;
};


export type MutationUpdateProcessModelReleaseArgs = {
  input: ProcessModelUpdateReleaseInput;
};


export type MutationUpdateProfessionalExperienceArgs = {
  input: ProfessionalExperienceUpdateInput;
};


export type MutationUpdateProfessionalFieldArgs = {
  input: ProfessionalFieldUpdateInput;
};


export type MutationUpdateRequiredDocumentArgs = {
  input: RequiredDocumentUpdateInput;
};


export type MutationUpdateRequiredDocumentMetaArgs = {
  input: RequiredDocumentUpdateMetaInput;
};


export type MutationUpdateRequiredVaccinationsArgs = {
  input: RequiredVaccinationsUpdateInput;
};


export type MutationUpdateSharingArgs = {
  input: SharingUpdateInput;
};


export type MutationUpdateSharingPresetArgs = {
  input: SharingPresetUpdateInput;
};


export type MutationUpdateSharingTemplateArgs = {
  input: SharingTemplateUpdateInput;
};


export type MutationUpdateSystemSettingArgs = {
  input: SystemSettingsUpdateInput;
};


export type MutationUpdateTalentPoolArgs = {
  input: TalentPoolUpdateInput;
};


export type MutationUpdateTalentPoolParticipantArgs = {
  input: TalentPoolParticipantUpdateInput;
};


export type MutationUpdateTalentPoolSharingArgs = {
  input: TalentPoolSharingUpdateInput;
};


export type MutationUpdateTaskArgs = {
  input: ProcessTaskUpdateInput;
  language?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateUserArgs = {
  input: UserInput;
};


export type MutationUpdateUserGroupArgs = {
  input: UserGroupInput;
};


export type MutationUpsertCandidateFieldTranslationsArgs = {
  input: PropertyTranslationUpdateInput;
};


export type MutationUpsertDocumentTypeOverwriteArgs = {
  input: DocumentTypeOverwriteUpsertInput;
};


export type MutationUpsertDocumentWithoutProcessStateChangeArgs = {
  input: DocumentUpsertInput;
};


export type MutationUpsertEducationExamResultArgs = {
  input: EducationExamResultUpsertInput;
};


export type MutationUpsertGeneralDocumentRequirementsArgs = {
  input: GeneralDocumentRequirementInput;
};


export type MutationUpsertInterviewCommentArgs = {
  input: CandidateCommentUpsertInput;
};


export type MutationUpsertInterviewEmployerArgs = {
  input: CandidateEmployerUpsertInput;
};


export type MutationUpsertInterviewFeedbackArgs = {
  input: CandidateFeedbackUpsertInput;
};


export type MutationUpsertManyDocumentRequirementsArgs = {
  input: DocumentRequirementsUpsertInput;
};


export type MutationUpsertProfessionalFieldSettingsArgs = {
  input: ProfessionalFieldSettingsUpsertInput;
};

export class MutationResult {
  __typename?: 'MutationResult';
  _etag?: Maybe<Scalars['String']['output']>;
  status: Scalars['Boolean']['output'];
};

export class NotificationList {
  __typename?: 'NotificationList';
  notifications: Array<Activity>;
  since: Scalars['DateTime']['output'];
};

export enum NotificationScope {
  Candidates = 'Candidates',
  OwnedCandidates = 'OwnedCandidates',
  Processes = 'Processes'
}

export class NotificationSettings {
  __typename?: 'NotificationSettings';
  _etag?: Maybe<Scalars['String']['output']>;
  activities?: Maybe<Array<ActivityType>>;
  documents?: Maybe<Array<Scalars['String']['output']>>;
  enabled?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  organizationId: Scalars['ID']['output'];
  scopes?: Maybe<Array<NotificationScope>>;
  userId: Scalars['ID']['output'];
};

export class NotificationSettingsInput {
  activities?: InputMaybe<Array<ActivityType>>;
  documents?: InputMaybe<Array<Scalars['String']['input']>>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  scopes?: InputMaybe<Array<NotificationScope>>;
};

export class OccupationSkill {
  __typename?: 'OccupationSkill';
  description: Scalars['String']['output'];
  preferredLabel: Scalars['String']['output'];
  skillId: Scalars['String']['output'];
};

export class OccupationSkillByIdsInput {
  language: Scalars['String']['input'];
  skillIds: Array<Scalars['String']['input']>;
  skillType: OccupationSkillType;
};

export class OccupationSkillSearchInput {
  language: Scalars['String']['input'];
  query: Scalars['String']['input'];
  skillType: OccupationSkillType;
};

export class OccupationSkillSet {
  __typename?: 'OccupationSkillSet';
  description: Scalars['String']['output'];
  preferredLabel: Scalars['String']['output'];
  skillType: OccupationSkillType;
  skills?: Maybe<Array<OccupationSkill>>;
};

export enum OccupationSkillType {
  Knowledge = 'Knowledge',
  SkillCompetence = 'SkillCompetence'
}

export class Organization {
  __typename?: 'Organization';
  EZBData?: Maybe<EzbInfo>;
  _etag?: Maybe<Scalars['String']['output']>;
  address?: Maybe<Scalars['String']['output']>;
  allowOrganizationManagementbyCreator?: Maybe<Scalars['Boolean']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  code: Scalars['String']['output'];
  companyRegistrationAuthority?: Maybe<Scalars['String']['output']>;
  configToken?: Maybe<Scalars['String']['output']>;
  cooperationType?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  creationDate?: Maybe<Scalars['DateTime']['output']>;
  creatorOrganizationId?: Maybe<Scalars['ID']['output']>;
  creatorOrganizationName?: Maybe<Scalars['String']['output']>;
  emailAdress?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isCreatorMasterOrganization?: Maybe<Scalars['Boolean']['output']>;
  isLicensed?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  notifyUsersOnDeletionRequest?: Maybe<Array<Scalars['String']['output']>>;
  numberOfEmployers?: Maybe<Scalars['Float']['output']>;
  operatingNumber?: Maybe<Scalars['String']['output']>;
  organizationId: Scalars['ID']['output'];
  parentOrganizationId?: Maybe<Scalars['ID']['output']>;
  parentOrganizationName?: Maybe<Scalars['String']['output']>;
  prefix: Scalars['String']['output'];
  recognitionProfessionalExperience?: Maybe<RecognitionProfessionalExperience>;
  region?: Maybe<Scalars['String']['output']>;
  registerNo?: Maybe<Scalars['String']['output']>;
  requireCandidateConsent?: Maybe<Scalars['Boolean']['output']>;
  requireUserConsent?: Maybe<Scalars['Boolean']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  urlToWebsite?: Maybe<Scalars['String']['output']>;
  yearOfEstablishment?: Maybe<Scalars['String']['output']>;
  zipcode?: Maybe<Scalars['String']['output']>;
};

export enum OrganizationAuditAdditionRequestResult {
  Approved = 'Approved',
  Rejected = 'Rejected'
}

export class OrganizationAuditData {
  __typename?: 'OrganizationAuditData';
  additionalRequestFiles?: Maybe<Array<AuditFile>>;
  additionalRequestReply?: Maybe<Scalars['String']['output']>;
  additionalRequestResult?: Maybe<OrganizationAuditAdditionRequestResult>;
  additionalRequestResultDescription?: Maybe<Scalars['String']['output']>;
  auditResult?: Maybe<OrganizationAuditResult>;
  auditResultDescription?: Maybe<Scalars['String']['output']>;
  auditTreeNodeId: Scalars['ID']['output'];
  description?: Maybe<Scalars['String']['output']>;
  files?: Maybe<Array<AuditFile>>;
};

export class OrganizationAuditDataInput {
  additionalRequestFiles?: InputMaybe<Array<AuditFileInput>>;
  additionalRequestReply?: InputMaybe<Scalars['String']['input']>;
  additionalRequestResult?: InputMaybe<OrganizationAuditAdditionRequestResult>;
  additionalRequestResultDescription?: InputMaybe<Scalars['String']['input']>;
  auditResult?: InputMaybe<OrganizationAuditResult>;
  auditResultDescription?: InputMaybe<Scalars['String']['input']>;
  auditTreeNodeId: Scalars['ID']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  files?: InputMaybe<Array<AuditFileInput>>;
};

export class OrganizationAuditInterview {
  __typename?: 'OrganizationAuditInterview';
  interviewAssessment?: Maybe<OrganizationAuditInterviewAssessmentEnum>;
  interviewDate: Scalars['DateTime']['output'];
  nameOfInterviewee: Scalars['String']['output'];
  qualitativeInterviewAssessment?: Maybe<Scalars['String']['output']>;
  questionsAndAnswers?: Maybe<Array<OrganizationAuditInterviewQuestionAndAnswer>>;
};

export enum OrganizationAuditInterviewAssessmentEnum {
  ConfirmsTheInformation = 'ConfirmsTheInformation',
  PartiallyConfirmsTheInformation = 'PartiallyConfirmsTheInformation',
  ThereAreContradictionsToTheInformation = 'ThereAreContradictionsToTheInformation'
}

export class OrganizationAuditInterviewInput {
  interviewAssessment?: InputMaybe<OrganizationAuditInterviewAssessmentEnum>;
  interviewDate: Scalars['DateTime']['input'];
  nameOfInterviewee: Scalars['String']['input'];
  qualitativeInterviewAssessment?: InputMaybe<Scalars['String']['input']>;
  questionsAndAnswers?: InputMaybe<Array<OrganizationAuditInterviewQuestionAndAnswerInput>>;
};

export class OrganizationAuditInterviewQuestionAndAnswer {
  __typename?: 'OrganizationAuditInterviewQuestionAndAnswer';
  answer?: Maybe<Scalars['String']['output']>;
  question?: Maybe<Scalars['String']['output']>;
};

export class OrganizationAuditInterviewQuestionAndAnswerInput {
  answer?: InputMaybe<Scalars['String']['input']>;
  question?: InputMaybe<Scalars['String']['input']>;
};

export class OrganizationAuditInterviewSamples {
  __typename?: 'OrganizationAuditInterviewSamples';
  interviews?: Maybe<Array<OrganizationAuditInterview>>;
  qualitativeSampleAssessment?: Maybe<Scalars['String']['output']>;
  sampleAssessment?: Maybe<OrganizationAuditInterviewAssessmentEnum>;
};

export class OrganizationAuditInterviewSamplesInput {
  interviews?: InputMaybe<Array<OrganizationAuditInterviewInput>>;
  qualitativeSampleAssessment?: InputMaybe<Scalars['String']['input']>;
  sampleAssessment?: InputMaybe<OrganizationAuditInterviewAssessmentEnum>;
};

export class OrganizationAuditReportInput {
  auditDefinitionId: Scalars['String']['input'];
  auditMode: AuditTaskModeEnum;
  auditRemarksAdditionalClaim?: InputMaybe<Scalars['String']['input']>;
  auditRemarksAuditor?: InputMaybe<Scalars['String']['input']>;
  auditRemarksBoard?: InputMaybe<Scalars['String']['input']>;
  auditVotumBoard?: InputMaybe<OrganizationAuditAdditionRequestResult>;
  awardDate?: InputMaybe<Scalars['DateTime']['input']>;
  expiryDate?: InputMaybe<Scalars['DateTime']['input']>;
  finalFinalVotum?: InputMaybe<OrganizationAuditAdditionRequestResult>;
  finalVotum?: InputMaybe<OrganizationAuditResult>;
  finalVotumDescription?: InputMaybe<Scalars['String']['input']>;
  interviewNotes?: InputMaybe<Scalars['String']['input']>;
  interviewSamples?: InputMaybe<OrganizationAuditInterviewSamplesInput>;
  organizationAuditData?: InputMaybe<Array<OrganizationAuditDataInput>>;
  organizationId: Scalars['ID']['input'];
  processAuditGroupName: Scalars['String']['input'];
  recruitingConcept?: InputMaybe<Scalars['JSON']['input']>;
  reportName: Scalars['String']['input'];
  rootId: Scalars['ID']['input'];
};

export enum OrganizationAuditResult {
  Approved = 'Approved',
  ApprovedWhenDemandIsMet = 'ApprovedWhenDemandIsMet',
  Rejected = 'Rejected'
}

export class OrganizationContact {
  __typename?: 'OrganizationContact';
  _etag?: Maybe<Scalars['String']['output']>;
  address?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  function?: Maybe<OrganizationContactFunction>;
  id?: Maybe<Scalars['ID']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  mobilePhone?: Maybe<Scalars['String']['output']>;
  organizationId: Scalars['ID']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  salutation?: Maybe<Scalars['String']['output']>;
  signatureAddition?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export class OrganizationContactCreateInput {
  address?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  function?: InputMaybe<OrganizationContactFunction>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  mobilePhone?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['ID']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  salutation?: InputMaybe<Scalars['String']['input']>;
  signatureAddition?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export class OrganizationContactDeleteInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
};

export enum OrganizationContactFunction {
  Contact = 'Contact',
  Representative = 'Representative'
}

export class OrganizationContactGetInput {
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
};

export class OrganizationContactListInput {
  organizationId: Scalars['ID']['input'];
};

export class OrganizationContactSettings {
  __typename?: 'OrganizationContactSettings';
  _etag?: Maybe<Scalars['String']['output']>;
  defaultFirstDeputy?: Maybe<Scalars['ID']['output']>;
  defaultRecognitionContactPerson?: Maybe<Scalars['ID']['output']>;
  defaultRepresentative?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  organizationId: Scalars['ID']['output'];
};

export class OrganizationContactSettingsGetInput {
  organizationId: Scalars['ID']['input'];
};

export class OrganizationContactSettingsSetInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  defaultFirstDeputy?: InputMaybe<Scalars['ID']['input']>;
  defaultRecognitionContactPerson?: InputMaybe<Scalars['ID']['input']>;
  defaultRepresentative?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
};

export class OrganizationContactUpdateInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  address?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  function?: InputMaybe<OrganizationContactFunction>;
  id?: InputMaybe<Scalars['ID']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  mobilePhone?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['ID']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  salutation?: InputMaybe<Scalars['String']['input']>;
  signatureAddition?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export class OrganizationContractSettings {
  __typename?: 'OrganizationContractSettings';
  id?: Maybe<Scalars['ID']['output']>;
  recognitionProfessionalExperience?: Maybe<RecognitionProfessionalExperience>;
};

export class OrganizationContractSettingsInput {
  id?: InputMaybe<Scalars['ID']['input']>;
  recognitionProfessionalExperience?: InputMaybe<RecognitionProfessionalExperience>;
};

export class OrganizationCrm {
  __typename?: 'OrganizationCrm';
  _etag?: Maybe<Scalars['String']['output']>;
  clientNumber?: Maybe<Scalars['String']['output']>;
  debitorNumber?: Maybe<Scalars['String']['output']>;
  destinationOrganizationId: Scalars['ID']['output'];
  id?: Maybe<Scalars['ID']['output']>;
  note?: Maybe<OrganizationCrmNote>;
  organizationId: Scalars['ID']['output'];
};

export class OrganizationCrmGetInput {
  destinationOrganizationId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};

export class OrganizationCrmNote {
  __typename?: 'OrganizationCrmNote';
  contents: Scalars['String']['output'];
  delta: Scalars['JSONObject']['output'];
};

export class OrganizationCrmNoteInput {
  contents: Scalars['String']['input'];
  delta: Scalars['JSONObject']['input'];
};

export class OrganizationCrmSetInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  clientNumber?: InputMaybe<Scalars['String']['input']>;
  debitorNumber?: InputMaybe<Scalars['String']['input']>;
  destinationOrganizationId: Scalars['ID']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  note?: InputMaybe<OrganizationCrmNoteInput>;
  organizationId: Scalars['ID']['input'];
};

export class OrganizationDeleteInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

export class OrganizationDocumentModel {
  __typename?: 'OrganizationDocumentModel';
  documentType: Scalars['String']['output'];
  organizationRole?: Maybe<Scalars['String']['output']>;
};

export class OrganizationDocumentModelInput {
  documentType: Scalars['String']['input'];
  organizationRole?: InputMaybe<Scalars['String']['input']>;
};

export class OrganizationEventsInput {
  documentTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  eventCategories: Array<Scalars['String']['input']>;
  eventTimeframe: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
};

export class OrganizationFindInput {
  id?: InputMaybe<Scalars['ID']['input']>;
  includeMyself?: InputMaybe<Scalars['Boolean']['input']>;
};

export class OrganizationForAssignmentInput {
  collectionId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};

export class OrganizationInput {
  EZBData?: InputMaybe<EzbInfoInput>;
  _etag?: InputMaybe<Scalars['String']['input']>;
  address?: InputMaybe<Scalars['String']['input']>;
  allowOrganizationManagementbyCreator?: InputMaybe<Scalars['Boolean']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  code: Scalars['String']['input'];
  companyRegistrationAuthority?: InputMaybe<Scalars['String']['input']>;
  configToken?: InputMaybe<Scalars['String']['input']>;
  cooperationType?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  creationDate?: InputMaybe<Scalars['DateTime']['input']>;
  creatorOrganizationId?: InputMaybe<Scalars['ID']['input']>;
  emailAdress?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  notifyUsersOnDeletionRequest?: InputMaybe<Array<Scalars['String']['input']>>;
  numberOfEmployers?: InputMaybe<Scalars['Float']['input']>;
  operatingNumber?: InputMaybe<Scalars['String']['input']>;
  parentOrganizationId?: InputMaybe<Scalars['ID']['input']>;
  prefix: Scalars['String']['input'];
  recognitionProfessionalExperience?: InputMaybe<RecognitionProfessionalExperience>;
  region?: InputMaybe<Scalars['String']['input']>;
  registerNo?: InputMaybe<Scalars['String']['input']>;
  requireCandidateConsent?: InputMaybe<Scalars['Boolean']['input']>;
  requireUserConsent?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  urlToWebsite?: InputMaybe<Scalars['String']['input']>;
  yearOfEstablishment?: InputMaybe<Scalars['String']['input']>;
  zipcode?: InputMaybe<Scalars['String']['input']>;
};

export class OrganizationLink {
  __typename?: 'OrganizationLink';
  _etag?: Maybe<Scalars['String']['output']>;
  creationDate?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  linkedOrganizationId: Scalars['ID']['output'];
  organizationCode?: Maybe<Scalars['String']['output']>;
  organizationId: Scalars['ID']['output'];
  organizationName?: Maybe<Scalars['String']['output']>;
  organizationType?: Maybe<Scalars['String']['output']>;
  partnerSharing?: Maybe<PartnerSharingConfiguration>;
  selectedContacts?: Maybe<Array<Scalars['ID']['output']>>;
  selectedContractTemplates?: Maybe<Array<Scalars['ID']['output']>>;
  selectedPartners?: Maybe<Array<SelectedPartnerSharingConfiguration>>;
  shareContacts?: Maybe<ContactSharingMode>;
  shareContractTemplates?: Maybe<ContractTemplateSharingMode>;
  shareEZBData?: Maybe<EzbDataSharingMode>;
  sharePartners?: Maybe<PartnerSharingMode>;
};

export class OrganizationLinkCreateInput {
  creationDate?: InputMaybe<Scalars['DateTime']['input']>;
  linkedOrganizationId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
  partnerSharing?: InputMaybe<PartnerSharingConfigurationInput>;
  selectedContacts?: InputMaybe<Array<Scalars['ID']['input']>>;
  selectedContractTemplates?: InputMaybe<Array<Scalars['ID']['input']>>;
  selectedPartners?: InputMaybe<Array<SelectedPartnerSharingConfigurationInput>>;
  shareContacts?: InputMaybe<ContactSharingMode>;
  shareContractTemplates?: InputMaybe<ContractTemplateSharingMode>;
  shareEZBData?: InputMaybe<EzbDataSharingMode>;
  sharePartners?: InputMaybe<PartnerSharingMode>;
};

export class OrganizationLinkDeleteInput {
  linkedOrganizationId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};

export class OrganizationLinkGetInput {
  linkedOrganizationId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};

export class OrganizationLinkUpdateInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  creationDate?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  linkedOrganizationId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
  partnerSharing?: InputMaybe<PartnerSharingConfigurationInput>;
  selectedContacts?: InputMaybe<Array<Scalars['ID']['input']>>;
  selectedContractTemplates?: InputMaybe<Array<Scalars['ID']['input']>>;
  selectedPartners?: InputMaybe<Array<SelectedPartnerSharingConfigurationInput>>;
  shareContacts?: InputMaybe<ContactSharingMode>;
  shareContractTemplates?: InputMaybe<ContractTemplateSharingMode>;
  shareEZBData?: InputMaybe<EzbDataSharingMode>;
  sharePartners?: InputMaybe<PartnerSharingMode>;
};

export class OrganizationLinkedInput {
  organizationId: Scalars['ID']['input'];
};

export class OrganizationListDto {
  __typename?: 'OrganizationListDTO';
  organizations?: Maybe<Array<OrganizationListEntry>>;
};

export class OrganizationListEntry {
  __typename?: 'OrganizationListEntry';
  isLicensed: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  organizationId: Scalars['String']['output'];
};

export class OrganizationListInput {
  immigrationCountry?: InputMaybe<SupportedImmigrationCountry>;
  sourceOrganizationId: Scalars['ID']['input'];
  targetOrganizationId: Scalars['ID']['input'];
};

export class OrganizationProfile {
  __typename?: 'OrganizationProfile';
  _etag?: Maybe<Scalars['String']['output']>;
  contactUserGroups?: Maybe<Array<Scalars['String']['output']>>;
  description: Array<OrganizationProfileDescription>;
  id?: Maybe<Scalars['ID']['output']>;
  images?: Maybe<Array<Scalars['String']['output']>>;
  isMatchingEnabled: Scalars['Boolean']['output'];
  locations: Array<OrganizationProfileLocation>;
  organizationId: Scalars['ID']['output'];
};

export class OrganizationProfileDescription {
  __typename?: 'OrganizationProfileDescription';
  contents: Scalars['String']['output'];
  delta: Scalars['JSONObject']['output'];
  language: Scalars['String']['output'];
};

export class OrganizationProfileDescriptionInput {
  contents: Scalars['String']['input'];
  delta: Scalars['JSONObject']['input'];
  language: Scalars['String']['input'];
};

export class OrganizationProfileFields {
  __typename?: 'OrganizationProfileFields';
  careFacility: Scalars['Boolean']['output'];
  city: Scalars['Boolean']['output'];
  countryOfOrigin: Scalars['Boolean']['output'];
  employmentType: Scalars['Boolean']['output'];
  experienceField: Scalars['Boolean']['output'];
  function: Scalars['Boolean']['output'];
  interviewLanguageLevel: Scalars['Boolean']['output'];
  pathOfRecognition: Scalars['Boolean']['output'];
  requiredStaff: Scalars['Boolean']['output'];
  state: Scalars['Boolean']['output'];
  temporalScope: Scalars['Boolean']['output'];
  transferLanguageLevel: Scalars['Boolean']['output'];
};

export class OrganizationProfileGetInput {
  organizationId: Scalars['ID']['input'];
};

export class OrganizationProfileLocation {
  __typename?: 'OrganizationProfileLocation';
  address: Scalars['String']['output'];
  beds?: Maybe<Scalars['Float']['output']>;
  careFacility: Array<Scalars['String']['output']>;
  city: Scalars['String']['output'];
  experienceField?: Maybe<Array<Scalars['String']['output']>>;
  hasInternationalExperience: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  state: Scalars['String']['output'];
  vacancies: Array<OrganizationProfileLocationVacancy>;
  website?: Maybe<Scalars['String']['output']>;
  zipcode: Scalars['String']['output'];
};

export class OrganizationProfileLocationInput {
  address: Scalars['String']['input'];
  beds?: InputMaybe<Scalars['Float']['input']>;
  careFacility: Array<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  experienceField?: InputMaybe<Array<Scalars['String']['input']>>;
  hasInternationalExperience: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  state: Scalars['String']['input'];
  vacancies: Array<OrganizationProfileLocationVacancyInput>;
  website?: InputMaybe<Scalars['String']['input']>;
  zipcode: Scalars['String']['input'];
};

export class OrganizationProfileLocationVacancy {
  __typename?: 'OrganizationProfileLocationVacancy';
  benefits?: Maybe<Array<Scalars['String']['output']>>;
  countryOfOrigin?: Maybe<Array<Scalars['String']['output']>>;
  employmentType?: Maybe<Array<EmploymentRelationshipType>>;
  function?: Maybe<Array<Scalars['String']['output']>>;
  interviewLanguageLevel?: Maybe<Scalars['String']['output']>;
  pathOfRecognition?: Maybe<Scalars['String']['output']>;
  requiredStaff: Array<Scalars['String']['output']>;
  temporalScope?: Maybe<Array<WorkingTimeTypeEnum>>;
  transferLanguageLevel?: Maybe<Scalars['String']['output']>;
};

export class OrganizationProfileLocationVacancyInput {
  benefits?: InputMaybe<Array<Scalars['String']['input']>>;
  countryOfOrigin?: InputMaybe<Array<Scalars['String']['input']>>;
  employmentType?: InputMaybe<Array<EmploymentRelationshipType>>;
  function?: InputMaybe<Array<Scalars['String']['input']>>;
  interviewLanguageLevel?: InputMaybe<Scalars['String']['input']>;
  pathOfRecognition?: InputMaybe<Scalars['String']['input']>;
  requiredStaff: Array<Scalars['String']['input']>;
  temporalScope?: InputMaybe<Array<WorkingTimeTypeEnum>>;
  transferLanguageLevel?: InputMaybe<Scalars['String']['input']>;
};

export class OrganizationProfileSetInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  contactUserGroups?: InputMaybe<Array<Scalars['String']['input']>>;
  description: Array<OrganizationProfileDescriptionInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  images?: InputMaybe<Array<Scalars['String']['input']>>;
  isMatchingEnabled: Scalars['Boolean']['input'];
  locations: Array<OrganizationProfileLocationInput>;
  organizationId: Scalars['ID']['input'];
};

export class OrganizationRequireConsentInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  requireCandidateConsent?: InputMaybe<Scalars['Boolean']['input']>;
  requireUserConsent?: InputMaybe<Scalars['Boolean']['input']>;
};

export class OrganizationRolesInput {
  organizationId: Scalars['ID']['input'];
  roles: Array<UserRole>;
};

export class OrganizationsBySharedTabInput {
  candidateId: Scalars['ID']['input'];
  candidateOrganizationId: Scalars['ID']['input'];
  sharingTypes?: InputMaybe<Array<SharingTypeEnum>>;
  tab: Scalars['String']['input'];
};

export class OutboxRunInput {
  organizationId: Scalars['ID']['input'];
  processId: Scalars['ID']['input'];
};

export class ParticipationCondition {
  __typename?: 'ParticipationCondition';
  allowedValues: Array<Scalars['JSON']['output']>;
  path: Scalars['String']['output'];
};

export class ParticipationConditionInput {
  allowedValues: Array<Scalars['JSON']['input']>;
  path: Scalars['String']['input'];
};

export class PartnerSharingConfiguration {
  __typename?: 'PartnerSharingConfiguration';
  allowLinkingInCaseItIsManaged?: Maybe<Scalars['Boolean']['output']>;
  shareContacts?: Maybe<Scalars['Boolean']['output']>;
  shareContractTemplates?: Maybe<Scalars['Boolean']['output']>;
  shareEZBData?: Maybe<Scalars['Boolean']['output']>;
  sharePartners?: Maybe<Scalars['Boolean']['output']>;
};

export class PartnerSharingConfigurationInput {
  allowLinkingInCaseItIsManaged?: InputMaybe<Scalars['Boolean']['input']>;
  shareContacts?: InputMaybe<Scalars['Boolean']['input']>;
  shareContractTemplates?: InputMaybe<Scalars['Boolean']['input']>;
  shareEZBData?: InputMaybe<Scalars['Boolean']['input']>;
  sharePartners?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum PartnerSharingMode {
  ShareAll = 'ShareAll',
  ShareSelected = 'ShareSelected'
}

export enum PaymentPeriod {
  Quarterly = 'Quarterly',
  '1M' = '_1M',
  '3M' = '_3M',
  '6M' = '_6M',
  '12M' = '_12M'
}

export class PaymentSettings {
  __typename?: 'PaymentSettings';
  paymentTime: PaymentTime;
  paymentTranches?: Maybe<Array<PaymentTranche>>;
  period: PaymentPeriod;
  startDate: Scalars['DateTime']['output'];
};

export class PaymentSettingsInput {
  paymentTime: PaymentTime;
  paymentTranches?: InputMaybe<Array<PaymentTrancheInput>>;
  period: PaymentPeriod;
  startDate: Scalars['DateTime']['input'];
};

export enum PaymentTime {
  InAdvance = 'InAdvance',
  InArrears = 'InArrears'
}

export class PaymentTranche {
  __typename?: 'PaymentTranche';
  dueDate: DayOfYear;
  percentage: Scalars['Float']['output'];
};

export class PaymentTrancheInput {
  dueDate: DayOfYearInput;
  percentage: Scalars['Float']['input'];
};

export class PdfFormGenerationState {
  __typename?: 'PdfFormGenerationState';
  blob?: Maybe<Scalars['String']['output']>;
  container?: Maybe<Scalars['String']['output']>;
  fileName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name: Scalars['String']['output'];
  status: DocumentTemplateStatus;
  warnings?: Maybe<Array<DocumentTemplateWarning>>;
};

export class PdfFormGenerationStateInput {
  blob?: InputMaybe<Scalars['String']['input']>;
  container?: InputMaybe<Scalars['String']['input']>;
  fileName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  status: DocumentTemplateStatus;
  warnings?: InputMaybe<Array<DocumentTemplateWarningInput>>;
};

export class PdfFormTemplate {
  __typename?: 'PdfFormTemplate';
  _etag?: Maybe<Scalars['String']['output']>;
  blob?: Maybe<Scalars['String']['output']>;
  changedAt?: Maybe<Scalars['DateTime']['output']>;
  changedBy?: Maybe<Scalars['String']['output']>;
  creationDate?: Maybe<Scalars['DateTime']['output']>;
  details?: Maybe<Scalars['String']['output']>;
  displayName: Scalars['String']['output'];
  federalStateId?: Maybe<Scalars['String']['output']>;
  fields?: Maybe<Scalars['JSON']['output']>;
  fileName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isVirtual: Scalars['Boolean']['output'];
  key: Scalars['String']['output'];
  organizationId: Scalars['ID']['output'];
  pdfFormToken: KnownPdfFormTokens;
  recipient?: Maybe<Scalars['String']['output']>;
};

export class PdfFormTemplateClearInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
};

export class PdfFormTemplateGetInput {
  id?: InputMaybe<Scalars['ID']['input']>;
  isVirtual: Scalars['Boolean']['input'];
  key: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  pdfFormToken: KnownPdfFormTokens;
};

export class PdfFormTemplateListInput {
  organizationId: Scalars['ID']['input'];
};

export class PdfFormTemplateSetInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  blob?: InputMaybe<Scalars['String']['input']>;
  creationDate?: InputMaybe<Scalars['DateTime']['input']>;
  details?: InputMaybe<Scalars['String']['input']>;
  displayName: Scalars['String']['input'];
  federalStateId?: InputMaybe<Scalars['String']['input']>;
  fields?: InputMaybe<Scalars['JSON']['input']>;
  fileName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isVirtual: Scalars['Boolean']['input'];
  key: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  pdfFormToken: KnownPdfFormTokens;
  recipient?: InputMaybe<Scalars['String']['input']>;
};

export class PdfFormTemplateSettings {
  __typename?: 'PdfFormTemplateSettings';
  _etag?: Maybe<Scalars['String']['output']>;
  alwaysGeneratedForms?: Maybe<Array<KnownPdfFormTokens>>;
  changedAt: Scalars['DateTime']['output'];
  changedBy: Scalars['String']['output'];
  id?: Maybe<Scalars['ID']['output']>;
  organizationId: Scalars['ID']['output'];
};

export class PdfFormTemplateSettingsGetInput {
  organizationId: Scalars['ID']['input'];
};

export class PdfFormTemplateSettingsSetInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  alwaysGeneratedForms?: InputMaybe<Array<KnownPdfFormTokens>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
};

export class Phase {
  __typename?: 'Phase';
  candidateDiscounts?: Maybe<Array<Discount>>;
  licenseDiscounts?: Maybe<Array<Discount>>;
  planId: Scalars['String']['output'];
  startDate: Scalars['DateTime']['output'];
  type: PhaseType;
};

export class PhaseInput {
  candidateDiscounts?: InputMaybe<Array<DiscountInput>>;
  licenseDiscounts?: InputMaybe<Array<DiscountInput>>;
  planId: Scalars['String']['input'];
  startDate: Scalars['DateTime']['input'];
  type: PhaseType;
};

export enum PhaseType {
  Normal = 'Normal',
  Paused = 'Paused'
}

export class Plan {
  __typename?: 'Plan';
  _className: Scalars['String']['output'];
  _typeName: Scalars['String']['output'];
  candidatePrice: CandidatePrice;
  currency: SupportedBillingCurrency;
  id?: Maybe<Scalars['ID']['output']>;
  labelKey: Scalars['String']['output'];
  licensePrice: LicensePrice;
};

export class PredefinedProcessModelDeclaration {
  __typename?: 'PredefinedProcessModelDeclaration';
  displayName: Array<ProcessModelString>;
  isPredefinedModel?: Maybe<Scalars['Boolean']['output']>;
  modelId: Scalars['String']['output'];
  organizationToken?: Maybe<Scalars['String']['output']>;
  ownerOrganizationId?: Maybe<Scalars['String']['output']>;
  releaseId?: Maybe<Scalars['String']['output']>;
  rootLanguage: Scalars['String']['output'];
  version?: Maybe<Scalars['String']['output']>;
};

export class PrepareNewTaskInput {
  candidateDisplayId?: InputMaybe<Scalars['String']['input']>;
  candidateDisplayName?: InputMaybe<Scalars['String']['input']>;
  candidateId?: InputMaybe<Scalars['String']['input']>;
  candidateOrganizationId?: InputMaybe<Scalars['String']['input']>;
  instanceState: InstanceStateEnum;
  organizationId: Scalars['String']['input'];
  processId: Scalars['String']['input'];
  responsibleRoles: Array<Scalars['String']['input']>;
  targetObject: ProcessTargetEnum;
  typeName: Scalars['String']['input'];
  visibleForRoles: Array<Scalars['String']['input']>;
};

export class PresentationCandidateData {
  __typename?: 'PresentationCandidateData';
  recommendationNote?: Maybe<RecommendationNote>;
};

export class PresentationCandidateDataInput {
  recommendationNote?: InputMaybe<RecommendationNoteInput>;
};

export class PreviewableFile {
  __typename?: 'PreviewableFile';
  document: Document;
  file: DocumentFile;
  setName?: Maybe<Scalars['String']['output']>;
  setType?: Maybe<Scalars['String']['output']>;
};

export class PriceRow {
  __typename?: 'PriceRow';
  candidateFunction?: Maybe<Scalars['String']['output']>;
  candidateSource?: Maybe<CandidateSource>;
  cap?: Maybe<Scalars['Float']['output']>;
  degression: Array<DegressionRow>;
  events: Array<PriceRowEventAndShare>;
  floor?: Maybe<Scalars['Float']['output']>;
  professionCategory?: Maybe<ProfessionCategory>;
};

export class PriceRowEventAndShare {
  __typename?: 'PriceRowEventAndShare';
  event: BillingEventType;
  share: Scalars['Float']['output'];
};

export class Process {
  __typename?: 'Process';
  _etag?: Maybe<Scalars['String']['output']>;
  automaticLanguage?: Maybe<Scalars['String']['output']>;
  changedAt: Scalars['DateTime']['output'];
  collectionId?: Maybe<Scalars['ID']['output']>;
  commentedStatesIds?: Maybe<Array<Scalars['String']['output']>>;
  configuration?: Maybe<Scalars['JSONObject']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  delegatedProcessId?: Maybe<Scalars['ID']['output']>;
  delegatedProcessOrganizationId?: Maybe<Scalars['ID']['output']>;
  displayDescription?: Maybe<Scalars['String']['output']>;
  displayName: Scalars['String']['output'];
  documentLanguages?: Maybe<Array<Scalars['String']['output']>>;
  hasDelegation?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isDelegation?: Maybe<Scalars['Boolean']['output']>;
  isSystemProcess?: Maybe<Scalars['Boolean']['output']>;
  languages: Array<Scalars['String']['output']>;
  operationMapping?: Maybe<ProcessOperationMapping>;
  organizationId: Scalars['ID']['output'];
  participationConditions?: Maybe<Array<ParticipationCondition>>;
  processModelId: Scalars['ID']['output'];
  processModelName: Array<ProcessRequiredString>;
  readonly?: Maybe<Scalars['Boolean']['output']>;
  releaseId?: Maybe<Scalars['ID']['output']>;
  roleMappingBeforeDelegation?: Maybe<Array<RoleOrganizationUserGroupMapping>>;
  roleOrganisationUsergroupMapping: Array<RoleOrganizationUserGroupMapping>;
  roles: Array<ProcessRole>;
  rules?: Maybe<Array<ProcessRule>>;
  state: ProcessInstanceStateEnum;
  taskVisibility?: Maybe<TaskVisibilityEnum>;
};

export class ProcessAccess {
  __typename?: 'ProcessAccess';
  canAccess: Scalars['Boolean']['output'];
  redirectToOrganization?: Maybe<Scalars['ID']['output']>;
};

export class ProcessAccessInput {
  organizationId: Scalars['ID']['input'];
  processId: Scalars['ID']['input'];
};

export class ProcessAction {
  __typename?: 'ProcessAction';
  _etag?: Maybe<Scalars['String']['output']>;
  changedAt: Scalars['DateTime']['output'];
  changedBy: Scalars['String']['output'];
  collectionNames: Array<Scalars['String']['output']>;
  collections?: Maybe<Array<CollectionDefinition>>;
  events: Array<ProcessEventEnum>;
  id?: Maybe<Scalars['ID']['output']>;
  isInactive?: Maybe<Scalars['Boolean']['output']>;
  issues: Array<ProcessActionIssue>;
  operationTypes: Array<ProcessOperationEnum>;
  operations: Array<ProcessOperationResult>;
  organizationId: Scalars['ID']['output'];
  processes: Array<ProcessActionProcess>;
};

export class ProcessActionCreateInput {
  collections?: InputMaybe<Array<CollectionDefinitionInput>>;
  events: Array<ProcessEventEnum>;
  isInactive?: InputMaybe<Scalars['Boolean']['input']>;
  operations: Array<ProcessOperationInput>;
  organizationId: Scalars['ID']['input'];
};

export class ProcessActionDeleteInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
};

export class ProcessActionGetInput {
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
};

export class ProcessActionIssue {
  __typename?: 'ProcessActionIssue';
  messages: Array<Scalars['String']['output']>;
  operationId: Scalars['String']['output'];
};

export class ProcessActionListInput {
  organizationId: Scalars['ID']['input'];
};

export class ProcessActionProcess {
  __typename?: 'ProcessActionProcess';
  collectionId: Scalars['String']['output'];
  collectionName: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isDelegation: Scalars['Boolean']['output'];
  organizationId: Scalars['String']['output'];
};

export class ProcessActionUpdateInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  collections?: InputMaybe<Array<CollectionDefinitionInput>>;
  events: Array<ProcessEventEnum>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isInactive?: InputMaybe<Scalars['Boolean']['input']>;
  operations: Array<ProcessOperationInput>;
  organizationId: Scalars['ID']['input'];
};

export class ProcessActivateInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
  state: ProcessInstanceStateEnum;
};

export class ProcessAndOperation {
  __typename?: 'ProcessAndOperation';
  operationId: Scalars['String']['output'];
  processId: Scalars['String']['output'];
};

export class ProcessAndOperationInput {
  operationId: Scalars['String']['input'];
  processId: Scalars['String']['input'];
};

export class ProcessAreaModel {
  __typename?: 'ProcessAreaModel';
  _typeName: Scalars['String']['output'];
  displayDescription?: Maybe<Array<ProcessModelString>>;
  displayName: Array<ProcessModelString>;
  doNotCreateOnSync?: Maybe<Scalars['Boolean']['output']>;
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  modelId: Scalars['ID']['output'];
  phases: Array<ProcessPhaseModel>;
  visibleForRoles?: Maybe<Array<Scalars['String']['output']>>;
};

export class ProcessAreaModelCreateInput {
  _typeName?: Scalars['String']['input'];
  displayDescription?: InputMaybe<Array<ProcessModelStringInput>>;
  displayName: Array<ProcessModelStringInput>;
  doNotCreateOnSync?: InputMaybe<Scalars['Boolean']['input']>;
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  modelId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
  parentId: Scalars['ID']['input'];
  previousAreaId?: InputMaybe<Scalars['String']['input']>;
  rootId: Scalars['ID']['input'];
  visibleForRoles?: InputMaybe<Array<Scalars['String']['input']>>;
};

export class ProcessAreaModelDeleteInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
};

export class ProcessAreaModelDuplicateInput {
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
  withChildren?: InputMaybe<Scalars['Boolean']['input']>;
};

export class ProcessAreaModelEntity {
  __typename?: 'ProcessAreaModelEntity';
  _etag?: Maybe<Scalars['String']['output']>;
  _typeName: Scalars['String']['output'];
  displayDescription?: Maybe<Array<ProcessModelString>>;
  displayName: Array<ProcessModelString>;
  doNotCreateOnSync?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  modelId: Scalars['ID']['output'];
  organizationId: Scalars['ID']['output'];
  parentId: Scalars['ID']['output'];
  rootId: Scalars['ID']['output'];
  sortOrder: Scalars['Float']['output'];
  visibleForRoles?: Maybe<Array<Scalars['String']['output']>>;
};

export class ProcessAreaModelEntityInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  _typeName?: Scalars['String']['input'];
  displayDescription?: InputMaybe<Array<ProcessModelStringInput>>;
  displayName: Array<ProcessModelStringInput>;
  doNotCreateOnSync?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  modelId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
  parentId: Scalars['ID']['input'];
  rootId: Scalars['ID']['input'];
  sortOrder: Scalars['Float']['input'];
  visibleForRoles?: InputMaybe<Array<Scalars['String']['input']>>;
};

export class ProcessAreaModelGetInput {
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
};

export class ProcessAreaModelMoveInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
  parentId: Scalars['ID']['input'];
  previousAreaId?: InputMaybe<Scalars['String']['input']>;
};

export class ProcessAreaModelUpdateInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  _typeName?: Scalars['String']['input'];
  displayDescription?: InputMaybe<Array<ProcessModelStringInput>>;
  displayName: Array<ProcessModelStringInput>;
  doNotCreateOnSync?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  modelId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
  parentId: Scalars['ID']['input'];
  rootId: Scalars['ID']['input'];
  sortOrder: Scalars['Float']['input'];
  visibleForRoles?: InputMaybe<Array<Scalars['String']['input']>>;
};

export class ProcessCreateInput {
  collectionId?: InputMaybe<Scalars['ID']['input']>;
  configuration?: InputMaybe<Scalars['JSONObject']['input']>;
  delegatedProcessId?: InputMaybe<Scalars['ID']['input']>;
  delegatedProcessOrganizationId?: InputMaybe<Scalars['ID']['input']>;
  displayDescription?: InputMaybe<Scalars['String']['input']>;
  displayName: Scalars['String']['input'];
  documentLanguages?: InputMaybe<Array<Scalars['String']['input']>>;
  isDelegation?: InputMaybe<Scalars['Boolean']['input']>;
  languages: Array<Scalars['String']['input']>;
  operationMapping?: InputMaybe<ProcessOperationMappingInput>;
  organizationId: Scalars['ID']['input'];
  processModelId: Scalars['ID']['input'];
  processModelName: Array<ProcessRequiredStringInput>;
  readonly?: InputMaybe<Scalars['Boolean']['input']>;
  releaseId?: InputMaybe<Scalars['ID']['input']>;
  roleMappingBeforeDelegation?: InputMaybe<Array<RoleOrganizationUserGroupMappingInput>>;
  roleOrganisationUsergroupMapping: Array<RoleOrganizationUserGroupMappingInput>;
  roles: Array<ProcessRoleInput>;
  rules?: InputMaybe<Array<ProcessRuleInput>>;
  state: ProcessInstanceStateEnum;
  taskVisibility?: InputMaybe<TaskVisibilityEnum>;
};

export class ProcessDeleteInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
};

export class ProcessDocumentRequirementType {
  __typename?: 'ProcessDocumentRequirementType';
  deliveryFormat?: Maybe<DocumentDeliveryFormat>;
  description?: Maybe<Array<ProcessOptionalString>>;
  documentSetType?: Maybe<Scalars['String']['output']>;
  documentType: Scalars['String']['output'];
  fileformats: Array<Scalars['String']['output']>;
  minfileFormatsRequired?: Maybe<Scalars['Float']['output']>;
  numberOfCopies?: Maybe<Array<Scalars['Float']['output']>>;
  requireNewUpload?: Maybe<Scalars['Boolean']['output']>;
};

export class ProcessDocumentRequirementTypeInput {
  deliveryFormat?: InputMaybe<DocumentDeliveryFormat>;
  description?: InputMaybe<Array<ProcessOptionalStringInput>>;
  documentSetType?: InputMaybe<Scalars['String']['input']>;
  documentType: Scalars['String']['input'];
  fileformats: Array<Scalars['String']['input']>;
  minfileFormatsRequired?: InputMaybe<Scalars['Float']['input']>;
  numberOfCopies?: InputMaybe<Array<Scalars['Float']['input']>>;
  requireNewUpload?: InputMaybe<Scalars['Boolean']['input']>;
};

export class ProcessDocumentToUploadType {
  __typename?: 'ProcessDocumentToUploadType';
  displayDescription?: Maybe<Array<ProcessOptionalString>>;
  displayName: Array<ProcessRequiredString>;
  documentId: Scalars['String']['output'];
  documentType?: Maybe<Scalars['String']['output']>;
  fileformats: Array<Scalars['String']['output']>;
};

export class ProcessDocumentToUploadTypeInput {
  displayDescription?: InputMaybe<Array<ProcessOptionalStringInput>>;
  displayName: Array<ProcessRequiredStringInput>;
  documentId: Scalars['String']['input'];
  documentType?: InputMaybe<Scalars['String']['input']>;
  fileformats: Array<Scalars['String']['input']>;
};

export enum ProcessEventEnum {
  ApplicationReceived = 'ApplicationReceived',
  ApplicationRejected = 'ApplicationRejected',
  CandidateChanged = 'CandidateChanged',
  CandidateCreated = 'CandidateCreated',
  EmployerChanged = 'EmployerChanged',
  InterviewCanceled = 'InterviewCanceled',
  InterviewRequested = 'InterviewRequested',
  NegativeFeedback = 'NegativeFeedback',
  ProcessCompleted = 'ProcessCompleted'
}

export class ProcessForDelegation {
  __typename?: 'ProcessForDelegation';
  _etag?: Maybe<Scalars['String']['output']>;
  automaticLanguage?: Maybe<Scalars['String']['output']>;
  changedAt: Scalars['DateTime']['output'];
  collectionId?: Maybe<Scalars['ID']['output']>;
  commentedStatesIds?: Maybe<Array<Scalars['String']['output']>>;
  configuration?: Maybe<Scalars['JSONObject']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  delegatedProcessId?: Maybe<Scalars['ID']['output']>;
  delegatedProcessOrganizationId?: Maybe<Scalars['ID']['output']>;
  displayDescription?: Maybe<Scalars['String']['output']>;
  displayName: Scalars['String']['output'];
  documentLanguages?: Maybe<Array<Scalars['String']['output']>>;
  hasDelegation?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isDelegation?: Maybe<Scalars['Boolean']['output']>;
  isSystemProcess?: Maybe<Scalars['Boolean']['output']>;
  languages: Array<Scalars['String']['output']>;
  operationMapping?: Maybe<ProcessOperationMapping>;
  organizationId: Scalars['ID']['output'];
  organizationName: Scalars['String']['output'];
  participationConditions?: Maybe<Array<ParticipationCondition>>;
  processModelId: Scalars['ID']['output'];
  processModelName: Array<ProcessRequiredString>;
  readonly?: Maybe<Scalars['Boolean']['output']>;
  releaseId?: Maybe<Scalars['ID']['output']>;
  roleMappingBeforeDelegation?: Maybe<Array<RoleOrganizationUserGroupMapping>>;
  roleOrganisationUsergroupMapping: Array<RoleOrganizationUserGroupMapping>;
  roles: Array<ProcessRole>;
  rules?: Maybe<Array<ProcessRule>>;
  state: ProcessInstanceStateEnum;
  taskVisibility?: Maybe<TaskVisibilityEnum>;
};

export class ProcessFormlyModel {
  __typename?: 'ProcessFormlyModel';
  formly?: Maybe<Array<Scalars['JSONObject']['output']>>;
};

export class ProcessFormlyModelInput {
  formly?: InputMaybe<Array<Scalars['JSONObject']['input']>>;
};

export class ProcessHeatmap {
  __typename?: 'ProcessHeatmap';
  candidateNames: Array<Scalars['String']['output']>;
  children?: Maybe<Array<ProcessHeatmapNode>>;
  id?: Maybe<Scalars['ID']['output']>;
  progresses: Array<Maybe<Scalars['Float']['output']>>;
  step?: Maybe<Scalars['String']['output']>;
};

export class ProcessHeatmapInput {
  language?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['ID']['input'];
  processId: Scalars['ID']['input'];
};

export class ProcessHeatmapNode {
  __typename?: 'ProcessHeatmapNode';
  children?: Maybe<Array<ProcessHeatmapNode>>;
  id?: Maybe<Scalars['ID']['output']>;
  progresses: Array<Maybe<Scalars['Float']['output']>>;
  step?: Maybe<Scalars['String']['output']>;
};

export enum ProcessInstanceStateEnum {
  Active = 'Active',
  Inactive = 'Inactive'
}

export class ProcessListInput {
  organizationId: Scalars['ID']['input'];
};

export class ProcessModel {
  __typename?: 'ProcessModel';
  _typeName: Scalars['String']['output'];
  areas?: Maybe<Array<ProcessAreaModel>>;
  displayDescription?: Maybe<Array<ProcessModelString>>;
  displayName: Array<ProcessModelString>;
  documentLanguages?: Maybe<Array<Scalars['String']['output']>>;
  isSystemProcess?: Maybe<Scalars['Boolean']['output']>;
  languages: Array<Scalars['String']['output']>;
  modelId: Scalars['String']['output'];
  participationConditions?: Maybe<Array<ParticipationCondition>>;
  preset?: Maybe<ProcessPreset>;
  releaseId?: Maybe<Scalars['ID']['output']>;
  requiredTranslations?: Maybe<Array<ProcessModelRequiredTranslation>>;
  roles: Array<ProcessModelRole>;
  rootLanguage: Scalars['String']['output'];
  rules?: Maybe<Array<ProcessRule>>;
  showDocumentLanguageSelection?: Maybe<Scalars['Boolean']['output']>;
  showTaskVisibilityConfiguration?: Maybe<Scalars['Boolean']['output']>;
  taskVisibility?: Maybe<TaskVisibilityEnum>;
  version?: Maybe<Scalars['String']['output']>;
  withoutCandidates?: Maybe<Scalars['Boolean']['output']>;
};

export class ProcessModelActivityParameter {
  __typename?: 'ProcessModelActivityParameter';
  id: Scalars['ID']['output'];
  modelId: Scalars['String']['output'];
  name: Array<ProcessModelString>;
  organizationId: Scalars['ID']['output'];
  organizationName?: Maybe<Scalars['String']['output']>;
};

export enum ProcessModelCommandEnum {
  BulkTranslate = 'BulkTranslate',
  CreateArea = 'CreateArea',
  CreatePhase = 'CreatePhase',
  CreateTask = 'CreateTask',
  DeleteArea = 'DeleteArea',
  DeletePhase = 'DeletePhase',
  DeleteTask = 'DeleteTask',
  DuplicateArea = 'DuplicateArea',
  DuplicatePhase = 'DuplicatePhase',
  DuplicateTask = 'DuplicateTask',
  MoveArea = 'MoveArea',
  MovePhase = 'MovePhase',
  MoveTask = 'MoveTask',
  TranslateArea = 'TranslateArea',
  TranslatePhase = 'TranslatePhase',
  TranslateProcess = 'TranslateProcess',
  TranslateTask = 'TranslateTask',
  UpdateArea = 'UpdateArea',
  UpdatePhase = 'UpdatePhase',
  UpdateTask = 'UpdateTask'
}

export class ProcessModelCommandGetLatestInput {
  language: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  rootId: Scalars['ID']['input'];
};

export class ProcessModelCommandGetLatestResult {
  __typename?: 'ProcessModelCommandGetLatestResult';
  lastCommand?: Maybe<ProcessModelCommandHistoryInfo>;
};

export class ProcessModelCommandHistoryGetInputType {
  first?: InputMaybe<Scalars['Int']['input']>;
  language: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  rootId: Scalars['ID']['input'];
  rows?: InputMaybe<Scalars['Int']['input']>;
};

export class ProcessModelCommandHistoryInfo {
  __typename?: 'ProcessModelCommandHistoryInfo';
  changes: Array<Scalars['String']['output']>;
  commandId: Scalars['ID']['output'];
  commandType: Scalars['String']['output'];
  date: Scalars['DateTime']['output'];
  details: Scalars['String']['output'];
  stageName: Scalars['String']['output'];
  user: Scalars['String']['output'];
};

export class ProcessModelCommandHistoryInfoPage {
  __typename?: 'ProcessModelCommandHistoryInfoPage';
  nodes?: Maybe<Array<ProcessModelCommandHistoryInfo>>;
  totalCount: Scalars['Int']['output'];
};

export class ProcessModelCommandResult {
  __typename?: 'ProcessModelCommandResult';
  errorMessage?: Maybe<Scalars['String']['output']>;
  lastCommand?: Maybe<ProcessModelCommandHistoryInfo>;
  node?: Maybe<ProcessModelCommandResultEntity>;
  success: Scalars['Boolean']['output'];
  tree: ProcessModelTree;
};

export class ProcessModelCommandResultEntity {
  __typename?: 'ProcessModelCommandResultEntity';
  area?: Maybe<ProcessAreaModelEntity>;
  mutationResult?: Maybe<MutationResult>;
  phase?: Maybe<ProcessPhaseModelEntity>;
  task?: Maybe<ProcessTaskModelResult>;
};

export class ProcessModelCreateDerivedInput {
  autoUpdate?: InputMaybe<Scalars['Boolean']['input']>;
  importModel?: InputMaybe<ProcessModelDeclarationInput>;
  modelId: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  rootLanguage?: InputMaybe<Scalars['String']['input']>;
};

export class ProcessModelCreateEmptyInput {
  displayName?: InputMaybe<Array<ProcessModelStringInput>>;
  isSystemProcess?: InputMaybe<Scalars['Boolean']['input']>;
  languages?: InputMaybe<Array<Scalars['String']['input']>>;
  modelId: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  roles?: InputMaybe<Array<ProcessModelRoleInput>>;
  rootLanguage?: InputMaybe<Scalars['String']['input']>;
};

export class ProcessModelCreateReleaseInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  modelId: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  processModelId: Scalars['ID']['input'];
};

export class ProcessModelDeclaration {
  __typename?: 'ProcessModelDeclaration';
  isPredefinedModel?: Maybe<Scalars['Boolean']['output']>;
  modelId: Scalars['String']['output'];
  organizationToken?: Maybe<Scalars['String']['output']>;
  ownerOrganizationId?: Maybe<Scalars['String']['output']>;
  releaseId?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['String']['output']>;
};

export class ProcessModelDeclarationInput {
  isPredefinedModel?: InputMaybe<Scalars['Boolean']['input']>;
  modelId: Scalars['String']['input'];
  organizationToken?: InputMaybe<Scalars['String']['input']>;
  ownerOrganizationId?: InputMaybe<Scalars['String']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  version?: InputMaybe<Scalars['String']['input']>;
};

export class ProcessModelDeleteInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
};

export class ProcessModelDeleteReleaseInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
};

export class ProcessModelDirective {
  __typename?: 'ProcessModelDirective';
  directive: ProcessStageModelEntity;
  order: Scalars['Int']['output'];
};

export class ProcessModelDuplicateInput {
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
};

export class ProcessModelEntity {
  __typename?: 'ProcessModelEntity';
  _etag?: Maybe<Scalars['String']['output']>;
  _typeName: Scalars['String']['output'];
  changedAt: Scalars['DateTime']['output'];
  changedBy: Scalars['String']['output'];
  displayDescription?: Maybe<Array<ProcessModelString>>;
  displayName: Array<ProcessModelString>;
  documentLanguages?: Maybe<Array<Scalars['String']['output']>>;
  id?: Maybe<Scalars['ID']['output']>;
  isSystemProcess?: Maybe<Scalars['Boolean']['output']>;
  languages: Array<Scalars['String']['output']>;
  modelId: Scalars['String']['output'];
  organizationId: Scalars['ID']['output'];
  participationConditions?: Maybe<Array<ParticipationCondition>>;
  preset?: Maybe<ProcessPreset>;
  requiredTranslations?: Maybe<Array<ProcessModelRequiredTranslation>>;
  roles: Array<ProcessModelRole>;
  rootLanguage: Scalars['String']['output'];
  rules?: Maybe<Array<ProcessRule>>;
  showDocumentLanguageSelection?: Maybe<Scalars['Boolean']['output']>;
  showTaskVisibilityConfiguration?: Maybe<Scalars['Boolean']['output']>;
  taskVisibility?: Maybe<TaskVisibilityEnum>;
  withoutCandidates?: Maybe<Scalars['Boolean']['output']>;
};

export class ProcessModelEntityInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  _typeName?: Scalars['String']['input'];
  changedAt: Scalars['DateTime']['input'];
  changedBy: Scalars['String']['input'];
  displayDescription?: InputMaybe<Array<ProcessModelStringInput>>;
  displayName: Array<ProcessModelStringInput>;
  documentLanguages?: InputMaybe<Array<Scalars['String']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isSystemProcess?: InputMaybe<Scalars['Boolean']['input']>;
  languages: Array<Scalars['String']['input']>;
  modelId: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  participationConditions?: InputMaybe<Array<ParticipationConditionInput>>;
  preset?: InputMaybe<ProcessPresetInputType>;
  requiredTranslations?: InputMaybe<Array<ProcessModelRequiredTranslationInput>>;
  roles: Array<ProcessModelRoleInput>;
  rootLanguage: Scalars['String']['input'];
  rules?: InputMaybe<Array<ProcessRuleInput>>;
  showDocumentLanguageSelection?: InputMaybe<Scalars['Boolean']['input']>;
  showTaskVisibilityConfiguration?: InputMaybe<Scalars['Boolean']['input']>;
  taskVisibility?: InputMaybe<TaskVisibilityEnum>;
  withoutCandidates?: InputMaybe<Scalars['Boolean']['input']>;
};

export class ProcessModelExtensionEntity {
  __typename?: 'ProcessModelExtensionEntity';
  _etag?: Maybe<Scalars['String']['output']>;
  _typeName: Scalars['String']['output'];
  autoUpdate?: Maybe<Scalars['Boolean']['output']>;
  changedAt: Scalars['DateTime']['output'];
  changedBy: Scalars['String']['output'];
  displayDescription?: Maybe<Array<ProcessModelString>>;
  displayName: Array<ProcessModelString>;
  documentLanguages?: Maybe<Array<Scalars['String']['output']>>;
  excludeIds?: Maybe<Array<Scalars['String']['output']>>;
  id?: Maybe<Scalars['ID']['output']>;
  importModel: ProcessModelDeclaration;
  isSystemProcess?: Maybe<Scalars['Boolean']['output']>;
  languages: Array<Scalars['String']['output']>;
  modelId: Scalars['String']['output'];
  organizationId: Scalars['ID']['output'];
  participationConditions?: Maybe<Array<ParticipationCondition>>;
  preset?: Maybe<ProcessPreset>;
  requiredTranslations?: Maybe<Array<ProcessModelRequiredTranslation>>;
  roles: Array<ProcessModelRole>;
  rootLanguage: Scalars['String']['output'];
  rules?: Maybe<Array<ProcessRule>>;
  showDocumentLanguageSelection?: Maybe<Scalars['Boolean']['output']>;
  showTaskVisibilityConfiguration?: Maybe<Scalars['Boolean']['output']>;
  taskVisibility?: Maybe<TaskVisibilityEnum>;
  withoutCandidates?: Maybe<Scalars['Boolean']['output']>;
};

export class ProcessModelExtensionEntityInput {
  _typeName?: Scalars['String']['input'];
  autoUpdate?: InputMaybe<Scalars['Boolean']['input']>;
  excludeIds?: InputMaybe<Array<Scalars['String']['input']>>;
  importModel: ProcessModelDeclarationInput;
  roles: Array<ProcessModelRoleInput>;
};

export class ProcessModelGetInput {
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
};

export class ProcessModelInfo {
  __typename?: 'ProcessModelInfo';
  _etag?: Maybe<Scalars['String']['output']>;
  _typeName: Scalars['String']['output'];
  displayName: Array<ProcessModelString>;
  id?: Maybe<Scalars['ID']['output']>;
  modelId: Scalars['String']['output'];
  organizationId: Scalars['ID']['output'];
};

export class ProcessModelPublishInfo {
  __typename?: 'ProcessModelPublishInfo';
  isModelPublished?: Maybe<Scalars['Boolean']['output']>;
  isModelSelectable: Scalars['Boolean']['output'];
  isModelShared?: Maybe<Scalars['Boolean']['output']>;
  isPredefinedModel: Scalars['Boolean']['output'];
  modelId: Scalars['String']['output'];
  organizationId: Scalars['ID']['output'];
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  publishedBy?: Maybe<Scalars['String']['output']>;
  publishedReleases?: Maybe<Array<ProcessModelPublishInfoHistoryEntry>>;
  releasedProcessModelId?: Maybe<Scalars['ID']['output']>;
  sharedReleases?: Maybe<Array<ProcessModelPublishInfoHistoryEntry>>;
};

export class ProcessModelPublishInfoHistoryEntry {
  __typename?: 'ProcessModelPublishInfoHistoryEntry';
  createdAt: Scalars['DateTime']['output'];
  createdBy: Scalars['String']['output'];
  releasedProcessModelId: Scalars['ID']['output'];
};

export class ProcessModelPublishInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  isModelSelectable: Scalars['Boolean']['input'];
  isModelShared?: InputMaybe<Scalars['Boolean']['input']>;
  isPredefinedModel: Scalars['Boolean']['input'];
  modelId: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  syncOptions?: InputMaybe<ProcessSyncInputOptions>;
};

export class ProcessModelReleaseListGetInput {
  modelId: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
};

export class ProcessModelRequiredTranslation {
  __typename?: 'ProcessModelRequiredTranslation';
  language: Scalars['String']['output'];
  modelId: Scalars['String']['output'];
  property: Scalars['String']['output'];
};

export class ProcessModelRequiredTranslationInput {
  language: Scalars['String']['input'];
  modelId: Scalars['String']['input'];
  property: Scalars['String']['input'];
};

export type ProcessModelResult = ProcessModelEntity | ProcessModelExtensionEntity;

export class ProcessModelRole {
  __typename?: 'ProcessModelRole';
  displayName: Array<ProcessModelString>;
  isOptional?: Maybe<Scalars['Boolean']['output']>;
  presetOrganizationId?: Maybe<Scalars['ID']['output']>;
  presetRoleId?: Maybe<Scalars['ID']['output']>;
  processRole: Scalars['String']['output'];
  setCreatorOrgAsDefault?: Maybe<Scalars['Boolean']['output']>;
  visibleStates?: Maybe<Array<InstanceStateEnum>>;
};

export class ProcessModelRoleInput {
  displayName: Array<ProcessModelStringInput>;
  isOptional?: InputMaybe<Scalars['Boolean']['input']>;
  presetOrganizationId?: InputMaybe<Scalars['ID']['input']>;
  presetRoleId?: InputMaybe<Scalars['ID']['input']>;
  processRole: Scalars['String']['input'];
  setCreatorOrgAsDefault?: InputMaybe<Scalars['Boolean']['input']>;
  visibleStates?: InputMaybe<Array<InstanceStateEnum>>;
};

export class ProcessModelString {
  __typename?: 'ProcessModelString';
  language: Scalars['String']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export class ProcessModelStringInput {
  language: Scalars['String']['input'];
  value?: InputMaybe<Scalars['String']['input']>;
};

export class ProcessModelTree {
  __typename?: 'ProcessModelTree';
  areas: Array<ProcessStageInfo>;
  model: ProcessModelInfo;
  phases: Array<ProcessStageInfo>;
  tasks: Array<ProcessStageInfo>;
  validationResult?: Maybe<ProcessModelValidationResult>;
};

export class ProcessModelTreeGetInput {
  id?: InputMaybe<Scalars['ID']['input']>;
  language: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  recompile: Scalars['Boolean']['input'];
  validate: Scalars['Boolean']['input'];
};

export class ProcessModelUndoCommand {
  commandId: Scalars['ID']['input'];
  language: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  rootId: Scalars['ID']['input'];
  validate: Scalars['Boolean']['input'];
};

export class ProcessModelUnpublishInput {
  modelId: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
};

export class ProcessModelUpdateInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  _typeName: Scalars['String']['input'];
  autoUpdate?: InputMaybe<Scalars['Boolean']['input']>;
  displayDescription?: InputMaybe<Array<ProcessModelStringInput>>;
  displayName?: InputMaybe<Array<ProcessModelStringInput>>;
  documentLanguages?: InputMaybe<Array<Scalars['String']['input']>>;
  excludeIds?: InputMaybe<Array<Scalars['String']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  importModel?: InputMaybe<ProcessModelDeclarationInput>;
  isSystemProcess?: InputMaybe<Scalars['Boolean']['input']>;
  languages?: InputMaybe<Array<Scalars['String']['input']>>;
  modelId: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  participationConditions?: InputMaybe<Array<ParticipationConditionInput>>;
  preset?: InputMaybe<ProcessPresetInputType>;
  requiredTranslations?: InputMaybe<Array<ProcessModelRequiredTranslationInput>>;
  roles?: InputMaybe<Array<ProcessModelRoleInput>>;
  rootLanguage?: InputMaybe<Scalars['String']['input']>;
  rules?: InputMaybe<Array<ProcessRuleInput>>;
  showDocumentLanguageSelection?: InputMaybe<Scalars['Boolean']['input']>;
  showTaskVisibilityConfiguration?: InputMaybe<Scalars['Boolean']['input']>;
  taskVisibility?: InputMaybe<TaskVisibilityEnum>;
  withoutCandidates?: InputMaybe<Scalars['Boolean']['input']>;
};

export class ProcessModelUpdateReleaseInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
};

export class ProcessModelValidationInput {
  id?: InputMaybe<Scalars['ID']['input']>;
  language: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
};

export class ProcessModelValidationMessage {
  __typename?: 'ProcessModelValidationMessage';
  message: Scalars['String']['output'];
  modelId: Scalars['String']['output'];
};

export class ProcessModelValidationResult {
  __typename?: 'ProcessModelValidationResult';
  errors: Array<ProcessModelValidationMessage>;
  isValid: Scalars['Boolean']['output'];
};

export interface ProcessOperation {
  id: Scalars['ID']['output'];
  operationType: ProcessOperationEnum;
}

export class ProcessOperationAddToList implements ProcessOperation {
  __typename?: 'ProcessOperationAddToList';
  collection: CollectionDefinition;
  id: Scalars['ID']['output'];
  operationType: ProcessOperationEnum;
};

export class ProcessOperationCreateDelegation implements ProcessOperation {
  __typename?: 'ProcessOperationCreateDelegation';
  baseProcess: BaseProcessDefinition;
  collection: CollectionDefinition;
  delegationMappings: Array<ProcessRoleMapping>;
  displayNameTemplate: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  operationType: ProcessOperationEnum;
  organizationId: Scalars['ID']['output'];
  taskVisibility: Scalars['String']['output'];
};

export class ProcessOperationCreateProcess implements ProcessOperation {
  __typename?: 'ProcessOperationCreateProcess';
  collection: CollectionDefinition;
  displayNameTemplate: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  operationType: ProcessOperationEnum;
  organizationId: Scalars['ID']['output'];
  processModelId: Scalars['String']['output'];
  roleMappings: Array<ProcessRoleMapping>;
};

export enum ProcessOperationEnum {
  AddToList = 'AddToList',
  CreateDelegation = 'CreateDelegation',
  CreateProcess = 'CreateProcess',
  RemoveFromList = 'RemoveFromList'
}

export class ProcessOperationInput {
  baseProcess?: InputMaybe<BaseProcessDefinitionInput>;
  behaviorType?: InputMaybe<RemoveFromListBehaviorEnum>;
  collection?: InputMaybe<CollectionDefinitionInput>;
  delegationMappings?: InputMaybe<Array<ProcessRoleMappingInput>>;
  displayNameTemplate?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  operationType?: InputMaybe<ProcessOperationEnum>;
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  processModelId?: InputMaybe<Scalars['String']['input']>;
  removeFromCollection?: InputMaybe<CollectionDefinitionInput>;
  roleMappings?: InputMaybe<Array<ProcessRoleMappingInput>>;
  sharingType?: InputMaybe<Scalars['String']['input']>;
  taskVisibility?: InputMaybe<Scalars['String']['input']>;
};

export class ProcessOperationMapping {
  __typename?: 'ProcessOperationMapping';
  processOperationId: Scalars['ID']['output'];
  variableMappings?: Maybe<Array<ProcessOperationVariableMapping>>;
};

export class ProcessOperationMappingInput {
  processOperationId: Scalars['ID']['input'];
  variableMappings?: InputMaybe<Array<ProcessOperationVariableMappingInput>>;
};

export class ProcessOperationRemoveFromList implements ProcessOperation {
  __typename?: 'ProcessOperationRemoveFromList';
  behaviorType: RemoveFromListBehaviorEnum;
  id: Scalars['ID']['output'];
  operationType: ProcessOperationEnum;
  removeFromCollection?: Maybe<CollectionDefinition>;
  sharingType?: Maybe<Scalars['String']['output']>;
};

export type ProcessOperationResult = ProcessOperationAddToList | ProcessOperationCreateDelegation | ProcessOperationCreateProcess | ProcessOperationRemoveFromList;

export class ProcessOperationVariableMapping {
  __typename?: 'ProcessOperationVariableMapping';
  value: Scalars['String']['output'];
  variable: Scalars['String']['output'];
};

export class ProcessOperationVariableMappingInput {
  value: Scalars['String']['input'];
  variable: Scalars['String']['input'];
};

export class ProcessOptionalQuillDelta {
  __typename?: 'ProcessOptionalQuillDelta';
  language: Scalars['String']['output'];
  value?: Maybe<Scalars['JSONObject']['output']>;
};

export class ProcessOptionalQuillDeltaInput {
  language: Scalars['String']['input'];
  value?: InputMaybe<Scalars['JSONObject']['input']>;
};

export class ProcessOptionalString {
  __typename?: 'ProcessOptionalString';
  language: Scalars['String']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export class ProcessOptionalStringInput {
  language: Scalars['String']['input'];
  value?: InputMaybe<Scalars['String']['input']>;
};

export class ProcessPerformResult {
  __typename?: 'ProcessPerformResult';
  state: ProcessStateResult;
  task: ProcessTaskResult;
};

export class ProcessPhaseModel {
  __typename?: 'ProcessPhaseModel';
  _typeName: Scalars['String']['output'];
  displayDescription?: Maybe<Array<ProcessModelString>>;
  displayName: Array<ProcessModelString>;
  doNotCreateOnSync?: Maybe<Scalars['Boolean']['output']>;
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  modelId: Scalars['ID']['output'];
  tasks: Array<ProcessTaskModel>;
  visibleForRoles?: Maybe<Array<Scalars['String']['output']>>;
};

export class ProcessPhaseModelCreateInput {
  _typeName?: Scalars['String']['input'];
  displayDescription?: InputMaybe<Array<ProcessModelStringInput>>;
  displayName: Array<ProcessModelStringInput>;
  doNotCreateOnSync?: InputMaybe<Scalars['Boolean']['input']>;
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  modelId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
  parentId: Scalars['ID']['input'];
  previousPhaseId?: InputMaybe<Scalars['String']['input']>;
  rootId: Scalars['ID']['input'];
  visibleForRoles?: InputMaybe<Array<Scalars['String']['input']>>;
};

export class ProcessPhaseModelDeleteInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
};

export class ProcessPhaseModelDuplicateInput {
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
  withChildren?: InputMaybe<Scalars['Boolean']['input']>;
};

export class ProcessPhaseModelEntity {
  __typename?: 'ProcessPhaseModelEntity';
  _etag?: Maybe<Scalars['String']['output']>;
  _typeName: Scalars['String']['output'];
  displayDescription?: Maybe<Array<ProcessModelString>>;
  displayName: Array<ProcessModelString>;
  doNotCreateOnSync?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  modelId: Scalars['ID']['output'];
  organizationId: Scalars['ID']['output'];
  parentId: Scalars['ID']['output'];
  rootId: Scalars['ID']['output'];
  sortOrder: Scalars['Float']['output'];
  visibleForRoles?: Maybe<Array<Scalars['String']['output']>>;
};

export class ProcessPhaseModelEntityInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  _typeName?: Scalars['String']['input'];
  displayDescription?: InputMaybe<Array<ProcessModelStringInput>>;
  displayName: Array<ProcessModelStringInput>;
  doNotCreateOnSync?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  modelId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
  parentId: Scalars['ID']['input'];
  rootId: Scalars['ID']['input'];
  sortOrder: Scalars['Float']['input'];
  visibleForRoles?: InputMaybe<Array<Scalars['String']['input']>>;
};

export class ProcessPhaseModelGetInput {
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
};

export class ProcessPhaseModelMoveInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
  parentId: Scalars['ID']['input'];
  previousPhaseId?: InputMaybe<Scalars['String']['input']>;
};

export class ProcessPhaseModelUpdateInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  _typeName?: Scalars['String']['input'];
  displayDescription?: InputMaybe<Array<ProcessModelStringInput>>;
  displayName: Array<ProcessModelStringInput>;
  doNotCreateOnSync?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  modelId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
  parentId: Scalars['ID']['input'];
  rootId: Scalars['ID']['input'];
  sortOrder: Scalars['Float']['input'];
  visibleForRoles?: InputMaybe<Array<Scalars['String']['input']>>;
};

export class ProcessPredecessorPhase {
  __typename?: 'ProcessPredecessorPhase';
  displayName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  tasks?: Maybe<Array<ProcessPredecessorTask>>;
};

export class ProcessPredecessorTask {
  __typename?: 'ProcessPredecessorTask';
  displayName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  parentId: Scalars['ID']['output'];
};

export class ProcessPreset {
  __typename?: 'ProcessPreset';
  displayDescription?: Maybe<Array<ProcessModelString>>;
  displayName?: Maybe<Array<ProcessModelString>>;
  roleMapping?: Maybe<Array<RoleOrganizationUserGroupMapping>>;
  runningNumberToken?: Maybe<Scalars['String']['output']>;
};

export class ProcessPresetInput {
  organizationId: Scalars['ID']['input'];
  processModelId: Scalars['ID']['input'];
};

export class ProcessPresetInputType {
  displayDescription?: InputMaybe<Array<ProcessModelStringInput>>;
  displayName?: InputMaybe<Array<ProcessModelStringInput>>;
  roleMapping?: InputMaybe<Array<RoleOrganizationUserGroupMappingInput>>;
  runningNumberToken?: InputMaybe<Scalars['String']['input']>;
};

export class ProcessProgress {
  __typename?: 'ProcessProgress';
  children?: Maybe<Array<ProcessProgress>>;
  level: ProcessProgressLevelEnum;
  step?: Maybe<Scalars['String']['output']>;
  targetRoles?: Maybe<Array<Scalars['String']['output']>>;
  tasksDone: Scalars['Float']['output'];
  tasksTotal: Scalars['Float']['output'];
};

export enum ProcessProgressLevelEnum {
  Area = 'Area',
  Phase = 'Phase',
  Process = 'Process',
  Task = 'Task'
}

export class ProcessReportInput {
  candidateId?: InputMaybe<Scalars['ID']['input']>;
  language: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  processId?: InputMaybe<Scalars['ID']['input']>;
};

export class ProcessReportResult {
  __typename?: 'ProcessReportResult';
  filename: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export class ProcessRequiredString {
  __typename?: 'ProcessRequiredString';
  language: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export class ProcessRequiredStringInput {
  language: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export class ProcessRole {
  __typename?: 'ProcessRole';
  displayName: Array<ProcessRequiredString>;
  localizedDisplayName?: Maybe<Scalars['String']['output']>;
  processRole: Scalars['String']['output'];
  visibleStates?: Maybe<Array<InstanceStateEnum>>;
};

export class ProcessRoleInput {
  displayName: Array<ProcessRequiredStringInput>;
  localizedDisplayName?: InputMaybe<Scalars['String']['input']>;
  processRole: Scalars['String']['input'];
  visibleStates?: InputMaybe<Array<InstanceStateEnum>>;
};

export class ProcessRoleMapping {
  __typename?: 'ProcessRoleMapping';
  assignedUserGroupIds?: Maybe<Array<Scalars['String']['output']>>;
  processOrganizationId?: Maybe<Scalars['String']['output']>;
  processRole: Scalars['String']['output'];
};

export class ProcessRoleMappingInput {
  assignedUserGroupIds?: InputMaybe<Array<Scalars['String']['input']>>;
  processOrganizationId?: InputMaybe<Scalars['String']['input']>;
  processRole: Scalars['String']['input'];
};

export class ProcessRule {
  __typename?: 'ProcessRule';
  event: ProcessRuleTriggerEvent;
  expression?: Maybe<Scalars['String']['output']>;
  expressionBlocklyWorkspace?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  propertyName?: Maybe<Scalars['String']['output']>;
  ruleType: ProcessRuleType;
  runCondition?: Maybe<Scalars['String']['output']>;
  runConditionBlocklyWorkspace?: Maybe<Scalars['String']['output']>;
  shortDescription?: Maybe<Scalars['String']['output']>;
  targetTasks?: Maybe<RuleTaskTarget>;
  targetType: ProcessRuleTargetType;
};

export class ProcessRuleInput {
  event?: ProcessRuleTriggerEvent;
  expression?: InputMaybe<Scalars['String']['input']>;
  expressionBlocklyWorkspace?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  propertyName?: InputMaybe<Scalars['String']['input']>;
  ruleType: ProcessRuleType;
  runCondition?: InputMaybe<Scalars['String']['input']>;
  runConditionBlocklyWorkspace?: InputMaybe<Scalars['String']['input']>;
  shortDescription?: InputMaybe<Scalars['String']['input']>;
  targetTasks?: InputMaybe<RuleTaskTargetInput>;
  targetType: ProcessRuleTargetType;
};

export enum ProcessRuleTargetType {
  Candidate = 'Candidate',
  State = 'State',
  Task = 'Task'
}

export enum ProcessRuleTaskTargetType {
  All = 'All',
  AllOfAreas = 'AllOfAreas',
  AllOfPhases = 'AllOfPhases',
  Tasks = 'Tasks'
}

export enum ProcessRuleTriggerEvent {
  OnActive = 'OnActive',
  OnCancelled = 'OnCancelled',
  OnCreate = 'OnCreate',
  OnError = 'OnError',
  OnFinished = 'OnFinished',
  OnInactive = 'OnInactive',
  OnStarted = 'OnStarted'
}

export enum ProcessRuleType {
  SetProperty = 'SetProperty'
}

export class ProcessStageInfo {
  __typename?: 'ProcessStageInfo';
  _className?: Maybe<Scalars['String']['output']>;
  _etag?: Maybe<Scalars['String']['output']>;
  _typeName: Scalars['String']['output'];
  displayName: Array<ProcessModelString>;
  id?: Maybe<Scalars['ID']['output']>;
  modelId: Scalars['ID']['output'];
  organizationId: Scalars['ID']['output'];
  parentId: Scalars['ID']['output'];
  rootId: Scalars['ID']['output'];
  sortOrder: Scalars['Float']['output'];
};

export interface ProcessStageModelEntity {
  _etag?: Maybe<Scalars['String']['output']>;
  displayDescription?: Maybe<Array<ProcessModelString>>;
  displayName: Array<ProcessModelString>;
  doNotCreateOnSync?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  modelId: Scalars['ID']['output'];
  organizationId: Scalars['ID']['output'];
  parentId: Scalars['ID']['output'];
  rootId: Scalars['ID']['output'];
  sortOrder: Scalars['Float']['output'];
}

export interface ProcessState {
  _etag?: Maybe<Scalars['String']['output']>;
  cancelReason?: Maybe<Scalars['String']['output']>;
  candidateId?: Maybe<Scalars['ID']['output']>;
  candidateOrganizationId?: Maybe<Scalars['ID']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  commentedAt?: Maybe<Scalars['DateTime']['output']>;
  commentedById?: Maybe<Scalars['ID']['output']>;
  commentedByName?: Maybe<Scalars['String']['output']>;
  commentedByOrgId?: Maybe<Scalars['ID']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id?: Maybe<Scalars['ID']['output']>;
  instanceId: Scalars['ID']['output'];
  instanceType: Scalars['String']['output'];
  isCommentByCandidate?: Maybe<Scalars['Boolean']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  organizationId: Scalars['ID']['output'];
  rootId: Scalars['ID']['output'];
  taskCanceled?: Maybe<Scalars['Boolean']['output']>;
  taskFinished?: Maybe<Scalars['Boolean']['output']>;
}

export class ProcessStateAssignmentException implements ProcessState {
  __typename?: 'ProcessStateAssignmentException';
  _etag?: Maybe<Scalars['String']['output']>;
  cancelReason?: Maybe<Scalars['String']['output']>;
  candidateId?: Maybe<Scalars['ID']['output']>;
  candidateOrganizationId?: Maybe<Scalars['ID']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  commentedAt?: Maybe<Scalars['DateTime']['output']>;
  commentedById?: Maybe<Scalars['ID']['output']>;
  commentedByName?: Maybe<Scalars['String']['output']>;
  commentedByOrgId?: Maybe<Scalars['ID']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id?: Maybe<Scalars['ID']['output']>;
  instanceId: Scalars['ID']['output'];
  instanceType: Scalars['String']['output'];
  isCommentByCandidate?: Maybe<Scalars['Boolean']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  organizationId: Scalars['ID']['output'];
  rootId: Scalars['ID']['output'];
  taskCanceled?: Maybe<Scalars['Boolean']['output']>;
  taskFinished?: Maybe<Scalars['Boolean']['output']>;
};

export class ProcessStateAutoOperation implements ProcessState {
  __typename?: 'ProcessStateAutoOperation';
  _etag?: Maybe<Scalars['String']['output']>;
  cancelReason?: Maybe<Scalars['String']['output']>;
  candidateId?: Maybe<Scalars['ID']['output']>;
  candidateOrganizationId?: Maybe<Scalars['ID']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  commentedAt?: Maybe<Scalars['DateTime']['output']>;
  commentedById?: Maybe<Scalars['ID']['output']>;
  commentedByName?: Maybe<Scalars['String']['output']>;
  commentedByOrgId?: Maybe<Scalars['ID']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id?: Maybe<Scalars['ID']['output']>;
  instanceId: Scalars['ID']['output'];
  instanceType: Scalars['String']['output'];
  isCommentByCandidate?: Maybe<Scalars['Boolean']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  organizationId: Scalars['ID']['output'];
  rootId: Scalars['ID']['output'];
  taskCanceled?: Maybe<Scalars['Boolean']['output']>;
  taskFinished?: Maybe<Scalars['Boolean']['output']>;
};

export class ProcessStateDeactivateProcess implements ProcessState {
  __typename?: 'ProcessStateDeactivateProcess';
  _etag?: Maybe<Scalars['String']['output']>;
  cancelReason?: Maybe<Scalars['String']['output']>;
  candidateId?: Maybe<Scalars['ID']['output']>;
  candidateOrganizationId?: Maybe<Scalars['ID']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  commentedAt?: Maybe<Scalars['DateTime']['output']>;
  commentedById?: Maybe<Scalars['ID']['output']>;
  commentedByName?: Maybe<Scalars['String']['output']>;
  commentedByOrgId?: Maybe<Scalars['ID']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id?: Maybe<Scalars['ID']['output']>;
  instanceId: Scalars['ID']['output'];
  instanceType: Scalars['String']['output'];
  isCommentByCandidate?: Maybe<Scalars['Boolean']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  organizationId: Scalars['ID']['output'];
  rootId: Scalars['ID']['output'];
  taskCanceled?: Maybe<Scalars['Boolean']['output']>;
  taskFinished?: Maybe<Scalars['Boolean']['output']>;
};

export class ProcessStateDocumentCheck implements ProcessState {
  __typename?: 'ProcessStateDocumentCheck';
  _etag?: Maybe<Scalars['String']['output']>;
  cancelReason?: Maybe<Scalars['String']['output']>;
  candidateId?: Maybe<Scalars['ID']['output']>;
  candidateOrganizationId?: Maybe<Scalars['ID']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  commentedAt?: Maybe<Scalars['DateTime']['output']>;
  commentedById?: Maybe<Scalars['ID']['output']>;
  commentedByName?: Maybe<Scalars['String']['output']>;
  commentedByOrgId?: Maybe<Scalars['ID']['output']>;
  createdAt: Scalars['DateTime']['output'];
  documentReviewResults?: Maybe<Array<ReviewDocumentResult>>;
  id?: Maybe<Scalars['ID']['output']>;
  instanceId: Scalars['ID']['output'];
  instanceType: Scalars['String']['output'];
  isCommentByCandidate?: Maybe<Scalars['Boolean']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  organizationId: Scalars['ID']['output'];
  reviewDoneByReviewers?: Maybe<Array<ReviewerVotum>>;
  reviewFinished: Scalars['Boolean']['output'];
  rootId: Scalars['ID']['output'];
  taskCanceled?: Maybe<Scalars['Boolean']['output']>;
  taskFinished?: Maybe<Scalars['Boolean']['output']>;
};

export class ProcessStateEmailSend implements ProcessState {
  __typename?: 'ProcessStateEmailSend';
  _etag?: Maybe<Scalars['String']['output']>;
  cancelReason?: Maybe<Scalars['String']['output']>;
  candidateId?: Maybe<Scalars['ID']['output']>;
  candidateOrganizationId?: Maybe<Scalars['ID']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  commentedAt?: Maybe<Scalars['DateTime']['output']>;
  commentedById?: Maybe<Scalars['ID']['output']>;
  commentedByName?: Maybe<Scalars['String']['output']>;
  commentedByOrgId?: Maybe<Scalars['ID']['output']>;
  createdAt: Scalars['DateTime']['output'];
  html?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  instanceId: Scalars['ID']['output'];
  instanceType: Scalars['String']['output'];
  isCommentByCandidate?: Maybe<Scalars['Boolean']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  organizationId: Scalars['ID']['output'];
  roles?: Maybe<Array<Scalars['String']['output']>>;
  rootId: Scalars['ID']['output'];
  taskCanceled?: Maybe<Scalars['Boolean']['output']>;
  taskFinished?: Maybe<Scalars['Boolean']['output']>;
  templateId?: Maybe<Scalars['ID']['output']>;
  templateLanguage?: Maybe<Scalars['String']['output']>;
};

export class ProcessStateEnterMissingInformation implements ProcessState {
  __typename?: 'ProcessStateEnterMissingInformation';
  _etag?: Maybe<Scalars['String']['output']>;
  cancelReason?: Maybe<Scalars['String']['output']>;
  candidateId?: Maybe<Scalars['ID']['output']>;
  candidateOrganizationId?: Maybe<Scalars['ID']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  commentedAt?: Maybe<Scalars['DateTime']['output']>;
  commentedById?: Maybe<Scalars['ID']['output']>;
  commentedByName?: Maybe<Scalars['String']['output']>;
  commentedByOrgId?: Maybe<Scalars['ID']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id?: Maybe<Scalars['ID']['output']>;
  instanceId: Scalars['ID']['output'];
  instanceType: Scalars['String']['output'];
  isCommentByCandidate?: Maybe<Scalars['Boolean']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  organizationId: Scalars['ID']['output'];
  requiredInformation?: Maybe<Scalars['JSONObject']['output']>;
  rootId: Scalars['ID']['output'];
  taskCanceled?: Maybe<Scalars['Boolean']['output']>;
  taskFinished?: Maybe<Scalars['Boolean']['output']>;
};

export class ProcessStateEnterMissingInformationV2 implements ProcessState {
  __typename?: 'ProcessStateEnterMissingInformationV2';
  _etag?: Maybe<Scalars['String']['output']>;
  cancelReason?: Maybe<Scalars['String']['output']>;
  candidateId?: Maybe<Scalars['ID']['output']>;
  candidateOrganizationId?: Maybe<Scalars['ID']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  commentedAt?: Maybe<Scalars['DateTime']['output']>;
  commentedById?: Maybe<Scalars['ID']['output']>;
  commentedByName?: Maybe<Scalars['String']['output']>;
  commentedByOrgId?: Maybe<Scalars['ID']['output']>;
  createdAt: Scalars['DateTime']['output'];
  data?: Maybe<Scalars['JSONObject']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  inaccessibleProperties?: Maybe<Array<Scalars['String']['output']>>;
  instanceId: Scalars['ID']['output'];
  instanceType: Scalars['String']['output'];
  isCommentByCandidate?: Maybe<Scalars['Boolean']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  organizationId: Scalars['ID']['output'];
  rootId: Scalars['ID']['output'];
  taskCanceled?: Maybe<Scalars['Boolean']['output']>;
  taskFinished?: Maybe<Scalars['Boolean']['output']>;
};

export class ProcessStateInformationCollection implements ProcessState {
  __typename?: 'ProcessStateInformationCollection';
  _etag?: Maybe<Scalars['String']['output']>;
  cancelReason?: Maybe<Scalars['String']['output']>;
  candidateId?: Maybe<Scalars['ID']['output']>;
  candidateOrganizationId?: Maybe<Scalars['ID']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  commentedAt?: Maybe<Scalars['DateTime']['output']>;
  commentedById?: Maybe<Scalars['ID']['output']>;
  commentedByName?: Maybe<Scalars['String']['output']>;
  commentedByOrgId?: Maybe<Scalars['ID']['output']>;
  createdAt: Scalars['DateTime']['output'];
  data?: Maybe<Scalars['JSONObject']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  instanceId: Scalars['ID']['output'];
  instanceType: Scalars['String']['output'];
  isCommentByCandidate?: Maybe<Scalars['Boolean']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  organizationId: Scalars['ID']['output'];
  prefillTaskId?: Maybe<Scalars['ID']['output']>;
  rootId: Scalars['ID']['output'];
  taskCanceled?: Maybe<Scalars['Boolean']['output']>;
  taskFinished?: Maybe<Scalars['Boolean']['output']>;
};

export class ProcessStateInformationSendOut implements ProcessState {
  __typename?: 'ProcessStateInformationSendOut';
  _etag?: Maybe<Scalars['String']['output']>;
  agreed?: Maybe<Scalars['Boolean']['output']>;
  cancelReason?: Maybe<Scalars['String']['output']>;
  candidateId?: Maybe<Scalars['ID']['output']>;
  candidateOrganizationId?: Maybe<Scalars['ID']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  commentedAt?: Maybe<Scalars['DateTime']['output']>;
  commentedById?: Maybe<Scalars['ID']['output']>;
  commentedByName?: Maybe<Scalars['String']['output']>;
  commentedByOrgId?: Maybe<Scalars['ID']['output']>;
  createdAt: Scalars['DateTime']['output'];
  date?: Maybe<Scalars['DateTime']['output']>;
  downloadableFiles?: Maybe<Array<DownloadableFile>>;
  id?: Maybe<Scalars['ID']['output']>;
  instanceId: Scalars['ID']['output'];
  instanceType: Scalars['String']['output'];
  isCommentByCandidate?: Maybe<Scalars['Boolean']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  organizationId: Scalars['ID']['output'];
  received?: Maybe<Scalars['Boolean']['output']>;
  reply?: Maybe<Scalars['String']['output']>;
  rootId: Scalars['ID']['output'];
  taskCanceled?: Maybe<Scalars['Boolean']['output']>;
  taskFinished?: Maybe<Scalars['Boolean']['output']>;
};

export class ProcessStateInviteToPlatform implements ProcessState {
  __typename?: 'ProcessStateInviteToPlatform';
  _etag?: Maybe<Scalars['String']['output']>;
  cancelReason?: Maybe<Scalars['String']['output']>;
  candidateId?: Maybe<Scalars['ID']['output']>;
  candidateOrganizationId?: Maybe<Scalars['ID']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  commentedAt?: Maybe<Scalars['DateTime']['output']>;
  commentedById?: Maybe<Scalars['ID']['output']>;
  commentedByName?: Maybe<Scalars['String']['output']>;
  commentedByOrgId?: Maybe<Scalars['ID']['output']>;
  createdAt: Scalars['DateTime']['output'];
  gdprConsent?: Maybe<Scalars['Boolean']['output']>;
  gdprConsentDate?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  instanceId: Scalars['ID']['output'];
  instanceType: Scalars['String']['output'];
  isCommentByCandidate?: Maybe<Scalars['Boolean']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  organizationId: Scalars['ID']['output'];
  rootId: Scalars['ID']['output'];
  taskCanceled?: Maybe<Scalars['Boolean']['output']>;
  taskFinished?: Maybe<Scalars['Boolean']['output']>;
};

export class ProcessStateOrganizationAudit implements ProcessState {
  __typename?: 'ProcessStateOrganizationAudit';
  _etag?: Maybe<Scalars['String']['output']>;
  auditMode: AuditTaskModeEnum;
  auditRemarksAdditionalClaim?: Maybe<Scalars['String']['output']>;
  auditRemarksAuditor?: Maybe<Scalars['String']['output']>;
  auditRemarksBoard?: Maybe<Scalars['String']['output']>;
  auditRemarksConfidant?: Maybe<Scalars['String']['output']>;
  auditVotumBoard?: Maybe<OrganizationAuditAdditionRequestResult>;
  auditorFiles?: Maybe<Array<AuditFile>>;
  awardDate?: Maybe<Scalars['DateTime']['output']>;
  cancelReason?: Maybe<Scalars['String']['output']>;
  candidateId?: Maybe<Scalars['ID']['output']>;
  candidateOrganizationId?: Maybe<Scalars['ID']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  commentedAt?: Maybe<Scalars['DateTime']['output']>;
  commentedById?: Maybe<Scalars['ID']['output']>;
  commentedByName?: Maybe<Scalars['String']['output']>;
  commentedByOrgId?: Maybe<Scalars['ID']['output']>;
  createdAt: Scalars['DateTime']['output'];
  expiryDate?: Maybe<Scalars['DateTime']['output']>;
  finalFinalVotum?: Maybe<OrganizationAuditAdditionRequestResult>;
  finalVotum?: Maybe<OrganizationAuditResult>;
  finalVotumDescription?: Maybe<Scalars['String']['output']>;
  finalVotumExpiryDate?: Maybe<Scalars['DateTime']['output']>;
  finalVotumReply?: Maybe<Scalars['String']['output']>;
  finalVotumReplyFiles?: Maybe<Array<AuditFile>>;
  id?: Maybe<Scalars['ID']['output']>;
  instanceId: Scalars['ID']['output'];
  instanceType: Scalars['String']['output'];
  interviewNotes?: Maybe<Scalars['String']['output']>;
  interviewSamples?: Maybe<OrganizationAuditInterviewSamples>;
  isCommentByCandidate?: Maybe<Scalars['Boolean']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  organizationAuditData?: Maybe<Array<OrganizationAuditData>>;
  organizationId: Scalars['ID']['output'];
  processAuditGroupName: Scalars['String']['output'];
  processDeadlineDate?: Maybe<Scalars['DateTime']['output']>;
  rootId: Scalars['ID']['output'];
  taskCanceled?: Maybe<Scalars['Boolean']['output']>;
  taskFinished?: Maybe<Scalars['Boolean']['output']>;
};

export class ProcessStatePdfFormGeneration implements ProcessState {
  __typename?: 'ProcessStatePdfFormGeneration';
  _etag?: Maybe<Scalars['String']['output']>;
  cancelReason?: Maybe<Scalars['String']['output']>;
  candidateId?: Maybe<Scalars['ID']['output']>;
  candidateOrganizationId?: Maybe<Scalars['ID']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  commentedAt?: Maybe<Scalars['DateTime']['output']>;
  commentedById?: Maybe<Scalars['ID']['output']>;
  commentedByName?: Maybe<Scalars['String']['output']>;
  commentedByOrgId?: Maybe<Scalars['ID']['output']>;
  createdAt: Scalars['DateTime']['output'];
  forms?: Maybe<Array<PdfFormGenerationState>>;
  id?: Maybe<Scalars['ID']['output']>;
  instanceId: Scalars['ID']['output'];
  instanceType: Scalars['String']['output'];
  isCommentByCandidate?: Maybe<Scalars['Boolean']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  organizationId: Scalars['ID']['output'];
  rootId: Scalars['ID']['output'];
  taskCanceled?: Maybe<Scalars['Boolean']['output']>;
  taskFinished?: Maybe<Scalars['Boolean']['output']>;
  tokens?: Maybe<Array<Scalars['String']['output']>>;
};

export class ProcessStateReminder implements ProcessState {
  __typename?: 'ProcessStateReminder';
  _etag?: Maybe<Scalars['String']['output']>;
  cancelReason?: Maybe<Scalars['String']['output']>;
  candidateId?: Maybe<Scalars['ID']['output']>;
  candidateOrganizationId?: Maybe<Scalars['ID']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  commentedAt?: Maybe<Scalars['DateTime']['output']>;
  commentedById?: Maybe<Scalars['ID']['output']>;
  commentedByName?: Maybe<Scalars['String']['output']>;
  commentedByOrgId?: Maybe<Scalars['ID']['output']>;
  createdAt: Scalars['DateTime']['output'];
  dueDate?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  instanceId: Scalars['ID']['output'];
  instanceType: Scalars['String']['output'];
  isCommentByCandidate?: Maybe<Scalars['Boolean']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  organizationId: Scalars['ID']['output'];
  rootId: Scalars['ID']['output'];
  snoozed?: Maybe<Scalars['Boolean']['output']>;
  taskCanceled?: Maybe<Scalars['Boolean']['output']>;
  taskFinished?: Maybe<Scalars['Boolean']['output']>;
};

export type ProcessStateResult = ProcessStateAssignmentException | ProcessStateAutoOperation | ProcessStateDeactivateProcess | ProcessStateDocumentCheck | ProcessStateEmailSend | ProcessStateEnterMissingInformation | ProcessStateEnterMissingInformationV2 | ProcessStateInformationCollection | ProcessStateInformationSendOut | ProcessStateInviteToPlatform | ProcessStateOrganizationAudit | ProcessStatePdfFormGeneration | ProcessStateReminder | ProcessStateRoleSetup | ProcessStateUpdateCandidateProfile | ProcessStateUpload | ProcessStateWork;

export class ProcessStateRoleSetup implements ProcessState {
  __typename?: 'ProcessStateRoleSetup';
  _etag?: Maybe<Scalars['String']['output']>;
  cancelReason?: Maybe<Scalars['String']['output']>;
  candidateId?: Maybe<Scalars['ID']['output']>;
  candidateOrganizationId?: Maybe<Scalars['ID']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  commentedAt?: Maybe<Scalars['DateTime']['output']>;
  commentedById?: Maybe<Scalars['ID']['output']>;
  commentedByName?: Maybe<Scalars['String']['output']>;
  commentedByOrgId?: Maybe<Scalars['ID']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id?: Maybe<Scalars['ID']['output']>;
  instanceId: Scalars['ID']['output'];
  instanceType: Scalars['String']['output'];
  isCommentByCandidate?: Maybe<Scalars['Boolean']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  organizationId: Scalars['ID']['output'];
  roleOrganisationUsergroupMapping: Array<RoleOrganizationUserGroupMapping>;
  rootId: Scalars['ID']['output'];
  taskCanceled?: Maybe<Scalars['Boolean']['output']>;
  taskFinished?: Maybe<Scalars['Boolean']['output']>;
};

export class ProcessStateUpdateCandidateProfile implements ProcessState {
  __typename?: 'ProcessStateUpdateCandidateProfile';
  _etag?: Maybe<Scalars['String']['output']>;
  cancelReason?: Maybe<Scalars['String']['output']>;
  candidateId?: Maybe<Scalars['ID']['output']>;
  candidateOrganizationId?: Maybe<Scalars['ID']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  commentedAt?: Maybe<Scalars['DateTime']['output']>;
  commentedById?: Maybe<Scalars['ID']['output']>;
  commentedByName?: Maybe<Scalars['String']['output']>;
  commentedByOrgId?: Maybe<Scalars['ID']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id?: Maybe<Scalars['ID']['output']>;
  instanceId: Scalars['ID']['output'];
  instanceType: Scalars['String']['output'];
  isCommentByCandidate?: Maybe<Scalars['Boolean']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  organizationId: Scalars['ID']['output'];
  rootId: Scalars['ID']['output'];
  taskCanceled?: Maybe<Scalars['Boolean']['output']>;
  taskFinished?: Maybe<Scalars['Boolean']['output']>;
};

export class ProcessStateUpdateInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  agreed?: InputMaybe<Scalars['Boolean']['input']>;
  allFilesSent?: InputMaybe<Scalars['Boolean']['input']>;
  attachments?: InputMaybe<Array<FileAttachmentInput>>;
  auditMode?: InputMaybe<AuditTaskModeEnum>;
  auditRemarksAdditionalClaim?: InputMaybe<Scalars['String']['input']>;
  auditRemarksAuditor?: InputMaybe<Scalars['String']['input']>;
  auditRemarksBoard?: InputMaybe<Scalars['String']['input']>;
  auditRemarksConfidant?: InputMaybe<Scalars['String']['input']>;
  auditVotumBoard?: InputMaybe<OrganizationAuditAdditionRequestResult>;
  auditorFiles?: InputMaybe<Array<AuditFileInput>>;
  awardDate?: InputMaybe<Scalars['DateTime']['input']>;
  cancelReason?: InputMaybe<Scalars['String']['input']>;
  candidateId?: InputMaybe<Scalars['ID']['input']>;
  candidateOrganizationId?: InputMaybe<Scalars['ID']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  commentedAt?: InputMaybe<Scalars['DateTime']['input']>;
  commentedById?: InputMaybe<Scalars['ID']['input']>;
  commentedByName?: InputMaybe<Scalars['String']['input']>;
  commentedByOrgId?: InputMaybe<Scalars['ID']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  data?: InputMaybe<Scalars['JSONObject']['input']>;
  date?: InputMaybe<Scalars['DateTime']['input']>;
  documentReviewResults?: InputMaybe<Array<ReviewDocumentResultInput>>;
  done?: InputMaybe<Scalars['Boolean']['input']>;
  downloadableFiles?: InputMaybe<Array<DownloadableFilestInput>>;
  dueDate?: InputMaybe<Scalars['DateTime']['input']>;
  equivalentDocumentFormats?: InputMaybe<Scalars['JSONObject']['input']>;
  expiryDate?: InputMaybe<Scalars['DateTime']['input']>;
  finalFinalVotum?: InputMaybe<OrganizationAuditAdditionRequestResult>;
  finalVotum?: InputMaybe<OrganizationAuditResult>;
  finalVotumDescription?: InputMaybe<Scalars['String']['input']>;
  finalVotumExpiryDate?: InputMaybe<Scalars['DateTime']['input']>;
  finalVotumReply?: InputMaybe<Scalars['String']['input']>;
  finalVotumReplyFiles?: InputMaybe<Array<AuditFileInput>>;
  forms?: InputMaybe<Array<PdfFormGenerationStateInput>>;
  gdprConsent?: InputMaybe<Scalars['Boolean']['input']>;
  gdprConsentDate?: InputMaybe<Scalars['DateTime']['input']>;
  html?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  inaccessibleProperties?: InputMaybe<Array<Scalars['String']['input']>>;
  instanceId?: InputMaybe<Scalars['ID']['input']>;
  instanceType?: InputMaybe<Scalars['String']['input']>;
  interviewNotes?: InputMaybe<Scalars['String']['input']>;
  interviewSamples?: InputMaybe<OrganizationAuditInterviewSamplesInput>;
  isCommentByCandidate?: InputMaybe<Scalars['Boolean']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  organizationAuditData?: InputMaybe<Array<OrganizationAuditDataInput>>;
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  prefillTaskId?: InputMaybe<Scalars['ID']['input']>;
  processAuditGroupName?: InputMaybe<Scalars['String']['input']>;
  processDeadlineDate?: InputMaybe<Scalars['DateTime']['input']>;
  received?: InputMaybe<Scalars['Boolean']['input']>;
  reply?: InputMaybe<Scalars['String']['input']>;
  requiredInformation?: InputMaybe<Scalars['JSONObject']['input']>;
  reviewDoneByReviewers?: InputMaybe<Array<ReviewerVotumInput>>;
  reviewFinished?: InputMaybe<Scalars['Boolean']['input']>;
  roleOrganisationUsergroupMapping?: InputMaybe<Array<RoleOrganizationUserGroupMappingInput>>;
  roles?: InputMaybe<Array<Scalars['String']['input']>>;
  rootId?: InputMaybe<Scalars['ID']['input']>;
  selectedChildTaskIds?: InputMaybe<Array<Scalars['String']['input']>>;
  showInternalFlagAtDocuments?: InputMaybe<Scalars['Boolean']['input']>;
  showMarkedAsCompletedFlagAtDocuments?: InputMaybe<Scalars['Boolean']['input']>;
  snoozed?: InputMaybe<Scalars['Boolean']['input']>;
  taskCanceled?: InputMaybe<Scalars['Boolean']['input']>;
  taskFinished?: InputMaybe<Scalars['Boolean']['input']>;
  templateId?: InputMaybe<Scalars['ID']['input']>;
  templateLanguage?: InputMaybe<Scalars['String']['input']>;
  tokens?: InputMaybe<Array<Scalars['String']['input']>>;
  typeName: Scalars['String']['input'];
  uploadedFileTypes?: InputMaybe<Array<UploadedDocumentTypeInput>>;
  uploadedProcessDocuments?: InputMaybe<Array<UploadedProcessDocumentInput>>;
  userId?: InputMaybe<Scalars['String']['input']>;
  userOrganizationId?: InputMaybe<Scalars['String']['input']>;
};

export class ProcessStateUpload implements ProcessState {
  __typename?: 'ProcessStateUpload';
  _etag?: Maybe<Scalars['String']['output']>;
  allFilesSent?: Maybe<Scalars['Boolean']['output']>;
  attachments?: Maybe<Array<FileAttachment>>;
  cancelReason?: Maybe<Scalars['String']['output']>;
  candidateId?: Maybe<Scalars['ID']['output']>;
  candidateOrganizationId?: Maybe<Scalars['ID']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  commentedAt?: Maybe<Scalars['DateTime']['output']>;
  commentedById?: Maybe<Scalars['ID']['output']>;
  commentedByName?: Maybe<Scalars['String']['output']>;
  commentedByOrgId?: Maybe<Scalars['ID']['output']>;
  createdAt: Scalars['DateTime']['output'];
  downloadableFiles?: Maybe<Array<DownloadableFile>>;
  equivalentDocumentFormats?: Maybe<Scalars['JSONObject']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  instanceId: Scalars['ID']['output'];
  instanceType: Scalars['String']['output'];
  isCommentByCandidate?: Maybe<Scalars['Boolean']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  organizationId: Scalars['ID']['output'];
  rootId: Scalars['ID']['output'];
  showInternalFlagAtDocuments?: Maybe<Scalars['Boolean']['output']>;
  showMarkedAsCompletedFlagAtDocuments?: Maybe<Scalars['Boolean']['output']>;
  taskCanceled?: Maybe<Scalars['Boolean']['output']>;
  taskFinished?: Maybe<Scalars['Boolean']['output']>;
  uploadedFileTypes?: Maybe<Array<UploadedDocumentType>>;
  uploadedProcessDocuments?: Maybe<Array<UploadedProcessDocument>>;
};

export class ProcessStateWork implements ProcessState {
  __typename?: 'ProcessStateWork';
  _etag?: Maybe<Scalars['String']['output']>;
  cancelReason?: Maybe<Scalars['String']['output']>;
  candidateId?: Maybe<Scalars['ID']['output']>;
  candidateOrganizationId?: Maybe<Scalars['ID']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  commentedAt?: Maybe<Scalars['DateTime']['output']>;
  commentedById?: Maybe<Scalars['ID']['output']>;
  commentedByName?: Maybe<Scalars['String']['output']>;
  commentedByOrgId?: Maybe<Scalars['ID']['output']>;
  createdAt: Scalars['DateTime']['output'];
  done?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  instanceId: Scalars['ID']['output'];
  instanceType: Scalars['String']['output'];
  isCommentByCandidate?: Maybe<Scalars['Boolean']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  organizationId: Scalars['ID']['output'];
  rootId: Scalars['ID']['output'];
  taskCanceled?: Maybe<Scalars['Boolean']['output']>;
  taskFinished?: Maybe<Scalars['Boolean']['output']>;
};

export class ProcessStatusCandidateData {
  __typename?: 'ProcessStatusCandidateData';
  estimatedArrivalDate?: Maybe<Scalars['DateTime']['output']>;
  estimatedWorkStartDate?: Maybe<Scalars['DateTime']['output']>;
};

export class ProcessStatusCandidateDataInput {
  estimatedArrivalDate?: InputMaybe<Scalars['DateTime']['input']>;
  estimatedWorkStartDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export class ProcessSyncAllInput {
  organizationId: Scalars['ID']['input'];
  processModelId?: InputMaybe<Scalars['ID']['input']>;
};

export class ProcessSyncInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
};

export class ProcessSyncInputOptions {
  doNotStartNewTasksWhoseSuccessorsAreAlreadyFinished?: InputMaybe<Scalars['Boolean']['input']>;
  finishAllTasksWhoseSuccessorsAreAlreadyFinished?: InputMaybe<Scalars['Boolean']['input']>;
  recalculateTaskState?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum ProcessTargetEnum {
  Candidate = 'Candidate',
  Collection = 'Collection',
  FamiliyMember = 'FamiliyMember'
}

export interface ProcessTask {
  _etag?: Maybe<Scalars['String']['output']>;
  activationCondition?: Maybe<Scalars['String']['output']>;
  atLeastOnePredecessorIds?: Maybe<Array<Scalars['ID']['output']>>;
  autoStateChanges?: Maybe<Array<AutoStateChange>>;
  cancelLabel?: Maybe<Array<ProcessOptionalString>>;
  candidateDisplayId?: Maybe<Scalars['String']['output']>;
  candidateDisplayName?: Maybe<Scalars['String']['output']>;
  candidateId?: Maybe<Scalars['ID']['output']>;
  candidateOrganizationId?: Maybe<Scalars['ID']['output']>;
  checkTaskActivationPeriodically?: Maybe<Scalars['Boolean']['output']>;
  delayStartByDays?: Maybe<Scalars['Int']['output']>;
  displayDescription?: Maybe<Array<ProcessOptionalString>>;
  displayDescriptionQD?: Maybe<Array<ProcessOptionalQuillDelta>>;
  displayName?: Maybe<Array<ProcessOptionalString>>;
  doNotCreateOnSync?: Maybe<Scalars['Boolean']['output']>;
  fieldsToExcludeFromSync?: Maybe<Array<Scalars['String']['output']>>;
  finishLabel?: Maybe<Array<ProcessOptionalString>>;
  hideGenericTaskComment?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  instanceState: InstanceStateEnum;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  lastInstanceStateChangedAt?: Maybe<Scalars['DateTime']['output']>;
  lastUpdateManualStarted?: Maybe<Scalars['Boolean']['output']>;
  localizedCancelLabel?: Maybe<Scalars['String']['output']>;
  localizedDisplayDescription?: Maybe<Scalars['String']['output']>;
  localizedDisplayName?: Maybe<Scalars['String']['output']>;
  localizedFinishLabel?: Maybe<Scalars['String']['output']>;
  localizedReactivatedCancelLabel?: Maybe<Scalars['String']['output']>;
  localizedReactivatedFinishLabel?: Maybe<Scalars['String']['output']>;
  localizedRuleExecutionError?: Maybe<Scalars['String']['output']>;
  manuallyCreated?: Maybe<Scalars['Boolean']['output']>;
  modelId: Scalars['ID']['output'];
  organizationId: Scalars['ID']['output'];
  ownerRoles: Array<Scalars['String']['output']>;
  parentId: Scalars['ID']['output'];
  predecessorIds?: Maybe<Array<Scalars['ID']['output']>>;
  previousTaskId?: Maybe<Scalars['ID']['output']>;
  processInstanceState: ProcessInstanceStateEnum;
  reactivateTasksOnCancel?: Maybe<Array<Scalars['ID']['output']>>;
  reactivateTasksOnFinish?: Maybe<Array<Scalars['ID']['output']>>;
  reactivatedByTask?: Maybe<Scalars['ID']['output']>;
  reactivatedCancelLabel?: Maybe<Array<ProcessOptionalString>>;
  reactivatedFinishLabel?: Maybe<Array<ProcessOptionalString>>;
  reactivatedRequiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  readonly?: Maybe<Scalars['Boolean']['output']>;
  reminderSettings?: Maybe<TaskReminderSettings>;
  requiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  responsibleRoles: Array<Scalars['String']['output']>;
  rootId: Scalars['ID']['output'];
  ruleExecutionError?: Maybe<RuleExecutionError>;
  rules?: Maybe<Array<ProcessTaskRule>>;
  selectFinishByDefault?: Maybe<Scalars['Boolean']['output']>;
  sortOrder: Scalars['Float']['output'];
  targetObject: ProcessTargetEnum;
  targetRoles: Array<Scalars['String']['output']>;
  taskEmails?: Maybe<Array<EmailTemplateDescription>>;
  visibleForRoles: Array<Scalars['String']['output']>;
}

export class ProcessTaskAssignmentException implements ProcessTask {
  __typename?: 'ProcessTaskAssignmentException';
  _etag?: Maybe<Scalars['String']['output']>;
  activationCondition?: Maybe<Scalars['String']['output']>;
  assignmentExceptionOperation?: Maybe<CandidateAssignmentExceptionOperation>;
  assignmentExceptionType: CandidateAssignmentExceptionType;
  atLeastOnePredecessorIds?: Maybe<Array<Scalars['ID']['output']>>;
  autoStateChanges?: Maybe<Array<AutoStateChange>>;
  cancelLabel?: Maybe<Array<ProcessOptionalString>>;
  candidateDisplayId?: Maybe<Scalars['String']['output']>;
  candidateDisplayName?: Maybe<Scalars['String']['output']>;
  candidateId?: Maybe<Scalars['ID']['output']>;
  candidateOrganizationId?: Maybe<Scalars['ID']['output']>;
  checkTaskActivationPeriodically?: Maybe<Scalars['Boolean']['output']>;
  delayStartByDays?: Maybe<Scalars['Int']['output']>;
  displayDescription?: Maybe<Array<ProcessOptionalString>>;
  displayDescriptionQD?: Maybe<Array<ProcessOptionalQuillDelta>>;
  displayName?: Maybe<Array<ProcessOptionalString>>;
  doNotCreateOnSync?: Maybe<Scalars['Boolean']['output']>;
  fieldsToExcludeFromSync?: Maybe<Array<Scalars['String']['output']>>;
  finishLabel?: Maybe<Array<ProcessOptionalString>>;
  hideGenericTaskComment?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  instanceState: InstanceStateEnum;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  lastInstanceStateChangedAt?: Maybe<Scalars['DateTime']['output']>;
  lastUpdateManualStarted?: Maybe<Scalars['Boolean']['output']>;
  localizedCancelLabel?: Maybe<Scalars['String']['output']>;
  localizedDisplayDescription?: Maybe<Scalars['String']['output']>;
  localizedDisplayName?: Maybe<Scalars['String']['output']>;
  localizedFinishLabel?: Maybe<Scalars['String']['output']>;
  localizedReactivatedCancelLabel?: Maybe<Scalars['String']['output']>;
  localizedReactivatedFinishLabel?: Maybe<Scalars['String']['output']>;
  localizedRuleExecutionError?: Maybe<Scalars['String']['output']>;
  manuallyCreated?: Maybe<Scalars['Boolean']['output']>;
  modelId: Scalars['ID']['output'];
  organizationId: Scalars['ID']['output'];
  ownerRoles: Array<Scalars['String']['output']>;
  parentId: Scalars['ID']['output'];
  predecessorIds?: Maybe<Array<Scalars['ID']['output']>>;
  previousTaskId?: Maybe<Scalars['ID']['output']>;
  processInstanceState: ProcessInstanceStateEnum;
  reactivateTasksOnCancel?: Maybe<Array<Scalars['ID']['output']>>;
  reactivateTasksOnFinish?: Maybe<Array<Scalars['ID']['output']>>;
  reactivatedByTask?: Maybe<Scalars['ID']['output']>;
  reactivatedCancelLabel?: Maybe<Array<ProcessOptionalString>>;
  reactivatedFinishLabel?: Maybe<Array<ProcessOptionalString>>;
  reactivatedRequiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  readonly?: Maybe<Scalars['Boolean']['output']>;
  reminderSettings?: Maybe<TaskReminderSettings>;
  requiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  responsibleRoles: Array<Scalars['String']['output']>;
  rootId: Scalars['ID']['output'];
  ruleExecutionError?: Maybe<RuleExecutionError>;
  rules?: Maybe<Array<ProcessTaskRule>>;
  selectFinishByDefault?: Maybe<Scalars['Boolean']['output']>;
  sortOrder: Scalars['Float']['output'];
  targetObject: ProcessTargetEnum;
  targetRoles: Array<Scalars['String']['output']>;
  taskEmails?: Maybe<Array<EmailTemplateDescription>>;
  visibleForRoles: Array<Scalars['String']['output']>;
};

export class ProcessTaskAssignmentExceptionModel {
  __typename?: 'ProcessTaskAssignmentExceptionModel';
  _className: Scalars['String']['output'];
  _typeName: Scalars['String']['output'];
  activationCondition?: Maybe<Scalars['String']['output']>;
  activationConditionBlocklyWorkspace?: Maybe<Scalars['String']['output']>;
  assignmentExceptionOperation: CandidateAssignmentExceptionOperation;
  assignmentExceptionType?: Maybe<CandidateAssignmentExceptionType>;
  atLeastOnePredecessorIds?: Maybe<Array<Scalars['String']['output']>>;
  autoStateChanges?: Maybe<Array<AutoStateChangeModel>>;
  cancelLabel?: Maybe<Array<ProcessModelString>>;
  checkTaskActivationPeriodically?: Maybe<Scalars['Boolean']['output']>;
  delayStartByDays?: Maybe<Scalars['Int']['output']>;
  displayDescription?: Maybe<Array<ProcessModelString>>;
  displayName: Array<ProcessModelString>;
  doNotCreateOnSync?: Maybe<Scalars['Boolean']['output']>;
  dontSendEmails?: Maybe<Scalars['Boolean']['output']>;
  finishLabel?: Maybe<Array<ProcessModelString>>;
  hideGenericTaskComment?: Maybe<Scalars['Boolean']['output']>;
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  modelId: Scalars['ID']['output'];
  predecessorsIds?: Maybe<Array<Scalars['String']['output']>>;
  reactivateTasksOnCancel?: Maybe<Array<Scalars['String']['output']>>;
  reactivateTasksOnFinish?: Maybe<Array<Scalars['String']['output']>>;
  reactivatedCancelLabel?: Maybe<Array<ProcessModelString>>;
  reactivatedFinishLabel?: Maybe<Array<ProcessModelString>>;
  reactivatedRequiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  reminderSettings?: Maybe<TaskReminderSettings>;
  requiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  responsibleRoles: Array<Scalars['String']['output']>;
  rules?: Maybe<Array<ProcessTaskRule>>;
  selectFinishByDefault?: Maybe<Scalars['Boolean']['output']>;
  targetObject: ProcessTargetEnum;
  targetRoles: Array<Scalars['String']['output']>;
  taskEmails?: Maybe<Array<EmailTemplateDescription>>;
  visibleForRoles: Array<Scalars['String']['output']>;
};

export class ProcessTaskAssignmentExceptionModelEntity implements ProcessTaskModelEntity {
  __typename?: 'ProcessTaskAssignmentExceptionModelEntity';
  _className: Scalars['String']['output'];
  _etag?: Maybe<Scalars['String']['output']>;
  _typeName: Scalars['String']['output'];
  activationCondition?: Maybe<Scalars['String']['output']>;
  activationConditionBlocklyWorkspace?: Maybe<Scalars['String']['output']>;
  assignmentExceptionOperation: CandidateAssignmentExceptionOperation;
  assignmentExceptionType?: Maybe<CandidateAssignmentExceptionType>;
  atLeastOnePredecessorIds?: Maybe<Array<Scalars['String']['output']>>;
  autoStateChanges?: Maybe<Array<AutoStateChangeModel>>;
  cancelLabel?: Maybe<Array<ProcessModelString>>;
  checkTaskActivationPeriodically?: Maybe<Scalars['Boolean']['output']>;
  delayStartByDays?: Maybe<Scalars['Int']['output']>;
  displayDescription?: Maybe<Array<ProcessModelString>>;
  displayName: Array<ProcessModelString>;
  doNotCreateOnSync?: Maybe<Scalars['Boolean']['output']>;
  dontSendEmails?: Maybe<Scalars['Boolean']['output']>;
  finishLabel?: Maybe<Array<ProcessModelString>>;
  hideGenericTaskComment?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  modelId: Scalars['ID']['output'];
  organizationId: Scalars['ID']['output'];
  parentId: Scalars['ID']['output'];
  predecessorsIds?: Maybe<Array<Scalars['String']['output']>>;
  reactivateTasksOnCancel?: Maybe<Array<Scalars['String']['output']>>;
  reactivateTasksOnFinish?: Maybe<Array<Scalars['String']['output']>>;
  reactivatedCancelLabel?: Maybe<Array<ProcessModelString>>;
  reactivatedFinishLabel?: Maybe<Array<ProcessModelString>>;
  reactivatedRequiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  reminderSettings?: Maybe<TaskReminderSettings>;
  requiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  responsibleRoles: Array<Scalars['String']['output']>;
  rootId: Scalars['ID']['output'];
  rules?: Maybe<Array<ProcessTaskRule>>;
  selectFinishByDefault?: Maybe<Scalars['Boolean']['output']>;
  sortOrder: Scalars['Float']['output'];
  targetObject: ProcessTargetEnum;
  targetRoles: Array<Scalars['String']['output']>;
  taskEmails?: Maybe<Array<EmailTemplateDescription>>;
  visibleForRoles: Array<Scalars['String']['output']>;
};

export class ProcessTaskAutoOperation implements ProcessTask {
  __typename?: 'ProcessTaskAutoOperation';
  _etag?: Maybe<Scalars['String']['output']>;
  activationCondition?: Maybe<Scalars['String']['output']>;
  atLeastOnePredecessorIds?: Maybe<Array<Scalars['ID']['output']>>;
  autoProcessToken: Scalars['String']['output'];
  autoStateChanges?: Maybe<Array<AutoStateChange>>;
  cancelLabel?: Maybe<Array<ProcessOptionalString>>;
  candidateDisplayId?: Maybe<Scalars['String']['output']>;
  candidateDisplayName?: Maybe<Scalars['String']['output']>;
  candidateId?: Maybe<Scalars['ID']['output']>;
  candidateOrganizationId?: Maybe<Scalars['ID']['output']>;
  checkTaskActivationPeriodically?: Maybe<Scalars['Boolean']['output']>;
  delayStartByDays?: Maybe<Scalars['Int']['output']>;
  displayDescription?: Maybe<Array<ProcessOptionalString>>;
  displayDescriptionQD?: Maybe<Array<ProcessOptionalQuillDelta>>;
  displayName?: Maybe<Array<ProcessOptionalString>>;
  doNotCreateOnSync?: Maybe<Scalars['Boolean']['output']>;
  fieldsToExcludeFromSync?: Maybe<Array<Scalars['String']['output']>>;
  finishLabel?: Maybe<Array<ProcessOptionalString>>;
  hideGenericTaskComment?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  instanceState: InstanceStateEnum;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  lastInstanceStateChangedAt?: Maybe<Scalars['DateTime']['output']>;
  lastUpdateManualStarted?: Maybe<Scalars['Boolean']['output']>;
  localizedCancelLabel?: Maybe<Scalars['String']['output']>;
  localizedDisplayDescription?: Maybe<Scalars['String']['output']>;
  localizedDisplayName?: Maybe<Scalars['String']['output']>;
  localizedFinishLabel?: Maybe<Scalars['String']['output']>;
  localizedReactivatedCancelLabel?: Maybe<Scalars['String']['output']>;
  localizedReactivatedFinishLabel?: Maybe<Scalars['String']['output']>;
  localizedRuleExecutionError?: Maybe<Scalars['String']['output']>;
  manuallyCreated?: Maybe<Scalars['Boolean']['output']>;
  modelId: Scalars['ID']['output'];
  organizationId: Scalars['ID']['output'];
  ownerRoles: Array<Scalars['String']['output']>;
  parentId: Scalars['ID']['output'];
  predecessorIds?: Maybe<Array<Scalars['ID']['output']>>;
  previousTaskId?: Maybe<Scalars['ID']['output']>;
  processInstanceState: ProcessInstanceStateEnum;
  reactivateTasksOnCancel?: Maybe<Array<Scalars['ID']['output']>>;
  reactivateTasksOnFinish?: Maybe<Array<Scalars['ID']['output']>>;
  reactivatedByTask?: Maybe<Scalars['ID']['output']>;
  reactivatedCancelLabel?: Maybe<Array<ProcessOptionalString>>;
  reactivatedFinishLabel?: Maybe<Array<ProcessOptionalString>>;
  reactivatedRequiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  readonly?: Maybe<Scalars['Boolean']['output']>;
  reminderSettings?: Maybe<TaskReminderSettings>;
  requiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  responsibleRoles: Array<Scalars['String']['output']>;
  rootId: Scalars['ID']['output'];
  ruleExecutionError?: Maybe<RuleExecutionError>;
  rules?: Maybe<Array<ProcessTaskRule>>;
  selectFinishByDefault?: Maybe<Scalars['Boolean']['output']>;
  sortOrder: Scalars['Float']['output'];
  targetObject: ProcessTargetEnum;
  targetRoles: Array<Scalars['String']['output']>;
  taskEmails?: Maybe<Array<EmailTemplateDescription>>;
  visibleForRoles: Array<Scalars['String']['output']>;
};

export class ProcessTaskAutoOperationModel {
  __typename?: 'ProcessTaskAutoOperationModel';
  _className: Scalars['String']['output'];
  _typeName: Scalars['String']['output'];
  activationCondition?: Maybe<Scalars['String']['output']>;
  activationConditionBlocklyWorkspace?: Maybe<Scalars['String']['output']>;
  atLeastOnePredecessorIds?: Maybe<Array<Scalars['String']['output']>>;
  autoProcessToken: Scalars['String']['output'];
  autoStateChanges?: Maybe<Array<AutoStateChangeModel>>;
  cancelLabel?: Maybe<Array<ProcessModelString>>;
  checkTaskActivationPeriodically?: Maybe<Scalars['Boolean']['output']>;
  delayStartByDays?: Maybe<Scalars['Int']['output']>;
  displayDescription?: Maybe<Array<ProcessModelString>>;
  displayName: Array<ProcessModelString>;
  doNotCreateOnSync?: Maybe<Scalars['Boolean']['output']>;
  dontSendEmails?: Maybe<Scalars['Boolean']['output']>;
  finishLabel?: Maybe<Array<ProcessModelString>>;
  hideGenericTaskComment?: Maybe<Scalars['Boolean']['output']>;
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  modelId: Scalars['ID']['output'];
  predecessorsIds?: Maybe<Array<Scalars['String']['output']>>;
  reactivateTasksOnCancel?: Maybe<Array<Scalars['String']['output']>>;
  reactivateTasksOnFinish?: Maybe<Array<Scalars['String']['output']>>;
  reactivatedCancelLabel?: Maybe<Array<ProcessModelString>>;
  reactivatedFinishLabel?: Maybe<Array<ProcessModelString>>;
  reactivatedRequiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  reminderSettings?: Maybe<TaskReminderSettings>;
  requiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  responsibleRoles: Array<Scalars['String']['output']>;
  rules?: Maybe<Array<ProcessTaskRule>>;
  selectFinishByDefault?: Maybe<Scalars['Boolean']['output']>;
  targetObject: ProcessTargetEnum;
  targetRoles: Array<Scalars['String']['output']>;
  taskEmails?: Maybe<Array<EmailTemplateDescription>>;
  visibleForRoles: Array<Scalars['String']['output']>;
};

export class ProcessTaskAutoOperationModelEntity implements ProcessTaskModelEntity {
  __typename?: 'ProcessTaskAutoOperationModelEntity';
  _className: Scalars['String']['output'];
  _etag?: Maybe<Scalars['String']['output']>;
  _typeName: Scalars['String']['output'];
  activationCondition?: Maybe<Scalars['String']['output']>;
  activationConditionBlocklyWorkspace?: Maybe<Scalars['String']['output']>;
  atLeastOnePredecessorIds?: Maybe<Array<Scalars['String']['output']>>;
  autoProcessToken: Scalars['String']['output'];
  autoStateChanges?: Maybe<Array<AutoStateChangeModel>>;
  cancelLabel?: Maybe<Array<ProcessModelString>>;
  checkTaskActivationPeriodically?: Maybe<Scalars['Boolean']['output']>;
  delayStartByDays?: Maybe<Scalars['Int']['output']>;
  displayDescription?: Maybe<Array<ProcessModelString>>;
  displayName: Array<ProcessModelString>;
  doNotCreateOnSync?: Maybe<Scalars['Boolean']['output']>;
  dontSendEmails?: Maybe<Scalars['Boolean']['output']>;
  finishLabel?: Maybe<Array<ProcessModelString>>;
  hideGenericTaskComment?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  modelId: Scalars['ID']['output'];
  organizationId: Scalars['ID']['output'];
  parentId: Scalars['ID']['output'];
  predecessorsIds?: Maybe<Array<Scalars['String']['output']>>;
  reactivateTasksOnCancel?: Maybe<Array<Scalars['String']['output']>>;
  reactivateTasksOnFinish?: Maybe<Array<Scalars['String']['output']>>;
  reactivatedCancelLabel?: Maybe<Array<ProcessModelString>>;
  reactivatedFinishLabel?: Maybe<Array<ProcessModelString>>;
  reactivatedRequiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  reminderSettings?: Maybe<TaskReminderSettings>;
  requiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  responsibleRoles: Array<Scalars['String']['output']>;
  rootId: Scalars['ID']['output'];
  rules?: Maybe<Array<ProcessTaskRule>>;
  selectFinishByDefault?: Maybe<Scalars['Boolean']['output']>;
  sortOrder: Scalars['Float']['output'];
  targetObject: ProcessTargetEnum;
  targetRoles: Array<Scalars['String']['output']>;
  taskEmails?: Maybe<Array<EmailTemplateDescription>>;
  visibleForRoles: Array<Scalars['String']['output']>;
};

export class ProcessTaskCopyDataInput {
  organizationId: Scalars['ID']['input'];
  processId: Scalars['ID']['input'];
  taskId: Scalars['ID']['input'];
};

export class ProcessTaskCreateInput {
  acceptFileTypes?: InputMaybe<Scalars['String']['input']>;
  activationCondition?: InputMaybe<Scalars['String']['input']>;
  assignmentExceptionOperation?: InputMaybe<CandidateAssignmentExceptionOperation>;
  assignmentExceptionType?: InputMaybe<CandidateAssignmentExceptionType>;
  atLeastOnePredecessorIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  attachDownloadableTaskFilesToEmails?: InputMaybe<Scalars['Boolean']['input']>;
  attachmentError?: InputMaybe<Scalars['String']['input']>;
  attachmentForms?: InputMaybe<Array<Scalars['String']['input']>>;
  attachmentTaskIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  attachmentTemplates?: InputMaybe<Array<Scalars['String']['input']>>;
  attachmentTemplatesFileType?: InputMaybe<Array<AttachmentTemplateFileType>>;
  attachments?: InputMaybe<Array<FileAttachmentInput>>;
  attachmentsAllowedOnTopLevel?: InputMaybe<Scalars['Boolean']['input']>;
  attachmentsDisplayText?: InputMaybe<Array<ProcessOptionalStringInput>>;
  attachmentsRequired?: InputMaybe<Scalars['Boolean']['input']>;
  auditDefinitionName?: InputMaybe<Scalars['String']['input']>;
  auditMode?: InputMaybe<AuditTaskModeEnum>;
  autoProcessToken?: InputMaybe<Scalars['String']['input']>;
  autoStateChanges?: InputMaybe<Array<AutoStateChangeInput>>;
  cancelLabel?: InputMaybe<Array<ProcessOptionalStringInput>>;
  candidateDisplayId?: InputMaybe<Scalars['String']['input']>;
  candidateDisplayName?: InputMaybe<Scalars['String']['input']>;
  candidateDocuments?: InputMaybe<Array<CandidateDocTypeAttachmentInput>>;
  candidateId?: InputMaybe<Scalars['ID']['input']>;
  candidateOrganizationId?: InputMaybe<Scalars['ID']['input']>;
  candidateRequiredDocumentSetIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  checkTaskActivationPeriodically?: InputMaybe<Scalars['Boolean']['input']>;
  confirmationMode?: InputMaybe<Scalars['Boolean']['input']>;
  createDownloadableArchiveWithLink?: InputMaybe<Scalars['Boolean']['input']>;
  delayStartByDays?: InputMaybe<Scalars['Int']['input']>;
  deliveryFormat?: InputMaybe<DocumentDeliveryFormat>;
  displayAttachmentError?: InputMaybe<Scalars['Boolean']['input']>;
  displayAttachmentInfo?: InputMaybe<Scalars['Boolean']['input']>;
  displayDescription?: InputMaybe<Array<ProcessOptionalStringInput>>;
  displayDescriptionQD?: InputMaybe<Array<ProcessOptionalQuillDeltaInput>>;
  displayName?: InputMaybe<Array<ProcessOptionalStringInput>>;
  doNotAutoFinishTaskInCaseAllRequirementsFullfilled?: InputMaybe<Scalars['Boolean']['input']>;
  doNotCreateOnSync?: InputMaybe<Scalars['Boolean']['input']>;
  documentCheckType?: InputMaybe<DocumentDeliveryFormat>;
  documentTypesToUpload?: InputMaybe<Array<ProcessDocumentRequirementTypeInput>>;
  downloadableAttachmentsLink?: InputMaybe<Scalars['String']['input']>;
  downloadableFiles?: InputMaybe<Array<DownloadableFilestInput>>;
  fieldsToExcludeFromSync?: InputMaybe<Array<Scalars['String']['input']>>;
  finishLabel?: InputMaybe<Array<ProcessOptionalStringInput>>;
  finishingMode?: InputMaybe<DocumentFinishingMode>;
  formToken?: InputMaybe<Scalars['String']['input']>;
  hideGenericTaskComment?: InputMaybe<Scalars['Boolean']['input']>;
  includeDocumentsMarkedAsNotRelevantInRequiredCount?: InputMaybe<Scalars['Boolean']['input']>;
  initialModel?: InputMaybe<Scalars['JSONObject']['input']>;
  instanceState?: InputMaybe<InstanceStateEnum>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  isTaskConfigured?: InputMaybe<Scalars['Boolean']['input']>;
  lastInstanceStateChangedAt?: InputMaybe<Scalars['DateTime']['input']>;
  lastUpdateManualStarted?: InputMaybe<Scalars['Boolean']['input']>;
  lockDocumentsAfterReviewForIds?: InputMaybe<Array<Scalars['String']['input']>>;
  manuallyCreated?: InputMaybe<Scalars['Boolean']['input']>;
  maxFileSize?: InputMaybe<Scalars['Float']['input']>;
  maxFilesRequired?: InputMaybe<Scalars['Float']['input']>;
  maxUploadedDocumentTypesRequired?: InputMaybe<Scalars['Float']['input']>;
  minFilesRequired?: InputMaybe<Scalars['Float']['input']>;
  minUploadedDocumentTypesRequired?: InputMaybe<Scalars['Float']['input']>;
  modelId?: InputMaybe<Scalars['ID']['input']>;
  numberOfAllowedAttachments?: InputMaybe<Scalars['Float']['input']>;
  numberOfReviewers?: InputMaybe<Scalars['Float']['input']>;
  organizationDocuments?: InputMaybe<Array<DocTypeAttachmentInput>>;
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  parentId?: InputMaybe<Scalars['ID']['input']>;
  preConfiguredAllowedDocumentFormats?: InputMaybe<Array<Scalars['String']['input']>>;
  preConfiguredDocumentFormatOverwrite?: InputMaybe<Array<Scalars['String']['input']>>;
  preConfiguredDocumentPurpose?: InputMaybe<DocumentPurpose>;
  preConfiguredDocumentSetName?: InputMaybe<Scalars['String']['input']>;
  preConfiguredFilterForCandidate?: InputMaybe<Scalars['Boolean']['input']>;
  predecessorIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  prefillTaskId?: InputMaybe<Scalars['ID']['input']>;
  previousTaskId?: InputMaybe<Scalars['ID']['input']>;
  processAuditGroupName?: InputMaybe<Scalars['String']['input']>;
  processDeadlineInDays?: InputMaybe<Scalars['Float']['input']>;
  processDocumentsToUpload?: InputMaybe<Array<ProcessDocumentToUploadTypeInput>>;
  processInstanceState?: InputMaybe<ProcessInstanceStateEnum>;
  properties?: InputMaybe<Array<CandidateProfilePropertyInput>>;
  reactivateTasksOnCancel?: InputMaybe<Array<Scalars['ID']['input']>>;
  reactivateTasksOnFinish?: InputMaybe<Array<Scalars['ID']['input']>>;
  reactivatedByTask?: InputMaybe<Scalars['ID']['input']>;
  reactivatedCancelLabel?: InputMaybe<Array<ProcessOptionalStringInput>>;
  reactivatedFinishLabel?: InputMaybe<Array<ProcessOptionalStringInput>>;
  reactivatedRequiresCancelReason?: InputMaybe<Scalars['Boolean']['input']>;
  readonly?: InputMaybe<Scalars['Boolean']['input']>;
  recipientRoles?: InputMaybe<Array<Scalars['String']['input']>>;
  recruitingConceptTaskId?: InputMaybe<Scalars['String']['input']>;
  referenceTaskUploadIds?: InputMaybe<Array<Scalars['String']['input']>>;
  reminderDate?: InputMaybe<Scalars['DateTime']['input']>;
  reminderSettings?: InputMaybe<TaskReminderSettingsInput>;
  replyType?: InputMaybe<ReplyTypeEnum>;
  requestedInformation?: InputMaybe<Array<EnterMissingInformationRequiredInformationInput>>;
  requiredInformation?: InputMaybe<Array<EnterMissingInformation>>;
  requiresCancelReason?: InputMaybe<Scalars['Boolean']['input']>;
  responsibleRoles?: InputMaybe<Array<Scalars['String']['input']>>;
  rootId?: InputMaybe<Scalars['ID']['input']>;
  ruleExecutionError?: InputMaybe<RuleExecutionErrorInput>;
  rules?: InputMaybe<Array<ProcessTaskRuleInput>>;
  selectFinishByDefault?: InputMaybe<Scalars['Boolean']['input']>;
  setupRoles?: InputMaybe<Array<Scalars['String']['input']>>;
  skipOnAlreadyEntered?: InputMaybe<Scalars['Boolean']['input']>;
  targetObject?: InputMaybe<ProcessTargetEnum>;
  targetRoles?: InputMaybe<Array<Scalars['String']['input']>>;
  taskEmails?: InputMaybe<Array<EmailTemplateDescriptionInput>>;
  templateId?: InputMaybe<Scalars['ID']['input']>;
  templateLanguage?: InputMaybe<Scalars['String']['input']>;
  tokens?: InputMaybe<Array<Scalars['String']['input']>>;
  typeName: Scalars['String']['input'];
  usePreConfiguredDocumentTypes?: InputMaybe<Scalars['Boolean']['input']>;
  visibleForRoles?: InputMaybe<Array<Scalars['String']['input']>>;
};

export class ProcessTaskDeactivateProcess implements ProcessTask {
  __typename?: 'ProcessTaskDeactivateProcess';
  _etag?: Maybe<Scalars['String']['output']>;
  activationCondition?: Maybe<Scalars['String']['output']>;
  atLeastOnePredecessorIds?: Maybe<Array<Scalars['ID']['output']>>;
  autoStateChanges?: Maybe<Array<AutoStateChange>>;
  cancelLabel?: Maybe<Array<ProcessOptionalString>>;
  candidateDisplayId?: Maybe<Scalars['String']['output']>;
  candidateDisplayName?: Maybe<Scalars['String']['output']>;
  candidateId?: Maybe<Scalars['ID']['output']>;
  candidateOrganizationId?: Maybe<Scalars['ID']['output']>;
  checkTaskActivationPeriodically?: Maybe<Scalars['Boolean']['output']>;
  delayStartByDays?: Maybe<Scalars['Int']['output']>;
  displayDescription?: Maybe<Array<ProcessOptionalString>>;
  displayDescriptionQD?: Maybe<Array<ProcessOptionalQuillDelta>>;
  displayName?: Maybe<Array<ProcessOptionalString>>;
  doNotCreateOnSync?: Maybe<Scalars['Boolean']['output']>;
  fieldsToExcludeFromSync?: Maybe<Array<Scalars['String']['output']>>;
  finishLabel?: Maybe<Array<ProcessOptionalString>>;
  hideGenericTaskComment?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  instanceState: InstanceStateEnum;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  lastInstanceStateChangedAt?: Maybe<Scalars['DateTime']['output']>;
  lastUpdateManualStarted?: Maybe<Scalars['Boolean']['output']>;
  localizedCancelLabel?: Maybe<Scalars['String']['output']>;
  localizedDisplayDescription?: Maybe<Scalars['String']['output']>;
  localizedDisplayName?: Maybe<Scalars['String']['output']>;
  localizedFinishLabel?: Maybe<Scalars['String']['output']>;
  localizedReactivatedCancelLabel?: Maybe<Scalars['String']['output']>;
  localizedReactivatedFinishLabel?: Maybe<Scalars['String']['output']>;
  localizedRuleExecutionError?: Maybe<Scalars['String']['output']>;
  manuallyCreated?: Maybe<Scalars['Boolean']['output']>;
  modelId: Scalars['ID']['output'];
  organizationId: Scalars['ID']['output'];
  ownerRoles: Array<Scalars['String']['output']>;
  parentId: Scalars['ID']['output'];
  predecessorIds?: Maybe<Array<Scalars['ID']['output']>>;
  previousTaskId?: Maybe<Scalars['ID']['output']>;
  processInstanceState: ProcessInstanceStateEnum;
  reactivateTasksOnCancel?: Maybe<Array<Scalars['ID']['output']>>;
  reactivateTasksOnFinish?: Maybe<Array<Scalars['ID']['output']>>;
  reactivatedByTask?: Maybe<Scalars['ID']['output']>;
  reactivatedCancelLabel?: Maybe<Array<ProcessOptionalString>>;
  reactivatedFinishLabel?: Maybe<Array<ProcessOptionalString>>;
  reactivatedRequiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  readonly?: Maybe<Scalars['Boolean']['output']>;
  reminderSettings?: Maybe<TaskReminderSettings>;
  requiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  responsibleRoles: Array<Scalars['String']['output']>;
  rootId: Scalars['ID']['output'];
  ruleExecutionError?: Maybe<RuleExecutionError>;
  rules?: Maybe<Array<ProcessTaskRule>>;
  selectFinishByDefault?: Maybe<Scalars['Boolean']['output']>;
  sortOrder: Scalars['Float']['output'];
  targetObject: ProcessTargetEnum;
  targetRoles: Array<Scalars['String']['output']>;
  taskEmails?: Maybe<Array<EmailTemplateDescription>>;
  visibleForRoles: Array<Scalars['String']['output']>;
};

export class ProcessTaskDeactivateProcessModel {
  __typename?: 'ProcessTaskDeactivateProcessModel';
  _className: Scalars['String']['output'];
  _typeName: Scalars['String']['output'];
  activationCondition?: Maybe<Scalars['String']['output']>;
  activationConditionBlocklyWorkspace?: Maybe<Scalars['String']['output']>;
  atLeastOnePredecessorIds?: Maybe<Array<Scalars['String']['output']>>;
  autoStateChanges?: Maybe<Array<AutoStateChangeModel>>;
  cancelLabel?: Maybe<Array<ProcessModelString>>;
  checkTaskActivationPeriodically?: Maybe<Scalars['Boolean']['output']>;
  delayStartByDays?: Maybe<Scalars['Int']['output']>;
  displayDescription?: Maybe<Array<ProcessModelString>>;
  displayName: Array<ProcessModelString>;
  doNotCreateOnSync?: Maybe<Scalars['Boolean']['output']>;
  dontSendEmails?: Maybe<Scalars['Boolean']['output']>;
  finishLabel?: Maybe<Array<ProcessModelString>>;
  hideGenericTaskComment?: Maybe<Scalars['Boolean']['output']>;
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  modelId: Scalars['ID']['output'];
  predecessorsIds?: Maybe<Array<Scalars['String']['output']>>;
  reactivateTasksOnCancel?: Maybe<Array<Scalars['String']['output']>>;
  reactivateTasksOnFinish?: Maybe<Array<Scalars['String']['output']>>;
  reactivatedCancelLabel?: Maybe<Array<ProcessModelString>>;
  reactivatedFinishLabel?: Maybe<Array<ProcessModelString>>;
  reactivatedRequiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  reminderSettings?: Maybe<TaskReminderSettings>;
  requiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  responsibleRoles: Array<Scalars['String']['output']>;
  rules?: Maybe<Array<ProcessTaskRule>>;
  selectFinishByDefault?: Maybe<Scalars['Boolean']['output']>;
  targetObject: ProcessTargetEnum;
  targetRoles: Array<Scalars['String']['output']>;
  taskEmails?: Maybe<Array<EmailTemplateDescription>>;
  visibleForRoles: Array<Scalars['String']['output']>;
};

export class ProcessTaskDeactivateProcessModelEntity implements ProcessTaskModelEntity {
  __typename?: 'ProcessTaskDeactivateProcessModelEntity';
  _className: Scalars['String']['output'];
  _etag?: Maybe<Scalars['String']['output']>;
  _typeName: Scalars['String']['output'];
  activationCondition?: Maybe<Scalars['String']['output']>;
  activationConditionBlocklyWorkspace?: Maybe<Scalars['String']['output']>;
  atLeastOnePredecessorIds?: Maybe<Array<Scalars['String']['output']>>;
  autoStateChanges?: Maybe<Array<AutoStateChangeModel>>;
  cancelLabel?: Maybe<Array<ProcessModelString>>;
  checkTaskActivationPeriodically?: Maybe<Scalars['Boolean']['output']>;
  delayStartByDays?: Maybe<Scalars['Int']['output']>;
  displayDescription?: Maybe<Array<ProcessModelString>>;
  displayName: Array<ProcessModelString>;
  doNotCreateOnSync?: Maybe<Scalars['Boolean']['output']>;
  dontSendEmails?: Maybe<Scalars['Boolean']['output']>;
  finishLabel?: Maybe<Array<ProcessModelString>>;
  hideGenericTaskComment?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  modelId: Scalars['ID']['output'];
  organizationId: Scalars['ID']['output'];
  parentId: Scalars['ID']['output'];
  predecessorsIds?: Maybe<Array<Scalars['String']['output']>>;
  reactivateTasksOnCancel?: Maybe<Array<Scalars['String']['output']>>;
  reactivateTasksOnFinish?: Maybe<Array<Scalars['String']['output']>>;
  reactivatedCancelLabel?: Maybe<Array<ProcessModelString>>;
  reactivatedFinishLabel?: Maybe<Array<ProcessModelString>>;
  reactivatedRequiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  reminderSettings?: Maybe<TaskReminderSettings>;
  requiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  responsibleRoles: Array<Scalars['String']['output']>;
  rootId: Scalars['ID']['output'];
  rules?: Maybe<Array<ProcessTaskRule>>;
  selectFinishByDefault?: Maybe<Scalars['Boolean']['output']>;
  sortOrder: Scalars['Float']['output'];
  targetObject: ProcessTargetEnum;
  targetRoles: Array<Scalars['String']['output']>;
  taskEmails?: Maybe<Array<EmailTemplateDescription>>;
  visibleForRoles: Array<Scalars['String']['output']>;
};

export class ProcessTaskDeleteInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  rootId?: InputMaybe<Scalars['ID']['input']>;
};

export class ProcessTaskDocumentCheck implements ProcessTask {
  __typename?: 'ProcessTaskDocumentCheck';
  _etag?: Maybe<Scalars['String']['output']>;
  activationCondition?: Maybe<Scalars['String']['output']>;
  atLeastOnePredecessorIds?: Maybe<Array<Scalars['ID']['output']>>;
  autoStateChanges?: Maybe<Array<AutoStateChange>>;
  cancelLabel?: Maybe<Array<ProcessOptionalString>>;
  candidateDisplayId?: Maybe<Scalars['String']['output']>;
  candidateDisplayName?: Maybe<Scalars['String']['output']>;
  candidateId?: Maybe<Scalars['ID']['output']>;
  candidateOrganizationId?: Maybe<Scalars['ID']['output']>;
  checkTaskActivationPeriodically?: Maybe<Scalars['Boolean']['output']>;
  delayStartByDays?: Maybe<Scalars['Int']['output']>;
  displayDescription?: Maybe<Array<ProcessOptionalString>>;
  displayDescriptionQD?: Maybe<Array<ProcessOptionalQuillDelta>>;
  displayName?: Maybe<Array<ProcessOptionalString>>;
  doNotCreateOnSync?: Maybe<Scalars['Boolean']['output']>;
  documentCheckType: DocumentDeliveryFormat;
  fieldsToExcludeFromSync?: Maybe<Array<Scalars['String']['output']>>;
  finishLabel?: Maybe<Array<ProcessOptionalString>>;
  hideGenericTaskComment?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  instanceState: InstanceStateEnum;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  lastInstanceStateChangedAt?: Maybe<Scalars['DateTime']['output']>;
  lastUpdateManualStarted?: Maybe<Scalars['Boolean']['output']>;
  localizedCancelLabel?: Maybe<Scalars['String']['output']>;
  localizedDisplayDescription?: Maybe<Scalars['String']['output']>;
  localizedDisplayName?: Maybe<Scalars['String']['output']>;
  localizedFinishLabel?: Maybe<Scalars['String']['output']>;
  localizedReactivatedCancelLabel?: Maybe<Scalars['String']['output']>;
  localizedReactivatedFinishLabel?: Maybe<Scalars['String']['output']>;
  localizedRuleExecutionError?: Maybe<Scalars['String']['output']>;
  lockDocumentsAfterReviewForIds?: Maybe<Array<Scalars['String']['output']>>;
  manuallyCreated?: Maybe<Scalars['Boolean']['output']>;
  modelId: Scalars['ID']['output'];
  numberOfReviewers: Scalars['Float']['output'];
  organizationId: Scalars['ID']['output'];
  ownerRoles: Array<Scalars['String']['output']>;
  parentId: Scalars['ID']['output'];
  predecessorIds?: Maybe<Array<Scalars['ID']['output']>>;
  previousTaskId?: Maybe<Scalars['ID']['output']>;
  processInstanceState: ProcessInstanceStateEnum;
  reactivateTasksOnCancel?: Maybe<Array<Scalars['ID']['output']>>;
  reactivateTasksOnFinish?: Maybe<Array<Scalars['ID']['output']>>;
  reactivatedByTask?: Maybe<Scalars['ID']['output']>;
  reactivatedCancelLabel?: Maybe<Array<ProcessOptionalString>>;
  reactivatedFinishLabel?: Maybe<Array<ProcessOptionalString>>;
  reactivatedRequiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  readonly?: Maybe<Scalars['Boolean']['output']>;
  referenceTaskUploadIds: Array<Scalars['String']['output']>;
  reminderSettings?: Maybe<TaskReminderSettings>;
  requiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  responsibleRoles: Array<Scalars['String']['output']>;
  rootId: Scalars['ID']['output'];
  ruleExecutionError?: Maybe<RuleExecutionError>;
  rules?: Maybe<Array<ProcessTaskRule>>;
  selectFinishByDefault?: Maybe<Scalars['Boolean']['output']>;
  sortOrder: Scalars['Float']['output'];
  targetObject: ProcessTargetEnum;
  targetRoles: Array<Scalars['String']['output']>;
  taskEmails?: Maybe<Array<EmailTemplateDescription>>;
  visibleForRoles: Array<Scalars['String']['output']>;
};

export class ProcessTaskDocumentCheckModel {
  __typename?: 'ProcessTaskDocumentCheckModel';
  _className: Scalars['String']['output'];
  _typeName: Scalars['String']['output'];
  activationCondition?: Maybe<Scalars['String']['output']>;
  activationConditionBlocklyWorkspace?: Maybe<Scalars['String']['output']>;
  atLeastOnePredecessorIds?: Maybe<Array<Scalars['String']['output']>>;
  autoStateChanges?: Maybe<Array<AutoStateChangeModel>>;
  cancelLabel?: Maybe<Array<ProcessModelString>>;
  checkTaskActivationPeriodically?: Maybe<Scalars['Boolean']['output']>;
  delayStartByDays?: Maybe<Scalars['Int']['output']>;
  displayDescription?: Maybe<Array<ProcessModelString>>;
  displayName: Array<ProcessModelString>;
  doNotCreateOnSync?: Maybe<Scalars['Boolean']['output']>;
  documentCheckType: DocumentDeliveryFormat;
  dontSendEmails?: Maybe<Scalars['Boolean']['output']>;
  finishLabel?: Maybe<Array<ProcessModelString>>;
  hideGenericTaskComment?: Maybe<Scalars['Boolean']['output']>;
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  lockDocumentsAfterReviewForIds?: Maybe<Array<Scalars['String']['output']>>;
  modelId: Scalars['ID']['output'];
  numberOfReviewers: Scalars['Int']['output'];
  predecessorsIds?: Maybe<Array<Scalars['String']['output']>>;
  reactivateTasksOnCancel?: Maybe<Array<Scalars['String']['output']>>;
  reactivateTasksOnFinish?: Maybe<Array<Scalars['String']['output']>>;
  reactivatedCancelLabel?: Maybe<Array<ProcessModelString>>;
  reactivatedFinishLabel?: Maybe<Array<ProcessModelString>>;
  reactivatedRequiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  referenceTaskUploadModelIds: Array<Scalars['String']['output']>;
  reminderSettings?: Maybe<TaskReminderSettings>;
  requiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  responsibleRoles: Array<Scalars['String']['output']>;
  rules?: Maybe<Array<ProcessTaskRule>>;
  selectFinishByDefault?: Maybe<Scalars['Boolean']['output']>;
  targetObject: ProcessTargetEnum;
  targetRoles: Array<Scalars['String']['output']>;
  taskEmails?: Maybe<Array<EmailTemplateDescription>>;
  visibleForRoles: Array<Scalars['String']['output']>;
};

export class ProcessTaskDocumentCheckModelEntity implements ProcessTaskModelEntity {
  __typename?: 'ProcessTaskDocumentCheckModelEntity';
  _className: Scalars['String']['output'];
  _etag?: Maybe<Scalars['String']['output']>;
  _typeName: Scalars['String']['output'];
  activationCondition?: Maybe<Scalars['String']['output']>;
  activationConditionBlocklyWorkspace?: Maybe<Scalars['String']['output']>;
  atLeastOnePredecessorIds?: Maybe<Array<Scalars['String']['output']>>;
  autoStateChanges?: Maybe<Array<AutoStateChangeModel>>;
  cancelLabel?: Maybe<Array<ProcessModelString>>;
  checkTaskActivationPeriodically?: Maybe<Scalars['Boolean']['output']>;
  delayStartByDays?: Maybe<Scalars['Int']['output']>;
  displayDescription?: Maybe<Array<ProcessModelString>>;
  displayName: Array<ProcessModelString>;
  doNotCreateOnSync?: Maybe<Scalars['Boolean']['output']>;
  documentCheckType: DocumentDeliveryFormat;
  dontSendEmails?: Maybe<Scalars['Boolean']['output']>;
  finishLabel?: Maybe<Array<ProcessModelString>>;
  hideGenericTaskComment?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  lockDocumentsAfterReviewForIds?: Maybe<Array<Scalars['String']['output']>>;
  modelId: Scalars['ID']['output'];
  numberOfReviewers: Scalars['Int']['output'];
  organizationId: Scalars['ID']['output'];
  parentId: Scalars['ID']['output'];
  predecessorsIds?: Maybe<Array<Scalars['String']['output']>>;
  reactivateTasksOnCancel?: Maybe<Array<Scalars['String']['output']>>;
  reactivateTasksOnFinish?: Maybe<Array<Scalars['String']['output']>>;
  reactivatedCancelLabel?: Maybe<Array<ProcessModelString>>;
  reactivatedFinishLabel?: Maybe<Array<ProcessModelString>>;
  reactivatedRequiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  referenceTaskUploadModelIds: Array<Scalars['String']['output']>;
  reminderSettings?: Maybe<TaskReminderSettings>;
  requiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  responsibleRoles: Array<Scalars['String']['output']>;
  rootId: Scalars['ID']['output'];
  rules?: Maybe<Array<ProcessTaskRule>>;
  selectFinishByDefault?: Maybe<Scalars['Boolean']['output']>;
  sortOrder: Scalars['Float']['output'];
  targetObject: ProcessTargetEnum;
  targetRoles: Array<Scalars['String']['output']>;
  taskEmails?: Maybe<Array<EmailTemplateDescription>>;
  visibleForRoles: Array<Scalars['String']['output']>;
};

export class ProcessTaskEmailSend implements ProcessTask {
  __typename?: 'ProcessTaskEmailSend';
  _etag?: Maybe<Scalars['String']['output']>;
  activationCondition?: Maybe<Scalars['String']['output']>;
  atLeastOnePredecessorIds?: Maybe<Array<Scalars['ID']['output']>>;
  autoStateChanges?: Maybe<Array<AutoStateChange>>;
  cancelLabel?: Maybe<Array<ProcessOptionalString>>;
  candidateDisplayId?: Maybe<Scalars['String']['output']>;
  candidateDisplayName?: Maybe<Scalars['String']['output']>;
  candidateId?: Maybe<Scalars['ID']['output']>;
  candidateOrganizationId?: Maybe<Scalars['ID']['output']>;
  checkTaskActivationPeriodically?: Maybe<Scalars['Boolean']['output']>;
  delayStartByDays?: Maybe<Scalars['Int']['output']>;
  displayDescription?: Maybe<Array<ProcessOptionalString>>;
  displayDescriptionQD?: Maybe<Array<ProcessOptionalQuillDelta>>;
  displayName?: Maybe<Array<ProcessOptionalString>>;
  doNotCreateOnSync?: Maybe<Scalars['Boolean']['output']>;
  fieldsToExcludeFromSync?: Maybe<Array<Scalars['String']['output']>>;
  finishLabel?: Maybe<Array<ProcessOptionalString>>;
  hideGenericTaskComment?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  instanceState: InstanceStateEnum;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  lastInstanceStateChangedAt?: Maybe<Scalars['DateTime']['output']>;
  lastUpdateManualStarted?: Maybe<Scalars['Boolean']['output']>;
  localizedCancelLabel?: Maybe<Scalars['String']['output']>;
  localizedDisplayDescription?: Maybe<Scalars['String']['output']>;
  localizedDisplayName?: Maybe<Scalars['String']['output']>;
  localizedFinishLabel?: Maybe<Scalars['String']['output']>;
  localizedReactivatedCancelLabel?: Maybe<Scalars['String']['output']>;
  localizedReactivatedFinishLabel?: Maybe<Scalars['String']['output']>;
  localizedRuleExecutionError?: Maybe<Scalars['String']['output']>;
  manuallyCreated?: Maybe<Scalars['Boolean']['output']>;
  modelId: Scalars['ID']['output'];
  organizationId: Scalars['ID']['output'];
  ownerRoles: Array<Scalars['String']['output']>;
  parentId: Scalars['ID']['output'];
  predecessorIds?: Maybe<Array<Scalars['ID']['output']>>;
  previousTaskId?: Maybe<Scalars['ID']['output']>;
  processInstanceState: ProcessInstanceStateEnum;
  reactivateTasksOnCancel?: Maybe<Array<Scalars['ID']['output']>>;
  reactivateTasksOnFinish?: Maybe<Array<Scalars['ID']['output']>>;
  reactivatedByTask?: Maybe<Scalars['ID']['output']>;
  reactivatedCancelLabel?: Maybe<Array<ProcessOptionalString>>;
  reactivatedFinishLabel?: Maybe<Array<ProcessOptionalString>>;
  reactivatedRequiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  readonly?: Maybe<Scalars['Boolean']['output']>;
  recipientRoles?: Maybe<Array<Scalars['String']['output']>>;
  reminderSettings?: Maybe<TaskReminderSettings>;
  requiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  responsibleRoles: Array<Scalars['String']['output']>;
  rootId: Scalars['ID']['output'];
  ruleExecutionError?: Maybe<RuleExecutionError>;
  rules?: Maybe<Array<ProcessTaskRule>>;
  selectFinishByDefault?: Maybe<Scalars['Boolean']['output']>;
  sortOrder: Scalars['Float']['output'];
  targetObject: ProcessTargetEnum;
  targetRoles: Array<Scalars['String']['output']>;
  taskEmails?: Maybe<Array<EmailTemplateDescription>>;
  templateId?: Maybe<Scalars['ID']['output']>;
  templateLanguage?: Maybe<Scalars['String']['output']>;
  visibleForRoles: Array<Scalars['String']['output']>;
};

export class ProcessTaskEmailSendModel {
  __typename?: 'ProcessTaskEmailSendModel';
  _className: Scalars['String']['output'];
  _typeName: Scalars['String']['output'];
  activationCondition?: Maybe<Scalars['String']['output']>;
  activationConditionBlocklyWorkspace?: Maybe<Scalars['String']['output']>;
  atLeastOnePredecessorIds?: Maybe<Array<Scalars['String']['output']>>;
  autoStateChanges?: Maybe<Array<AutoStateChangeModel>>;
  cancelLabel?: Maybe<Array<ProcessModelString>>;
  checkTaskActivationPeriodically?: Maybe<Scalars['Boolean']['output']>;
  delayStartByDays?: Maybe<Scalars['Int']['output']>;
  displayDescription?: Maybe<Array<ProcessModelString>>;
  displayName: Array<ProcessModelString>;
  doNotCreateOnSync?: Maybe<Scalars['Boolean']['output']>;
  dontSendEmails?: Maybe<Scalars['Boolean']['output']>;
  finishLabel?: Maybe<Array<ProcessModelString>>;
  hideGenericTaskComment?: Maybe<Scalars['Boolean']['output']>;
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  modelId: Scalars['ID']['output'];
  predecessorsIds?: Maybe<Array<Scalars['String']['output']>>;
  reactivateTasksOnCancel?: Maybe<Array<Scalars['String']['output']>>;
  reactivateTasksOnFinish?: Maybe<Array<Scalars['String']['output']>>;
  reactivatedCancelLabel?: Maybe<Array<ProcessModelString>>;
  reactivatedFinishLabel?: Maybe<Array<ProcessModelString>>;
  reactivatedRequiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  recipientRoles?: Maybe<Array<Scalars['String']['output']>>;
  reminderSettings?: Maybe<TaskReminderSettings>;
  requiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  responsibleRoles: Array<Scalars['String']['output']>;
  rules?: Maybe<Array<ProcessTaskRule>>;
  selectFinishByDefault?: Maybe<Scalars['Boolean']['output']>;
  targetObject: ProcessTargetEnum;
  targetRoles: Array<Scalars['String']['output']>;
  taskEmails?: Maybe<Array<EmailTemplateDescription>>;
  templateLanguage?: Maybe<Scalars['String']['output']>;
  templateName?: Maybe<Scalars['String']['output']>;
  visibleForRoles: Array<Scalars['String']['output']>;
};

export class ProcessTaskEmailSendModelEntity implements ProcessTaskModelEntity {
  __typename?: 'ProcessTaskEmailSendModelEntity';
  _className: Scalars['String']['output'];
  _etag?: Maybe<Scalars['String']['output']>;
  _typeName: Scalars['String']['output'];
  activationCondition?: Maybe<Scalars['String']['output']>;
  activationConditionBlocklyWorkspace?: Maybe<Scalars['String']['output']>;
  atLeastOnePredecessorIds?: Maybe<Array<Scalars['String']['output']>>;
  autoStateChanges?: Maybe<Array<AutoStateChangeModel>>;
  cancelLabel?: Maybe<Array<ProcessModelString>>;
  checkTaskActivationPeriodically?: Maybe<Scalars['Boolean']['output']>;
  delayStartByDays?: Maybe<Scalars['Int']['output']>;
  displayDescription?: Maybe<Array<ProcessModelString>>;
  displayName: Array<ProcessModelString>;
  doNotCreateOnSync?: Maybe<Scalars['Boolean']['output']>;
  dontSendEmails?: Maybe<Scalars['Boolean']['output']>;
  finishLabel?: Maybe<Array<ProcessModelString>>;
  hideGenericTaskComment?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  modelId: Scalars['ID']['output'];
  organizationId: Scalars['ID']['output'];
  parentId: Scalars['ID']['output'];
  predecessorsIds?: Maybe<Array<Scalars['String']['output']>>;
  reactivateTasksOnCancel?: Maybe<Array<Scalars['String']['output']>>;
  reactivateTasksOnFinish?: Maybe<Array<Scalars['String']['output']>>;
  reactivatedCancelLabel?: Maybe<Array<ProcessModelString>>;
  reactivatedFinishLabel?: Maybe<Array<ProcessModelString>>;
  reactivatedRequiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  recipientRoles?: Maybe<Array<Scalars['String']['output']>>;
  reminderSettings?: Maybe<TaskReminderSettings>;
  requiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  responsibleRoles: Array<Scalars['String']['output']>;
  rootId: Scalars['ID']['output'];
  rules?: Maybe<Array<ProcessTaskRule>>;
  selectFinishByDefault?: Maybe<Scalars['Boolean']['output']>;
  sortOrder: Scalars['Float']['output'];
  targetObject: ProcessTargetEnum;
  targetRoles: Array<Scalars['String']['output']>;
  taskEmails?: Maybe<Array<EmailTemplateDescription>>;
  templateLanguage?: Maybe<Scalars['String']['output']>;
  templateName?: Maybe<Scalars['String']['output']>;
  visibleForRoles: Array<Scalars['String']['output']>;
};

export class ProcessTaskEnterMissingInformation implements ProcessTask {
  __typename?: 'ProcessTaskEnterMissingInformation';
  _etag?: Maybe<Scalars['String']['output']>;
  activationCondition?: Maybe<Scalars['String']['output']>;
  atLeastOnePredecessorIds?: Maybe<Array<Scalars['ID']['output']>>;
  autoStateChanges?: Maybe<Array<AutoStateChange>>;
  cancelLabel?: Maybe<Array<ProcessOptionalString>>;
  candidateDisplayId?: Maybe<Scalars['String']['output']>;
  candidateDisplayName?: Maybe<Scalars['String']['output']>;
  candidateId?: Maybe<Scalars['ID']['output']>;
  candidateOrganizationId?: Maybe<Scalars['ID']['output']>;
  checkTaskActivationPeriodically?: Maybe<Scalars['Boolean']['output']>;
  delayStartByDays?: Maybe<Scalars['Int']['output']>;
  displayDescription?: Maybe<Array<ProcessOptionalString>>;
  displayDescriptionQD?: Maybe<Array<ProcessOptionalQuillDelta>>;
  displayName?: Maybe<Array<ProcessOptionalString>>;
  doNotCreateOnSync?: Maybe<Scalars['Boolean']['output']>;
  fieldsToExcludeFromSync?: Maybe<Array<Scalars['String']['output']>>;
  finishLabel?: Maybe<Array<ProcessOptionalString>>;
  hideGenericTaskComment?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  instanceState: InstanceStateEnum;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  lastInstanceStateChangedAt?: Maybe<Scalars['DateTime']['output']>;
  lastUpdateManualStarted?: Maybe<Scalars['Boolean']['output']>;
  localizedCancelLabel?: Maybe<Scalars['String']['output']>;
  localizedDisplayDescription?: Maybe<Scalars['String']['output']>;
  localizedDisplayName?: Maybe<Scalars['String']['output']>;
  localizedFinishLabel?: Maybe<Scalars['String']['output']>;
  localizedReactivatedCancelLabel?: Maybe<Scalars['String']['output']>;
  localizedReactivatedFinishLabel?: Maybe<Scalars['String']['output']>;
  localizedRuleExecutionError?: Maybe<Scalars['String']['output']>;
  manuallyCreated?: Maybe<Scalars['Boolean']['output']>;
  modelId: Scalars['ID']['output'];
  organizationId: Scalars['ID']['output'];
  ownerRoles: Array<Scalars['String']['output']>;
  parentId: Scalars['ID']['output'];
  predecessorIds?: Maybe<Array<Scalars['ID']['output']>>;
  previousTaskId?: Maybe<Scalars['ID']['output']>;
  processInstanceState: ProcessInstanceStateEnum;
  reactivateTasksOnCancel?: Maybe<Array<Scalars['ID']['output']>>;
  reactivateTasksOnFinish?: Maybe<Array<Scalars['ID']['output']>>;
  reactivatedByTask?: Maybe<Scalars['ID']['output']>;
  reactivatedCancelLabel?: Maybe<Array<ProcessOptionalString>>;
  reactivatedFinishLabel?: Maybe<Array<ProcessOptionalString>>;
  reactivatedRequiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  readonly?: Maybe<Scalars['Boolean']['output']>;
  reminderSettings?: Maybe<TaskReminderSettings>;
  requiredInformation: Array<EnterMissingInformation>;
  requiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  responsibleRoles: Array<Scalars['String']['output']>;
  rootId: Scalars['ID']['output'];
  ruleExecutionError?: Maybe<RuleExecutionError>;
  rules?: Maybe<Array<ProcessTaskRule>>;
  selectFinishByDefault?: Maybe<Scalars['Boolean']['output']>;
  skipOnAlreadyEntered?: Maybe<Scalars['Boolean']['output']>;
  sortOrder: Scalars['Float']['output'];
  targetObject: ProcessTargetEnum;
  targetRoles: Array<Scalars['String']['output']>;
  taskEmails?: Maybe<Array<EmailTemplateDescription>>;
  visibleForRoles: Array<Scalars['String']['output']>;
};

export class ProcessTaskEnterMissingInformationModel {
  __typename?: 'ProcessTaskEnterMissingInformationModel';
  _className: Scalars['String']['output'];
  _typeName: Scalars['String']['output'];
  activationCondition?: Maybe<Scalars['String']['output']>;
  activationConditionBlocklyWorkspace?: Maybe<Scalars['String']['output']>;
  atLeastOnePredecessorIds?: Maybe<Array<Scalars['String']['output']>>;
  autoStateChanges?: Maybe<Array<AutoStateChangeModel>>;
  cancelLabel?: Maybe<Array<ProcessModelString>>;
  checkTaskActivationPeriodically?: Maybe<Scalars['Boolean']['output']>;
  delayStartByDays?: Maybe<Scalars['Int']['output']>;
  displayDescription?: Maybe<Array<ProcessModelString>>;
  displayName: Array<ProcessModelString>;
  doNotCreateOnSync?: Maybe<Scalars['Boolean']['output']>;
  dontSendEmails?: Maybe<Scalars['Boolean']['output']>;
  finishLabel?: Maybe<Array<ProcessModelString>>;
  hideGenericTaskComment?: Maybe<Scalars['Boolean']['output']>;
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  modelId: Scalars['ID']['output'];
  predecessorsIds?: Maybe<Array<Scalars['String']['output']>>;
  reactivateTasksOnCancel?: Maybe<Array<Scalars['String']['output']>>;
  reactivateTasksOnFinish?: Maybe<Array<Scalars['String']['output']>>;
  reactivatedCancelLabel?: Maybe<Array<ProcessModelString>>;
  reactivatedFinishLabel?: Maybe<Array<ProcessModelString>>;
  reactivatedRequiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  reminderSettings?: Maybe<TaskReminderSettings>;
  requiredInformation: Array<EnterMissingInformation>;
  requiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  responsibleRoles: Array<Scalars['String']['output']>;
  rules?: Maybe<Array<ProcessTaskRule>>;
  selectFinishByDefault?: Maybe<Scalars['Boolean']['output']>;
  skipOnAlreadyEntered?: Maybe<Scalars['Boolean']['output']>;
  targetObject: ProcessTargetEnum;
  targetRoles: Array<Scalars['String']['output']>;
  taskEmails?: Maybe<Array<EmailTemplateDescription>>;
  visibleForRoles: Array<Scalars['String']['output']>;
};

export class ProcessTaskEnterMissingInformationModelEntity implements ProcessTaskModelEntity {
  __typename?: 'ProcessTaskEnterMissingInformationModelEntity';
  _className: Scalars['String']['output'];
  _etag?: Maybe<Scalars['String']['output']>;
  _typeName: Scalars['String']['output'];
  activationCondition?: Maybe<Scalars['String']['output']>;
  activationConditionBlocklyWorkspace?: Maybe<Scalars['String']['output']>;
  atLeastOnePredecessorIds?: Maybe<Array<Scalars['String']['output']>>;
  autoStateChanges?: Maybe<Array<AutoStateChangeModel>>;
  cancelLabel?: Maybe<Array<ProcessModelString>>;
  checkTaskActivationPeriodically?: Maybe<Scalars['Boolean']['output']>;
  delayStartByDays?: Maybe<Scalars['Int']['output']>;
  displayDescription?: Maybe<Array<ProcessModelString>>;
  displayName: Array<ProcessModelString>;
  doNotCreateOnSync?: Maybe<Scalars['Boolean']['output']>;
  dontSendEmails?: Maybe<Scalars['Boolean']['output']>;
  finishLabel?: Maybe<Array<ProcessModelString>>;
  hideGenericTaskComment?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  modelId: Scalars['ID']['output'];
  organizationId: Scalars['ID']['output'];
  parentId: Scalars['ID']['output'];
  predecessorsIds?: Maybe<Array<Scalars['String']['output']>>;
  reactivateTasksOnCancel?: Maybe<Array<Scalars['String']['output']>>;
  reactivateTasksOnFinish?: Maybe<Array<Scalars['String']['output']>>;
  reactivatedCancelLabel?: Maybe<Array<ProcessModelString>>;
  reactivatedFinishLabel?: Maybe<Array<ProcessModelString>>;
  reactivatedRequiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  reminderSettings?: Maybe<TaskReminderSettings>;
  requiredInformation: Array<EnterMissingInformation>;
  requiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  responsibleRoles: Array<Scalars['String']['output']>;
  rootId: Scalars['ID']['output'];
  rules?: Maybe<Array<ProcessTaskRule>>;
  selectFinishByDefault?: Maybe<Scalars['Boolean']['output']>;
  skipOnAlreadyEntered?: Maybe<Scalars['Boolean']['output']>;
  sortOrder: Scalars['Float']['output'];
  targetObject: ProcessTargetEnum;
  targetRoles: Array<Scalars['String']['output']>;
  taskEmails?: Maybe<Array<EmailTemplateDescription>>;
  visibleForRoles: Array<Scalars['String']['output']>;
};

export class ProcessTaskEnterMissingInformationV2 implements ProcessTask {
  __typename?: 'ProcessTaskEnterMissingInformationV2';
  _etag?: Maybe<Scalars['String']['output']>;
  activationCondition?: Maybe<Scalars['String']['output']>;
  atLeastOnePredecessorIds?: Maybe<Array<Scalars['ID']['output']>>;
  autoStateChanges?: Maybe<Array<AutoStateChange>>;
  cancelLabel?: Maybe<Array<ProcessOptionalString>>;
  candidateDisplayId?: Maybe<Scalars['String']['output']>;
  candidateDisplayName?: Maybe<Scalars['String']['output']>;
  candidateId?: Maybe<Scalars['ID']['output']>;
  candidateOrganizationId?: Maybe<Scalars['ID']['output']>;
  checkTaskActivationPeriodically?: Maybe<Scalars['Boolean']['output']>;
  delayStartByDays?: Maybe<Scalars['Int']['output']>;
  displayDescription?: Maybe<Array<ProcessOptionalString>>;
  displayDescriptionQD?: Maybe<Array<ProcessOptionalQuillDelta>>;
  displayName?: Maybe<Array<ProcessOptionalString>>;
  doNotCreateOnSync?: Maybe<Scalars['Boolean']['output']>;
  fieldsToExcludeFromSync?: Maybe<Array<Scalars['String']['output']>>;
  finishLabel?: Maybe<Array<ProcessOptionalString>>;
  hideGenericTaskComment?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  instanceState: InstanceStateEnum;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  lastInstanceStateChangedAt?: Maybe<Scalars['DateTime']['output']>;
  lastUpdateManualStarted?: Maybe<Scalars['Boolean']['output']>;
  localizedCancelLabel?: Maybe<Scalars['String']['output']>;
  localizedDisplayDescription?: Maybe<Scalars['String']['output']>;
  localizedDisplayName?: Maybe<Scalars['String']['output']>;
  localizedFinishLabel?: Maybe<Scalars['String']['output']>;
  localizedReactivatedCancelLabel?: Maybe<Scalars['String']['output']>;
  localizedReactivatedFinishLabel?: Maybe<Scalars['String']['output']>;
  localizedRuleExecutionError?: Maybe<Scalars['String']['output']>;
  manuallyCreated?: Maybe<Scalars['Boolean']['output']>;
  modelId: Scalars['ID']['output'];
  organizationId: Scalars['ID']['output'];
  ownerRoles: Array<Scalars['String']['output']>;
  parentId: Scalars['ID']['output'];
  predecessorIds?: Maybe<Array<Scalars['ID']['output']>>;
  previousTaskId?: Maybe<Scalars['ID']['output']>;
  processInstanceState: ProcessInstanceStateEnum;
  reactivateTasksOnCancel?: Maybe<Array<Scalars['ID']['output']>>;
  reactivateTasksOnFinish?: Maybe<Array<Scalars['ID']['output']>>;
  reactivatedByTask?: Maybe<Scalars['ID']['output']>;
  reactivatedCancelLabel?: Maybe<Array<ProcessOptionalString>>;
  reactivatedFinishLabel?: Maybe<Array<ProcessOptionalString>>;
  reactivatedRequiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  readonly?: Maybe<Scalars['Boolean']['output']>;
  reminderSettings?: Maybe<TaskReminderSettings>;
  requestedInformation?: Maybe<Array<EnterMissingInformationRequestedInformation>>;
  requiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  responsibleRoles: Array<Scalars['String']['output']>;
  rootId: Scalars['ID']['output'];
  ruleExecutionError?: Maybe<RuleExecutionError>;
  rules?: Maybe<Array<ProcessTaskRule>>;
  selectFinishByDefault?: Maybe<Scalars['Boolean']['output']>;
  skipOnAlreadyEntered?: Maybe<Scalars['Boolean']['output']>;
  sortOrder: Scalars['Float']['output'];
  targetObject: ProcessTargetEnum;
  targetRoles: Array<Scalars['String']['output']>;
  taskEmails?: Maybe<Array<EmailTemplateDescription>>;
  visibleForRoles: Array<Scalars['String']['output']>;
};

export class ProcessTaskEnterMissingInformationV2Model {
  __typename?: 'ProcessTaskEnterMissingInformationV2Model';
  _className: Scalars['String']['output'];
  _typeName: Scalars['String']['output'];
  activationCondition?: Maybe<Scalars['String']['output']>;
  activationConditionBlocklyWorkspace?: Maybe<Scalars['String']['output']>;
  atLeastOnePredecessorIds?: Maybe<Array<Scalars['String']['output']>>;
  autoStateChanges?: Maybe<Array<AutoStateChangeModel>>;
  cancelLabel?: Maybe<Array<ProcessModelString>>;
  checkTaskActivationPeriodically?: Maybe<Scalars['Boolean']['output']>;
  delayStartByDays?: Maybe<Scalars['Int']['output']>;
  displayDescription?: Maybe<Array<ProcessModelString>>;
  displayName: Array<ProcessModelString>;
  doNotCreateOnSync?: Maybe<Scalars['Boolean']['output']>;
  dontSendEmails?: Maybe<Scalars['Boolean']['output']>;
  finishLabel?: Maybe<Array<ProcessModelString>>;
  hideGenericTaskComment?: Maybe<Scalars['Boolean']['output']>;
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  modelId: Scalars['ID']['output'];
  predecessorsIds?: Maybe<Array<Scalars['String']['output']>>;
  reactivateTasksOnCancel?: Maybe<Array<Scalars['String']['output']>>;
  reactivateTasksOnFinish?: Maybe<Array<Scalars['String']['output']>>;
  reactivatedCancelLabel?: Maybe<Array<ProcessModelString>>;
  reactivatedFinishLabel?: Maybe<Array<ProcessModelString>>;
  reactivatedRequiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  reminderSettings?: Maybe<TaskReminderSettings>;
  requestedInformation?: Maybe<Array<EnterMissingInformationRequestedInformation>>;
  requiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  responsibleRoles: Array<Scalars['String']['output']>;
  rules?: Maybe<Array<ProcessTaskRule>>;
  selectFinishByDefault?: Maybe<Scalars['Boolean']['output']>;
  skipOnAlreadyEntered?: Maybe<Scalars['Boolean']['output']>;
  targetObject: ProcessTargetEnum;
  targetRoles: Array<Scalars['String']['output']>;
  taskEmails?: Maybe<Array<EmailTemplateDescription>>;
  visibleForRoles: Array<Scalars['String']['output']>;
};

export class ProcessTaskEnterMissingInformationV2ModelEntity implements ProcessTaskModelEntity {
  __typename?: 'ProcessTaskEnterMissingInformationV2ModelEntity';
  _className: Scalars['String']['output'];
  _etag?: Maybe<Scalars['String']['output']>;
  _typeName: Scalars['String']['output'];
  activationCondition?: Maybe<Scalars['String']['output']>;
  activationConditionBlocklyWorkspace?: Maybe<Scalars['String']['output']>;
  atLeastOnePredecessorIds?: Maybe<Array<Scalars['String']['output']>>;
  autoStateChanges?: Maybe<Array<AutoStateChangeModel>>;
  cancelLabel?: Maybe<Array<ProcessModelString>>;
  checkTaskActivationPeriodically?: Maybe<Scalars['Boolean']['output']>;
  delayStartByDays?: Maybe<Scalars['Int']['output']>;
  displayDescription?: Maybe<Array<ProcessModelString>>;
  displayName: Array<ProcessModelString>;
  doNotCreateOnSync?: Maybe<Scalars['Boolean']['output']>;
  dontSendEmails?: Maybe<Scalars['Boolean']['output']>;
  finishLabel?: Maybe<Array<ProcessModelString>>;
  hideGenericTaskComment?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  modelId: Scalars['ID']['output'];
  organizationId: Scalars['ID']['output'];
  parentId: Scalars['ID']['output'];
  predecessorsIds?: Maybe<Array<Scalars['String']['output']>>;
  reactivateTasksOnCancel?: Maybe<Array<Scalars['String']['output']>>;
  reactivateTasksOnFinish?: Maybe<Array<Scalars['String']['output']>>;
  reactivatedCancelLabel?: Maybe<Array<ProcessModelString>>;
  reactivatedFinishLabel?: Maybe<Array<ProcessModelString>>;
  reactivatedRequiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  reminderSettings?: Maybe<TaskReminderSettings>;
  requestedInformation?: Maybe<Array<EnterMissingInformationRequestedInformation>>;
  requiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  responsibleRoles: Array<Scalars['String']['output']>;
  rootId: Scalars['ID']['output'];
  rules?: Maybe<Array<ProcessTaskRule>>;
  selectFinishByDefault?: Maybe<Scalars['Boolean']['output']>;
  skipOnAlreadyEntered?: Maybe<Scalars['Boolean']['output']>;
  sortOrder: Scalars['Float']['output'];
  targetObject: ProcessTargetEnum;
  targetRoles: Array<Scalars['String']['output']>;
  taskEmails?: Maybe<Array<EmailTemplateDescription>>;
  visibleForRoles: Array<Scalars['String']['output']>;
};

export class ProcessTaskInformationCollection implements ProcessTask {
  __typename?: 'ProcessTaskInformationCollection';
  _etag?: Maybe<Scalars['String']['output']>;
  activationCondition?: Maybe<Scalars['String']['output']>;
  atLeastOnePredecessorIds?: Maybe<Array<Scalars['ID']['output']>>;
  autoStateChanges?: Maybe<Array<AutoStateChange>>;
  cancelLabel?: Maybe<Array<ProcessOptionalString>>;
  candidateDisplayId?: Maybe<Scalars['String']['output']>;
  candidateDisplayName?: Maybe<Scalars['String']['output']>;
  candidateId?: Maybe<Scalars['ID']['output']>;
  candidateOrganizationId?: Maybe<Scalars['ID']['output']>;
  checkTaskActivationPeriodically?: Maybe<Scalars['Boolean']['output']>;
  confirmationMode?: Maybe<Scalars['Boolean']['output']>;
  delayStartByDays?: Maybe<Scalars['Int']['output']>;
  displayDescription?: Maybe<Array<ProcessOptionalString>>;
  displayDescriptionQD?: Maybe<Array<ProcessOptionalQuillDelta>>;
  displayName?: Maybe<Array<ProcessOptionalString>>;
  doNotCreateOnSync?: Maybe<Scalars['Boolean']['output']>;
  fieldsToExcludeFromSync?: Maybe<Array<Scalars['String']['output']>>;
  finishLabel?: Maybe<Array<ProcessOptionalString>>;
  formToken: Scalars['String']['output'];
  hideGenericTaskComment?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  initialModel?: Maybe<Scalars['JSONObject']['output']>;
  instanceState: InstanceStateEnum;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  lastInstanceStateChangedAt?: Maybe<Scalars['DateTime']['output']>;
  lastUpdateManualStarted?: Maybe<Scalars['Boolean']['output']>;
  localizedCancelLabel?: Maybe<Scalars['String']['output']>;
  localizedDisplayDescription?: Maybe<Scalars['String']['output']>;
  localizedDisplayName?: Maybe<Scalars['String']['output']>;
  localizedFinishLabel?: Maybe<Scalars['String']['output']>;
  localizedReactivatedCancelLabel?: Maybe<Scalars['String']['output']>;
  localizedReactivatedFinishLabel?: Maybe<Scalars['String']['output']>;
  localizedRuleExecutionError?: Maybe<Scalars['String']['output']>;
  manuallyCreated?: Maybe<Scalars['Boolean']['output']>;
  modelId: Scalars['ID']['output'];
  organizationId: Scalars['ID']['output'];
  ownerRoles: Array<Scalars['String']['output']>;
  parentId: Scalars['ID']['output'];
  predecessorIds?: Maybe<Array<Scalars['ID']['output']>>;
  prefillTaskId?: Maybe<Scalars['ID']['output']>;
  previousTaskId?: Maybe<Scalars['ID']['output']>;
  processInstanceState: ProcessInstanceStateEnum;
  reactivateTasksOnCancel?: Maybe<Array<Scalars['ID']['output']>>;
  reactivateTasksOnFinish?: Maybe<Array<Scalars['ID']['output']>>;
  reactivatedByTask?: Maybe<Scalars['ID']['output']>;
  reactivatedCancelLabel?: Maybe<Array<ProcessOptionalString>>;
  reactivatedFinishLabel?: Maybe<Array<ProcessOptionalString>>;
  reactivatedRequiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  readonly?: Maybe<Scalars['Boolean']['output']>;
  reminderSettings?: Maybe<TaskReminderSettings>;
  requiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  responsibleRoles: Array<Scalars['String']['output']>;
  rootId: Scalars['ID']['output'];
  ruleExecutionError?: Maybe<RuleExecutionError>;
  rules?: Maybe<Array<ProcessTaskRule>>;
  selectFinishByDefault?: Maybe<Scalars['Boolean']['output']>;
  sortOrder: Scalars['Float']['output'];
  targetObject: ProcessTargetEnum;
  targetRoles: Array<Scalars['String']['output']>;
  taskEmails?: Maybe<Array<EmailTemplateDescription>>;
  visibleForRoles: Array<Scalars['String']['output']>;
};

export class ProcessTaskInformationCollectionModel {
  __typename?: 'ProcessTaskInformationCollectionModel';
  _className: Scalars['String']['output'];
  _typeName: Scalars['String']['output'];
  activationCondition?: Maybe<Scalars['String']['output']>;
  activationConditionBlocklyWorkspace?: Maybe<Scalars['String']['output']>;
  atLeastOnePredecessorIds?: Maybe<Array<Scalars['String']['output']>>;
  autoStateChanges?: Maybe<Array<AutoStateChangeModel>>;
  cancelLabel?: Maybe<Array<ProcessModelString>>;
  checkTaskActivationPeriodically?: Maybe<Scalars['Boolean']['output']>;
  confirmationMode?: Maybe<Scalars['Boolean']['output']>;
  delayStartByDays?: Maybe<Scalars['Int']['output']>;
  displayDescription?: Maybe<Array<ProcessModelString>>;
  displayName: Array<ProcessModelString>;
  doNotCreateOnSync?: Maybe<Scalars['Boolean']['output']>;
  dontSendEmails?: Maybe<Scalars['Boolean']['output']>;
  finishLabel?: Maybe<Array<ProcessModelString>>;
  formToken: KnownFormToken;
  hideGenericTaskComment?: Maybe<Scalars['Boolean']['output']>;
  initialModel?: Maybe<Scalars['JSONObject']['output']>;
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  modelId: Scalars['ID']['output'];
  predecessorsIds?: Maybe<Array<Scalars['String']['output']>>;
  prefillTaskId?: Maybe<Scalars['String']['output']>;
  reactivateTasksOnCancel?: Maybe<Array<Scalars['String']['output']>>;
  reactivateTasksOnFinish?: Maybe<Array<Scalars['String']['output']>>;
  reactivatedCancelLabel?: Maybe<Array<ProcessModelString>>;
  reactivatedFinishLabel?: Maybe<Array<ProcessModelString>>;
  reactivatedRequiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  reminderSettings?: Maybe<TaskReminderSettings>;
  requiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  responsibleRoles: Array<Scalars['String']['output']>;
  rules?: Maybe<Array<ProcessTaskRule>>;
  selectFinishByDefault?: Maybe<Scalars['Boolean']['output']>;
  targetObject: ProcessTargetEnum;
  targetRoles: Array<Scalars['String']['output']>;
  taskEmails?: Maybe<Array<EmailTemplateDescription>>;
  visibleForRoles: Array<Scalars['String']['output']>;
};

export class ProcessTaskInformationCollectionModelEntity implements ProcessTaskModelEntity {
  __typename?: 'ProcessTaskInformationCollectionModelEntity';
  _className: Scalars['String']['output'];
  _etag?: Maybe<Scalars['String']['output']>;
  _typeName: Scalars['String']['output'];
  activationCondition?: Maybe<Scalars['String']['output']>;
  activationConditionBlocklyWorkspace?: Maybe<Scalars['String']['output']>;
  atLeastOnePredecessorIds?: Maybe<Array<Scalars['String']['output']>>;
  autoStateChanges?: Maybe<Array<AutoStateChangeModel>>;
  cancelLabel?: Maybe<Array<ProcessModelString>>;
  checkTaskActivationPeriodically?: Maybe<Scalars['Boolean']['output']>;
  confirmationMode?: Maybe<Scalars['Boolean']['output']>;
  delayStartByDays?: Maybe<Scalars['Int']['output']>;
  displayDescription?: Maybe<Array<ProcessModelString>>;
  displayName: Array<ProcessModelString>;
  doNotCreateOnSync?: Maybe<Scalars['Boolean']['output']>;
  dontSendEmails?: Maybe<Scalars['Boolean']['output']>;
  finishLabel?: Maybe<Array<ProcessModelString>>;
  formToken: KnownFormToken;
  hideGenericTaskComment?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  initialModel?: Maybe<Scalars['JSONObject']['output']>;
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  modelId: Scalars['ID']['output'];
  organizationId: Scalars['ID']['output'];
  parentId: Scalars['ID']['output'];
  predecessorsIds?: Maybe<Array<Scalars['String']['output']>>;
  prefillTaskId?: Maybe<Scalars['String']['output']>;
  reactivateTasksOnCancel?: Maybe<Array<Scalars['String']['output']>>;
  reactivateTasksOnFinish?: Maybe<Array<Scalars['String']['output']>>;
  reactivatedCancelLabel?: Maybe<Array<ProcessModelString>>;
  reactivatedFinishLabel?: Maybe<Array<ProcessModelString>>;
  reactivatedRequiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  reminderSettings?: Maybe<TaskReminderSettings>;
  requiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  responsibleRoles: Array<Scalars['String']['output']>;
  rootId: Scalars['ID']['output'];
  rules?: Maybe<Array<ProcessTaskRule>>;
  selectFinishByDefault?: Maybe<Scalars['Boolean']['output']>;
  sortOrder: Scalars['Float']['output'];
  targetObject: ProcessTargetEnum;
  targetRoles: Array<Scalars['String']['output']>;
  taskEmails?: Maybe<Array<EmailTemplateDescription>>;
  visibleForRoles: Array<Scalars['String']['output']>;
};

export class ProcessTaskInformationSendOut implements ProcessTask {
  __typename?: 'ProcessTaskInformationSendOut';
  _etag?: Maybe<Scalars['String']['output']>;
  activationCondition?: Maybe<Scalars['String']['output']>;
  atLeastOnePredecessorIds?: Maybe<Array<Scalars['ID']['output']>>;
  attachDownloadableTaskFilesToEmails?: Maybe<Scalars['Boolean']['output']>;
  attachmentError?: Maybe<Scalars['String']['output']>;
  attachmentForms?: Maybe<Array<Scalars['String']['output']>>;
  attachmentTaskIds?: Maybe<Array<Scalars['ID']['output']>>;
  attachmentTemplates?: Maybe<Array<Scalars['String']['output']>>;
  attachmentTemplatesFileType?: Maybe<Array<AttachmentTemplateFileTypeMapping>>;
  attachments?: Maybe<Array<FileAttachment>>;
  attachmentsRequired?: Maybe<Scalars['Boolean']['output']>;
  autoStateChanges?: Maybe<Array<AutoStateChange>>;
  cancelLabel?: Maybe<Array<ProcessOptionalString>>;
  candidateDisplayId?: Maybe<Scalars['String']['output']>;
  candidateDisplayName?: Maybe<Scalars['String']['output']>;
  candidateDocuments?: Maybe<Array<CandidateDocTypeAttachment>>;
  candidateId?: Maybe<Scalars['ID']['output']>;
  candidateOrganizationId?: Maybe<Scalars['ID']['output']>;
  candidateRequiredDocumentSetIds?: Maybe<Array<Scalars['ID']['output']>>;
  checkTaskActivationPeriodically?: Maybe<Scalars['Boolean']['output']>;
  createDownloadableArchiveWithLink?: Maybe<Scalars['Boolean']['output']>;
  delayStartByDays?: Maybe<Scalars['Int']['output']>;
  displayAttachmentError?: Maybe<Scalars['Boolean']['output']>;
  displayAttachmentInfo?: Maybe<Scalars['Boolean']['output']>;
  displayDescription?: Maybe<Array<ProcessOptionalString>>;
  displayDescriptionQD?: Maybe<Array<ProcessOptionalQuillDelta>>;
  displayName?: Maybe<Array<ProcessOptionalString>>;
  doNotCreateOnSync?: Maybe<Scalars['Boolean']['output']>;
  downloadableAttachmentsLink?: Maybe<Scalars['String']['output']>;
  downloadableFiles?: Maybe<Array<DownloadableFile>>;
  fieldsToExcludeFromSync?: Maybe<Array<Scalars['String']['output']>>;
  finishLabel?: Maybe<Array<ProcessOptionalString>>;
  hideGenericTaskComment?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  instanceState: InstanceStateEnum;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  lastInstanceStateChangedAt?: Maybe<Scalars['DateTime']['output']>;
  lastUpdateManualStarted?: Maybe<Scalars['Boolean']['output']>;
  localizedCancelLabel?: Maybe<Scalars['String']['output']>;
  localizedDisplayDescription?: Maybe<Scalars['String']['output']>;
  localizedDisplayName?: Maybe<Scalars['String']['output']>;
  localizedFinishLabel?: Maybe<Scalars['String']['output']>;
  localizedReactivatedCancelLabel?: Maybe<Scalars['String']['output']>;
  localizedReactivatedFinishLabel?: Maybe<Scalars['String']['output']>;
  localizedRuleExecutionError?: Maybe<Scalars['String']['output']>;
  manuallyCreated?: Maybe<Scalars['Boolean']['output']>;
  modelId: Scalars['ID']['output'];
  organizationDocuments?: Maybe<Array<DocTypeAttachment>>;
  organizationId: Scalars['ID']['output'];
  ownerRoles: Array<Scalars['String']['output']>;
  parentId: Scalars['ID']['output'];
  predecessorIds?: Maybe<Array<Scalars['ID']['output']>>;
  previousTaskId?: Maybe<Scalars['ID']['output']>;
  processInstanceState: ProcessInstanceStateEnum;
  reactivateTasksOnCancel?: Maybe<Array<Scalars['ID']['output']>>;
  reactivateTasksOnFinish?: Maybe<Array<Scalars['ID']['output']>>;
  reactivatedByTask?: Maybe<Scalars['ID']['output']>;
  reactivatedCancelLabel?: Maybe<Array<ProcessOptionalString>>;
  reactivatedFinishLabel?: Maybe<Array<ProcessOptionalString>>;
  reactivatedRequiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  readonly?: Maybe<Scalars['Boolean']['output']>;
  reminderSettings?: Maybe<TaskReminderSettings>;
  replyType: ReplyTypeEnum;
  requiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  responsibleRoles: Array<Scalars['String']['output']>;
  rootId: Scalars['ID']['output'];
  ruleExecutionError?: Maybe<RuleExecutionError>;
  rules?: Maybe<Array<ProcessTaskRule>>;
  selectFinishByDefault?: Maybe<Scalars['Boolean']['output']>;
  sortOrder: Scalars['Float']['output'];
  targetObject: ProcessTargetEnum;
  targetRoles: Array<Scalars['String']['output']>;
  taskEmails?: Maybe<Array<EmailTemplateDescription>>;
  visibleForRoles: Array<Scalars['String']['output']>;
};

export class ProcessTaskInformationSendOutModel {
  __typename?: 'ProcessTaskInformationSendOutModel';
  _className: Scalars['String']['output'];
  _typeName: Scalars['String']['output'];
  activationCondition?: Maybe<Scalars['String']['output']>;
  activationConditionBlocklyWorkspace?: Maybe<Scalars['String']['output']>;
  atLeastOnePredecessorIds?: Maybe<Array<Scalars['String']['output']>>;
  attachDownloadableTaskFilesToEmails?: Maybe<Scalars['Boolean']['output']>;
  attachmentForms?: Maybe<Array<Scalars['String']['output']>>;
  attachmentTasks?: Maybe<Array<Scalars['String']['output']>>;
  attachmentTemplates?: Maybe<Array<Scalars['String']['output']>>;
  attachmentTemplatesFileType?: Maybe<Array<AttachmentTemplateFileTypeMapping>>;
  attachmentsRequired: Scalars['Boolean']['output'];
  autoStateChanges?: Maybe<Array<AutoStateChangeModel>>;
  cancelLabel?: Maybe<Array<ProcessModelString>>;
  candidateDocuments?: Maybe<Array<CandidateDocumentModel>>;
  candidateRequiredDocumentSetIds?: Maybe<Array<Scalars['ID']['output']>>;
  checkTaskActivationPeriodically?: Maybe<Scalars['Boolean']['output']>;
  createDownloadableArchiveWithLink?: Maybe<Scalars['Boolean']['output']>;
  delayStartByDays?: Maybe<Scalars['Int']['output']>;
  displayDescription?: Maybe<Array<ProcessModelString>>;
  displayName: Array<ProcessModelString>;
  doNotCreateOnSync?: Maybe<Scalars['Boolean']['output']>;
  dontSendEmails?: Maybe<Scalars['Boolean']['output']>;
  finishLabel?: Maybe<Array<ProcessModelString>>;
  hideGenericTaskComment?: Maybe<Scalars['Boolean']['output']>;
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  modelId: Scalars['ID']['output'];
  organizationDocuments?: Maybe<Array<OrganizationDocumentModel>>;
  predecessorsIds?: Maybe<Array<Scalars['String']['output']>>;
  reactivateTasksOnCancel?: Maybe<Array<Scalars['String']['output']>>;
  reactivateTasksOnFinish?: Maybe<Array<Scalars['String']['output']>>;
  reactivatedCancelLabel?: Maybe<Array<ProcessModelString>>;
  reactivatedFinishLabel?: Maybe<Array<ProcessModelString>>;
  reactivatedRequiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  reminderSettings?: Maybe<TaskReminderSettings>;
  replyType: ReplyTypeEnum;
  requiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  responsibleRoles: Array<Scalars['String']['output']>;
  rules?: Maybe<Array<ProcessTaskRule>>;
  selectFinishByDefault?: Maybe<Scalars['Boolean']['output']>;
  targetObject: ProcessTargetEnum;
  targetRoles: Array<Scalars['String']['output']>;
  taskEmails?: Maybe<Array<EmailTemplateDescription>>;
  visibleForRoles: Array<Scalars['String']['output']>;
};

export class ProcessTaskInformationSendOutModelEntity implements ProcessTaskModelEntity {
  __typename?: 'ProcessTaskInformationSendOutModelEntity';
  _className: Scalars['String']['output'];
  _etag?: Maybe<Scalars['String']['output']>;
  _typeName: Scalars['String']['output'];
  activationCondition?: Maybe<Scalars['String']['output']>;
  activationConditionBlocklyWorkspace?: Maybe<Scalars['String']['output']>;
  atLeastOnePredecessorIds?: Maybe<Array<Scalars['String']['output']>>;
  attachDownloadableTaskFilesToEmails?: Maybe<Scalars['Boolean']['output']>;
  attachmentForms?: Maybe<Array<Scalars['String']['output']>>;
  attachmentTasks?: Maybe<Array<Scalars['String']['output']>>;
  attachmentTemplates?: Maybe<Array<Scalars['String']['output']>>;
  attachmentTemplatesFileType?: Maybe<Array<AttachmentTemplateFileTypeMapping>>;
  attachmentsRequired: Scalars['Boolean']['output'];
  autoStateChanges?: Maybe<Array<AutoStateChangeModel>>;
  cancelLabel?: Maybe<Array<ProcessModelString>>;
  candidateDocuments?: Maybe<Array<CandidateDocumentModel>>;
  candidateRequiredDocumentSetIds?: Maybe<Array<Scalars['ID']['output']>>;
  checkTaskActivationPeriodically?: Maybe<Scalars['Boolean']['output']>;
  createDownloadableArchiveWithLink?: Maybe<Scalars['Boolean']['output']>;
  delayStartByDays?: Maybe<Scalars['Int']['output']>;
  displayDescription?: Maybe<Array<ProcessModelString>>;
  displayName: Array<ProcessModelString>;
  doNotCreateOnSync?: Maybe<Scalars['Boolean']['output']>;
  dontSendEmails?: Maybe<Scalars['Boolean']['output']>;
  finishLabel?: Maybe<Array<ProcessModelString>>;
  hideGenericTaskComment?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  modelId: Scalars['ID']['output'];
  organizationDocuments?: Maybe<Array<OrganizationDocumentModel>>;
  organizationId: Scalars['ID']['output'];
  parentId: Scalars['ID']['output'];
  predecessorsIds?: Maybe<Array<Scalars['String']['output']>>;
  reactivateTasksOnCancel?: Maybe<Array<Scalars['String']['output']>>;
  reactivateTasksOnFinish?: Maybe<Array<Scalars['String']['output']>>;
  reactivatedCancelLabel?: Maybe<Array<ProcessModelString>>;
  reactivatedFinishLabel?: Maybe<Array<ProcessModelString>>;
  reactivatedRequiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  reminderSettings?: Maybe<TaskReminderSettings>;
  replyType: ReplyTypeEnum;
  requiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  responsibleRoles: Array<Scalars['String']['output']>;
  rootId: Scalars['ID']['output'];
  rules?: Maybe<Array<ProcessTaskRule>>;
  selectFinishByDefault?: Maybe<Scalars['Boolean']['output']>;
  sortOrder: Scalars['Float']['output'];
  targetObject: ProcessTargetEnum;
  targetRoles: Array<Scalars['String']['output']>;
  taskEmails?: Maybe<Array<EmailTemplateDescription>>;
  visibleForRoles: Array<Scalars['String']['output']>;
};

export class ProcessTaskInviteToPlatform implements ProcessTask {
  __typename?: 'ProcessTaskInviteToPlatform';
  _etag?: Maybe<Scalars['String']['output']>;
  activationCondition?: Maybe<Scalars['String']['output']>;
  atLeastOnePredecessorIds?: Maybe<Array<Scalars['ID']['output']>>;
  autoStateChanges?: Maybe<Array<AutoStateChange>>;
  cancelLabel?: Maybe<Array<ProcessOptionalString>>;
  candidateDisplayId?: Maybe<Scalars['String']['output']>;
  candidateDisplayName?: Maybe<Scalars['String']['output']>;
  candidateId?: Maybe<Scalars['ID']['output']>;
  candidateOrganizationId?: Maybe<Scalars['ID']['output']>;
  checkTaskActivationPeriodically?: Maybe<Scalars['Boolean']['output']>;
  delayStartByDays?: Maybe<Scalars['Int']['output']>;
  displayDescription?: Maybe<Array<ProcessOptionalString>>;
  displayDescriptionQD?: Maybe<Array<ProcessOptionalQuillDelta>>;
  displayName?: Maybe<Array<ProcessOptionalString>>;
  doNotCreateOnSync?: Maybe<Scalars['Boolean']['output']>;
  fieldsToExcludeFromSync?: Maybe<Array<Scalars['String']['output']>>;
  finishLabel?: Maybe<Array<ProcessOptionalString>>;
  hideGenericTaskComment?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  instanceState: InstanceStateEnum;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  lastInstanceStateChangedAt?: Maybe<Scalars['DateTime']['output']>;
  lastUpdateManualStarted?: Maybe<Scalars['Boolean']['output']>;
  localizedCancelLabel?: Maybe<Scalars['String']['output']>;
  localizedDisplayDescription?: Maybe<Scalars['String']['output']>;
  localizedDisplayName?: Maybe<Scalars['String']['output']>;
  localizedFinishLabel?: Maybe<Scalars['String']['output']>;
  localizedReactivatedCancelLabel?: Maybe<Scalars['String']['output']>;
  localizedReactivatedFinishLabel?: Maybe<Scalars['String']['output']>;
  localizedRuleExecutionError?: Maybe<Scalars['String']['output']>;
  manuallyCreated?: Maybe<Scalars['Boolean']['output']>;
  modelId: Scalars['ID']['output'];
  organizationId: Scalars['ID']['output'];
  ownerRoles: Array<Scalars['String']['output']>;
  parentId: Scalars['ID']['output'];
  predecessorIds?: Maybe<Array<Scalars['ID']['output']>>;
  previousTaskId?: Maybe<Scalars['ID']['output']>;
  processInstanceState: ProcessInstanceStateEnum;
  reactivateTasksOnCancel?: Maybe<Array<Scalars['ID']['output']>>;
  reactivateTasksOnFinish?: Maybe<Array<Scalars['ID']['output']>>;
  reactivatedByTask?: Maybe<Scalars['ID']['output']>;
  reactivatedCancelLabel?: Maybe<Array<ProcessOptionalString>>;
  reactivatedFinishLabel?: Maybe<Array<ProcessOptionalString>>;
  reactivatedRequiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  readonly?: Maybe<Scalars['Boolean']['output']>;
  reminderSettings?: Maybe<TaskReminderSettings>;
  requiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  responsibleRoles: Array<Scalars['String']['output']>;
  rootId: Scalars['ID']['output'];
  ruleExecutionError?: Maybe<RuleExecutionError>;
  rules?: Maybe<Array<ProcessTaskRule>>;
  selectFinishByDefault?: Maybe<Scalars['Boolean']['output']>;
  sortOrder: Scalars['Float']['output'];
  targetObject: ProcessTargetEnum;
  targetRoles: Array<Scalars['String']['output']>;
  taskEmails?: Maybe<Array<EmailTemplateDescription>>;
  visibleForRoles: Array<Scalars['String']['output']>;
};

export class ProcessTaskInviteToPlatformModel {
  __typename?: 'ProcessTaskInviteToPlatformModel';
  _className: Scalars['String']['output'];
  _typeName: Scalars['String']['output'];
  activationCondition?: Maybe<Scalars['String']['output']>;
  activationConditionBlocklyWorkspace?: Maybe<Scalars['String']['output']>;
  atLeastOnePredecessorIds?: Maybe<Array<Scalars['String']['output']>>;
  autoStateChanges?: Maybe<Array<AutoStateChangeModel>>;
  cancelLabel?: Maybe<Array<ProcessModelString>>;
  checkTaskActivationPeriodically?: Maybe<Scalars['Boolean']['output']>;
  delayStartByDays?: Maybe<Scalars['Int']['output']>;
  displayDescription?: Maybe<Array<ProcessModelString>>;
  displayName: Array<ProcessModelString>;
  doNotCreateOnSync?: Maybe<Scalars['Boolean']['output']>;
  dontSendEmails?: Maybe<Scalars['Boolean']['output']>;
  finishLabel?: Maybe<Array<ProcessModelString>>;
  hideGenericTaskComment?: Maybe<Scalars['Boolean']['output']>;
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  modelId: Scalars['ID']['output'];
  predecessorsIds?: Maybe<Array<Scalars['String']['output']>>;
  reactivateTasksOnCancel?: Maybe<Array<Scalars['String']['output']>>;
  reactivateTasksOnFinish?: Maybe<Array<Scalars['String']['output']>>;
  reactivatedCancelLabel?: Maybe<Array<ProcessModelString>>;
  reactivatedFinishLabel?: Maybe<Array<ProcessModelString>>;
  reactivatedRequiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  reminderSettings?: Maybe<TaskReminderSettings>;
  requiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  responsibleRoles: Array<Scalars['String']['output']>;
  rules?: Maybe<Array<ProcessTaskRule>>;
  selectFinishByDefault?: Maybe<Scalars['Boolean']['output']>;
  targetObject: ProcessTargetEnum;
  targetRoles: Array<Scalars['String']['output']>;
  taskEmails?: Maybe<Array<EmailTemplateDescription>>;
  visibleForRoles: Array<Scalars['String']['output']>;
};

export class ProcessTaskInviteToPlatformModelEntity implements ProcessTaskModelEntity {
  __typename?: 'ProcessTaskInviteToPlatformModelEntity';
  _className: Scalars['String']['output'];
  _etag?: Maybe<Scalars['String']['output']>;
  _typeName: Scalars['String']['output'];
  activationCondition?: Maybe<Scalars['String']['output']>;
  activationConditionBlocklyWorkspace?: Maybe<Scalars['String']['output']>;
  atLeastOnePredecessorIds?: Maybe<Array<Scalars['String']['output']>>;
  autoStateChanges?: Maybe<Array<AutoStateChangeModel>>;
  cancelLabel?: Maybe<Array<ProcessModelString>>;
  checkTaskActivationPeriodically?: Maybe<Scalars['Boolean']['output']>;
  delayStartByDays?: Maybe<Scalars['Int']['output']>;
  displayDescription?: Maybe<Array<ProcessModelString>>;
  displayName: Array<ProcessModelString>;
  doNotCreateOnSync?: Maybe<Scalars['Boolean']['output']>;
  dontSendEmails?: Maybe<Scalars['Boolean']['output']>;
  finishLabel?: Maybe<Array<ProcessModelString>>;
  hideGenericTaskComment?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  modelId: Scalars['ID']['output'];
  organizationId: Scalars['ID']['output'];
  parentId: Scalars['ID']['output'];
  predecessorsIds?: Maybe<Array<Scalars['String']['output']>>;
  reactivateTasksOnCancel?: Maybe<Array<Scalars['String']['output']>>;
  reactivateTasksOnFinish?: Maybe<Array<Scalars['String']['output']>>;
  reactivatedCancelLabel?: Maybe<Array<ProcessModelString>>;
  reactivatedFinishLabel?: Maybe<Array<ProcessModelString>>;
  reactivatedRequiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  reminderSettings?: Maybe<TaskReminderSettings>;
  requiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  responsibleRoles: Array<Scalars['String']['output']>;
  rootId: Scalars['ID']['output'];
  rules?: Maybe<Array<ProcessTaskRule>>;
  selectFinishByDefault?: Maybe<Scalars['Boolean']['output']>;
  sortOrder: Scalars['Float']['output'];
  targetObject: ProcessTargetEnum;
  targetRoles: Array<Scalars['String']['output']>;
  taskEmails?: Maybe<Array<EmailTemplateDescription>>;
  visibleForRoles: Array<Scalars['String']['output']>;
};

export class ProcessTaskListInput {
  candidateId?: InputMaybe<Scalars['ID']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['ID']['input'];
  processId: Scalars['ID']['input'];
};

export class ProcessTaskListReportInput {
  includeHistory?: InputMaybe<Scalars['Boolean']['input']>;
  language: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
};

export type ProcessTaskModel = ProcessTaskAssignmentExceptionModel | ProcessTaskAutoOperationModel | ProcessTaskDeactivateProcessModel | ProcessTaskDocumentCheckModel | ProcessTaskEmailSendModel | ProcessTaskEnterMissingInformationModel | ProcessTaskEnterMissingInformationV2Model | ProcessTaskInformationCollectionModel | ProcessTaskInformationSendOutModel | ProcessTaskInviteToPlatformModel | ProcessTaskOrganizationAuditModel | ProcessTaskPdfFormGenerationModel | ProcessTaskReminderModel | ProcessTaskRoleSetupModel | ProcessTaskTriggerEventModel | ProcessTaskUpdateCandidateProfileModel | ProcessTaskUploadModel | ProcessTaskWorkModel;

export class ProcessTaskModelCommandInputType {
  command: ProcessModelCommandEnum;
  createAreaData?: InputMaybe<ProcessAreaModelCreateInput>;
  createPhaseData?: InputMaybe<ProcessPhaseModelCreateInput>;
  createTaskData?: InputMaybe<ProcessTaskModelCreateInput>;
  deleteAreaData?: InputMaybe<ProcessAreaModelDeleteInput>;
  deletePhaseData?: InputMaybe<ProcessPhaseModelDeleteInput>;
  deleteTaskData?: InputMaybe<ProcessTaskModelDeleteInput>;
  duplicateAreaData?: InputMaybe<ProcessAreaModelDuplicateInput>;
  duplicatePhaseData?: InputMaybe<ProcessPhaseModelDuplicateInput>;
  duplicateTaskData?: InputMaybe<ProcessTaskModelDuplicateInput>;
  entityEtag?: InputMaybe<Scalars['String']['input']>;
  entityId?: InputMaybe<Scalars['ID']['input']>;
  moveAreaData?: InputMaybe<ProcessAreaModelMoveInput>;
  movePhaseData?: InputMaybe<ProcessPhaseModelMoveInput>;
  moveTaskData?: InputMaybe<ProcessTaskModelMoveInput>;
  organizationId: Scalars['ID']['input'];
  rootId: Scalars['String']['input'];
  updateAreaData?: InputMaybe<ProcessAreaModelUpdateInput>;
  updatePhaseData?: InputMaybe<ProcessPhaseModelUpdateInput>;
  updateTaskData?: InputMaybe<ProcessTaskModelUpdateInput>;
};

export class ProcessTaskModelCreateInput {
  _className?: InputMaybe<Scalars['String']['input']>;
  _typeName?: Scalars['String']['input'];
  acceptFileTypes?: InputMaybe<Scalars['String']['input']>;
  activationCondition?: InputMaybe<Scalars['String']['input']>;
  activationConditionBlocklyWorkspace?: InputMaybe<Scalars['String']['input']>;
  assignmentExceptionOperation?: InputMaybe<CandidateAssignmentExceptionOperation>;
  assignmentExceptionType?: InputMaybe<CandidateAssignmentExceptionType>;
  atLeastOnePredecessorIds?: InputMaybe<Array<Scalars['String']['input']>>;
  attachDownloadableTaskFilesToEmails?: InputMaybe<Scalars['Boolean']['input']>;
  attachmentForms?: InputMaybe<Array<Scalars['String']['input']>>;
  attachmentTasks?: InputMaybe<Array<Scalars['String']['input']>>;
  attachmentTemplates?: InputMaybe<Array<Scalars['String']['input']>>;
  attachmentTemplatesFileType?: InputMaybe<Array<AttachmentTemplateFileType>>;
  attachmentsAllowedOnTopLevel?: InputMaybe<Scalars['Boolean']['input']>;
  attachmentsDisplayText?: InputMaybe<Array<ProcessModelStringInput>>;
  attachmentsRequired?: InputMaybe<Scalars['Boolean']['input']>;
  auditDefinitionName?: InputMaybe<Scalars['String']['input']>;
  auditDefinitionName23?: InputMaybe<Scalars['String']['input']>;
  auditDefinitionName24?: InputMaybe<Scalars['String']['input']>;
  auditMode?: InputMaybe<AuditTaskModeEnum>;
  autoProcessToken?: InputMaybe<Scalars['String']['input']>;
  autoStateChanges?: InputMaybe<Array<AutoStateChangeModelInput>>;
  cancelLabel?: InputMaybe<Array<ProcessModelStringInput>>;
  candidateDocuments?: InputMaybe<Array<CandidateDocumentModelInput>>;
  candidateRequiredDocumentSetIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  checkTaskActivationPeriodically?: InputMaybe<Scalars['Boolean']['input']>;
  confirmationMode?: InputMaybe<Scalars['Boolean']['input']>;
  createDownloadableArchiveWithLink?: InputMaybe<Scalars['Boolean']['input']>;
  delayStartByDays?: InputMaybe<Scalars['Int']['input']>;
  deliveryFormat?: InputMaybe<DocumentDeliveryFormat>;
  displayDescription?: InputMaybe<Array<ProcessModelStringInput>>;
  displayName?: InputMaybe<Array<ProcessModelStringInput>>;
  doNotAutoFinishTaskInCaseAllRequirementsFullfilled?: InputMaybe<Scalars['Boolean']['input']>;
  doNotCreateOnSync?: InputMaybe<Scalars['Boolean']['input']>;
  documentCheckType?: InputMaybe<DocumentDeliveryFormat>;
  documentTypesToUpload?: InputMaybe<Array<DocumentRequirementTypeInput>>;
  dontSendEmails?: InputMaybe<Scalars['Boolean']['input']>;
  dummyForGraphQL?: InputMaybe<Scalars['String']['input']>;
  finishLabel?: InputMaybe<Array<ProcessModelStringInput>>;
  finishingMode?: InputMaybe<DocumentFinishingMode>;
  formToken?: InputMaybe<KnownFormToken>;
  hideGenericTaskComment?: InputMaybe<Scalars['Boolean']['input']>;
  includeDocumentsMarkedAsNotRelevantInRequiredCount?: InputMaybe<Scalars['Boolean']['input']>;
  initialModel?: InputMaybe<Scalars['JSONObject']['input']>;
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  lockDocumentsAfterReviewForIds?: InputMaybe<Array<Scalars['String']['input']>>;
  maxDocumentsRequired?: InputMaybe<Scalars['Int']['input']>;
  maxFileSize?: InputMaybe<Scalars['Int']['input']>;
  maxUploadedDocumentsRequired?: InputMaybe<Scalars['Int']['input']>;
  minDocumentsRequired?: InputMaybe<Scalars['Int']['input']>;
  minUploadedDocumentsRequired?: InputMaybe<Scalars['Int']['input']>;
  modelId?: InputMaybe<Scalars['ID']['input']>;
  numberOfAllowedAttachments?: InputMaybe<Scalars['Int']['input']>;
  numberOfReviewers?: InputMaybe<Scalars['Int']['input']>;
  organizationDocuments?: InputMaybe<Array<OrganizationDocumentModelInput>>;
  organizationId: Scalars['ID']['input'];
  parentId: Scalars['ID']['input'];
  preConfiguredAllowedDocumentFormats?: InputMaybe<Array<Scalars['String']['input']>>;
  preConfiguredDocumentFormatOverwrite?: InputMaybe<Array<Scalars['String']['input']>>;
  preConfiguredDocumentPurpose?: InputMaybe<DocumentPurpose>;
  preConfiguredDocumentSetName?: InputMaybe<Scalars['String']['input']>;
  preConfiguredFilterForCandidate?: InputMaybe<Scalars['Boolean']['input']>;
  predecessorsIds?: InputMaybe<Array<Scalars['String']['input']>>;
  prefillTaskId?: InputMaybe<Scalars['String']['input']>;
  previousTaskId?: InputMaybe<Scalars['String']['input']>;
  processAuditGroupName?: InputMaybe<Scalars['String']['input']>;
  processDeadlineInDays?: InputMaybe<Scalars['Int']['input']>;
  processDocumentsToUpload?: InputMaybe<Array<DocumentProcessUploadTypeInput>>;
  processEvent?: InputMaybe<ProcessEventEnum>;
  properties?: InputMaybe<Array<CandidateProfilePropertyInput>>;
  reactivateTasksOnCancel?: InputMaybe<Array<Scalars['String']['input']>>;
  reactivateTasksOnFinish?: InputMaybe<Array<Scalars['String']['input']>>;
  reactivatedCancelLabel?: InputMaybe<Array<ProcessModelStringInput>>;
  reactivatedFinishLabel?: InputMaybe<Array<ProcessModelStringInput>>;
  reactivatedRequiresCancelReason?: InputMaybe<Scalars['Boolean']['input']>;
  recipientRoles?: InputMaybe<Array<Scalars['String']['input']>>;
  recruitingConceptTaskId?: InputMaybe<Scalars['String']['input']>;
  referenceTaskUploadModelIds?: InputMaybe<Array<Scalars['String']['input']>>;
  reminderDate?: InputMaybe<Scalars['DateTime']['input']>;
  reminderSettings?: InputMaybe<TaskReminderSettingsInput>;
  replyType?: InputMaybe<ReplyTypeEnum>;
  requestedInformation?: InputMaybe<Array<EnterMissingInformationRequiredInformationInput>>;
  requiredInformation?: InputMaybe<Array<EnterMissingInformation>>;
  requiresCancelReason?: InputMaybe<Scalars['Boolean']['input']>;
  responsibleRoles?: InputMaybe<Array<Scalars['String']['input']>>;
  rootId: Scalars['ID']['input'];
  rules?: InputMaybe<Array<ProcessTaskRuleInput>>;
  selectFinishByDefault?: InputMaybe<Scalars['Boolean']['input']>;
  setupRoles?: InputMaybe<Array<Scalars['String']['input']>>;
  skipOnAlreadyEntered?: InputMaybe<Scalars['Boolean']['input']>;
  targetObject?: InputMaybe<ProcessTargetEnum>;
  targetRoles?: InputMaybe<Array<Scalars['String']['input']>>;
  taskEmails?: InputMaybe<Array<EmailTemplateDescriptionInput>>;
  templateLanguage?: InputMaybe<Scalars['String']['input']>;
  templateName?: InputMaybe<Scalars['String']['input']>;
  tokens?: InputMaybe<Array<Scalars['String']['input']>>;
  usePreConfiguredDocumentTypes?: InputMaybe<Scalars['Boolean']['input']>;
  visibleForRoles?: InputMaybe<Array<Scalars['String']['input']>>;
};

export class ProcessTaskModelDeleteInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
};

export class ProcessTaskModelDuplicateInput {
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
};

export interface ProcessTaskModelEntity {
  _className: Scalars['String']['output'];
  _etag?: Maybe<Scalars['String']['output']>;
  _typeName: Scalars['String']['output'];
  activationCondition?: Maybe<Scalars['String']['output']>;
  activationConditionBlocklyWorkspace?: Maybe<Scalars['String']['output']>;
  atLeastOnePredecessorIds?: Maybe<Array<Scalars['String']['output']>>;
  autoStateChanges?: Maybe<Array<AutoStateChangeModel>>;
  cancelLabel?: Maybe<Array<ProcessModelString>>;
  checkTaskActivationPeriodically?: Maybe<Scalars['Boolean']['output']>;
  delayStartByDays?: Maybe<Scalars['Int']['output']>;
  displayDescription?: Maybe<Array<ProcessModelString>>;
  displayName: Array<ProcessModelString>;
  doNotCreateOnSync?: Maybe<Scalars['Boolean']['output']>;
  dontSendEmails?: Maybe<Scalars['Boolean']['output']>;
  finishLabel?: Maybe<Array<ProcessModelString>>;
  hideGenericTaskComment?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  modelId: Scalars['ID']['output'];
  organizationId: Scalars['ID']['output'];
  parentId: Scalars['ID']['output'];
  predecessorsIds?: Maybe<Array<Scalars['String']['output']>>;
  reactivateTasksOnCancel?: Maybe<Array<Scalars['String']['output']>>;
  reactivateTasksOnFinish?: Maybe<Array<Scalars['String']['output']>>;
  reactivatedCancelLabel?: Maybe<Array<ProcessModelString>>;
  reactivatedFinishLabel?: Maybe<Array<ProcessModelString>>;
  reactivatedRequiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  reminderSettings?: Maybe<TaskReminderSettings>;
  requiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  responsibleRoles: Array<Scalars['String']['output']>;
  rootId: Scalars['ID']['output'];
  rules?: Maybe<Array<ProcessTaskRule>>;
  selectFinishByDefault?: Maybe<Scalars['Boolean']['output']>;
  sortOrder: Scalars['Float']['output'];
  targetObject: ProcessTargetEnum;
  targetRoles: Array<Scalars['String']['output']>;
  taskEmails?: Maybe<Array<EmailTemplateDescription>>;
  visibleForRoles: Array<Scalars['String']['output']>;
}

export class ProcessTaskModelGetInput {
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
};

export class ProcessTaskModelMoveInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
  parentId: Scalars['ID']['input'];
  previousTaskId?: InputMaybe<Scalars['String']['input']>;
};

export type ProcessTaskModelResult = ProcessTaskAssignmentExceptionModelEntity | ProcessTaskAutoOperationModelEntity | ProcessTaskDeactivateProcessModelEntity | ProcessTaskDocumentCheckModelEntity | ProcessTaskEmailSendModelEntity | ProcessTaskEnterMissingInformationModelEntity | ProcessTaskEnterMissingInformationV2ModelEntity | ProcessTaskInformationCollectionModelEntity | ProcessTaskInformationSendOutModelEntity | ProcessTaskInviteToPlatformModelEntity | ProcessTaskOrganizationAuditModelEntity | ProcessTaskPdfFormGenerationModelEntity | ProcessTaskReminderModelEntity | ProcessTaskRoleSetupModelEntity | ProcessTaskTriggerEventModelEntity | ProcessTaskUpdateCandidateProfileModelEntity | ProcessTaskUploadModelEntity | ProcessTaskWorkModelEntity;

export class ProcessTaskModelUpdateInput {
  _className?: InputMaybe<Scalars['String']['input']>;
  _etag?: InputMaybe<Scalars['String']['input']>;
  _typeName?: Scalars['String']['input'];
  acceptFileTypes?: InputMaybe<Scalars['String']['input']>;
  activationCondition?: InputMaybe<Scalars['String']['input']>;
  activationConditionBlocklyWorkspace?: InputMaybe<Scalars['String']['input']>;
  assignmentExceptionOperation?: InputMaybe<CandidateAssignmentExceptionOperation>;
  assignmentExceptionType?: InputMaybe<CandidateAssignmentExceptionType>;
  atLeastOnePredecessorIds?: InputMaybe<Array<Scalars['String']['input']>>;
  attachDownloadableTaskFilesToEmails?: InputMaybe<Scalars['Boolean']['input']>;
  attachmentForms?: InputMaybe<Array<Scalars['String']['input']>>;
  attachmentTasks?: InputMaybe<Array<Scalars['String']['input']>>;
  attachmentTemplates?: InputMaybe<Array<Scalars['String']['input']>>;
  attachmentTemplatesFileType?: InputMaybe<Array<AttachmentTemplateFileType>>;
  attachmentsAllowedOnTopLevel?: InputMaybe<Scalars['Boolean']['input']>;
  attachmentsDisplayText?: InputMaybe<Array<ProcessModelStringInput>>;
  attachmentsRequired?: InputMaybe<Scalars['Boolean']['input']>;
  auditDefinitionName?: InputMaybe<Scalars['String']['input']>;
  auditDefinitionName23?: InputMaybe<Scalars['String']['input']>;
  auditDefinitionName24?: InputMaybe<Scalars['String']['input']>;
  auditMode?: InputMaybe<AuditTaskModeEnum>;
  autoProcessToken?: InputMaybe<Scalars['String']['input']>;
  autoStateChanges?: InputMaybe<Array<AutoStateChangeModelInput>>;
  cancelLabel?: InputMaybe<Array<ProcessModelStringInput>>;
  candidateDocuments?: InputMaybe<Array<CandidateDocumentModelInput>>;
  candidateRequiredDocumentSetIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  checkTaskActivationPeriodically?: InputMaybe<Scalars['Boolean']['input']>;
  confirmationMode?: InputMaybe<Scalars['Boolean']['input']>;
  createDownloadableArchiveWithLink?: InputMaybe<Scalars['Boolean']['input']>;
  delayStartByDays?: InputMaybe<Scalars['Int']['input']>;
  deliveryFormat?: InputMaybe<DocumentDeliveryFormat>;
  displayDescription?: InputMaybe<Array<ProcessModelStringInput>>;
  displayName?: InputMaybe<Array<ProcessModelStringInput>>;
  doNotAutoFinishTaskInCaseAllRequirementsFullfilled?: InputMaybe<Scalars['Boolean']['input']>;
  doNotCreateOnSync?: InputMaybe<Scalars['Boolean']['input']>;
  documentCheckType?: InputMaybe<DocumentDeliveryFormat>;
  documentTypesToUpload?: InputMaybe<Array<DocumentRequirementTypeInput>>;
  dontSendEmails?: InputMaybe<Scalars['Boolean']['input']>;
  dummyForGraphQL?: InputMaybe<Scalars['String']['input']>;
  finishLabel?: InputMaybe<Array<ProcessModelStringInput>>;
  finishingMode?: InputMaybe<DocumentFinishingMode>;
  formToken?: InputMaybe<KnownFormToken>;
  hideGenericTaskComment?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  includeDocumentsMarkedAsNotRelevantInRequiredCount?: InputMaybe<Scalars['Boolean']['input']>;
  initialModel?: InputMaybe<Scalars['JSONObject']['input']>;
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  lockDocumentsAfterReviewForIds?: InputMaybe<Array<Scalars['String']['input']>>;
  maxDocumentsRequired?: InputMaybe<Scalars['Int']['input']>;
  maxFileSize?: InputMaybe<Scalars['Int']['input']>;
  maxUploadedDocumentsRequired?: InputMaybe<Scalars['Int']['input']>;
  minDocumentsRequired?: InputMaybe<Scalars['Int']['input']>;
  minUploadedDocumentsRequired?: InputMaybe<Scalars['Int']['input']>;
  modelId?: InputMaybe<Scalars['ID']['input']>;
  numberOfAllowedAttachments?: InputMaybe<Scalars['Int']['input']>;
  numberOfReviewers?: InputMaybe<Scalars['Int']['input']>;
  organizationDocuments?: InputMaybe<Array<OrganizationDocumentModelInput>>;
  organizationId: Scalars['ID']['input'];
  parentId: Scalars['ID']['input'];
  preConfiguredAllowedDocumentFormats?: InputMaybe<Array<Scalars['String']['input']>>;
  preConfiguredDocumentFormatOverwrite?: InputMaybe<Array<Scalars['String']['input']>>;
  preConfiguredDocumentPurpose?: InputMaybe<DocumentPurpose>;
  preConfiguredDocumentSetName?: InputMaybe<Scalars['String']['input']>;
  preConfiguredFilterForCandidate?: InputMaybe<Scalars['Boolean']['input']>;
  predecessorsIds?: InputMaybe<Array<Scalars['String']['input']>>;
  prefillTaskId?: InputMaybe<Scalars['String']['input']>;
  processAuditGroupName?: InputMaybe<Scalars['String']['input']>;
  processDeadlineInDays?: InputMaybe<Scalars['Int']['input']>;
  processDocumentsToUpload?: InputMaybe<Array<DocumentProcessUploadTypeInput>>;
  processEvent?: InputMaybe<ProcessEventEnum>;
  properties?: InputMaybe<Array<CandidateProfilePropertyInput>>;
  reactivateTasksOnCancel?: InputMaybe<Array<Scalars['String']['input']>>;
  reactivateTasksOnFinish?: InputMaybe<Array<Scalars['String']['input']>>;
  reactivatedCancelLabel?: InputMaybe<Array<ProcessModelStringInput>>;
  reactivatedFinishLabel?: InputMaybe<Array<ProcessModelStringInput>>;
  reactivatedRequiresCancelReason?: InputMaybe<Scalars['Boolean']['input']>;
  recipientRoles?: InputMaybe<Array<Scalars['String']['input']>>;
  recruitingConceptTaskId?: InputMaybe<Scalars['String']['input']>;
  referenceTaskUploadModelIds?: InputMaybe<Array<Scalars['String']['input']>>;
  reminderDate?: InputMaybe<Scalars['DateTime']['input']>;
  reminderSettings?: InputMaybe<TaskReminderSettingsInput>;
  replyType?: InputMaybe<ReplyTypeEnum>;
  requestedInformation?: InputMaybe<Array<EnterMissingInformationRequiredInformationInput>>;
  requiredInformation?: InputMaybe<Array<EnterMissingInformation>>;
  requiresCancelReason?: InputMaybe<Scalars['Boolean']['input']>;
  responsibleRoles?: InputMaybe<Array<Scalars['String']['input']>>;
  rootId: Scalars['ID']['input'];
  rules?: InputMaybe<Array<ProcessTaskRuleInput>>;
  selectFinishByDefault?: InputMaybe<Scalars['Boolean']['input']>;
  setupRoles?: InputMaybe<Array<Scalars['String']['input']>>;
  skipOnAlreadyEntered?: InputMaybe<Scalars['Boolean']['input']>;
  sortOrder: Scalars['Float']['input'];
  targetObject?: InputMaybe<ProcessTargetEnum>;
  targetRoles?: InputMaybe<Array<Scalars['String']['input']>>;
  taskEmails?: InputMaybe<Array<EmailTemplateDescriptionInput>>;
  templateLanguage?: InputMaybe<Scalars['String']['input']>;
  templateName?: InputMaybe<Scalars['String']['input']>;
  tokens?: InputMaybe<Array<Scalars['String']['input']>>;
  usePreConfiguredDocumentTypes?: InputMaybe<Scalars['Boolean']['input']>;
  visibleForRoles?: InputMaybe<Array<Scalars['String']['input']>>;
};

export class ProcessTaskOrganizationAudit implements ProcessTask {
  __typename?: 'ProcessTaskOrganizationAudit';
  _etag?: Maybe<Scalars['String']['output']>;
  acceptFileTypes: Scalars['String']['output'];
  activationCondition?: Maybe<Scalars['String']['output']>;
  applicantUser?: Maybe<User>;
  atLeastOnePredecessorIds?: Maybe<Array<Scalars['ID']['output']>>;
  auditDefinition?: Maybe<AuditDefinition>;
  auditDefinitionName: Scalars['String']['output'];
  auditMode: AuditTaskModeEnum;
  auditorUser?: Maybe<User>;
  autoStateChanges?: Maybe<Array<AutoStateChange>>;
  cancelLabel?: Maybe<Array<ProcessOptionalString>>;
  candidateDisplayId?: Maybe<Scalars['String']['output']>;
  candidateDisplayName?: Maybe<Scalars['String']['output']>;
  candidateId?: Maybe<Scalars['ID']['output']>;
  candidateOrganizationId?: Maybe<Scalars['ID']['output']>;
  checkTaskActivationPeriodically?: Maybe<Scalars['Boolean']['output']>;
  delayStartByDays?: Maybe<Scalars['Int']['output']>;
  displayDescription?: Maybe<Array<ProcessOptionalString>>;
  displayDescriptionQD?: Maybe<Array<ProcessOptionalQuillDelta>>;
  displayName?: Maybe<Array<ProcessOptionalString>>;
  doNotCreateOnSync?: Maybe<Scalars['Boolean']['output']>;
  fieldsToExcludeFromSync?: Maybe<Array<Scalars['String']['output']>>;
  finishLabel?: Maybe<Array<ProcessOptionalString>>;
  hideGenericTaskComment?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  instanceState: InstanceStateEnum;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  lastInstanceStateChangedAt?: Maybe<Scalars['DateTime']['output']>;
  lastUpdateManualStarted?: Maybe<Scalars['Boolean']['output']>;
  localizedCancelLabel?: Maybe<Scalars['String']['output']>;
  localizedDisplayDescription?: Maybe<Scalars['String']['output']>;
  localizedDisplayName?: Maybe<Scalars['String']['output']>;
  localizedFinishLabel?: Maybe<Scalars['String']['output']>;
  localizedReactivatedCancelLabel?: Maybe<Scalars['String']['output']>;
  localizedReactivatedFinishLabel?: Maybe<Scalars['String']['output']>;
  localizedRuleExecutionError?: Maybe<Scalars['String']['output']>;
  manuallyCreated?: Maybe<Scalars['Boolean']['output']>;
  maxFileSize: Scalars['Float']['output'];
  modelId: Scalars['ID']['output'];
  organizationId: Scalars['ID']['output'];
  ownerRoles: Array<Scalars['String']['output']>;
  parentId: Scalars['ID']['output'];
  predecessorIds?: Maybe<Array<Scalars['ID']['output']>>;
  previousTaskId?: Maybe<Scalars['ID']['output']>;
  processAuditGroupName: Scalars['String']['output'];
  processDeadlineInDays?: Maybe<Scalars['Float']['output']>;
  processInstanceState: ProcessInstanceStateEnum;
  reactivateTasksOnCancel?: Maybe<Array<Scalars['ID']['output']>>;
  reactivateTasksOnFinish?: Maybe<Array<Scalars['ID']['output']>>;
  reactivatedByTask?: Maybe<Scalars['ID']['output']>;
  reactivatedCancelLabel?: Maybe<Array<ProcessOptionalString>>;
  reactivatedFinishLabel?: Maybe<Array<ProcessOptionalString>>;
  reactivatedRequiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  readonly?: Maybe<Scalars['Boolean']['output']>;
  recruitingConcept?: Maybe<Scalars['JSON']['output']>;
  recruitingConceptTaskId?: Maybe<Scalars['String']['output']>;
  reminderSettings?: Maybe<TaskReminderSettings>;
  requiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  responsibleRoles: Array<Scalars['String']['output']>;
  rootId: Scalars['ID']['output'];
  ruleExecutionError?: Maybe<RuleExecutionError>;
  rules?: Maybe<Array<ProcessTaskRule>>;
  selectFinishByDefault?: Maybe<Scalars['Boolean']['output']>;
  sortOrder: Scalars['Float']['output'];
  targetObject: ProcessTargetEnum;
  targetRoles: Array<Scalars['String']['output']>;
  taskEmails?: Maybe<Array<EmailTemplateDescription>>;
  visibleForRoles: Array<Scalars['String']['output']>;
};

export class ProcessTaskOrganizationAuditModel {
  __typename?: 'ProcessTaskOrganizationAuditModel';
  _className: Scalars['String']['output'];
  _typeName: Scalars['String']['output'];
  acceptFileTypes?: Maybe<Scalars['String']['output']>;
  activationCondition?: Maybe<Scalars['String']['output']>;
  activationConditionBlocklyWorkspace?: Maybe<Scalars['String']['output']>;
  atLeastOnePredecessorIds?: Maybe<Array<Scalars['String']['output']>>;
  auditDefinitionName: Scalars['String']['output'];
  auditDefinitionName23: Scalars['String']['output'];
  auditDefinitionName24: Scalars['String']['output'];
  auditMode: AuditTaskModeEnum;
  autoStateChanges?: Maybe<Array<AutoStateChangeModel>>;
  cancelLabel?: Maybe<Array<ProcessModelString>>;
  checkTaskActivationPeriodically?: Maybe<Scalars['Boolean']['output']>;
  delayStartByDays?: Maybe<Scalars['Int']['output']>;
  displayDescription?: Maybe<Array<ProcessModelString>>;
  displayName: Array<ProcessModelString>;
  doNotCreateOnSync?: Maybe<Scalars['Boolean']['output']>;
  dontSendEmails?: Maybe<Scalars['Boolean']['output']>;
  finishLabel?: Maybe<Array<ProcessModelString>>;
  hideGenericTaskComment?: Maybe<Scalars['Boolean']['output']>;
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  maxFileSize?: Maybe<Scalars['Int']['output']>;
  modelId: Scalars['ID']['output'];
  predecessorsIds?: Maybe<Array<Scalars['String']['output']>>;
  processAuditGroupName: Scalars['String']['output'];
  processDeadlineInDays?: Maybe<Scalars['Int']['output']>;
  reactivateTasksOnCancel?: Maybe<Array<Scalars['String']['output']>>;
  reactivateTasksOnFinish?: Maybe<Array<Scalars['String']['output']>>;
  reactivatedCancelLabel?: Maybe<Array<ProcessModelString>>;
  reactivatedFinishLabel?: Maybe<Array<ProcessModelString>>;
  reactivatedRequiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  recruitingConceptTaskId: Scalars['String']['output'];
  reminderSettings?: Maybe<TaskReminderSettings>;
  requiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  responsibleRoles: Array<Scalars['String']['output']>;
  rules?: Maybe<Array<ProcessTaskRule>>;
  selectFinishByDefault?: Maybe<Scalars['Boolean']['output']>;
  targetObject: ProcessTargetEnum;
  targetRoles: Array<Scalars['String']['output']>;
  taskEmails?: Maybe<Array<EmailTemplateDescription>>;
  visibleForRoles: Array<Scalars['String']['output']>;
};

export class ProcessTaskOrganizationAuditModelEntity implements ProcessTaskModelEntity {
  __typename?: 'ProcessTaskOrganizationAuditModelEntity';
  _className: Scalars['String']['output'];
  _etag?: Maybe<Scalars['String']['output']>;
  _typeName: Scalars['String']['output'];
  acceptFileTypes?: Maybe<Scalars['String']['output']>;
  activationCondition?: Maybe<Scalars['String']['output']>;
  activationConditionBlocklyWorkspace?: Maybe<Scalars['String']['output']>;
  atLeastOnePredecessorIds?: Maybe<Array<Scalars['String']['output']>>;
  auditDefinitionName: Scalars['String']['output'];
  auditDefinitionName23: Scalars['String']['output'];
  auditDefinitionName24: Scalars['String']['output'];
  auditMode: AuditTaskModeEnum;
  autoStateChanges?: Maybe<Array<AutoStateChangeModel>>;
  cancelLabel?: Maybe<Array<ProcessModelString>>;
  checkTaskActivationPeriodically?: Maybe<Scalars['Boolean']['output']>;
  delayStartByDays?: Maybe<Scalars['Int']['output']>;
  displayDescription?: Maybe<Array<ProcessModelString>>;
  displayName: Array<ProcessModelString>;
  doNotCreateOnSync?: Maybe<Scalars['Boolean']['output']>;
  dontSendEmails?: Maybe<Scalars['Boolean']['output']>;
  finishLabel?: Maybe<Array<ProcessModelString>>;
  hideGenericTaskComment?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  maxFileSize?: Maybe<Scalars['Int']['output']>;
  modelId: Scalars['ID']['output'];
  organizationId: Scalars['ID']['output'];
  parentId: Scalars['ID']['output'];
  predecessorsIds?: Maybe<Array<Scalars['String']['output']>>;
  processAuditGroupName: Scalars['String']['output'];
  processDeadlineInDays?: Maybe<Scalars['Int']['output']>;
  reactivateTasksOnCancel?: Maybe<Array<Scalars['String']['output']>>;
  reactivateTasksOnFinish?: Maybe<Array<Scalars['String']['output']>>;
  reactivatedCancelLabel?: Maybe<Array<ProcessModelString>>;
  reactivatedFinishLabel?: Maybe<Array<ProcessModelString>>;
  reactivatedRequiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  recruitingConceptTaskId: Scalars['String']['output'];
  reminderSettings?: Maybe<TaskReminderSettings>;
  requiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  responsibleRoles: Array<Scalars['String']['output']>;
  rootId: Scalars['ID']['output'];
  rules?: Maybe<Array<ProcessTaskRule>>;
  selectFinishByDefault?: Maybe<Scalars['Boolean']['output']>;
  sortOrder: Scalars['Float']['output'];
  targetObject: ProcessTargetEnum;
  targetRoles: Array<Scalars['String']['output']>;
  taskEmails?: Maybe<Array<EmailTemplateDescription>>;
  visibleForRoles: Array<Scalars['String']['output']>;
};

export class ProcessTaskPdfFormGeneration implements ProcessTask {
  __typename?: 'ProcessTaskPdfFormGeneration';
  _etag?: Maybe<Scalars['String']['output']>;
  activationCondition?: Maybe<Scalars['String']['output']>;
  atLeastOnePredecessorIds?: Maybe<Array<Scalars['ID']['output']>>;
  autoStateChanges?: Maybe<Array<AutoStateChange>>;
  cancelLabel?: Maybe<Array<ProcessOptionalString>>;
  candidateDisplayId?: Maybe<Scalars['String']['output']>;
  candidateDisplayName?: Maybe<Scalars['String']['output']>;
  candidateId?: Maybe<Scalars['ID']['output']>;
  candidateOrganizationId?: Maybe<Scalars['ID']['output']>;
  checkTaskActivationPeriodically?: Maybe<Scalars['Boolean']['output']>;
  delayStartByDays?: Maybe<Scalars['Int']['output']>;
  displayDescription?: Maybe<Array<ProcessOptionalString>>;
  displayDescriptionQD?: Maybe<Array<ProcessOptionalQuillDelta>>;
  displayName?: Maybe<Array<ProcessOptionalString>>;
  doNotCreateOnSync?: Maybe<Scalars['Boolean']['output']>;
  fieldsToExcludeFromSync?: Maybe<Array<Scalars['String']['output']>>;
  finishLabel?: Maybe<Array<ProcessOptionalString>>;
  hideGenericTaskComment?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  instanceState: InstanceStateEnum;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  lastInstanceStateChangedAt?: Maybe<Scalars['DateTime']['output']>;
  lastUpdateManualStarted?: Maybe<Scalars['Boolean']['output']>;
  localizedCancelLabel?: Maybe<Scalars['String']['output']>;
  localizedDisplayDescription?: Maybe<Scalars['String']['output']>;
  localizedDisplayName?: Maybe<Scalars['String']['output']>;
  localizedFinishLabel?: Maybe<Scalars['String']['output']>;
  localizedReactivatedCancelLabel?: Maybe<Scalars['String']['output']>;
  localizedReactivatedFinishLabel?: Maybe<Scalars['String']['output']>;
  localizedRuleExecutionError?: Maybe<Scalars['String']['output']>;
  manuallyCreated?: Maybe<Scalars['Boolean']['output']>;
  modelId: Scalars['ID']['output'];
  organizationId: Scalars['ID']['output'];
  ownerRoles: Array<Scalars['String']['output']>;
  parentId: Scalars['ID']['output'];
  predecessorIds?: Maybe<Array<Scalars['ID']['output']>>;
  previousTaskId?: Maybe<Scalars['ID']['output']>;
  processInstanceState: ProcessInstanceStateEnum;
  reactivateTasksOnCancel?: Maybe<Array<Scalars['ID']['output']>>;
  reactivateTasksOnFinish?: Maybe<Array<Scalars['ID']['output']>>;
  reactivatedByTask?: Maybe<Scalars['ID']['output']>;
  reactivatedCancelLabel?: Maybe<Array<ProcessOptionalString>>;
  reactivatedFinishLabel?: Maybe<Array<ProcessOptionalString>>;
  reactivatedRequiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  readonly?: Maybe<Scalars['Boolean']['output']>;
  reminderSettings?: Maybe<TaskReminderSettings>;
  requiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  responsibleRoles: Array<Scalars['String']['output']>;
  rootId: Scalars['ID']['output'];
  ruleExecutionError?: Maybe<RuleExecutionError>;
  rules?: Maybe<Array<ProcessTaskRule>>;
  selectFinishByDefault?: Maybe<Scalars['Boolean']['output']>;
  sortOrder: Scalars['Float']['output'];
  targetObject: ProcessTargetEnum;
  targetRoles: Array<Scalars['String']['output']>;
  taskEmails?: Maybe<Array<EmailTemplateDescription>>;
  tokens: Array<Scalars['String']['output']>;
  visibleForRoles: Array<Scalars['String']['output']>;
};

export class ProcessTaskPdfFormGenerationModel {
  __typename?: 'ProcessTaskPdfFormGenerationModel';
  _className: Scalars['String']['output'];
  _typeName: Scalars['String']['output'];
  activationCondition?: Maybe<Scalars['String']['output']>;
  activationConditionBlocklyWorkspace?: Maybe<Scalars['String']['output']>;
  atLeastOnePredecessorIds?: Maybe<Array<Scalars['String']['output']>>;
  autoStateChanges?: Maybe<Array<AutoStateChangeModel>>;
  cancelLabel?: Maybe<Array<ProcessModelString>>;
  checkTaskActivationPeriodically?: Maybe<Scalars['Boolean']['output']>;
  delayStartByDays?: Maybe<Scalars['Int']['output']>;
  displayDescription?: Maybe<Array<ProcessModelString>>;
  displayName: Array<ProcessModelString>;
  doNotCreateOnSync?: Maybe<Scalars['Boolean']['output']>;
  dontSendEmails?: Maybe<Scalars['Boolean']['output']>;
  finishLabel?: Maybe<Array<ProcessModelString>>;
  hideGenericTaskComment?: Maybe<Scalars['Boolean']['output']>;
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  modelId: Scalars['ID']['output'];
  predecessorsIds?: Maybe<Array<Scalars['String']['output']>>;
  reactivateTasksOnCancel?: Maybe<Array<Scalars['String']['output']>>;
  reactivateTasksOnFinish?: Maybe<Array<Scalars['String']['output']>>;
  reactivatedCancelLabel?: Maybe<Array<ProcessModelString>>;
  reactivatedFinishLabel?: Maybe<Array<ProcessModelString>>;
  reactivatedRequiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  reminderSettings?: Maybe<TaskReminderSettings>;
  requiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  responsibleRoles: Array<Scalars['String']['output']>;
  rules?: Maybe<Array<ProcessTaskRule>>;
  selectFinishByDefault?: Maybe<Scalars['Boolean']['output']>;
  targetObject: ProcessTargetEnum;
  targetRoles: Array<Scalars['String']['output']>;
  taskEmails?: Maybe<Array<EmailTemplateDescription>>;
  tokens: Array<Scalars['String']['output']>;
  visibleForRoles: Array<Scalars['String']['output']>;
};

export class ProcessTaskPdfFormGenerationModelEntity implements ProcessTaskModelEntity {
  __typename?: 'ProcessTaskPdfFormGenerationModelEntity';
  _className: Scalars['String']['output'];
  _etag?: Maybe<Scalars['String']['output']>;
  _typeName: Scalars['String']['output'];
  activationCondition?: Maybe<Scalars['String']['output']>;
  activationConditionBlocklyWorkspace?: Maybe<Scalars['String']['output']>;
  atLeastOnePredecessorIds?: Maybe<Array<Scalars['String']['output']>>;
  autoStateChanges?: Maybe<Array<AutoStateChangeModel>>;
  cancelLabel?: Maybe<Array<ProcessModelString>>;
  checkTaskActivationPeriodically?: Maybe<Scalars['Boolean']['output']>;
  delayStartByDays?: Maybe<Scalars['Int']['output']>;
  displayDescription?: Maybe<Array<ProcessModelString>>;
  displayName: Array<ProcessModelString>;
  doNotCreateOnSync?: Maybe<Scalars['Boolean']['output']>;
  dontSendEmails?: Maybe<Scalars['Boolean']['output']>;
  finishLabel?: Maybe<Array<ProcessModelString>>;
  hideGenericTaskComment?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  modelId: Scalars['ID']['output'];
  organizationId: Scalars['ID']['output'];
  parentId: Scalars['ID']['output'];
  predecessorsIds?: Maybe<Array<Scalars['String']['output']>>;
  reactivateTasksOnCancel?: Maybe<Array<Scalars['String']['output']>>;
  reactivateTasksOnFinish?: Maybe<Array<Scalars['String']['output']>>;
  reactivatedCancelLabel?: Maybe<Array<ProcessModelString>>;
  reactivatedFinishLabel?: Maybe<Array<ProcessModelString>>;
  reactivatedRequiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  reminderSettings?: Maybe<TaskReminderSettings>;
  requiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  responsibleRoles: Array<Scalars['String']['output']>;
  rootId: Scalars['ID']['output'];
  rules?: Maybe<Array<ProcessTaskRule>>;
  selectFinishByDefault?: Maybe<Scalars['Boolean']['output']>;
  sortOrder: Scalars['Float']['output'];
  targetObject: ProcessTargetEnum;
  targetRoles: Array<Scalars['String']['output']>;
  taskEmails?: Maybe<Array<EmailTemplateDescription>>;
  tokens: Array<Scalars['String']['output']>;
  visibleForRoles: Array<Scalars['String']['output']>;
};

export class ProcessTaskReminder implements ProcessTask {
  __typename?: 'ProcessTaskReminder';
  _etag?: Maybe<Scalars['String']['output']>;
  activationCondition?: Maybe<Scalars['String']['output']>;
  atLeastOnePredecessorIds?: Maybe<Array<Scalars['ID']['output']>>;
  autoStateChanges?: Maybe<Array<AutoStateChange>>;
  cancelLabel?: Maybe<Array<ProcessOptionalString>>;
  candidateDisplayId?: Maybe<Scalars['String']['output']>;
  candidateDisplayName?: Maybe<Scalars['String']['output']>;
  candidateId?: Maybe<Scalars['ID']['output']>;
  candidateOrganizationId?: Maybe<Scalars['ID']['output']>;
  checkTaskActivationPeriodically?: Maybe<Scalars['Boolean']['output']>;
  delayStartByDays?: Maybe<Scalars['Int']['output']>;
  displayDescription?: Maybe<Array<ProcessOptionalString>>;
  displayDescriptionQD?: Maybe<Array<ProcessOptionalQuillDelta>>;
  displayName?: Maybe<Array<ProcessOptionalString>>;
  doNotCreateOnSync?: Maybe<Scalars['Boolean']['output']>;
  fieldsToExcludeFromSync?: Maybe<Array<Scalars['String']['output']>>;
  finishLabel?: Maybe<Array<ProcessOptionalString>>;
  hideGenericTaskComment?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  instanceState: InstanceStateEnum;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  lastInstanceStateChangedAt?: Maybe<Scalars['DateTime']['output']>;
  lastUpdateManualStarted?: Maybe<Scalars['Boolean']['output']>;
  localizedCancelLabel?: Maybe<Scalars['String']['output']>;
  localizedDisplayDescription?: Maybe<Scalars['String']['output']>;
  localizedDisplayName?: Maybe<Scalars['String']['output']>;
  localizedFinishLabel?: Maybe<Scalars['String']['output']>;
  localizedReactivatedCancelLabel?: Maybe<Scalars['String']['output']>;
  localizedReactivatedFinishLabel?: Maybe<Scalars['String']['output']>;
  localizedRuleExecutionError?: Maybe<Scalars['String']['output']>;
  manuallyCreated?: Maybe<Scalars['Boolean']['output']>;
  modelId: Scalars['ID']['output'];
  organizationId: Scalars['ID']['output'];
  ownerRoles: Array<Scalars['String']['output']>;
  parentId: Scalars['ID']['output'];
  predecessorIds?: Maybe<Array<Scalars['ID']['output']>>;
  previousTaskId?: Maybe<Scalars['ID']['output']>;
  processInstanceState: ProcessInstanceStateEnum;
  reactivateTasksOnCancel?: Maybe<Array<Scalars['ID']['output']>>;
  reactivateTasksOnFinish?: Maybe<Array<Scalars['ID']['output']>>;
  reactivatedByTask?: Maybe<Scalars['ID']['output']>;
  reactivatedCancelLabel?: Maybe<Array<ProcessOptionalString>>;
  reactivatedFinishLabel?: Maybe<Array<ProcessOptionalString>>;
  reactivatedRequiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  readonly?: Maybe<Scalars['Boolean']['output']>;
  reminderDate?: Maybe<Scalars['DateTime']['output']>;
  reminderSettings?: Maybe<TaskReminderSettings>;
  requiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  responsibleRoles: Array<Scalars['String']['output']>;
  rootId: Scalars['ID']['output'];
  ruleExecutionError?: Maybe<RuleExecutionError>;
  rules?: Maybe<Array<ProcessTaskRule>>;
  selectFinishByDefault?: Maybe<Scalars['Boolean']['output']>;
  sortOrder: Scalars['Float']['output'];
  targetObject: ProcessTargetEnum;
  targetRoles: Array<Scalars['String']['output']>;
  taskEmails?: Maybe<Array<EmailTemplateDescription>>;
  visibleForRoles: Array<Scalars['String']['output']>;
};

export class ProcessTaskReminderModel {
  __typename?: 'ProcessTaskReminderModel';
  _className: Scalars['String']['output'];
  _typeName: Scalars['String']['output'];
  activationCondition?: Maybe<Scalars['String']['output']>;
  activationConditionBlocklyWorkspace?: Maybe<Scalars['String']['output']>;
  atLeastOnePredecessorIds?: Maybe<Array<Scalars['String']['output']>>;
  autoStateChanges?: Maybe<Array<AutoStateChangeModel>>;
  cancelLabel?: Maybe<Array<ProcessModelString>>;
  checkTaskActivationPeriodically?: Maybe<Scalars['Boolean']['output']>;
  delayStartByDays?: Maybe<Scalars['Int']['output']>;
  displayDescription?: Maybe<Array<ProcessModelString>>;
  displayName: Array<ProcessModelString>;
  doNotCreateOnSync?: Maybe<Scalars['Boolean']['output']>;
  dontSendEmails?: Maybe<Scalars['Boolean']['output']>;
  finishLabel?: Maybe<Array<ProcessModelString>>;
  hideGenericTaskComment?: Maybe<Scalars['Boolean']['output']>;
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  modelId: Scalars['ID']['output'];
  predecessorsIds?: Maybe<Array<Scalars['String']['output']>>;
  reactivateTasksOnCancel?: Maybe<Array<Scalars['String']['output']>>;
  reactivateTasksOnFinish?: Maybe<Array<Scalars['String']['output']>>;
  reactivatedCancelLabel?: Maybe<Array<ProcessModelString>>;
  reactivatedFinishLabel?: Maybe<Array<ProcessModelString>>;
  reactivatedRequiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  reminderDate?: Maybe<Scalars['DateTime']['output']>;
  reminderSettings?: Maybe<TaskReminderSettings>;
  requiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  responsibleRoles: Array<Scalars['String']['output']>;
  rules?: Maybe<Array<ProcessTaskRule>>;
  selectFinishByDefault?: Maybe<Scalars['Boolean']['output']>;
  targetObject: ProcessTargetEnum;
  targetRoles: Array<Scalars['String']['output']>;
  taskEmails?: Maybe<Array<EmailTemplateDescription>>;
  visibleForRoles: Array<Scalars['String']['output']>;
};

export class ProcessTaskReminderModelEntity implements ProcessTaskModelEntity {
  __typename?: 'ProcessTaskReminderModelEntity';
  _className: Scalars['String']['output'];
  _etag?: Maybe<Scalars['String']['output']>;
  _typeName: Scalars['String']['output'];
  activationCondition?: Maybe<Scalars['String']['output']>;
  activationConditionBlocklyWorkspace?: Maybe<Scalars['String']['output']>;
  atLeastOnePredecessorIds?: Maybe<Array<Scalars['String']['output']>>;
  autoStateChanges?: Maybe<Array<AutoStateChangeModel>>;
  cancelLabel?: Maybe<Array<ProcessModelString>>;
  checkTaskActivationPeriodically?: Maybe<Scalars['Boolean']['output']>;
  delayStartByDays?: Maybe<Scalars['Int']['output']>;
  displayDescription?: Maybe<Array<ProcessModelString>>;
  displayName: Array<ProcessModelString>;
  doNotCreateOnSync?: Maybe<Scalars['Boolean']['output']>;
  dontSendEmails?: Maybe<Scalars['Boolean']['output']>;
  finishLabel?: Maybe<Array<ProcessModelString>>;
  hideGenericTaskComment?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  modelId: Scalars['ID']['output'];
  organizationId: Scalars['ID']['output'];
  parentId: Scalars['ID']['output'];
  predecessorsIds?: Maybe<Array<Scalars['String']['output']>>;
  reactivateTasksOnCancel?: Maybe<Array<Scalars['String']['output']>>;
  reactivateTasksOnFinish?: Maybe<Array<Scalars['String']['output']>>;
  reactivatedCancelLabel?: Maybe<Array<ProcessModelString>>;
  reactivatedFinishLabel?: Maybe<Array<ProcessModelString>>;
  reactivatedRequiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  reminderDate?: Maybe<Scalars['DateTime']['output']>;
  reminderSettings?: Maybe<TaskReminderSettings>;
  requiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  responsibleRoles: Array<Scalars['String']['output']>;
  rootId: Scalars['ID']['output'];
  rules?: Maybe<Array<ProcessTaskRule>>;
  selectFinishByDefault?: Maybe<Scalars['Boolean']['output']>;
  sortOrder: Scalars['Float']['output'];
  targetObject: ProcessTargetEnum;
  targetRoles: Array<Scalars['String']['output']>;
  taskEmails?: Maybe<Array<EmailTemplateDescription>>;
  visibleForRoles: Array<Scalars['String']['output']>;
};

export type ProcessTaskResult = ProcessTaskAssignmentException | ProcessTaskAutoOperation | ProcessTaskDeactivateProcess | ProcessTaskDocumentCheck | ProcessTaskEmailSend | ProcessTaskEnterMissingInformation | ProcessTaskEnterMissingInformationV2 | ProcessTaskInformationCollection | ProcessTaskInformationSendOut | ProcessTaskInviteToPlatform | ProcessTaskOrganizationAudit | ProcessTaskPdfFormGeneration | ProcessTaskReminder | ProcessTaskRoleSetup | ProcessTaskTriggerEvent | ProcessTaskUpdateCandidateProfile | ProcessTaskUpload | ProcessTaskWork;

export class ProcessTaskRoleSetup implements ProcessTask {
  __typename?: 'ProcessTaskRoleSetup';
  _etag?: Maybe<Scalars['String']['output']>;
  activationCondition?: Maybe<Scalars['String']['output']>;
  atLeastOnePredecessorIds?: Maybe<Array<Scalars['ID']['output']>>;
  autoStateChanges?: Maybe<Array<AutoStateChange>>;
  cancelLabel?: Maybe<Array<ProcessOptionalString>>;
  candidateDisplayId?: Maybe<Scalars['String']['output']>;
  candidateDisplayName?: Maybe<Scalars['String']['output']>;
  candidateId?: Maybe<Scalars['ID']['output']>;
  candidateOrganizationId?: Maybe<Scalars['ID']['output']>;
  checkTaskActivationPeriodically?: Maybe<Scalars['Boolean']['output']>;
  delayStartByDays?: Maybe<Scalars['Int']['output']>;
  displayDescription?: Maybe<Array<ProcessOptionalString>>;
  displayDescriptionQD?: Maybe<Array<ProcessOptionalQuillDelta>>;
  displayName?: Maybe<Array<ProcessOptionalString>>;
  doNotCreateOnSync?: Maybe<Scalars['Boolean']['output']>;
  fieldsToExcludeFromSync?: Maybe<Array<Scalars['String']['output']>>;
  finishLabel?: Maybe<Array<ProcessOptionalString>>;
  hideGenericTaskComment?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  instanceState: InstanceStateEnum;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  lastInstanceStateChangedAt?: Maybe<Scalars['DateTime']['output']>;
  lastUpdateManualStarted?: Maybe<Scalars['Boolean']['output']>;
  localizedCancelLabel?: Maybe<Scalars['String']['output']>;
  localizedDisplayDescription?: Maybe<Scalars['String']['output']>;
  localizedDisplayName?: Maybe<Scalars['String']['output']>;
  localizedFinishLabel?: Maybe<Scalars['String']['output']>;
  localizedReactivatedCancelLabel?: Maybe<Scalars['String']['output']>;
  localizedReactivatedFinishLabel?: Maybe<Scalars['String']['output']>;
  localizedRuleExecutionError?: Maybe<Scalars['String']['output']>;
  manuallyCreated?: Maybe<Scalars['Boolean']['output']>;
  modelId: Scalars['ID']['output'];
  organizationId: Scalars['ID']['output'];
  ownerRoles: Array<Scalars['String']['output']>;
  parentId: Scalars['ID']['output'];
  predecessorIds?: Maybe<Array<Scalars['ID']['output']>>;
  previousTaskId?: Maybe<Scalars['ID']['output']>;
  processInstanceState: ProcessInstanceStateEnum;
  reactivateTasksOnCancel?: Maybe<Array<Scalars['ID']['output']>>;
  reactivateTasksOnFinish?: Maybe<Array<Scalars['ID']['output']>>;
  reactivatedByTask?: Maybe<Scalars['ID']['output']>;
  reactivatedCancelLabel?: Maybe<Array<ProcessOptionalString>>;
  reactivatedFinishLabel?: Maybe<Array<ProcessOptionalString>>;
  reactivatedRequiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  readonly?: Maybe<Scalars['Boolean']['output']>;
  reminderSettings?: Maybe<TaskReminderSettings>;
  requiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  responsibleRoles: Array<Scalars['String']['output']>;
  rootId: Scalars['ID']['output'];
  ruleExecutionError?: Maybe<RuleExecutionError>;
  rules?: Maybe<Array<ProcessTaskRule>>;
  selectFinishByDefault?: Maybe<Scalars['Boolean']['output']>;
  setupRoles: Array<Scalars['String']['output']>;
  sortOrder: Scalars['Float']['output'];
  targetObject: ProcessTargetEnum;
  targetRoles: Array<Scalars['String']['output']>;
  taskEmails?: Maybe<Array<EmailTemplateDescription>>;
  visibleForRoles: Array<Scalars['String']['output']>;
};

export class ProcessTaskRoleSetupModel {
  __typename?: 'ProcessTaskRoleSetupModel';
  _className: Scalars['String']['output'];
  _typeName: Scalars['String']['output'];
  activationCondition?: Maybe<Scalars['String']['output']>;
  activationConditionBlocklyWorkspace?: Maybe<Scalars['String']['output']>;
  atLeastOnePredecessorIds?: Maybe<Array<Scalars['String']['output']>>;
  autoStateChanges?: Maybe<Array<AutoStateChangeModel>>;
  cancelLabel?: Maybe<Array<ProcessModelString>>;
  checkTaskActivationPeriodically?: Maybe<Scalars['Boolean']['output']>;
  delayStartByDays?: Maybe<Scalars['Int']['output']>;
  displayDescription?: Maybe<Array<ProcessModelString>>;
  displayName: Array<ProcessModelString>;
  doNotCreateOnSync?: Maybe<Scalars['Boolean']['output']>;
  dontSendEmails?: Maybe<Scalars['Boolean']['output']>;
  finishLabel?: Maybe<Array<ProcessModelString>>;
  hideGenericTaskComment?: Maybe<Scalars['Boolean']['output']>;
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  modelId: Scalars['ID']['output'];
  predecessorsIds?: Maybe<Array<Scalars['String']['output']>>;
  reactivateTasksOnCancel?: Maybe<Array<Scalars['String']['output']>>;
  reactivateTasksOnFinish?: Maybe<Array<Scalars['String']['output']>>;
  reactivatedCancelLabel?: Maybe<Array<ProcessModelString>>;
  reactivatedFinishLabel?: Maybe<Array<ProcessModelString>>;
  reactivatedRequiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  reminderSettings?: Maybe<TaskReminderSettings>;
  requiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  responsibleRoles: Array<Scalars['String']['output']>;
  rules?: Maybe<Array<ProcessTaskRule>>;
  selectFinishByDefault?: Maybe<Scalars['Boolean']['output']>;
  setupRoles: Array<Scalars['String']['output']>;
  targetObject: ProcessTargetEnum;
  targetRoles: Array<Scalars['String']['output']>;
  taskEmails?: Maybe<Array<EmailTemplateDescription>>;
  visibleForRoles: Array<Scalars['String']['output']>;
};

export class ProcessTaskRoleSetupModelEntity implements ProcessTaskModelEntity {
  __typename?: 'ProcessTaskRoleSetupModelEntity';
  _className: Scalars['String']['output'];
  _etag?: Maybe<Scalars['String']['output']>;
  _typeName: Scalars['String']['output'];
  activationCondition?: Maybe<Scalars['String']['output']>;
  activationConditionBlocklyWorkspace?: Maybe<Scalars['String']['output']>;
  atLeastOnePredecessorIds?: Maybe<Array<Scalars['String']['output']>>;
  autoStateChanges?: Maybe<Array<AutoStateChangeModel>>;
  cancelLabel?: Maybe<Array<ProcessModelString>>;
  checkTaskActivationPeriodically?: Maybe<Scalars['Boolean']['output']>;
  delayStartByDays?: Maybe<Scalars['Int']['output']>;
  displayDescription?: Maybe<Array<ProcessModelString>>;
  displayName: Array<ProcessModelString>;
  doNotCreateOnSync?: Maybe<Scalars['Boolean']['output']>;
  dontSendEmails?: Maybe<Scalars['Boolean']['output']>;
  finishLabel?: Maybe<Array<ProcessModelString>>;
  hideGenericTaskComment?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  modelId: Scalars['ID']['output'];
  organizationId: Scalars['ID']['output'];
  parentId: Scalars['ID']['output'];
  predecessorsIds?: Maybe<Array<Scalars['String']['output']>>;
  reactivateTasksOnCancel?: Maybe<Array<Scalars['String']['output']>>;
  reactivateTasksOnFinish?: Maybe<Array<Scalars['String']['output']>>;
  reactivatedCancelLabel?: Maybe<Array<ProcessModelString>>;
  reactivatedFinishLabel?: Maybe<Array<ProcessModelString>>;
  reactivatedRequiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  reminderSettings?: Maybe<TaskReminderSettings>;
  requiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  responsibleRoles: Array<Scalars['String']['output']>;
  rootId: Scalars['ID']['output'];
  rules?: Maybe<Array<ProcessTaskRule>>;
  selectFinishByDefault?: Maybe<Scalars['Boolean']['output']>;
  setupRoles: Array<Scalars['String']['output']>;
  sortOrder: Scalars['Float']['output'];
  targetObject: ProcessTargetEnum;
  targetRoles: Array<Scalars['String']['output']>;
  taskEmails?: Maybe<Array<EmailTemplateDescription>>;
  visibleForRoles: Array<Scalars['String']['output']>;
};

export class ProcessTaskRule {
  __typename?: 'ProcessTaskRule';
  event: ProcessRuleTriggerEvent;
  expression?: Maybe<Scalars['String']['output']>;
  expressionBlocklyWorkspace?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  propertyName?: Maybe<Scalars['String']['output']>;
  ruleType: ProcessRuleType;
  runCondition?: Maybe<Scalars['String']['output']>;
  runConditionBlocklyWorkspace?: Maybe<Scalars['String']['output']>;
  shortDescription?: Maybe<Scalars['String']['output']>;
  targetTasks?: Maybe<RuleTaskTarget>;
  targetType: ProcessRuleTargetType;
};

export class ProcessTaskRuleInput {
  event: ProcessRuleTriggerEvent;
  expression?: InputMaybe<Scalars['String']['input']>;
  expressionBlocklyWorkspace?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  propertyName?: InputMaybe<Scalars['String']['input']>;
  ruleType: ProcessRuleType;
  runCondition?: InputMaybe<Scalars['String']['input']>;
  runConditionBlocklyWorkspace?: InputMaybe<Scalars['String']['input']>;
  shortDescription?: InputMaybe<Scalars['String']['input']>;
  targetTasks?: InputMaybe<RuleTaskTargetInput>;
  targetType: ProcessRuleTargetType;
};

export class ProcessTaskTriggerEvent implements ProcessTask {
  __typename?: 'ProcessTaskTriggerEvent';
  _etag?: Maybe<Scalars['String']['output']>;
  activationCondition?: Maybe<Scalars['String']['output']>;
  atLeastOnePredecessorIds?: Maybe<Array<Scalars['ID']['output']>>;
  autoStateChanges?: Maybe<Array<AutoStateChange>>;
  cancelLabel?: Maybe<Array<ProcessOptionalString>>;
  candidateDisplayId?: Maybe<Scalars['String']['output']>;
  candidateDisplayName?: Maybe<Scalars['String']['output']>;
  candidateId?: Maybe<Scalars['ID']['output']>;
  candidateOrganizationId?: Maybe<Scalars['ID']['output']>;
  checkTaskActivationPeriodically?: Maybe<Scalars['Boolean']['output']>;
  delayStartByDays?: Maybe<Scalars['Int']['output']>;
  displayDescription?: Maybe<Array<ProcessOptionalString>>;
  displayDescriptionQD?: Maybe<Array<ProcessOptionalQuillDelta>>;
  displayName?: Maybe<Array<ProcessOptionalString>>;
  doNotCreateOnSync?: Maybe<Scalars['Boolean']['output']>;
  fieldsToExcludeFromSync?: Maybe<Array<Scalars['String']['output']>>;
  finishLabel?: Maybe<Array<ProcessOptionalString>>;
  hideGenericTaskComment?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  instanceState: InstanceStateEnum;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  lastInstanceStateChangedAt?: Maybe<Scalars['DateTime']['output']>;
  lastUpdateManualStarted?: Maybe<Scalars['Boolean']['output']>;
  localizedCancelLabel?: Maybe<Scalars['String']['output']>;
  localizedDisplayDescription?: Maybe<Scalars['String']['output']>;
  localizedDisplayName?: Maybe<Scalars['String']['output']>;
  localizedFinishLabel?: Maybe<Scalars['String']['output']>;
  localizedReactivatedCancelLabel?: Maybe<Scalars['String']['output']>;
  localizedReactivatedFinishLabel?: Maybe<Scalars['String']['output']>;
  localizedRuleExecutionError?: Maybe<Scalars['String']['output']>;
  manuallyCreated?: Maybe<Scalars['Boolean']['output']>;
  modelId: Scalars['ID']['output'];
  organizationId: Scalars['ID']['output'];
  ownerRoles: Array<Scalars['String']['output']>;
  parentId: Scalars['ID']['output'];
  predecessorIds?: Maybe<Array<Scalars['ID']['output']>>;
  previousTaskId?: Maybe<Scalars['ID']['output']>;
  processEvent: ProcessEventEnum;
  processInstanceState: ProcessInstanceStateEnum;
  reactivateTasksOnCancel?: Maybe<Array<Scalars['ID']['output']>>;
  reactivateTasksOnFinish?: Maybe<Array<Scalars['ID']['output']>>;
  reactivatedByTask?: Maybe<Scalars['ID']['output']>;
  reactivatedCancelLabel?: Maybe<Array<ProcessOptionalString>>;
  reactivatedFinishLabel?: Maybe<Array<ProcessOptionalString>>;
  reactivatedRequiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  readonly?: Maybe<Scalars['Boolean']['output']>;
  reminderSettings?: Maybe<TaskReminderSettings>;
  requiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  responsibleRoles: Array<Scalars['String']['output']>;
  rootId: Scalars['ID']['output'];
  ruleExecutionError?: Maybe<RuleExecutionError>;
  rules?: Maybe<Array<ProcessTaskRule>>;
  selectFinishByDefault?: Maybe<Scalars['Boolean']['output']>;
  sortOrder: Scalars['Float']['output'];
  targetObject: ProcessTargetEnum;
  targetRoles: Array<Scalars['String']['output']>;
  taskEmails?: Maybe<Array<EmailTemplateDescription>>;
  visibleForRoles: Array<Scalars['String']['output']>;
};

export class ProcessTaskTriggerEventModel {
  __typename?: 'ProcessTaskTriggerEventModel';
  _className: Scalars['String']['output'];
  _typeName: Scalars['String']['output'];
  activationCondition?: Maybe<Scalars['String']['output']>;
  activationConditionBlocklyWorkspace?: Maybe<Scalars['String']['output']>;
  atLeastOnePredecessorIds?: Maybe<Array<Scalars['String']['output']>>;
  autoStateChanges?: Maybe<Array<AutoStateChangeModel>>;
  cancelLabel?: Maybe<Array<ProcessModelString>>;
  checkTaskActivationPeriodically?: Maybe<Scalars['Boolean']['output']>;
  delayStartByDays?: Maybe<Scalars['Int']['output']>;
  displayDescription?: Maybe<Array<ProcessModelString>>;
  displayName: Array<ProcessModelString>;
  doNotCreateOnSync?: Maybe<Scalars['Boolean']['output']>;
  dontSendEmails?: Maybe<Scalars['Boolean']['output']>;
  finishLabel?: Maybe<Array<ProcessModelString>>;
  hideGenericTaskComment?: Maybe<Scalars['Boolean']['output']>;
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  modelId: Scalars['ID']['output'];
  predecessorsIds?: Maybe<Array<Scalars['String']['output']>>;
  processEvent: ProcessEventEnum;
  reactivateTasksOnCancel?: Maybe<Array<Scalars['String']['output']>>;
  reactivateTasksOnFinish?: Maybe<Array<Scalars['String']['output']>>;
  reactivatedCancelLabel?: Maybe<Array<ProcessModelString>>;
  reactivatedFinishLabel?: Maybe<Array<ProcessModelString>>;
  reactivatedRequiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  reminderSettings?: Maybe<TaskReminderSettings>;
  requiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  responsibleRoles: Array<Scalars['String']['output']>;
  rules?: Maybe<Array<ProcessTaskRule>>;
  selectFinishByDefault?: Maybe<Scalars['Boolean']['output']>;
  targetObject: ProcessTargetEnum;
  targetRoles: Array<Scalars['String']['output']>;
  taskEmails?: Maybe<Array<EmailTemplateDescription>>;
  visibleForRoles: Array<Scalars['String']['output']>;
};

export class ProcessTaskTriggerEventModelEntity implements ProcessTaskModelEntity {
  __typename?: 'ProcessTaskTriggerEventModelEntity';
  _className: Scalars['String']['output'];
  _etag?: Maybe<Scalars['String']['output']>;
  _typeName: Scalars['String']['output'];
  activationCondition?: Maybe<Scalars['String']['output']>;
  activationConditionBlocklyWorkspace?: Maybe<Scalars['String']['output']>;
  atLeastOnePredecessorIds?: Maybe<Array<Scalars['String']['output']>>;
  autoStateChanges?: Maybe<Array<AutoStateChangeModel>>;
  cancelLabel?: Maybe<Array<ProcessModelString>>;
  checkTaskActivationPeriodically?: Maybe<Scalars['Boolean']['output']>;
  delayStartByDays?: Maybe<Scalars['Int']['output']>;
  displayDescription?: Maybe<Array<ProcessModelString>>;
  displayName: Array<ProcessModelString>;
  doNotCreateOnSync?: Maybe<Scalars['Boolean']['output']>;
  dontSendEmails?: Maybe<Scalars['Boolean']['output']>;
  finishLabel?: Maybe<Array<ProcessModelString>>;
  hideGenericTaskComment?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  modelId: Scalars['ID']['output'];
  organizationId: Scalars['ID']['output'];
  parentId: Scalars['ID']['output'];
  predecessorsIds?: Maybe<Array<Scalars['String']['output']>>;
  processEvent: ProcessEventEnum;
  reactivateTasksOnCancel?: Maybe<Array<Scalars['String']['output']>>;
  reactivateTasksOnFinish?: Maybe<Array<Scalars['String']['output']>>;
  reactivatedCancelLabel?: Maybe<Array<ProcessModelString>>;
  reactivatedFinishLabel?: Maybe<Array<ProcessModelString>>;
  reactivatedRequiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  reminderSettings?: Maybe<TaskReminderSettings>;
  requiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  responsibleRoles: Array<Scalars['String']['output']>;
  rootId: Scalars['ID']['output'];
  rules?: Maybe<Array<ProcessTaskRule>>;
  selectFinishByDefault?: Maybe<Scalars['Boolean']['output']>;
  sortOrder: Scalars['Float']['output'];
  targetObject: ProcessTargetEnum;
  targetRoles: Array<Scalars['String']['output']>;
  taskEmails?: Maybe<Array<EmailTemplateDescription>>;
  visibleForRoles: Array<Scalars['String']['output']>;
};

export class ProcessTaskUpdateCandidateProfile implements ProcessTask {
  __typename?: 'ProcessTaskUpdateCandidateProfile';
  _etag?: Maybe<Scalars['String']['output']>;
  activationCondition?: Maybe<Scalars['String']['output']>;
  atLeastOnePredecessorIds?: Maybe<Array<Scalars['ID']['output']>>;
  autoStateChanges?: Maybe<Array<AutoStateChange>>;
  cancelLabel?: Maybe<Array<ProcessOptionalString>>;
  candidateDisplayId?: Maybe<Scalars['String']['output']>;
  candidateDisplayName?: Maybe<Scalars['String']['output']>;
  candidateId?: Maybe<Scalars['ID']['output']>;
  candidateOrganizationId?: Maybe<Scalars['ID']['output']>;
  checkTaskActivationPeriodically?: Maybe<Scalars['Boolean']['output']>;
  delayStartByDays?: Maybe<Scalars['Int']['output']>;
  displayDescription?: Maybe<Array<ProcessOptionalString>>;
  displayDescriptionQD?: Maybe<Array<ProcessOptionalQuillDelta>>;
  displayName?: Maybe<Array<ProcessOptionalString>>;
  doNotCreateOnSync?: Maybe<Scalars['Boolean']['output']>;
  fieldsToExcludeFromSync?: Maybe<Array<Scalars['String']['output']>>;
  finishLabel?: Maybe<Array<ProcessOptionalString>>;
  hideGenericTaskComment?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  instanceState: InstanceStateEnum;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  lastInstanceStateChangedAt?: Maybe<Scalars['DateTime']['output']>;
  lastUpdateManualStarted?: Maybe<Scalars['Boolean']['output']>;
  localizedCancelLabel?: Maybe<Scalars['String']['output']>;
  localizedDisplayDescription?: Maybe<Scalars['String']['output']>;
  localizedDisplayName?: Maybe<Scalars['String']['output']>;
  localizedFinishLabel?: Maybe<Scalars['String']['output']>;
  localizedReactivatedCancelLabel?: Maybe<Scalars['String']['output']>;
  localizedReactivatedFinishLabel?: Maybe<Scalars['String']['output']>;
  localizedRuleExecutionError?: Maybe<Scalars['String']['output']>;
  manuallyCreated?: Maybe<Scalars['Boolean']['output']>;
  modelId: Scalars['ID']['output'];
  organizationId: Scalars['ID']['output'];
  ownerRoles: Array<Scalars['String']['output']>;
  parentId: Scalars['ID']['output'];
  predecessorIds?: Maybe<Array<Scalars['ID']['output']>>;
  previousTaskId?: Maybe<Scalars['ID']['output']>;
  processInstanceState: ProcessInstanceStateEnum;
  properties: Array<CandidateProfileProperty>;
  reactivateTasksOnCancel?: Maybe<Array<Scalars['ID']['output']>>;
  reactivateTasksOnFinish?: Maybe<Array<Scalars['ID']['output']>>;
  reactivatedByTask?: Maybe<Scalars['ID']['output']>;
  reactivatedCancelLabel?: Maybe<Array<ProcessOptionalString>>;
  reactivatedFinishLabel?: Maybe<Array<ProcessOptionalString>>;
  reactivatedRequiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  readonly?: Maybe<Scalars['Boolean']['output']>;
  reminderSettings?: Maybe<TaskReminderSettings>;
  requiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  responsibleRoles: Array<Scalars['String']['output']>;
  rootId: Scalars['ID']['output'];
  ruleExecutionError?: Maybe<RuleExecutionError>;
  rules?: Maybe<Array<ProcessTaskRule>>;
  selectFinishByDefault?: Maybe<Scalars['Boolean']['output']>;
  sortOrder: Scalars['Float']['output'];
  targetObject: ProcessTargetEnum;
  targetRoles: Array<Scalars['String']['output']>;
  taskEmails?: Maybe<Array<EmailTemplateDescription>>;
  visibleForRoles: Array<Scalars['String']['output']>;
};

export class ProcessTaskUpdateCandidateProfileModel {
  __typename?: 'ProcessTaskUpdateCandidateProfileModel';
  _className: Scalars['String']['output'];
  _typeName: Scalars['String']['output'];
  activationCondition?: Maybe<Scalars['String']['output']>;
  activationConditionBlocklyWorkspace?: Maybe<Scalars['String']['output']>;
  atLeastOnePredecessorIds?: Maybe<Array<Scalars['String']['output']>>;
  autoStateChanges?: Maybe<Array<AutoStateChangeModel>>;
  cancelLabel?: Maybe<Array<ProcessModelString>>;
  checkTaskActivationPeriodically?: Maybe<Scalars['Boolean']['output']>;
  delayStartByDays?: Maybe<Scalars['Int']['output']>;
  displayDescription?: Maybe<Array<ProcessModelString>>;
  displayName: Array<ProcessModelString>;
  doNotCreateOnSync?: Maybe<Scalars['Boolean']['output']>;
  dontSendEmails?: Maybe<Scalars['Boolean']['output']>;
  finishLabel?: Maybe<Array<ProcessModelString>>;
  hideGenericTaskComment?: Maybe<Scalars['Boolean']['output']>;
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  modelId: Scalars['ID']['output'];
  predecessorsIds?: Maybe<Array<Scalars['String']['output']>>;
  properties: Array<CandidateProfileProperty>;
  reactivateTasksOnCancel?: Maybe<Array<Scalars['String']['output']>>;
  reactivateTasksOnFinish?: Maybe<Array<Scalars['String']['output']>>;
  reactivatedCancelLabel?: Maybe<Array<ProcessModelString>>;
  reactivatedFinishLabel?: Maybe<Array<ProcessModelString>>;
  reactivatedRequiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  reminderSettings?: Maybe<TaskReminderSettings>;
  requiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  responsibleRoles: Array<Scalars['String']['output']>;
  rules?: Maybe<Array<ProcessTaskRule>>;
  selectFinishByDefault?: Maybe<Scalars['Boolean']['output']>;
  targetObject: ProcessTargetEnum;
  targetRoles: Array<Scalars['String']['output']>;
  taskEmails?: Maybe<Array<EmailTemplateDescription>>;
  visibleForRoles: Array<Scalars['String']['output']>;
};

export class ProcessTaskUpdateCandidateProfileModelEntity implements ProcessTaskModelEntity {
  __typename?: 'ProcessTaskUpdateCandidateProfileModelEntity';
  _className: Scalars['String']['output'];
  _etag?: Maybe<Scalars['String']['output']>;
  _typeName: Scalars['String']['output'];
  activationCondition?: Maybe<Scalars['String']['output']>;
  activationConditionBlocklyWorkspace?: Maybe<Scalars['String']['output']>;
  atLeastOnePredecessorIds?: Maybe<Array<Scalars['String']['output']>>;
  autoStateChanges?: Maybe<Array<AutoStateChangeModel>>;
  cancelLabel?: Maybe<Array<ProcessModelString>>;
  checkTaskActivationPeriodically?: Maybe<Scalars['Boolean']['output']>;
  delayStartByDays?: Maybe<Scalars['Int']['output']>;
  displayDescription?: Maybe<Array<ProcessModelString>>;
  displayName: Array<ProcessModelString>;
  doNotCreateOnSync?: Maybe<Scalars['Boolean']['output']>;
  dontSendEmails?: Maybe<Scalars['Boolean']['output']>;
  finishLabel?: Maybe<Array<ProcessModelString>>;
  hideGenericTaskComment?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  modelId: Scalars['ID']['output'];
  organizationId: Scalars['ID']['output'];
  parentId: Scalars['ID']['output'];
  predecessorsIds?: Maybe<Array<Scalars['String']['output']>>;
  properties: Array<CandidateProfileProperty>;
  reactivateTasksOnCancel?: Maybe<Array<Scalars['String']['output']>>;
  reactivateTasksOnFinish?: Maybe<Array<Scalars['String']['output']>>;
  reactivatedCancelLabel?: Maybe<Array<ProcessModelString>>;
  reactivatedFinishLabel?: Maybe<Array<ProcessModelString>>;
  reactivatedRequiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  reminderSettings?: Maybe<TaskReminderSettings>;
  requiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  responsibleRoles: Array<Scalars['String']['output']>;
  rootId: Scalars['ID']['output'];
  rules?: Maybe<Array<ProcessTaskRule>>;
  selectFinishByDefault?: Maybe<Scalars['Boolean']['output']>;
  sortOrder: Scalars['Float']['output'];
  targetObject: ProcessTargetEnum;
  targetRoles: Array<Scalars['String']['output']>;
  taskEmails?: Maybe<Array<EmailTemplateDescription>>;
  visibleForRoles: Array<Scalars['String']['output']>;
};

export class ProcessTaskUpdateInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  acceptFileTypes?: InputMaybe<Scalars['String']['input']>;
  activationCondition?: InputMaybe<Scalars['String']['input']>;
  assignmentExceptionOperation?: InputMaybe<CandidateAssignmentExceptionOperation>;
  assignmentExceptionType?: InputMaybe<CandidateAssignmentExceptionType>;
  atLeastOnePredecessorIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  attachDownloadableTaskFilesToEmails?: InputMaybe<Scalars['Boolean']['input']>;
  attachmentError?: InputMaybe<Scalars['String']['input']>;
  attachmentForms?: InputMaybe<Array<Scalars['String']['input']>>;
  attachmentTaskIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  attachmentTemplates?: InputMaybe<Array<Scalars['String']['input']>>;
  attachmentTemplatesFileType?: InputMaybe<Array<AttachmentTemplateFileType>>;
  attachments?: InputMaybe<Array<FileAttachmentInput>>;
  attachmentsAllowedOnTopLevel?: InputMaybe<Scalars['Boolean']['input']>;
  attachmentsDisplayText?: InputMaybe<Array<ProcessOptionalStringInput>>;
  attachmentsRequired?: InputMaybe<Scalars['Boolean']['input']>;
  auditDefinitionName?: InputMaybe<Scalars['String']['input']>;
  auditMode?: InputMaybe<AuditTaskModeEnum>;
  autoProcessToken?: InputMaybe<Scalars['String']['input']>;
  autoStateChanges?: InputMaybe<Array<AutoStateChangeInput>>;
  cancelLabel?: InputMaybe<Array<ProcessOptionalStringInput>>;
  candidateDisplayId?: InputMaybe<Scalars['String']['input']>;
  candidateDisplayName?: InputMaybe<Scalars['String']['input']>;
  candidateDocuments?: InputMaybe<Array<CandidateDocTypeAttachmentInput>>;
  candidateId?: InputMaybe<Scalars['ID']['input']>;
  candidateOrganizationId?: InputMaybe<Scalars['ID']['input']>;
  candidateRequiredDocumentSetIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  checkTaskActivationPeriodically?: InputMaybe<Scalars['Boolean']['input']>;
  confirmationMode?: InputMaybe<Scalars['Boolean']['input']>;
  createDownloadableArchiveWithLink?: InputMaybe<Scalars['Boolean']['input']>;
  delayStartByDays?: InputMaybe<Scalars['Int']['input']>;
  deliveryFormat?: InputMaybe<DocumentDeliveryFormat>;
  displayAttachmentError?: InputMaybe<Scalars['Boolean']['input']>;
  displayAttachmentInfo?: InputMaybe<Scalars['Boolean']['input']>;
  displayDescription?: InputMaybe<Array<ProcessOptionalStringInput>>;
  displayDescriptionQD?: InputMaybe<Array<ProcessOptionalQuillDeltaInput>>;
  displayName?: InputMaybe<Array<ProcessOptionalStringInput>>;
  doNotAutoFinishTaskInCaseAllRequirementsFullfilled?: InputMaybe<Scalars['Boolean']['input']>;
  doNotCreateOnSync?: InputMaybe<Scalars['Boolean']['input']>;
  documentCheckType?: InputMaybe<DocumentDeliveryFormat>;
  documentTypesToUpload?: InputMaybe<Array<ProcessDocumentRequirementTypeInput>>;
  downloadableAttachmentsLink?: InputMaybe<Scalars['String']['input']>;
  downloadableFiles?: InputMaybe<Array<DownloadableFilestInput>>;
  fieldsToExcludeFromSync?: InputMaybe<Array<Scalars['String']['input']>>;
  finishLabel?: InputMaybe<Array<ProcessOptionalStringInput>>;
  finishingMode?: InputMaybe<DocumentFinishingMode>;
  formToken?: InputMaybe<Scalars['String']['input']>;
  hideGenericTaskComment?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  includeDocumentsMarkedAsNotRelevantInRequiredCount?: InputMaybe<Scalars['Boolean']['input']>;
  initialModel?: InputMaybe<Scalars['JSONObject']['input']>;
  instanceState?: InputMaybe<InstanceStateEnum>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  isTaskConfigured?: InputMaybe<Scalars['Boolean']['input']>;
  lastInstanceStateChangedAt?: InputMaybe<Scalars['DateTime']['input']>;
  lastUpdateManualStarted?: InputMaybe<Scalars['Boolean']['input']>;
  lockDocumentsAfterReviewForIds?: InputMaybe<Array<Scalars['String']['input']>>;
  manuallyCreated?: InputMaybe<Scalars['Boolean']['input']>;
  maxFileSize?: InputMaybe<Scalars['Float']['input']>;
  maxFilesRequired?: InputMaybe<Scalars['Float']['input']>;
  maxUploadedDocumentTypesRequired?: InputMaybe<Scalars['Float']['input']>;
  minFilesRequired?: InputMaybe<Scalars['Float']['input']>;
  minUploadedDocumentTypesRequired?: InputMaybe<Scalars['Float']['input']>;
  modelId?: InputMaybe<Scalars['ID']['input']>;
  numberOfAllowedAttachments?: InputMaybe<Scalars['Float']['input']>;
  numberOfReviewers?: InputMaybe<Scalars['Float']['input']>;
  organizationDocuments?: InputMaybe<Array<DocTypeAttachmentInput>>;
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  ownerRoles?: InputMaybe<Array<Scalars['String']['input']>>;
  parentId?: InputMaybe<Scalars['ID']['input']>;
  preConfiguredAllowedDocumentFormats?: InputMaybe<Array<Scalars['String']['input']>>;
  preConfiguredDocumentFormatOverwrite?: InputMaybe<Array<Scalars['String']['input']>>;
  preConfiguredDocumentPurpose?: InputMaybe<DocumentPurpose>;
  preConfiguredDocumentSetName?: InputMaybe<Scalars['String']['input']>;
  preConfiguredFilterForCandidate?: InputMaybe<Scalars['Boolean']['input']>;
  predecessorIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  prefillTaskId?: InputMaybe<Scalars['ID']['input']>;
  previousTaskId?: InputMaybe<Scalars['ID']['input']>;
  processAuditGroupName?: InputMaybe<Scalars['String']['input']>;
  processDeadlineInDays?: InputMaybe<Scalars['Float']['input']>;
  processDocumentsToUpload?: InputMaybe<Array<ProcessDocumentToUploadTypeInput>>;
  processInstanceState?: InputMaybe<ProcessInstanceStateEnum>;
  properties?: InputMaybe<Array<CandidateProfilePropertyInput>>;
  reactivateTasksOnCancel?: InputMaybe<Array<Scalars['ID']['input']>>;
  reactivateTasksOnFinish?: InputMaybe<Array<Scalars['ID']['input']>>;
  reactivatedByTask?: InputMaybe<Scalars['ID']['input']>;
  reactivatedCancelLabel?: InputMaybe<Array<ProcessOptionalStringInput>>;
  reactivatedFinishLabel?: InputMaybe<Array<ProcessOptionalStringInput>>;
  reactivatedRequiresCancelReason?: InputMaybe<Scalars['Boolean']['input']>;
  readonly?: InputMaybe<Scalars['Boolean']['input']>;
  recipientRoles?: InputMaybe<Array<Scalars['String']['input']>>;
  recruitingConceptTaskId?: InputMaybe<Scalars['String']['input']>;
  referenceTaskUploadIds?: InputMaybe<Array<Scalars['String']['input']>>;
  reminderDate?: InputMaybe<Scalars['DateTime']['input']>;
  reminderSettings?: InputMaybe<TaskReminderSettingsInput>;
  replyType?: InputMaybe<ReplyTypeEnum>;
  requestedInformation?: InputMaybe<Array<EnterMissingInformationRequiredInformationInput>>;
  requiredInformation?: InputMaybe<Array<EnterMissingInformation>>;
  requiresCancelReason?: InputMaybe<Scalars['Boolean']['input']>;
  responsibleRoles?: InputMaybe<Array<Scalars['String']['input']>>;
  rootId?: InputMaybe<Scalars['ID']['input']>;
  ruleExecutionError?: InputMaybe<RuleExecutionErrorInput>;
  rules?: InputMaybe<Array<ProcessTaskRuleInput>>;
  selectFinishByDefault?: InputMaybe<Scalars['Boolean']['input']>;
  setupRoles?: InputMaybe<Array<Scalars['String']['input']>>;
  skipOnAlreadyEntered?: InputMaybe<Scalars['Boolean']['input']>;
  targetObject?: InputMaybe<ProcessTargetEnum>;
  targetRoles?: InputMaybe<Array<Scalars['String']['input']>>;
  taskEmails?: InputMaybe<Array<EmailTemplateDescriptionInput>>;
  templateId?: InputMaybe<Scalars['ID']['input']>;
  templateLanguage?: InputMaybe<Scalars['String']['input']>;
  tokens?: InputMaybe<Array<Scalars['String']['input']>>;
  typeName: Scalars['String']['input'];
  usePreConfiguredDocumentTypes?: InputMaybe<Scalars['Boolean']['input']>;
  visibleForRoles?: InputMaybe<Array<Scalars['String']['input']>>;
};

export class ProcessTaskUpload implements ProcessTask {
  __typename?: 'ProcessTaskUpload';
  _etag?: Maybe<Scalars['String']['output']>;
  activationCondition?: Maybe<Scalars['String']['output']>;
  atLeastOnePredecessorIds?: Maybe<Array<Scalars['ID']['output']>>;
  attachDownloadableTaskFilesToEmails?: Maybe<Scalars['Boolean']['output']>;
  attachmentError?: Maybe<Scalars['String']['output']>;
  attachmentForms?: Maybe<Array<Scalars['String']['output']>>;
  attachmentTaskIds?: Maybe<Array<Scalars['ID']['output']>>;
  attachmentTemplates?: Maybe<Array<Scalars['String']['output']>>;
  attachmentTemplatesFileType?: Maybe<Array<AttachmentTemplateFileTypeMapping>>;
  attachments?: Maybe<Array<FileAttachment>>;
  attachmentsAllowedOnTopLevel?: Maybe<Scalars['Boolean']['output']>;
  attachmentsDisplayText?: Maybe<Array<ProcessOptionalString>>;
  attachmentsRequired?: Maybe<Scalars['Boolean']['output']>;
  autoStateChanges?: Maybe<Array<AutoStateChange>>;
  cancelLabel?: Maybe<Array<ProcessOptionalString>>;
  candidateDisplayId?: Maybe<Scalars['String']['output']>;
  candidateDisplayName?: Maybe<Scalars['String']['output']>;
  candidateDocuments?: Maybe<Array<CandidateDocTypeAttachment>>;
  candidateId?: Maybe<Scalars['ID']['output']>;
  candidateOrganizationId?: Maybe<Scalars['ID']['output']>;
  candidateRequiredDocumentSetIds?: Maybe<Array<Scalars['ID']['output']>>;
  checkTaskActivationPeriodically?: Maybe<Scalars['Boolean']['output']>;
  createDownloadableArchiveWithLink?: Maybe<Scalars['Boolean']['output']>;
  delayStartByDays?: Maybe<Scalars['Int']['output']>;
  deliveryFormat?: Maybe<DocumentDeliveryFormat>;
  displayAttachmentError?: Maybe<Scalars['Boolean']['output']>;
  displayAttachmentInfo?: Maybe<Scalars['Boolean']['output']>;
  displayDescription?: Maybe<Array<ProcessOptionalString>>;
  displayDescriptionQD?: Maybe<Array<ProcessOptionalQuillDelta>>;
  displayName?: Maybe<Array<ProcessOptionalString>>;
  doNotAutoFinishTaskInCaseAllRequirementsFullfilled?: Maybe<Scalars['Boolean']['output']>;
  doNotCreateOnSync?: Maybe<Scalars['Boolean']['output']>;
  documentTypesToUpload?: Maybe<Array<ProcessDocumentRequirementType>>;
  downloadableAttachmentsLink?: Maybe<Scalars['String']['output']>;
  downloadableFiles?: Maybe<Array<DownloadableFile>>;
  fieldsToExcludeFromSync?: Maybe<Array<Scalars['String']['output']>>;
  finishLabel?: Maybe<Array<ProcessOptionalString>>;
  finishingMode?: Maybe<DocumentFinishingMode>;
  hideGenericTaskComment?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  includeDocumentsMarkedAsNotRelevantInRequiredCount?: Maybe<Scalars['Boolean']['output']>;
  instanceState: InstanceStateEnum;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  isTaskConfigured: Scalars['Boolean']['output'];
  lastInstanceStateChangedAt?: Maybe<Scalars['DateTime']['output']>;
  lastUpdateManualStarted?: Maybe<Scalars['Boolean']['output']>;
  localizedCancelLabel?: Maybe<Scalars['String']['output']>;
  localizedDisplayDescription?: Maybe<Scalars['String']['output']>;
  localizedDisplayName?: Maybe<Scalars['String']['output']>;
  localizedFinishLabel?: Maybe<Scalars['String']['output']>;
  localizedReactivatedCancelLabel?: Maybe<Scalars['String']['output']>;
  localizedReactivatedFinishLabel?: Maybe<Scalars['String']['output']>;
  localizedRuleExecutionError?: Maybe<Scalars['String']['output']>;
  manuallyCreated?: Maybe<Scalars['Boolean']['output']>;
  maxFilesRequired?: Maybe<Scalars['Float']['output']>;
  maxUploadedDocumentTypesRequired?: Maybe<Scalars['Float']['output']>;
  minFilesRequired?: Maybe<Scalars['Float']['output']>;
  minUploadedDocumentTypesRequired?: Maybe<Scalars['Float']['output']>;
  modelId: Scalars['ID']['output'];
  numberOfAllowedAttachments?: Maybe<Scalars['Float']['output']>;
  organizationDocuments?: Maybe<Array<DocTypeAttachment>>;
  organizationId: Scalars['ID']['output'];
  ownerRoles: Array<Scalars['String']['output']>;
  parentId: Scalars['ID']['output'];
  preConfiguredAllowedDocumentFormats?: Maybe<Array<Scalars['String']['output']>>;
  preConfiguredDocumentFormatOverwrite?: Maybe<Array<Scalars['String']['output']>>;
  preConfiguredDocumentPurpose?: Maybe<DocumentPurpose>;
  preConfiguredDocumentSetName?: Maybe<Scalars['String']['output']>;
  preConfiguredFilterForCandidate?: Maybe<Scalars['Boolean']['output']>;
  predecessorIds?: Maybe<Array<Scalars['ID']['output']>>;
  previousTaskId?: Maybe<Scalars['ID']['output']>;
  processDocumentsToUpload?: Maybe<Array<ProcessDocumentToUploadType>>;
  processInstanceState: ProcessInstanceStateEnum;
  reactivateTasksOnCancel?: Maybe<Array<Scalars['ID']['output']>>;
  reactivateTasksOnFinish?: Maybe<Array<Scalars['ID']['output']>>;
  reactivatedByTask?: Maybe<Scalars['ID']['output']>;
  reactivatedCancelLabel?: Maybe<Array<ProcessOptionalString>>;
  reactivatedFinishLabel?: Maybe<Array<ProcessOptionalString>>;
  reactivatedRequiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  readonly?: Maybe<Scalars['Boolean']['output']>;
  reminderSettings?: Maybe<TaskReminderSettings>;
  requiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  responsibleRoles: Array<Scalars['String']['output']>;
  rootId: Scalars['ID']['output'];
  ruleExecutionError?: Maybe<RuleExecutionError>;
  rules?: Maybe<Array<ProcessTaskRule>>;
  selectFinishByDefault?: Maybe<Scalars['Boolean']['output']>;
  sortOrder: Scalars['Float']['output'];
  targetObject: ProcessTargetEnum;
  targetRoles: Array<Scalars['String']['output']>;
  taskEmails?: Maybe<Array<EmailTemplateDescription>>;
  usePreConfiguredDocumentTypes?: Maybe<Scalars['Boolean']['output']>;
  visibleForRoles: Array<Scalars['String']['output']>;
};

export class ProcessTaskUploadModel {
  __typename?: 'ProcessTaskUploadModel';
  _className: Scalars['String']['output'];
  _typeName: Scalars['String']['output'];
  activationCondition?: Maybe<Scalars['String']['output']>;
  activationConditionBlocklyWorkspace?: Maybe<Scalars['String']['output']>;
  atLeastOnePredecessorIds?: Maybe<Array<Scalars['String']['output']>>;
  attachDownloadableTaskFilesToEmails?: Maybe<Scalars['Boolean']['output']>;
  attachmentForms?: Maybe<Array<Scalars['String']['output']>>;
  attachmentTasks?: Maybe<Array<Scalars['String']['output']>>;
  attachmentTemplates?: Maybe<Array<Scalars['String']['output']>>;
  attachmentTemplatesFileType?: Maybe<Array<AttachmentTemplateFileTypeMapping>>;
  attachmentsAllowedOnTopLevel?: Maybe<Scalars['Boolean']['output']>;
  attachmentsDisplayText?: Maybe<Array<ProcessModelString>>;
  attachmentsRequired: Scalars['Boolean']['output'];
  autoStateChanges?: Maybe<Array<AutoStateChangeModel>>;
  cancelLabel?: Maybe<Array<ProcessModelString>>;
  candidateDocuments?: Maybe<Array<CandidateDocumentModel>>;
  candidateRequiredDocumentSetIds?: Maybe<Array<Scalars['ID']['output']>>;
  checkTaskActivationPeriodically?: Maybe<Scalars['Boolean']['output']>;
  createDownloadableArchiveWithLink?: Maybe<Scalars['Boolean']['output']>;
  delayStartByDays?: Maybe<Scalars['Int']['output']>;
  deliveryFormat?: Maybe<DocumentDeliveryFormat>;
  displayDescription?: Maybe<Array<ProcessModelString>>;
  displayName: Array<ProcessModelString>;
  doNotAutoFinishTaskInCaseAllRequirementsFullfilled?: Maybe<Scalars['Boolean']['output']>;
  doNotCreateOnSync?: Maybe<Scalars['Boolean']['output']>;
  documentTypesToUpload?: Maybe<Array<DocumentRequirementType>>;
  dontSendEmails?: Maybe<Scalars['Boolean']['output']>;
  finishLabel?: Maybe<Array<ProcessModelString>>;
  finishingMode?: Maybe<DocumentFinishingMode>;
  hideGenericTaskComment?: Maybe<Scalars['Boolean']['output']>;
  includeDocumentsMarkedAsNotRelevantInRequiredCount?: Maybe<Scalars['Boolean']['output']>;
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  maxDocumentsRequired?: Maybe<Scalars['Int']['output']>;
  maxUploadedDocumentsRequired?: Maybe<Scalars['Int']['output']>;
  minDocumentsRequired?: Maybe<Scalars['Int']['output']>;
  minUploadedDocumentsRequired?: Maybe<Scalars['Int']['output']>;
  modelId: Scalars['ID']['output'];
  numberOfAllowedAttachments?: Maybe<Scalars['Int']['output']>;
  organizationDocuments?: Maybe<Array<OrganizationDocumentModel>>;
  preConfiguredAllowedDocumentFormats?: Maybe<Array<Scalars['String']['output']>>;
  preConfiguredDocumentFormatOverwrite?: Maybe<Array<Scalars['String']['output']>>;
  preConfiguredDocumentPurpose?: Maybe<DocumentPurpose>;
  preConfiguredDocumentSetName?: Maybe<Scalars['String']['output']>;
  preConfiguredFilterForCandidate?: Maybe<Scalars['Boolean']['output']>;
  predecessorsIds?: Maybe<Array<Scalars['String']['output']>>;
  processDocumentsToUpload?: Maybe<Array<DocumentProcessUploadType>>;
  reactivateTasksOnCancel?: Maybe<Array<Scalars['String']['output']>>;
  reactivateTasksOnFinish?: Maybe<Array<Scalars['String']['output']>>;
  reactivatedCancelLabel?: Maybe<Array<ProcessModelString>>;
  reactivatedFinishLabel?: Maybe<Array<ProcessModelString>>;
  reactivatedRequiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  reminderSettings?: Maybe<TaskReminderSettings>;
  requiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  responsibleRoles: Array<Scalars['String']['output']>;
  rules?: Maybe<Array<ProcessTaskRule>>;
  selectFinishByDefault?: Maybe<Scalars['Boolean']['output']>;
  targetObject: ProcessTargetEnum;
  targetRoles: Array<Scalars['String']['output']>;
  taskEmails?: Maybe<Array<EmailTemplateDescription>>;
  usePreConfiguredDocumentTypes?: Maybe<Scalars['Boolean']['output']>;
  visibleForRoles: Array<Scalars['String']['output']>;
};

export class ProcessTaskUploadModelEntity implements ProcessTaskModelEntity {
  __typename?: 'ProcessTaskUploadModelEntity';
  _className: Scalars['String']['output'];
  _etag?: Maybe<Scalars['String']['output']>;
  _typeName: Scalars['String']['output'];
  activationCondition?: Maybe<Scalars['String']['output']>;
  activationConditionBlocklyWorkspace?: Maybe<Scalars['String']['output']>;
  atLeastOnePredecessorIds?: Maybe<Array<Scalars['String']['output']>>;
  attachDownloadableTaskFilesToEmails?: Maybe<Scalars['Boolean']['output']>;
  attachmentForms?: Maybe<Array<Scalars['String']['output']>>;
  attachmentTasks?: Maybe<Array<Scalars['String']['output']>>;
  attachmentTemplates?: Maybe<Array<Scalars['String']['output']>>;
  attachmentTemplatesFileType?: Maybe<Array<AttachmentTemplateFileTypeMapping>>;
  attachmentsAllowedOnTopLevel?: Maybe<Scalars['Boolean']['output']>;
  attachmentsDisplayText?: Maybe<Array<ProcessModelString>>;
  attachmentsRequired: Scalars['Boolean']['output'];
  autoStateChanges?: Maybe<Array<AutoStateChangeModel>>;
  cancelLabel?: Maybe<Array<ProcessModelString>>;
  candidateDocuments?: Maybe<Array<CandidateDocumentModel>>;
  candidateRequiredDocumentSetIds?: Maybe<Array<Scalars['ID']['output']>>;
  checkTaskActivationPeriodically?: Maybe<Scalars['Boolean']['output']>;
  createDownloadableArchiveWithLink?: Maybe<Scalars['Boolean']['output']>;
  delayStartByDays?: Maybe<Scalars['Int']['output']>;
  deliveryFormat?: Maybe<DocumentDeliveryFormat>;
  displayDescription?: Maybe<Array<ProcessModelString>>;
  displayName: Array<ProcessModelString>;
  doNotAutoFinishTaskInCaseAllRequirementsFullfilled?: Maybe<Scalars['Boolean']['output']>;
  doNotCreateOnSync?: Maybe<Scalars['Boolean']['output']>;
  documentTypesToUpload?: Maybe<Array<DocumentRequirementType>>;
  dontSendEmails?: Maybe<Scalars['Boolean']['output']>;
  finishLabel?: Maybe<Array<ProcessModelString>>;
  finishingMode?: Maybe<DocumentFinishingMode>;
  hideGenericTaskComment?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  includeDocumentsMarkedAsNotRelevantInRequiredCount?: Maybe<Scalars['Boolean']['output']>;
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  maxDocumentsRequired?: Maybe<Scalars['Int']['output']>;
  maxUploadedDocumentsRequired?: Maybe<Scalars['Int']['output']>;
  minDocumentsRequired?: Maybe<Scalars['Int']['output']>;
  minUploadedDocumentsRequired?: Maybe<Scalars['Int']['output']>;
  modelId: Scalars['ID']['output'];
  numberOfAllowedAttachments?: Maybe<Scalars['Int']['output']>;
  organizationDocuments?: Maybe<Array<OrganizationDocumentModel>>;
  organizationId: Scalars['ID']['output'];
  parentId: Scalars['ID']['output'];
  preConfiguredAllowedDocumentFormats?: Maybe<Array<Scalars['String']['output']>>;
  preConfiguredDocumentFormatOverwrite?: Maybe<Array<Scalars['String']['output']>>;
  preConfiguredDocumentPurpose?: Maybe<DocumentPurpose>;
  preConfiguredDocumentSetName?: Maybe<Scalars['String']['output']>;
  preConfiguredFilterForCandidate?: Maybe<Scalars['Boolean']['output']>;
  predecessorsIds?: Maybe<Array<Scalars['String']['output']>>;
  processDocumentsToUpload?: Maybe<Array<DocumentProcessUploadType>>;
  reactivateTasksOnCancel?: Maybe<Array<Scalars['String']['output']>>;
  reactivateTasksOnFinish?: Maybe<Array<Scalars['String']['output']>>;
  reactivatedCancelLabel?: Maybe<Array<ProcessModelString>>;
  reactivatedFinishLabel?: Maybe<Array<ProcessModelString>>;
  reactivatedRequiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  reminderSettings?: Maybe<TaskReminderSettings>;
  requiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  responsibleRoles: Array<Scalars['String']['output']>;
  rootId: Scalars['ID']['output'];
  rules?: Maybe<Array<ProcessTaskRule>>;
  selectFinishByDefault?: Maybe<Scalars['Boolean']['output']>;
  sortOrder: Scalars['Float']['output'];
  targetObject: ProcessTargetEnum;
  targetRoles: Array<Scalars['String']['output']>;
  taskEmails?: Maybe<Array<EmailTemplateDescription>>;
  usePreConfiguredDocumentTypes?: Maybe<Scalars['Boolean']['output']>;
  visibleForRoles: Array<Scalars['String']['output']>;
};

export class ProcessTaskWork implements ProcessTask {
  __typename?: 'ProcessTaskWork';
  _etag?: Maybe<Scalars['String']['output']>;
  activationCondition?: Maybe<Scalars['String']['output']>;
  atLeastOnePredecessorIds?: Maybe<Array<Scalars['ID']['output']>>;
  autoStateChanges?: Maybe<Array<AutoStateChange>>;
  cancelLabel?: Maybe<Array<ProcessOptionalString>>;
  candidateDisplayId?: Maybe<Scalars['String']['output']>;
  candidateDisplayName?: Maybe<Scalars['String']['output']>;
  candidateId?: Maybe<Scalars['ID']['output']>;
  candidateOrganizationId?: Maybe<Scalars['ID']['output']>;
  checkTaskActivationPeriodically?: Maybe<Scalars['Boolean']['output']>;
  delayStartByDays?: Maybe<Scalars['Int']['output']>;
  displayDescription?: Maybe<Array<ProcessOptionalString>>;
  displayDescriptionQD?: Maybe<Array<ProcessOptionalQuillDelta>>;
  displayName?: Maybe<Array<ProcessOptionalString>>;
  doNotCreateOnSync?: Maybe<Scalars['Boolean']['output']>;
  fieldsToExcludeFromSync?: Maybe<Array<Scalars['String']['output']>>;
  finishLabel?: Maybe<Array<ProcessOptionalString>>;
  hideGenericTaskComment?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  instanceState: InstanceStateEnum;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  lastInstanceStateChangedAt?: Maybe<Scalars['DateTime']['output']>;
  lastUpdateManualStarted?: Maybe<Scalars['Boolean']['output']>;
  localizedCancelLabel?: Maybe<Scalars['String']['output']>;
  localizedDisplayDescription?: Maybe<Scalars['String']['output']>;
  localizedDisplayName?: Maybe<Scalars['String']['output']>;
  localizedFinishLabel?: Maybe<Scalars['String']['output']>;
  localizedReactivatedCancelLabel?: Maybe<Scalars['String']['output']>;
  localizedReactivatedFinishLabel?: Maybe<Scalars['String']['output']>;
  localizedRuleExecutionError?: Maybe<Scalars['String']['output']>;
  manuallyCreated?: Maybe<Scalars['Boolean']['output']>;
  modelId: Scalars['ID']['output'];
  organizationId: Scalars['ID']['output'];
  ownerRoles: Array<Scalars['String']['output']>;
  parentId: Scalars['ID']['output'];
  predecessorIds?: Maybe<Array<Scalars['ID']['output']>>;
  previousTaskId?: Maybe<Scalars['ID']['output']>;
  processInstanceState: ProcessInstanceStateEnum;
  reactivateTasksOnCancel?: Maybe<Array<Scalars['ID']['output']>>;
  reactivateTasksOnFinish?: Maybe<Array<Scalars['ID']['output']>>;
  reactivatedByTask?: Maybe<Scalars['ID']['output']>;
  reactivatedCancelLabel?: Maybe<Array<ProcessOptionalString>>;
  reactivatedFinishLabel?: Maybe<Array<ProcessOptionalString>>;
  reactivatedRequiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  readonly?: Maybe<Scalars['Boolean']['output']>;
  reminderSettings?: Maybe<TaskReminderSettings>;
  requiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  responsibleRoles: Array<Scalars['String']['output']>;
  rootId: Scalars['ID']['output'];
  ruleExecutionError?: Maybe<RuleExecutionError>;
  rules?: Maybe<Array<ProcessTaskRule>>;
  selectFinishByDefault?: Maybe<Scalars['Boolean']['output']>;
  sortOrder: Scalars['Float']['output'];
  targetObject: ProcessTargetEnum;
  targetRoles: Array<Scalars['String']['output']>;
  taskEmails?: Maybe<Array<EmailTemplateDescription>>;
  visibleForRoles: Array<Scalars['String']['output']>;
};

export class ProcessTaskWorkModel {
  __typename?: 'ProcessTaskWorkModel';
  _className: Scalars['String']['output'];
  _typeName: Scalars['String']['output'];
  activationCondition?: Maybe<Scalars['String']['output']>;
  activationConditionBlocklyWorkspace?: Maybe<Scalars['String']['output']>;
  atLeastOnePredecessorIds?: Maybe<Array<Scalars['String']['output']>>;
  autoStateChanges?: Maybe<Array<AutoStateChangeModel>>;
  cancelLabel?: Maybe<Array<ProcessModelString>>;
  checkTaskActivationPeriodically?: Maybe<Scalars['Boolean']['output']>;
  delayStartByDays?: Maybe<Scalars['Int']['output']>;
  displayDescription?: Maybe<Array<ProcessModelString>>;
  displayName: Array<ProcessModelString>;
  doNotCreateOnSync?: Maybe<Scalars['Boolean']['output']>;
  dontSendEmails?: Maybe<Scalars['Boolean']['output']>;
  finishLabel?: Maybe<Array<ProcessModelString>>;
  hideGenericTaskComment?: Maybe<Scalars['Boolean']['output']>;
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  modelId: Scalars['ID']['output'];
  predecessorsIds?: Maybe<Array<Scalars['String']['output']>>;
  reactivateTasksOnCancel?: Maybe<Array<Scalars['String']['output']>>;
  reactivateTasksOnFinish?: Maybe<Array<Scalars['String']['output']>>;
  reactivatedCancelLabel?: Maybe<Array<ProcessModelString>>;
  reactivatedFinishLabel?: Maybe<Array<ProcessModelString>>;
  reactivatedRequiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  reminderSettings?: Maybe<TaskReminderSettings>;
  requiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  responsibleRoles: Array<Scalars['String']['output']>;
  rules?: Maybe<Array<ProcessTaskRule>>;
  selectFinishByDefault?: Maybe<Scalars['Boolean']['output']>;
  targetObject: ProcessTargetEnum;
  targetRoles: Array<Scalars['String']['output']>;
  taskEmails?: Maybe<Array<EmailTemplateDescription>>;
  visibleForRoles: Array<Scalars['String']['output']>;
};

export class ProcessTaskWorkModelEntity implements ProcessTaskModelEntity {
  __typename?: 'ProcessTaskWorkModelEntity';
  _className: Scalars['String']['output'];
  _etag?: Maybe<Scalars['String']['output']>;
  _typeName: Scalars['String']['output'];
  activationCondition?: Maybe<Scalars['String']['output']>;
  activationConditionBlocklyWorkspace?: Maybe<Scalars['String']['output']>;
  atLeastOnePredecessorIds?: Maybe<Array<Scalars['String']['output']>>;
  autoStateChanges?: Maybe<Array<AutoStateChangeModel>>;
  cancelLabel?: Maybe<Array<ProcessModelString>>;
  checkTaskActivationPeriodically?: Maybe<Scalars['Boolean']['output']>;
  delayStartByDays?: Maybe<Scalars['Int']['output']>;
  displayDescription?: Maybe<Array<ProcessModelString>>;
  displayName: Array<ProcessModelString>;
  doNotCreateOnSync?: Maybe<Scalars['Boolean']['output']>;
  dontSendEmails?: Maybe<Scalars['Boolean']['output']>;
  finishLabel?: Maybe<Array<ProcessModelString>>;
  hideGenericTaskComment?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  modelId: Scalars['ID']['output'];
  organizationId: Scalars['ID']['output'];
  parentId: Scalars['ID']['output'];
  predecessorsIds?: Maybe<Array<Scalars['String']['output']>>;
  reactivateTasksOnCancel?: Maybe<Array<Scalars['String']['output']>>;
  reactivateTasksOnFinish?: Maybe<Array<Scalars['String']['output']>>;
  reactivatedCancelLabel?: Maybe<Array<ProcessModelString>>;
  reactivatedFinishLabel?: Maybe<Array<ProcessModelString>>;
  reactivatedRequiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  reminderSettings?: Maybe<TaskReminderSettings>;
  requiresCancelReason?: Maybe<Scalars['Boolean']['output']>;
  responsibleRoles: Array<Scalars['String']['output']>;
  rootId: Scalars['ID']['output'];
  rules?: Maybe<Array<ProcessTaskRule>>;
  selectFinishByDefault?: Maybe<Scalars['Boolean']['output']>;
  sortOrder: Scalars['Float']['output'];
  targetObject: ProcessTargetEnum;
  targetRoles: Array<Scalars['String']['output']>;
  taskEmails?: Maybe<Array<EmailTemplateDescription>>;
  visibleForRoles: Array<Scalars['String']['output']>;
};

export class ProcessUpdateInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  collectionId?: InputMaybe<Scalars['ID']['input']>;
  configuration?: InputMaybe<Scalars['JSONObject']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  delegatedProcessId?: InputMaybe<Scalars['ID']['input']>;
  delegatedProcessOrganizationId?: InputMaybe<Scalars['ID']['input']>;
  displayDescription?: InputMaybe<Scalars['String']['input']>;
  displayName: Scalars['String']['input'];
  documentLanguages?: InputMaybe<Array<Scalars['String']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isDelegation?: InputMaybe<Scalars['Boolean']['input']>;
  languages: Array<Scalars['String']['input']>;
  operationMapping?: InputMaybe<ProcessOperationMappingInput>;
  organizationId: Scalars['ID']['input'];
  processModelId: Scalars['ID']['input'];
  processModelName: Array<ProcessRequiredStringInput>;
  readonly?: InputMaybe<Scalars['Boolean']['input']>;
  releaseId?: InputMaybe<Scalars['ID']['input']>;
  roleMappingBeforeDelegation?: InputMaybe<Array<RoleOrganizationUserGroupMappingInput>>;
  roleOrganisationUsergroupMapping: Array<RoleOrganizationUserGroupMappingInput>;
  roles: Array<ProcessRoleInput>;
  rules?: InputMaybe<Array<ProcessRuleInput>>;
  state: ProcessInstanceStateEnum;
  taskVisibility?: InputMaybe<TaskVisibilityEnum>;
};

export class ProcessVisibleTaskListInput {
  candidateId?: InputMaybe<Scalars['ID']['input']>;
  instanceStates?: InputMaybe<Array<InstanceStateEnum>>;
  language?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['ID']['input'];
  processId: Scalars['ID']['input'];
};

export class ProcessesForOrganizationInput {
  destinationOrganizationId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};

export enum ProfessionCategory {
  HealthCare = 'HealthCare',
  Other = 'Other'
}

export class ProfessionConfiguration {
  __typename?: 'ProfessionConfiguration';
  _etag?: Maybe<Scalars['String']['output']>;
  defaultFunction?: Maybe<Scalars['String']['output']>;
  defaultProfession?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  organizationId: Scalars['ID']['output'];
  professions?: Maybe<Array<Scalars['String']['output']>>;
};

export class ProfessionConfigurationGetInput {
  organizationId: Scalars['ID']['input'];
};

export class ProfessionConfigurationSetInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  defaultFunction?: InputMaybe<Scalars['String']['input']>;
  defaultProfession?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
  professions?: InputMaybe<Array<Scalars['String']['input']>>;
};

export class ProfessionDefaults {
  __typename?: 'ProfessionDefaults';
  defaultFunction?: Maybe<Scalars['String']['output']>;
  defaultProfession?: Maybe<Scalars['String']['output']>;
};

export class ProfessionInput {
  all?: InputMaybe<Scalars['Boolean']['input']>;
  area?: InputMaybe<Scalars['String']['input']>;
  language: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  subArea?: InputMaybe<Scalars['String']['input']>;
};

export class ProfessionalExperience {
  __typename?: 'ProfessionalExperience';
  _etag?: Maybe<Scalars['String']['output']>;
  changedAt: Scalars['DateTime']['output'];
  changedBy: Scalars['String']['output'];
  description?: Maybe<Array<LocalizedString>>;
  id?: Maybe<Scalars['ID']['output']>;
  localizedDescription?: Maybe<Scalars['String']['output']>;
  localizedName?: Maybe<Scalars['String']['output']>;
  name: Array<LocalizedString>;
  organizationId: Scalars['ID']['output'];
  professionalFieldId: Scalars['ID']['output'];
};

export class ProfessionalExperienceCreateInput {
  description?: InputMaybe<Array<LocalizedStringInput>>;
  name: Array<LocalizedStringInput>;
  organizationId: Scalars['ID']['input'];
  professionalFieldId: Scalars['ID']['input'];
};

export class ProfessionalExperienceDeleteInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
};

export class ProfessionalExperienceGetByTypeInput {
  organizationId: Scalars['ID']['input'];
  type: OccupationSkillType;
};

export class ProfessionalExperienceGetInput {
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
};

export class ProfessionalExperienceListInput {
  organizationId: Scalars['ID']['input'];
  professionalFieldId: Scalars['ID']['input'];
};

export class ProfessionalExperienceUpdateInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Array<LocalizedStringInput>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Array<LocalizedStringInput>;
  organizationId: Scalars['ID']['input'];
  professionalFieldId: Scalars['ID']['input'];
};

export class ProfessionalField {
  __typename?: 'ProfessionalField';
  _etag?: Maybe<Scalars['String']['output']>;
  additionalInformationLabel?: Maybe<Array<LocalizedString>>;
  additionalInformationTypes?: Maybe<Array<ProfessionalFieldAdditionalInformationType>>;
  changedAt: Scalars['DateTime']['output'];
  changedBy: Scalars['String']['output'];
  description?: Maybe<Array<LocalizedString>>;
  id?: Maybe<Scalars['ID']['output']>;
  languages: Array<Scalars['String']['output']>;
  localizedDescription?: Maybe<Scalars['String']['output']>;
  localizedName?: Maybe<Scalars['String']['output']>;
  name: Array<LocalizedString>;
  organizationId: Scalars['ID']['output'];
  professions: Array<Scalars['String']['output']>;
  requireAdditionalInformation?: Maybe<Scalars['Boolean']['output']>;
  type: OccupationSkillType;
};

export enum ProfessionalFieldAdditionalInformationType {
  WhenExperienceAvailable = 'WhenExperienceAvailable',
  WhenExperienceNotAvailable = 'WhenExperienceNotAvailable'
}

export class ProfessionalFieldCreateInput {
  additionalInformationLabel?: InputMaybe<Array<LocalizedStringInput>>;
  additionalInformationTypes?: InputMaybe<Array<ProfessionalFieldAdditionalInformationType>>;
  description?: InputMaybe<Array<LocalizedStringInput>>;
  languages: Array<Scalars['String']['input']>;
  name: Array<LocalizedStringInput>;
  organizationId: Scalars['ID']['input'];
  professions: Array<Scalars['String']['input']>;
  requireAdditionalInformation?: InputMaybe<Scalars['Boolean']['input']>;
  type: OccupationSkillType;
};

export class ProfessionalFieldDeleteInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
};

export class ProfessionalFieldGetInput {
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
};

export class ProfessionalFieldListInput {
  organizationId: Scalars['ID']['input'];
};

export class ProfessionalFieldSettings {
  __typename?: 'ProfessionalFieldSettings';
  _etag?: Maybe<Scalars['String']['output']>;
  changedAt: Scalars['DateTime']['output'];
  changedBy: Scalars['String']['output'];
  id?: Maybe<Scalars['ID']['output']>;
  organizationId: Scalars['ID']['output'];
  standardCompetencesProfessionIdBlackList?: Maybe<Array<Scalars['String']['output']>>;
  standardKnowledgeProfessionIdBlackList?: Maybe<Array<Scalars['String']['output']>>;
};

export class ProfessionalFieldSettingsGetInput {
  organizationId: Scalars['ID']['input'];
};

export class ProfessionalFieldSettingsUpsertInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
  standardCompetencesProfessionIdBlackList?: InputMaybe<Array<Scalars['String']['input']>>;
  standardKnowledgeProfessionIdBlackList?: InputMaybe<Array<Scalars['String']['input']>>;
};

export class ProfessionalFieldUpdateInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  additionalInformationLabel?: InputMaybe<Array<LocalizedStringInput>>;
  additionalInformationTypes?: InputMaybe<Array<ProfessionalFieldAdditionalInformationType>>;
  description?: InputMaybe<Array<LocalizedStringInput>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  languages: Array<Scalars['String']['input']>;
  name: Array<LocalizedStringInput>;
  organizationId: Scalars['ID']['input'];
  professions: Array<Scalars['String']['input']>;
  requireAdditionalInformation?: InputMaybe<Scalars['Boolean']['input']>;
  type: OccupationSkillType;
};

export enum ProfileDataEditStatus {
  Default = 'Default',
  Editable = 'Editable',
  ReadOnly = 'ReadOnly'
}

export class PropertyDefinition {
  __typename?: 'PropertyDefinition';
  children?: Maybe<Array<PropertyDefinition>>;
  enum?: Maybe<Scalars['String']['output']>;
  isMultiSelect: Scalars['Boolean']['output'];
  isRequired?: Maybe<Scalars['Boolean']['output']>;
  labelKey: Scalars['String']['output'];
  parentLabelKeys?: Maybe<Array<Scalars['String']['output']>>;
  propertyPath: Scalars['String']['output'];
  staticDataType?: Maybe<StaticDataType>;
  type: Scalars['String']['output'];
};

export class PropertyTranslation {
  __typename?: 'PropertyTranslation';
  _etag?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  organizationId: Scalars['ID']['output'];
  propertyPath: Array<Scalars['String']['output']>;
  sourceLanguage?: Maybe<Scalars['String']['output']>;
  sourceText?: Maybe<Scalars['String']['output']>;
  sourceTextAsDelta?: Maybe<Scalars['JSONObject']['output']>;
  targetLanguage?: Maybe<Scalars['String']['output']>;
  targetText?: Maybe<Scalars['String']['output']>;
  targetTextAsDelta?: Maybe<Scalars['JSONObject']['output']>;
  translationItemId: Scalars['String']['output'];
  translationProvider?: Maybe<TranslatorType>;
};

export class PropertyTranslationEntity {
  _etag?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
  propertyPath: Array<Scalars['String']['input']>;
  sourceLanguage?: InputMaybe<Scalars['String']['input']>;
  sourceText?: InputMaybe<Scalars['String']['input']>;
  sourceTextAsDelta?: InputMaybe<Scalars['JSONObject']['input']>;
  targetLanguage?: InputMaybe<Scalars['String']['input']>;
  targetText?: InputMaybe<Scalars['String']['input']>;
  targetTextAsDelta?: InputMaybe<Scalars['JSONObject']['input']>;
  translationItemId: Scalars['String']['input'];
  translationProvider?: InputMaybe<TranslatorType>;
};

export class PropertyTranslationInput {
  entityId: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
};

export class PropertyTranslationUpdateInput {
  translations: Array<PropertyTranslationEntity>;
};

export class PublicTalentPoolOccupationSkillByIdsInputInput {
  language: Scalars['String']['input'];
  skillIds: Array<Scalars['String']['input']>;
  skillType: OccupationSkillType;
};

export class PublicTalentPoolSearch {
  __typename?: 'PublicTalentPoolSearch';
  count: Scalars['Float']['output'];
  facets: TalentPoolFacets;
  results: Array<Talent>;
};

export class PublicTalentPoolSearchInput {
  filters: TalentPoolFilters;
  pool: PublicTalentPoolSelection;
  skip: Scalars['Float']['input'];
  top: Scalars['Float']['input'];
};

export class PublicTalentPoolSelection {
  talentPoolId: Scalars['ID']['input'];
  talentPoolOrganizationId: Scalars['ID']['input'];
};

export class PublicTalentPoolTalentRequestInput {
  captchaToken: Scalars['String']['input'];
  requesterSource: TalentRequestSource;
  talentIds: Array<Scalars['String']['input']>;
};

export class PublishedProcessModelEntity {
  __typename?: 'PublishedProcessModelEntity';
  _etag?: Maybe<Scalars['String']['output']>;
  _typeName: Scalars['String']['output'];
  id?: Maybe<Scalars['ID']['output']>;
  organizationId: Scalars['ID']['output'];
  publishedModels?: Maybe<Array<ProcessModelPublishInfo>>;
};

export class PublishedProcessModelReleaseVersion {
  __typename?: 'PublishedProcessModelReleaseVersion';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  displayName: Scalars['String']['output'];
  isShared: Scalars['Boolean']['output'];
  modelDeclaration: ProcessModelDeclaration;
  ownerOrganizationId?: Maybe<Scalars['ID']['output']>;
  ownerOrganizationName?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['String']['output']>;
};

export class QualificationEvaluationDataDe {
  __typename?: 'QualificationEvaluationDataDE';
  noQualificationEvaluation?: Maybe<Scalars['Boolean']['output']>;
  qualificationEvaluationFirstDeputyFirstName?: Maybe<Scalars['String']['output']>;
  qualificationEvaluationFirstDeputyId?: Maybe<Scalars['ID']['output']>;
  qualificationEvaluationFirstDeputyLastName?: Maybe<Scalars['String']['output']>;
  qualificationEvaluationRepresentativeFirstName?: Maybe<Scalars['String']['output']>;
  qualificationEvaluationRepresentativeId?: Maybe<Scalars['ID']['output']>;
  qualificationEvaluationRepresentativeLastName?: Maybe<Scalars['String']['output']>;
  qualificationEvaluations?: Maybe<Array<QualificationEvaluationsDe>>;
  responsibleQualificationEvaluationOrganizationId?: Maybe<Scalars['ID']['output']>;
  responsibleRoleQualificationEvaluation?: Maybe<Scalars['String']['output']>;
  responsibleRoleQualificationEvaluationPayment?: Maybe<Scalars['String']['output']>;
};

export class QualificationEvaluationDataDeInput {
  noQualificationEvaluation?: InputMaybe<Scalars['Boolean']['input']>;
  qualificationEvaluationFirstDeputyFirstName?: InputMaybe<Scalars['String']['input']>;
  qualificationEvaluationFirstDeputyId?: InputMaybe<Scalars['ID']['input']>;
  qualificationEvaluationFirstDeputyLastName?: InputMaybe<Scalars['String']['input']>;
  qualificationEvaluationRepresentativeFirstName?: InputMaybe<Scalars['String']['input']>;
  qualificationEvaluationRepresentativeId?: InputMaybe<Scalars['ID']['input']>;
  qualificationEvaluationRepresentativeLastName?: InputMaybe<Scalars['String']['input']>;
  qualificationEvaluations?: InputMaybe<Array<QualificationEvaluationsDataDeInput>>;
  responsibleQualificationEvaluationOrganizationId?: InputMaybe<Scalars['ID']['input']>;
  responsibleRoleQualificationEvaluation?: InputMaybe<Scalars['String']['input']>;
  responsibleRoleQualificationEvaluationPayment?: InputMaybe<Scalars['String']['input']>;
};

export class QualificationEvaluationsDe {
  __typename?: 'QualificationEvaluationsDE';
  evaluatedQualification?: Maybe<Scalars['String']['output']>;
  evaluationNoticeKind?: Maybe<Scalars['String']['output']>;
  federalState?: Maybe<Scalars['String']['output']>;
  fileNumber?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  responsibleAuthorityForQualification?: Maybe<Scalars['String']['output']>;
  responsibleAuthorityForQualificationInOrigin?: Maybe<Scalars['String']['output']>;
};

export class QualificationEvaluationsDataDeInput {
  evaluatedQualification?: InputMaybe<Scalars['String']['input']>;
  evaluationNoticeKind?: InputMaybe<Scalars['String']['input']>;
  federalState?: InputMaybe<Scalars['String']['input']>;
  fileNumber?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  responsibleAuthorityForQualification?: InputMaybe<Scalars['String']['input']>;
  responsibleAuthorityForQualificationInOrigin?: InputMaybe<Scalars['String']['input']>;
};

export class QualificationExam {
  __typename?: 'QualificationExam';
  examArea?: Maybe<Scalars['String']['output']>;
  examType: Scalars['String']['output'];
  firstExamDate?: Maybe<Scalars['DateTime']['output']>;
  firstExamPassed?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  moduleName?: Maybe<Scalars['String']['output']>;
  secondExamDate?: Maybe<Scalars['DateTime']['output']>;
  secondExamPassed?: Maybe<Scalars['String']['output']>;
};

export class QualificationExamInput {
  examArea?: InputMaybe<Scalars['String']['input']>;
  examType: Scalars['String']['input'];
  firstExamDate?: InputMaybe<Scalars['DateTime']['input']>;
  firstExamPassed?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  moduleName?: InputMaybe<Scalars['String']['input']>;
  secondExamDate?: InputMaybe<Scalars['DateTime']['input']>;
  secondExamPassed?: InputMaybe<Scalars['String']['input']>;
};

export class QualificationMeasureCandidateDataAt {
  __typename?: 'QualificationMeasureCandidateDataAT';
  endOfFurtherEducation?: Maybe<Scalars['DateTime']['output']>;
  locationOfComplementaryMeasure?: Maybe<Scalars['String']['output']>;
  responsibleRoleQualification?: Maybe<Scalars['String']['output']>;
  startOfFurtherEducation?: Maybe<Scalars['DateTime']['output']>;
  theoryInstitution?: Maybe<Scalars['String']['output']>;
};

export class QualificationMeasureCandidateDataAtInput {
  endOfFurtherEducation?: InputMaybe<Scalars['DateTime']['input']>;
  locationOfComplementaryMeasure?: InputMaybe<Scalars['String']['input']>;
  responsibleRoleQualification?: InputMaybe<Scalars['String']['input']>;
  startOfFurtherEducation?: InputMaybe<Scalars['DateTime']['input']>;
  theoryInstitution?: InputMaybe<Scalars['String']['input']>;
};

export class QualificationMeasureCandidateDataDe {
  __typename?: 'QualificationMeasureCandidateDataDE';
  careSituations?: Maybe<Scalars['Float']['output']>;
  courseNumber?: Maybe<Scalars['String']['output']>;
  educationVoucherFileNumber?: Maybe<Scalars['String']['output']>;
  educationVoucherFirstDeputyFirstName?: Maybe<Scalars['String']['output']>;
  educationVoucherFirstDeputyId?: Maybe<Scalars['ID']['output']>;
  educationVoucherFirstDeputyLastName?: Maybe<Scalars['String']['output']>;
  educationVoucherRepresentativeFirstName?: Maybe<Scalars['String']['output']>;
  educationVoucherRepresentativeId?: Maybe<Scalars['ID']['output']>;
  educationVoucherRepresentativeLastName?: Maybe<Scalars['String']['output']>;
  educationVoucherRequestDate?: Maybe<Scalars['DateTime']['output']>;
  educationVoucherRequested?: Maybe<Scalars['Boolean']['output']>;
  endOfFurtherEducation?: Maybe<Scalars['DateTime']['output']>;
  noDetailedEquivalence?: Maybe<Scalars['Boolean']['output']>;
  practiceExamDate?: Maybe<Scalars['DateTime']['output']>;
  practiceHours?: Maybe<Scalars['Float']['output']>;
  practiceInstitution?: Maybe<Scalars['String']['output']>;
  qualificationExams?: Maybe<Array<QualificationExam>>;
  qualificationIsSend?: Maybe<Scalars['Boolean']['output']>;
  qualificationIsSendDate?: Maybe<Scalars['DateTime']['output']>;
  qualificationMeasure?: Maybe<Scalars['String']['output']>;
  qualificationMeasureFileNumber?: Maybe<Scalars['String']['output']>;
  qualificationModules?: Maybe<Array<QualificationModule>>;
  qualificationType?: Maybe<Scalars['String']['output']>;
  responsibleEducationVoucherOrganizationId?: Maybe<Scalars['ID']['output']>;
  responsibleRoleEducationVoucher?: Maybe<Scalars['String']['output']>;
  responsibleRoleQualification?: Maybe<Scalars['String']['output']>;
  startOfFurtherEducation?: Maybe<Scalars['DateTime']['output']>;
  theoryExamDate?: Maybe<Scalars['DateTime']['output']>;
  theoryHours?: Maybe<Scalars['Float']['output']>;
  theoryInstitution?: Maybe<Scalars['String']['output']>;
  totalHours?: Maybe<Scalars['Float']['output']>;
};

export class QualificationMeasureCandidateDataDeInput {
  careSituations?: InputMaybe<Scalars['Float']['input']>;
  courseNumber?: InputMaybe<Scalars['String']['input']>;
  educationVoucherFileNumber?: InputMaybe<Scalars['String']['input']>;
  educationVoucherFirstDeputyFirstName?: InputMaybe<Scalars['String']['input']>;
  educationVoucherFirstDeputyId?: InputMaybe<Scalars['ID']['input']>;
  educationVoucherFirstDeputyLastName?: InputMaybe<Scalars['String']['input']>;
  educationVoucherRepresentativeFirstName?: InputMaybe<Scalars['String']['input']>;
  educationVoucherRepresentativeId?: InputMaybe<Scalars['ID']['input']>;
  educationVoucherRepresentativeLastName?: InputMaybe<Scalars['String']['input']>;
  educationVoucherRequestDate?: InputMaybe<Scalars['DateTime']['input']>;
  educationVoucherRequested?: InputMaybe<Scalars['Boolean']['input']>;
  endOfFurtherEducation?: InputMaybe<Scalars['DateTime']['input']>;
  noDetailedEquivalence?: InputMaybe<Scalars['Boolean']['input']>;
  practiceExamDate?: InputMaybe<Scalars['DateTime']['input']>;
  practiceHours?: InputMaybe<Scalars['Float']['input']>;
  practiceInstitution?: InputMaybe<Scalars['String']['input']>;
  qualificationExams?: InputMaybe<Array<QualificationExamInput>>;
  qualificationIsSend?: InputMaybe<Scalars['Boolean']['input']>;
  qualificationIsSendDate?: InputMaybe<Scalars['DateTime']['input']>;
  qualificationMeasure?: InputMaybe<Scalars['String']['input']>;
  qualificationMeasureFileNumber?: InputMaybe<Scalars['String']['input']>;
  qualificationModules?: InputMaybe<Array<QualificationModuleInput>>;
  qualificationType?: InputMaybe<Scalars['String']['input']>;
  responsibleEducationVoucherOrganizationId?: InputMaybe<Scalars['ID']['input']>;
  responsibleRoleEducationVoucher?: InputMaybe<Scalars['String']['input']>;
  responsibleRoleQualification?: InputMaybe<Scalars['String']['input']>;
  startOfFurtherEducation?: InputMaybe<Scalars['DateTime']['input']>;
  theoryExamDate?: InputMaybe<Scalars['DateTime']['input']>;
  theoryHours?: InputMaybe<Scalars['Float']['input']>;
  theoryInstitution?: InputMaybe<Scalars['String']['input']>;
  totalHours?: InputMaybe<Scalars['Float']['input']>;
};

export class QualificationModule {
  __typename?: 'QualificationModule';
  areaOfDeployment?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  practiceHours?: Maybe<Scalars['Float']['output']>;
  theoryHours?: Maybe<Scalars['Float']['output']>;
};

export class QualificationModuleInput {
  areaOfDeployment?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  practiceHours?: InputMaybe<Scalars['Float']['input']>;
  theoryHours?: InputMaybe<Scalars['Float']['input']>;
};

export class Query {
  __typename?: 'Query';
  areCollectionsFavorite: Array<Favorite>;
  areOrganizationLinked: Scalars['Boolean']['output'];
  canAccessCandidateEdit: CandidateAccess;
  canAccessCandidateView: CandidateAccess;
  canAccessCollectionEdit: CollectionAccess;
  canAccessCollectionView: CollectionAccess;
  canAccessProcessView: ProcessAccess;
  canAccessTaskAct: TaskAccess;
  checkUserGroupHasUsers: Array<UserGroupHasMembers>;
  convertAndGetDocumentDownloadUrl: DocumentUrlAndFileName;
  convertManyDocuments: DocumentUrlAndFileName;
  createOrganizationAuditReport: ReportResult;
  detectZipCode?: Maybe<GeoZipCode>;
  downloadAllDocumentTemplatesForCandidate: Archive;
  downloadAllDocumentTemplatesForCollection: Archive;
  exportAssets: AssetDownload;
  exportOrganizationsData: XlsxTemplateResult;
  exportStaticData: StaticDataDownload;
  findAllLinkableOrganizations: Array<Organization>;
  findOccupationSkills?: Maybe<OccupationSkillSet>;
  generateCVAsDocx?: Maybe<GenerateCvAsDocxResponse>;
  generateFeedbackReport: ReportResult;
  generateProcessStatusReport: ProcessReportResult;
  generateProcessTaskListReport: ProcessReportResult;
  generateSummaryReportOfAllProcesses: ProcessReportResult;
  getAccessibleProcesses: Array<Process>;
  getActiveContract: Contract;
  getActivities: Array<Activity>;
  getActivityDiff?: Maybe<Array<ActivityDiff>>;
  getActivityFilterCandidates: Array<ActivityFilterOption>;
  getActivityFilterCollections: Array<ActivityFilterOption>;
  getActivityFilterOrganizations: Array<ActivityFilterOption>;
  getActivityFilterProcesses: Array<ActivityFilterOption>;
  getActivityFilterUsers: Array<ActivityFilterOption>;
  getAdHocEducationExams: Array<AdHocEducationExam>;
  getAdHocEducationExamsByCandidateId: Array<AdHocEducationExamForCandidate>;
  getAllAttachableProcesses: Array<AttachableProcess>;
  getAllByTemplateGeneratedCollections: Array<Collection>;
  getAllCandidates: CandidatePage;
  getAllCollectionAutoFilterTemplates: Array<CollectionAutoFilterTemplate>;
  getAllCollections: Array<Collection>;
  getAllConfigs: Array<CandidateProfileConfig>;
  getAllContractTemplates: Array<ContractTemplate>;
  getAllContracts: Array<Contract>;
  getAllDeletionRequests?: Maybe<Array<CandidateDeletionRequest>>;
  getAllDerivableModels: Array<PublishedProcessModelReleaseVersion>;
  getAllDocumentOverwrites?: Maybe<Array<DocumentTypeOverwrite>>;
  getAllFeedbackForView: Array<Feedback>;
  getAllLocalPartnerProfileConfigSets: Array<LocalPartnerProfileConfigSet>;
  getAllManagedOrganizations?: Maybe<Array<Organization>>;
  getAllOrganizationWhichLinkTo: Array<OrganizationLink>;
  getAllProcessActions: Array<ProcessAction>;
  getAllProcesses: Array<Process>;
  getAllReleases: Array<ReleasedProcessModelInfo>;
  getAllTemplateDisplayNamesOfDemoTenant: Array<Scalars['String']['output']>;
  getAllUserGroups: Array<UserGroup>;
  getAlwaysGeneratedTemplates: Array<Scalars['String']['output']>;
  getAnabinDegreeDetails?: Maybe<AnabinDegreesForInfoView>;
  getAnabinInstitutionDetails?: Maybe<AnabinInstitutionInfoViewDetails>;
  getAreaModel: ProcessAreaModelEntity;
  getAssignableCollectionsForCandidate: Array<Collection>;
  getAssignableMembers: Array<AssignableMember>;
  getAttachableAutoCollections: Array<AttachableAutoCollection>;
  getAudit?: Maybe<Audit>;
  getAuditDefinition: AuditDefinition;
  getAuditDefinitions: Array<AuditDefinition>;
  getAutoGeneratedCVUrl: AutoCvResponse;
  getAvailableAutogenTemplates: Array<AvailableDocumentTemplate>;
  getAvailableDocumentFormats: DocumentFormats;
  getAvailableDocumentTemplates: Array<AvailableDocumentTemplateGroup>;
  getAvailableLanguages: Array<Scalars['String']['output']>;
  getAvailableOrganizations: OrganizationListDto;
  getAvailablePdfFormTemplates: Array<PdfFormTemplate>;
  getAvailableProcessModelVersions: Array<PublishedProcessModelReleaseVersion>;
  getAvailableProcessModels: Array<ProcessModel>;
  getAvailableReportTemplates: Array<ReportTemplate>;
  getAvailableUserGroups: Array<UserGroup>;
  getBillingSettings: BillingSettings;
  getCandidate?: Maybe<Candidate>;
  getCandidateActivities: Array<Activity>;
  getCandidateArchive: Archive;
  getCandidateData: ExcelAddInDto;
  getCandidateDeletionRequest?: Maybe<CandidateDeletionRequest>;
  getCandidateDocumentTemplates: Array<DocumentTemplate>;
  getCandidateEvents: Array<CandidateEvent>;
  getCandidateFieldTranslations: Array<PropertyTranslation>;
  getCandidateForEdit: CandidateForEdit;
  getCandidateForView: CandidateForView;
  getCandidateInbox: TaskInbox;
  getCandidateInboxCount: Scalars['Float']['output'];
  getCandidateInfo: Scalars['String']['output'];
  getCandidateInterviewIterations?: Maybe<CandidateInterviewsFeedback>;
  getCandidateMultiEditConfiguration: CandidateMultiEditConfiguration;
  getCandidateNote: CandidateNote;
  getCandidateNotes: Array<CandidateNote>;
  getCandidateStaticDataContextInformation: CandidateStaticDataContextInformation;
  getCandidateTagDescriptions: Array<CandidateTagDescription>;
  getCandidateTags?: Maybe<Array<CandidateTag>>;
  getCandidatesForAssignment: CandidatePage;
  getCandidatesOfCollection: CandidatePage;
  getCandidatesOfCollectionForView: Array<CandidateForView>;
  getCascadedLinkedOrganizations: Array<Organization>;
  getCascadedLinkedOrganizationsFromSourceOrganization: Array<Organization>;
  getCertificateValuationAuthorities: Array<CertificateValuationAuthority>;
  getCertifiedEducationExams: Array<CertifiedEducationExamGroup>;
  getChamberOfCrafts: Array<ChamberOfCrafts>;
  getCollection: Collection;
  getCollectionAutoFilter: CollectionAutoFilter;
  getCollectionAutoFilterTemplate: CollectionAutoFilterTemplate;
  getCollectionDocumentTemplates: Array<DocumentTemplate>;
  getCollectionEvents: Array<CandidateEvent>;
  getCollectionFilterPreset: CollectionFilterPreset;
  getCollectionFilterPresets: Array<CollectionFilterPreset>;
  getCollectionForPreselection?: Maybe<CollectionId>;
  getCollectionList: CollectionListDto;
  getCollectionOrganizations: Array<Organization>;
  getCollectionsForAssignment: Array<Collection>;
  getCollectionsForCandidate: Array<CollectionsForCandidateResult>;
  getCollectionsForDelegation: Array<Collection>;
  getCollectionsSharedToOrganization: Array<Collection>;
  getCommandHistory: ProcessModelCommandHistoryInfoPage;
  getCommentedStatesIds: Array<Scalars['String']['output']>;
  getConfig: CandidateProfileConfig;
  getConfigByStatusAndFunction: CandidateProfileConfig;
  getConsent?: Maybe<TcConsent>;
  getConsents: Array<Consent>;
  getContract: Contract;
  getContractTemplate?: Maybe<ContractTemplate>;
  getCourseEvents: Array<CandidateEvent>;
  getCreatableDocumentTypes: Array<StaticDataModel>;
  getCurrentPublicAPIAccessToken: Scalars['String']['output'];
  getCustomSkillSets?: Maybe<Array<CustomSkillSet>>;
  getCustomer?: Maybe<Customer>;
  getCustomerPortalUrl?: Maybe<Scalars['String']['output']>;
  getDashboardMetrics: Array<DashboardMetric>;
  getDataForFormerAudit?: Maybe<TaskStateProcessForOrganizationAudit>;
  getDataStrategies: Array<StaticDataModel>;
  getDataStrategyConfigurationFormly: Scalars['JSON']['output'];
  getDataStrategyGenerationFormly: Scalars['JSON']['output'];
  getDiplomaticMissionsAbroad: Array<DiplomaticMission>;
  getDiplomaticMissionsAtHome: Array<DiplomaticMission>;
  getDocument: Document;
  getDocumentAiUploadUrl: DocumentAiUrl;
  getDocumentByType?: Maybe<Document>;
  getDocumentDownloadUrl: Scalars['String']['output'];
  getDocumentDownloadUrlWithFileName: DocumentUrlAndFileName;
  getDocumentDownloadWithContainerUrl: Scalars['String']['output'];
  getDocumentRequirement: DocumentRequirement;
  getDocumentRequirementByType: DocumentRequirement;
  getDocumentRequirements: Array<DocumentRequirement>;
  getDocumentTemplate: DocumentTemplate;
  getDocumentTypeOverwrite?: Maybe<DocumentTypeOverwrite>;
  getDocumentUploadUrl: DocumentUrl;
  getDocumentUploadWithContainerUrl: Scalars['String']['output'];
  getDocuments: Array<Document>;
  getDocumentsByTypes: Array<Document>;
  getEducationCourse: EducationCourse;
  getEducationCoursePerformance: EducationCoursePerformance;
  getEducationCoursePerformanceByCandidate: EducationCoursePerformanceByCandidate;
  getEducationCourseTotalPerformance: EducationCourseTotalPerformance;
  getEducationCourseWeeklyPerformance: EducationCourseWeeklyPerformance;
  getEducationCourses: Array<EducationCourse>;
  getEducationCoursesForCandidate: Array<EducationCourse>;
  getEducationCoursesForCandidateToAddTo: Array<EducationCourse>;
  getEducationExamData: EducationExamBaseData;
  getEducationExamParticipants: Array<EducationExamParticipant>;
  getEducationExamResultsByCandidateId: Array<EducationExamResultFullData>;
  getEducationExamResultsByExamId: Array<EducationExamResultFullData>;
  getEducationExamResultsUpdateTable: Array<EducationExamResultFullData>;
  getEducationExams: Array<EducationExam>;
  getEducationExamsByCandidateId: Array<EducationExamForCandidate>;
  getEducationExamsByCourseId: Array<EducationExam>;
  getEducationModule: EducationModule;
  getEducationModules: Array<EducationModule>;
  getEffectiveDocumentAccess: DocumentAccessInfo;
  getEffectiveFileAccess: FileAccessInfo;
  getEffectiveSharing: EffectiveSharingOutput;
  getEmail: Email;
  getEmailSendOutRules: Array<EmailSendOutRule>;
  getEmailTemplate: EmailTemplate;
  getEmailTemplates: Array<EmailTemplate>;
  getEmailTemplatesByType: Array<EmailTemplate>;
  getEmailsForCandidate: Array<EmailForList>;
  getEmailsForProcess: Array<EmailForList>;
  getEmailsForUser: Array<EmailForList>;
  getEmployer?: Maybe<Employer>;
  getEmployerApplications: Array<EmployerApplication>;
  getEmployerFilterDefaults: EmployerFilterDefaults;
  getEmployerFilterFields: EmployerFilterFields;
  getEmployers: Array<Employer>;
  getEmploymentApprovalAuthorities: Array<EmploymentApprovalAuthority>;
  getEmploymentApprovalAuthorityById: EmploymentApprovalAuthority;
  getEnabledSharing: Sharing;
  getEscoSkillsByProfession?: Maybe<Array<OccupationSkillSet>>;
  getEscoSkillsByProfessionAndOrganization?: Maybe<Array<OccupationSkillSet>>;
  getFavoriteCandidateIds: Array<CandidateId>;
  getFavoriteCandidates: Array<Candidate>;
  getFavoriteCollections: Array<Collection>;
  getFeedback: Feedback;
  getFieldActivityDiff?: Maybe<Array<ActivityDiff>>;
  getGeneralDocumentRequirement?: Maybe<GeneralDocumentRequirement>;
  getHighestPlacementTest: HighestPlacementEducationExam;
  getImageAccessToken: ImageAccessTokenResult;
  getImmigrationAuthorities: Array<ImmigrationAuthority>;
  getImmigrationCountries: Array<Scalars['String']['output']>;
  getImportUploadUrl: Scalars['String']['output'];
  getKnowTemplateVariablesDocument: Scalars['String']['output'];
  getKnownPdfFormTokens: Array<KnownPdfFormTokens>;
  getKnownVars: KnownVarsTreeDto;
  getLaborAgreement: LaborAgreement;
  getLaborAgreements: Array<LaborAgreement>;
  getLastCommand: ProcessModelCommandGetLatestResult;
  getLayoutEmailTemplate?: Maybe<EmailTemplate>;
  getLinkedOrganizations: Array<Organization>;
  getLocalPartnerProfileConfigSet: LocalPartnerProfileConfigSet;
  getLoginLogoUrl: Scalars['String']['output'];
  getMarkedTalents?: Maybe<Array<Talent>>;
  getMergeTags: Scalars['JSONObject']['output'];
  getMessagesReception: MessagesReception;
  getMessengerOptInState?: Maybe<MessengerUserOptIn>;
  getMinimalAnabinAssetById: Array<StaticDataModel>;
  getMinimalAnabinAssetData: Array<StaticDataModel>;
  getMissingDocumentTemplates: Array<Scalars['String']['output']>;
  getMissingEmailTemplates: Array<Scalars['String']['output']>;
  getMissingOrganizationDocuments: Array<Scalars['String']['output']>;
  getMissingSharingNotificationEmailTemplates: Array<Scalars['String']['output']>;
  getNextOutboxRun: Scalars['DateTime']['output'];
  getNotificationActivities: NotificationList;
  getNotificationActivityCount: Scalars['Float']['output'];
  getNotificationSettings: NotificationSettings;
  getNotifyUsersOnDeletionRequest?: Maybe<Array<User>>;
  getOrganizationContact?: Maybe<OrganizationContact>;
  getOrganizationContactSettings?: Maybe<OrganizationContactSettings>;
  getOrganizationContacts: Array<OrganizationContact>;
  getOrganizationContractSettings: OrganizationContractSettings;
  getOrganizationCrm: OrganizationCrm;
  getOrganizationEvents: Array<CandidateEvent>;
  getOrganizationLink: OrganizationLink;
  getOrganizationProfile?: Maybe<OrganizationProfile>;
  getOrganizationProfileFields: OrganizationProfileFields;
  getOrganizationsForAssignment: Array<Organization>;
  getOrganizationsForCollectionSelection: Array<Organization>;
  getOrganizationsLinkedTo: Array<Organization>;
  getOrganizationsSharingTabOfCandidate: Array<Organization>;
  getOrganizationsWithProcesses: Array<Organization>;
  getOutboxEmail?: Maybe<Email>;
  getOutboxForProcess: Array<EmailForList>;
  getOverviewOfRequiredDocumentsForCandidate: RequiredDocumentOverview;
  getOverviewOfRequiredDocumentsForCollection: RequiredDocumentOverview;
  getOverviewOfRequiredDocumentsForProcess: RequiredDocumentOverview;
  getPdfFormTemplate: PdfFormTemplate;
  getPdfFormTemplateSettings: PdfFormTemplateSettings;
  getPhaseModel: ProcessPhaseModelEntity;
  getPlan: Plan;
  getPlans: Array<Plan>;
  getPredecessors: Array<ProcessPredecessorPhase>;
  getPredefinedProcessModels: Array<PredefinedProcessModelDeclaration>;
  getProcess: Process;
  getProcessAction: ProcessAction;
  getProcessCandidates: Array<Candidate>;
  getProcessFormly: ProcessFormlyModel;
  getProcessHeatmap: ProcessHeatmap;
  getProcessModel: ProcessModelResult;
  getProcessModelTree: ProcessModelTree;
  getProcessParties: Array<Organization>;
  getProcessPreset?: Maybe<ProcessPreset>;
  getProcessProgress: ProcessProgress;
  getProcessesForCandidate: Array<Process>;
  getProcessesForDelegation: Array<ProcessForDelegation>;
  getProcessesForOrganization: Array<Process>;
  getProcessesOrganizationsForCandidate: Array<Organization>;
  getProcessesRolesForCandidate: Array<ProcessRole>;
  getProfessionAreas?: Maybe<Array<StaticDataModel>>;
  getProfessionConfiguration?: Maybe<ProfessionConfiguration>;
  getProfessionDefaults?: Maybe<ProfessionDefaults>;
  getProfessionSubAreas?: Maybe<Array<StaticDataModel>>;
  getProfessionTree?: Maybe<Array<TreeDataModel>>;
  getProfessionalExperience: ProfessionalExperience;
  getProfessionalExperiences: Array<ProfessionalExperience>;
  getProfessionalExperiencesByType: Array<ProfessionalExperience>;
  getProfessionalField: ProfessionalField;
  getProfessionalFieldSettings?: Maybe<ProfessionalFieldSettings>;
  getProfessionalFields: Array<ProfessionalField>;
  getProfessions?: Maybe<Array<StaticDataModel>>;
  getProperties: Array<PropertyDefinition>;
  getPublicStaticData?: Maybe<StaticDataResult>;
  getPublishedProcessModels?: Maybe<PublishedProcessModelEntity>;
  getReCertificationLink: Scalars['String']['output'];
  getReadableTalentPools: Array<TalentPool>;
  getRecognitionAuthorities: Array<RecognitionAuthority>;
  getReleaseHistory: Array<ReleasedProcessModelInfo>;
  getRelevantConsentForUser: Array<Consent>;
  getReplacementsForCandidate?: Maybe<Array<Candidate>>;
  getRequiredDocument: RequiredDocument;
  getRequiredDocumentsForCandidate: Array<RequiredDocument>;
  getRequiredDocumentsForOrganization: Array<RequiredDocument>;
  getRequiredVaccinationStatesForCandidates?: Maybe<Array<CandidateVaccinationStatus>>;
  getRequiredVaccinations?: Maybe<Array<RequiredVaccinationsResultEntry>>;
  getRequiredVaccinationsConfiguration: RequiredVaccinations;
  getRoleDefinitions: Array<ProcessModelRole>;
  getSalesData: ExcelAddInDto;
  getSelectableCollections: Array<CollectionAndSharing>;
  getSelectableEducationModules: Array<EducationModule>;
  getSelectableOrganizations: Array<Organization>;
  getSentMessage: MessengerMessage;
  getSettingsForCandidateOrganization: SystemSettingsForCandidateOrg;
  getSharedCollectionEvents: Array<CandidateEvent>;
  getSharing: Sharing;
  getSharingActivities: Array<Activity>;
  getSharingDocuments: Array<Document>;
  getSharingPreset: SharingPreset;
  getSharingPresets: Array<SharingPreset>;
  getSharingState: SharingState;
  getSharingsForCollection: Array<Sharing>;
  getSharingsForOrganization: Array<Sharing>;
  getState?: Maybe<ProcessStateResult>;
  getStaticData?: Maybe<StaticDataResult>;
  getStoredProcessModels: Array<ProcessModelResult>;
  getSwitchableOrganizations: Array<Organization>;
  getSystemSetting: SystemSetting;
  getTalentPool: TalentPool;
  getTalentPoolConfig: TalentPoolConfiguration;
  getTalentPoolCv: TalentPoolCv;
  getTalentPoolFindOccupationSkills?: Maybe<OccupationSkillSet>;
  getTalentPoolImageAccessToken: ImageAccessTokenResult;
  getTalentPoolOrganization: Organization;
  getTalentPoolParticipant: TalentPoolParticipant;
  getTalentPoolParticipants: Array<TalentPoolParticipant>;
  getTalentPoolSharing: TalentPoolSharing;
  getTalentPoolSharings: Array<TalentPoolSharing>;
  getTalentPoolStaticData?: Maybe<StaticDataResult>;
  getTalentPools: Array<TalentPool>;
  getTalentsPublic: PublicTalentPoolSearch;
  getTalentsRestricted: RestrictedTalentPoolSearch;
  getTask: ProcessTaskResult;
  getTaskActivities: Array<Activity>;
  getTaskArchive?: Maybe<Archive>;
  getTaskCopyData: Scalars['String']['output'];
  getTaskForAct: ProcessTaskResult;
  getTaskModel: ProcessTaskModelResult;
  getTaskReferencedBy: TaskReferencedBy;
  getTaskReminderState?: Maybe<TaskReminderState>;
  getUsedVariables: UsedVariablesDownload;
  getUserInbox: TaskInbox;
  getUserInboxCount: Scalars['Float']['output'];
  getVisibleTasks: Array<ProcessTaskResult>;
  getWritableDocumentTypes: Array<StaticDataModel>;
  getWritableTalentPools: Array<TalentPool>;
  getXLSXProcessTranslationsUploadUrl: Scalars['String']['output'];
  getXLSXTemplateForCandidates: XlsxTemplateResult;
  getXLSXTemplateForContractTemplates: XlsxTemplateResult;
  getXLSXTemplateForOrganizationCRMData: XlsxTemplateResult;
  getXLSXTemplateForOrganizationContactSettings: XlsxTemplateResult;
  getXLSXTemplateForOrganizationContacts: XlsxTemplateResult;
  getXLSXTemplateForOrganizationUserGroups: XlsxTemplateResult;
  getXLSXTemplateForOrganizationUserMemberships: XlsxTemplateResult;
  getXLSXTemplateForOrganizationUsers: XlsxTemplateResult;
  getXLSXTemplateForOrganizations: XlsxTemplateResult;
  getXLSXTemplateForProcessTranslations: XlsxTemplateResult;
  getXLSXTemplateForProcesses: XlsxTemplateResult;
  isCandidateFavorite: Favorite;
  isCollectionFavorite: Favorite;
  isMatchingEnabled: Array<IsMatchingEnabled>;
  isOrganizationLicensed: Scalars['Boolean']['output'];
  lastLoginDate: LastUserLogin;
  organization: Organization;
  prepareNewTask: ProcessTaskResult;
  previewCandidateMultiEdit: Array<CandidateMultiEditPreview>;
  previewCandidateMultiInvite: Array<CandidateMultiEditPreview>;
  previewCandidateMultiUninvite: Array<CandidateMultiEditPreview>;
  previewEnterMissingInformation: EnterMissingInformationPreview;
  previewEnterMissingInformationV2: EnterMissingInformationV2Preview;
  renderEmailTemplate: EmailTemplateRender;
  requestTranslation: PropertyTranslation;
  resolveShortLink?: Maybe<Scalars['String']['output']>;
  searchOccupationSkills?: Maybe<OccupationSkillSet>;
  settings: Settings;
  user: User;
  userGroup: UserGroup;
  userGroups: Array<UserGroup>;
  users: Array<User>;
  usersWithRoles: Array<User>;
  validateContract: Array<ContractValidationError>;
  validateDocuments: MutationResult;
  validateProcessModel: ProcessModelValidationResult;
  version: Scalars['String']['output'];
};


export type QueryAreCollectionsFavoriteArgs = {
  input: FavoriteCollectionsInput;
};


export type QueryAreOrganizationLinkedArgs = {
  input: OrganizationLinkedInput;
};


export type QueryCanAccessCandidateEditArgs = {
  input: CandidateAccessInput;
};


export type QueryCanAccessCandidateViewArgs = {
  input: CandidateAccessInput;
};


export type QueryCanAccessCollectionEditArgs = {
  input: CollectionAccessInput;
};


export type QueryCanAccessCollectionViewArgs = {
  input: CollectionAccessInput;
};


export type QueryCanAccessProcessViewArgs = {
  input: ProcessAccessInput;
};


export type QueryCanAccessTaskActArgs = {
  input: TaskAccessInput;
};


export type QueryCheckUserGroupHasUsersArgs = {
  input: UserGroupHasMembersInput;
};


export type QueryConvertAndGetDocumentDownloadUrlArgs = {
  input: DocumentLoadWithFileNameInput;
};


export type QueryConvertManyDocumentsArgs = {
  input: ConvertManyDocumentsInput;
};


export type QueryCreateOrganizationAuditReportArgs = {
  input: OrganizationAuditReportInput;
};


export type QueryDetectZipCodeArgs = {
  input: GeoZipCodeInput;
};


export type QueryDownloadAllDocumentTemplatesForCandidateArgs = {
  input: DownloadDocumentTemplateCandidateInput;
};


export type QueryDownloadAllDocumentTemplatesForCollectionArgs = {
  input: DownloadDocumentTemplateCollectionInput;
};


export type QueryExportAssetsArgs = {
  input: ExportAssetInput;
};


export type QueryExportOrganizationsDataArgs = {
  input: XlsxTemplateForProcessesGetInput;
};


export type QueryExportStaticDataArgs = {
  input: ExportStaticDataInput;
};


export type QueryFindAllLinkableOrganizationsArgs = {
  input: LinkableOrganizationsInput;
};


export type QueryFindOccupationSkillsArgs = {
  input: OccupationSkillByIdsInput;
};


export type QueryGenerateCvAsDocxArgs = {
  candidateId: Scalars['String']['input'];
  candidateOrgId: Scalars['String']['input'];
  language: Scalars['String']['input'];
  sharingOrgId: Scalars['String']['input'];
};


export type QueryGenerateFeedbackReportArgs = {
  input: FeedbackReportInput;
};


export type QueryGenerateProcessStatusReportArgs = {
  input: ProcessReportInput;
};


export type QueryGenerateProcessTaskListReportArgs = {
  input: ProcessTaskListReportInput;
};


export type QueryGenerateSummaryReportOfAllProcessesArgs = {
  input: ReportInput;
};


export type QueryGetActiveContractArgs = {
  organizationId: Scalars['ID']['input'];
};


export type QueryGetActivitiesArgs = {
  input: ActivityPageInput;
};


export type QueryGetActivityDiffArgs = {
  input: ActivityDiffInput;
};


export type QueryGetActivityFilterCandidatesArgs = {
  input: ActivityCandidateFilterInput;
};


export type QueryGetActivityFilterCollectionsArgs = {
  input: ActivityCollectionFilterInput;
};


export type QueryGetActivityFilterOrganizationsArgs = {
  input: ActivityOrganizationFilterInput;
};


export type QueryGetActivityFilterProcessesArgs = {
  input: ActivityCandidateFilterInput;
};


export type QueryGetActivityFilterUsersArgs = {
  input: ActivityUserFilterInput;
};


export type QueryGetAdHocEducationExamsArgs = {
  input: EducationExamGetByOrgIdInput;
};


export type QueryGetAdHocEducationExamsByCandidateIdArgs = {
  input: AdHocEducationExamGetByCandidateIdInput;
};


export type QueryGetAllAttachableProcessesArgs = {
  input: AttachableProcessesGetInput;
};


export type QueryGetAllByTemplateGeneratedCollectionsArgs = {
  input: CreatedCollectionsOfAutoFilterTemplateGetInput;
};


export type QueryGetAllCandidatesArgs = {
  input: CandidatePageInput;
};


export type QueryGetAllCollectionAutoFilterTemplatesArgs = {
  input: CollectionAutoFilterTemplateGetAllInput;
};


export type QueryGetAllCollectionsArgs = {
  input: CollectionEnumerationInput;
};


export type QueryGetAllConfigsArgs = {
  input: CandidateProfileConfigGetListAllInput;
};


export type QueryGetAllContractTemplatesArgs = {
  input: ContractTemplateListInput;
};


export type QueryGetAllContractsArgs = {
  organizationId: Scalars['ID']['input'];
};


export type QueryGetAllDeletionRequestsArgs = {
  organizationId: Scalars['String']['input'];
};


export type QueryGetAllDerivableModelsArgs = {
  input: DerivableProcessModelDeclarationGetInput;
};


export type QueryGetAllDocumentOverwritesArgs = {
  organizationId: Scalars['String']['input'];
};


export type QueryGetAllFeedbackForViewArgs = {
  orgId: Scalars['String']['input'];
  sharingId: Scalars['String']['input'];
};


export type QueryGetAllLocalPartnerProfileConfigSetsArgs = {
  input: LocalPartnerProfileConfigSetGetListAllInput;
};


export type QueryGetAllManagedOrganizationsArgs = {
  organizationId: Scalars['ID']['input'];
};


export type QueryGetAllOrganizationWhichLinkToArgs = {
  organizationId: Scalars['String']['input'];
};


export type QueryGetAllProcessActionsArgs = {
  input: ProcessActionListInput;
};


export type QueryGetAllProcessesArgs = {
  input: ProcessListInput;
};


export type QueryGetAllReleasesArgs = {
  orgId: Scalars['String']['input'];
};


export type QueryGetAllUserGroupsArgs = {
  organizationId: Scalars['ID']['input'];
};


export type QueryGetAlwaysGeneratedTemplatesArgs = {
  organizationId: Scalars['ID']['input'];
};


export type QueryGetAnabinDegreeDetailsArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetAnabinInstitutionDetailsArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetAreaModelArgs = {
  input: ProcessAreaModelGetInput;
};


export type QueryGetAssignableCollectionsForCandidateArgs = {
  input: CollectionListInput;
};


export type QueryGetAssignableMembersArgs = {
  groupId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};


export type QueryGetAttachableAutoCollectionsArgs = {
  input: GetAttachableAutoCollectionsInput;
};


export type QueryGetAuditArgs = {
  input: AuditGetInput;
};


export type QueryGetAuditDefinitionArgs = {
  input: AuditDefinitionGetInput;
};


export type QueryGetAuditDefinitionsArgs = {
  input: AuditDefinitionListInput;
};


export type QueryGetAutoGeneratedCvUrlArgs = {
  input: AutoGeneratedCvUrlInput;
};


export type QueryGetAvailableAutogenTemplatesArgs = {
  input: AvailableAutogenTemplateListInput;
};


export type QueryGetAvailableDocumentFormatsArgs = {
  input: DocumentFormatsInput;
};


export type QueryGetAvailableDocumentTemplatesArgs = {
  input: AvailableDocumentTemplateGetInput;
};


export type QueryGetAvailableLanguagesArgs = {
  organizationId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetAvailablePdfFormTemplatesArgs = {
  immigrationCountry: SupportedImmigrationCountry;
  input: PdfFormTemplateListInput;
};


export type QueryGetAvailableProcessModelVersionsArgs = {
  input: AvailableProcessModelVersionsGetInput;
};


export type QueryGetAvailableProcessModelsArgs = {
  input: AvailableProcessModelGetListInput;
};


export type QueryGetAvailableReportTemplatesArgs = {
  input: AvailableReportsGetInput;
};


export type QueryGetAvailableUserGroupsArgs = {
  organizationId: Scalars['ID']['input'];
};


export type QueryGetBillingSettingsArgs = {
  organizationId: Scalars['ID']['input'];
};


export type QueryGetCandidateArgs = {
  input: GetCandidateInput;
};


export type QueryGetCandidateActivitiesArgs = {
  input: CandidateActivityInput;
};


export type QueryGetCandidateArchiveArgs = {
  input: CandidateArchiveInput;
};


export type QueryGetCandidateDataArgs = {
  input: ExcelAddInGetCandidateDataInput;
};


export type QueryGetCandidateDeletionRequestArgs = {
  input: CandidateDeletionRequestInput;
};


export type QueryGetCandidateDocumentTemplatesArgs = {
  input: DocumentTemplateCandidateInput;
};


export type QueryGetCandidateEventsArgs = {
  input: CandidateEventsInput;
};


export type QueryGetCandidateFieldTranslationsArgs = {
  input: PropertyTranslationInput;
};


export type QueryGetCandidateForEditArgs = {
  input: GetCandidateForEdit;
};


export type QueryGetCandidateForViewArgs = {
  input: CandidateIdInput;
};


export type QueryGetCandidateInboxArgs = {
  input: TaskInboxInput;
};


export type QueryGetCandidateInfoArgs = {
  email: Scalars['String']['input'];
};


export type QueryGetCandidateInterviewIterationsArgs = {
  candidateId: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
};


export type QueryGetCandidateMultiEditConfigurationArgs = {
  input: CandidateMultiEditConfigurationInput;
};


export type QueryGetCandidateNoteArgs = {
  input: CandidateNoteGetInput;
};


export type QueryGetCandidateNotesArgs = {
  input: CandidateNoteListInput;
};


export type QueryGetCandidateStaticDataContextInformationArgs = {
  candidateId: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
};


export type QueryGetCandidateTagsArgs = {
  input: CandidateTagGetInput;
};


export type QueryGetCandidatesForAssignmentArgs = {
  input: CollectionCandidatePageInput;
};


export type QueryGetCandidatesOfCollectionArgs = {
  input: CollectionCandidatePageInput;
};


export type QueryGetCandidatesOfCollectionForViewArgs = {
  input: CandidatesOfCollectionForViewInput;
};


export type QueryGetCascadedLinkedOrganizationsArgs = {
  organizationId: Scalars['String']['input'];
};


export type QueryGetCascadedLinkedOrganizationsFromSourceOrganizationArgs = {
  input: OrganizationListInput;
};


export type QueryGetCertificateValuationAuthoritiesArgs = {
  input: CertificateValuationAuthorityInput;
};


export type QueryGetCertifiedEducationExamsArgs = {
  input: EducationExamGetByCandidateIdInput;
};


export type QueryGetChamberOfCraftsArgs = {
  input: ChamberOfCraftsInput;
};


export type QueryGetCollectionArgs = {
  input: CollectionQueryInput;
};


export type QueryGetCollectionAutoFilterArgs = {
  input: CollectionAutoFilterGetInput;
};


export type QueryGetCollectionAutoFilterTemplateArgs = {
  input: CollectionAutoFilterTemplateGetInput;
};


export type QueryGetCollectionDocumentTemplatesArgs = {
  input: DocumentTemplateCollectionInput;
};


export type QueryGetCollectionEventsArgs = {
  input: CollectionEventsInput;
};


export type QueryGetCollectionFilterPresetArgs = {
  input: CollectionFilterPresetGetInput;
};


export type QueryGetCollectionFilterPresetsArgs = {
  input: CollectionFilterPresetListInput;
};


export type QueryGetCollectionForPreselectionArgs = {
  input: GetCandidateInput;
};


export type QueryGetCollectionOrganizationsArgs = {
  colId: Scalars['String']['input'];
  orgId: Scalars['String']['input'];
};


export type QueryGetCollectionsForAssignmentArgs = {
  input: CollectionForAssignmentInput;
};


export type QueryGetCollectionsForCandidateArgs = {
  candidateId: Scalars['String']['input'];
  orgId: Scalars['String']['input'];
};


export type QueryGetCollectionsForDelegationArgs = {
  input: CollectionForDelegationInput;
};


export type QueryGetCollectionsSharedToOrganizationArgs = {
  input: CollectionSharingByOrgAndTypeInput;
};


export type QueryGetCommandHistoryArgs = {
  input: ProcessModelCommandHistoryGetInputType;
};


export type QueryGetCommentedStatesIdsArgs = {
  input: GetCommentsInput;
};


export type QueryGetConfigArgs = {
  input: CandidateProfileConfigGetInput;
};


export type QueryGetConfigByStatusAndFunctionArgs = {
  input: CandidateProfileConfigGetByFunctionAndStatusInput;
};


export type QueryGetConsentArgs = {
  auth0UserId: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
};


export type QueryGetConsentsArgs = {
  input: ConsentListInput;
};


export type QueryGetContractArgs = {
  id: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};


export type QueryGetContractTemplateArgs = {
  input: ContractTemplateGetInput;
};


export type QueryGetCourseEventsArgs = {
  input: CourseEventsInput;
};


export type QueryGetCreatableDocumentTypesArgs = {
  input: CandidateDocumentListInput;
};


export type QueryGetCurrentPublicApiAccessTokenArgs = {
  organizationId: Scalars['String']['input'];
};


export type QueryGetCustomSkillSetsArgs = {
  input: CustomSkillSetInput;
};


export type QueryGetCustomerArgs = {
  organizationId: Scalars['ID']['input'];
};


export type QueryGetCustomerPortalUrlArgs = {
  input: BillingSettingsGetCustomerPortalUrlInput;
};


export type QueryGetDashboardMetricsArgs = {
  input: DashboardMetricInput;
};


export type QueryGetDataForFormerAuditArgs = {
  organizationId: Scalars['String']['input'];
  processId: Scalars['String']['input'];
  taskId: Scalars['String']['input'];
};


export type QueryGetDataStrategiesArgs = {
  input: GetDataStrategiesInput;
};


export type QueryGetDataStrategyConfigurationFormlyArgs = {
  input: GetDataStrategyConfigurationInput;
};


export type QueryGetDataStrategyGenerationFormlyArgs = {
  input: GetDataStrategyGenerationInput;
};


export type QueryGetDiplomaticMissionsAbroadArgs = {
  input: DiplomaticMissionInput;
};


export type QueryGetDiplomaticMissionsAtHomeArgs = {
  input: DiplomaticMissionInput;
};


export type QueryGetDocumentArgs = {
  input: DocumentGetInput;
};


export type QueryGetDocumentAiUploadUrlArgs = {
  input: DocumentAiUploadInput;
};


export type QueryGetDocumentByTypeArgs = {
  input: DocumentGetByTypeInput;
};


export type QueryGetDocumentDownloadUrlArgs = {
  input: DocumentLoadInput;
};


export type QueryGetDocumentDownloadUrlWithFileNameArgs = {
  input: DocumentLoadWithFileNameInput;
};


export type QueryGetDocumentDownloadWithContainerUrlArgs = {
  input: DocumentLoadWithContainerInput;
};


export type QueryGetDocumentRequirementArgs = {
  input: DocumentRequirementGetInput;
};


export type QueryGetDocumentRequirementByTypeArgs = {
  input: DocumentRequirementGetByTypeInput;
};


export type QueryGetDocumentRequirementsArgs = {
  input: DocumentRequirementListInput;
};


export type QueryGetDocumentTemplateArgs = {
  input: DocumentTemplateGetInput;
};


export type QueryGetDocumentTypeOverwriteArgs = {
  input: DocumentTypeOverwriteGetInput;
};


export type QueryGetDocumentUploadUrlArgs = {
  input: DocumentLoadInput;
};


export type QueryGetDocumentUploadWithContainerUrlArgs = {
  input: DocumentLoadWithContainerInput;
};


export type QueryGetDocumentsArgs = {
  input: DocumentListInput;
};


export type QueryGetDocumentsByTypesArgs = {
  input: DocumentGetByTypesInput;
};


export type QueryGetEducationCourseArgs = {
  input: EducationCourseGetInput;
};


export type QueryGetEducationCoursePerformanceArgs = {
  input: EducationCoursePerformanceListInput;
};


export type QueryGetEducationCoursePerformanceByCandidateArgs = {
  input: EducationCoursePerformanceByCandidateGetInput;
};


export type QueryGetEducationCourseTotalPerformanceArgs = {
  input: EducationCourseTotalPerformanceGetInput;
};


export type QueryGetEducationCourseWeeklyPerformanceArgs = {
  input: EducationCourseWeeklyPerformanceGetInput;
};


export type QueryGetEducationCoursesArgs = {
  input: EducationCourseListInput;
};


export type QueryGetEducationCoursesForCandidateArgs = {
  input: EducationCourseListForCandidateInput;
};


export type QueryGetEducationExamDataArgs = {
  input: EducationExamGetByIdInput;
};


export type QueryGetEducationExamParticipantsArgs = {
  input: EducationExamParticipantGetInput;
};


export type QueryGetEducationExamResultsByCandidateIdArgs = {
  input: EducationExamResultsByCandidateIdInput;
};


export type QueryGetEducationExamResultsByExamIdArgs = {
  input: EducationExamResultListInput;
};


export type QueryGetEducationExamResultsUpdateTableArgs = {
  input: EducationExamResultListInput;
};


export type QueryGetEducationExamsArgs = {
  input: EducationExamGetByOrgIdInput;
};


export type QueryGetEducationExamsByCandidateIdArgs = {
  input: EducationExamGetByCandidateIdInput;
};


export type QueryGetEducationExamsByCourseIdArgs = {
  input: EducationExamGetByCourseIdInput;
};


export type QueryGetEducationModuleArgs = {
  input: EducationModuleGetInput;
};


export type QueryGetEducationModulesArgs = {
  input: EducationModuleListInput;
};


export type QueryGetEffectiveDocumentAccessArgs = {
  input: SharingDocumentQueryInput;
};


export type QueryGetEffectiveFileAccessArgs = {
  input: SharingFileQueryInput;
};


export type QueryGetEffectiveSharingArgs = {
  input: EffectiveSharingInput;
};


export type QueryGetEmailArgs = {
  input: EmailGetInput;
};


export type QueryGetEmailTemplateArgs = {
  input: EmailTemplateGetInput;
};


export type QueryGetEmailTemplatesArgs = {
  input: EmailTemplateListInput;
};


export type QueryGetEmailTemplatesByTypeArgs = {
  input: EmailTemplateTypeInput;
};


export type QueryGetEmailsForCandidateArgs = {
  input: EmailListForCandidateInput;
};


export type QueryGetEmailsForProcessArgs = {
  input: EmailListForProcessInput;
};


export type QueryGetEmailsForUserArgs = {
  input: EmailListForUserInput;
};


export type QueryGetEmployerArgs = {
  input: EmployerGetInput;
};


export type QueryGetEmployerApplicationsArgs = {
  input: EmployerApplicationGetInput;
};


export type QueryGetEmployerFilterDefaultsArgs = {
  input: EmployerListInput;
};


export type QueryGetEmployerFilterFieldsArgs = {
  input: EmployerListInput;
};


export type QueryGetEmployersArgs = {
  input: EmployerListInput;
};


export type QueryGetEmploymentApprovalAuthoritiesArgs = {
  input: EmploymentApprovalAuthorityInput;
};


export type QueryGetEmploymentApprovalAuthorityByIdArgs = {
  input: EmploymentApprovalAuthorityByIdInput;
};


export type QueryGetEnabledSharingArgs = {
  input: SharingCollectionQueryInput;
};


export type QueryGetEscoSkillsByProfessionArgs = {
  input: EscoSkillByProfessionInput;
};


export type QueryGetEscoSkillsByProfessionAndOrganizationArgs = {
  input: EscoSkillByProfessionAndOrganizationInput;
};


export type QueryGetFavoriteCandidatesArgs = {
  input?: InputMaybe<FavoriteCollectionInput>;
};


export type QueryGetFavoriteCollectionsArgs = {
  input: FavoriteCollectionsWithSharingByOrgInput;
};


export type QueryGetFeedbackArgs = {
  feedbackId: Scalars['String']['input'];
  orgId: Scalars['String']['input'];
};


export type QueryGetFieldActivityDiffArgs = {
  input: FieldActivityDiffInput;
};


export type QueryGetGeneralDocumentRequirementArgs = {
  organizationId: Scalars['String']['input'];
};


export type QueryGetHighestPlacementTestArgs = {
  input: EducationExamGetByCandidateIdInput;
};


export type QueryGetImageAccessTokenArgs = {
  input: ImageAccessInput;
};


export type QueryGetImmigrationAuthoritiesArgs = {
  input: ImmigrationAuthorityInput;
};


export type QueryGetImmigrationCountriesArgs = {
  input: CollectionInput;
};


export type QueryGetImportUploadUrlArgs = {
  input: ImportUrlInput;
};


export type QueryGetKnowTemplateVariablesDocumentArgs = {
  organizationId: Scalars['String']['input'];
};


export type QueryGetKnownPdfFormTokensArgs = {
  input: PdfFormTemplateListInput;
};


export type QueryGetLaborAgreementArgs = {
  input: LaborAgreementGetInput;
};


export type QueryGetLaborAgreementsArgs = {
  input: LaborAgreementListInput;
};


export type QueryGetLastCommandArgs = {
  input: ProcessModelCommandGetLatestInput;
};


export type QueryGetLayoutEmailTemplateArgs = {
  input: EmailTemplateListInput;
};


export type QueryGetLinkedOrganizationsArgs = {
  input: OrganizationFindInput;
};


export type QueryGetLocalPartnerProfileConfigSetArgs = {
  input: LocalPartnerProfileConfigSetGetInput;
};


export type QueryGetLoginLogoUrlArgs = {
  input: GetLogoUrlInput;
};


export type QueryGetMergeTagsArgs = {
  emailTarget?: InputMaybe<EmailTemplateTarget>;
  organizationId: Scalars['String']['input'];
};


export type QueryGetMessagesReceptionArgs = {
  candidateOrUserId: Scalars['String']['input'];
  isCandidate: Scalars['Boolean']['input'];
  organizationId: Scalars['String']['input'];
};


export type QueryGetMinimalAnabinAssetByIdArgs = {
  input: AnabinByIdInput;
};


export type QueryGetMinimalAnabinAssetDataArgs = {
  input: AnabinMinimalInput;
};


export type QueryGetMissingDocumentTemplatesArgs = {
  organizationId: Scalars['String']['input'];
};


export type QueryGetMissingEmailTemplatesArgs = {
  organizationId: Scalars['String']['input'];
};


export type QueryGetMissingOrganizationDocumentsArgs = {
  organizationId: Scalars['String']['input'];
};


export type QueryGetMissingSharingNotificationEmailTemplatesArgs = {
  organizationId: Scalars['String']['input'];
};


export type QueryGetNextOutboxRunArgs = {
  input: OutboxRunInput;
};


export type QueryGetNotifyUsersOnDeletionRequestArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetOrganizationContactArgs = {
  input: OrganizationContactGetInput;
};


export type QueryGetOrganizationContactSettingsArgs = {
  input: OrganizationContactSettingsGetInput;
};


export type QueryGetOrganizationContactsArgs = {
  input: OrganizationContactListInput;
};


export type QueryGetOrganizationContractSettingsArgs = {
  organizationId: Scalars['ID']['input'];
};


export type QueryGetOrganizationCrmArgs = {
  input: OrganizationCrmGetInput;
};


export type QueryGetOrganizationEventsArgs = {
  input: OrganizationEventsInput;
};


export type QueryGetOrganizationLinkArgs = {
  input: OrganizationLinkGetInput;
};


export type QueryGetOrganizationProfileArgs = {
  input: OrganizationProfileGetInput;
};


export type QueryGetOrganizationProfileFieldsArgs = {
  input: OrganizationProfileGetInput;
};


export type QueryGetOrganizationsForAssignmentArgs = {
  input: OrganizationForAssignmentInput;
};


export type QueryGetOrganizationsForCollectionSelectionArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetOrganizationsLinkedToArgs = {
  input: OrganizationFindInput;
};


export type QueryGetOrganizationsSharingTabOfCandidateArgs = {
  input: OrganizationsBySharedTabInput;
};


export type QueryGetOrganizationsWithProcessesArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetOutboxEmailArgs = {
  input: EmailGetInput;
};


export type QueryGetOutboxForProcessArgs = {
  input: EmailListForProcessInput;
};


export type QueryGetOverviewOfRequiredDocumentsForCandidateArgs = {
  input: DocumentOverviewInputCandidate;
};


export type QueryGetOverviewOfRequiredDocumentsForCollectionArgs = {
  input: DocumentOverviewInputCollection;
};


export type QueryGetOverviewOfRequiredDocumentsForProcessArgs = {
  input: DocumentOverviewInputProcess;
};


export type QueryGetPdfFormTemplateArgs = {
  input: PdfFormTemplateGetInput;
};


export type QueryGetPdfFormTemplateSettingsArgs = {
  input: PdfFormTemplateSettingsGetInput;
};


export type QueryGetPhaseModelArgs = {
  input: ProcessPhaseModelGetInput;
};


export type QueryGetPlanArgs = {
  planId: Scalars['ID']['input'];
};


export type QueryGetPredecessorsArgs = {
  input: ProcessTaskListInput;
};


export type QueryGetPredefinedProcessModelsArgs = {
  orgId: Scalars['String']['input'];
};


export type QueryGetProcessArgs = {
  orgId: Scalars['String']['input'];
  processId: Scalars['String']['input'];
};


export type QueryGetProcessActionArgs = {
  input: ProcessActionGetInput;
};


export type QueryGetProcessCandidatesArgs = {
  organizationId: Scalars['String']['input'];
  processId: Scalars['String']['input'];
};


export type QueryGetProcessFormlyArgs = {
  orgId: Scalars['String']['input'];
  processId: Scalars['String']['input'];
};


export type QueryGetProcessHeatmapArgs = {
  input: ProcessHeatmapInput;
};


export type QueryGetProcessModelArgs = {
  input: ProcessModelGetInput;
};


export type QueryGetProcessModelTreeArgs = {
  input: ProcessModelTreeGetInput;
};


export type QueryGetProcessPartiesArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetProcessPresetArgs = {
  input: ProcessPresetInput;
};


export type QueryGetProcessProgressArgs = {
  input: ProcessTaskListInput;
};


export type QueryGetProcessesForCandidateArgs = {
  candidateId: Scalars['String']['input'];
  orgId: Scalars['String']['input'];
};


export type QueryGetProcessesForDelegationArgs = {
  input: ProcessListInput;
};


export type QueryGetProcessesForOrganizationArgs = {
  input: ProcessesForOrganizationInput;
};


export type QueryGetProcessesOrganizationsForCandidateArgs = {
  candidateId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};


export type QueryGetProcessesRolesForCandidateArgs = {
  candidateId: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
};


export type QueryGetProfessionAreasArgs = {
  input: ProfessionInput;
};


export type QueryGetProfessionConfigurationArgs = {
  input: ProfessionConfigurationGetInput;
};


export type QueryGetProfessionDefaultsArgs = {
  input: ProfessionConfigurationGetInput;
};


export type QueryGetProfessionSubAreasArgs = {
  input: ProfessionInput;
};


export type QueryGetProfessionTreeArgs = {
  input: ProfessionInput;
};


export type QueryGetProfessionalExperienceArgs = {
  input: ProfessionalExperienceGetInput;
};


export type QueryGetProfessionalExperiencesArgs = {
  input: ProfessionalExperienceListInput;
};


export type QueryGetProfessionalExperiencesByTypeArgs = {
  input: ProfessionalExperienceGetByTypeInput;
};


export type QueryGetProfessionalFieldArgs = {
  input: ProfessionalFieldGetInput;
};


export type QueryGetProfessionalFieldSettingsArgs = {
  input: ProfessionalFieldSettingsGetInput;
};


export type QueryGetProfessionalFieldsArgs = {
  input: ProfessionalFieldListInput;
};


export type QueryGetProfessionsArgs = {
  input: ProfessionInput;
};


export type QueryGetPropertiesArgs = {
  includeArrays?: Scalars['Boolean']['input'];
  organizationId: Scalars['String']['input'];
  typeName: Scalars['String']['input'];
  writableOnly?: Scalars['Boolean']['input'];
};


export type QueryGetPublicStaticDataArgs = {
  input: StaticDataModelInput;
};


export type QueryGetPublishedProcessModelsArgs = {
  orgId: Scalars['String']['input'];
};


export type QueryGetReadableTalentPoolsArgs = {
  input: TalentPoolListInput;
};


export type QueryGetRecognitionAuthoritiesArgs = {
  input: RecognitionAuthorityInput;
};


export type QueryGetReleaseHistoryArgs = {
  input: ProcessModelReleaseListGetInput;
};


export type QueryGetRelevantConsentForUserArgs = {
  input: ConsentForUser;
};


export type QueryGetReplacementsForCandidateArgs = {
  input: CandidateReplacedByInput;
};


export type QueryGetRequiredDocumentArgs = {
  input: RequiredDocumentGetInput;
};


export type QueryGetRequiredDocumentsForCandidateArgs = {
  input: RequiredDocumentsForCandidateInput;
};


export type QueryGetRequiredDocumentsForOrganizationArgs = {
  input: RequiredDocumentsForOrganizationInput;
};


export type QueryGetRequiredVaccinationStatesForCandidatesArgs = {
  input: RequiredVaccinationsStatesForCollectionInput;
};


export type QueryGetRequiredVaccinationsArgs = {
  input: RequiredVaccinationsForCandidateInput;
};


export type QueryGetRequiredVaccinationsConfigurationArgs = {
  input: RequiredVaccinationsGetInput;
};


export type QueryGetSalesDataArgs = {
  input: ExcelAddInSalesDataInput;
};


export type QueryGetSelectableCollectionsArgs = {
  input: CollectionSharingByOrgAndTypeInput;
};


export type QueryGetSelectableEducationModulesArgs = {
  input: EducationModuleListInput;
};


export type QueryGetSelectableOrganizationsArgs = {
  id: Scalars['ID']['input'];
  sharingTypes?: InputMaybe<Array<SharingTypeEnum>>;
};


export type QueryGetSentMessageArgs = {
  candidateId: Scalars['String']['input'];
  messageId: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
};


export type QueryGetSettingsForCandidateOrganizationArgs = {
  input: SystemSettingsGetInput;
};


export type QueryGetSharedCollectionEventsArgs = {
  input: SharedCollectionEventsInput;
};


export type QueryGetSharingArgs = {
  input: SharingQueryInput;
};


export type QueryGetSharingActivitiesArgs = {
  input: SharingActivityInput;
};


export type QueryGetSharingDocumentsArgs = {
  input: DocumentSharingInput;
};


export type QueryGetSharingPresetArgs = {
  input: SharingPresetGetInput;
};


export type QueryGetSharingPresetsArgs = {
  input: SharingPresetListInput;
};


export type QueryGetSharingStateArgs = {
  input: SharingStateInput;
};


export type QueryGetSharingsForCollectionArgs = {
  input: SharingCollectionQueryInput;
};


export type QueryGetSharingsForOrganizationArgs = {
  input: SharingEnumerationInput;
};


export type QueryGetStateArgs = {
  orgId: Scalars['String']['input'];
  taskId: Scalars['String']['input'];
};


export type QueryGetStaticDataArgs = {
  input: StaticDataModelInput;
};


export type QueryGetStoredProcessModelsArgs = {
  orgId: Scalars['String']['input'];
};


export type QueryGetSwitchableOrganizationsArgs = {
  search: Scalars['String']['input'];
};


export type QueryGetSystemSettingArgs = {
  input: SystemSettingsGetInput;
};


export type QueryGetTalentPoolArgs = {
  input: TalentPoolGetInput;
};


export type QueryGetTalentPoolConfigArgs = {
  input: TalentPoolConfigurationInput;
};


export type QueryGetTalentPoolCvArgs = {
  input: TalentPoolCvInput;
};


export type QueryGetTalentPoolFindOccupationSkillsArgs = {
  input: PublicTalentPoolOccupationSkillByIdsInputInput;
};


export type QueryGetTalentPoolImageAccessTokenArgs = {
  input: ImageAccessInput;
};


export type QueryGetTalentPoolOrganizationArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetTalentPoolParticipantArgs = {
  input: TalentPoolParticipantGetInput;
};


export type QueryGetTalentPoolParticipantsArgs = {
  input: TalentPoolParticipantListInput;
};


export type QueryGetTalentPoolSharingArgs = {
  input: TalentPoolSharingGetInput;
};


export type QueryGetTalentPoolSharingsArgs = {
  input: TalentPoolSharingListInput;
};


export type QueryGetTalentPoolStaticDataArgs = {
  input: StaticDataModelInput;
};


export type QueryGetTalentPoolsArgs = {
  input: TalentPoolListInput;
};


export type QueryGetTalentsPublicArgs = {
  input: PublicTalentPoolSearchInput;
};


export type QueryGetTalentsRestrictedArgs = {
  input: RestrictedTalentPoolSearchInput;
};


export type QueryGetTaskArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
  orgId: Scalars['String']['input'];
  taskId: Scalars['String']['input'];
};


export type QueryGetTaskActivitiesArgs = {
  input: TaskActivityInput;
};


export type QueryGetTaskArchiveArgs = {
  input: TaskArchiveInput;
};


export type QueryGetTaskCopyDataArgs = {
  input: ProcessTaskCopyDataInput;
};


export type QueryGetTaskForActArgs = {
  language: Scalars['String']['input'];
  orgId: Scalars['String']['input'];
  taskId: Scalars['String']['input'];
};


export type QueryGetTaskModelArgs = {
  input: ProcessTaskModelGetInput;
};


export type QueryGetTaskReferencedByArgs = {
  input: GetTaskReferenceByInput;
};


export type QueryGetTaskReminderStateArgs = {
  orgId: Scalars['String']['input'];
  taskId: Scalars['String']['input'];
};


export type QueryGetUserInboxArgs = {
  input: TaskInboxInput;
};


export type QueryGetVisibleTasksArgs = {
  input: ProcessVisibleTaskListInput;
};


export type QueryGetWritableDocumentTypesArgs = {
  input: CandidateDocumentListInput;
};


export type QueryGetWritableTalentPoolsArgs = {
  input: TalentPoolListInput;
};


export type QueryGetXlsxProcessTranslationsUploadUrlArgs = {
  input: XlsxProcessTranslationUploadUrlInput;
};


export type QueryGetXlsxTemplateForCandidatesArgs = {
  input: XlsxTemplateForCandidatesGetInput;
};


export type QueryGetXlsxTemplateForContractTemplatesArgs = {
  input: XlsxTemplateForContractTemplatesGetInput;
};


export type QueryGetXlsxTemplateForOrganizationCrmDataArgs = {
  input: XlsxTemplateForOrganizationCrmDataGetInput;
};


export type QueryGetXlsxTemplateForOrganizationContactSettingsArgs = {
  input: XlsxTemplateForOrganizationContactSettingsGetInput;
};


export type QueryGetXlsxTemplateForOrganizationContactsArgs = {
  input: XlsxTemplateForOrganizationContactsGetInput;
};


export type QueryGetXlsxTemplateForOrganizationUserGroupsArgs = {
  input: XlsxTemplateForOrganizationUserGroupsInput;
};


export type QueryGetXlsxTemplateForOrganizationUserMembershipsArgs = {
  input: XlsxTemplateForOrganizationUserMembershipsInput;
};


export type QueryGetXlsxTemplateForOrganizationUsersArgs = {
  input: XlsxTemplateForOrganizationUsersInput;
};


export type QueryGetXlsxTemplateForOrganizationsArgs = {
  input: XlsxTemplateForOrganizationsGetInput;
};


export type QueryGetXlsxTemplateForProcessTranslationsArgs = {
  input: XlsxProcessTranslationGetInput;
};


export type QueryGetXlsxTemplateForProcessesArgs = {
  input: XlsxTemplateForProcessesGetInput;
};


export type QueryIsCandidateFavoriteArgs = {
  input: FavoriteCandidateInput;
};


export type QueryIsCollectionFavoriteArgs = {
  input: FavoriteCollectionInput;
};


export type QueryIsMatchingEnabledArgs = {
  input: IsMatchingEnabledInput;
};


export type QueryIsOrganizationLicensedArgs = {
  organizationId: Scalars['ID']['input'];
};


export type QueryLastLoginDateArgs = {
  input: LastUserLoginInput;
};


export type QueryOrganizationArgs = {
  id: Scalars['ID']['input'];
};


export type QueryPrepareNewTaskArgs = {
  input: PrepareNewTaskInput;
  language?: InputMaybe<Scalars['String']['input']>;
};


export type QueryPreviewCandidateMultiEditArgs = {
  input: CandidateMultiEditPreviewInput;
};


export type QueryPreviewCandidateMultiInviteArgs = {
  input: CandidateMultiInvitePreviewInput;
};


export type QueryPreviewCandidateMultiUninviteArgs = {
  input: CandidateMultiInvitePreviewInput;
};


export type QueryPreviewEnterMissingInformationArgs = {
  input: EnterMissingInformationPreviewInput;
};


export type QueryPreviewEnterMissingInformationV2Args = {
  input: EnterMissingInformationV2PreviewInput;
};


export type QueryRenderEmailTemplateArgs = {
  input: EmailTemplateRenderInput;
};


export type QueryRequestTranslationArgs = {
  input: MeteredTranslationInput;
};


export type QueryResolveShortLinkArgs = {
  shortLink: Scalars['String']['input'];
};


export type QuerySearchOccupationSkillsArgs = {
  input: OccupationSkillSearchInput;
};


export type QuerySettingsArgs = {
  firstLoadInSession: Scalars['Boolean']['input'];
};


export type QueryUserArgs = {
  id: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};


export type QueryUserGroupArgs = {
  id: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};


export type QueryUserGroupsArgs = {
  organizationId: Scalars['ID']['input'];
};


export type QueryUsersArgs = {
  organizationId: Scalars['ID']['input'];
};


export type QueryUsersWithRolesArgs = {
  input: OrganizationRolesInput;
};


export type QueryValidateContractArgs = {
  input: ContractCreateInput;
};


export type QueryValidateDocumentsArgs = {
  input: DocumentValidationInput;
};


export type QueryValidateProcessModelArgs = {
  input: ProcessModelValidationInput;
};

export class ReCertificationProcessCreationResult {
  __typename?: 'ReCertificationProcessCreationResult';
  orgMismatch?: Maybe<Scalars['Boolean']['output']>;
  organizationId?: Maybe<Scalars['ID']['output']>;
  processId?: Maybe<Scalars['ID']['output']>;
  switchOrg?: Maybe<Scalars['Boolean']['output']>;
  taskId?: Maybe<Scalars['ID']['output']>;
  taskLink?: Maybe<Scalars['String']['output']>;
  userAlreadyExisted?: Maybe<Scalars['Boolean']['output']>;
};

export class RecognitionAuthority {
  __typename?: 'RecognitionAuthority';
  id: Scalars['Float']['output'];
  locationId: Scalars['Float']['output'];
  reglementation: Scalars['Boolean']['output'];
  respCity?: Maybe<Scalars['String']['output']>;
  respCommentBottom?: Maybe<Scalars['String']['output']>;
  respCommentTop?: Maybe<Scalars['String']['output']>;
  respContacts?: Maybe<Array<RecognitionAuthorityContactPerson>>;
  respEmail?: Maybe<Scalars['String']['output']>;
  respHomepage?: Maybe<Scalars['String']['output']>;
  respId: Scalars['Float']['output'];
  respPhone?: Maybe<Scalars['String']['output']>;
  respProfComment?: Maybe<Scalars['String']['output']>;
  respState?: Maybe<Scalars['String']['output']>;
  respStreet?: Maybe<Scalars['String']['output']>;
  respTitle?: Maybe<Scalars['String']['output']>;
  respZip?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  stateAbbr?: Maybe<Scalars['String']['output']>;
  stateReglementation?: Maybe<Scalars['String']['output']>;
  stateTitle?: Maybe<Scalars['String']['output']>;
  stateTitleAdditional?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  titleAdditional?: Maybe<Scalars['String']['output']>;
};

export class RecognitionAuthorityContactPerson {
  __typename?: 'RecognitionAuthorityContactPerson';
  email?: Maybe<Scalars['String']['output']>;
  fax?: Maybe<Scalars['String']['output']>;
  info?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  prof: Scalars['Float']['output'];
  salutation?: Maybe<Scalars['String']['output']>;
};

export class RecognitionAuthorityInput {
  federalStates?: InputMaybe<Array<Scalars['String']['input']>>;
  immigrationCountry: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
};

export class RecognitionPathCandidateDataDe {
  __typename?: 'RecognitionPathCandidateDataDE';
  alternative?: Maybe<RecognitionPathEntryDe>;
  current?: Maybe<RecognitionPathEntryDe>;
};

export class RecognitionPathCandidateDataDeForView {
  __typename?: 'RecognitionPathCandidateDataDEForView';
  alternative?: Maybe<RecognitionPathEntryDeForView>;
  current?: Maybe<RecognitionPathEntryDeForView>;
};

export class RecognitionPathCandidateDataDeInput {
  alternative?: InputMaybe<RecognitionPathEntryDeInput>;
  current?: InputMaybe<RecognitionPathEntryDeInput>;
};

export class RecognitionPathContractTemplate {
  __typename?: 'RecognitionPathContractTemplate';
  allowances?: Maybe<Array<ContractTemplateAllowance>>;
  benefits?: Maybe<Array<ContractTemplateBenefit>>;
  compensationRate?: Maybe<Scalars['Float']['output']>;
  compensationType?: Maybe<CompensationType>;
  contractType?: Maybe<Scalars['String']['output']>;
  customLaborAgreement?: Maybe<CustomLaborAgreement>;
  employmentRelationshipType?: Maybe<EmploymentRelationshipType>;
  ezbAdditionalInformation?: Maybe<Scalars['String']['output']>;
  holidayEntitlement?: Maybe<Scalars['Float']['output']>;
  laborAgreementSelection?: Maybe<LaborAgreementSelection>;
  noticePeriod?: Maybe<Scalars['Float']['output']>;
  noticePeriodUnit?: Maybe<ContractTermTimePeriod>;
  numberOfSalaries?: Maybe<Scalars['Float']['output']>;
  otherPaymentRegulation?: Maybe<Scalars['String']['output']>;
  overtimeCompensation?: Maybe<Scalars['String']['output']>;
  overtimeHours?: Maybe<Scalars['Float']['output']>;
  overtimeWithinLimitsPermittedByLaw?: Maybe<Scalars['Boolean']['output']>;
  probationPeriod?: Maybe<Scalars['Float']['output']>;
  probationPeriodUnit?: Maybe<ContractTermTimePeriod>;
  professionDescription?: Maybe<Scalars['String']['output']>;
  professionTitle?: Maybe<Scalars['String']['output']>;
  validFrom?: Maybe<Scalars['DateTime']['output']>;
  validUntil?: Maybe<Scalars['DateTime']['output']>;
  workSchedule?: Maybe<Scalars['String']['output']>;
  workingHoursPerWeek?: Maybe<Scalars['Float']['output']>;
  workingTimeType?: Maybe<WorkingTimeTypeEnum>;
};

export class RecognitionPathContractTemplateInput {
  allowances?: InputMaybe<Array<ContractTemplateAllowanceInput>>;
  benefits?: InputMaybe<Array<ContractTemplateBenefitInput>>;
  compensationRate?: InputMaybe<Scalars['Float']['input']>;
  compensationType?: InputMaybe<CompensationType>;
  contractType?: InputMaybe<Scalars['String']['input']>;
  customLaborAgreement?: InputMaybe<CustomLaborAgreementInput>;
  employmentRelationshipType?: InputMaybe<EmploymentRelationshipType>;
  ezbAdditionalInformation?: InputMaybe<Scalars['String']['input']>;
  holidayEntitlement?: InputMaybe<Scalars['Float']['input']>;
  laborAgreementSelection?: InputMaybe<LaborAgreementSelectionInput>;
  noticePeriod?: InputMaybe<Scalars['Float']['input']>;
  noticePeriodUnit?: InputMaybe<ContractTermTimePeriod>;
  numberOfSalaries?: InputMaybe<Scalars['Float']['input']>;
  otherPaymentRegulation?: InputMaybe<Scalars['String']['input']>;
  overtimeCompensation?: InputMaybe<Scalars['String']['input']>;
  overtimeHours?: InputMaybe<Scalars['Float']['input']>;
  overtimeWithinLimitsPermittedByLaw?: InputMaybe<Scalars['Boolean']['input']>;
  probationPeriod?: InputMaybe<Scalars['Float']['input']>;
  probationPeriodUnit?: InputMaybe<ContractTermTimePeriod>;
  professionDescription?: InputMaybe<Scalars['String']['input']>;
  professionTitle?: InputMaybe<Scalars['String']['input']>;
  validFrom?: InputMaybe<Scalars['DateTime']['input']>;
  validUntil?: InputMaybe<Scalars['DateTime']['input']>;
  workSchedule?: InputMaybe<Scalars['String']['input']>;
  workingHoursPerWeek?: InputMaybe<Scalars['Float']['input']>;
  workingTimeType?: InputMaybe<WorkingTimeTypeEnum>;
};

export class RecognitionPathEntryAt {
  __typename?: 'RecognitionPathEntryAT';
  federalState?: Maybe<Scalars['String']['output']>;
  fileNumber?: Maybe<Scalars['String']['output']>;
  legalBasis?: Maybe<Scalars['String']['output']>;
  pathOfRecognition?: Maybe<Scalars['String']['output']>;
  psaFirstDeputyFirstName?: Maybe<Scalars['String']['output']>;
  psaFirstDeputyId?: Maybe<Scalars['ID']['output']>;
  psaFirstDeputyLastName?: Maybe<Scalars['String']['output']>;
  psaRepresentativeFirstName?: Maybe<Scalars['String']['output']>;
  psaRepresentativeId?: Maybe<Scalars['ID']['output']>;
  psaRepresentativeLastName?: Maybe<Scalars['String']['output']>;
  recognitionAuthority?: Maybe<Scalars['String']['output']>;
  recognitionReceiveDate?: Maybe<Scalars['DateTime']['output']>;
  recognitionReceived?: Maybe<Scalars['Boolean']['output']>;
  recognitionStartDate?: Maybe<Scalars['DateTime']['output']>;
  recognitionStarted?: Maybe<Scalars['Boolean']['output']>;
  responsibleRecognitionOrganizationId?: Maybe<Scalars['ID']['output']>;
  responsibleRoleRecognition?: Maybe<Scalars['String']['output']>;
  targetRecognition?: Maybe<Scalars['String']['output']>;
};

export class RecognitionPathEntryAtInput {
  federalState?: InputMaybe<Scalars['String']['input']>;
  fileNumber?: InputMaybe<Scalars['String']['input']>;
  legalBasis?: InputMaybe<Scalars['String']['input']>;
  pathOfRecognition?: InputMaybe<Scalars['String']['input']>;
  psaFirstDeputyFirstName?: InputMaybe<Scalars['String']['input']>;
  psaFirstDeputyId?: InputMaybe<Scalars['ID']['input']>;
  psaFirstDeputyLastName?: InputMaybe<Scalars['String']['input']>;
  psaRepresentativeFirstName?: InputMaybe<Scalars['String']['input']>;
  psaRepresentativeId?: InputMaybe<Scalars['ID']['input']>;
  psaRepresentativeLastName?: InputMaybe<Scalars['String']['input']>;
  recognitionAuthority?: InputMaybe<Scalars['String']['input']>;
  recognitionReceiveDate?: InputMaybe<Scalars['DateTime']['input']>;
  recognitionReceived?: InputMaybe<Scalars['Boolean']['input']>;
  recognitionStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  recognitionStarted?: InputMaybe<Scalars['Boolean']['input']>;
  responsibleRecognitionOrganizationId?: InputMaybe<Scalars['ID']['input']>;
  responsibleRoleRecognition?: InputMaybe<Scalars['String']['input']>;
  targetRecognition?: InputMaybe<Scalars['String']['input']>;
};

export class RecognitionPathEntryDe {
  __typename?: 'RecognitionPathEntryDE';
  certificateRequestDate?: Maybe<Scalars['DateTime']['output']>;
  certificateRequested?: Maybe<Scalars['Boolean']['output']>;
  chamberOfCrafts?: Maybe<Scalars['String']['output']>;
  equivalenceTest?: Maybe<Scalars['String']['output']>;
  federalState?: Maybe<Scalars['String']['output']>;
  fileNumber?: Maybe<Scalars['String']['output']>;
  fileNumberAcceleratedProcedure?: Maybe<Scalars['String']['output']>;
  immigrationAuthority?: Maybe<Scalars['String']['output']>;
  isCurrent?: Maybe<Scalars['Boolean']['output']>;
  pathOfRecognition?: Maybe<Scalars['String']['output']>;
  psaFirstDeputyFirstName?: Maybe<Scalars['String']['output']>;
  psaFirstDeputyId?: Maybe<Scalars['ID']['output']>;
  psaFirstDeputyLastName?: Maybe<Scalars['String']['output']>;
  psaRepresentativeFirstName?: Maybe<Scalars['String']['output']>;
  psaRepresentativeId?: Maybe<Scalars['ID']['output']>;
  psaRepresentativeLastName?: Maybe<Scalars['String']['output']>;
  recognitionAuthority?: Maybe<Scalars['String']['output']>;
  recognitionCenter?: Maybe<Scalars['String']['output']>;
  recognitionNoticeKind?: Maybe<Scalars['String']['output']>;
  recognitionPartnership?: Maybe<Scalars['Boolean']['output']>;
  recognitionPaymentRole?: Maybe<Scalars['String']['output']>;
  recognitionPaymentRoleAccelerated?: Maybe<Scalars['String']['output']>;
  recognitionStartDate?: Maybe<Scalars['DateTime']['output']>;
  recognitionStarted?: Maybe<Scalars['Boolean']['output']>;
  recognitionType?: Maybe<Scalars['String']['output']>;
  responsibleRecognitionOrganizationId?: Maybe<Scalars['ID']['output']>;
  responsibleRoleRecognition?: Maybe<Scalars['String']['output']>;
};

export class RecognitionPathEntryDeForView {
  __typename?: 'RecognitionPathEntryDEForView';
  certificateRequestDate?: Maybe<Scalars['DateTime']['output']>;
  certificateRequested?: Maybe<Scalars['Boolean']['output']>;
  chamberOfCrafts?: Maybe<Scalars['String']['output']>;
  equivalenceTest?: Maybe<Scalars['String']['output']>;
  federalState?: Maybe<Scalars['String']['output']>;
  fileNumber?: Maybe<Scalars['String']['output']>;
  fileNumberAcceleratedProcedure?: Maybe<Scalars['String']['output']>;
  immigrationAuthority?: Maybe<Scalars['String']['output']>;
  isCurrent?: Maybe<Scalars['Boolean']['output']>;
  pathOfRecognition?: Maybe<Scalars['String']['output']>;
  psaFirstDeputyFirstName?: Maybe<Scalars['String']['output']>;
  psaFirstDeputyId?: Maybe<Scalars['ID']['output']>;
  psaFirstDeputyLastName?: Maybe<Scalars['String']['output']>;
  psaRepresentativeFirstName?: Maybe<Scalars['String']['output']>;
  psaRepresentativeId?: Maybe<Scalars['ID']['output']>;
  psaRepresentativeLastName?: Maybe<Scalars['String']['output']>;
  recognitionAuthority?: Maybe<Scalars['String']['output']>;
  recognitionCenter?: Maybe<Scalars['String']['output']>;
  recognitionNoticeKind?: Maybe<Scalars['String']['output']>;
  recognitionPartnership?: Maybe<Scalars['Boolean']['output']>;
  recognitionPaymentRole?: Maybe<Scalars['String']['output']>;
  recognitionPaymentRoleAccelerated?: Maybe<Scalars['String']['output']>;
  recognitionReceiveDate?: Maybe<Scalars['DateTime']['output']>;
  recognitionReceived?: Maybe<Scalars['Boolean']['output']>;
  recognitionStartDate?: Maybe<Scalars['DateTime']['output']>;
  recognitionStarted?: Maybe<Scalars['Boolean']['output']>;
  recognitionType?: Maybe<Scalars['String']['output']>;
  responsibleRecognitionOrganizationId?: Maybe<Scalars['ID']['output']>;
  responsibleRoleRecognition?: Maybe<Scalars['String']['output']>;
};

export class RecognitionPathEntryDeInput {
  certificateRequestDate?: InputMaybe<Scalars['DateTime']['input']>;
  certificateRequested?: InputMaybe<Scalars['Boolean']['input']>;
  chamberOfCrafts?: InputMaybe<Scalars['String']['input']>;
  equivalenceTest?: InputMaybe<Scalars['String']['input']>;
  federalState?: InputMaybe<Scalars['String']['input']>;
  fileNumber?: InputMaybe<Scalars['String']['input']>;
  fileNumberAcceleratedProcedure?: InputMaybe<Scalars['String']['input']>;
  immigrationAuthority?: InputMaybe<Scalars['String']['input']>;
  isCurrent?: InputMaybe<Scalars['Boolean']['input']>;
  pathOfRecognition?: InputMaybe<Scalars['String']['input']>;
  psaFirstDeputyFirstName?: InputMaybe<Scalars['String']['input']>;
  psaFirstDeputyId?: InputMaybe<Scalars['ID']['input']>;
  psaFirstDeputyLastName?: InputMaybe<Scalars['String']['input']>;
  psaRepresentativeFirstName?: InputMaybe<Scalars['String']['input']>;
  psaRepresentativeId?: InputMaybe<Scalars['ID']['input']>;
  psaRepresentativeLastName?: InputMaybe<Scalars['String']['input']>;
  recognitionAuthority?: InputMaybe<Scalars['String']['input']>;
  recognitionCenter?: InputMaybe<Scalars['String']['input']>;
  recognitionNoticeKind?: InputMaybe<Scalars['String']['input']>;
  recognitionPartnership?: InputMaybe<Scalars['Boolean']['input']>;
  recognitionPaymentRole?: InputMaybe<Scalars['String']['input']>;
  recognitionPaymentRoleAccelerated?: InputMaybe<Scalars['String']['input']>;
  recognitionStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  recognitionStarted?: InputMaybe<Scalars['Boolean']['input']>;
  recognitionType?: InputMaybe<Scalars['String']['input']>;
  responsibleRecognitionOrganizationId?: InputMaybe<Scalars['ID']['input']>;
  responsibleRoleRecognition?: InputMaybe<Scalars['String']['input']>;
};

export enum RecognitionProfessionalExperience {
  FullRecognition = 'FullRecognition',
  NoRecognition = 'NoRecognition',
  PartialRecognition = 'PartialRecognition'
}

export enum RecognitionState {
  DeficitNotice = 'DeficitNotice',
  FullNotice = 'FullNotice',
  NoNoticeReceived = 'NoNoticeReceived',
  RecognitionNoticeReceived = 'RecognitionNoticeReceived',
  RecognitionStarted = 'RecognitionStarted',
  RejectNotice = 'RejectNotice'
}

export class RecommendationNote {
  __typename?: 'RecommendationNote';
  contents: Scalars['String']['output'];
  delta: Scalars['JSONObject']['output'];
};

export class RecommendationNoteInput {
  contents: Scalars['String']['input'];
  delta: Scalars['JSONObject']['input'];
};

export class ReleasedProcessModelInfo {
  __typename?: 'ReleasedProcessModelInfo';
  _etag?: Maybe<Scalars['String']['output']>;
  _typeName: Scalars['String']['output'];
  comment?: Maybe<Scalars['String']['output']>;
  directives?: Maybe<Array<ProcessModelDirective>>;
  id?: Maybe<Scalars['ID']['output']>;
  importModel?: Maybe<ProcessModelDeclaration>;
  modelId: Scalars['String']['output'];
  organizationId: Scalars['ID']['output'];
  releasedAt: Scalars['DateTime']['output'];
  releasedBy: Scalars['String']['output'];
  revision: Scalars['Float']['output'];
  version?: Maybe<Scalars['String']['output']>;
};

export class RemoveForeignGroupsFromGroupInput {
  _etag: Scalars['String']['input'];
  groupId: Scalars['ID']['input'];
  groupsIds: Array<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
};

export enum RemoveFromListBehaviorEnum {
  All = 'All',
  AllWithSharing = 'AllWithSharing',
  CollectionOrTemplate = 'CollectionOrTemplate',
  Current = 'Current',
  SharedToOtherEmployers = 'SharedToOtherEmployers'
}

export class RemoveUsersFromGroupInput {
  _etag: Scalars['String']['input'];
  groupId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
  userIds: Array<Scalars['ID']['input']>;
};

export enum ReplyTypeEnum {
  ConfirmAgreement = 'ConfirmAgreement',
  ConfirmReception = 'ConfirmReception',
  NoReply = 'NoReply',
  ReplyWithDate = 'ReplyWithDate',
  ReplyWithText = 'ReplyWithText',
  ReplyWithYesOrNo = 'ReplyWithYesOrNo',
  ReplyWithYesOrNoAndForcedCommentAtNo = 'ReplyWithYesOrNoAndForcedCommentAtNo'
}

export class ReportInput {
  language: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
};

export class ReportResult {
  __typename?: 'ReportResult';
  filename: Scalars['String']['output'];
  urlToReport?: Maybe<Scalars['String']['output']>;
};

export class ReportTemplate {
  __typename?: 'ReportTemplate';
  description?: Maybe<Scalars['String']['output']>;
  displayName: Scalars['String']['output'];
  generationDataStrategyId: Scalars['String']['output'];
  templateId: Scalars['ID']['output'];
  templateOrganizationId: Scalars['ID']['output'];
};

export class RequiredDocument {
  __typename?: 'RequiredDocument';
  _etag?: Maybe<Scalars['String']['output']>;
  changedAt: Scalars['DateTime']['output'];
  changedBy: Scalars['String']['output'];
  configData?: Maybe<Array<DocumentConfigurationResultType>>;
  description?: Maybe<Scalars['String']['output']>;
  functions: Array<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  immigrationCountry: SupportedImmigrationCountry;
  name: Scalars['String']['output'];
  organizationId: Scalars['ID']['output'];
  setsAvailableForCandidates?: Maybe<Scalars['Boolean']['output']>;
};

export class RequiredDocumentCreateInput {
  configData?: InputMaybe<Array<DocumentConfigurationInputType>>;
  description?: InputMaybe<Scalars['String']['input']>;
  functions: Array<Scalars['String']['input']>;
  immigrationCountry: SupportedImmigrationCountry;
  name: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  setsAvailableForCandidates?: InputMaybe<Scalars['Boolean']['input']>;
};

export class RequiredDocumentDeleteInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
};

export class RequiredDocumentDuplicateInput {
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
};

export class RequiredDocumentGetInput {
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
};

export class RequiredDocumentOverview {
  __typename?: 'RequiredDocumentOverview';
  candidateData: Array<CandidateData>;
  documentStatus: Array<DocumentStatus>;
};

export class RequiredDocumentUpdateInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  configData?: InputMaybe<Array<DocumentConfigurationInputType>>;
  description?: InputMaybe<Scalars['String']['input']>;
  functions: Array<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  immigrationCountry: SupportedImmigrationCountry;
  name: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  setsAvailableForCandidates?: InputMaybe<Scalars['Boolean']['input']>;
};

export class RequiredDocumentUpdateMetaInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  functions: Array<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  setsAvailableForCandidates?: InputMaybe<Scalars['Boolean']['input']>;
};

export class RequiredDocumentsForCandidateInput {
  candidateId: Scalars['ID']['input'];
  candidateOrganizationId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};

export class RequiredDocumentsForOrganizationInput {
  organizationId: Scalars['ID']['input'];
};

export class RequiredVaccinations {
  __typename?: 'RequiredVaccinations';
  _etag?: Maybe<Scalars['String']['output']>;
  at?: Maybe<RequiredVaccinationsAt>;
  changedAt: Scalars['DateTime']['output'];
  changedBy: Scalars['String']['output'];
  de?: Maybe<RequiredVaccinationsDe>;
  id?: Maybe<Scalars['ID']['output']>;
  organizationId: Scalars['ID']['output'];
};

export class RequiredVaccinationsAt {
  __typename?: 'RequiredVaccinationsAT';
  configs: Array<RequiredVaccinationsEntryAt>;
};

export class RequiredVaccinationsAtInput {
  configs: Array<RequiredVaccinationsEntryAtInput>;
};

export class RequiredVaccinationsDe {
  __typename?: 'RequiredVaccinationsDE';
  configs: Array<RequiredVaccinationsEntryDe>;
};

export class RequiredVaccinationsDeInput {
  configs: Array<RequiredVaccinationsEntryDeInput>;
};

export class RequiredVaccinationsEntryAt {
  __typename?: 'RequiredVaccinationsEntryAT';
  countryOfOrigin: Array<Scalars['String']['output']>;
  employer: Array<Scalars['String']['output']>;
  federalState: Array<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  mandatoryVaccines: Scalars['Boolean']['output'];
  notNecessary?: Maybe<Scalars['Boolean']['output']>;
  profession: Array<Scalars['String']['output']>;
  vaccineFunction: Array<Scalars['String']['output']>;
  vaccines: Array<Scalars['String']['output']>;
};

export class RequiredVaccinationsEntryAtInput {
  countryOfOrigin: Array<Scalars['String']['input']>;
  employer: Array<Scalars['String']['input']>;
  federalState: Array<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  mandatoryVaccines: Scalars['Boolean']['input'];
  notNecessary?: InputMaybe<Scalars['Boolean']['input']>;
  profession: Array<Scalars['String']['input']>;
  vaccineFunction: Array<Scalars['String']['input']>;
  vaccines: Array<Scalars['String']['input']>;
};

export class RequiredVaccinationsEntryDe {
  __typename?: 'RequiredVaccinationsEntryDE';
  countryOfOrigin: Array<Scalars['String']['output']>;
  employer: Array<Scalars['String']['output']>;
  federalState: Array<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  mandatoryVaccines: Scalars['Boolean']['output'];
  notNecessary?: Maybe<Scalars['Boolean']['output']>;
  profession: Array<Scalars['String']['output']>;
  vaccineFunction: Array<Scalars['String']['output']>;
  vaccines: Array<Scalars['String']['output']>;
};

export class RequiredVaccinationsEntryDeInput {
  countryOfOrigin: Array<Scalars['String']['input']>;
  employer: Array<Scalars['String']['input']>;
  federalState: Array<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  mandatoryVaccines: Scalars['Boolean']['input'];
  notNecessary?: InputMaybe<Scalars['Boolean']['input']>;
  profession: Array<Scalars['String']['input']>;
  vaccineFunction: Array<Scalars['String']['input']>;
  vaccines: Array<Scalars['String']['input']>;
};

export class RequiredVaccinationsForCandidateInput {
  candidateId: Scalars['String']['input'];
  candidateOrganizationId: Scalars['String']['input'];
  overviewOrganizationId: Scalars['String']['input'];
};

export class RequiredVaccinationsGetInput {
  organizationId: Scalars['ID']['input'];
};

export class RequiredVaccinationsResultEntry {
  __typename?: 'RequiredVaccinationsResultEntry';
  mandatoryVaccines: Scalars['Boolean']['output'];
  vaccineFunction: Scalars['String']['output'];
  vaccines: Array<Scalars['String']['output']>;
};

export class RequiredVaccinationsStatesForCollectionInput {
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
};

export class RequiredVaccinationsUpdateInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  at?: InputMaybe<RequiredVaccinationsAtInput>;
  de?: InputMaybe<RequiredVaccinationsDeInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
};

export class ResidenceCandidateDataAt {
  __typename?: 'ResidenceCandidateDataAT';
  personalOnboardingDateTime?: Maybe<DateWithTime>;
  recognitionMedicalCertificate?: Maybe<Scalars['Boolean']['output']>;
  registrationAuthority?: Maybe<Scalars['String']['output']>;
  registrationAuthorityAppointmentNumber?: Maybe<Scalars['String']['output']>;
  registrationOfficeDate?: Maybe<DateWithTime>;
  residenceBank?: Maybe<Scalars['String']['output']>;
  residenceBankContactPartner?: Maybe<Scalars['String']['output']>;
  residenceFirstDeputyFirstName?: Maybe<Scalars['String']['output']>;
  residenceFirstDeputyId?: Maybe<Scalars['ID']['output']>;
  residenceFirstDeputyLastName?: Maybe<Scalars['String']['output']>;
  residenceRepresentativeFirstName?: Maybe<Scalars['String']['output']>;
  residenceRepresentativeId?: Maybe<Scalars['ID']['output']>;
  residenceRepresentativeLastName?: Maybe<Scalars['String']['output']>;
  responsibleResidenceOrganizationId?: Maybe<Scalars['ID']['output']>;
  responsibleRoleResidence?: Maybe<Scalars['String']['output']>;
  suitabilityForWork?: Maybe<Scalars['String']['output']>;
  workStartDate?: Maybe<Scalars['DateTime']['output']>;
};

export class ResidenceCandidateDataAtInput {
  personalOnboardingDateTime?: InputMaybe<DateWithTimeInput>;
  recognitionMedicalCertificate?: InputMaybe<Scalars['Boolean']['input']>;
  registrationAuthority?: InputMaybe<Scalars['String']['input']>;
  registrationAuthorityAppointmentNumber?: InputMaybe<Scalars['String']['input']>;
  registrationOfficeDate?: InputMaybe<DateWithTimeInput>;
  residenceBank?: InputMaybe<Scalars['String']['input']>;
  residenceBankContactPartner?: InputMaybe<Scalars['String']['input']>;
  residenceFirstDeputyFirstName?: InputMaybe<Scalars['String']['input']>;
  residenceFirstDeputyId?: InputMaybe<Scalars['ID']['input']>;
  residenceFirstDeputyLastName?: InputMaybe<Scalars['String']['input']>;
  residenceRepresentativeFirstName?: InputMaybe<Scalars['String']['input']>;
  residenceRepresentativeId?: InputMaybe<Scalars['ID']['input']>;
  residenceRepresentativeLastName?: InputMaybe<Scalars['String']['input']>;
  responsibleResidenceOrganizationId?: InputMaybe<Scalars['ID']['input']>;
  responsibleRoleResidence?: InputMaybe<Scalars['String']['input']>;
  suitabilityForWork?: InputMaybe<Scalars['String']['input']>;
  workStartDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export class ResidenceCandidateDataDe {
  __typename?: 'ResidenceCandidateDataDE';
  appointmentHealthDepartmentDate?: Maybe<DateWithTime>;
  appointmentHealthDepartmentKept?: Maybe<Scalars['Boolean']['output']>;
  bankAppointmentDate?: Maybe<DateWithTime>;
  certificateOfConduct?: Maybe<Array<Scalars['String']['output']>>;
  certificateOfConductRequested?: Maybe<Scalars['Boolean']['output']>;
  companyDoctorAppointmentDate?: Maybe<DateWithTime>;
  medicalExaminationAppointmentDate?: Maybe<DateWithTime>;
  personalOnboardingDateTime?: Maybe<DateWithTime>;
  policeRecordAppointmentDate?: Maybe<DateWithTime>;
  recognitionMedicalCertificate?: Maybe<Scalars['Boolean']['output']>;
  registrationAuthority?: Maybe<Scalars['String']['output']>;
  registrationAuthorityAppointmentNumber?: Maybe<Scalars['String']['output']>;
  registrationOfficeDate?: Maybe<DateWithTime>;
  residenceBank?: Maybe<Scalars['String']['output']>;
  residenceBankContactPartner?: Maybe<Scalars['String']['output']>;
  residenceFirstDeputyFirstName?: Maybe<Scalars['String']['output']>;
  residenceFirstDeputyId?: Maybe<Scalars['ID']['output']>;
  residenceFirstDeputyLastName?: Maybe<Scalars['String']['output']>;
  residencePermits?: Maybe<Array<ResidencePermit>>;
  residenceRepresentativeFirstName?: Maybe<Scalars['String']['output']>;
  residenceRepresentativeId?: Maybe<Scalars['ID']['output']>;
  residenceRepresentativeLastName?: Maybe<Scalars['String']['output']>;
  responsibleResidenceOrganizationId?: Maybe<Scalars['ID']['output']>;
  responsibleRoleResidence?: Maybe<Scalars['String']['output']>;
  suitabilityForWork?: Maybe<Scalars['String']['output']>;
  workStartDate?: Maybe<Scalars['DateTime']['output']>;
};

export class ResidenceCandidateDataDeInput {
  appointmentHealthDepartmentDate?: InputMaybe<DateWithTimeInput>;
  appointmentHealthDepartmentKept?: InputMaybe<Scalars['Boolean']['input']>;
  bankAppointmentDate?: InputMaybe<DateWithTimeInput>;
  certificateOfConduct?: InputMaybe<Array<Scalars['String']['input']>>;
  certificateOfConductRequested?: InputMaybe<Scalars['Boolean']['input']>;
  companyDoctorAppointmentDate?: InputMaybe<DateWithTimeInput>;
  medicalExaminationAppointmentDate?: InputMaybe<DateWithTimeInput>;
  personalOnboardingDateTime?: InputMaybe<DateWithTimeInput>;
  policeRecordAppointmentDate?: InputMaybe<DateWithTimeInput>;
  recognitionMedicalCertificate?: InputMaybe<Scalars['Boolean']['input']>;
  registrationAuthority?: InputMaybe<Scalars['String']['input']>;
  registrationAuthorityAppointmentNumber?: InputMaybe<Scalars['String']['input']>;
  registrationOfficeDate?: InputMaybe<DateWithTimeInput>;
  residenceBank?: InputMaybe<Scalars['String']['input']>;
  residenceBankContactPartner?: InputMaybe<Scalars['String']['input']>;
  residenceFirstDeputyFirstName?: InputMaybe<Scalars['String']['input']>;
  residenceFirstDeputyId?: InputMaybe<Scalars['ID']['input']>;
  residenceFirstDeputyLastName?: InputMaybe<Scalars['String']['input']>;
  residencePermits?: InputMaybe<Array<ResidencePermitDataInput>>;
  residenceRepresentativeFirstName?: InputMaybe<Scalars['String']['input']>;
  residenceRepresentativeId?: InputMaybe<Scalars['ID']['input']>;
  residenceRepresentativeLastName?: InputMaybe<Scalars['String']['input']>;
  responsibleResidenceOrganizationId?: InputMaybe<Scalars['ID']['input']>;
  responsibleRoleResidence?: InputMaybe<Scalars['String']['input']>;
  suitabilityForWork?: InputMaybe<Scalars['String']['input']>;
  workStartDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export class ResidencePermit {
  __typename?: 'ResidencePermit';
  fictionalCertificateAvailable?: Maybe<Scalars['Boolean']['output']>;
  fictionalCertificateDoesNotAuthorisesWorking?: Maybe<Scalars['Boolean']['output']>;
  fictionalCertificateNumber?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  immigrationAuthority?: Maybe<Scalars['String']['output']>;
  residencePermit?: Maybe<Scalars['String']['output']>;
  residenceRequestedByPostDate?: Maybe<Scalars['DateTime']['output']>;
  residenceRequestedElectronicallyDate?: Maybe<Scalars['DateTime']['output']>;
};

export class ResidencePermitDataInput {
  fictionalCertificateAvailable?: InputMaybe<Scalars['Boolean']['input']>;
  fictionalCertificateDoesNotAuthorisesWorking?: InputMaybe<Scalars['Boolean']['input']>;
  fictionalCertificateNumber?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  immigrationAuthority?: InputMaybe<Scalars['String']['input']>;
  residencePermit?: InputMaybe<Scalars['String']['input']>;
  residenceRequestedByPostDate?: InputMaybe<Scalars['DateTime']['input']>;
  residenceRequestedElectronicallyDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export class RestrictedTalentPoolSearch {
  __typename?: 'RestrictedTalentPoolSearch';
  count: Scalars['Float']['output'];
  facets: TalentPoolFacets;
  results: Array<Talent>;
};

export class RestrictedTalentPoolSearchInput {
  filters: TalentPoolFilters;
  pools: Array<RestrictedTalentPoolSelection>;
  skip: Scalars['Float']['input'];
  top: Scalars['Float']['input'];
};

export class RestrictedTalentPoolSelection {
  talentPoolId: Scalars['ID']['input'];
  talentPoolOrganizationId: Scalars['ID']['input'];
};

export class RestrictedTalentPoolTalentRequestInput {
  talentIds: Array<Scalars['String']['input']>;
};

export class ReviewDocumentResult {
  __typename?: 'ReviewDocumentResult';
  feedbackText?: Maybe<Scalars['String']['output']>;
  fileBlob: Scalars['String']['output'];
  fileFormats: Array<Scalars['String']['output']>;
  isOutDated?: Maybe<Scalars['Boolean']['output']>;
  reviewerVotum: Array<ReviewerVotum>;
  taskId: Scalars['ID']['output'];
};

export class ReviewDocumentResultInput {
  feedbackText?: InputMaybe<Scalars['String']['input']>;
  fileBlob: Scalars['String']['input'];
  fileFormats: Array<Scalars['String']['input']>;
  isOutDated?: InputMaybe<Scalars['Boolean']['input']>;
  reviewerVotum: Array<ReviewerVotumInput>;
  taskId: Scalars['ID']['input'];
};

export class ReviewerVotum {
  __typename?: 'ReviewerVotum';
  confirmOk?: Maybe<Scalars['Boolean']['output']>;
  userDisplayName?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['ID']['output']>;
  userorganizationId?: Maybe<Scalars['ID']['output']>;
};

export class ReviewerVotumInput {
  confirmOk?: InputMaybe<Scalars['Boolean']['input']>;
  userDisplayName?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
  userorganizationId?: InputMaybe<Scalars['ID']['input']>;
};

export class RoleOrganizationUserGroupMapping {
  __typename?: 'RoleOrganizationUserGroupMapping';
  assignedUserGroupIds?: Maybe<Array<Scalars['String']['output']>>;
  processOrganizationId?: Maybe<Scalars['ID']['output']>;
  processRole: Scalars['String']['output'];
};

export class RoleOrganizationUserGroupMappingInput {
  assignedUserGroupIds?: InputMaybe<Array<Scalars['String']['input']>>;
  processOrganizationId?: InputMaybe<Scalars['ID']['input']>;
  processRole: Scalars['String']['input'];
};

export class RuleExecutionError {
  __typename?: 'RuleExecutionError';
  errorMessage?: Maybe<Scalars['String']['output']>;
  reason: RuleExecutionErrorReason;
  ruleId: Scalars['String']['output'];
  ruleName: Scalars['String']['output'];
  technicalDetails?: Maybe<Scalars['String']['output']>;
};

export class RuleExecutionErrorInput {
  errorMessage?: InputMaybe<Scalars['String']['input']>;
  reason: RuleExecutionErrorReason;
  ruleId: Scalars['String']['input'];
  ruleName: Scalars['String']['input'];
  technicalDetails?: InputMaybe<Scalars['String']['input']>;
};

export enum RuleExecutionErrorReason {
  ExpressionError = 'ExpressionError',
  GeneralError = 'GeneralError',
  RunConditionError = 'RunConditionError',
  ValidationError = 'ValidationError'
}

export class RuleTaskTarget {
  __typename?: 'RuleTaskTarget';
  areaIds?: Maybe<Array<Scalars['String']['output']>>;
  excludeTaskIds?: Maybe<Array<Scalars['String']['output']>>;
  phaseIds?: Maybe<Array<Scalars['String']['output']>>;
  taskIds?: Maybe<Array<Scalars['String']['output']>>;
  type: ProcessRuleTaskTargetType;
};

export class RuleTaskTargetInput {
  areaIds?: InputMaybe<Array<Scalars['String']['input']>>;
  excludeTaskIds?: InputMaybe<Array<Scalars['String']['input']>>;
  phaseIds?: InputMaybe<Array<Scalars['String']['input']>>;
  taskIds?: InputMaybe<Array<Scalars['String']['input']>>;
  type: ProcessRuleTaskTargetType;
};

export enum SalesTableType {
  AllCandidatesTable = 'AllCandidatesTable',
  CandidateCountPerStateOverTime = 'CandidateCountPerStateOverTime',
  CandidateStatusMovementOverTimeNew = 'CandidateStatusMovementOverTimeNew',
  CandidateTable = 'CandidateTable',
  CollectionTable = 'CollectionTable',
  DocumentTable = 'DocumentTable',
  GueteSiegelTable = 'GueteSiegelTable',
  LoginTable = 'LoginTable',
  OrganizationMappingTable = 'OrganizationMappingTable',
  OrganizationTable = 'OrganizationTable',
  UserTable = 'UserTable'
}

export class SearchFacet {
  __typename?: 'SearchFacet';
  count: Scalars['Float']['output'];
  value: Scalars['String']['output'];
};

export class SelectedPartnerSharingConfiguration {
  __typename?: 'SelectedPartnerSharingConfiguration';
  allowLinkingInCaseItIsManaged?: Maybe<Scalars['Boolean']['output']>;
  organizationId: Scalars['ID']['output'];
  shareContacts?: Maybe<Scalars['Boolean']['output']>;
  shareContractTemplates?: Maybe<Scalars['Boolean']['output']>;
  shareEZBData?: Maybe<Scalars['Boolean']['output']>;
  sharePartners?: Maybe<Scalars['Boolean']['output']>;
};

export class SelectedPartnerSharingConfigurationInput {
  allowLinkingInCaseItIsManaged?: InputMaybe<Scalars['Boolean']['input']>;
  organizationId: Scalars['ID']['input'];
  shareContacts?: InputMaybe<Scalars['Boolean']['input']>;
  shareContractTemplates?: InputMaybe<Scalars['Boolean']['input']>;
  shareEZBData?: InputMaybe<Scalars['Boolean']['input']>;
  sharePartners?: InputMaybe<Scalars['Boolean']['input']>;
};

export class SetInfo {
  __typename?: 'SetInfo';
  dateOfReceipt?: Maybe<Scalars['DateTime']['output']>;
  exists?: Maybe<Scalars['Boolean']['output']>;
  issueDate?: Maybe<Scalars['DateTime']['output']>;
  resubmissionDate?: Maybe<Scalars['DateTime']['output']>;
  resubmissionReason?: Maybe<Scalars['String']['output']>;
  validFrom?: Maybe<Scalars['DateTime']['output']>;
  validUntil?: Maybe<Scalars['DateTime']['output']>;
};

export class SetInfoInput {
  dateOfReceipt?: InputMaybe<Scalars['DateTime']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  issueDate?: InputMaybe<Scalars['DateTime']['input']>;
  resubmissionDate?: InputMaybe<Scalars['DateTime']['input']>;
  resubmissionReason?: InputMaybe<Scalars['String']['input']>;
  validFrom?: InputMaybe<Scalars['DateTime']['input']>;
  validUntil?: InputMaybe<Scalars['DateTime']['input']>;
};

export class Settings {
  __typename?: 'Settings';
  additionalModules?: Maybe<AdditionalModules>;
  auth0UserId?: Maybe<Scalars['String']['output']>;
  autoCVConfigured?: Maybe<Scalars['Boolean']['output']>;
  canEditOrganizationProfile?: Maybe<Scalars['Boolean']['output']>;
  candidateOrUserId?: Maybe<Scalars['String']['output']>;
  communicationLanguage?: Maybe<Scalars['String']['output']>;
  configToken?: Maybe<Scalars['String']['output']>;
  creatorOrganizationId?: Maybe<Scalars['String']['output']>;
  creatorOrganizationName?: Maybe<Scalars['String']['output']>;
  environmentName?: Maybe<Scalars['String']['output']>;
  facebookPageId?: Maybe<Scalars['String']['output']>;
  facebookUserId?: Maybe<Scalars['String']['output']>;
  formats?: Maybe<Scalars['String']['output']>;
  isCandidate?: Maybe<Scalars['Boolean']['output']>;
  isCreatorMasterOrganization?: Maybe<Scalars['Boolean']['output']>;
  isDemo?: Maybe<Scalars['Boolean']['output']>;
  isMasterOrganization?: Maybe<Scalars['Boolean']['output']>;
  isMasterUser?: Maybe<Scalars['Boolean']['output']>;
  isProcessActionEnabled?: Maybe<Scalars['Boolean']['output']>;
  licensed?: Maybe<Scalars['Boolean']['output']>;
  logoutPeriodInMinutes?: Maybe<Scalars['Float']['output']>;
  maxSize?: Maybe<Scalars['Float']['output']>;
  menu?: Maybe<Menu>;
  messagesReception?: Maybe<MessagesReception>;
  organizationCode?: Maybe<Scalars['String']['output']>;
  organizationEmail?: Maybe<Scalars['String']['output']>;
  organizationId?: Maybe<Scalars['String']['output']>;
  organizationName?: Maybe<Scalars['String']['output']>;
  profileDataEditStatus?: Maybe<ProfileDataEditStatus>;
  reloadAndReAuthenticate?: Maybe<Scalars['Boolean']['output']>;
  removeCandidatesFromAllListsIfDropout?: Maybe<Scalars['Boolean']['output']>;
  requireCandidateConsent?: Maybe<Scalars['Boolean']['output']>;
  requireUserConsent?: Maybe<Scalars['Boolean']['output']>;
  sharingSettings?: Maybe<SharingSettings>;
  showEnvironmentName?: Maybe<Scalars['Boolean']['output']>;
  standardImmigrationCountry?: Maybe<SupportedImmigrationCountry>;
  startUrl?: Maybe<Scalars['String']['output']>;
  supportEmail?: Maybe<Scalars['String']['output']>;
  supportPhone?: Maybe<Scalars['String']['output']>;
  supportedImmigrationCountries?: Maybe<Array<SupportedImmigrationCountry>>;
  switchableOrganizationCount?: Maybe<Scalars['Float']['output']>;
  userDisplayId?: Maybe<Scalars['String']['output']>;
  userDisplayName?: Maybe<Scalars['String']['output']>;
  userEmail?: Maybe<Scalars['String']['output']>;
  userGroups?: Maybe<Array<Scalars['ID']['output']>>;
  userInitials?: Maybe<Scalars['String']['output']>;
  userPermissions?: Maybe<Array<Scalars['String']['output']>>;
};

export class SharedCollectionEventsInput {
  collectionId: Scalars['ID']['input'];
  documentTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  eventCategories: Array<Scalars['String']['input']>;
  eventTimeframe: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  sharingId?: InputMaybe<Scalars['ID']['input']>;
};

export class SharedField {
  __typename?: 'SharedField';
  isEnabled?: Maybe<Scalars['Boolean']['output']>;
  isMandatory?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
};

export class SharedTab {
  __typename?: 'SharedTab';
  key: Scalars['String']['output'];
  sharedFields?: Maybe<Array<SharedField>>;
};

export class Sharing {
  __typename?: 'Sharing';
  _etag?: Maybe<Scalars['String']['output']>;
  anonymizationOptions?: Maybe<Array<AnonymizationTypeEnum>>;
  autoCVRequested?: Maybe<Scalars['Boolean']['output']>;
  creationDate: Scalars['DateTime']['output'];
  destinationOrganizationId: Scalars['ID']['output'];
  destinationOrganizationName: Scalars['String']['output'];
  disableSubmitFeedback?: Maybe<Scalars['Boolean']['output']>;
  enableNotificationEmails?: Maybe<Scalars['Boolean']['output']>;
  enabled: Scalars['Boolean']['output'];
  expiryDate?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isAnonymous?: Maybe<Scalars['Boolean']['output']>;
  isCandidateEditable?: Maybe<Scalars['Boolean']['output']>;
  notificationEmailUserGroupIds?: Maybe<Array<Scalars['ID']['output']>>;
  organizationId: Scalars['ID']['output'];
  profileAccessId?: Maybe<Scalars['String']['output']>;
  providedTemplates?: Maybe<Array<AvailableDocumentTemplate>>;
  shareInternalDocuments?: Maybe<Scalars['Boolean']['output']>;
  sharedCollectionId: Scalars['ID']['output'];
  sharedCollectionName: Scalars['String']['output'];
  sharedFileTypes?: Maybe<Array<Scalars['String']['output']>>;
  sharedTabs?: Maybe<Array<Scalars['String']['output']>>;
  sharingType: SharingTypeEnum;
  showCompletionStateAtDocuments?: Maybe<Scalars['Boolean']['output']>;
  submissionDate?: Maybe<Scalars['DateTime']['output']>;
  submitted?: Maybe<Scalars['Boolean']['output']>;
};

export class SharingActivityInput {
  filters?: InputMaybe<Scalars['JSONObject']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  organizationId: Scalars['ID']['input'];
  rows?: InputMaybe<Scalars['Int']['input']>;
  sharingId: Scalars['ID']['input'];
  sortField?: InputMaybe<Scalars['String']['input']>;
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
};

export class SharingActivityParameter {
  __typename?: 'SharingActivityParameter';
  id: Scalars['ID']['output'];
  organizationId: Scalars['ID']['output'];
  organizationName?: Maybe<Scalars['String']['output']>;
};

export class SharingCollectionQueryInput {
  collectionId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};

export class SharingCreateInput {
  anonymizationOptions?: InputMaybe<Array<AnonymizationTypeEnum>>;
  autoCVRequested?: InputMaybe<Scalars['Boolean']['input']>;
  destinationOrganizationId: Array<Scalars['ID']['input']>;
  disableSubmitFeedback?: InputMaybe<Scalars['Boolean']['input']>;
  enableNotificationEmails?: InputMaybe<Scalars['Boolean']['input']>;
  enabled: Scalars['Boolean']['input'];
  expiryDate?: InputMaybe<Scalars['DateTime']['input']>;
  isAnonymous?: InputMaybe<Scalars['Boolean']['input']>;
  isCandidateEditable?: InputMaybe<Scalars['Boolean']['input']>;
  notificationEmailUserGroups?: InputMaybe<Array<SharingCreateNotificationEmailUserGroupInput>>;
  organizationId: Scalars['ID']['input'];
  profileAccessId?: InputMaybe<Scalars['String']['input']>;
  providedTemplates?: InputMaybe<Array<AvailableDocumentTemplateInput>>;
  shareInternalDocuments?: InputMaybe<Scalars['Boolean']['input']>;
  sharedCollectionId: Scalars['ID']['input'];
  sharedFileTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  sharedTabs?: InputMaybe<Array<Scalars['String']['input']>>;
  sharingType: SharingTypeEnum;
  showCompletionStateAtDocuments?: InputMaybe<Scalars['Boolean']['input']>;
  submissionDate?: InputMaybe<Scalars['DateTime']['input']>;
  submitted?: InputMaybe<Scalars['Boolean']['input']>;
};

export class SharingCreateNotificationEmailUserGroup {
  __typename?: 'SharingCreateNotificationEmailUserGroup';
  organizationId: Scalars['ID']['output'];
  userGroupIds: Array<Scalars['ID']['output']>;
};

export class SharingCreateNotificationEmailUserGroupInput {
  organizationId: Scalars['ID']['input'];
  userGroupIds: Array<Scalars['ID']['input']>;
};

export class SharingDeleteInput {
  _etag: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  sharingId: Scalars['ID']['input'];
};

export class SharingDocumentQueryInput {
  candidateId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
  type: Scalars['String']['input'];
};

export class SharingEnumerationInput {
  organizationId: Scalars['ID']['input'];
};

export class SharingFileQueryInput {
  blob: Scalars['String']['input'];
  candidateId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};

export class SharingForCollectionCreateInput {
  anonymizationOptions?: InputMaybe<Array<AnonymizationTypeEnum>>;
  autoCVRequested?: InputMaybe<Scalars['Boolean']['input']>;
  destinationOrganizationId: Array<Scalars['ID']['input']>;
  disableSubmitFeedback?: InputMaybe<Scalars['Boolean']['input']>;
  enableNotificationEmails?: InputMaybe<Scalars['Boolean']['input']>;
  enabled: Scalars['Boolean']['input'];
  expiryDate?: InputMaybe<Scalars['DateTime']['input']>;
  isAnonymous?: InputMaybe<Scalars['Boolean']['input']>;
  isCandidateEditable?: InputMaybe<Scalars['Boolean']['input']>;
  notificationEmailUserGroups?: InputMaybe<Array<SharingCreateNotificationEmailUserGroupInput>>;
  profileAccessId?: InputMaybe<Scalars['String']['input']>;
  providedTemplates?: InputMaybe<Array<AvailableDocumentTemplateInput>>;
  shareInternalDocuments?: InputMaybe<Scalars['Boolean']['input']>;
  sharedFileTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  sharedTabs?: InputMaybe<Array<Scalars['String']['input']>>;
  sharingType: SharingTypeEnum;
  showCompletionStateAtDocuments?: InputMaybe<Scalars['Boolean']['input']>;
  submissionDate?: InputMaybe<Scalars['DateTime']['input']>;
  submitted?: InputMaybe<Scalars['Boolean']['input']>;
};

export class SharingPreset {
  __typename?: 'SharingPreset';
  _etag?: Maybe<Scalars['String']['output']>;
  changedAt: Scalars['DateTime']['output'];
  changedBy: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name: Scalars['String']['output'];
  organizationId: Scalars['ID']['output'];
  sharing: SharingPresetSharing;
};

export class SharingPresetCopyInput {
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
};

export class SharingPresetCreateInput {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  sharing: SharingPresetSharingInput;
};

export class SharingPresetDeleteInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
};

export class SharingPresetGetInput {
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
};

export class SharingPresetListInput {
  organizationId: Scalars['ID']['input'];
};

export class SharingPresetSharing {
  __typename?: 'SharingPresetSharing';
  anonymizationOptions?: Maybe<Array<AnonymizationTypeEnum>>;
  autoCVRequested?: Maybe<Scalars['Boolean']['output']>;
  disableSubmitFeedback?: Maybe<Scalars['Boolean']['output']>;
  enableNotificationEmails?: Maybe<Scalars['Boolean']['output']>;
  enabled: Scalars['Boolean']['output'];
  expiryDate?: Maybe<Scalars['DateTime']['output']>;
  isAnonymous?: Maybe<Scalars['Boolean']['output']>;
  isCandidateEditable?: Maybe<Scalars['Boolean']['output']>;
  notificationEmailUserGroups?: Maybe<Array<SharingCreateNotificationEmailUserGroup>>;
  profileAccessId?: Maybe<Scalars['String']['output']>;
  providedTemplates?: Maybe<Array<AvailableDocumentTemplate>>;
  shareInternalDocuments?: Maybe<Scalars['Boolean']['output']>;
  sharedFileTypes?: Maybe<Array<Scalars['String']['output']>>;
  sharedTabs?: Maybe<Array<Scalars['String']['output']>>;
  sharingType: SharingTypeEnum;
  showCompletionStateAtDocuments?: Maybe<Scalars['Boolean']['output']>;
};

export class SharingPresetSharingInput {
  anonymizationOptions?: InputMaybe<Array<AnonymizationTypeEnum>>;
  autoCVRequested?: InputMaybe<Scalars['Boolean']['input']>;
  disableSubmitFeedback?: InputMaybe<Scalars['Boolean']['input']>;
  enableNotificationEmails?: InputMaybe<Scalars['Boolean']['input']>;
  enabled: Scalars['Boolean']['input'];
  expiryDate?: InputMaybe<Scalars['DateTime']['input']>;
  isAnonymous?: InputMaybe<Scalars['Boolean']['input']>;
  isCandidateEditable?: InputMaybe<Scalars['Boolean']['input']>;
  notificationEmailUserGroups?: InputMaybe<Array<SharingCreateNotificationEmailUserGroupInput>>;
  profileAccessId?: InputMaybe<Scalars['String']['input']>;
  providedTemplates?: InputMaybe<Array<AvailableDocumentTemplateInput>>;
  shareInternalDocuments?: InputMaybe<Scalars['Boolean']['input']>;
  sharedFileTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  sharedTabs?: InputMaybe<Array<Scalars['String']['input']>>;
  sharingType: SharingTypeEnum;
  showCompletionStateAtDocuments?: InputMaybe<Scalars['Boolean']['input']>;
};

export class SharingPresetUpdateInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  sharing: SharingPresetSharingInput;
};

export class SharingQueryInput {
  organizationId: Scalars['ID']['input'];
  sharingId: Scalars['ID']['input'];
};

export class SharingSettings {
  __typename?: 'SharingSettings';
  autoCV: Scalars['Boolean']['output'];
  disableSubmitFeedback?: Maybe<Scalars['Boolean']['output']>;
  sharedDocuments: Array<Scalars['String']['output']>;
  sharedTabs?: Maybe<Array<Scalars['String']['output']>>;
};

export class SharingState {
  __typename?: 'SharingState';
  contactsSharedByTargetOrganization: Scalars['Boolean']['output'];
  contractsSharedByTargetOrganization: Scalars['Boolean']['output'];
  organizationsSharedByCandidateOwner: Scalars['Boolean']['output'];
  specificContactsSharedByTargetOrganization: Array<SharingStateContacts>;
  specificContractSharedByTargetOrganization: Scalars['Boolean']['output'];
  targetOrganizationShared: Scalars['Boolean']['output'];
};

export class SharingStateContacts {
  __typename?: 'SharingStateContacts';
  id: Scalars['String']['output'];
  shared: Scalars['Boolean']['output'];
};

export class SharingStateInput {
  candidateOwnerOrganizationId: Scalars['ID']['input'];
  contactIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  contractId?: InputMaybe<Scalars['ID']['input']>;
  targetOrganizationId?: InputMaybe<Scalars['ID']['input']>;
  userOrganizationId: Scalars['ID']['input'];
};

export class SharingSubmitInput {
  organizationId: Scalars['ID']['input'];
  sharingId: Scalars['ID']['input'];
};

export class SharingTemplate {
  __typename?: 'SharingTemplate';
  _etag?: Maybe<Scalars['String']['output']>;
  anonymizationOptions?: Maybe<Array<AnonymizationTypeEnum>>;
  autoCVRequested?: Maybe<Scalars['Boolean']['output']>;
  changedAt: Scalars['DateTime']['output'];
  creationDate: Scalars['DateTime']['output'];
  destinationOrganizationIdOrVariable: Scalars['String']['output'];
  destinationOrganizationIdOrVariableName: Scalars['String']['output'];
  disableSubmitFeedback?: Maybe<Scalars['Boolean']['output']>;
  enableNotificationEmails?: Maybe<Scalars['Boolean']['output']>;
  enabled: Scalars['Boolean']['output'];
  expiryDate?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isAnonymous?: Maybe<Scalars['Boolean']['output']>;
  isCandidateEditable?: Maybe<Scalars['Boolean']['output']>;
  notificationEmailUserGroupIds?: Maybe<Array<Scalars['ID']['output']>>;
  organizationId: Scalars['ID']['output'];
  profileAccessId?: Maybe<Scalars['String']['output']>;
  providedTemplates?: Maybe<Array<AvailableDocumentTemplate>>;
  shareInternalDocuments?: Maybe<Scalars['Boolean']['output']>;
  sharedCollectionId: Scalars['ID']['output'];
  sharedFileTypes?: Maybe<Array<Scalars['String']['output']>>;
  sharedTabs?: Maybe<Array<Scalars['String']['output']>>;
  sharingType: SharingTypeEnum;
  showCompletionStateAtDocuments?: Maybe<Scalars['Boolean']['output']>;
  submissionDate?: Maybe<Scalars['DateTime']['output']>;
  submitted?: Maybe<Scalars['Boolean']['output']>;
};

export class SharingTemplateCreateInput {
  anonymizationOptions?: InputMaybe<Array<AnonymizationTypeEnum>>;
  autoCVRequested?: InputMaybe<Scalars['Boolean']['input']>;
  destinationOrganizationIdOrVariables: Array<Scalars['String']['input']>;
  disableSubmitFeedback?: InputMaybe<Scalars['Boolean']['input']>;
  enableNotificationEmails?: InputMaybe<Scalars['Boolean']['input']>;
  enabled: Scalars['Boolean']['input'];
  expiryDate?: InputMaybe<Scalars['DateTime']['input']>;
  isAnonymous?: InputMaybe<Scalars['Boolean']['input']>;
  isCandidateEditable?: InputMaybe<Scalars['Boolean']['input']>;
  notificationEmailUserGroups?: InputMaybe<Array<SharingCreateNotificationEmailUserGroupInput>>;
  organizationId: Scalars['ID']['input'];
  profileAccessId?: InputMaybe<Scalars['String']['input']>;
  providedTemplates?: InputMaybe<Array<AvailableDocumentTemplateInput>>;
  shareInternalDocuments?: InputMaybe<Scalars['Boolean']['input']>;
  sharedCollectionId: Scalars['ID']['input'];
  sharedFileTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  sharedTabs?: InputMaybe<Array<Scalars['String']['input']>>;
  sharingType: SharingTypeEnum;
  showCompletionStateAtDocuments?: InputMaybe<Scalars['Boolean']['input']>;
  submissionDate?: InputMaybe<Scalars['DateTime']['input']>;
  submitted?: InputMaybe<Scalars['Boolean']['input']>;
};

export class SharingTemplateDeleteInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
};

export class SharingTemplateUpdateInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  anonymizationOptions?: InputMaybe<Array<AnonymizationTypeEnum>>;
  autoCVRequested?: InputMaybe<Scalars['Boolean']['input']>;
  destinationOrganizationIdOrVariable: Scalars['String']['input'];
  disableSubmitFeedback?: InputMaybe<Scalars['Boolean']['input']>;
  enableNotificationEmails?: InputMaybe<Scalars['Boolean']['input']>;
  enabled: Scalars['Boolean']['input'];
  expiryDate?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isAnonymous?: InputMaybe<Scalars['Boolean']['input']>;
  isCandidateEditable?: InputMaybe<Scalars['Boolean']['input']>;
  notificationEmailUserGroupIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  organizationId: Scalars['ID']['input'];
  profileAccessId?: InputMaybe<Scalars['String']['input']>;
  providedTemplates?: InputMaybe<Array<AvailableDocumentTemplateInput>>;
  shareInternalDocuments?: InputMaybe<Scalars['Boolean']['input']>;
  sharedCollectionId: Scalars['ID']['input'];
  sharedFileTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  sharedTabs?: InputMaybe<Array<Scalars['String']['input']>>;
  sharingType: SharingTypeEnum;
  showCompletionStateAtDocuments?: InputMaybe<Scalars['Boolean']['input']>;
  submissionDate?: InputMaybe<Scalars['DateTime']['input']>;
  submitted?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum SharingTypeEnum {
  Collaboration = 'Collaboration',
  ReadOnlyCollaboration = 'ReadOnlyCollaboration',
  View = 'View'
}

export class SharingUpdateInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  anonymizationOptions?: InputMaybe<Array<AnonymizationTypeEnum>>;
  autoCVRequested?: InputMaybe<Scalars['Boolean']['input']>;
  creationDate: Scalars['DateTime']['input'];
  disableSubmitFeedback?: InputMaybe<Scalars['Boolean']['input']>;
  enableNotificationEmails?: InputMaybe<Scalars['Boolean']['input']>;
  enabled: Scalars['Boolean']['input'];
  expiryDate?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isAnonymous?: InputMaybe<Scalars['Boolean']['input']>;
  isCandidateEditable?: InputMaybe<Scalars['Boolean']['input']>;
  notificationEmailUserGroupIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  organizationId: Scalars['ID']['input'];
  profileAccessId?: InputMaybe<Scalars['String']['input']>;
  providedTemplates?: InputMaybe<Array<AvailableDocumentTemplateInput>>;
  shareInternalDocuments?: InputMaybe<Scalars['Boolean']['input']>;
  sharedCollectionId: Scalars['ID']['input'];
  sharedFileTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  sharedTabs?: InputMaybe<Array<Scalars['String']['input']>>;
  sharingType: SharingTypeEnum;
  showCompletionStateAtDocuments?: InputMaybe<Scalars['Boolean']['input']>;
  submissionDate?: InputMaybe<Scalars['DateTime']['input']>;
  submitted?: InputMaybe<Scalars['Boolean']['input']>;
};

export class SingleSetConfig {
  __typename?: 'SingleSetConfig';
  formats: Array<Scalars['String']['output']>;
  type: Scalars['String']['output'];
};

export class SingleSetConfigInput {
  formats: Array<Scalars['String']['input']>;
  type: Scalars['String']['input'];
};

export class StaticDataDownload {
  __typename?: 'StaticDataDownload';
  fileName: Scalars['String']['output'];
  fileUrl: Scalars['String']['output'];
};

export class StaticDataInputContext {
  immigrationCountry?: InputMaybe<SupportedImmigrationCountry>;
  organizationId?: InputMaybe<Scalars['ID']['input']>;
};

export class StaticDataModel {
  __typename?: 'StaticDataModel';
  label: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export class StaticDataModelInput {
  contexts?: InputMaybe<Array<StaticDataInputContext>>;
  language: Scalars['String']['input'];
  type: StaticDataType;
};

export class StaticDataResult {
  __typename?: 'StaticDataResult';
  contextSensitiveFields?: Maybe<Array<Scalars['String']['output']>>;
  data: Array<StaticDataModel>;
  isLanguageIndependent?: Maybe<Scalars['Boolean']['output']>;
};

export enum StaticDataType {
  All = 'All',
  AllowedSharedProfileTabs = 'AllowedSharedProfileTabs',
  AllowedUploadFileTypes = 'AllowedUploadFileTypes',
  AwardFrequency = 'AwardFrequency',
  Beds = 'Beds',
  Benefits = 'Benefits',
  CandidateEvent = 'CandidateEvent',
  CandidateEventCategory = 'CandidateEventCategory',
  CandidateEventTimeframe = 'CandidateEventTimeframe',
  CareFacilities = 'CareFacilities',
  Category = 'Category',
  CertificateOfConduct = 'CertificateOfConduct',
  CooperationType = 'CooperationType',
  Countries = 'Countries',
  Currencies = 'Currencies',
  DocumentAvailabilityState = 'DocumentAvailabilityState',
  DocumentDescription = 'DocumentDescription',
  DocumentFormats = 'DocumentFormats',
  DocumentFormats2FileNameMapping = 'DocumentFormats2FileNameMapping',
  DocumentFormatsAppreviation = 'DocumentFormatsAppreviation',
  DrivingLicenses = 'DrivingLicenses',
  Education = 'Education',
  EquivalenceTest = 'EquivalenceTest',
  ExamInstitutions = 'ExamInstitutions',
  ExamResultState = 'ExamResultState',
  ExamType = 'ExamType',
  FederalStates = 'FederalStates',
  FileType2FileNameMapping = 'FileType2FileNameMapping',
  Functions = 'Functions',
  Gender = 'Gender',
  KnownCities = 'KnownCities',
  LaborAgreementType = 'LaborAgreementType',
  LaborContractAllowance = 'LaborContractAllowance',
  LaborContractType = 'LaborContractType',
  LaborContractWorkSchedule = 'LaborContractWorkSchedule',
  LanguageLevels = 'LanguageLevels',
  LanguageModules = 'LanguageModules',
  Languages = 'Languages',
  MartialStatus = 'MartialStatus',
  OrganizationType = 'OrganizationType',
  OtherActivities = 'OtherActivities',
  PathOfRecognition = 'PathOfRecognition',
  Positions = 'Positions',
  PreferredFamilyReunion = 'PreferredFamilyReunion',
  PreferredTypeOfImmigration = 'PreferredTypeOfImmigration',
  ProcessRole = 'ProcessRole',
  Profession = 'Profession',
  ProfessionArea = 'ProfessionArea',
  ProfessionalFields = 'ProfessionalFields',
  QualificationEvaluationNoticeKind = 'QualificationEvaluationNoticeKind',
  QualificationExams = 'QualificationExams',
  QualificationMeasures = 'QualificationMeasures',
  QualificationType = 'QualificationType',
  RecognitionCenter = 'RecognitionCenter',
  RecognitionNoticeKind = 'RecognitionNoticeKind',
  RecognitionState = 'RecognitionState',
  RecognitionType = 'RecognitionType',
  RelocationRequirements = 'RelocationRequirements',
  ResidencePermits = 'ResidencePermits',
  Salutation = 'Salutation',
  SignatureAddition = 'SignatureAddition',
  SuitabilityForWork = 'SuitabilityForWork',
  SupportedImmigrationCountries = 'SupportedImmigrationCountries',
  TargetRecognition = 'TargetRecognition',
  Title = 'Title',
  TravelVehicles = 'TravelVehicles',
  Vaccination = 'Vaccination',
  VaccinationStatus = 'VaccinationStatus',
  VaccineFunction = 'VaccineFunction',
  VocationalSchoolTrainingTimeCurrent = 'VocationalSchoolTrainingTimeCurrent',
  VocationalSchoolTrainingTimeTotal = 'VocationalSchoolTrainingTimeTotal',
  WayToUs = 'WayToUs',
  WorkArea = 'WorkArea',
  WorkLocations = 'WorkLocations',
  WorkingTimeType = 'WorkingTimeType'
}

export class Subscription {
  __typename?: 'Subscription';
  extractDocumentData: DocumentAiExtractionResult;
  getChangeStatisticsData: ExcelAddInDto;
};


export type SubscriptionExtractDocumentDataArgs = {
  input: DocumentAiExtractionInput;
};


export type SubscriptionGetChangeStatisticsDataArgs = {
  input: ExcelAddinGetChangeStatisticsInput;
};

export enum SupportedBillingCurrency {
  Eur = 'EUR'
}

export enum SupportedImmigrationCountry {
  At = 'AT',
  De = 'DE',
  Unknown = 'UNKNOWN'
}

export class SystemSetting {
  __typename?: 'SystemSetting';
  _etag?: Maybe<Scalars['String']['output']>;
  automaticRejectionEmailsEnabled: Scalars['Boolean']['output'];
  candidateDeletionSetting: Array<CandidateDeletionSetting>;
  candidateTags?: Maybe<Array<CandidateTagConfiguration>>;
  enableCustomCandidateTags?: Maybe<Scalars['Boolean']['output']>;
  fileNameWithCandidateId: Scalars['Boolean']['output'];
  fileNameWithFileFormat: Scalars['Boolean']['output'];
  id?: Maybe<Scalars['ID']['output']>;
  isPubliclyVisible: Scalars['Boolean']['output'];
  isSystemLogoutEnabled: Scalars['Boolean']['output'];
  logoutPeriodMinutes: Scalars['Float']['output'];
  organizationId: Scalars['ID']['output'];
  precisionOfEducation?: Maybe<WorkExperiencePrecissionEnum>;
  precisionOfWorkexperience?: Maybe<WorkExperiencePrecissionEnum>;
  removeCandidatesFromAllListsIfDropout?: Maybe<Scalars['Boolean']['output']>;
  requiredCandidateFields?: Maybe<Array<Scalars['String']['output']>>;
  standardImmigrationCountry: SupportedImmigrationCountry;
  supportedImmigrationCountries: Array<SupportedImmigrationCountry>;
};

export class SystemSettingsForCandidateOrg {
  __typename?: 'SystemSettingsForCandidateOrg';
  id?: Maybe<Scalars['ID']['output']>;
  organizationId: Scalars['ID']['output'];
  precisionOfEducation?: Maybe<WorkExperiencePrecissionEnum>;
  precisionOfWorkexperience?: Maybe<WorkExperiencePrecissionEnum>;
  requiredCandidateFields?: Maybe<Array<Scalars['String']['output']>>;
  standardImmigrationCountry: SupportedImmigrationCountry;
};

export class SystemSettingsGetInput {
  organizationId: Scalars['ID']['input'];
};

export class SystemSettingsUpdateInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  automaticRejectionEmailsEnabled: Scalars['Boolean']['input'];
  candidateDeletionSetting: Array<CandidateDeletionSettingInput>;
  candidateTags?: InputMaybe<Array<CandidateTagConfigurationInput>>;
  enableCustomCandidateTags?: InputMaybe<Scalars['Boolean']['input']>;
  fileNameWithCandidateId: Scalars['Boolean']['input'];
  fileNameWithFileFormat: Scalars['Boolean']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  isPubliclyVisible: Scalars['Boolean']['input'];
  isSystemLogoutEnabled: Scalars['Boolean']['input'];
  logoutPeriodMinutes: Scalars['Float']['input'];
  organizationId: Scalars['ID']['input'];
  precisionOfEducation?: InputMaybe<WorkExperiencePrecissionEnum>;
  precisionOfWorkexperience?: InputMaybe<WorkExperiencePrecissionEnum>;
  removeCandidatesFromAllListsIfDropout?: InputMaybe<Scalars['Boolean']['input']>;
  requiredCandidateFields?: InputMaybe<Array<Scalars['String']['input']>>;
  standardImmigrationCountry: SupportedImmigrationCountry;
  supportedImmigrationCountries: Array<SupportedImmigrationCountry>;
};

export class TcConsent {
  __typename?: 'TCConsent';
  consentDate: Scalars['DateTime']['output'];
  consentTexts?: Maybe<Array<TcConsentText>>;
};

export class TcConsentText {
  __typename?: 'TCConsentText';
  contents: Scalars['String']['output'];
  language: Scalars['String']['output'];
};

export class TabField {
  __typename?: 'TabField';
  isEnabled?: Maybe<Scalars['Boolean']['output']>;
  isMandatory?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
};

export class TabFieldInput {
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isMandatory?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
};

export enum TableMode {
  Flat = 'Flat',
  Relational = 'Relational'
}

export class Talent {
  __typename?: 'Talent';
  applicationSafeCompetencies?: Maybe<Array<Scalars['String']['output']>>;
  availableFrom?: Maybe<Scalars['DateTime']['output']>;
  careFacilities?: Maybe<Array<Scalars['String']['output']>>;
  contributorOrganizationId: Scalars['ID']['output'];
  country?: Maybe<Scalars['String']['output']>;
  drivingLicense?: Maybe<Array<Scalars['String']['output']>>;
  experienceFields?: Maybe<Array<Scalars['String']['output']>>;
  function: Scalars['String']['output'];
  germanLevel?: Maybe<Scalars['String']['output']>;
  hasCv: Scalars['Boolean']['output'];
  immigrationCountry: SupportedImmigrationCountry;
  nonCertifiedGermanLevel?: Maybe<Scalars['String']['output']>;
  nonProfessionalTrainings?: Maybe<Array<Scalars['String']['output']>>;
  otherLanguageSkills?: Maybe<Array<LanguageSkill>>;
  preferredCareFacility?: Maybe<Array<Scalars['String']['output']>>;
  preferredLocationState?: Maybe<Array<Scalars['String']['output']>>;
  preferredTemporalScope?: Maybe<WorkingTimeTypeEnum>;
  preferredWorkingField?: Maybe<Array<Scalars['String']['output']>>;
  profession: Scalars['String']['output'];
  professionalRecognitionState: RecognitionState;
  professionalTrainings?: Maybe<Array<Scalars['String']['output']>>;
  talentId: Scalars['String']['output'];
  talentPoolId: Scalars['ID']['output'];
  talentPoolOrganizationId: Scalars['ID']['output'];
  yearsOfProfessionalExperience: YearsAndMonths;
};

export class TalentInstance {
  __typename?: 'TalentInstance';
  talentId: Scalars['String']['output'];
  talentPoolId: Scalars['ID']['output'];
  talentPoolOrganizationId: Scalars['ID']['output'];
};

export class TalentInstanceInput {
  talentId: Scalars['String']['input'];
  talentPoolId: Scalars['ID']['input'];
  talentPoolOrganizationId: Scalars['ID']['input'];
};

export class TalentPool {
  __typename?: 'TalentPool';
  _etag?: Maybe<Scalars['String']['output']>;
  changedAt: Scalars['DateTime']['output'];
  changedBy: Scalars['String']['output'];
  description?: Maybe<Array<LocalizedString>>;
  id?: Maybe<Scalars['ID']['output']>;
  isSpecial?: Maybe<Scalars['Boolean']['output']>;
  localizedDescription?: Maybe<Scalars['String']['output']>;
  localizedName: Scalars['String']['output'];
  name: Array<LocalizedString>;
  organizationId: Scalars['ID']['output'];
  organizationName?: Maybe<Scalars['String']['output']>;
  participantOrganizationIds?: Maybe<Array<Scalars['String']['output']>>;
  type: TalentPoolType;
};

export class TalentPoolConfiguration {
  __typename?: 'TalentPoolConfiguration';
  standard: Scalars['Boolean']['output'];
  standardInclContributors: Scalars['Boolean']['output'];
  website: Scalars['Boolean']['output'];
};

export class TalentPoolConfigurationInput {
  organizationId: Scalars['ID']['input'];
};

export class TalentPoolCreateInput {
  description?: InputMaybe<Array<LocalizedStringInput>>;
  isSpecial?: InputMaybe<Scalars['Boolean']['input']>;
  name: Array<LocalizedStringInput>;
  organizationId: Scalars['ID']['input'];
  type: TalentPoolType;
};

export class TalentPoolCv {
  __typename?: 'TalentPoolCv';
  fileName: Scalars['String']['output'];
  status: AutoCvResponseStatus;
  url?: Maybe<Scalars['String']['output']>;
};

export class TalentPoolCvInput {
  talent: TalentInstanceInput;
};

export class TalentPoolDeleteInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
};

export class TalentPoolFacets {
  __typename?: 'TalentPoolFacets';
  certifiedLanguageLevels: Array<SearchFacet>;
  countryOfOrigin: Array<SearchFacet>;
  drivingLicense: Array<SearchFacet>;
  function: Array<SearchFacet>;
  immigrationCountry: Array<SearchFacet>;
  knowledge: Array<SearchFacet>;
  notCertifiedLanguageLevels: Array<SearchFacet>;
  preferredCareFacility: Array<SearchFacet>;
  preferredEmploymentType: Array<SearchFacet>;
  preferredFamilyReunion: Array<SearchFacet>;
  preferredLocationState: Array<SearchFacet>;
  preferredTemporalScope: Array<SearchFacet>;
  preferredTypeOfImmigration: Array<SearchFacet>;
  preferredWorkingField: Array<SearchFacet>;
  profession: Array<SearchFacet>;
  professionArea: Array<SearchFacet>;
  professionSubArea: Array<SearchFacet>;
  recognitionReceived: Array<SearchFacet>;
  skills: Array<SearchFacet>;
  yearsOfExperience: Array<SearchFacet>;
};

export class TalentPoolFilters {
  availableFrom?: InputMaybe<Scalars['DateTime']['input']>;
  certifiedLanguageLevels: Array<Scalars['String']['input']>;
  countryOfOrigin: Array<Scalars['String']['input']>;
  drivingLicense: Array<Scalars['String']['input']>;
  function: Array<Scalars['String']['input']>;
  immigrationCountry: Array<SupportedImmigrationCountry>;
  knowledge?: InputMaybe<Array<Scalars['String']['input']>>;
  notCertifiedLanguageLevels: Array<Scalars['String']['input']>;
  preferredCareFacility: Array<Scalars['String']['input']>;
  preferredEmploymentType: Array<EmploymentRelationshipType>;
  preferredFamilyReunion: Array<Scalars['String']['input']>;
  preferredLocationState: Array<Scalars['String']['input']>;
  preferredTemporalScope: Array<WorkingTimeTypeEnum>;
  preferredTypeOfImmigration: Array<Scalars['String']['input']>;
  preferredWorkingField: Array<Scalars['String']['input']>;
  profession: Array<Scalars['String']['input']>;
  professionArea: Array<Scalars['String']['input']>;
  professionSubArea: Array<Scalars['String']['input']>;
  recognitionReceived: Scalars['Boolean']['input'];
  skills?: InputMaybe<Array<Scalars['String']['input']>>;
  yearsOfExperience: Array<Scalars['Float']['input']>;
};

export class TalentPoolGetInput {
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
};

export class TalentPoolListInput {
  organizationId: Scalars['ID']['input'];
};

export class TalentPoolParticipant {
  __typename?: 'TalentPoolParticipant';
  _etag?: Maybe<Scalars['String']['output']>;
  changedAt: Scalars['DateTime']['output'];
  changedBy: Scalars['String']['output'];
  id?: Maybe<Scalars['ID']['output']>;
  organizationId: Scalars['ID']['output'];
  participantOrganizationId: Scalars['ID']['output'];
  participantOrganizationName?: Maybe<Scalars['String']['output']>;
  talentPoolId: Scalars['ID']['output'];
  types: Array<TalentPoolParticipantType>;
};

export class TalentPoolParticipantDeleteInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
};

export class TalentPoolParticipantGetInput {
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
};

export class TalentPoolParticipantListInput {
  organizationId: Scalars['ID']['input'];
  talentPoolId: Scalars['ID']['input'];
};

export enum TalentPoolParticipantType {
  Contributor = 'Contributor',
  User = 'User'
}

export class TalentPoolParticipantUpdateInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
  participantOrganizationId: Scalars['ID']['input'];
  talentPoolId: Scalars['ID']['input'];
  types: Array<TalentPoolParticipantType>;
};

export class TalentPoolParticipantsCreateInput {
  organizationId: Scalars['ID']['input'];
  participantOrganizationIds: Array<Scalars['ID']['input']>;
  talentPoolId: Scalars['ID']['input'];
  types: Array<TalentPoolParticipantType>;
};

export class TalentPoolSharing {
  __typename?: 'TalentPoolSharing';
  _etag?: Maybe<Scalars['String']['output']>;
  changedAt: Scalars['DateTime']['output'];
  changedBy: Scalars['String']['output'];
  collectionId: Scalars['ID']['output'];
  cvKind?: Maybe<TalentPoolSharingCvKind>;
  enabled: Scalars['Boolean']['output'];
  expiryDate?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  organizationId: Scalars['ID']['output'];
  talentPoolId: Scalars['ID']['output'];
  talentPoolName?: Maybe<Scalars['String']['output']>;
  talentPoolOrganizationId: Scalars['ID']['output'];
  useCv: Scalars['Boolean']['output'];
};

export class TalentPoolSharingCreateInput {
  collectionId: Scalars['ID']['input'];
  cvKind?: InputMaybe<TalentPoolSharingCvKind>;
  enabled: Scalars['Boolean']['input'];
  expiryDate?: InputMaybe<Scalars['DateTime']['input']>;
  organizationId: Scalars['ID']['input'];
  talentPoolId: Scalars['ID']['input'];
  talentPoolOrganizationId: Scalars['ID']['input'];
  useCv: Scalars['Boolean']['input'];
};

export enum TalentPoolSharingCvKind {
  Standard = 'Standard',
  TalentPool = 'TalentPool'
}

export class TalentPoolSharingDeleteInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
};

export class TalentPoolSharingGetInput {
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
};

export class TalentPoolSharingListInput {
  collectionId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};

export class TalentPoolSharingUpdateInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  collectionId: Scalars['ID']['input'];
  cvKind?: InputMaybe<TalentPoolSharingCvKind>;
  enabled: Scalars['Boolean']['input'];
  expiryDate?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
  talentPoolId: Scalars['ID']['input'];
  talentPoolOrganizationId: Scalars['ID']['input'];
  useCv: Scalars['Boolean']['input'];
};

export enum TalentPoolType {
  Standard = 'Standard',
  Website = 'Website'
}

export class TalentPoolUpdateInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Array<LocalizedStringInput>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isSpecial?: InputMaybe<Scalars['Boolean']['input']>;
  name: Array<LocalizedStringInput>;
  organizationId: Scalars['ID']['input'];
  type: TalentPoolType;
};

export class TalentRequestSource {
  companyName: Scalars['String']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export class TaskAccess {
  __typename?: 'TaskAccess';
  canAccess: Scalars['Boolean']['output'];
  redirectToDelegation?: Maybe<Scalars['ID']['output']>;
  redirectToOrganization?: Maybe<Scalars['ID']['output']>;
};

export class TaskAccessInput {
  organizationId: Scalars['ID']['input'];
  processId: Scalars['ID']['input'];
  taskId: Scalars['ID']['input'];
};

export class TaskActivityInput {
  filters?: InputMaybe<Scalars['JSONObject']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  organizationId: Scalars['ID']['input'];
  processId: Scalars['ID']['input'];
  rows?: InputMaybe<Scalars['Int']['input']>;
  sortField?: InputMaybe<Scalars['String']['input']>;
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
  taskId: Scalars['ID']['input'];
};

export class TaskActivityParameter {
  __typename?: 'TaskActivityParameter';
  id: Scalars['ID']['output'];
  name?: Maybe<Array<ProcessOptionalString>>;
  organizationId: Scalars['ID']['output'];
  organizationName?: Maybe<Scalars['String']['output']>;
  userGroupIds?: Maybe<Array<Scalars['String']['output']>>;
};

export class TaskArchiveInput {
  language: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  processId: Scalars['ID']['input'];
  taskId: Scalars['ID']['input'];
};

export class TaskInbox {
  __typename?: 'TaskInbox';
  candidates: Array<Candidate>;
  languages: Array<Scalars['String']['output']>;
  processes: Array<Process>;
  tasks: Array<ProcessTaskResult>;
};

export class TaskInboxInput {
  candidateId?: InputMaybe<Scalars['ID']['input']>;
  candidateOrganizationId?: InputMaybe<Scalars['ID']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  states?: InputMaybe<Array<InstanceStateEnum>>;
};

export class TaskReferencedBy {
  __typename?: 'TaskReferencedBy';
  referencesOnActivationCondition: Array<TaskReferencedByInfo>;
  referencesOnPredecessor: Array<TaskReferencedByInfo>;
  referencesOnRules: Array<TaskReferencedByInfo>;
  referencesOnTaskFunctionality: Array<TaskReferencedByInfo>;
};

export class TaskReferencedByInfo {
  __typename?: 'TaskReferencedByInfo';
  id: Scalars['String']['output'];
  localizedDisplayName: Scalars['String']['output'];
};

export class TaskReminderSettings {
  __typename?: 'TaskReminderSettings';
  enabled: Scalars['Boolean']['output'];
  escalate?: Maybe<Scalars['Boolean']['output']>;
  escalationAfterAttemps?: Maybe<Scalars['Int']['output']>;
  firstReminderInDays: Scalars['Int']['output'];
  followUpReminderInDays?: Maybe<Scalars['Int']['output']>;
};

export class TaskReminderSettingsInput {
  enabled: Scalars['Boolean']['input'];
  escalate?: InputMaybe<Scalars['Boolean']['input']>;
  escalationAfterAttemps?: InputMaybe<Scalars['Int']['input']>;
  firstReminderInDays: Scalars['Int']['input'];
  followUpReminderInDays?: InputMaybe<Scalars['Int']['input']>;
};

export class TaskReminderState {
  __typename?: 'TaskReminderState';
  _etag?: Maybe<Scalars['String']['output']>;
  escalated: Scalars['Boolean']['output'];
  id?: Maybe<Scalars['ID']['output']>;
  lastReminderDate?: Maybe<Scalars['DateTime']['output']>;
  maxReminderCountReached?: Maybe<Scalars['Boolean']['output']>;
  nextReminderDate?: Maybe<Scalars['DateTime']['output']>;
  organizationId: Scalars['ID']['output'];
  reminderCount: Scalars['Int']['output'];
  taskId: Scalars['String']['output'];
};

export class TaskStateProcessForOrganizationAudit {
  __typename?: 'TaskStateProcessForOrganizationAudit';
  process: Process;
  state: ProcessStateOrganizationAudit;
  task: ProcessTaskOrganizationAudit;
};

export enum TaskVisibilityEnum {
  UserGroup = 'UserGroup',
  WholeOrganization = 'WholeOrganization'
}

export enum TechnicalSourceEnum {
  Api = 'API',
  Copy = 'Copy',
  Import = 'Import',
  Transfer = 'Transfer',
  Ux = 'UX'
}

export enum TemplateToDocumentTypeConversion {
  OriginalFileType = 'OriginalFileType',
  Pdf = 'PDF'
}

export enum TerminationEmploymentActor {
  Employee = 'Employee',
  Employer = 'Employer'
}

export class TransferData {
  __typename?: 'TransferData';
  originalId: Scalars['ID']['output'];
  targetDisplayId: Scalars['String']['output'];
  targetOrganizationId: Scalars['ID']['output'];
  transferDate: Scalars['DateTime']['output'];
};

export class TransferDataInput {
  originalId: Scalars['ID']['input'];
  targetDisplayId: Scalars['String']['input'];
  targetOrganizationId: Scalars['ID']['input'];
  transferDate: Scalars['DateTime']['input'];
};

export class TranslationMetaData {
  __typename?: 'TranslationMetaData';
  _etag?: Maybe<Scalars['String']['output']>;
  charCount: Scalars['Float']['output'];
  id?: Maybe<Scalars['ID']['output']>;
  propertyPath: Array<Scalars['String']['output']>;
  sourceLanguage?: Maybe<Scalars['String']['output']>;
  sourceTextAsDelta?: Maybe<Scalars['JSONObject']['output']>;
  targetLanguage?: Maybe<Scalars['String']['output']>;
  targetTextAsDelta?: Maybe<Scalars['JSONObject']['output']>;
  translationItemId: Scalars['String']['output'];
  translationProvider?: Maybe<TranslatorType>;
};

export enum TranslationMode {
  All = 'All',
  MissingAndChanged = 'MissingAndChanged'
}

export class TranslationProposalInput {
  elementPath: Scalars['String']['input'];
  language: Scalars['String']['input'];
  reasonForCorrection?: InputMaybe<Scalars['String']['input']>;
  textToCorrect: Scalars['String']['input'];
  translationProposal: Scalars['String']['input'];
};

export enum TranslatorType {
  Deepl = 'Deepl',
  Google = 'Google',
  Human = 'Human'
}

export class TreeDataModel {
  __typename?: 'TreeDataModel';
  children?: Maybe<Array<TreeDataModel>>;
  data: Scalars['String']['output'];
  key: Scalars['String']['output'];
  label: Scalars['String']['output'];
};

export class TreeNodeData {
  __typename?: 'TreeNodeData';
  example?: Maybe<Scalars['String']['output']>;
  explanation?: Maybe<Scalars['String']['output']>;
  syntax: Scalars['String']['output'];
};

export class TreeNodeInput {
  children?: InputMaybe<Array<TreeNodeInput>>;
  description?: InputMaybe<Scalars['String']['input']>;
  descriptionOwnRecruitment?: InputMaybe<Scalars['String']['input']>;
  filesAreMandatory?: InputMaybe<Scalars['Boolean']['input']>;
  forEmployer?: InputMaybe<Scalars['Boolean']['input']>;
  forPSA?: InputMaybe<Scalars['Boolean']['input']>;
  helptext?: InputMaybe<Scalars['String']['input']>;
  helptextProof?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  level: Scalars['Float']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  reCertificationOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

export class UpdateAssignedCandidatesInput {
  _etag: Scalars['String']['input'];
  assignedCandidatesIds?: InputMaybe<Array<CandidateIdInput>>;
  collectionId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};

export class UpdateAssignmentExceptionsInput {
  _etag: Scalars['String']['input'];
  assignmentExceptions: Array<CandidateAssignmentExceptionIdInput>;
  collectionId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};

export class UploadedDocumentType {
  __typename?: 'UploadedDocumentType';
  documentId?: Maybe<Scalars['ID']['output']>;
  documentType: Scalars['String']['output'];
  files?: Maybe<Array<UploadedFileDescription>>;
  isNotRelevant?: Maybe<Scalars['Boolean']['output']>;
  sentDate?: Maybe<Scalars['DateTime']['output']>;
  sentTo?: Maybe<Scalars['ID']['output']>;
};

export class UploadedDocumentTypeInput {
  documentId?: InputMaybe<Scalars['ID']['input']>;
  documentType: Scalars['String']['input'];
  files?: InputMaybe<Array<UploadedFileDescriptionInput>>;
  isNotRelevant?: InputMaybe<Scalars['Boolean']['input']>;
  sentDate?: InputMaybe<Scalars['DateTime']['input']>;
  sentTo?: InputMaybe<Scalars['ID']['input']>;
};

export class UploadedFileDescription {
  __typename?: 'UploadedFileDescription';
  complainText?: Maybe<Scalars['String']['output']>;
  complaint?: Maybe<Scalars['Boolean']['output']>;
  dateOfReceipt?: Maybe<Scalars['DateTime']['output']>;
  fileBlob: Scalars['String']['output'];
  fileFormats?: Maybe<Array<Scalars['String']['output']>>;
  fileName: Scalars['String']['output'];
  fileSize: Scalars['Float']['output'];
  fileType: Scalars['String']['output'];
  isDefaultSet?: Maybe<Scalars['Boolean']['output']>;
  issueDate?: Maybe<Scalars['DateTime']['output']>;
  resubmissionDate?: Maybe<Scalars['DateTime']['output']>;
  setName?: Maybe<Scalars['String']['output']>;
  setType?: Maybe<Scalars['String']['output']>;
  singleSetConfig?: Maybe<Array<SingleSetConfig>>;
  validFrom?: Maybe<Scalars['DateTime']['output']>;
  validUntil?: Maybe<Scalars['DateTime']['output']>;
};

export class UploadedFileDescriptionInput {
  complainText?: InputMaybe<Scalars['String']['input']>;
  complaint?: InputMaybe<Scalars['Boolean']['input']>;
  dateOfReceipt?: InputMaybe<Scalars['DateTime']['input']>;
  fileBlob: Scalars['String']['input'];
  fileFormats?: InputMaybe<Array<Scalars['String']['input']>>;
  fileName: Scalars['String']['input'];
  fileSize: Scalars['Float']['input'];
  fileType: Scalars['String']['input'];
  isDefaultSet?: InputMaybe<Scalars['Boolean']['input']>;
  issueDate?: InputMaybe<Scalars['DateTime']['input']>;
  resubmissionDate?: InputMaybe<Scalars['DateTime']['input']>;
  setName?: InputMaybe<Scalars['String']['input']>;
  setType?: InputMaybe<Scalars['String']['input']>;
  singleSetConfig?: InputMaybe<Array<SingleSetConfigInput>>;
  validFrom?: InputMaybe<Scalars['DateTime']['input']>;
  validUntil?: InputMaybe<Scalars['DateTime']['input']>;
};

export class UploadedProcessDocument {
  __typename?: 'UploadedProcessDocument';
  containerName: Scalars['String']['output'];
  documentId: Scalars['String']['output'];
  files?: Maybe<Array<UploadedFileDescription>>;
};

export class UploadedProcessDocumentInput {
  containerName: Scalars['String']['input'];
  documentId: Scalars['String']['input'];
  files?: InputMaybe<Array<UploadedFileDescriptionInput>>;
};

export class UsedVariablesDownload {
  __typename?: 'UsedVariablesDownload';
  fileName: Scalars['String']['output'];
  fileUrl: Scalars['String']['output'];
};

export class User {
  __typename?: 'User';
  _etag?: Maybe<Scalars['String']['output']>;
  auth0Id?: Maybe<Scalars['String']['output']>;
  creationDate?: Maybe<Scalars['DateTime']['output']>;
  email: Scalars['String']['output'];
  firstname: Scalars['String']['output'];
  id?: Maybe<Scalars['ID']['output']>;
  isBlocked?: Maybe<Scalars['Boolean']['output']>;
  language: Scalars['String']['output'];
  lastSeenAppVersion?: Maybe<Scalars['String']['output']>;
  lastname: Scalars['String']['output'];
  mobileNumber?: Maybe<Scalars['String']['output']>;
  organizationId: Scalars['ID']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['String']['output']>;
  salutation: UserSalutation;
  signatureAddition?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  userRoles: Array<UserRole>;
};

export class UserFeedbackCreateInput {
  comment?: InputMaybe<Scalars['String']['input']>;
  functionalityRating?: InputMaybe<Scalars['Float']['input']>;
  organizationName?: InputMaybe<Scalars['String']['input']>;
  overallRating?: InputMaybe<Scalars['Float']['input']>;
  performanceRating?: InputMaybe<Scalars['Float']['input']>;
  usabilityRating?: InputMaybe<Scalars['Float']['input']>;
};

export class UserGroup {
  __typename?: 'UserGroup';
  _etag?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isPublic?: Maybe<Scalars['Boolean']['output']>;
  memberGroups: Array<UserGroup>;
  memberUsers: Array<User>;
  name: Scalars['String']['output'];
  organizationId: Scalars['ID']['output'];
};

export class UserGroupHasMembers {
  __typename?: 'UserGroupHasMembers';
  hasMembers: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
};

export class UserGroupHasMembersInput {
  groups: Array<GroupWithOrgId>;
};

export class UserGroupInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
};

export class UserId {
  __typename?: 'UserId';
  id: Scalars['ID']['output'];
  orgId: Scalars['ID']['output'];
};

export class UserInput {
  _etag?: InputMaybe<Scalars['String']['input']>;
  auth0Id?: InputMaybe<Scalars['String']['input']>;
  creationDate?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  firstname: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  isBlocked?: InputMaybe<Scalars['Boolean']['input']>;
  language: Scalars['String']['input'];
  lastSeenAppVersion?: InputMaybe<Scalars['String']['input']>;
  lastname: Scalars['String']['input'];
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['ID']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['String']['input']>;
  salutation: UserSalutation;
  signatureAddition?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  userRoles: Array<UserRole>;
};

export enum UserRole {
  Administrator = 'Administrator',
  BillingAdministrator = 'BillingAdministrator',
  Candidate = 'Candidate',
  CandidateManager = 'CandidateManager',
  ContentAdministrator = 'ContentAdministrator',
  CourseAdministrator = 'CourseAdministrator',
  CourseTeacher = 'CourseTeacher',
  CrmContributor = 'CrmContributor',
  EmailReceiverOnly = 'EmailReceiverOnly',
  PartnerAdministrator = 'PartnerAdministrator',
  ProcessAdministrator = 'ProcessAdministrator',
  ProcessObserver = 'ProcessObserver',
  User = 'User'
}

export enum UserSalutation {
  Mr = 'MR',
  Mrs = 'MRS',
  Mx = 'MX'
}

export class VaccinationStatus {
  __typename?: 'VaccinationStatus';
  status: VaccineStatusEnum;
  vaccine: Scalars['String']['output'];
  vaccineFunction: Scalars['String']['output'];
};

export enum VaccineStatusEnum {
  Available = 'Available',
  Missing = 'Missing',
  MissingNotRequired = 'MissingNotRequired',
  NotShared = 'NotShared',
  Partial = 'Partial'
}

export class VariableMapping {
  __typename?: 'VariableMapping';
  value: Scalars['String']['output'];
  variable: Scalars['String']['output'];
};

export class VariableMappingForUi {
  __typename?: 'VariableMappingForUI';
  label: Scalars['String']['output'];
  value: Scalars['String']['output'];
  variable: Scalars['String']['output'];
};

export class VariableMappingInput {
  value: Scalars['String']['input'];
  variable: Scalars['String']['input'];
};

export class VisaCandidateDataAt {
  __typename?: 'VisaCandidateDataAT';
  arrivalAirport?: Maybe<Scalars['String']['output']>;
  arrivalTrainStation?: Maybe<Scalars['String']['output']>;
  contactAtArrivalLocation?: Maybe<Scalars['String']['output']>;
  departureAirport?: Maybe<Scalars['String']['output']>;
  departureTrainStation?: Maybe<Scalars['String']['output']>;
  fileNumber?: Maybe<Scalars['String']['output']>;
  fingerPrintDate?: Maybe<Scalars['DateTime']['output']>;
  flightArrivalDate?: Maybe<DateWithTime>;
  flightDate?: Maybe<DateWithTime>;
  flightNumber?: Maybe<Scalars['String']['output']>;
  pickUpDetails?: Maybe<Scalars['String']['output']>;
  pickUpVehicle?: Maybe<Scalars['String']['output']>;
  plannedEntryDate?: Maybe<Scalars['DateTime']['output']>;
  responsibleForTransfer?: Maybe<Scalars['String']['output']>;
  responsibleRoleVisa?: Maybe<Scalars['String']['output']>;
  trainArrivalDate?: Maybe<DateWithTime>;
  trainDepartureDate?: Maybe<DateWithTime>;
  trainNumber?: Maybe<Scalars['String']['output']>;
  visaEnquired?: Maybe<Scalars['Boolean']['output']>;
  visaEnquiredDate?: Maybe<Scalars['DateTime']['output']>;
  visaReceiveDate?: Maybe<Scalars['DateTime']['output']>;
  visaReceived?: Maybe<Scalars['Boolean']['output']>;
  visaRelocationRequirements?: Maybe<Array<Scalars['String']['output']>>;
  visaValidFromDate?: Maybe<Scalars['DateTime']['output']>;
  visaValidUntilDate?: Maybe<Scalars['DateTime']['output']>;
};

export class VisaCandidateDataAtInput {
  arrivalAirport?: InputMaybe<Scalars['String']['input']>;
  arrivalTrainStation?: InputMaybe<Scalars['String']['input']>;
  contactAtArrivalLocation?: InputMaybe<Scalars['String']['input']>;
  departureAirport?: InputMaybe<Scalars['String']['input']>;
  departureTrainStation?: InputMaybe<Scalars['String']['input']>;
  fileNumber?: InputMaybe<Scalars['String']['input']>;
  fingerPrintDate?: InputMaybe<Scalars['DateTime']['input']>;
  flightArrivalDate?: InputMaybe<DateWithTimeInput>;
  flightDate?: InputMaybe<DateWithTimeInput>;
  flightNumber?: InputMaybe<Scalars['String']['input']>;
  pickUpDetails?: InputMaybe<Scalars['String']['input']>;
  pickUpVehicle?: InputMaybe<Scalars['String']['input']>;
  plannedEntryDate?: InputMaybe<Scalars['DateTime']['input']>;
  responsibleForTransfer?: InputMaybe<Scalars['String']['input']>;
  responsibleRoleVisa?: InputMaybe<Scalars['String']['input']>;
  trainArrivalDate?: InputMaybe<DateWithTimeInput>;
  trainDepartureDate?: InputMaybe<DateWithTimeInput>;
  trainNumber?: InputMaybe<Scalars['String']['input']>;
  visaEnquired?: InputMaybe<Scalars['Boolean']['input']>;
  visaEnquiredDate?: InputMaybe<Scalars['DateTime']['input']>;
  visaReceiveDate?: InputMaybe<Scalars['DateTime']['input']>;
  visaReceived?: InputMaybe<Scalars['Boolean']['input']>;
  visaRelocationRequirements?: InputMaybe<Array<Scalars['String']['input']>>;
  visaValidFromDate?: InputMaybe<Scalars['DateTime']['input']>;
  visaValidUntilDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export class VisaCandidateDataDe {
  __typename?: 'VisaCandidateDataDE';
  arrivalAirport?: Maybe<Scalars['String']['output']>;
  arrivalDate?: Maybe<DateWithTime>;
  arrivalTrainStation?: Maybe<Scalars['String']['output']>;
  contactAtArrivalLocation?: Maybe<Scalars['String']['output']>;
  departureAirport?: Maybe<Scalars['String']['output']>;
  departureTrainStation?: Maybe<Scalars['String']['output']>;
  diplomaticMissionOfGermanyInCountry?: Maybe<Scalars['String']['output']>;
  fileNumber?: Maybe<Scalars['String']['output']>;
  flightArrivalDate?: Maybe<DateWithTime>;
  flightDate?: Maybe<DateWithTime>;
  flightNumber?: Maybe<Scalars['String']['output']>;
  pickUpVehicle?: Maybe<Scalars['String']['output']>;
  residencePermit?: Maybe<Scalars['String']['output']>;
  responsibleForTransfer?: Maybe<Scalars['String']['output']>;
  responsibleRoleVisa?: Maybe<Scalars['String']['output']>;
  trainArrivalDate?: Maybe<DateWithTime>;
  trainDepartureDate?: Maybe<DateWithTime>;
  trainNumber?: Maybe<Scalars['String']['output']>;
  visaEnquired?: Maybe<Scalars['Boolean']['output']>;
  visaEnquiredDate?: Maybe<Scalars['DateTime']['output']>;
  visaRelocationRequirements?: Maybe<Array<Scalars['String']['output']>>;
  visaRequestDate?: Maybe<Scalars['DateTime']['output']>;
  visaRequested?: Maybe<Scalars['Boolean']['output']>;
};

export class VisaCandidateDataDeInput {
  arrivalAirport?: InputMaybe<Scalars['String']['input']>;
  arrivalDate?: InputMaybe<DateWithTimeInput>;
  arrivalTrainStation?: InputMaybe<Scalars['String']['input']>;
  contactAtArrivalLocation?: InputMaybe<Scalars['String']['input']>;
  departureAirport?: InputMaybe<Scalars['String']['input']>;
  departureTrainStation?: InputMaybe<Scalars['String']['input']>;
  diplomaticMissionOfGermanyInCountry?: InputMaybe<Scalars['String']['input']>;
  fileNumber?: InputMaybe<Scalars['String']['input']>;
  flightArrivalDate?: InputMaybe<DateWithTimeInput>;
  flightDate?: InputMaybe<DateWithTimeInput>;
  flightNumber?: InputMaybe<Scalars['String']['input']>;
  pickUpVehicle?: InputMaybe<Scalars['String']['input']>;
  residencePermit?: InputMaybe<Scalars['String']['input']>;
  responsibleForTransfer?: InputMaybe<Scalars['String']['input']>;
  responsibleRoleVisa?: InputMaybe<Scalars['String']['input']>;
  trainArrivalDate?: InputMaybe<DateWithTimeInput>;
  trainDepartureDate?: InputMaybe<DateWithTimeInput>;
  trainNumber?: InputMaybe<Scalars['String']['input']>;
  visaEnquired?: InputMaybe<Scalars['Boolean']['input']>;
  visaEnquiredDate?: InputMaybe<Scalars['DateTime']['input']>;
  visaRelocationRequirements?: InputMaybe<Array<Scalars['String']['input']>>;
  visaRequestDate?: InputMaybe<Scalars['DateTime']['input']>;
  visaRequested?: InputMaybe<Scalars['Boolean']['input']>;
};

export class VocationalSchoolCandidateDataAt {
  __typename?: 'VocationalSchoolCandidateDataAT';
  address?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  currentTrainingYear?: Maybe<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['DateTime']['output']>;
  expectedEndDate?: Maybe<Scalars['DateTime']['output']>;
  federalState?: Maybe<Scalars['String']['output']>;
  medicalCheckAttended?: Maybe<Scalars['Boolean']['output']>;
  medicalCheckAttendedDate?: Maybe<Scalars['DateTime']['output']>;
  medicalCheckPassed?: Maybe<Scalars['Boolean']['output']>;
  medicalCheckPassedDate?: Maybe<Scalars['DateTime']['output']>;
  schoolCourse?: Maybe<Scalars['String']['output']>;
  schoolName?: Maybe<Scalars['String']['output']>;
  trainingTimeInPartTime?: Maybe<Scalars['Boolean']['output']>;
  trainingTimeInYearsTotal?: Maybe<Scalars['String']['output']>;
  trainingTimeShortened?: Maybe<Scalars['Boolean']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export class VocationalSchoolCandidateDataAtInput {
  address?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  currentTrainingYear?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  expectedEndDate?: InputMaybe<Scalars['DateTime']['input']>;
  federalState?: InputMaybe<Scalars['String']['input']>;
  medicalCheckAttended?: InputMaybe<Scalars['Boolean']['input']>;
  medicalCheckAttendedDate?: InputMaybe<Scalars['DateTime']['input']>;
  medicalCheckPassed?: InputMaybe<Scalars['Boolean']['input']>;
  medicalCheckPassedDate?: InputMaybe<Scalars['DateTime']['input']>;
  schoolCourse?: InputMaybe<Scalars['String']['input']>;
  schoolName?: InputMaybe<Scalars['String']['input']>;
  trainingTimeInPartTime?: InputMaybe<Scalars['Boolean']['input']>;
  trainingTimeInYearsTotal?: InputMaybe<Scalars['String']['input']>;
  trainingTimeShortened?: InputMaybe<Scalars['Boolean']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export class VocationalSchoolCandidateDataDe {
  __typename?: 'VocationalSchoolCandidateDataDE';
  address?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  currentTrainingYear?: Maybe<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['DateTime']['output']>;
  expectedEndDate?: Maybe<Scalars['DateTime']['output']>;
  federalState?: Maybe<Scalars['String']['output']>;
  medicalCheckAttended?: Maybe<Scalars['Boolean']['output']>;
  medicalCheckAttendedDate?: Maybe<Scalars['DateTime']['output']>;
  medicalCheckPassed?: Maybe<Scalars['Boolean']['output']>;
  medicalCheckPassedDate?: Maybe<Scalars['DateTime']['output']>;
  schoolCourse?: Maybe<Scalars['String']['output']>;
  schoolName?: Maybe<Scalars['String']['output']>;
  trainingTimeInPartTime?: Maybe<Scalars['Boolean']['output']>;
  trainingTimeInYearsTotal?: Maybe<Scalars['String']['output']>;
  trainingTimeShortened?: Maybe<Scalars['Boolean']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export class VocationalSchoolCandidateDataDeInput {
  address?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  currentTrainingYear?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  expectedEndDate?: InputMaybe<Scalars['DateTime']['input']>;
  federalState?: InputMaybe<Scalars['String']['input']>;
  medicalCheckAttended?: InputMaybe<Scalars['Boolean']['input']>;
  medicalCheckAttendedDate?: InputMaybe<Scalars['DateTime']['input']>;
  medicalCheckPassed?: InputMaybe<Scalars['Boolean']['input']>;
  medicalCheckPassedDate?: InputMaybe<Scalars['DateTime']['input']>;
  schoolCourse?: InputMaybe<Scalars['String']['input']>;
  schoolName?: InputMaybe<Scalars['String']['input']>;
  trainingTimeInPartTime?: InputMaybe<Scalars['Boolean']['input']>;
  trainingTimeInYearsTotal?: InputMaybe<Scalars['String']['input']>;
  trainingTimeShortened?: InputMaybe<Scalars['Boolean']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export enum VoteType {
  Negative = 'Negative',
  Neutral = 'Neutral',
  Positive = 'Positive'
}

export enum WorkExperiencePrecissionEnum {
  Day = 'DAY',
  Month = 'MONTH'
}

export enum WorkingTimeTypeEnum {
  FullTime = 'FullTime',
  MarginalEmployment = 'MarginalEmployment',
  PartTime = 'PartTime'
}

export class XlsxCandidateImportInput {
  addTestData?: InputMaybe<Scalars['Boolean']['input']>;
  blob: Scalars['String']['input'];
  collectionId: Scalars['ID']['input'];
  fileName: Scalars['String']['input'];
  language: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
};

export class XlsxContractTemplateImportInput {
  addTestData?: InputMaybe<Scalars['Boolean']['input']>;
  blob: Scalars['String']['input'];
  fileName: Scalars['String']['input'];
  language: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
};

export class XlsxImportIssue {
  __typename?: 'XLSXImportIssue';
  lineNumber?: Maybe<Scalars['Float']['output']>;
  message: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  property?: Maybe<Scalars['String']['output']>;
};

export class XlsxImportIssueInput {
  lineNumber?: InputMaybe<Scalars['Float']['input']>;
  message: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  property?: InputMaybe<Scalars['String']['input']>;
};

export class XlsxImportResult {
  __typename?: 'XLSXImportResult';
  failed: Scalars['Boolean']['output'];
  fileName: Scalars['String']['output'];
  importType: Scalars['String']['output'];
  imported: Scalars['Float']['output'];
  issues?: Maybe<Array<XlsxImportIssue>>;
  total: Scalars['Float']['output'];
};

export class XlsxOrganizationCrmDataImportInput {
  addTestData?: InputMaybe<Scalars['Boolean']['input']>;
  blob: Scalars['String']['input'];
  fileName: Scalars['String']['input'];
  language: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
};

export class XlsxOrganizationContactImportInput {
  addTestData?: InputMaybe<Scalars['Boolean']['input']>;
  blob: Scalars['String']['input'];
  fileName: Scalars['String']['input'];
  language: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
};

export class XlsxOrganizationContactSettingsImportInput {
  addTestData?: InputMaybe<Scalars['Boolean']['input']>;
  blob: Scalars['String']['input'];
  fileName: Scalars['String']['input'];
  language: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
};

export class XlsxOrganizationImportInput {
  addTestData?: InputMaybe<Scalars['Boolean']['input']>;
  blob: Scalars['String']['input'];
  fileName: Scalars['String']['input'];
  language: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
};

export class XlsxOrganizationUserGroupsImportInput {
  addTestData?: InputMaybe<Scalars['Boolean']['input']>;
  blob: Scalars['String']['input'];
  fileName: Scalars['String']['input'];
  language: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
};

export class XlsxOrganizationUserMembershipsImportInput {
  addTestData?: InputMaybe<Scalars['Boolean']['input']>;
  blob: Scalars['String']['input'];
  fileName: Scalars['String']['input'];
  language: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
};

export class XlsxOrganizationUsersImportInput {
  addTestData?: InputMaybe<Scalars['Boolean']['input']>;
  blob: Scalars['String']['input'];
  fileName: Scalars['String']['input'];
  language: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
};

export class XlsxProcessTranslationGetInput {
  languages: Array<Scalars['String']['input']>;
  mode: TranslationMode;
  organizationId: Scalars['ID']['input'];
  processId: Scalars['ID']['input'];
};

export class XlsxProcessTranslationImportInput {
  blob: Scalars['String']['input'];
  fileName: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  processId: Scalars['ID']['input'];
};

export class XlsxProcessTranslationUploadUrlInput {
  blob: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
};

export class XlsxTemplateForCandidatesGetInput {
  addTestData?: InputMaybe<Scalars['Boolean']['input']>;
  collectionId: Scalars['ID']['input'];
  collectionOrganizationId: Scalars['ID']['input'];
  immigrationCountry: Scalars['String']['input'];
  language: Scalars['String']['input'];
  mode: CandidateSheetModeEnum;
  organizationId: Scalars['ID']['input'];
};

export class XlsxTemplateForContractTemplatesGetInput {
  addTestData?: InputMaybe<Scalars['Boolean']['input']>;
  immigrationCountry: Scalars['String']['input'];
  language: Scalars['String']['input'];
  myOrganizationOnly: Scalars['Boolean']['input'];
  organizationId: Scalars['ID']['input'];
};

export class XlsxTemplateForOrganizationCrmDataGetInput {
  addTestData?: InputMaybe<Scalars['Boolean']['input']>;
  language: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
};

export class XlsxTemplateForOrganizationContactSettingsGetInput {
  addTestData?: InputMaybe<Scalars['Boolean']['input']>;
  language: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
};

export class XlsxTemplateForOrganizationContactsGetInput {
  addTestData?: InputMaybe<Scalars['Boolean']['input']>;
  language: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
};

export class XlsxTemplateForOrganizationUserGroupsInput {
  addTestData?: InputMaybe<Scalars['Boolean']['input']>;
  language: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
};

export class XlsxTemplateForOrganizationUserMembershipsInput {
  addTestData?: InputMaybe<Scalars['Boolean']['input']>;
  language: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
};

export class XlsxTemplateForOrganizationUsersInput {
  addTestData?: InputMaybe<Scalars['Boolean']['input']>;
  language: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
};

export class XlsxTemplateForOrganizationsGetInput {
  addTestData?: InputMaybe<Scalars['Boolean']['input']>;
  language: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
};

export class XlsxTemplateForProcessesGetInput {
  addTestData?: InputMaybe<Scalars['Boolean']['input']>;
  language: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
};

export class XlsxTemplateResult {
  __typename?: 'XLSXTemplateResult';
  downloadUrl: Scalars['String']['output'];
  fileName: Scalars['String']['output'];
};

export class XlsxTranslationImportMessage {
  __typename?: 'XLSXTranslationImportMessage';
  language: Scalars['String']['output'];
  modelId: Scalars['String']['output'];
  name: Scalars['String']['output'];
  property?: Maybe<Scalars['String']['output']>;
  severity: Scalars['String']['output'];
  text: Scalars['String']['output'];
};

export class XlsxTranslationImportResult {
  __typename?: 'XLSXTranslationImportResult';
  messages: Array<XlsxTranslationImportMessage>;
  success: Scalars['Boolean']['output'];
};

export class YearsAndMonths {
  __typename?: 'YearsAndMonths';
  months: Scalars['Float']['output'];
  years: Scalars['Float']['output'];
};

export type GetCandidateDataQueryVariables = Exact<{
  input: ExcelAddInGetCandidateDataInput;
}>;


export type GetCandidateDataQuery = { __typename?: 'Query', getCandidateData: { __typename?: 'ExcelAddInDTO', dataTables: Array<{ __typename?: 'ExcelTable', id: string, label: string, dataCells: Array<any>, columns?: Array<{ __typename?: 'ExcelColumn', id: string, label: string }> | null }> } };

export type GetCollectionListQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCollectionListQuery = { __typename?: 'Query', getCollectionList: { __typename?: 'CollectionListDTO', orgId: string, collection?: Array<{ __typename?: 'CollectionListEntry', id: string, name: string, organizationId: string }> | null } };

export type CollectionListDtoFragment = { __typename?: 'CollectionListDTO', orgId: string, collection?: Array<{ __typename?: 'CollectionListEntry', id: string, name: string, organizationId: string }> | null };

export type CollectionListEntryFragment = { __typename?: 'CollectionListEntry', id: string, name: string, organizationId: string };

export type ExcelAddInDtoFragment = { __typename?: 'ExcelAddInDTO', dataTables: Array<{ __typename?: 'ExcelTable', id: string, label: string, dataCells: Array<any>, columns?: Array<{ __typename?: 'ExcelColumn', id: string, label: string }> | null }> };

export type ExcelTableFragment = { __typename?: 'ExcelTable', id: string, label: string, dataCells: Array<any>, columns?: Array<{ __typename?: 'ExcelColumn', id: string, label: string }> | null };

export type ExcelColumnFragment = { __typename?: 'ExcelColumn', id: string, label: string };

export type GetChangeStatisticsDataSubscriptionVariables = Exact<{
  input: ExcelAddinGetChangeStatisticsInput;
}>;


export type GetChangeStatisticsDataSubscription = { __typename?: 'Subscription', getChangeStatisticsData: { __typename?: 'ExcelAddInDTO', dataTables: Array<{ __typename?: 'ExcelTable', id: string, label: string, dataCells: Array<any>, columns?: Array<{ __typename?: 'ExcelColumn', id: string, label: string }> | null }> } };

export type GetKnownVarsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetKnownVarsQuery = { __typename?: 'Query', getKnownVars: { __typename?: 'KnownVarsTreeDTO', metaData?: string | null, knownVars: Array<{ __typename?: 'KnownVarsNode', key: string, label: string, parentKey?: string | null, data: { __typename?: 'TreeNodeData', explanation?: string | null, example?: string | null, syntax: string } }> } };

export type KnownVarsTreeDtoFragment = { __typename?: 'KnownVarsTreeDTO', metaData?: string | null, knownVars: Array<{ __typename?: 'KnownVarsNode', key: string, label: string, parentKey?: string | null, data: { __typename?: 'TreeNodeData', explanation?: string | null, example?: string | null, syntax: string } }> };

export type KnownVarsNodeFragment = { __typename?: 'KnownVarsNode', key: string, label: string, parentKey?: string | null, data: { __typename?: 'TreeNodeData', explanation?: string | null, example?: string | null, syntax: string } };

export type TreeNodeDataFragment = { __typename?: 'TreeNodeData', explanation?: string | null, example?: string | null, syntax: string };

export type GetSalesDataQueryVariables = Exact<{
  input: ExcelAddInSalesDataInput;
}>;


export type GetSalesDataQuery = { __typename?: 'Query', getSalesData: { __typename?: 'ExcelAddInDTO', dataTables: Array<{ __typename?: 'ExcelTable', id: string, label: string, dataCells: Array<any>, columns?: Array<{ __typename?: 'ExcelColumn', id: string, label: string }> | null }> } };

export type GetAvailableOrganizationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAvailableOrganizationsQuery = { __typename?: 'Query', getAvailableOrganizations: { __typename?: 'OrganizationListDTO', organizations?: Array<{ __typename?: 'OrganizationListEntry', organizationId: string, isLicensed: boolean, name: string }> | null } };

export type OrganizationListDtoFragment = { __typename?: 'OrganizationListDTO', organizations?: Array<{ __typename?: 'OrganizationListEntry', organizationId: string, isLicensed: boolean, name: string }> | null };

export type OrganizationListEntryFragment = { __typename?: 'OrganizationListEntry', organizationId: string, isLicensed: boolean, name: string };

export type GetSettingsQueryVariables = Exact<{
  firstLoadInSession: Scalars['Boolean']['input'];
}>;


export type GetSettingsQuery = { __typename?: 'Query', settings: { __typename?: 'Settings', userPermissions?: Array<string> | null, organizationId?: string | null, auth0UserId?: string | null, isCandidate?: boolean | null, organizationCode?: string | null, organizationName?: string | null, organizationEmail?: string | null, supportEmail?: string | null, supportPhone?: string | null, creatorOrganizationId?: string | null, creatorOrganizationName?: string | null, isCreatorMasterOrganization?: boolean | null, isMasterOrganization?: boolean | null, isMasterUser?: boolean | null, userEmail?: string | null, userDisplayName?: string | null, userInitials?: string | null, userDisplayId?: string | null } };

export const CollectionListEntryFragmentDoc = gql`
    fragment CollectionListEntry on CollectionListEntry {
  id
  name
  organizationId
}
    `;
export const CollectionListDtoFragmentDoc = gql`
    fragment CollectionListDTO on CollectionListDTO {
  orgId
  collection {
    ...CollectionListEntry
  }
}
    ${CollectionListEntryFragmentDoc}`;
export const ExcelColumnFragmentDoc = gql`
    fragment ExcelColumn on ExcelColumn {
  id
  label
}
    `;
export const ExcelTableFragmentDoc = gql`
    fragment ExcelTable on ExcelTable {
  id
  label
  columns {
    ...ExcelColumn
  }
  dataCells
}
    ${ExcelColumnFragmentDoc}`;
export const ExcelAddInDtoFragmentDoc = gql`
    fragment ExcelAddInDTO on ExcelAddInDTO {
  dataTables {
    ...ExcelTable
  }
}
    ${ExcelTableFragmentDoc}`;
export const TreeNodeDataFragmentDoc = gql`
    fragment TreeNodeData on TreeNodeData {
  explanation
  example
  syntax
}
    `;
export const KnownVarsNodeFragmentDoc = gql`
    fragment KnownVarsNode on KnownVarsNode {
  key
  label
  data {
    ...TreeNodeData
  }
  parentKey
}
    ${TreeNodeDataFragmentDoc}`;
export const KnownVarsTreeDtoFragmentDoc = gql`
    fragment KnownVarsTreeDTO on KnownVarsTreeDTO {
  knownVars {
    ...KnownVarsNode
  }
  metaData
}
    ${KnownVarsNodeFragmentDoc}`;
export const OrganizationListEntryFragmentDoc = gql`
    fragment OrganizationListEntry on OrganizationListEntry {
  organizationId
  isLicensed
  name
}
    `;
export const OrganizationListDtoFragmentDoc = gql`
    fragment OrganizationListDTO on OrganizationListDTO {
  organizations {
    ...OrganizationListEntry
  }
}
    ${OrganizationListEntryFragmentDoc}`;
export const GetCandidateDataDocument = gql`
    query getCandidateData($input: ExcelAddInGetCandidateDataInput!) {
  getCandidateData(input: $input) {
    ...ExcelAddInDTO
  }
}
    ${ExcelAddInDtoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetCandidateDataGQL extends Apollo.Query<GetCandidateDataQuery, GetCandidateDataQueryVariables> {
    override document = GetCandidateDataDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetCollectionListDocument = gql`
    query getCollectionList {
  getCollectionList {
    ...CollectionListDTO
  }
}
    ${CollectionListDtoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetCollectionListGQL extends Apollo.Query<GetCollectionListQuery, GetCollectionListQueryVariables> {
    override document = GetCollectionListDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetChangeStatisticsDataDocument = gql`
    subscription getChangeStatisticsData($input: ExcelAddinGetChangeStatisticsInput!) {
  getChangeStatisticsData(input: $input) {
    ...ExcelAddInDTO
  }
}
    ${ExcelAddInDtoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetChangeStatisticsDataGQL extends Apollo.Subscription<GetChangeStatisticsDataSubscription, GetChangeStatisticsDataSubscriptionVariables> {
    override document = GetChangeStatisticsDataDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetKnownVarsDocument = gql`
    query getKnownVars {
  getKnownVars {
    ...KnownVarsTreeDTO
  }
}
    ${KnownVarsTreeDtoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetKnownVarsGQL extends Apollo.Query<GetKnownVarsQuery, GetKnownVarsQueryVariables> {
    override document = GetKnownVarsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetSalesDataDocument = gql`
    query getSalesData($input: ExcelAddInSalesDataInput!) {
  getSalesData(input: $input) {
    ...ExcelAddInDTO
  }
}
    ${ExcelAddInDtoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetSalesDataGQL extends Apollo.Query<GetSalesDataQuery, GetSalesDataQueryVariables> {
    override document = GetSalesDataDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetAvailableOrganizationsDocument = gql`
    query getAvailableOrganizations {
  getAvailableOrganizations {
    ...OrganizationListDTO
  }
}
    ${OrganizationListDtoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAvailableOrganizationsGQL extends Apollo.Query<GetAvailableOrganizationsQuery, GetAvailableOrganizationsQueryVariables> {
    override document = GetAvailableOrganizationsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetSettingsDocument = gql`
    query getSettings($firstLoadInSession: Boolean!) {
  settings(firstLoadInSession: $firstLoadInSession) {
    userPermissions
    organizationId
    auth0UserId
    isCandidate
    organizationCode
    organizationName
    organizationEmail
    supportEmail
    supportPhone
    creatorOrganizationId
    creatorOrganizationName
    isCreatorMasterOrganization
    isMasterOrganization
    isMasterUser
    userEmail
    userDisplayName
    userInitials
    userDisplayId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetSettingsGQL extends Apollo.Query<GetSettingsQuery, GetSettingsQueryVariables> {
    override document = GetSettingsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }